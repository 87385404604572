// @ts-nocheck

angular.module('yaltyApp')
    
    .controller('LoyaltyCardYaltyStampsAndRedeemsController', function($scope, $http, API_PREFIX, GoogleAnalyticsService, SubscriptionNames, $rootScope, $cookies) {
        $scope.initRefreshInprogress = true;
        $scope.isFullscreen = false;
        $scope.fullscreenImageSrc = null;
    
        $scope.openFullscreen = function(imageSrc) {
            $scope.isFullscreen = true;
            $scope.fullscreenImageSrc = imageSrc;
        };
    
        $scope.closeFullscreen = function() {
            $scope.isFullscreen = false;
            $scope.fullscreenImageSrc = null;
        };

        let date = new Date();
        let actualYear = date.getFullYear();
        let actualMonth = date.getMonth();
        let actualDay = date.getDate();
        $scope.areRedeemPageFilterSettingsPredefined = $cookies.get('areRedeemPageFilterSettingsPredefined') != null ? $cookies.get('areRedeemPageFilterSettingsPredefined') === "true" : false;
        $scope.adminUserType = $cookies.get('adminUserType');
        $scope.partnerSubscriptionType = $rootScope.partnerSubscriptionType;
        $scope.currentSubType = SubscriptionNames[$rootScope.partnerSubscriptionType];
        $scope.reqSubType = SubscriptionNames['premium'];
        $scope.timeId = 1;
        $scope.nrOfStampings = '-'
        $scope.nrOfRedeems = '-'
        $scope.originalList = [];
        $scope.year = actualYear;
        $scope.allYear = [];
        $scope.allMonth = [];
        $scope.allDays = [];
        $scope.checkedCompanyStores = [];
        $scope.loyaltyCards = [];
        $scope.companyStores = [];
        $scope.companyStoreCashRegisters = [];
        $scope.allCompanyStore = [];
        $scope.allCompanyStoreCashRegister = [];
        $scope.notCashRegister = true;
        $scope.allLoyaltyCard = [];
        let monthList = ["Január", "Február", "Március", "Április", "Május", "Június", "Július", "Augusztus", "Szeptember", "Október", "November", "December"];
        $scope.month = actualMonth + 1;
        $scope.day = actualDay;
        $scope.stampOrRedeemHappenedEver = false;
        if(actualYear == 2019){
            for(let i = 3; i <= actualMonth; i++){
                let months = {};
                months.value = i+1;
                months.name = monthList[i];
                $scope.allMonth.push(months);
            }
        } else {
            for(let i = 0; i <= actualMonth; i++){
                let months = {};
                months.value = i+1;
                months.name = monthList[i];
                $scope.allMonth.push(months);
            }
        }
        for(let i = actualYear; i >= 2019; i--){
            let years = {};
            years.value = i;
            years.name = i;
            $scope.allYear.push(years);
        }
        for(let i = 1; i <= actualDay; i++) {
            let day = {};
            day.value = i;
            day.name = i;
            $scope.allDays.push(day);
        }

        $scope.changeMonth = function(){
            $scope.month = actualMonth + 1;
            $scope.allMonth = [];
            $scope.allDays = [];
            if($scope.year == 2019 && $scope.year == actualYear){
                for(let i = 2; i <= actualMonth; i++){
                    let months = {};
                    months.value = i+1;
                    months.name = monthList[i];
                    $scope.allMonth.push(months);
                }
            } else if($scope.year == 2019){
                for(let i = 2; i < monthList.length; i++){
                    let months = {};
                    months.value = i+1;
                    months.name = monthList[i];
                    $scope.allMonth.push(months);
                }
            } else if($scope.year == actualYear) {
                for(let i = 0; i <= actualMonth; i++){
                    let months = {};
                    months.value = i+1;
                    months.name = monthList[i];
                    $scope.allMonth.push(months);
                }
            } else {
                for(let i = 0; i < monthList.length; i++){
                    let months = {};
                    months.value = i+1;
                    months.name = monthList[i];
                    $scope.allMonth.push(months);
                }
            }
            if($scope.year == actualYear && $scope.month == (actualMonth + 1)) {
                for(let i = 1; i <= actualDay; i++) {
                    let day = {};
                    day.value = i;
                    day.name = i;
                    $scope.allDays.push(day);
                }
            } else if($scope.month == 1) {
                for(let i = 1; i <= 31; i++) {
                    let day = {};
                    day.value = i;
                    day.name = i;
                    $scope.allDays.push(day);
                }
            } else if($scope.month == 2 && $scope.year % 4 == 0) {
                for(let i = 1; i <= 29; i++) {
                    let day = {};
                    day.value = i;
                    day.name = i;
                    $scope.allDays.push(day);
                }
            } else if($scope.month == 2 && $scope.year % 4 != 0) {
                for(let i = 1; i <= 28; i++) {
                    let day = {};
                    day.value = i;
                    day.name = i;
                    $scope.allDays.push(day);
                }
            } else if($scope.month == 3) {
                for(let i = 1; i <= 31; i++) {
                    let day = {};
                    day.value = i;
                    day.name = i;
                    $scope.allDays.push(day);
                }
            } else if($scope.month == 4) {
                for(let i = 1; i <= 30; i++) {
                    let day = {};
                    day.value = i;
                    day.name = i;
                    $scope.allDays.push(day);
                }
            } else if($scope.month == 5) {
                for(let i = 1; i <= 31; i++) {
                    let day = {};
                    day.value = i;
                    day.name = i;
                    $scope.allDays.push(day);
                }
            } else if($scope.month == 6) {
                for(let i = 1; i <= 30; i++) {
                    let day = {};
                    day.value = i;
                    day.name = i;
                    $scope.allDays.push(day);
                }
            } else if($scope.month == 7) {
                for(let i = 1; i <= 31; i++) {
                    let day = {};
                    day.value = i;
                    day.name = i;
                    $scope.allDays.push(day);
                }
            } else if($scope.month == 8) {
                for(let i = 1; i <= 31; i++) {
                    let day = {};
                    day.value = i;
                    day.name = i;
                    $scope.allDays.push(day);
                }
            } else if($scope.month == 9) {
                for(let i = 1; i <= 30; i++) {
                    let day = {};
                    day.value = i;
                    day.name = i;
                    $scope.allDays.push(day);
                }
            } else if($scope.month == 10) {
                for(let i = 1; i <= 31; i++) {
                    let day = {};
                    day.value = i;
                    day.name = i;
                    $scope.allDays.push(day);
                }
            } else if($scope.month == 11) {
                for(let i = 1; i <= 30; i++) {
                    let day = {};
                    day.value = i;
                    day.name = i;
                    $scope.allDays.push(day);
                }
            } else if($scope.month == 12) {
                for(let i = 1; i <= 31; i++) {
                    let day = {};
                    day.value = i;
                    day.name = i;
                    $scope.allDays.push(day);
                }
            }
        }

        $scope.changeDay = function(){
            $scope.allDays = [];
            if($scope.year == actualYear && $scope.month == (actualMonth + 1)) {
                for(let i = 1; i <= actualDay; i++) {
                    let day = {};
                    day.value = i;
                    day.name = i;
                    $scope.allDays.push(day);
                }
            } else if($scope.month == 1) {
                for(let i = 1; i <= 31; i++) {
                    let day = {};
                    day.value = i;
                    day.name = i;
                    $scope.allDays.push(day);
                }
            } else if($scope.month == 2) {
                for(let i = 1; i <= 28; i++) {
                    let day = {};
                    day.value = i;
                    day.name = i;
                    $scope.allDays.push(day);
                }
            } else if($scope.month == 3) {
                for(let i = 1; i <= 31; i++) {
                    let day = {};
                    day.value = i;
                    day.name = i;
                    $scope.allDays.push(day);
                }
            } else if($scope.month == 4) {
                for(let i = 1; i <= 30; i++) {
                    let day = {};
                    day.value = i;
                    day.name = i;
                    $scope.allDays.push(day);
                }
            } else if($scope.month == 5) {
                for(let i = 1; i <= 31; i++) {
                    let day = {};
                    day.value = i;
                    day.name = i;
                    $scope.allDays.push(day);
                }
            } else if($scope.month == 6) {
                for(let i = 1; i <= 30; i++) {
                    let day = {};
                    day.value = i;
                    day.name = i;
                    $scope.allDays.push(day);
                }
            } else if($scope.month == 7) {
                for(let i = 1; i <= 31; i++) {
                    let day = {};
                    day.value = i;
                    day.name = i;
                    $scope.allDays.push(day);
                }
            } else if($scope.month == 8) {
                for(let i = 1; i <= 31; i++) {
                    let day = {};
                    day.value = i;
                    day.name = i;
                    $scope.allDays.push(day);
                }
            } else if($scope.month == 9) {
                for(let i = 1; i <= 30; i++) {
                    let day = {};
                    day.value = i;
                    day.name = i;
                    $scope.allDays.push(day);
                }
            } else if($scope.month == 10) {
                for(let i = 1; i <= 31; i++) {
                    let day = {};
                    day.value = i;
                    day.name = i;
                    $scope.allDays.push(day);
                }
            } else if($scope.month == 11) {
                for(let i = 1; i <= 30; i++) {
                    let day = {};
                    day.value = i;
                    day.name = i;
                    $scope.allDays.push(day);
                }
            } else if($scope.month == 12) {
                for(let i = 1; i <= 31; i++) {
                    let day = {};
                    day.value = i;
                    day.name = i;
                    $scope.allDays.push(day);
                }
            }
        }

        $scope.refreshList = function () {
            let filters = {};
            if ($scope.year) {
                filters.year = $scope.year;
            }
            if ($scope.month) {
                filters.month = $scope.month;
            }
            if ($scope.timeId == 1 && $scope.day) {
                filters.day = $scope.day;
            }          
            if(($scope.partnerSubscriptionType == 'premium' || $scope.partnerSubscriptionType == 'custom') && ($scope.adminUserType== null || $scope.adminUserType =='' || $scope.adminUserType=='executive')) {
                $scope.refreshInProgress = true;
                return $http.post(API_PREFIX.url + '/partner/getLoyaltyCardYaltyStampAndRedeems', filters).then(function (result) {
                    $scope.refreshInProgress = false;
                    $scope.initRefreshInprogress = false;
                    if (result.data.errorCode == 0) {
                        $scope.stampOrRedeemHappenedEver = result.data.stampOrRedeemHappenedEver;
                        $scope.originalList = result.data.stampAndRedeemsArray;
                        $scope.redeemsHistoryData = $scope.originalList;
                        $scope.companyStores = [];
                        $scope.companyStoreCashRegisters = [];
                        $scope.loyaltyCards = [];
                        $scope.allCompanyStore = [];
                        $scope.allCompanyStoreCashRegister = [];
                        $scope.allLoyaltyCard = [];
                        for(let i = 0; i < result.data.stampAndRedeemsArray.length; i++){
                            if(result.data.stampAndRedeemsArray[i].compStoreName != null){
                                let compStore = {};
                                compStore.value = result.data.stampAndRedeemsArray[i].companyStoreId;
                                compStore.name = result.data.stampAndRedeemsArray[i].compStoreName;
                                const index = $scope.allCompanyStore.findIndex(x => x.name==result.data.stampAndRedeemsArray[i].compStoreName)
                                if (index === -1){
                                    $scope.allCompanyStore.push(compStore);
                                }
                            }
                            var loyCard = {};
                            loyCard.value = result.data.stampAndRedeemsArray[i].loyaltyCardYaltyId;
                            loyCard.name = result.data.stampAndRedeemsArray[i].promotionName;
                            loyCard.info = '(';
                            if(result.data.stampAndRedeemsArray[i].deactivatedTimestamp != null) {
                                loyCard.info += 'törölt, '
                            } else if(result.data.stampAndRedeemsArray[i].newCardIssuingStoppedTimestamp != null) {
                                loyCard.info += 'kifutó, '
                            }
                            loyCard.info += 'aktiválva: '
                            loyCard.activatedTimestamp = result.data.stampAndRedeemsArray[i].activatedTimestamp
                            const index = $scope.allLoyaltyCard.findIndex(x => x.value==result.data.stampAndRedeemsArray[i].loyaltyCardYaltyId)
                            if (index === -1){
                                $scope.allLoyaltyCard.push(loyCard);
                            }
                        }
                        for(let i = 0; i < result.data.stampAndRedeemsArray.length; i++){ //Online vagy telefonos rendelés opció hozzáadása
                            if(result.data.stampAndRedeemsArray[i].compStoreName == null){
                                let compStore = {};
                                compStore.value = 0;
                                compStore.name = "Online vagy telefonos rendelés/beváltás";
                                const index = $scope.allCompanyStore.findIndex(x => x.name=="Online vagy telefonos rendelés/beváltás")
                                if (index === -1){
                                    $scope.allCompanyStore.push(compStore);
                                }
                            }
                        }
                        $scope.checkAll = true;
                        for(let i = 0; i < $scope.allCompanyStore.length; i++){
                            $scope.companyStores.push($scope.allCompanyStore[i].value);
                        }
                        $scope.checkAllLoyaltyCard = true;
                        for(let i = 0; i < $scope.allLoyaltyCard.length; i++){
                            $scope.loyaltyCards.push($scope.allLoyaltyCard[i].value);
                        }
                        $scope.allCompanyStoreCashRegister = [];
                        $scope.companyStoreCashRegisters = [];
                        if($scope.companyStores.length == 1) {
                            $scope.checkAllCashRegister = true;
                            $scope.notCashRegister = true;
                            for(let i = 0; i < $scope.redeemsHistoryData.length; i++){
                                if($scope.redeemsHistoryData[i].companyStoreCashRegisterName != null && $scope.companyStores[0] == $scope.redeemsHistoryData[i].companyStoreId){
                                    let compStoreCashRegister = {};
                                    compStoreCashRegister.value = $scope.redeemsHistoryData[i].companyStoreCashRegisterId;
                                    compStoreCashRegister.name = $scope.redeemsHistoryData[i].companyStoreCashRegisterName;
                                    const index = $scope.allCompanyStoreCashRegister.findIndex(x => x.value==$scope.redeemsHistoryData[i].companyStoreCashRegisterId)
                                    if (index === -1){
                                        $scope.allCompanyStoreCashRegister.push(compStoreCashRegister);
                                    }
                                }
                            }
                            for(let i = 0; i < $scope.allCompanyStoreCashRegister.length; i++){
                                $scope.companyStoreCashRegisters.push($scope.allCompanyStoreCashRegister[i].value);
                            }
                            let showNotCashRegister = false;
                            const index = $scope.redeemsHistoryData.findIndex(x => x.companyStoreId==$scope.companyStores[0] && x.companyStoreCashRegisterId == null)
                            if (index !== -1 && $scope.allCompanyStoreCashRegister.length > 0){
                                showNotCashRegister = true;
                            }
                            $scope.showNotCashRegister = showNotCashRegister;
                        }
                        $scope.nrOfStampings = 0;
                        $scope.nrOfRedeems = 0;
                        for(let i = 0; i < $scope.redeemsHistoryData.length; i++) {
                            $scope.nrOfStampings +=  $scope.redeemsHistoryData[i].nrOfStamps;
                            $scope.nrOfRedeems += $scope.redeemsHistoryData[i].nrOfRedeems;
                        }
                        if($cookies.get('defaultRedeemPageCompanyStoreFilter') != null && $cookies.get('defaultRedeemPageCompanyStoreFilter') != 'all') {
                            $scope.companyStores = JSON.parse($cookies.get('defaultRedeemPageCompanyStoreFilter'));
                            $scope.selectCompStore();
                        }
                        setTimeout(function () {
                            if($cookies.get('defaultRedeemPageCompanyStoreCashRegisterFilter') != null && $cookies.get('defaultRedeemPageCompanyStoreCashRegisterFilter') != 'null') {
                                $scope.companyStoreCashRegisters = [];
                                $scope.companyStoreCashRegisters.push(parseInt($cookies.get('defaultRedeemPageCompanyStoreCashRegisterFilter')));
                                $scope.notCashRegister = false;
                                $scope.selectCompStoreCashRegister();
                            }
                        }, 100)
                        GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/pecsét_jutalom_kiadások_összessítése/lekérdezés_sikeres",
                            "HU Partner [PRIVÁT] - Pecsét/jutalom kiadások összessítése oldal - Sikeres lekérdezés")
                    } else {
                        $scope.redeemsHistoryData = [];
                        GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/pecsét_jutalom_kiadások_összessítése/lekérdezés_sikertelen",
                            "HU Partner [PRIVÁT] - Pecsét/jutalom kiadások összessítése oldal - Sikertelen lekérdezés")
                        $scope.showHistoryData = false;
                    }
                });
            }else{
                $scope.initRefreshInprogress = false;
            }
        }

        $scope.filterList = function () {
            var filteredData = [];
            for(let i = 0; i < $scope.originalList.length; i++){
                filteredData.push($scope.originalList[i]);
            }
            for(let i = 0; i < filteredData.length; i++){
                if(!$scope.companyStores.includes(filteredData[i].companyStoreId) ){
                    filteredData.splice(i, 1);
                    i = -1;
                }
            }
            if($scope.companyStores.length == 1 && ($scope.allCompanyStoreCashRegister.length > 0 || ($cookies.get('defaultRedeemPageCompanyStoreCashRegisterFilter') != null && $cookies.get('defaultRedeemPageCompanyStoreCashRegisterFilter') != 'null'))) {
                for(let i = 0; i < filteredData.length; i++){
                    if(!$scope.companyStoreCashRegisters.includes(filteredData[i].companyStoreCashRegisterId) && (filteredData[i].companyStoreCashRegisterId != null || $scope.notCashRegister == false)){
                        filteredData.splice(i, 1);
                        i = -1;
                    }
                }
            }
            for(let i = 0; i < filteredData.length; i++){
                if(!$scope.loyaltyCards.includes(filteredData[i].loyaltyCardYaltyId)){
                    filteredData.splice(i, 1);
                    i = -1;
                }
            }
            $scope.redeemsHistoryData = filteredData;
            $scope.nrOfStampings = 0;
            $scope.nrOfRedeems = 0;
            for(let i = 0; i < $scope.redeemsHistoryData.length; i++) {
                $scope.nrOfStampings +=  $scope.redeemsHistoryData[i].nrOfStamps;
                $scope.nrOfRedeems += $scope.redeemsHistoryData[i].nrOfRedeems;
            }
        }

        $scope.selectCompStore = function () {
            $scope.filterList();
        }

        $scope.selectCompStoreCashRegister = function () {
            $scope.filterList();
        }

        $scope.selectLoyaltyCard = function () {
            $scope.filterList();
        }

        $scope.$watch('companyStores', function (value){
            if (value) {
                if ($scope.companyStores.length == $scope.allCompanyStore.length) {
                    $scope.checkAll = true;
                } else {
                    $scope.checkAll = false;
                }
                if($scope.checkAll == true) {
                    $scope.companyStoreName = 'Mind';
                } else {
                    $scope.companyStoreName = '';
                    for(let i = 0; i < value.length; i++) {
                        let result = $scope.allCompanyStore.filter(obj => {
                            return obj.value === value[i]
                        })
                        if(result.length == 1) {
                            if($scope.companyStoreName != '') {
                                $scope.companyStoreName += ', '
                            }
                            $scope.companyStoreName += result[0].name;
                        }
                    }
                }
                $scope.companyStoreCashRegisters = [];
                $scope.allCompanyStoreCashRegister = [];
                if($scope.companyStores.length == 1) {
                    $scope.checkAllCashRegister = true;
                    $scope.notCashRegister = true;
                    for(let i = 0; i < $scope.redeemsHistoryData.length; i++){
                        if($scope.redeemsHistoryData[i].companyStoreCashRegisterName != null && $scope.companyStores[0] == $scope.redeemsHistoryData[i].companyStoreId){
                            let compStoreCashRegister = {};
                            compStoreCashRegister.value = $scope.redeemsHistoryData[i].companyStoreCashRegisterId;
                            compStoreCashRegister.name = $scope.redeemsHistoryData[i].companyStoreCashRegisterName;
                            const index = $scope.allCompanyStoreCashRegister.findIndex(x => x.value==$scope.redeemsHistoryData[i].companyStoreCashRegisterId)
                            if (index === -1){
                                $scope.allCompanyStoreCashRegister.push(compStoreCashRegister);
                            }
                        }
                    }
                    for(let i = 0; i < $scope.allCompanyStoreCashRegister.length; i++){
                        $scope.companyStoreCashRegisters.push($scope.allCompanyStoreCashRegister[i].value);
                    }
                    let showNotCashRegister = false;
                    const index = $scope.redeemsHistoryData.findIndex(x => x.companyStoreId==$scope.companyStores[0] && x.companyStoreCashRegisterId == null)
                    if (index !== -1 && $scope.allCompanyStoreCashRegister.length > 0){
                        showNotCashRegister = true;
                    }
                    $scope.showNotCashRegister = showNotCashRegister;
                }
            }
        })

        $scope.$watch('companyStoreCashRegisters', function (value){
            if (value) {
                if ($scope.companyStoreCashRegisters.length == $scope.allCompanyStoreCashRegister.length && $scope.notCashRegister == true) {
                    $scope.checkAllCashRegister = true;
                } else {
                    $scope.checkAllCashRegister = false;
                }
                if($scope.checkAllCashRegister == true) {
                    $scope.companyStoreCashRegisterName = 'Mind';
                } else {
                    $scope.companyStoreCashRegisterName = '';
                    for(let i = 0; i < value.length; i++) {
                        let result = $scope.allCompanyStoreCashRegister.filter(obj => {
                            return obj.value === value[i]
                        })
                        if(result.length == 1) {
                            if($scope.companyStoreCashRegisterName != '') {
                                $scope.companyStoreCashRegisterName += ', '
                            }
                            $scope.companyStoreCashRegisterName += result[0].name;
                        }
                    }
                    if($scope.notCashRegister == true) {
                        if($scope.companyStoreCashRegisterName != '') {
                            $scope.companyStoreCashRegisterName += ', '
                        }
                        $scope.companyStoreCashRegisterName += 'Nem pénztárnál történt'
                    }
                }
            }
        })

        $scope.$watch('loyaltyCards', function (value){
            if (value) {
                if ($scope.loyaltyCards.length == $scope.allLoyaltyCard.length) {
                    $scope.checkAllLoyaltyCard = true;
                } else {
                    $scope.checkAllLoyaltyCard = false;
                }
                if($scope.checkAllLoyaltyCard == true) {
                    $scope.loyaltyCardPromotionName = 'Mind';
                } else {
                    $scope.loyaltyCardPromotionName = '';
                    for(let i = 0; i < value.length; i++) {
                        let result = $scope.allLoyaltyCard.filter(obj => {
                            return obj.value === value[i]
                        })
                        if(result.length == 1) {
                            if($scope.loyaltyCardPromotionName != '') {
                                $scope.loyaltyCardPromotionName += ', '
                            }
                            $scope.loyaltyCardPromotionName += result[0].name;
                        }
                    }
                }
            }
        })

        $scope.notCashRegisterChanged = function() {
            if($scope.notCashRegister == true && $scope.companyStoreCashRegisters.length == $scope.allCompanyStoreCashRegister.length) {
                $scope.checkAllCashRegister = true;
            } else {
                $scope.checkAllCashRegister = false;
            }
            if($scope.checkAllCashRegister == true) {
                $scope.companyStoreCashRegisterName = 'Mind';
            } else {
                $scope.companyStoreCashRegisterName = '';
                for(let i = 0; i < $scope.companyStoreCashRegisters.length; i++) {
                    let result = $scope.allCompanyStoreCashRegister.filter(obj => {
                        return obj.value === $scope.companyStoreCashRegisters[i]
                    })
                    if(result.length == 1) {
                        if($scope.companyStoreCashRegisterName != '') {
                            $scope.companyStoreCashRegisterName += ', '
                        }
                        $scope.companyStoreCashRegisterName += result[0].name;
                    }
                }
                if($scope.notCashRegister == true) {
                    if($scope.companyStoreCashRegisterName != '') {
                        $scope.companyStoreCashRegisterName += ', '
                    }
                    $scope.companyStoreCashRegisterName += 'Nem pénztárnál történt'
                }
            }
            $scope.filterList();
        }
        
        $scope.setAllOptions = function (checkValue, array, model) {
            if (checkValue == true) {
                if (model == "companyStores") {
                    $scope.companyStores = [];
                    for(let i = 0; i < $scope.allCompanyStore.length; i++){
                        $scope.companyStores.push($scope.allCompanyStore[i].value);
                    }
                } else if (model == "loyaltyCards") {
                    $scope.loyaltyCards = [];
                    for(let i = 0; i < $scope.allLoyaltyCard.length; i++){
                        $scope.loyaltyCards.push($scope.allLoyaltyCard[i].value);
                    }
                } else if (model == "companyStoreCashRegisters") {
                    $scope.companyStoreCashRegisters = [];
                    $scope.notCashRegister = true;
                    for(let i = 0; i < $scope.allCompanyStoreCashRegister.length; i++){
                        $scope.companyStoreCashRegisters.push($scope.allCompanyStoreCashRegister[i].value);
                    }
                }
            } else {
                if (model == "companyStores") {
                    $scope.companyStores = [];
                } else if (model == "loyaltyCards") {
                    $scope.loyaltyCards = [];
                } else if (model == "companyStoreCashRegisters") {
                    $scope.companyStoreCashRegisters = [];
                    $scope.notCashRegister = false;
                }
            }
            $scope.filterList();
        }
        $scope.refreshList();
    })