import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, ControlContainer, FormControl, FormGroup } from '@angular/forms';
import { FormFieldValidatorService } from 'src/app/shared/services/form-field-validator.service';
import { SelectInput } from '../../interfaces/input';

@Component({
    selector: 'app-toggle-button',
    templateUrl: './toggle-button.component.html',
    styleUrls: ['./toggle-button.component.scss']
})
export class ToggleButtonComponent implements OnInit {

    form: FormGroup;
    @Input() inputName: string;
    @Input() options: SelectInput[];
    @Input() input: FormControl;

    constructor(
        private formFieldValidatorService: FormFieldValidatorService,
        private controlContainer: ControlContainer
    ) { }

    ngOnInit() {
        //this.form = <FormGroup>this.controlContainer.control;
    }

    //get required(): boolean { return this.formFieldValidatorService.isRequired(this.input); }

    //get input(): AbstractControl { return this.form.get(this.inputName)!; }

}
