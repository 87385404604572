<h1 mat-dialog-title *ngIf="data.title">{{data.title}}</h1>
<mat-dialog-content *ngIf="data.detail">
  {{data.detail}}
</mat-dialog-content>
<mat-dialog-content *ngIf="htmlContent">
	<span [innerHtml]="htmlContent"></span>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button *ngIf="data.cancel" (click)="onNoClick()">{{data.cancel}}</button>
  <button mat-button [mat-dialog-close]="'Confirmed'" cdkFocusInitial>{{data.confirm}}</button>
</mat-dialog-actions>
