import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { GetAllCompanyStoreDataForLCYDashboardStoreSelectorResponse } from 'src/app/shared/interfaces/stores';
import { SelectInput } from 'src/app/shared/interfaces/input';
import { HttpService } from 'src/app/shared/services/http.service';
import { CookieService } from 'ngx-cookie-service';
import {
    GetAllLoyaltyCardsListViewDataRequest,
    GetAllLoyaltyCardsListViewDataResponse,
    GetLCYAnalyticsDashboardStatsDataRequest,
    GetLCYAnalyticsDashboardStatsDataResponse,
    GetLCYAnalyticsUserStatsDataRequest,
    LCYAnalyticsDashboardStats,
    LCYAnalyticsUserStats
} from 'src/app/shared/interfaces/loyalty-cards';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DatePeriodService } from 'src/app/shared/services/date-period.service';
import { MultipleSelectService } from 'src/app/shared/services/multiple-select.service';
import { MultipleSelect } from 'src/app/shared/classes/multiple-select';
import { FormValidatorService } from 'src/app/shared/services/form-validator.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { MatRipple } from '@angular/material/core';

@Component({
    selector: 'app-loyalty-card-yalty-dashboard',
    templateUrl: './loyalty-card-yalty-dashboard.component.html',
    styleUrls: ['./loyalty-card-yalty-dashboard.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LoyaltyCardYaltyDashboardComponent implements OnInit {

    currentState = 'KIMUTATÁSOK';
    currentSubState = 'Dashboard';
    reqSubType = 'Premium';
    currentSubType: string | undefined;
    adminUserType = this.cookieService.get('adminUserType');

    filtersForm: FormGroup;
    sectionSelectorField: FormControl;
    activeStoreFilterField: FormControl;

    activeFilterSet = false;

    loyaltyCardTemplates: SelectInput[] = [];
    stores: SelectInput[] = [];
    getAllLoyaltyCardsListViewDataRequest: GetAllLoyaltyCardsListViewDataRequest = {
        getFilteredLCsByMarketingPackage: false
    }
    getLCYAnalyticsDashboardStatsDataRequest: GetLCYAnalyticsDashboardStatsDataRequest;
    getLCYAnalyticsUserStatsDataRequest: GetLCYAnalyticsUserStatsDataRequest;
    lCYDashboardStats: LCYAnalyticsDashboardStats;
    lCYAnalyticsUserStats: LCYAnalyticsUserStats[];
    filteredUserStats: LCYAnalyticsUserStats[] = [];
    allStores$ = this.httpService.get<GetAllCompanyStoreDataForLCYDashboardStoreSelectorResponse>('/company/getAllCompanyStoreDataForLCYDashboardStoreSelector');
    allLoyaltyCardTemplate$ = this.httpService.post<GetAllLoyaltyCardsListViewDataResponse>('/loyaltyCard/getAllLoyaltyCardStampListViewData', this.getAllLoyaltyCardsListViewDataRequest);
    allInitAPICall$ = forkJoin([this.allStores$, this.allLoyaltyCardTemplate$]);
    allStoresResult: GetAllCompanyStoreDataForLCYDashboardStoreSelectorResponse
    initApiCallFinished = false;
    showLCYDashboardStats = false;
    showLCYUserStats = false;
    dashboardDisplayable = true;
    totalNrOfActiveStores = 0;
    storeFilterDisplayValue: string | undefined;
    periodOptions = this.datePeriodService.periodOptions;
    multipleSelect = new MultipleSelect();
    resizeListContainer = new Subject<void>();
    storeListHeight: object;
    savedLoyaltyCardFilterStates: SectionStates = {
        dashboard: [],
        time: [],
        other: [],
        customer: []
    }
    savedStoreFilterStates: SectionStates = {
        dashboard: [],
        time: [],
        other: [],
        customer: []
    }
    globalLoyaltyCardFilter: (string | number)[];
    globalStoreFilter: (string | number)[];
    aggregationTypeOptions: SelectInput[] = [
        { value: 1, display: 'Napi' },
        { value: 2, display: 'Heti' },
        { value: 3, display: 'Havi' }];

    sectionSelectorOptions: SelectInput[] = [
        { value: 'dashboard', display: 'Dashboard' },
        { value: 'time', display: 'Időszaki grafikonok' },
        { value: 'other', display: 'Egyéb grafikonok' },
        { value: 'customer', display: 'Ügyfél lista' }];

    @ViewChild(MatRipple) ripple: MatRipple;
    waitingForAPIResult = false;
    stampOrRedeemHappenedEver = false;

    private subscriptions: Subscription[] = [];

    defaultFiltersFormValues = {
        loyaltyCardFilter: null,
        storeFilter: null
    };
    defaultSectionSelectorValue = 'dashboard'
    defaultActiveStoreFilterValue = true;
    defaultUserStatsFormValues = {
        period: 7,
        periodStartTimestamp: this.datePeriodService.getDatesFromSelectedPeriod(7).fromTimestamp,
        periodEndTimestamp: this.datePeriodService.getDatesFromSelectedPeriod(7).endTimestamp,
        aggregationType: 1
    };


    constructor(
        private httpService: HttpService,
        private dialogService: DialogService,
        private formBuilder: FormBuilder,
        private cookieService: CookieService,
        private datePeriodService: DatePeriodService,
        private multipleSelectService: MultipleSelectService,
        private formValidatorService: FormValidatorService,
    ) { }

    ngOnInit(): void {
        if(this.cookieService.get('sbcrtyp').includes('yalty_start_free')) {
            this.currentSubType = 'Yalty Start'
        } else if(this.cookieService.get('sbcrtyp').includes('yalty_start_paid')) {
            this.currentSubType = 'Yalty Start'
        } else if(this.cookieService.get('sbcrtyp').includes('basic')) {
            this.currentSubType = 'Design+'
        } else if(this.cookieService.get('sbcrtyp').includes('premium')) {
            this.currentSubType = 'Premium'
        } else if(this.cookieService.get('sbcrtyp').includes('custom')) {
            this.currentSubType = 'Egyedi'
        }
        this.filtersForm = this.formBuilder.group({
            loyaltyCardFilter: [this.defaultFiltersFormValues.loyaltyCardFilter, Validators.required],
            storeFilter: [this.defaultFiltersFormValues.storeFilter, Validators.required]
        });
        this.sectionSelectorField = new FormControl(this.defaultSectionSelectorValue);
        this.activeStoreFilterField = new FormControl(this.defaultActiveStoreFilterValue);
        this.subscriptions.push(
            this.allInitAPICall$.subscribe(results => {
                this.initApiCallFinished = true;
                this.allStoresResult = results[0];
                const loyaltyCards = results[1];
                this.updateStores(this.activeStoreFilter);
                this.storeFilterField.setValue(['all']);
                if(this.allStoresResult.companyStores != null && this.allStoresResult.companyStores.length != 0 && !this.stampOrRedeemHappenedEver){
                    for (let i = 0; i < this.allStoresResult.companyStores.length; i++) {
                        if(this.allStoresResult.companyStores[i].stampOrRedeemHappened){
                            this.stampOrRedeemHappenedEver = true;
                            break;
                        }
                    }
                }
                for (let i = 0; i < loyaltyCards.allLoyaltyCardsListViewData.length; i++) {
                    if (loyaltyCards.allLoyaltyCardsListViewData[i].loyaltyCardPromoStatus !== 0) {
                        const promoStatus = loyaltyCards.allLoyaltyCardsListViewData[i].loyaltyCardPromoStatus;
                        const promotionName = loyaltyCards.allLoyaltyCardsListViewData[i].promotionName
                        this.loyaltyCardTemplates.push({
                            value: loyaltyCards.allLoyaltyCardsListViewData[i].id,
                            display: this.getPromotionNameDisplayValue(promoStatus, promotionName)
                        });
                    }
                }
                this.loyaltyCardFilterField.setValue(['all']);
                setTimeout(() => {
                    // SetTimeout 0 needed because this.loyaltyCardFilterField.setValue(['all']) going to tick off all options
                    // but it takes time, because options not rendered yet and it's work async.
                    // In case of store it works, because it's a locally declared not a component from other files which using data bindings.   
                    //this.savedLoyaltyCardFilterStates[this.sectionSelector] = this.loyaltyCardFilter;
                    this.globalLoyaltyCardFilter = this.loyaltyCardFilter;
                    if(this.loyaltyCardTemplates.length > 0 && this.stores.length > 0 && (this.adminUserType=='' || this.adminUserType=='executive') && (this.currentSubType == 'Egyedi' || this.currentSubType == 'Premium')) {
                        this.update();
                    }
                }, 0);
                //this.savedStoreFilterStates[this.sectionSelector] = this.storeFilter;
                this.globalStoreFilter = this.storeFilter;
            }),
            this.storeFilterField.valueChanges.subscribe(() => {
                this.updateStoreFilters();
            }),
            this.activeStoreFilterField.valueChanges.subscribe(val => {
                this.updateStores(val);
                this.updateStoreFilters(true);
            }),
        );
    }

    getPromotionNameDisplayValue(promoStatus: number, promotionName: string): string {
        if (promoStatus == 1) return `${promotionName} (aktív)`
        else if (promoStatus == 2) return `${promotionName} (kifuttatott)`
        else if (promoStatus == 3) return `${promotionName} (deaktivált)`
        else return ''
    }

    isFullscreen = false;
    fullscreenImageSrc: string | null = null;

    openFullscreen(imageSrc: string): void {
      this.isFullscreen = true;
      this.fullscreenImageSrc = imageSrc;
    }

    closeFullscreen(): void {
      this.isFullscreen = false;
      this.fullscreenImageSrc = null;
    }

    updateStores(active: boolean) {
        const currentStores: SelectInput[] = [];
        for (let i = 0; i < this.allStoresResult.companyStores.length; i++) {
            let needToAdd = false;
            if (active) needToAdd = this.allStoresResult.companyStores[i].stampOrRedeemHappened;
            else needToAdd = true;
            if (needToAdd) currentStores.push({
                value: this.allStoresResult.companyStores[i].id,
                display: this.allStoresResult.companyStores[i].compStoreName
            });
        }
        this.stores = currentStores;
    }

    updateStoreFilters(activeFilterChanged?: boolean) {
        let all = false, deleted = false, virtual = false;
        const active = this.activeStoreFilter;
        for (let i = 0; i < this.storeFilter.length; i++) {
            if (all && deleted && virtual) break;
            if (this.storeFilter[i] === 'all') all = true;
            else if (this.storeFilter[i] === 'deleted') deleted = true;
            else if (this.storeFilter[i] === 'virtual' && !active) virtual = true;
        }
        const visibleStores = this.stores.map(store => store.value);
        if (all) visibleStores.unshift('all');
        let selectedStores;
        if (all && activeFilterChanged) {
            selectedStores = visibleStores;
        } else {
            const visibleSelectedStores = this.storeFilter.filter(store => visibleStores.includes(store));
            selectedStores = this.multipleSelect.getOptions(
                this.stores, visibleSelectedStores, all);
        }
        if (deleted) selectedStores.unshift('deleted');
        if (virtual) selectedStores.unshift('virtual');
        this.storeFilterField.setValue(selectedStores, { emitEvent: false });
        this.updateStoreDisplayValue(all, deleted, virtual);

    }

    updateStoreDisplayValue(all: boolean, deleted: boolean, virtual: boolean) {
        const stores = this.stores.filter(store => this.storeFilter.includes(store.value));
        const displayValues = stores.map(o => o.display);
        if (all) displayValues.push('all');
        if (virtual) displayValues.unshift('Online vagy telefonos rendelések (Nincs üzlet)');
        if (deleted) displayValues.unshift('Törölt üzletek');
        this.storeFilterDisplayValue = this.multipleSelectService.getDisplayValue(
            displayValues
        ) || 'Mind';
    }

    saveGlobalFilters() {
        this.globalLoyaltyCardFilter = this.loyaltyCardFilter;
        this.globalStoreFilter = this.storeFilter;
    }

    setNrOfActiveStores() {
        let n = this.stores.length + this.allStoresResult.deletedCompanyStoreIds.length;
        if (this.allStoresResult.wasStampOrRedeemWithoutStore && !this.activeStoreFilter) n++;
        this.totalNrOfActiveStores = n;
    }

    getDeletedOptionSelected(updateButtonPressed: boolean): boolean {
        if (updateButtonPressed) return this.storeFilter.includes('deleted');
        else return this.globalStoreFilter.includes('deleted');
    }

    getVirtualOptionSelected(updateButtonPressed: boolean): boolean {
        if (updateButtonPressed) return this.storeFilter.includes('virtual');
        else return this.globalStoreFilter.includes('virtual');
    }

    getStoresForRequestParams(deletedOptionSelected: boolean, virtualOptionSelected: boolean, updateButtonPressed: boolean): (string | number)[] {
        let stores = [];
        if (updateButtonPressed) stores = this.storeFilter.filter(store => store !== 'all' && store !== 'virtual' && store !== 'deleted');
        else  stores = this.globalStoreFilter.filter(store => store !== 'all' && store !== 'virtual' && store !== 'deleted');
        if (deletedOptionSelected) stores.push(...this.allStoresResult.deletedCompanyStoreIds);
        if (virtualOptionSelected) stores.push(0);
        return stores;
    }

    getLoyaltyCardsForRequestParams(updateButtonPressed: boolean): (string | number)[] {
        let loyaltyCards = [];
        if (updateButtonPressed) loyaltyCards = this.loyaltyCardFilter.filter(card => card !== 'all');
        else  loyaltyCards = this.globalLoyaltyCardFilter.filter(card => card !== 'all');
        return loyaltyCards;
    }

    update(updateButtonPressed: boolean = false) {
        if (!this.filtersForm.valid) {
            this.formValidatorService.validateAllFormFields(this.filtersForm);
        } else {
            this.getDashboardStats(updateButtonPressed);
        }
    }

    getDashboardStats(updateButtonPressed: boolean) {
        this.waitingForAPIResult = true;
        this.showLCYDashboardStats = false;
        const deletedOptionSelected = this.getDeletedOptionSelected(updateButtonPressed);
        const virtualOptionSelected = this.getVirtualOptionSelected(updateButtonPressed);
        const stores = this.getStoresForRequestParams(deletedOptionSelected, virtualOptionSelected, updateButtonPressed);
        if(this.loyaltyCardTemplates.length > 1 && this.getLoyaltyCardsForRequestParams(updateButtonPressed).length != this.loyaltyCardTemplates.length) {
            this.activeFilterSet = true;
        } else {
            this.activeFilterSet = false;
            if((this.storeFilter.filter(card => card === 'deleted')).length == 1) {
                this.activeFilterSet = true;
            } else if((this.storeFilter.filter(card => card === 'virtual')).length == 1) {
                this.activeFilterSet = true;
            } else if(this.stores.length > 1 && (this.storeFilter.filter(card => card === 'all')).length != 1) {
                this.activeFilterSet = true;
            }
        }
        this.getLCYAnalyticsDashboardStatsDataRequest = {
            loyaltyCardYaltyIds: this.getLoyaltyCardsForRequestParams(updateButtonPressed),
            companyStoreIds: stores,
            isRemovedStoreSelected: deletedOptionSelected,
            isVirtualStoreSelected: virtualOptionSelected,
            nrOfSelectedActiveStores: stores.length
        };
        this.httpService.post<GetLCYAnalyticsDashboardStatsDataResponse>('/partner/getLCYAnalyticsDashboardStatsData', this.getLCYAnalyticsDashboardStatsDataRequest)
            .subscribe((resp: GetLCYAnalyticsDashboardStatsDataResponse) => {
                if (resp.errorCode === 0) {
                    if (updateButtonPressed) this.saveGlobalFilters();
                    this.savedLoyaltyCardFilterStates['dashboard'] = this.globalLoyaltyCardFilter;
                    this.savedStoreFilterStates['dashboard'] = this.globalStoreFilter;
                    this.lCYDashboardStats = resp.metrics;
                    if(this.lCYDashboardStats.totalNrOfUsers != 0) {
                        this.dashboardDisplayable = true;
                    } else {
                        this.dashboardDisplayable = false;
                    }
                    this.setNrOfActiveStores();
                    this.waitingForAPIResult = false;
                    this.showLCYDashboardStats = true;
                } else {
                    this.waitingForAPIResult = false;
                    this.dialogService.openDialog('Szerver hiba. Kérjük próbálja meg később.',
                        '', 'Rendben', undefined, '450px');
                }
            });
    }

    get storeFilterField(): AbstractControl { return this.filtersForm.get('storeFilter')!; }
    get storeFilter(): (string | number)[] { return this.storeFilterField.value; }

    get loyaltyCardFilterField(): AbstractControl { return this.filtersForm.get('loyaltyCardFilter')!; }
    get loyaltyCardFilter(): (string | number)[] { return this.loyaltyCardFilterField.value; }

    get activeStoreFilter(): boolean { return this.activeStoreFilterField.value; }

    ngOnDestroy() {
        this.subscriptions.forEach((sub) => {
            sub.unsubscribe();
        })
    }

}

interface SectionStates {
    dashboard: (string | number)[];
    time: (string | number)[];
    other: (string | number)[];
    customer: (string | number)[];
}
