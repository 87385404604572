// @ts-nocheck

angular.module('yaltyApp')
    .component('thirdPartyLoyaltyCardDetailView', {
        templateUrl: 'js/components/thirdPartyLoyaltyCardDetailView.html?v=202405171545',
        controller: function () {
            var ctrl = this;
            ctrl.$onChanges = function (changes) {
                if (changes.cardBackgroundColor) {
                    if (ctrl.cardBackgroundColor == '#FFFFFF') {
                        ctrl.rewardStampedBackgroundColor = { 'background-color': '#00AEEF' }
                        ctrl.rewardUnstampedBackgroundColor = { 'background-color': '#DFDFDF' }
                        ctrl.rewardStampedIconColor = { 'fill': '#8B8B8E' }
                        ctrl.rewardUnstampedIconColor = { 'fill': '#FFFFFF' }
                    } else {
                        ctrl.rewardStampedBackgroundColor = {
                            'background-color': 'rgba(139, 139, 142, 0.7)'
                        }
                        ctrl.rewardUnstampedBackgroundColor = {
                            'background-color': 'rgba(139, 139, 142, 0.3)'
                        }
                        ctrl.rewardStampedIconColor = { 'fill': '#00AEEF' }
                        ctrl.rewardUnstampedIconColor = { 'fill': '#FFFFFF' }
                    }
                }
            }
        },
        bindings: {
            detailedDisplayBackgroundColor: '<',
            cardBackgroundColor: '<',
            cardLogoImgURL: '<',
            compNamePromo: '<',
            compNamePromoTextColor: '<',
            //stampTextPlaceholderColor: '<',
            //stampCurrentCountTextColor: '<',
            //floorNrOfStampsNeeded: '<',
            //sumNrOfStampsNeeded: '<',
            promotionNameTextColor: '<',
            promotionName: '<',
            //selectedTemplateImg: '<',
            //stampCropperData: '<',
            //unstampCropperData: '<',
            //stampedStyle: '<',
            //unstampedStyle: '<',
            //sizedStampStyle: '<',
            //sizedUnstampStyle: '<',
            //showStampIcon: '<',
            //stampIconType: '<',
            //isStampedColorEnabled: '<',
            //isUnstampedColorEnabled: '<',
            //stampIconStampedColor: '<',
            //stampIconUnstampedColor: '<',
            //stampConditionsDescr: '<',
            //reward1NrOfStampsNeeded: '<',
            //reward2NrOfStampsNeeded: '<',
            //reward3NrOfStampsNeeded: '<',
            //reward4NrOfStampsNeeded: '<',
            //reward5NrOfStampsNeeded: '<',
            //reward1RewardDescr: '<',
            //reward2RewardDescr: '<',
            //reward3RewardDescr: '<',
            //reward4RewardDescr: '<',
            //reward5RewardDescr: '<',
            //noOfRewards: '<',
            //isOnlineOrderAvailable: '<',
            //isPhoneOrderAvailable: '<',
            //onlineOrderInfoMessage: '<',
            //phoneOrderInfoMessage: '<',
            //maxNoOfDaysBetweenStampingText: '<',
            //cardValidityDurationText: '<',
            //promotionConditionsDescr: '<',
            nrOfCompanyStores: '<',
            detailPreviewIconsColor: '<',
            //baseStampStampedFillColor: '<',
            //isYLCPartnerRedeemRemarkEnabled: '<',
            showEntireContent: '<',
            isNumber: '&'
        }
    })/*.filter('removeDayPartFromValidity', () => {
        return (input) => {
            if (!input) return
            if (input.includes('év') || input.includes('hónap')) {
                return input.split('(')[0]
            } else return input
        }
    });*/