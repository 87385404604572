<div class="loyalty-card-yalty-time-series-stats-component default-srolling-design" style="max-width: 97%;">
    <div class="state-and-filters-container">
        <h2>{{ currentState }}
            <i class="fa fa-chevron-right" style="font-size:80%"></i> {{ currentSubState }}
            <i class="fa fa-info-circle tooltip" style="margin: 0; align-self: center;">
                <div class="yaltytiptextright" style="margin-bottom: 0px; width: 500px">
                    <p>Ez az oldal idősoros grafikonon mutatja meg egy tetszőlegesen kiválasztott időszakra az egyedi és új ügyfelek, az egyedi és új kártyák és a pecsételések/beváltások időbeni változását napi, heti vagy havi bontásban.
                    </p>
                </div>
            </i>
        </h2>
        <form [formGroup]="filtersForm" class="filters-form" [hidden]="((adminUserType=='' || adminUserType=='executive') && currentSubType != 'Egyedi' && currentSubType != 'Premium') || (loyaltyCardTemplates.length < 2 && stores.length < 2 && (allStoresResult == null || allStoresResult.wasStampOrRedeemInDeletedStore == false) && (allStoresResult == null || allStoresResult.wasStampOrRedeemWithoutStore == false))">
            <app-multiple-select-all inputName="loyaltyCardFilter" label="Hűségkártya" [objArray]="loyaltyCardTemplates"
                class="gray-1-theme" allOptionDisplayName="Mind">
            </app-multiple-select-all>
            <mat-form-field appearance="standard">
                <mat-label style="line-height: 1.2;">Üzlet</mat-label>
                <mat-select formControlName="storeFilter" multiple required>
                    <mat-select-trigger>{{storeFilterDisplayValue}}</mat-select-trigger>
                    <mat-checkbox color="primary" class="in-select option-separated"
                        [formControl]="activeStoreFilterField" matRipple>Csak aktív üzletek megjelenítése</mat-checkbox>
                    <mat-option [value]="'all'">Mind</mat-option>
                    <mat-option [value]="'deleted'"
                        *ngIf="allStoresResult && allStoresResult.wasStampOrRedeemInDeletedStore">Törölt üzletek
                    </mat-option>
                    <mat-option [value]="'virtual'"
                        *ngIf="allStoresResult && allStoresResult.wasStampOrRedeemWithoutStore && !activeStoreFilter">
                        Online vagy telefonos rendelések (Nincs üzlet)</mat-option>
                    <mat-option *ngFor="let store of stores" [value]="store.value">{{store.display}}</mat-option>
                </mat-select>
                <mat-error *ngIf="storeFilterField.hasError('required')">Mező kitöltése kötelező!</mat-error>
            </mat-form-field>
            <button mat-flat-button color="primary" (click)="update(true)" class="update-button" style="border-radius: 6px;"
                [disabled]="initApiCallFinished == false || waitingForAPIResult || (adminUserType=='' && currentSubType != 'Egyedi' && currentSubType != 'Premium') || (adminUserType!='' && adminUserType!='executive') || ((adminUserType=='' || adminUserType=='executive') && (loyaltyCardTemplates.length == 0 || stores.length == 0) && (currentSubType == 'Egyedi' || currentSubType == 'Premium'))">
                <span [hidden]="initApiCallFinished == false || waitingForAPIResult">Frissítés</span>
                <img *ngIf="initApiCallFinished == false || waitingForAPIResult" class="loading" src="imgs/loading_indicator.gif">
            </button>
        </form>
    </div>

    <div *ngIf="(adminUserType=='' || adminUserType=='executive') && currentSubType != 'Egyedi' && currentSubType != 'Premium'">
        <div class="stampOrRedeemHappenedEverErrorMessage" style="background-color:rgb(226,240,217); border-radius: 9px;">
            <img src="../imgs/warning_icon.svg" height="25">
            <p style="color:rgb(56,87,35);">Az adatok megtekintéséhez <b>{{reqSubType}}</b> előfizetés szükséges.</p>
        </div>
        <p style="max-width: 610px; width: 100%; color: darkgray;"> Önnek jelenleg <b>{{currentSubType}}</b> előfizetése van. <a href="partner/home/partner-subscription" style="color: #00AEEF; text-decoration: none;">Az előfizetések áttekintéséhez és módosításához kattintson ide.</a></p>
    </div>
    
    <div *ngIf="adminUserType!='' && adminUserType!='executive'" class="stat-container">
        <p>Adatbiztonsági okokból Yalty admin nem látja és nem kezelheti partnereink felhasználóinak adatait.</p>
    </div>

    <div *ngIf="initApiCallFinished == true && (adminUserType=='' || adminUserType=='executive') && !stampOrRedeemHappenedEver && (currentSubType == 'Egyedi' || currentSubType == 'Premium')">
        <div class="stampOrRedeemHappenedEverErrorMessage" style="background-color:rgb(226,240,217); border-radius: 9px;">
            <img src="../imgs/warning_icon.svg" height="25">
            <p style="color:rgb(56,87,35);">Még nincs aktív hűségkártyája vagy a kártyát még egy ügyfele sem használta.</p>
        </div>
        <p style="max-width: 610px; width: 100%; color: darkgray;">A Időszaki grafikonok akkor jelenik meg, ha legalább egy kártyán legalább egy pecsételés történt.</p>
    </div>
    <div *ngIf="initApiCallFinished == true && (adminUserType=='' || adminUserType=='executive') && (!stampOrRedeemHappenedEver || (currentSubType != 'Egyedi' && currentSubType != 'Premium'))">
        <img class="sampleViewOfDashboardOrTable" 
          src="../imgs/yalty_weblap_partneri_idoszaki_grafikonok.png" 
          alt="loyalty-cards-time-series-stats preview" 
          (click)="openFullscreen('../imgs/yalty_weblap_partneri_idoszaki_grafikonok.png')">

        <!-- Teljes képernyős nézet -->
        <div 
          *ngIf="isFullscreen" 
          class="fullscreen-model" 
          (click)="closeFullscreen()">
          <img [src]="fullscreenImageSrc" alt="Fullscreen preview">
        </div>
    </div>

    <div *ngIf="initApiCallFinished == true && (adminUserType=='' || adminUserType=='executive') && (currentSubType == 'Egyedi' || currentSubType == 'Premium') && stampOrRedeemHappenedEver">
        <form [formGroup]="timeSeriesForm" class="time-series-form" style="text-align: center; margin-top: 20px;">
            <app-select id="period" inputName="period" label="Időszak" [objArray]="periodOptions" class="gray-1-theme">
            </app-select>
            <app-date-picker id="periodStartTimestamp" inputName="periodStartTimestamp" label="Időszak -Tól" [disabled]="timeSeriesPeriod !== 6"
                *ngIf="timeSeriesPeriod !== 1">
            </app-date-picker>
            <app-date-picker id="periodEndTimestamp" inputName="periodEndTimestamp" label="Időszak -Ig" [disabled]="timeSeriesPeriod !== 6"
                *ngIf="timeSeriesPeriod !== 1">
            </app-date-picker>
            <app-select id="aggregationType" inputName="aggregationType" label="Vizsgált időszak egység" [objArray]="aggregationTypeOptions"
                class="gray-1-theme">
            </app-select>
            <button mat-flat-button color="primary" (click)="update(true)" class="update-button" style="border-radius: 6px;"
                [disabled]="waitingForAPIResult || initApiCallFinished != true || loyaltyCardTemplates.length == 0 || stores.length == 0">
                <span [hidden]="waitingForAPIResult">Frissítés</span>
                <img *ngIf="waitingForAPIResult" class="loading" src="imgs/loading_indicator.gif">
            </button>
        </form>
    </div>
    <div *ngIf="showLCYDashboardTimeSeriesStats && (adminUserType=='' || adminUserType=='executive') && (currentSubType == 'Egyedi' || currentSubType == 'Premium') && timeSeriesChartDisplayable == false" class="stat-container">
        <p>A beállított szűrések mellett nincs megjeleníthető adat.</p>
    </div>
    <div *ngIf="(currentSubType == 'Egyedi' || currentSubType == 'Premium') && (initApiCallFinished == false || waitingForAPIResult)" style="width: 100%; text-align: -webkit-center;">
        <mat-spinner [diameter]="40"></mat-spinner>
    </div>
    <div *ngIf="showLCYDashboardTimeSeriesStats && timeSeriesChartDisplayable == true && (adminUserType=='' || adminUserType=='executive') && (currentSubType == 'Egyedi' || currentSubType == 'Premium') && stampOrRedeemHappenedEver">
        <div>
            <form [formGroup]="displayedStatsForm" style="text-align: center; margin-top: 20px;">
                <div style="display: flex; justify-content: space-between;">
                    <mat-form-field appearance="standard" style="width: 250px; margin-left: 55px;">
                        <mat-label style="line-height: 1.2;">Megjelenített statisztikák</mat-label>
                        <mat-select formControlName="displayedStats" multiple>
                            <mat-optgroup label="Ügyfelek">
                                <mat-option [value]="'totalNrOfUsers'">Egyedi ügyfelek</mat-option>
                                <mat-option [value]="'nrOfNewUsers'">Új ügyfelek</mat-option>
                            </mat-optgroup>
                            <mat-optgroup label="Kártyák">
                                <mat-option [value]="'totalNrOfInstances'">Egyedi kártyák</mat-option>
                                <mat-option [value]="'nrOfNewInstances'">Új kártyák</mat-option>
                            </mat-optgroup>
                            <mat-optgroup label="Pecsételés/beváltások">
                                <mat-option [value]="'totalNrOfStamps'">Pecsételések</mat-option>
                                <mat-option [value]="'totalNrOfRedeems'">Beváltások</mat-option>
                            </mat-optgroup>
                        </mat-select>
                    </mat-form-field>
                    <div style="align-self: center; margin-right: 150px;">
                        <div style="display: flex; justify-content: space-between; width: 60px;">
                            <a (click)="setChartType('line')"><img [src]="chartType == 'line' ? '../../../imgs/line_area.png' : '../../../imgs/line_area_inactive.png'" style="width: 20px; height: 20px; cursor: pointer;"></a>
                            <a (click)="setChartType('bar')"><img [src]="chartType == 'bar' ? '../../../imgs/bar.png' : '../../../imgs/bar_inactive.png'" style="width: 20px; height: 20px; cursor: pointer;"></a>
                        </div>
                    </div>
                </div>
            </form>
    
            <div *ngIf="chartType == 'line'">
                <canvas baseChart height="350" [datasets]="timeSeriesChartDatasets" [labels]="timeSeriesChartLabels"
                    [options]="timeSeriesChartOptions" [colors]="timeSeriesChartColors" [legend]="timeSeriesChartLegend"
                    [chartType]="timeSeriesChartType" [plugins]="timeSeriesChartPlugins">
                </canvas>
            </div>
            <div *ngIf="chartType == 'bar'">
                <canvas baseChart height="350" [datasets]="timeSeriesChartDatasets" [labels]="timeSeriesChartLabels"
                    [options]="timeSeriesChartOptions" [colors]="timeSeriesChartColors" [legend]="timeSeriesChartLegend"
                    [chartType]="timeSeriesChartType2" [plugins]="timeSeriesChartPlugins">
                </canvas>
            </div>
        </div>
    
        <div style="margin-top: 50px;  margin-bottom: 50px;">
            <div style="display: flex; flex-direction: row; align-items: center; margin-bottom: 10px;">
                <h2 style="margin: 0px;">A grafikon alapján összesített számok a kiválasztott időszakra <br>{{displayPeriodStartTime}} - {{displayPeriodEndTime}}</h2>
                <i class="fa fa-info-circle tooltip" style="margin: 0px; margin-left: 10px;">
                    <div class="yaltytiptext" style="margin-bottom: -5px; width: 500px">
                        <p>Az ezekben a táblázatokban összesített számosságok a grafikon egyes számainak összesített számai - tehát az ügyfelekre és kártyákra vonatkozóan és a kiválasztott időszakra nézve ezek a számok NEM feltétlen az összes egyedi ügyfél számosságot adják meg.<br><br>
                            Például heti bontás esetén a grafikonon az egyes hetekre nézve az egyedi ügyfél és kártya számok jelennek meg, de mivel az egyes hetekben lehetnek ismétlődően előforduló ügyfelek és kártyák, így ezek felösszegzései ezekben a táblázatokban már nem (feltétlen) az egyedi számosságot adják meg az adott időszakra nézve.
                        </p>
                    </div>
                </i>
            </div>
            
            <div style="display: flex; justify-content: space-between; flex-wrap: wrap;">
                <div style="margin-right: 15px;">
                    <h3>Ügyfél számosság</h3>
                    <table>
                        <tbody>
                            <tr>    
                              <th>Egyedi ügyfelek</th>
                              <td>{{ timeSeriesDataSummary.totalNrOfUsers }}</td>
                            </tr>
                            <tr>
                                <th>Új ügyfelek</th>
                                <td>{{ timeSeriesDataSummary.nrOfNewUsers }}</td>
                            </tr>
                        </tbody>
                        </table>
                </div>
                <div style="margin-right: 15px;">
                    <h3>Kártya számosság</h3>
                    <table>
                        <tbody>
                            <tr>    
                                <th>Egyedi kártyák</th>
                                <td>{{ timeSeriesDataSummary.totalNrOfInstances }}</td>
                            </tr>
                            <tr>
                                <th>Új kártyák</th>
                                <td>{{ timeSeriesDataSummary.nrOfNewInstances }}</td>
                            </tr>
                        </tbody>
                        </table></div>
                <div>
                    <h3>Pecsételés/beváltás számosság</h3>
                    <table>
                        <tbody>
                            <tr>    
                                <th>Pecsételések</th>
                                <td>{{ timeSeriesDataSummary.totalNrOfStamps }}</td>
                            </tr>
                            <tr>
                                <th>Beváltások</th>
                                <td>{{ timeSeriesDataSummary.totalNrOfRedeems }}</td>
                            </tr>
                        </tbody>
                    </table></div>
            </div>
        </div>
        <div>
            <div style="display: flex; flex-direction: row; align-items: center; margin-bottom: 10px;">
                <h2 style="margin: 0px;">Egyedi számosságok a kiválasztott időszakra <br>{{displayPeriodStartTime}} - {{displayPeriodEndTime}}</h2>
                <i class="fa fa-info-circle tooltip" style="margin: 0px; margin-left: 10px;">
                    <div class="yaltytiptext" style="margin-bottom: -5px; width: 500px">
                        <p>Az ezekben a táblázatokban összesített számosságok a kiválasztott időszakra nézve a teljesen egyedi számosságokat mutatják - tehát itt az ügyfelek és kártyák számosságai is teljesen egyediek a teljes időszakra nézve, egy ügyfél és egy kártya csak egyszer számítódik a teljes időszakban.</p>
                    </div>
                </i>
            </div>
            
            <div style="display: flex; justify-content: space-between; flex-wrap: wrap;">
                <div style="margin-right: 15px;">
                    <h3>Ügyfél számosság</h3>
                    <table>
                        <tbody>
                            <tr>    
                              <th>Egyedi ügyfelek</th>
                              <td>{{ totalNrOfUsers }}</td>
                            </tr>
                        </tbody>
                        </table>
                </div>
                <div style="margin-right: 15px;">
                    <h3>Kártya számosság</h3>
                    <table>
                        <tbody>
                            <tr>    
                                <th>Egyedi kártyák</th>
                                <td>{{ totalNrOfInstances }}</td>
                            </tr>
                        </tbody>
                        </table></div>
                <div>
                    <h3>Pecsételés/beváltás számosság</h3>
                    <table>
                        <tbody>
                            <tr>    
                                <th>Pecsételések</th>
                                <td>{{ timeSeriesDataSummary.totalNrOfStamps }}</td>
                            </tr>
                            <tr>
                                <th>Beváltások</th>
                                <td>{{ timeSeriesDataSummary.totalNrOfRedeems }}</td>
                            </tr>
                        </tbody>
                    </table></div>
            </div>
        </div>
    </div>
</div>