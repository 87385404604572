<div class="google-maps-component">

    <div class="warning-message" *ngIf="invalidAddress">A Google térkép szolgáltatása nem találja a megadott Üzlet
        címet. Kérjük ellenőrizze a
        Üzlet
        címet. Ha a cím helyes, akkor a térképen manuálisan állítsa be cége vagy üzlete pontos
        lokációját a térképet arrébb húzva vagy a koordináták manuális megadásával.</div>
    <app-checkbox *ngIf="showMapSettingsSameAsCo" [inputName]="'mapSettingsSameAsCo'" class="gray-1-theme"
        [label]="'A térkép beállításai azonosak a cég térkép beállításaival'"></app-checkbox>
    <div class="map-container">
        <div id="map" [hidden]="!showMap"></div>
        <div class="google-maps-placeholder" *ngIf="!showMap"></div>
        <div class="map-settings-container">
            <div class="reset-map-to-default-container">
                <button *ngIf="mapManuallyDraged || (GPSCoordinatesAutoOrManual && mapInitialized)" mat-flat-button color="primary"
                    (click)="resetMapToStoreAddress()">
                    <span>Térkép visszaállítása az üzlet címére</span>
                </button>
            </div>
            <h4>Az üzlet koordinátái</h4>
            <app-radio-button class="gray-1-theme" [inputName]="'GPSCoordinatesAutoOrManual'"
                [objArray]="GPSCoordinatesAutoOrManualOptions"></app-radio-button>
            <div class="coordinate-container">
                <app-input-number [inputName]="'latitudeCoordinate'" [label]="'Szélességi koordináta'" [min]="0"
                    [disabled]="!GPSCoordinatesAutoOrManual" (blurEvent)="coordinatesChanged()" class="coordinate">
                </app-input-number>
                <app-input-number [inputName]="'longitudeCoordinate'" [label]="'Hosszúsági koordináta'"
                    [min]="0" [disabled]="!GPSCoordinatesAutoOrManual" (blurEvent)="coordinatesChanged()" class="coordinate">
                </app-input-number>
            </div>
        </div>
    </div>
</div>