<div class="sending-partner-email-component default-srolling-design">
    <div id="currentStateDiv">
        <h2>{{ currentState }}
            <i class="fa fa-chevron-right" style="font-size:80%"></i> {{ currentSubState }}
        </h2>
    </div>

    <form [formGroup]="companyIdsForm" id="companyIdsForm">
        <div class="tooltip-container">
            <app-input-textarea inputName="companyIds" label="Címzett partnerek azonosítói (::company::id)"
                patternErrorMsg="Csak a (0–9), szóköz (“ ”), és a vessző (“,”) elfogadottak." class="input-wide">
            </app-input-textarea>
            <i class="fa fa-info-circle tooltip">
                <span class="yaltytiptextbottom tooltip-bottom">
                    <p>
                        A címzett partner vagy partnerek azonosítói (::company::id) vesszővel elválasztva.
                    </p>
                </span>
            </i>
        </div>
        <button mat-flat-button color="primary" (click)="update()">
            <span [hidden]="waitingForCompanyDataAPIResult">Partnerlista frissítése</span>
            <img *ngIf="waitingForCompanyDataAPIResult" class="loading" src="imgs/loading_indicator.gif">
        </button>
    </form>

    <form [formGroup]="emailForm" id="emailForm">
        <app-select inputName="sender" label="Küldő" placeholder="Küldő email cím kiválasztása" [objArray]="senderOptions" class="gray-1-theme">
        </app-select>
        <div class="tooltip-container">
            <app-input-text inputName="subject" label="Tárgy" class="input-wide">
            </app-input-text>
            <i class="fa fa-info-circle tooltip">
                <span class="yaltytiptextbottom tooltip-bottom">
                    <p>
                        A tárgy legyen rövid. Mobilokon jellemzően 25-30 karakter jelenik meg a különböző kliensekben.
                        Egyéb cikkek alapján a (desktop) kliensek jellemzően 256 karakternél vágják a karakterhosszt.
                    </p>
                </span>
            </i>
        </div>
        <app-input-textarea inputName="body" label="Törzs" class="input-wide">
        </app-input-textarea>
        <app-select inputName="template" label="Email sablon" placeholder="Email sablon kiválasztása" [objArray]="templateOptions" class="gray-1-theme">
        </app-select>
    </form>

    <table class="default-design partner-list" #table>
        <tr *ngIf="companies?.length > 0">
            <th class="row-number-column very-narrow">#</th>
            <th class="narrow">id</th>
            <th class="normal">Cégnév</th>
            <th class="normal">Email</th>
            <th>Előnézet</th>
            <th class="wide">##PARTNERUSER_LAST_AND_FIRST_NAME##</th>
            <th class="normal">##PACKAGE_DISPLAY_NAME##
                <i class="tooltip2" style="margin: 0; margin-right: 10px;">
                    <ion-icon name="information-circle-outline"
                        style="position: absolute; margin-top: -5px; -ms-transform: translateY(-50%); transform: translateY(-50%);">
                    </ion-icon>
                    <div class="yaltytiptextleft" style="white-space: break-spaces; right: 7px; bottom: -48px;">
                        A partner aktuális előfizetésének megjelenítési neve. Lejárt előfizetés esetén a legutolsó
                        előfizetése megjelenítési nevét vesszük.
                    </div>
                </i>
            </th>
        </tr>
        <tr *ngFor="let company of companies; let i = index">
            <td class="index">{{(i + 1)}}</td>
            <td>{{company.id}}</td>
            <td *ngIf="company.compNamePromo">{{company.compNamePromo}}</td>
            <td *ngIf="!company.compNamePromo" class="invalid-company-data">Nem létező company id</td>
            <td *ngIf="company.userName">{{company.userName}}</td>
            <td *ngIf="!company.userName" class="invalid-company-data">A partnerhez nincs master felhasználó</td>
            <td>
                <button mat-flat-button color="primary" (click)="openPreview(company.id)">
                    <span>Előnézet</span>
                </button>
            </td>
            <td *ngIf="company.partnerUserLastAndFirstName">{{company.partnerUserLastAndFirstName}}</td>
            <td *ngIf="!company.partnerUserLastAndFirstName" class="invalid-company-data">A partnerhez nincs master
                felhasználó
            </td>
            <td *ngIf="company.packageDisplayName">{{company.packageDisplayName}}</td>
            <td *ngIf="!company.packageDisplayName" class="invalid-company-data">Regisztrált, de még nem fizetett elő
            </td>
        </tr>
    </table>

    <div class="send-button-container">
        <button mat-flat-button color="primary" (click)="sendEmails()" class="send-button">
            <span [hidden]="waitingForSendingEmailAPIResult">Küldés</span>
            <img *ngIf="waitingForSendingEmailAPIResult" class="loading" src="imgs/loading_indicator.gif">
        </button>
    </div>

    <div class="popup-overlay" *ngIf="showPreview">
        <div class="popup-window">
            <div class="popup-window_header">EMAIL ÜZENET</div>
            <div class="popup-window_content">
                <div class="sub-header">EMAIL ELŐNÉZET</div>
                <div class="popup-window_block">
                    Címzett: {{emailAddress}}
                </div>
                <div class="popup-window_block">
                    Tárgy: {{emailSubjectPreview}}
                </div>
                <app-partner-email-template [emailBody]="emailBodyPreview" [type]="template"></app-partner-email-template>
            </div>
            <div class="preview-ok-button-container">
                <button mat-raised-button color="primary" (click)="closePreviewWindow()" class="save-button">
                    <span>OK</span>
                </button>
            </div>
        </div>
    </div>
</div>