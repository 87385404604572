// @ts-nocheck

angular.module('yaltyApp')
    .controller('PartnerController', function ($scope, $mdDialog, $state, $location, $cookies, AuthService, GoogleAnalyticsService) {
        $scope.slogan = "Digital Customer Engagement";
        $scope.successEmailSent = false;
        $scope.sendButtonDisabled = false;
        $scope.active_sidebarmenu = { "float": "right", "font-weight": "bold" };
        $scope.active2_sidebarmenu = { "float": "right", "font-weight": "bold" };
        $scope.active3_sidebarmenu = { "float": "right", "font-weight": "bold", "color": "#4E525C" };
        if ($location.url() == '/partner/contact' || $location.url() == '/partnerHU/contactHU') {
            $scope.active_sidebarmenu = { "float": "right", "font-weight": "bold" };
            $scope.active2_sidebarmenu = { "float": "right", "font-weight": "bold", "color": "#4E525C" };
            $scope.active3_sidebarmenu = { "float": "right", "font-weight": "bold" };
            $scope.logoAndSlogan = { 'display': 'block' };
        } else if ($location.url() == '/partnerHU/partnerHomeHU') {
            $scope.active_sidebarmenu = { "float": "right", "font-weight": "bold" };
            $scope.active2_sidebarmenu = { "float": "right", "font-weight": "bold" };
            $scope.active3_sidebarmenu = { "float": "right", "font-weight": "bold", "color": "#4E525C" };
            $scope.logoAndSlogan = { 'display': 'none' };
        } else if ($location.url() == '/partner/login' || $location.url() == '/partnerHU/loginHU') {
            $scope.active_sidebarmenu = { "float": "right", "font-weight": "bold", "color": "#4E525C" };
            $scope.active2_sidebarmenu = { "float": "right", "font-weight": "bold" };
            $scope.active3_sidebarmenu = { "float": "right", "font-weight": "bold" };
            $scope.logoAndSlogan = { 'display': 'block' };
        }

        $scope.changeLang = function (lang) {
            if (lang == 'en') {
                if ($location.url() == '/partner/contact' || $location.url() == '/partner/login') {
                    return
                } else if ($location.url() == '/partnerHU/contactHU') {
                    $scope.goTo('contact');
                } else if ($location.url() == '/partnerHU/loginHU') {
                    $scope.goTo('login');
                } else {
                    $scope.goTo('login');
                }
            } else {
                if ($location.url() == '/partnerHU/contactHU' || $location.url() == '/partnerHU/loginHU' || $location.url() == '/partnerHU/partnerHomeHU') {
                    return
                } else if ($location.url() == '/partner/contact') {
                    $scope.goTo('contact_HU');
                } else if ($location.url() == '/partner/login') {
                    $scope.goTo('login_HU');
                } else {
                    $scope.goTo('login_HU');
                }
            }
        }

        $scope.setHULangPreffered = function () {
            $cookies.put('huLangPreffered', 'true');
        }

        $scope.forgottenPass = function (partnerData) {
            $scope.sendButtonDisabled = true;
            AuthService.forgottenPassword(partnerData).then(function (result) {
                if (result.data.errorCode == 0) {
                    $scope.successEmailSent = true;
                    GoogleAnalyticsService.sendDataToGA('/EN/partneri/elfelejtett_jelszó/jelszó_újra_beállítása_sikeres',
                        'EN Partner - Elfelejtett jelszó - Jelszó újra beállítása sikeres (szerver visszaigazolta)')
                } else if (result.data.errorCode == 2){
                    const alert = $mdDialog.alert()
                                .title('There is no user associated with the specified email address.')
                                .ok('Ok')
                            $mdDialog.show(alert);
                        $scope.sendButtonDisabled = false;
                }else {
                    $scope.sendButtonDisabled = false;
                    GoogleAnalyticsService.sendDataToGA('/EN/partneri/elfelejtett_jelszó/jelszó_újra_beállítása_sikertelen',
                        'EN Partner - Elfelejtett jelszó - Jelszó újra beállítása sikertelen (szerver hibajelzés)')
                }
            }, function (result) {
                GoogleAnalyticsService.sendDataToGA('/EN/partneri/elfelejtett_jelszó/jelszó_újra_beállítása_sikertelen',
                    'EN Partner - Elfelejtett jelszó - Jelszó újra beállítása sikertelen (szerver hibajelzés)')
            });
        }
        $scope.hamburgermenuclick = function () {
            document.getElementById("openSidebarMenu").checked = !document.getElementById("openSidebarMenu").checked;
        };
        $scope.hamburgermenuclose = function () {
            document.getElementById("openSidebarMenu").checked = false;
        };
        $scope.goTo = function (x) {
            if (x == 'contact') {
                $scope.logoAndSlogan = { 'display': 'block' };
                document.getElementById("openSidebarMenu").checked = false;
                $state.go('partner.contact', {});
                $scope.active_sidebarmenu = { "float": "right", "font-weight": "bold" };
                $scope.active2_sidebarmenu = { "float": "right", "font-weight": "bold", "color": "#4E525C" };
                $scope.active3_sidebarmenu = { "float": "right", "font-weight": "bold" };
            } else if (x == 'login') {
                $scope.logoAndSlogan = { 'display': 'block' };
                document.getElementById("openSidebarMenu").checked = false;
                $state.go('partner.login', {});
                $scope.active_sidebarmenu = { "float": "right", "font-weight": "bold", "color": "#4E525C" };
                $scope.active2_sidebarmenu = { "float": "right", "font-weight": "bold" };
                $scope.active3_sidebarmenu = { "float": "right", "font-weight": "bold" };
            } else if (x == 'contact_HU') {
                $scope.logoAndSlogan = { 'display': 'block' };
                document.getElementById("openSidebarMenu").checked = false;
                $state.go('partner_HU.contact', {});
                $scope.active_sidebarmenu = { "float": "right", "font-weight": "bold" };
                $scope.active2_sidebarmenu = { "float": "right", "font-weight": "bold", "color": "#4E525C" };
                $scope.active3_sidebarmenu = { "float": "right", "font-weight": "bold" };
            } else if (x == 'partnerHome_HU') {
                $scope.logoAndSlogan = { 'display': 'none' };
                document.getElementById("openSidebarMenu").checked = false;
                $state.go('partner_HU.partnerHome', {});
                $scope.active_sidebarmenu = { "float": "right", "font-weight": "bold" };
                $scope.active2_sidebarmenu = { "float": "right", "font-weight": "bold" };
                $scope.active3_sidebarmenu = { "float": "right", "font-weight": "bold", "color": "#4E525C" };
            } else if (x == 'login_HU') {
                $scope.logoAndSlogan = { 'display': 'block' };
                document.getElementById("openSidebarMenu").checked = false;
                $state.go('partner_HU.login', {});
                $scope.active_sidebarmenu = { "float": "right", "font-weight": "bold", "color": "#4E525C" };
                $scope.active2_sidebarmenu = { "float": "right", "font-weight": "bold" };
                $scope.active3_sidebarmenu = { "float": "right", "font-weight": "bold" };
            }
        };
    })
    
    .controller('PartnerController_HU', function ($scope, AuthService, GoogleAnalyticsService, $mdDialog) {
        $scope.slogan = "Digital Customer Engagement";
        $scope.successEmailSent = false;
        $scope.sendButtonDisabled = false;

        $scope.forgottenPass = function (partnerData) {
            $scope.sendButtonDisabled = true;
            AuthService.forgottenPassword(partnerData).then(function (result) {
                if (result.data.errorCode == 0) {
                    $scope.successEmailSent = true;
                    GoogleAnalyticsService.sendDataToGA('/HU/partneri/elfelejtett_jelszó/jelszó_újra_beállítása_sikeres',
                        'HU Partner - Elfelejtett jelszó - Jelszó újra beállítása sikeres (szerver visszaigazolta)')
                } else if (result.data.errorCode == 2){
                    const alert = $mdDialog.alert()
                                .title('A megadott email címhez felhasználó nem tartozik.')
                                .ok('Rendben')
                            $mdDialog.show(alert);
                        $scope.sendButtonDisabled = false;
                }else {
                    $scope.sendButtonDisabled = false;
                    GoogleAnalyticsService.sendDataToGA('/HU/partneri/elfelejtett_jelszó/jelszó_újra_beállítása_sikertelen',
                        'HU Partner - Elfelejtett jelszó - Jelszó újra beállítása sikertelen (szerver hibajelzés)')
                }
            }, function (result) {
                GoogleAnalyticsService.sendDataToGA('/HU/partneri/elfelejtett_jelszó/jelszó_újra_beállítása_sikertelen',
                    'HU Partner - Elfelejtett jelszó - Jelszó újra beállítása sikertelen (szerver hibajelzés)')
            });
        }
        $scope.hamburgermenuclick = function () {
            document.getElementById("openSidebarMenu").checked = !document.getElementById("openSidebarMenu").checked;
        };
        $scope.hamburgermenuclose = function () {
            document.getElementById("openSidebarMenu").checked = false;
        };
    })

    .controller('LoginController', function ($scope, AuthService, commonService, $state, $cookies, $stateParams, $rootScope, $mdDialog, $location) {
        commonService.setFloatingLabelInCaseOfAutoFill();
        $scope.currentStateName = $state.current.name;
        $scope.sendButtonDisabled = false;
        $scope.buttonText = "Belépés";
        $scope.errorText = $stateParams.errorText;
        $scope.user = {
            userName: '',
            password: ''
        };

        $scope.typePassword = true;
        $scope.togglePassword = function () {
            $scope.typePassword = !$scope.typePassword;
        };

        $scope.login = function () {
            $scope.sendButtonDisabled = true;
            $scope.buttonText = "";
            $scope.user.userAgent = AuthService.getUserAgent()
            AuthService.login($scope.user).then(function (result) {
                if ($scope.currentStateName != 'cashRegisterLogin' || result.hasPartnerActiveSub == true) {
                    if ($cookies.get('freePackageSubscriptionExpLimited') != null) {
                        $cookies.remove('freePackageSubscriptionExpLimited')
                    }
                    if ($cookies.get('freePackageSubscriptionExpLimited') != null) {
                        $cookies.remove('freePackageSubscriptionExpTimestamp')
                    }
                    $cookies.put('paymentFrequency', result.partnerData.paymentFrequency)
                    $cookies.put('isPaymentForTheNextPeriodRequired', result.partnerData.isPaymentForTheNextPeriodRequired)
                    $cookies.put('recurringPaymentEnabled', result.partnerData.recurringPaymentEnabled)
                    $cookies.put('firstName', result.partnerData.firstName);
                    $cookies.put('lastName', result.partnerData.lastName);
                    $cookies.put('nameTitle', result.partnerData.nameTitle);
                    $cookies.put('areRedeemPageFilterSettingsPredefined', result.partnerData.areRedeemPageFilterSettingsPredefined);
                    $cookies.put('defaultRedeemPageShowFilter', result.partnerData.redeemPageShowFilter);
                    $cookies.put('defaultRedeemPageCityFilter', result.partnerData.redeemPageCityFilter);
                    $cookies.put('defaultRedeemPageCompanyStoreFilter', result.partnerData.redeemPageCompanyStoreFilter);
                    $cookies.put('defaultRedeemPageCompanyStoreCashRegisterFilter', result.partnerData.redeemPageCompanyStoreCashRegisterFilter);
                    $scope.userName = $cookies.get('nameTitle') != 'null' ? $cookies.get('nameTitle') + ' ' + $cookies.get('lastName') + ' ' + $cookies.get('firstName') : $cookies.get('lastName') + ' ' + $cookies.get('firstName');
                    $cookies.put('userEmail', result.partnerData.partnerEmail);
                    $scope.userEmail = $cookies.get('userEmail');
                    $cookies.put('userCompany', result.partnerData.compNamePromo);
                    $scope.userCompany = $cookies.get('userCompany');
                    $cookies.put('compLogo', result.partnerData.compLogoImgURL);
                    $scope.compLogo = $cookies.get('compLogo');
                    $cookies.put('authorizationLevel', result.partnerData.authorizationLevel);
                    $scope.authorizationLevel = $cookies.get('authorizationLevel');
                    $cookies.put('sbcrtyp', result.partnerData.subData + "&" + result.hasPartnerActiveSub + "&" + result.partnerData.subscriptionEndTimestamp);
                    $cookies.put('additionalCompanyStoreNumber', result.partnerData.additionalCompanyStoreNumber);
                    $cookies.put('additionalPartnerUserNumber', result.partnerData.additionalPartnerUserNumber);
                    $cookies.put('additionalFreePartnerUserNumber', result.partnerData.additionalFreePartnerUserNumber);
                    $cookies.put('limitMonthlyPushMessageMaxNo', result.partnerData.limitMonthlyPushMessageMaxNo);
                    $cookies.put('limitMonthlyIndividualPushMessageMaxNo', result.partnerData.limitMonthlyIndividualPushMessageMaxNo);
                    $cookies.put('isMarketingPackagePageVisible', result.partnerData.isMarketingPackagePageVisible)
                    $cookies.put('areCustomDisplaySettingsForRedeemHandlingSheetEnabled', result.partnerData.areCustomDisplaySettingsForRedeemHandlingSheetEnabled);
                    $cookies.put('canSendIndividualPushMessages', result.partnerData.canSendIndividualPushMessages);
                    $cookies.put('isYaltyStartEnabled', result.partnerData.isYaltyStartPaidPackageEnabled);
                    $cookies.put('partnerType', result.partnerData.partnerType);
                    $rootScope.partnerSubscriptionType = $cookies.get('sbcrtyp');
                    $rootScope.hasPartnerActiveSub = result.hasPartnerActiveSub;
                    if (result.hasPartnerActiveSub == true) {
                        let userName = $scope.userName;
                        let subscriptionEndTimestamp = result.partnerData.subscriptionEndTimestamp;
                        let partnerSubscriptionType = $cookies.get('sbcrtyp') ? $cookies.get('sbcrtyp').split("&")[0] : "";
                        let checkToNow = new Date();
                        let freePackageSubscriptionExpTimestamp = new Date(subscriptionEndTimestamp)
                        // To calculate the time difference of two dates 
                        let Difference_In_Time = freePackageSubscriptionExpTimestamp.getTime() - checkToNow.getTime();

                        // To calculate the no. of days between two dates 
                        let differenceInDays = Math.ceil(Difference_In_Time / (1000 * 3600 * 24));
                        if (partnerSubscriptionType == "yalty_start_free" && subscriptionEndTimestamp != "9999-12-31T23:59:59.997Z") {
                            $mdDialog.show({
                                template:
                                    '<md-dialog>' +
                                    '  <md-dialog-content class="md-dialog-content">' +
                                    'Kedves {{userName}}!<br><br>' +
                                    //'Ne felejtse: bevezetési kedvezményünket, a teljesen működő alapcsomagunkra történő teljesen ingyenes előfizetést <span style="color: #ED7D31"><b>{{subscriptionEndTimestamp | date: \'yyyy. MMMM d\'}}-ig, még {{differenceInDays}} napig tudja aktiválni</b></span>.<br><br>' +
                                    //'Amennyiben ezen időn belül regisztrál <b>és aktiválja a hűségkártyáját</b>, alapcsomagunkat ezután már teljeskörűen és korlátlan ideig tovább használhatja ingyen. Fizetős csomagjainkat természetesen a kedvezményes időszak után is választhatja.<br><br>' +
                                    //'<b>Hozza létre és aktiválja hűségkártyáját {{subscriptionEndTimestamp | date: \'yyyy. MMMM d\'}}-ig a kedvezmény aktiválásához.</b>' +
                                    'Ne felejtse: az alapcsomagunk, a “Vírushelyzet - Üzletfejlesztési együttműködési csomagajánlatunk” ajánlatunk keretében történő költségmentes előfizetését ' +
                                    '<span style="color: #ED7D31"><b>{{subscriptionEndTimestamp | date: \'yyyy. MMMM d\'}}-ig, még {{differenceInDays}} napig tudja aktiválni</b></span>.<br><br>' +
                                    'Amennyiben ezen időn belül regisztrál <b>és aktiválja a hűségkártyáját</b>, alapcsomagunkat ezután már teljeskörűen és korlátlan ideig tovább használhatja költségmentesen. Fizetős csomagjainkat természetesen az ajánlati időszak után is választhatja.<br><br>' +
                                    '<b>Hozza létre és aktiválja hűségkártyáját {{subscriptionEndTimestamp | date: \'yyyy. MMMM d\'}}-ig az ajánlati kedvezmény aktiválásához.</b>' +
                                    '  </md-dialog-content>' +
                                    '  <md-dialog-actions>' +
                                    '    <md-button ng-click="closeDialog()" class="md-primary">' +
                                    '      Ok' +
                                    '    </md-button>' +
                                    '  </md-dialog-actions>' +
                                    '</md-dialog>',
                                locals: {
                                    userName: userName,
                                    subscriptionEndTimestamp: subscriptionEndTimestamp,
                                    differenceInDays: differenceInDays
                                },
                                controller: DialogCtrl
                            });
                            function DialogCtrl($scope, $mdDialog, userName, subscriptionEndTimestamp, differenceInDays) {
                                $scope.userName = userName;
                                $scope.subscriptionEndTimestamp = subscriptionEndTimestamp;
                                $scope.differenceInDays = differenceInDays;
                                $scope.closeDialog = function () {
                                    $mdDialog.hide();
                                    if ($scope.currentStateName != 'cashRegisterLogin') {
                                        if (result.partnerData.authorizationLevel !== "redeemer") {
                                            $state.go('home.welcome');
                                        } else {
                                            $state.go('home.cashing');
                                        }
                                    } else {
                                        $state.go('cashRegisterCashing');
                                    }
                                }
                            }
                        } else {
                            if ($scope.currentStateName != 'cashRegisterLogin') {
                                if (result.partnerData.authorizationLevel !== "redeemer") {
                                    if ($location.search().redirectToPayment == "true" && $location.search().emailAddress == result.partnerData.partnerEmail && result.partnerData.isPaymentForTheNextPeriodRequired == true) {
                                        location.href = "/partner/home/partner-subscription?prefillPaymentDetails=true";
                                    } else if ($location.search().redirectToMarketingPackage && $location.search().lcyid && $location.search().emailAddress == result.partnerData.partnerEmail) {
                                        $state.go('home.huseg3', { loyaltyCardId: $location.search().lcyid });
                                    } else {
                                        $state.go('home.welcome');
                                    }
                                } else {
                                    $state.go('home.cashing');
                                }
                            } else {
                                $state.go('cashRegisterCashing');
                            }
                        }
                    } else {
                        if (result.partnerData.authorizationLevel === "master") {
                            if (result.partnerData.isThereAnOpenTransactionWithinHalfAnHour == true) {
                                $cookies.put('brnpymntd', result.partnerData.barionPaymentId);
                                $cookies.put('trnsctncrtdtstmp', result.partnerData.transactionCreatedTimestamp);
                                $state.go('resumeOrCancelTransaction')
                            } else {
                                if (result.freePackageSubscriptionExpLimited != null) {
                                    $cookies.put('freePackageSubscriptionExpLimited', result.freePackageSubscriptionExpLimited);
                                }
                                if (result.freePackageSubscriptionExpTimestamp != null) {
                                    $cookies.put('freePackageSubscriptionExpTimestamp', result.freePackageSubscriptionExpTimestamp);
                                }
                                if ($location.search().redirectToPayment == "true" && $location.search().emailAddress == result.partnerData.partnerEmail && result.partnerData.isPaymentForTheNextPeriodRequired == true) {
                                    location.href = "/partner/home/partner-subscription?prefillPaymentDetails=true";
                                } else {
                                    $state.go('home.subscription');
                                }
                            }
                        } else {
                            $state.go('endOfSubscription');
                        }
                    }
                } else {
                    $scope.buttonText = "Belépés";
                    $scope.sendButtonDisabled = false;
                    if (result.partnerData.authorizationLevel === "master") {
                        if (result.partnerData.isThereAnOpenTransactionWithinHalfAnHour == true) {
                            const alert = $mdDialog.alert()
                                .title('Van folyamatban lévő, nyitott fizetési tranzakciója.')
                                .htmlContent('<p>Lehet, hogy véletlen lezárta a Barion fizetési oldalát? Rendszerünk adatai alapján Ön nemrég fizetési tranzakciót kezdeményezett, amit eddig azonban még nem fejezett be. A kezdeményezéstől számított fél órán belül még folytathatja a tranzakció véglegesítését.<br><br><b>Jelenleg a Yalty partneri oldal pénztárgépre optimalizált, menük nélküli megjelenítését használja, itt ez a funkció nem él. Nyissa meg a yalty.com oldalt egy másik eszközön, ott eléri ezt és minden más funkciót is.</b></p>')
                                .ok('Rendben')
                            $mdDialog.show(alert);
                        } else {
                            const alert = $mdDialog.alert()
                                .title('Fiókjához nincs jelenleg aktív előfizetés.')
                                .htmlContent('<p>A szolgáltatásunk használatához fizessen elő valamely Yalty csomagunkra.<br><br><b>Jelenleg a Yalty partneri oldal pénztárgépre optimalizált, menük nélküli megjelenítését használja, itt ez a funkció nem él. Nyissa meg a yalty.com oldalt egy másik eszközön, ott eléri ezt és minden más funkciót is.</b></p>')
                                .ok('Rendben')
                            $mdDialog.show(alert);
                        }
                    } else {
                        const alert = $mdDialog.alert()
                            .title('Fiókjához nincs jelenleg aktív előfizetés.')
                            .htmlContent('<p>Az előfizetést csak a fő (mester) fiók kezelője tudja módosítani, kezelni. Kérjük jelezze ezt cégénél a fő Yalty fiókkezelőnek. Ő majd a belépés után elő tud fizetni valamely Yalty csomagunkra.<br><br><b>Jelenleg a Yalty partneri oldal pénztárgépre optimalizált, menük nélküli megjelenítését használja, itt ez a funkció nem él. Nyissa meg a yalty.com oldalt egy másik eszközön, ott eléri ezt és minden más funkciót is.</b></p>')
                            .ok('Rendben')
                        $mdDialog.show(alert);
                    }
                }
            }, function (result) {
                $scope.buttonText = "Belépés";
                $scope.sendButtonDisabled = false;
                if (result == null) {
                    if ($scope.currentStateName == 'partner_HU.login' || $scope.currentStateName == 'cashRegisterLogin') {
                        $scope.errorText = 'Szerver hiba. Kérjük próbálja meg újra később.';
                    } else {
                        $scope.errorText = 'Server error. Please try again later.';
                    }
                } else if (result.errorCode == 3 && result.isMasterUser == true) {
                    if ($scope.currentStateName != 'cashRegisterLogin') {
                        $state.go('activateMasterUser');
                    } else {
                        const alert = $mdDialog.alert()
                            .title('Fiók aktiváció szükséges')
                            .textContent('A fiók (email cím) még nem aktivált. Aktiváláshoz kattintson az email címére kiküldött aktiválási linkre.')
                            .ok('Rendben')
                        $mdDialog.show(alert);
                    }
                } else if (result.errorCode == 1) {
                    if ($scope.currentStateName == 'partner_HU.login' || $scope.currentStateName == 'cashRegisterLogin') {
                        $scope.errorText = 'Sikertelen bejelentkezés. Helytelen email cím vagy jelszó.';
                    } else {
                        $scope.errorText = 'Authentication failed. Incorrect email address or password.';
                    }
                } else if (result.errorCode == 2) {
                    if ($scope.currentStateName == 'partner_HU.login' || $scope.currentStateName == 'cashRegisterLogin') {
                        $scope.errorText = 'Szerver hiba. Kérjük próbálja meg újra később.';
                    } else {
                        $scope.errorText = 'Server error. Please try again later.';
                    }
                } else if (result.errorCode == 3) {
                    if ($scope.currentStateName == 'partner_HU.login' || $scope.currentStateName == 'cashRegisterLogin') {
                        $scope.errorText = 'Felhasználói fiókod még nem aktivált.';
                    } else {
                        $scope.errorText = 'Your account is not activated yet.';
                    }
                }
                //alert(errMsg);
            });
        };
        if ($location.search().emailAddress) {
            /*if ($cookies.get('userEmail') != null && $location.search().emailAddress && $cookies.get('userEmail') != $location.search().emailAddress){
                AuthService.logout('noredirect');
            }*/
            $scope.user.userName = $location.search().emailAddress;
        }
    })

    .controller('SettingsCtrl', function ($scope, Map, $state, $http, $document, $location, $cookies, $rootScope, $mdDialog, $window, API_PREFIX, GoogleAnalyticsService, AuthService) {
        var url = $location.absUrl();
        if (url.includes("?preview")) {
            $scope.isRegistrationButtonDisabled = true;
        } else {
            $scope.isRegistrationButtonDisabled = false;
        }

        $scope.typePassword = true;
        $scope.togglePassword = function () {
            $scope.typePassword = !$scope.typePassword;
        };

        if ($location.url().includes('/service/register') || $location.url().includes('service/viewInfo')) {
            if ((navigator.userAgent.match(/iPhone/i)) || (navigator.userAgent.match(/iPod/i)) || (navigator.userAgent.match(/android/i))) {
                $('.content').css("margin-top", "0px");
                $('.yalty_top').css("display", "none");
            }
        }
        if ($location.url().includes('service/viewInfo')) {
            if ((navigator.userAgent.match(/iPhone/i)) || (navigator.userAgent.match(/iPod/i)) || (navigator.userAgent.match(/android/i))) {
                $('#yalty_top').css("display", "block");
            } else {
                $('#yalty_top').css("display", "none");
            }
        }
        $scope.visibleInAppPartnersMenu = true;
        $scope.appVisibilityDetailLevelOptions = [
            {
                id: 0, display: '0 - Semmi nem jelenik meg'
            },
            {
                id: 2, display: '2 - Egy top üzlet alapinfói'
            },
            {
                id: 3, display: '3 - Elérhetőségek'
            },
            {
                id: 4, display: '4 - Térkép'
            },
            {
                id: 5, display: '5 - További beváltási pontok'
            },
            {
                id: 6, display: '6 - Nyitvatartás (legmagasabb szint)'
            }
        ]
        $scope.companyStoreInfoAppVisibilityDetailLevel = 6;
        $scope.isCompLogoPushNotificationImgSame = true;
        $scope.isSameAddress = { "color": "black" };
        $scope.logoZoomSlider = 0;
        $scope.logoSliderMin = 0;
        $scope.logoSliderMax = 10;
        $scope.zoomSlider = 0;
        $scope.adminUserType = $cookies.get('adminUserType');
        var isValid = false;
        $scope.isAddress = true;
        $scope.emailSendingInProgress = false;
        $scope.isBillingAddrSame = false;
        $scope.sendButtonDisabled = false;
        $scope.facebookPrefix = "facebook.com/";
        $scope.FBURL = $scope.facebookPrefix;
        $rootScope.logoCropperData = {};
        $rootScope.companyCropperData = {};
        $rootScope.compLogoPushNotificationCropperData = {};
        $rootScope.isDataSheetModified = false;
        let googleMap;
        $scope.todaysDate = new Date();

        $scope.preventLineBreaks = function (keypress) {
            if (keypress.keyCode === 13) {
                keypress.preventDefault();
            }
        }

        $scope.resetDatePicker = function () {
            $scope.holidayOpeningTimesUntil = "";
            setTimeout(function () {
                $scope.holidayOpeningTimesUntil = null;
                }, 0)
            $scope.settingsForm.holidayOpeningTimesUntil.$setTouched();}

        $scope.setTimeToEndOfDay = function () {
                $scope.holidayOpeningTimesUntil = setDateToEndOfTheDay(moment($scope.holidayOpeningTimesUntil).toDate(), true);
        }

        function setDateToEndOfTheDay(date, isUTC) {
            date.setUTCHours(23)
            date.setUTCMinutes(59)
            date.setUTCSeconds(59)
            date.setUTCMilliseconds(999)
            if (isUTC) {
                date.setHours(date.getHours() - getHungarianOffset(date))
            }
            return date
        }

        function getLastSundayOfMarch(year) {
            var timezoneChangeDate = new Date(year, 2, 31);
            while (timezoneChangeDate.getDay() !== 0) {
                timezoneChangeDate.setDate(timezoneChangeDate.getDate() - 1);
            }
            return timezoneChangeDate.getDate()
        }

        function getLastSundayOfOctober(year) {
            var timezoneChangeDate = new Date(year, 9, 31);
            while (timezoneChangeDate.getDay() !== 0) {
                timezoneChangeDate.setDate(timezoneChangeDate.getDate() - 1);
            }
            return timezoneChangeDate.getDate();
        }

        function getHungarianOffset(date) {
            const timezoneChangeSummerMonth = 2
            const timezoneChangeSummerDay = getLastSundayOfMarch(date.getFullYear());
            const timezoneChangeAutumnMonth = 9
            const timezoneChangeAutumnDay = getLastSundayOfOctober(date.getFullYear());
            const resMonth = date.getMonth()
            const resDay = date.getDate()
            let offsetInHour
            if (resMonth > timezoneChangeSummerMonth && resMonth < timezoneChangeAutumnMonth) {
                offsetInHour = 2
            } else if (resMonth == timezoneChangeSummerMonth && resDay >= timezoneChangeSummerDay) {
                offsetInHour = 2
            } else if (resMonth == timezoneChangeAutumnMonth && resDay <= timezoneChangeAutumnDay) {
                offsetInHour = 2
            } else {
                offsetInHour = 1
            }
            return offsetInHour
        }

        if ($location.url() == '/partner/home/partner-registration-data') {
            $scope.showTranslation = true
            $scope.isEnglishTranslationEnabled = $scope.compData.isTranslation_EN_Enabled ? 'bekapcsolt' : 'kikapcsolt'
            $scope.translationEnabled = $scope.compData.isTranslation_EN_Enabled
            $scope.languages = [
                { id: 1, listName: 'Magyar (kötelező)', url: "../imgs/translation_flag_HU.png" },
                {
                    id: 2, listName: `Angol (${$scope.isEnglishTranslationEnabled})`,
                    url: "../imgs/translation_flag_EN.png",
                    windowTitleName: 'ANGOL', name: 'Angol', code: 'EN'
                }
            ]
            $scope.languageSelector = 1
            $scope['EN'] = {}
            $scope['EN'].compNamePromo = $scope.compData.compNamePromo_EN
            $scope['EN'].compDescription = $scope.compData.compDescription_EN
            $scope['EN'].webURL = $scope.compData.webURL_EN
            $scope['EN'].FBURL = $scope.facebookPrefix + $scope.compData.FBURL_EN
            $scope.translatedCompNamePromo = $scope.compData.compNamePromo_EN
            $scope.translatedCompDescription = $scope.compData.compDescription_EN
            $scope.translatedWebURL = $scope.compData.webURL_EN
            $scope.translatedFBURL = $scope.facebookPrefix + $scope.compData.FBURL_EN;
        }

        $scope.isSameCompLogoPushNotificationImg = function () {
            $rootScope.isDataSheetModified = true;
            if ($scope.isCompLogoPushNotificationImgSame == false) {
                $rootScope.compLogoPushNotificationCropperData = {};
            }
        }

        $scope.openTranslationWindow = (languageId, whereToOpen) => {
            if (languageId != 1) {
                const langIndex = $scope.languages.findIndex(item => item.id == languageId)
                $scope.windowTitleLang = $scope.languages[langIndex].windowTitleName
                $scope.langName = $scope.languages[langIndex].name
                $scope.lang = $scope.languages[langIndex].code
                $scope.translatedCompNamePromo = $scope[$scope.lang].compNamePromo
                $scope.translatedCompDescription = $scope[$scope.lang].compDescription
                $scope.translatedWebURL = $scope[$scope.lang].webURL
                $scope.translatedFBURL = $scope[$scope.lang].FBURL
                $scope.showTranslationWindow = true
                const langCode = $scope.languages[langIndex].code
                $scope.initValues = {}
                $scope.initValues[langCode] = {}
                $scope.initValues[langCode].enabled = $scope.translationEnabled
                if (!$scope.translationEnabled) {
                    $scope.translationEnabled = true
                    $scope.languageSelector = 2
                    $scope.modifyTranslationEnabled(true)
                }
                const divToScroll = whereToOpen ? whereToOpen : 'translationTable'
                $(`#${divToScroll}`).animate({ scrollTop: 0 }, "fast");
                /*setTimeout(function () {
                    document.getElementById(divToScroll).scrollIntoView()
                }, 0)*/
            }
        }

        $scope.modifyTranslationEnabled = (windowOpening) => {
            if ($scope.languageSelector == 2) {
                $scope.isEnglishTranslationEnabled = $scope.translationEnabled ? 'bekapcsolt' : 'kikapcsolt'
                $scope.languages[1].listName = `Angol (${$scope.isEnglishTranslationEnabled})`
            }
            const langName = $scope.langName.toLowerCase()
            if ($scope.translationEnabled) {
                let alert = $mdDialog.confirm()
                    .title(`A(z) ${langName} fordítás bekapcsolása esetén minden releváns, a felhasználók számára a mobil appokban megjelenő céges szövegeket, üzletek információit és a promóciók szövegeit is szükséges ${langName} nyelvre fordítani. A fordításban érintett oldalak:`)
                    .htmlContent(`
                    <ul class="md-title mdDialogList">
                        <li>Partneri adatok,</li>
                        <li>Üzletek (azon belül az egyes üzletek),</li>
                        <li>Hűségkártyáink (azon belül az egyes, még aktív hűségkártyák) és </li>
                    </ul>`)
                    .ok('Rendben')
                if (windowOpening) alert.cancel('Mégsem')
                $mdDialog.show(alert).catch(() => {
                    $scope.settingsForm.languageSelector.$valid = true
                    $scope.settingsForm.languageSelector.$invalid = false
                    $scope.settingsForm.languageSelector.$error = {}
                    $scope.translationEnabled = false
                    $scope.closeTranslationWindow()
                })
            } else {
                const alert = $mdDialog.alert()
                    .title(`A(z) ${langName} fordítás kikapcsolása esetén minden releváns, a felhasználók számára a mobil appokban megjelenő céges szövegek, üzletek információ és a promóciók szövege esetén kikapcsoljuk a(z) ${langName} nyelvű fordítást. Tehát minden, a fordításban érintett oldalon:`)
                    .htmlContent(`
                        <ul class="md-title mdDialogList">
                            <li>Partneri adatok,</li>
                            <li>Üzletek (azon belül az egyes üzletek),</li>
                            <li>Hűségkártyáink (azon belül az egyes hűségkártyák) és</li>
                        </ul>
                    `)
                    .ok('Rendben')
                $mdDialog.show(alert).then(() => {
                    $scope.settingsForm.languageSelector.$valid = true
                    $scope.settingsForm.languageSelector.$invalid = false
                    $scope.settingsForm.languageSelector.$error = {}
                })
            }
        }

        $scope.saveTranslation = () => {
            let isTranslationModified = false;
            if ($scope[$scope.lang].compNamePromo != $scope.translatedCompNamePromo) {
                $scope[$scope.lang].compNamePromo = $scope.translatedCompNamePromo
                isTranslationModified = true;
            }
            if ($scope[$scope.lang].compDescription != $scope.translatedCompDescription) {
                $scope[$scope.lang].compDescription = $scope.translatedCompDescription
                isTranslationModified = true;
            }
            if ($scope[$scope.lang].webURL != $scope.translatedWebURL) {
                $scope[$scope.lang].webURL = $scope.translatedWebURL
                isTranslationModified = true;
            }
            if ($scope[$scope.lang].FBURL != $scope.translatedFBURL) {
                $scope[$scope.lang].FBURL = $scope.translatedFBURL
                isTranslationModified = true;
            }
            $scope.languageSelector = 1
            $scope.sendButtonDisabled = false
            $rootScope.isDataSheetModified = true
            if ($scope.settingsForm.translatedCompNamePromo.$valid && $scope.settingsForm.translatedCompDescription.$valid &&
                $scope.settingsForm.translatedWebURL.$valid && $scope.settingsForm.translatedFBURL.$valid) {
                $scope.settingsForm.languageSelector.$valid = true
                $scope.settingsForm.languageSelector.$invalid = false
                $scope.settingsForm.languageSelector.$error = {}
            }
            $scope.showTranslationWindow = false
            if (isTranslationModified == true) {
                var confirm = $mdDialog.confirm()
                    .textContent('Figyelem: a fordítások módosításainak mentéséhez használja a Partneri adatok oldal Mentés gombját.')
                    .ok('Rendben')
                $mdDialog.show(confirm).then(function () {
                }, function () {
                });
            }
        }

        $scope.copyToTranslationField = (fieldName) => {
            if ($scope[fieldName]) {
                const translationFieldName = 'translated' + fieldName.charAt(0).toUpperCase() + fieldName.slice(1)
                $scope[translationFieldName] = $scope[fieldName]
            }
        }

        $scope.closeTranslationWindow = () => {
            $scope.languageSelector = 1
            $scope.showTranslationWindow = false
            $scope.translationEnabled = $scope.initValues['EN'].enabled
            $scope.isEnglishTranslationEnabled = $scope.translationEnabled ? 'bekapcsolt' : 'kikapcsolt'
            $scope.languages[$scope.languageSelector].listName = `Angol (${$scope.isEnglishTranslationEnabled})`
        }

        $scope.moveToInfo = function () {
            document.getElementById("moveToInfoPlace").scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
            var logData = {}
            logData.userIPAddress = 'Unknown'
            logData.pageName = 'Üdvözlő képernyő / További információk'
            logData.pageStandardName = 'c1_landing_more_info'
            logData.deviceType = 'Unknown'
            logData.deviceName = 'Unknown'
            logData.osType = "Unknown";
            logData.osVersion = 'Unknown'
            logData.browserUserAgent = 'Unknown'
            logData.browserVersion = 'Unknown';
            logData.browserName = 'Unknown';
            try {
                var md = new MobileDetect(window.navigator.userAgent);
                if (md.phone() != null) {
                    logData.deviceType = 'Mobile'
                    logData.deviceName = md.mobile();
                } else if (md.tablet() != null) {
                    logData.deviceType = 'Tablet'
                    logData.deviceName = md.tablet();
                } else {
                    logData.deviceType = 'Desktop'
                    logData.deviceName = 'Unknown'
                }
                if (logData.deviceName == "UnknownMobile" || logData.deviceName == "UnknownPhone" || logData.deviceName == "UnknownTablet") {
                    logData.deviceName = "Other";
                }
            } catch (err) {
                if (logData.etc == null) {
                    logData.etc = err;
                } else {
                    logData.etc += (" || " + err);
                }
            }
            try {
                var userAgent = window.navigator.userAgent,
                    platform = window.navigator.platform,
                    macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
                    windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
                    iosPlatforms = ['iPhone', 'iPad', 'iPod'];
                logData.browserUserAgent = userAgent.trunc(499);
                if (macosPlatforms.indexOf(platform) !== -1) {
                    logData.osType = 'Mac OS';
                } else if (iosPlatforms.indexOf(platform) !== -1) {
                    logData.osType = 'iOS';
                    if (md.version('iOS') != null) {
                        logData.osVersion = md.version('iOS');
                    }
                } else if (windowsPlatforms.indexOf(platform) !== -1) {
                    logData.osType = 'Windows';
                } else if (/Android/.test(userAgent)) {
                    logData.osType = 'Android';
                    if (md.version('Android') != null) {
                        logData.osVersion = md.version('Android');
                    }
                } else if (!os && /Linux/.test(platform)) {
                    logData.osType = 'Linux';
                }
                if (window.navigator.userAgent.indexOf("Windows NT 10.0") != -1) {
                    logData.osType = "Windows";
                    logData.osVersion = "10";
                }
                if (window.navigator.userAgent.indexOf("Windows NT 6.2") != -1) {
                    logData.osType = "Windows";
                    logData.osVersion = "8";
                }
                if (window.navigator.userAgent.indexOf("Windows NT 6.1") != -1) {
                    logData.osType = "Windows";
                    logData.osVersion = "7";
                }
                if (window.navigator.userAgent.indexOf("Windows NT 6.0") != -1) {
                    logData.osType = "Windows";
                    logData.osVersion = "Vista";
                }
                if (window.navigator.userAgent.indexOf("Windows NT 5.1") != -1) {
                    logData.osType = "Windows";
                    logData.osVersion = "XP";
                }
                if (window.navigator.userAgent.indexOf("Windows NT 5.0") != -1) {
                    logData.osType = "Windows";
                    logData.osVersion = "2000";
                }
            } catch (err) {
                if (logData.etc == null) {
                    logData.etc = err;
                } else {
                    logData.etc += (" || " + err);
                }
            }
            try {
                // Opera 8.0+
                var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

                // Firefox 1.0+
                var isFirefox = typeof InstallTrigger !== 'undefined';

                // Safari 3.0+ "[object HTMLElementConstructor]" 
                var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));

                // Internet Explorer 6-11
                var isIE = /*@cc_on!@*/false || !!document.documentMode;

                // Edge 20+
                var isEdge = !isIE && !!window.StyleMedia;

                // Chrome 1 - 71
                var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
                if (isOpera) {
                    logData.browserName = 'Opera';
                } else if (isFirefox) {
                    logData.browserName = 'Firefox';
                } else if (isIE) {
                    logData.browserName = 'IE';
                } else if (isEdge) {
                    logData.browserName = 'Edge';
                } else if (isSafari) {
                    logData.browserName = 'Safari';
                } else if (isChrome) {
                    logData.browserName = 'Chrome';
                } else {
                    logData.browserName = 'Unknown';
                }
            } catch (err) {
                if (logData.etc == null) {
                    logData.etc = err;
                } else {
                    logData.etc += (" || " + err);
                }
            }
            try {
                logData.devicePhysicalHeight = screen.height * window.devicePixelRatio;
                logData.devicePhysicalWidth = screen.width * window.devicePixelRatio;
                logData.deviceViewportHeight = document.documentElement.clientHeight;
                logData.deviceViewportWidth = document.documentElement.clientWidth;
            } catch (err) {
                if (logData.etc == null) {
                    logData.etc = err;
                } else {
                    logData.etc += (" || " + err);
                }
            }
            var url = $location.absUrl();
            if (!url.includes("?preview") && $cookies.get('isPartnerRegistered') == 'false') {
                $http.post(API_PREFIX.url + '/partner/companyRegPageSalesPotentialActivityLogs', logData)
            }

        }

        $("#moreInfo1").on("click", function () {
            var $el, $p, $ps, $up, totalHeight;
            totalHeight = 0
            $el = $(this);
            $p = $el.parent();
            $up = $p.parent();
            $ps = $up.find("li");
            $ps.each(function () {
                totalHeight += $(this).outerHeight();
            });
            $up
                .css({
                    "height": $up.outerHeight(),
                    "max-height": 9999
                })
                .animate({
                    "height": totalHeight + 40
                });
            $p.fadeOut();
            $("#moreInfoSeged1").parent().fadeOut();
            $scope.moreInfoButton1();
            return false;
        });

        $("#moreInfo2").on("click", function () {
            var $el, $p, $ps, $up, $video, totalHeight;
            totalHeight = 0
            $el = $(this);
            $p = $el.parent();
            $up = $p.parent();
            $ps = $up.find("li");
            $video = $up.find("video");
            $ps.each(function () {
                totalHeight += $(this).outerHeight();
            });
            $video.each(function () {
                totalHeight += $(this).outerHeight();
            });
            $up
                .css({
                    "height": $up.outerHeight(),
                    "max-height": 9999
                })
                .animate({
                    "height": totalHeight + 30
                });
            $p.fadeOut();
            $("#moreInfoSeged2").parent().fadeOut();
            $scope.moreInfoButton2();
            const video = document.getElementById("presentation_video");
            video.play();
            return false;
        });

        $("#moreInfo3").on("click", function () {
            var $el, $p, $ps, $up, totalHeight;
            totalHeight = 0
            $el = $(this);
            $p = $el.parent();
            $up = $p.parent();
            $ps = $up.find("li");
            $ps.each(function () {
                totalHeight += $(this).outerHeight();
            });
            $up
                .css({
                    "height": $up.outerHeight(),
                    "max-height": 9999
                })
                .animate({
                    "height": totalHeight + 20
                });
            $p.fadeOut();
            $("#moreInfoSeged3").parent().fadeOut();
            $scope.moreInfoButton3();
            return false;
        });

        $("#moreInfo4").on("click", function () {
            var $el, $p, $ps, $up, totalHeight;
            totalHeight = 0
            $el = $(this);
            $p = $el.parent();
            $up = $p.parent();
            $ps = $up.find("li");
            $ps.each(function () {
                totalHeight += $(this).outerHeight();
            });
            $up
                .css({
                    "height": $up.outerHeight(),
                    "max-height": 9999
                })
                .animate({
                    "height": totalHeight + 30
                });
            $p.fadeOut();
            $("#moreInfoSeged4").parent().fadeOut();
            $scope.moreInfoButton4();
            return false;
        });

        $("#moreInfo5").on("click", function () {
            var $el, $p, $ps, $up, totalHeight;
            totalHeight = 0
            $el = $(this);
            $p = $el.parent();
            $up = $p.parent();
            $ps = $up.find("p:not('.read-more')");
            $ps.each(function () {
                totalHeight += $(this).outerHeight();
            });
            $up
                .css({
                    "height": $up.outerHeight(),
                    "max-height": 9999
                })
                .animate({
                    "height": totalHeight + 80
                });
            $p.fadeOut();
            $("#moreInfoSeged5").parent().fadeOut();
            $scope.moreInfoButton5();
            return false;
        });

        $scope.scrollToTopOfWindow = function () {
            $('.main').animate({ scrollTop: $('#compNameTitle').offset().top }, "slow");
            var logData = {}
            logData.userIPAddress = 'Unknown'
            logData.pageName = 'Üdvözlő képernyő / További információk / 5. Back to top gomb'
            logData.pageStandardName = 'c1_landing_more_info_5_back_to_top'
            logData.deviceType = 'Unknown'
            logData.deviceName = 'Unknown'
            logData.osType = "Unknown";
            logData.osVersion = 'Unknown'
            logData.browserUserAgent = 'Unknown'
            logData.browserVersion = 'Unknown';
            logData.browserName = 'Unknown';
            try {
                var md = new MobileDetect(window.navigator.userAgent);
                if (md.phone() != null) {
                    logData.deviceType = 'Mobile'
                    logData.deviceName = md.mobile();
                } else if (md.tablet() != null) {
                    logData.deviceType = 'Tablet'
                    logData.deviceName = md.tablet();
                } else {
                    logData.deviceType = 'Desktop'
                    logData.deviceName = 'Unknown'
                }
                if (logData.deviceName == "UnknownMobile" || logData.deviceName == "UnknownPhone" || logData.deviceName == "UnknownTablet") {
                    logData.deviceName = "Other";
                }
            } catch (err) {
                if (logData.etc == null) {
                    logData.etc = err;
                } else {
                    logData.etc += (" || " + err);
                }
            }
            try {
                var userAgent = window.navigator.userAgent,
                    platform = window.navigator.platform,
                    macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
                    windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
                    iosPlatforms = ['iPhone', 'iPad', 'iPod'];
                logData.browserUserAgent = userAgent.trunc(499);
                if (macosPlatforms.indexOf(platform) !== -1) {
                    logData.osType = 'Mac OS';
                } else if (iosPlatforms.indexOf(platform) !== -1) {
                    logData.osType = 'iOS';
                    if (md.version('iOS') != null) {
                        logData.osVersion = md.version('iOS');
                    }
                } else if (windowsPlatforms.indexOf(platform) !== -1) {
                    logData.osType = 'Windows';
                } else if (/Android/.test(userAgent)) {
                    logData.osType = 'Android';
                    if (md.version('Android') != null) {
                        logData.osVersion = md.version('Android');
                    }
                } else if (!os && /Linux/.test(platform)) {
                    logData.osType = 'Linux';
                }
                if (window.navigator.userAgent.indexOf("Windows NT 10.0") != -1) {
                    logData.osType = "Windows";
                    logData.osVersion = "10";
                }
                if (window.navigator.userAgent.indexOf("Windows NT 6.2") != -1) {
                    logData.osType = "Windows";
                    logData.osVersion = "8";
                }
                if (window.navigator.userAgent.indexOf("Windows NT 6.1") != -1) {
                    logData.osType = "Windows";
                    logData.osVersion = "7";
                }
                if (window.navigator.userAgent.indexOf("Windows NT 6.0") != -1) {
                    logData.osType = "Windows";
                    logData.osVersion = "Vista";
                }
                if (window.navigator.userAgent.indexOf("Windows NT 5.1") != -1) {
                    logData.osType = "Windows";
                    logData.osVersion = "XP";
                }
                if (window.navigator.userAgent.indexOf("Windows NT 5.0") != -1) {
                    logData.osType = "Windows";
                    logData.osVersion = "2000";
                }
            } catch (err) {
                if (logData.etc == null) {
                    logData.etc = err;
                } else {
                    logData.etc += (" || " + err);
                }
            }
            try {
                // Opera 8.0+
                var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

                // Firefox 1.0+
                var isFirefox = typeof InstallTrigger !== 'undefined';

                // Safari 3.0+ "[object HTMLElementConstructor]" 
                var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));

                // Internet Explorer 6-11
                var isIE = /*@cc_on!@*/false || !!document.documentMode;

                // Edge 20+
                var isEdge = !isIE && !!window.StyleMedia;

                // Chrome 1 - 71
                var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
                if (isOpera) {
                    logData.browserName = 'Opera';
                } else if (isFirefox) {
                    logData.browserName = 'Firefox';
                } else if (isIE) {
                    logData.browserName = 'IE';
                } else if (isEdge) {
                    logData.browserName = 'Edge';
                } else if (isSafari) {
                    logData.browserName = 'Safari';
                } else if (isChrome) {
                    logData.browserName = 'Chrome';
                } else {
                    logData.browserName = 'Unknown';
                }
            } catch (err) {
                if (logData.etc == null) {
                    logData.etc = err;
                } else {
                    logData.etc += (" || " + err);
                }
            }
            try {
                logData.devicePhysicalHeight = screen.height * window.devicePixelRatio;
                logData.devicePhysicalWidth = screen.width * window.devicePixelRatio;
                logData.deviceViewportHeight = document.documentElement.clientHeight;
                logData.deviceViewportWidth = document.documentElement.clientWidth;
            } catch (err) {
                if (logData.etc == null) {
                    logData.etc = err;
                } else {
                    logData.etc += (" || " + err);
                }
            }
            var url = $location.absUrl();
            if (!url.includes("?preview") && $cookies.get('isPartnerRegistered') == 'false') {
                $http.post(API_PREFIX.url + '/partner/companyRegPageSalesPotentialActivityLogs', logData)
            }
        }

        $scope.moreInfoButton1 = function () {
            var logData = {}
            logData.userIPAddress = 'Unknown'
            logData.pageName = 'Üdvözlő képernyő / További információk / 1. Általános USPk'
            logData.pageStandardName = 'c1_landing_more_info_1_general_usps'
            logData.deviceType = 'Unknown'
            logData.deviceName = 'Unknown'
            logData.osType = "Unknown";
            logData.osVersion = 'Unknown'
            logData.browserUserAgent = 'Unknown'
            logData.browserVersion = 'Unknown';
            logData.browserName = 'Unknown';
            try {
                var md = new MobileDetect(window.navigator.userAgent);
                if (md.phone() != null) {
                    logData.deviceType = 'Mobile'
                    logData.deviceName = md.mobile();
                } else if (md.tablet() != null) {
                    logData.deviceType = 'Tablet'
                    logData.deviceName = md.tablet();
                } else {
                    logData.deviceType = 'Desktop'
                    logData.deviceName = 'Unknown'
                }
                if (logData.deviceName == "UnknownMobile" || logData.deviceName == "UnknownPhone" || logData.deviceName == "UnknownTablet") {
                    logData.deviceName = "Other";
                }
            } catch (err) {
                if (logData.etc == null) {
                    logData.etc = err;
                } else {
                    logData.etc += (" || " + err);
                }
            }
            try {
                var userAgent = window.navigator.userAgent,
                    platform = window.navigator.platform,
                    macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
                    windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
                    iosPlatforms = ['iPhone', 'iPad', 'iPod'];
                logData.browserUserAgent = userAgent.trunc(499);
                if (macosPlatforms.indexOf(platform) !== -1) {
                    logData.osType = 'Mac OS';
                } else if (iosPlatforms.indexOf(platform) !== -1) {
                    logData.osType = 'iOS';
                    if (md.version('iOS') != null) {
                        logData.osVersion = md.version('iOS');
                    }
                } else if (windowsPlatforms.indexOf(platform) !== -1) {
                    logData.osType = 'Windows';
                } else if (/Android/.test(userAgent)) {
                    logData.osType = 'Android';
                    if (md.version('Android') != null) {
                        logData.osVersion = md.version('Android');
                    }
                } else if (!os && /Linux/.test(platform)) {
                    logData.osType = 'Linux';
                }
                if (window.navigator.userAgent.indexOf("Windows NT 10.0") != -1) {
                    logData.osType = "Windows";
                    logData.osVersion = "10";
                }
                if (window.navigator.userAgent.indexOf("Windows NT 6.2") != -1) {
                    logData.osType = "Windows";
                    logData.osVersion = "8";
                }
                if (window.navigator.userAgent.indexOf("Windows NT 6.1") != -1) {
                    logData.osType = "Windows";
                    logData.osVersion = "7";
                }
                if (window.navigator.userAgent.indexOf("Windows NT 6.0") != -1) {
                    logData.osType = "Windows";
                    logData.osVersion = "Vista";
                }
                if (window.navigator.userAgent.indexOf("Windows NT 5.1") != -1) {
                    logData.osType = "Windows";
                    logData.osVersion = "XP";
                }
                if (window.navigator.userAgent.indexOf("Windows NT 5.0") != -1) {
                    logData.osType = "Windows";
                    logData.osVersion = "2000";
                }
            } catch (err) {
                if (logData.etc == null) {
                    logData.etc = err;
                } else {
                    logData.etc += (" || " + err);
                }
            }
            try {
                // Opera 8.0+
                var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

                // Firefox 1.0+
                var isFirefox = typeof InstallTrigger !== 'undefined';

                // Safari 3.0+ "[object HTMLElementConstructor]" 
                var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));

                // Internet Explorer 6-11
                var isIE = /*@cc_on!@*/false || !!document.documentMode;

                // Edge 20+
                var isEdge = !isIE && !!window.StyleMedia;

                // Chrome 1 - 71
                var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
                if (isOpera) {
                    logData.browserName = 'Opera';
                } else if (isFirefox) {
                    logData.browserName = 'Firefox';
                } else if (isIE) {
                    logData.browserName = 'IE';
                } else if (isEdge) {
                    logData.browserName = 'Edge';
                } else if (isSafari) {
                    logData.browserName = 'Safari';
                } else if (isChrome) {
                    logData.browserName = 'Chrome';
                } else {
                    logData.browserName = 'Unknown';
                }
            } catch (err) {
                if (logData.etc == null) {
                    logData.etc = err;
                } else {
                    logData.etc += (" || " + err);
                }
            }
            try {
                logData.devicePhysicalHeight = screen.height * window.devicePixelRatio;
                logData.devicePhysicalWidth = screen.width * window.devicePixelRatio;
                logData.deviceViewportHeight = document.documentElement.clientHeight;
                logData.deviceViewportWidth = document.documentElement.clientWidth;
            } catch (err) {
                if (logData.etc == null) {
                    logData.etc = err;
                } else {
                    logData.etc += (" || " + err);
                }
            }
            var url = $location.absUrl();
            if (!url.includes("?preview") && $cookies.get('isPartnerRegistered') == 'false') {
                $http.post(API_PREFIX.url + '/partner/companyRegPageSalesPotentialActivityLogs', logData)
            }
        }

        $scope.moreInfoButton2 = function () {
            var logData = {}
            logData.userIPAddress = 'Unknown'
            logData.pageName = 'Üdvözlő képernyő / További információk / 2. Beváltási folyamat'
            logData.pageStandardName = 'c1_landing_more_info_2_redeem_process'
            logData.deviceType = 'Unknown'
            logData.deviceName = 'Unknown'
            logData.osType = "Unknown";
            logData.osVersion = 'Unknown'
            logData.browserUserAgent = 'Unknown'
            logData.browserVersion = 'Unknown';
            logData.browserName = 'Unknown';
            try {
                var md = new MobileDetect(window.navigator.userAgent);
                if (md.phone() != null) {
                    logData.deviceType = 'Mobile'
                    logData.deviceName = md.mobile();
                } else if (md.tablet() != null) {
                    logData.deviceType = 'Tablet'
                    logData.deviceName = md.tablet();
                } else {
                    logData.deviceType = 'Desktop'
                    logData.deviceName = 'Unknown'
                }
                if (logData.deviceName == "UnknownMobile" || logData.deviceName == "UnknownPhone" || logData.deviceName == "UnknownTablet") {
                    logData.deviceName = "Other";
                }
            } catch (err) {
                if (logData.etc == null) {
                    logData.etc = err;
                } else {
                    logData.etc += (" || " + err);
                }
            }
            try {
                var userAgent = window.navigator.userAgent,
                    platform = window.navigator.platform,
                    macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
                    windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
                    iosPlatforms = ['iPhone', 'iPad', 'iPod'];
                logData.browserUserAgent = userAgent.trunc(499);
                if (macosPlatforms.indexOf(platform) !== -1) {
                    logData.osType = 'Mac OS';
                } else if (iosPlatforms.indexOf(platform) !== -1) {
                    logData.osType = 'iOS';
                    if (md.version('iOS') != null) {
                        logData.osVersion = md.version('iOS');
                    }
                } else if (windowsPlatforms.indexOf(platform) !== -1) {
                    logData.osType = 'Windows';
                } else if (/Android/.test(userAgent)) {
                    logData.osType = 'Android';
                    if (md.version('Android') != null) {
                        logData.osVersion = md.version('Android');
                    }
                } else if (!os && /Linux/.test(platform)) {
                    logData.osType = 'Linux';
                }
                if (window.navigator.userAgent.indexOf("Windows NT 10.0") != -1) {
                    logData.osType = "Windows";
                    logData.osVersion = "10";
                }
                if (window.navigator.userAgent.indexOf("Windows NT 6.2") != -1) {
                    logData.osType = "Windows";
                    logData.osVersion = "8";
                }
                if (window.navigator.userAgent.indexOf("Windows NT 6.1") != -1) {
                    logData.osType = "Windows";
                    logData.osVersion = "7";
                }
                if (window.navigator.userAgent.indexOf("Windows NT 6.0") != -1) {
                    logData.osType = "Windows";
                    logData.osVersion = "Vista";
                }
                if (window.navigator.userAgent.indexOf("Windows NT 5.1") != -1) {
                    logData.osType = "Windows";
                    logData.osVersion = "XP";
                }
                if (window.navigator.userAgent.indexOf("Windows NT 5.0") != -1) {
                    logData.osType = "Windows";
                    logData.osVersion = "2000";
                }
            } catch (err) {
                if (logData.etc == null) {
                    logData.etc = err;
                } else {
                    logData.etc += (" || " + err);
                }
            }
            try {
                // Opera 8.0+
                var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

                // Firefox 1.0+
                var isFirefox = typeof InstallTrigger !== 'undefined';

                // Safari 3.0+ "[object HTMLElementConstructor]" 
                var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));

                // Internet Explorer 6-11
                var isIE = /*@cc_on!@*/false || !!document.documentMode;

                // Edge 20+
                var isEdge = !isIE && !!window.StyleMedia;

                // Chrome 1 - 71
                var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
                if (isOpera) {
                    logData.browserName = 'Opera';
                } else if (isFirefox) {
                    logData.browserName = 'Firefox';
                } else if (isIE) {
                    logData.browserName = 'IE';
                } else if (isEdge) {
                    logData.browserName = 'Edge';
                } else if (isSafari) {
                    logData.browserName = 'Safari';
                } else if (isChrome) {
                    logData.browserName = 'Chrome';
                } else {
                    logData.browserName = 'Unknown';
                }
            } catch (err) {
                if (logData.etc == null) {
                    logData.etc = err;
                } else {
                    logData.etc += (" || " + err);
                }
            }
            try {
                logData.devicePhysicalHeight = screen.height * window.devicePixelRatio;
                logData.devicePhysicalWidth = screen.width * window.devicePixelRatio;
                logData.deviceViewportHeight = document.documentElement.clientHeight;
                logData.deviceViewportWidth = document.documentElement.clientWidth;
            } catch (err) {
                if (logData.etc == null) {
                    logData.etc = err;
                } else {
                    logData.etc += (" || " + err);
                }
            }
            var url = $location.absUrl();
            if (!url.includes("?preview") && $cookies.get('isPartnerRegistered') == 'false') {
                $http.post(API_PREFIX.url + '/partner/companyRegPageSalesPotentialActivityLogs', logData)
            }
        }

        $scope.moreInfoButton3 = function () {
            var logData = {}
            logData.userIPAddress = 'Unknown'
            logData.pageName = 'Üdvözlő képernyő / További információk / 3. Partneri oldalak'
            logData.pageStandardName = 'c1_landing_more_info_3_partner_pages'
            logData.deviceType = 'Unknown'
            logData.deviceName = 'Unknown'
            logData.osType = "Unknown";
            logData.osVersion = 'Unknown'
            logData.browserUserAgent = 'Unknown'
            logData.browserVersion = 'Unknown';
            logData.browserName = 'Unknown';
            try {
                var md = new MobileDetect(window.navigator.userAgent);
                if (md.phone() != null) {
                    logData.deviceType = 'Mobile'
                    logData.deviceName = md.mobile();
                } else if (md.tablet() != null) {
                    logData.deviceType = 'Tablet'
                    logData.deviceName = md.tablet();
                } else {
                    logData.deviceType = 'Desktop'
                    logData.deviceName = 'Unknown'
                }
                if (logData.deviceName == "UnknownMobile" || logData.deviceName == "UnknownPhone" || logData.deviceName == "UnknownTablet") {
                    logData.deviceName = "Other";
                }
            } catch (err) {
                if (logData.etc == null) {
                    logData.etc = err;
                } else {
                    logData.etc += (" || " + err);
                }
            }
            try {
                var userAgent = window.navigator.userAgent,
                    platform = window.navigator.platform,
                    macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
                    windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
                    iosPlatforms = ['iPhone', 'iPad', 'iPod'];
                logData.browserUserAgent = userAgent.trunc(499);
                if (macosPlatforms.indexOf(platform) !== -1) {
                    logData.osType = 'Mac OS';
                } else if (iosPlatforms.indexOf(platform) !== -1) {
                    logData.osType = 'iOS';
                    if (md.version('iOS') != null) {
                        logData.osVersion = md.version('iOS');
                    }
                } else if (windowsPlatforms.indexOf(platform) !== -1) {
                    logData.osType = 'Windows';
                } else if (/Android/.test(userAgent)) {
                    logData.osType = 'Android';
                    if (md.version('Android') != null) {
                        logData.osVersion = md.version('Android');
                    }
                } else if (!os && /Linux/.test(platform)) {
                    logData.osType = 'Linux';
                }
                if (window.navigator.userAgent.indexOf("Windows NT 10.0") != -1) {
                    logData.osType = "Windows";
                    logData.osVersion = "10";
                }
                if (window.navigator.userAgent.indexOf("Windows NT 6.2") != -1) {
                    logData.osType = "Windows";
                    logData.osVersion = "8";
                }
                if (window.navigator.userAgent.indexOf("Windows NT 6.1") != -1) {
                    logData.osType = "Windows";
                    logData.osVersion = "7";
                }
                if (window.navigator.userAgent.indexOf("Windows NT 6.0") != -1) {
                    logData.osType = "Windows";
                    logData.osVersion = "Vista";
                }
                if (window.navigator.userAgent.indexOf("Windows NT 5.1") != -1) {
                    logData.osType = "Windows";
                    logData.osVersion = "XP";
                }
                if (window.navigator.userAgent.indexOf("Windows NT 5.0") != -1) {
                    logData.osType = "Windows";
                    logData.osVersion = "2000";
                }
            } catch (err) {
                if (logData.etc == null) {
                    logData.etc = err;
                } else {
                    logData.etc += (" || " + err);
                }
            }
            try {
                // Opera 8.0+
                var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

                // Firefox 1.0+
                var isFirefox = typeof InstallTrigger !== 'undefined';

                // Safari 3.0+ "[object HTMLElementConstructor]" 
                var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));

                // Internet Explorer 6-11
                var isIE = /*@cc_on!@*/false || !!document.documentMode;

                // Edge 20+
                var isEdge = !isIE && !!window.StyleMedia;

                // Chrome 1 - 71
                var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
                if (isOpera) {
                    logData.browserName = 'Opera';
                } else if (isFirefox) {
                    logData.browserName = 'Firefox';
                } else if (isIE) {
                    logData.browserName = 'IE';
                } else if (isEdge) {
                    logData.browserName = 'Edge';
                } else if (isSafari) {
                    logData.browserName = 'Safari';
                } else if (isChrome) {
                    logData.browserName = 'Chrome';
                } else {
                    logData.browserName = 'Unknown';
                }
            } catch (err) {
                if (logData.etc == null) {
                    logData.etc = err;
                } else {
                    logData.etc += (" || " + err);
                }
            }
            try {
                logData.devicePhysicalHeight = screen.height * window.devicePixelRatio;
                logData.devicePhysicalWidth = screen.width * window.devicePixelRatio;
                logData.deviceViewportHeight = document.documentElement.clientHeight;
                logData.deviceViewportWidth = document.documentElement.clientWidth;
            } catch (err) {
                if (logData.etc == null) {
                    logData.etc = err;
                } else {
                    logData.etc += (" || " + err);
                }
            }
            var url = $location.absUrl();
            if (!url.includes("?preview") && $cookies.get('isPartnerRegistered') == 'false') {
                $http.post(API_PREFIX.url + '/partner/companyRegPageSalesPotentialActivityLogs', logData)
            }
        }

        $scope.moreInfoButton4 = function () {
            var logData = {}
            logData.userIPAddress = 'Unknown'
            logData.pageName = 'Üdvözlő képernyő / További információk / 4. Yalty app'
            logData.pageStandardName = 'c1_landing_more_info_4_yalty_app'
            logData.deviceType = 'Unknown'
            logData.deviceName = 'Unknown'
            logData.osType = "Unknown";
            logData.osVersion = 'Unknown'
            logData.browserUserAgent = 'Unknown'
            logData.browserVersion = 'Unknown';
            logData.browserName = 'Unknown';
            try {
                var md = new MobileDetect(window.navigator.userAgent);
                if (md.phone() != null) {
                    logData.deviceType = 'Mobile'
                    logData.deviceName = md.mobile();
                } else if (md.tablet() != null) {
                    logData.deviceType = 'Tablet'
                    logData.deviceName = md.tablet();
                } else {
                    logData.deviceType = 'Desktop'
                    logData.deviceName = 'Unknown'
                }
                if (logData.deviceName == "UnknownMobile" || logData.deviceName == "UnknownPhone" || logData.deviceName == "UnknownTablet") {
                    logData.deviceName = "Other";
                }
            } catch (err) {
                if (logData.etc == null) {
                    logData.etc = err;
                } else {
                    logData.etc += (" || " + err);
                }
            }
            try {
                var userAgent = window.navigator.userAgent,
                    platform = window.navigator.platform,
                    macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
                    windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
                    iosPlatforms = ['iPhone', 'iPad', 'iPod'];
                logData.browserUserAgent = userAgent.trunc(499);
                if (macosPlatforms.indexOf(platform) !== -1) {
                    logData.osType = 'Mac OS';
                } else if (iosPlatforms.indexOf(platform) !== -1) {
                    logData.osType = 'iOS';
                    if (md.version('iOS') != null) {
                        logData.osVersion = md.version('iOS');
                    }
                } else if (windowsPlatforms.indexOf(platform) !== -1) {
                    logData.osType = 'Windows';
                } else if (/Android/.test(userAgent)) {
                    logData.osType = 'Android';
                    if (md.version('Android') != null) {
                        logData.osVersion = md.version('Android');
                    }
                } else if (!os && /Linux/.test(platform)) {
                    logData.osType = 'Linux';
                }
                if (window.navigator.userAgent.indexOf("Windows NT 10.0") != -1) {
                    logData.osType = "Windows";
                    logData.osVersion = "10";
                }
                if (window.navigator.userAgent.indexOf("Windows NT 6.2") != -1) {
                    logData.osType = "Windows";
                    logData.osVersion = "8";
                }
                if (window.navigator.userAgent.indexOf("Windows NT 6.1") != -1) {
                    logData.osType = "Windows";
                    logData.osVersion = "7";
                }
                if (window.navigator.userAgent.indexOf("Windows NT 6.0") != -1) {
                    logData.osType = "Windows";
                    logData.osVersion = "Vista";
                }
                if (window.navigator.userAgent.indexOf("Windows NT 5.1") != -1) {
                    logData.osType = "Windows";
                    logData.osVersion = "XP";
                }
                if (window.navigator.userAgent.indexOf("Windows NT 5.0") != -1) {
                    logData.osType = "Windows";
                    logData.osVersion = "2000";
                }
            } catch (err) {
                if (logData.etc == null) {
                    logData.etc = err;
                } else {
                    logData.etc += (" || " + err);
                }
            }
            try {
                // Opera 8.0+
                var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

                // Firefox 1.0+
                var isFirefox = typeof InstallTrigger !== 'undefined';

                // Safari 3.0+ "[object HTMLElementConstructor]" 
                var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));

                // Internet Explorer 6-11
                var isIE = /*@cc_on!@*/false || !!document.documentMode;

                // Edge 20+
                var isEdge = !isIE && !!window.StyleMedia;

                // Chrome 1 - 71
                var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
                if (isOpera) {
                    logData.browserName = 'Opera';
                } else if (isFirefox) {
                    logData.browserName = 'Firefox';
                } else if (isIE) {
                    logData.browserName = 'IE';
                } else if (isEdge) {
                    logData.browserName = 'Edge';
                } else if (isSafari) {
                    logData.browserName = 'Safari';
                } else if (isChrome) {
                    logData.browserName = 'Chrome';
                } else {
                    logData.browserName = 'Unknown';
                }
            } catch (err) {
                if (logData.etc == null) {
                    logData.etc = err;
                } else {
                    logData.etc += (" || " + err);
                }
            }
            try {
                logData.devicePhysicalHeight = screen.height * window.devicePixelRatio;
                logData.devicePhysicalWidth = screen.width * window.devicePixelRatio;
                logData.deviceViewportHeight = document.documentElement.clientHeight;
                logData.deviceViewportWidth = document.documentElement.clientWidth;
            } catch (err) {
                if (logData.etc == null) {
                    logData.etc = err;
                } else {
                    logData.etc += (" || " + err);
                }
            }
            var url = $location.absUrl();
            if (!url.includes("?preview") && $cookies.get('isPartnerRegistered') == 'false') {
                $http.post(API_PREFIX.url + '/partner/companyRegPageSalesPotentialActivityLogs', logData)
            }
        }

        $scope.moreInfoButton5 = function () {
            var logData = {}
            logData.userIPAddress = 'Unknown'
            logData.pageName = 'Üdvözlő képernyő / További információk / 6. Rólunk'
            logData.pageStandardName = 'c1_landing_more_info_6_about_us'
            logData.deviceType = 'Unknown'
            logData.deviceName = 'Unknown'
            logData.osType = "Unknown";
            logData.osVersion = 'Unknown'
            logData.browserUserAgent = 'Unknown'
            logData.browserVersion = 'Unknown';
            logData.browserName = 'Unknown';
            try {
                var md = new MobileDetect(window.navigator.userAgent);
                if (md.phone() != null) {
                    logData.deviceType = 'Mobile'
                    logData.deviceName = md.mobile();
                } else if (md.tablet() != null) {
                    logData.deviceType = 'Tablet'
                    logData.deviceName = md.tablet();
                } else {
                    logData.deviceType = 'Desktop'
                    logData.deviceName = 'Unknown'
                }
                if (logData.deviceName == "UnknownMobile" || logData.deviceName == "UnknownPhone" || logData.deviceName == "UnknownTablet") {
                    logData.deviceName = "Other";
                }
            } catch (err) {
                if (logData.etc == null) {
                    logData.etc = err;
                } else {
                    logData.etc += (" || " + err);
                }
            }
            try {
                var userAgent = window.navigator.userAgent,
                    platform = window.navigator.platform,
                    macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
                    windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
                    iosPlatforms = ['iPhone', 'iPad', 'iPod'];
                logData.browserUserAgent = userAgent.trunc(499);
                if (macosPlatforms.indexOf(platform) !== -1) {
                    logData.osType = 'Mac OS';
                } else if (iosPlatforms.indexOf(platform) !== -1) {
                    logData.osType = 'iOS';
                    if (md.version('iOS') != null) {
                        logData.osVersion = md.version('iOS');
                    }
                } else if (windowsPlatforms.indexOf(platform) !== -1) {
                    logData.osType = 'Windows';
                } else if (/Android/.test(userAgent)) {
                    logData.osType = 'Android';
                    if (md.version('Android') != null) {
                        logData.osVersion = md.version('Android');
                    }
                } else if (!os && /Linux/.test(platform)) {
                    logData.osType = 'Linux';
                }
                if (window.navigator.userAgent.indexOf("Windows NT 10.0") != -1) {
                    logData.osType = "Windows";
                    logData.osVersion = "10";
                }
                if (window.navigator.userAgent.indexOf("Windows NT 6.2") != -1) {
                    logData.osType = "Windows";
                    logData.osVersion = "8";
                }
                if (window.navigator.userAgent.indexOf("Windows NT 6.1") != -1) {
                    logData.osType = "Windows";
                    logData.osVersion = "7";
                }
                if (window.navigator.userAgent.indexOf("Windows NT 6.0") != -1) {
                    logData.osType = "Windows";
                    logData.osVersion = "Vista";
                }
                if (window.navigator.userAgent.indexOf("Windows NT 5.1") != -1) {
                    logData.osType = "Windows";
                    logData.osVersion = "XP";
                }
                if (window.navigator.userAgent.indexOf("Windows NT 5.0") != -1) {
                    logData.osType = "Windows";
                    logData.osVersion = "2000";
                }
            } catch (err) {
                if (logData.etc == null) {
                    logData.etc = err;
                } else {
                    logData.etc += (" || " + err);
                }
            }
            try {
                // Opera 8.0+
                var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

                // Firefox 1.0+
                var isFirefox = typeof InstallTrigger !== 'undefined';

                // Safari 3.0+ "[object HTMLElementConstructor]" 
                var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));

                // Internet Explorer 6-11
                var isIE = /*@cc_on!@*/false || !!document.documentMode;

                // Edge 20+
                var isEdge = !isIE && !!window.StyleMedia;

                // Chrome 1 - 71
                var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
                if (isOpera) {
                    logData.browserName = 'Opera';
                } else if (isFirefox) {
                    logData.browserName = 'Firefox';
                } else if (isIE) {
                    logData.browserName = 'IE';
                } else if (isEdge) {
                    logData.browserName = 'Edge';
                } else if (isSafari) {
                    logData.browserName = 'Safari';
                } else if (isChrome) {
                    logData.browserName = 'Chrome';
                } else {
                    logData.browserName = 'Unknown';
                }
            } catch (err) {
                if (logData.etc == null) {
                    logData.etc = err;
                } else {
                    logData.etc += (" || " + err);
                }
            }
            try {
                logData.devicePhysicalHeight = screen.height * window.devicePixelRatio;
                logData.devicePhysicalWidth = screen.width * window.devicePixelRatio;
                logData.deviceViewportHeight = document.documentElement.clientHeight;
                logData.deviceViewportWidth = document.documentElement.clientWidth;
            } catch (err) {
                if (logData.etc == null) {
                    logData.etc = err;
                } else {
                    logData.etc += (" || " + err);
                }
            }
            var url = $location.absUrl();
            if (!url.includes("?preview") && $cookies.get('isPartnerRegistered') == 'false') {
                $http.post(API_PREFIX.url + '/partner/companyRegPageSalesPotentialActivityLogs', logData)
            }
        }

        $rootScope.$watch('logoCropperData.compLogoImg', function (current, prevent) {
            if (current) { //Fehér képeknél szürke háttér beállítása
                const img = document.createElement("img");
                img.src = current.includes('/imgs/') ? $location.protocol() + "://" + location.host + $rootScope.logoCropperData.compLogoImg : $rootScope.logoCropperData.compLogoImg;
                img.crossOrigin = 'anonymous';
                img.style.display = "none";
                document.body.appendChild(img);
                let colorSum = 0;
                let pixel = 0;

                img.onload = function () {
                    const canvas = document.createElement("canvas");
                    canvas.width = this.width;
                    canvas.height = this.height;
                    const ctx = canvas.getContext("2d");
                    ctx.drawImage(this, 0, 0);

                    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
                    const data = imageData.data;
                    let r, g, b, avg;

                    for (let x = 0, len = data.length; x < len; x += 4) {
                        if (data[x + 3] != 0) {
                            r = data[x];
                            g = data[x + 1];
                            b = data[x + 2];
                            avg = Math.floor((r + g + b) / 3);
                            colorSum += avg;
                            pixel++;
                        }
                    }

                    const brightness = Math.floor(colorSum / pixel);
                    if (brightness >= 230) {
                        $rootScope.logoCropperData.isTooBrightness = true;
                    } else {
                        $rootScope.logoCropperData.isTooBrightness = false;
                    }
                }
            }
            if (prevent && current) {
                if ($rootScope.isDataSheetModified == false) {
                    if (prevent.includes('/imgs/') && !current.includes('/imgs/')) {
                        $rootScope.isDataSheetModified = true;
                    }
                }
            }
        });
        $rootScope.$watch('compLogoPushNotificationCropperData.compLogoPushNotificationImg', function (current, prevent) {
            if (current) { //Fehér képeknél szürke háttér beállítása
                const img = document.createElement("img");
                img.src = current.includes('/imgs/') ? $location.protocol() + "://" + location.host + $rootScope.compLogoPushNotificationCropperData.compLogoPushNotificationImg : $rootScope.compLogoPushNotificationCropperData.compLogoPushNotificationImg;
                img.crossOrigin = 'anonymous';
                img.style.display = "none";
                document.body.appendChild(img);
                let colorSum = 0;
                let pixel = 0;

                img.onload = function () {
                    const canvas = document.createElement("canvas");
                    canvas.width = this.width;
                    canvas.height = this.height;
                    const ctx = canvas.getContext("2d");
                    ctx.drawImage(this, 0, 0);

                    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
                    const data = imageData.data;
                    let r, g, b, avg;

                    for (let x = 0, len = data.length; x < len; x += 4) {
                        if (data[x + 3] != 0) {
                            r = data[x];
                            g = data[x + 1];
                            b = data[x + 2];
                            avg = Math.floor((r + g + b) / 3);
                            colorSum += avg;
                            pixel++;
                        }
                    }

                    const brightness = Math.floor(colorSum / pixel);
                    if (brightness >= 230) {
                        $rootScope.compLogoPushNotificationCropperData.isTooBrightness = true;
                    } else {
                        $rootScope.compLogoPushNotificationCropperData.isTooBrightness = false;
                    }
                }
            }
            if (prevent && current) {
                if ($rootScope.isDataSheetModified == false) {
                    if (prevent.includes('/imgs/') && !current.includes('/imgs/')) {
                        $rootScope.isDataSheetModified = true;
                    }
                }
            } else if (current) {
                $rootScope.isDataSheetModified = true;
            }
        });
        $rootScope.$watch('companyCropperData.compImg', function (current, prevent) {
            if (prevent && current) {
                if ($rootScope.isDataSheetModified == false) {
                    if (prevent.includes('/imgs/') && !current.includes('/imgs/')) {
                        $rootScope.isDataSheetModified = true;
                    }
                }
            }
        });
        $rootScope.$watch('isDataSheetModified', function () {
            if ($rootScope.isDataSheetModified == false) {
                $scope.sendButtonDisabled = true;
            } else {
                $scope.sendButtonDisabled = false;
            }
        });
        $scope.checkChanges = function () {
            if ($scope.compName) {
                $scope.compName = $scope.compName.trim();
            }
            if ($scope.compNamePromo) {
                $scope.compNamePromo = $scope.compNamePromo.trim();
            }
            if ($scope.companyDescription) {
                $scope.companyDescription = $scope.companyDescription.trim();
            }
            if ($scope.taxNum) {
                $scope.taxNum = $scope.taxNum.trim();
            }
            if ($scope.publicPhone) {
                $scope.publicPhone = $scope.publicPhone.trim();
            }
            if ($scope.webURL) {
                $scope.webURL = $scope.webURL.trim();
            }
            if ($scope.FBURL) {
                $scope.FBURL = $scope.FBURL.trim();
            }
            if ($scope.mainContactNameTitle) {
                $scope.mainContactNameTitle = $scope.mainContactNameTitle.trim();
            }
            if ($scope.mainContactNameLastName) {
                $scope.mainContactNameLastName = $scope.mainContactNameLastName.trim();
            }
            if ($scope.mainContactNameFirstName) {
                $scope.mainContactNameFirstName = $scope.mainContactNameFirstName.trim();
            }
            if ($scope.mainContactEmail) {
                $scope.mainContactEmail = $scope.mainContactEmail.trim();
            }
            if ($scope.mainContactPhone) {
                $scope.mainContactPhone = $scope.mainContactPhone.trim();
            }
            if ($location.url() == "/partner/home/partner-registration-data") {
                if ($rootScope.isDataSheetModified == false) {
                    if ($scope.compName != $scope.compData.compName) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.compNamePromo != $scope.compData.compName) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.isCompNamePromoSame != $scope.compData.isCompNamePromoSame) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.visibleInAppPartnersMenu != $scope.compData.visibleInAppPartnersMenu) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.companyStoreInfoAppVisibilityDetailLevel != $scope.compData.companyStoreInfoAppVisibilityDetailLevel) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.companyDescription != $scope.compData.compDescription) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.mainPromoCategory != $scope.compData.mainPromoCategory) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.taxNum != $scope.compData.taxNum) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.publicPhone != $scope.compData.publicContactPhone) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.webURL != $scope.compData.webURL) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.FBURL != $scope.compData.FBURL) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.defOpeningTimeFromHour != parseInt($scope.compData.defOpeningTimeFrom.split(":")[0])) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.defOpeningTimeFromMin != parseInt($scope.compData.defOpeningTimeFrom.split(":")[1])) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.defOpeningTimeUntilHour != parseInt($scope.compData.defOpeningTimeUntil.split(":")[0])) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.defOpeningTimeUntilMin != parseInt($scope.compData.defOpeningTimeUntil.split(":")[1])) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.hqAddrCountry != 1) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.hqAddrZIP != parseInt($scope.compData.hqAddrZIP)) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.hqAddrCity && $scope.hqAddrCity.placeName != $scope.compData.hqAddrCity) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.hqAddrStreet != $scope.compData.hqAddrStreet) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.hqAddrNo != $scope.compData.hqAddrNo) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.isBillingAddrSame != $scope.compData.isBillingAddrSame) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.billingAddrCountry != 1) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.billingAddrZIP != parseInt($scope.compData.billingAddrZIP)) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.billingAddrCity && $scope.billingAddrCity.placeName != $scope.compData.billingAddrCity) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.billingAddrStreet != $scope.compData.billingAddrStreet) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.billingAddrNo != $scope.compData.billingAddrNo) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.partnerRedeemHandlingStatus != $scope.compData.partnerRedeemHandlingStatus) {
                        $rootScope.isDataSheetModified = true;
                    }
                }
            } else {
                if ($rootScope.isDataSheetModified == false) {
                    $rootScope.isDataSheetModified = true;
                }
            }
        }
        $scope.validFBURL = function () {
            if ($rootScope.isDataSheetModified == false) {
                $rootScope.isDataSheetModified = true;
            }
            if ($scope.FBURL.split($scope.facebookPrefix)[1]) {
                var FBURL = $scope.facebookPrefix + $scope.FBURL.split($scope.facebookPrefix)[1];
                $scope.FBURL = FBURL;
            } else {
                $scope.FBURL = $scope.facebookPrefix;
            }
        }
        $scope.validTranslatedFBURL = function () {
            if ($rootScope.isDataSheetModified == false) {
                $rootScope.isDataSheetModified = true;
            }
            if ($scope.translatedFBURL.split($scope.facebookPrefix)[1]) {
                var FBURL = $scope.facebookPrefix + $scope.translatedFBURL.split($scope.facebookPrefix)[1];
                $scope.translatedFBURL = FBURL;
            } else {
                $scope.translatedFBURL = $scope.facebookPrefix;
            }
        }
        $scope.setSumNrOfStampsNeeded = function () {
            $scope.sumNrOfStampsNeeded = $scope.LCYNrOfStamps;
            if ($scope.sumNrOfStampsNeeded < 10) {
                $scope.floorNrOfStampsNeeded = Math.ceil($scope.sumNrOfStampsNeeded / 2);
            } else {
                $scope.floorNrOfStampsNeeded = 6;
            }
        }
        $scope.showOpenNewTabLink = false;
        $scope.aspectRatio = 1;
        $scope.logoWidth = 320;
        $scope.mapManuallyRepositioned = 0;
        $scope.mapManuallyReposition = 0;
        $scope.mapManuallyRepositioned = 0;
        $scope.mapManuallyReposition = 0;
        $scope.center = {};
        $scope.coordinateMode = "automatic";
        if ($location.url() == "/yaltyadmin738/registerPartner") {
            $rootScope.isDataSheetModified = true;
            $scope.hqAddrCountry = 1;
            $scope.billingAddrCountry = 1;
            $scope.isBillingAddrSame = true;
            $scope.partnerRedeemHandlingStatus = false;
            $scope.visibleInAppPartnersMenu = true;
            $scope.companyStoreInfoAppVisibilityDetailLevel = 6;
        }
        if ($rootScope.sub) { //preregisztráció értékadás
            // if ($scope.$parent.setIconCanvases) {
            //     $scope.$parent.setIconCanvases("#FFFFFF")
            // }
            $scope.isSameAddress = { "color": "black" };
            $scope.freePackageSubscriptionExpLimited = $rootScope.sub[0].freePackageSubscriptionExpLimited;
            $scope.freePackageSubscriptionExpTimestamp = $rootScope.sub[0].freePackageSubscriptionExpTimestamp;
            $cookies.put('freePackageSubscriptionExpLimited', $rootScope.sub[0].freePackageSubscriptionExpLimited, { 'expires': $rootScope.activationStringExpTimestamp });
            $cookies.put('freePackageSubscriptionExpTimestamp', $rootScope.sub[0].freePackageSubscriptionExpTimestamp, { 'expires': $rootScope.activationStringExpTimestamp });
            $scope.checkToNow = new Date();
            var freePackageSubscriptionExpTimestamp = new Date($rootScope.sub[0].freePackageSubscriptionExpTimestamp)
            // To calculate the time difference of two dates 
            var Difference_In_Time = freePackageSubscriptionExpTimestamp.getTime() - $scope.checkToNow.getTime();

            // To calculate the no. of days between two dates 
            $scope.differenceInDays = Math.ceil(Difference_In_Time / (1000 * 3600 * 24));

            $scope.compName = $rootScope.sub[0].compName;
            $scope.LCYPromotionName = $rootScope.sub[0].LCYPromotionName;
            $scope.LCYCardBackgroundColor = $rootScope.sub[0].LCYCardBackgroundColor;
            $scope.LCYCompNamePromoTextColor = $rootScope.sub[0].LCYCompNamePromoTextColor;
            $scope.LCYPromotionNameTextColor = $rootScope.sub[0].LCYPromotionNameTextColor;
            $scope.LCYDetailedDisplayBackgroundColor = $rootScope.sub[0].LCYDetailedDisplayBackgroundColor;
            $scope.LCYBackButtonColor = $rootScope.sub[0].LCYBackButtonColor;
            $scope.LCYStampTextPlaceholderColor = $rootScope.sub[0].LCYStampTextPlaceholderColor;
            $scope.LCYStampCurrentCountTextColor = $rootScope.sub[0].LCYStampCurrentCountTextColor;
            $scope.LCYShowBaseStampDesign = $rootScope.sub[0].LCYShowBaseStampDesign;
            $scope.LCYBaseStampUnstampedFillColor = $rootScope.sub[0].LCYBaseStampUnstampedFillColor;
            $scope.LCYBaseStampUnstampedLineWeight = $rootScope.sub[0].LCYBaseStampUnstampedLineWeight;
            $scope.LCYBaseStampUnstampedLineColor = $rootScope.sub[0].LCYBaseStampUnstampedLineColor;
            $scope.LCYBaseStampStampedFillColor = $rootScope.sub[0].LCYBaseStampStampedFillColor;
            $scope.LCYBaseStampStampedLineWeight = $rootScope.sub[0].LCYBaseStampStampedLineWeight;
            $scope.LCYBaseStampStampedLineColor = $rootScope.sub[0].LCYBaseStampStampedLineColor;
            $scope.LCYShowStampIcon = $rootScope.sub[0].LCYShowStampIcon;

            $scope.stampedStyle = {
                "background-color": $scope.LCYBaseStampStampedFillColor,
                "border": "solid " + $scope.LCYBaseStampStampedLineWeight + "px",
                "border-color": $scope.LCYBaseStampStampedLineColor,
                "padding": (5 - $scope.LCYBaseStampStampedLineWeight) + "px",
                "position": "relative"
            };
            $scope.sizedPreviewStampStyle = {
                "width": "30px",
                "height": "30px",
                "margin-top": "0px",
                "margin-left": "0px"
            };
            $scope.unstampedStyle = {
                "background-color": $scope.LCYBaseStampUnstampedFillColor,
                "border": "solid " + $scope.LCYBaseStampUnstampedLineWeight + "px",
                "border-color": $scope.LCYBaseStampUnstampedLineColor,
                "padding": (5 - $scope.LCYBaseStampUnstampedLineWeight) + "px",
                "position": "relative"
            };
            $scope.sizedPreviewUnstampStyle = {
                "width": "30px",
                "height": "30px",
                "margin-top": "0px",
                "margin-left": "0px"
            }
            $scope.sizedUnstampStyle = {
                "width": "20px",
                "height": "20px",
                "position": "absolute",
                "top": "50%",
                "left": "50%",
                "transform": "translate(-50%,-50%)"
            };
            $scope.sizedStampStyle = {
                "width": "20px",
                "height": "20px",
                "position": "absolute",
                "top": "50%",
                "left": "50%",
                "transform": "translate(-50%,-50%)"
            };

            $scope.selectedTemplateImg = { stampIconURLPrefix: $rootScope.sub[0].LCYStampIconURL }
            $scope.LCYIsDemoActive = $rootScope.sub[0].LCYIsDemoActive;
            $scope.LCYNrOfStamps = $rootScope.sub[0].LCYNrOfStamps;
            $scope.setSumNrOfStampsNeeded();
            $rootScope.logoCropperData.compLogoImg = $rootScope.sub[0].compLogoImgURL;
            $scope.compNamePromo = $rootScope.sub[0].compNamePromo;
            $scope.isCompNamePromoSame = $rootScope.sub[0].isCompNamePromoSame;
            if ($scope.compNamePromo && $scope.compNamePromo.length > 25) {
                setTimeout(function () {
                    $scope.settingsForm.compNamePromo.$touched = true
                }, 0)
            }
            $scope.taxNum = $rootScope.sub[0].taxNum;
            if($rootScope.sub[0].mainContactNameTitle == null || $rootScope.sub[0].mainContactNameTitle == ''){
                $scope.defaultMainContactNameTitle = true;
            }else $scope.defaultMainContactNameTitle = false;
            $scope.mainContactNameTitle = $rootScope.sub[0].mainContactNameTitle;
            $scope.mainContactNameLastName = $rootScope.sub[0].mainContactNameLastName;
            $scope.mainContactNameFirstName = $rootScope.sub[0].mainContactNameFirstName;
            $scope.mainContactEmail = $rootScope.sub[0].mainContactEmail;
            $scope.mainContactPhone = $rootScope.sub[0].mainContactPhone;
            $scope.YLCFBMLSPImgURL = $rootScope.sub[0].YLCFBMLSPImgURL;
            $scope.hqAddrCountry = 1;
            $scope.billingAddrCountry = 1;
            $scope.isBillingAddrSame = true;
            $scope.partnerRedeemHandlingStatus = false;
            $scope.authorizationLevel = $rootScope.authorizationLevel;
            $cookies.put('authorizationLevel', $rootScope.authorizationLevel, { 'expires': $rootScope.activationStringExpTimestamp });
            $rootScope.isDataSheetModified = true;
            if ($scope.authorizationLevel == 'prereg' || $scope.currentUserType == 'preActivator') {
                $scope.isUserHover = "inactiveMenu";
            } else {
                $scope.isUserHover = "yalty-hover-turquoise";
            }
        }

        if ($location.url().startsWith("/service/viewInfo") ||
            $location.url().startsWith("/service/registerCompany")) {
            $location.search().e ? $scope.showPreviewButton = false : $scope.showPreviewButton = true;
            $rootScope.isDataSheetModified = false;
        }

        $scope.resetPage = function (state) {
            const queryParams = $location.search();
            if ($rootScope.isDataSheetModified == false) {
                if (state) {
                    if (queryParams.preview) {
                        $state.go(state, { preview: true });
                    } else if (queryParams.e) {
                        $state.go(state, { e: queryParams.e });
                    } else {
                        $state.go(state);
                    }
                } else {
                    $window.scrollTo(0, 0);
                    $state.reload();
                }
            } else {
                var confirm = $mdDialog.confirm()
                    .title('Biztosan el szeretné hagyni az oldalt?')
                    .textContent('Az adatlapon történt módosításai mentés nélkül elvesznek.')
                    .ariaLabel('Oldal elhagyás megerősítése')
                    .ok('Igen')
                    .cancel('Mégsem');
                $mdDialog.show(confirm).then(function () {
                    $rootScope.isDataSheetModified = false;
                    if (state) {
                        if (queryParams.preview) {
                            $state.go(state, { preview: true });
                        } else if (queryParams.e) {
                            $state.go(state, { e: queryParams.e });
                        } else {
                            $state.go(state);
                        }
                    } else {
                        $window.scrollTo(0, 0);
                        $state.reload();
                    }
                }, function () {
                });
            }
        }
        $scope.getAddressFromCoordinates = function () {
            if ($scope.coordinateMode == "manual") {
                if ($scope.center.lat && $scope.center.lng) {
                    $scope.mapManuallyReposition = 1;
                    Map.init().then(function () {
                        googleMap = Map.getMap();
                        googleMap.addListener("zoom_changed", () => {
                            $scope.refreshMapView()
                        })
                        $scope.isAddress = false;
                        Map.setCenterFromCoordinates($scope.center.lat, $scope.center.lng);
                    })
                }
            }
        }

        $scope.coordinateModeChange = function () {
            $rootScope.isDataSheetModified = true;
            if ($scope.coordinateMode == "manual") {
                if ($scope.center.lat && $scope.center.lng) {
                    $scope.isAddress = false;
                    $scope.googleMapIsInvalid = false;
                    $scope.getAddressFromCoordinates();
                }
            } else {
                if (($scope.hqAddrCountry && $scope.hqAddrZIP && $scope.hqAddrCity && $scope.hqAddrCity.placeName && $scope.hqAddrStreet && $scope.hqAddrNo) != null && $scope.mapManuallyRepositioned == 0 && $scope.coordinateMode == "automatic") {
                    setMapView();
                    refreshMapView();
                    $scope.isAddress = false;
                    $scope.isSameAddress = { "color": "black" };
                    $scope.mapManuallyReposition = 0;
                } else if (($scope.hqAddrCountry && $scope.hqAddrZIP && $scope.hqAddrCity && $scope.hqAddrCity.placeName && $scope.hqAddrStreet && $scope.hqAddrNo) != null && $scope.mapManuallyRepositioned == 1 && $scope.coordinateMode == "automatic") {
                    $scope.isAddress = false;
                    $scope.isSameAddress = { "color": "black" };
                    $scope.mapManuallyReposition = 1;
                } else {
                    if (!$scope.mapManuallyRepositioned) $scope.mapManuallyReposition = 0;
                    $scope.isAddress = true;
                }
            }
        }

        $scope.isUserExist = function (form) {
            var user = {};
            user.userName = $scope.mainContactEmail;
            $http.post(API_PREFIX.url + '/partner/userExists', user).then(function (result) {
                if (result.data.exists == true) {
                    form.contactEmail.$error.userExist = true;
                } else {
                    form.contactEmail.$error.userExist = false;
                    if (form.contactEmail.$invalid == true && form.contactEmail.$$success.email && form.contactEmail.$$success['md-maxlength'] && form.contactEmail.$$success.required) {
                        if (form.contactEmail.$$success.email == true && form.contactEmail.$$success['md-maxlength'] == true && form.contactEmail.$$success.required == true) {
                            form.contactEmail.$invalid = false;
                        }
                    }
                }
            });
        }

        String.prototype.trunc = String.prototype.trunc ||
            function (n) {
                return (this.length > n) ? this.substr(0, n - 1) + '&hellip;' : this;
            };

        $scope.logPartnerActivityBeforeRegistration = function () {
            var logData = {}
            logData.userIPAddress = 'Unknown'
            logData.pageName = 'Előfizetések tábla (regisztráció előtt)'
            logData.pageStandardName = 'c2_subscriptions'
            logData.deviceType = 'Unknown'
            logData.deviceName = 'Unknown'
            logData.osType = "Unknown";
            logData.osVersion = 'Unknown'
            logData.browserUserAgent = 'Unknown'
            logData.browserVersion = 'Unknown';
            logData.browserName = 'Unknown';
            try {
                var md = new MobileDetect(window.navigator.userAgent);
                if (md.phone() != null) {
                    logData.deviceType = 'Mobile'
                    logData.deviceName = md.mobile();
                } else if (md.tablet() != null) {
                    logData.deviceType = 'Tablet'
                    logData.deviceName = md.tablet();
                } else {
                    logData.deviceType = 'Desktop'
                    logData.deviceName = 'Unknown'
                }
                if (logData.deviceName == "UnknownMobile" || logData.deviceName == "UnknownPhone" || logData.deviceName == "UnknownTablet") {
                    logData.deviceName = "Other";
                }
            } catch (err) {
                if (logData.etc == null) {
                    logData.etc = err;
                } else {
                    logData.etc += (" || " + err);
                }
            }
            try {
                var userAgent = window.navigator.userAgent,
                    platform = window.navigator.platform,
                    macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
                    windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
                    iosPlatforms = ['iPhone', 'iPad', 'iPod'];
                logData.browserUserAgent = userAgent.trunc(499);
                if (macosPlatforms.indexOf(platform) !== -1) {
                    logData.osType = 'Mac OS';
                } else if (iosPlatforms.indexOf(platform) !== -1) {
                    logData.osType = 'iOS';
                    if (md.version('iOS') != null) {
                        logData.osVersion = md.version('iOS');
                    }
                } else if (windowsPlatforms.indexOf(platform) !== -1) {
                    logData.osType = 'Windows';
                } else if (/Android/.test(userAgent)) {
                    logData.osType = 'Android';
                    if (md.version('Android') != null) {
                        logData.osVersion = md.version('Android');
                    }
                } else if (!os && /Linux/.test(platform)) {
                    logData.osType = 'Linux';
                }
                if (window.navigator.userAgent.indexOf("Windows NT 10.0") != -1) {
                    logData.osType = "Windows";
                    logData.osVersion = "10";
                }
                if (window.navigator.userAgent.indexOf("Windows NT 6.2") != -1) {
                    logData.osType = "Windows";
                    logData.osVersion = "8";
                }
                if (window.navigator.userAgent.indexOf("Windows NT 6.1") != -1) {
                    logData.osType = "Windows";
                    logData.osVersion = "7";
                }
                if (window.navigator.userAgent.indexOf("Windows NT 6.0") != -1) {
                    logData.osType = "Windows";
                    logData.osVersion = "Vista";
                }
                if (window.navigator.userAgent.indexOf("Windows NT 5.1") != -1) {
                    logData.osType = "Windows";
                    logData.osVersion = "XP";
                }
                if (window.navigator.userAgent.indexOf("Windows NT 5.0") != -1) {
                    logData.osType = "Windows";
                    logData.osVersion = "2000";
                }
            } catch (err) {
                if (logData.etc == null) {
                    logData.etc = err;
                } else {
                    logData.etc += (" || " + err);
                }
            }
            try {
                // Opera 8.0+
                var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

                // Firefox 1.0+
                var isFirefox = typeof InstallTrigger !== 'undefined';

                // Safari 3.0+ "[object HTMLElementConstructor]" 
                var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));

                // Internet Explorer 6-11
                var isIE = /*@cc_on!@*/false || !!document.documentMode;

                // Edge 20+
                var isEdge = !isIE && !!window.StyleMedia;

                // Chrome 1 - 71
                var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
                if (isOpera) {
                    logData.browserName = 'Opera';
                } else if (isFirefox) {
                    logData.browserName = 'Firefox';
                } else if (isIE) {
                    logData.browserName = 'IE';
                } else if (isEdge) {
                    logData.browserName = 'Edge';
                } else if (isSafari) {
                    logData.browserName = 'Safari';
                } else if (isChrome) {
                    logData.browserName = 'Chrome';
                } else {
                    logData.browserName = 'Unknown';
                }
            } catch (err) {
                if (logData.etc == null) {
                    logData.etc = err;
                } else {
                    logData.etc += (" || " + err);
                }
            }
            try {
                logData.devicePhysicalHeight = screen.height * window.devicePixelRatio;
                logData.devicePhysicalWidth = screen.width * window.devicePixelRatio;
                logData.deviceViewportHeight = document.documentElement.clientHeight;
                logData.deviceViewportWidth = document.documentElement.clientWidth;
            } catch (err) {
                if (logData.etc == null) {
                    logData.etc = err;
                } else {
                    logData.etc += (" || " + err);
                }
            }
            var url = $location.absUrl();
            if (!url.includes("?preview") && $cookies.get('isPartnerRegistered') == 'false') {
                $http.post(API_PREFIX.url + '/partner/companyRegPageSalesPotentialActivityLogs', logData)
            }
        }


        $scope.resetImgs = function (overlay) {
            if (overlay == 'overlayCompany') {
                off('overlayCompany');
            } else if (overlay == 'overlayLogo') {
                off('overlayLogo');
            }
        }

        $scope.isAroundTheClock = function () {
            if ($scope.isDefAroundTheClock) {
                $scope.defOpeningTimeFromHour = 0;
                $scope.defOpeningTimeFromMin = 0;
                $scope.defOpeningTimeUntilHour = 24;
                $scope.defOpeningTimeUntilMin = 0;
                $rootScope.isDataSheetModified = true;
            } else {
                $scope.defOpeningTimeFromHour = null;
                $scope.defOpeningTimeFromMin = null;
                $scope.defOpeningTimeUntilHour = null;
                $scope.defOpeningTimeUntilMin = null;
                $rootScope.isDataSheetModified = true;
            }
        }

        $scope.isSameCompanyAddr = function (param, form) {
            if (param == "same") {
                if ($scope.isBillingAddrSame) {
                    $scope.billingAddrCountry = $scope.hqAddrCountry;
                    $scope.billingAddrZIP = $scope.hqAddrZIP;
                    $scope.billingAddrCity = $scope.hqAddrCity;
                    $scope.billingAddrStreet = $scope.hqAddrStreet;
                    $scope.billingAddrNo = $scope.hqAddrNo;
                    if (($scope.hqAddrCountry && $scope.hqAddrZIP && $scope.hqAddrCity && $scope.hqAddrCity.placeName && $scope.hqAddrStreet && $scope.hqAddrNo) != null) {
                        $scope.isAddress = false;
                    } else {
                        $scope.isAddress = true;
                    }
                    $scope.isSameAddress = { "color": "black" };
                    $scope.mapManuallyRepositioned = 0;
                    $rootScope.isDataSheetModified = true;
                } else {
                    $scope.billingAddrZIP = null
                    $scope.billingAddrCity = null
                    $scope.billingAddrStreet = null
                    $scope.billingAddrNo = null
                    $scope.isSameAddress = { "color": "#4E525C" };
                    $rootScope.isDataSheetModified = true;
                }
            } else if (param == "hqChange") {
                if ($scope.hqAddrStreet) {
                    $scope.hqAddrStreet = $scope.hqAddrStreet.trim();
                }
                if ($scope.hqAddrNo) {
                    $scope.hqAddrNo = $scope.hqAddrNo.trim();
                }
                if ($scope.hqAddrCity != null) {
                    if (($scope.hqAddrCountry && $scope.hqAddrZIP && $scope.hqAddrCity.placeName && $scope.hqAddrStreet && $scope.hqAddrNo) != null && $scope.mapManuallyRepositioned == 0 && $scope.coordinateMode == "automatic") {
                        $scope.isAddress = false;
                        $scope.isSameAddress = { "color": "black" };
                        $scope.mapManuallyReposition = 0;
                        setMapView(form);
                        refreshMapView();
                    } else if (($scope.hqAddrCountry && $scope.hqAddrZIP && $scope.hqAddrCity.placeName && $scope.hqAddrStreet && $scope.hqAddrNo) != null && $scope.mapManuallyRepositioned == 1 && $scope.coordinateMode == "automatic") {
                        $scope.isAddress = false;
                        $scope.isSameAddress = { "color": "black" };
                        $scope.mapManuallyReposition = 1;
                    } else if ($scope.coordinateMode == "manual" && $scope.center.lat && $scope.center.lng) {
                        if (($scope.hqAddrCountry && $scope.hqAddrZIP && $scope.hqAddrCity.placeName && $scope.hqAddrStreet && $scope.hqAddrNo) != null) {
                            $scope.mapManuallyReposition = 1;
                        }
                        $scope.isAddress = false;
                        $scope.isSameAddress = { "color": "black" };
                    }
                } else {
                    $scope.isAddress = true;
                    $scope.isSameAddress = { "color": "#4E525C" };
                }
                if ($scope.isBillingAddrSame) {
                    $scope.billingAddrCountry = $scope.hqAddrCountry;
                    $scope.billingAddrZIP = $scope.hqAddrZIP;
                    $scope.billingAddrCity = $scope.hqAddrCity;
                    $scope.billingAddrStreet = $scope.hqAddrStreet;
                    $scope.billingAddrNo = $scope.hqAddrNo;
                }
            } else if (param == "billSameHq") {
                if ($scope.isBillingAddrSame) {
                    $scope.billingAddrCountry = $scope.hqAddrCountry;
                    $scope.billingAddrZIP = $scope.hqAddrZIP;
                    $scope.billingAddrCity = $scope.hqAddrCity;
                    $scope.billingAddrStreet = $scope.hqAddrStreet;
                    $scope.billingAddrNo = $scope.hqAddrNo;
                }
            } else if (param == "billChange") {
                $scope.billingAddrStreet = $scope.billingAddrStreet ? $scope.billingAddrStreet.trim() : $scope.billingAddrStreet;
                $scope.billingAddrNo = $scope.billingAddrNo ? $scope.billingAddrNo.trim() : $scope.billingAddrNo;
                if (!$scope.isBillingAddrSame && (($scope.billingAddrCountry == $scope.hqAddrCountry) && ($scope.billingAddrZIP == $scope.hqAddrZIP) && ($scope.billingAddrCity == $scope.hqAddrCity) && ($scope.billingAddrStreet == $scope.hqAddrStreet) && ($scope.billingAddrNo == $scope.hqAddrNo))) {
                    $scope.isBillingAddrSame = true;
                } else {
                    $scope.isBillingAddrSame = false;
                }
            }
        }

        const setMapView = function (form) {
            Map.init().then(function () {
                var address = $scope.hqAddrCity.placeName + " " + $scope.hqAddrStreet + " " + $scope.hqAddrNo;
                Map.search(address).then(function (res) {
                    $scope.googleMapIsInvalid = false;
                    Map.addMarker(res);
                    googleMap = Map.getMap();
                    googleMap.addListener("zoom_changed", () => {
                        $scope.refreshMapView()
                    })
                    $scope.center.lat = res.geometry.location.lat();
                    $scope.center.lng = res.geometry.location.lng();
                    setStaticMapView();
                }, function (status) {
                    $scope.googleMapIsInvalid = $scope.mapManuallyRepositioned ? false : true;
                    address = $scope.hqAddrCity.placeName + " " + $scope.hqAddrStreet
                    Map.search(address).then(function (res) {
                        Map.addMarker(res);
                        googleMap = Map.getMap();
                        googleMap.addListener("zoom_changed", () => {
                            $scope.refreshMapView()
                        })
                        $scope.center.lat = res.geometry.location.lat();
                        $scope.center.lng = res.geometry.location.lng();
                        setStaticMapView();
                    }, function (status) {
                        address = $scope.hqAddrCity.placeName
                        Map.search(address).then(function (res) {
                            Map.addMarker(res);
                            googleMap = Map.getMap();
                            googleMap.addListener("zoom_changed", () => {
                                $scope.refreshMapView()
                            })
                            $scope.center.lat = res.geometry.location.lat();
                            $scope.center.lng = res.geometry.location.lng();
                            setStaticMapView();
                        })
                    });
                });
                $scope.center = Map.getCenterPos;
            })
        }
        const setStaticMapView = function () {
            if ($scope.hqAddrCountry == "1" && $scope.hqAddrCity && $scope.hqAddrCity.placeName && $scope.hqAddrStreet && $scope.hqAddrNo) {
                var center = Map.getCenterPos();
                $scope.compStoreMapImgURL = "https://maps.google.com/maps/api/staticmap?sensor=false&center=" + center.pos.lat() + "," + center.pos.lng() + "&zoom=19&size=750x460&maptype=roadmap&format=png&visual_refresh=true&markers=size:lar%7Ccolor:0x00AEEF%7Clabel:Y%7C" + center.pos.lat() + ',' + center.pos.lng() + "&key=AIzaSyAuMO2H5Jh1L92B1D7uKW4BneSzh6ywbMI";
            }
        }
        const refreshMapView = function () {
            var center = Map.getCenterPos().pos;
            Map.refreshMarker(center);
            $scope.center.lat = center.lat();
            $scope.center.lng = center.lng();
        }
        $scope.setMapView = function (form) {
            $rootScope.isDataSheetModified = true;
            if (($scope.hqAddrCountry && $scope.hqAddrZIP && $scope.hqAddrCity && $scope.hqAddrCity.placeName && $scope.hqAddrStreet && $scope.hqAddrNo) != null) {
                setMapView(form);
                refreshMapView();
                $scope.coordinateMode = "automatic";
                $scope.mapManuallyRepositioned = 0;
                $scope.mapManuallyReposition = 0;
            } else {
                var alert = $mdDialog.alert()
                    .title('Hiányos cím adatok')
                    .textContent('Töltse ki az összes cím mezőt, majd nyomja meg a gombot újra.')
                    .ariaLabel('Hiányos cím adatok')
                    .ok('Ok')
                $mdDialog.show(alert).then(function () {
                    angular.element($document[0].querySelector('input.ng-invalid')).focus();
                });
            }
        }
        $scope.refreshMapView = function () {
            refreshMapView();
            setStaticMapView();
            $scope.coordinateMode = "automatic";
            $rootScope.isDataSheetModified = true;
            $scope.mapManuallyRepositioned = 1;
            $scope.mapManuallyReposition = 1;
            $scope.googleMapIsInvalid = false;
        }
        $scope.isSameCompName = function () {
            if ($scope.isCompNamePromoSame == true) {
                $scope.compNamePromo = $scope.compName.length > 25 ? $scope.compName.slice(0, 25).trim() : $scope.compName.trim();
                $rootScope.isDataSheetModified = true;
            } else {
                $rootScope.isDataSheetModified = true;
            }
        }
        $scope.querySearch = function (query) {
            if (query) {
                var filtered = $scope.listOfCities.filter(function (i) {
                    var lowercaseQuery = angular.lowercase(query);
                    return ((i.placeName.toLowerCase()).indexOf(lowercaseQuery) === 0);
                })
                if (filtered.length > 50) {
                    return filtered.slice(0, 50);
                } else if (filtered.length > 0) {
                    return filtered;
                } else {
                    return $scope.listOfCities.slice(0, 50);
                }
            } else {
                return $scope.listOfCities.slice(0, 50);
            }
        }

        $scope.convertNumberToTime = function (fromHour, fromMin) {
            if (fromHour == null && fromMin == null) {
                return null;
            } else if (fromHour < 10 && fromMin > 9) {
                return "0" + fromHour + ":" + fromMin;
            } else if (fromHour < 10 && fromMin < 10) {
                return "0" + fromHour + ":0" + fromMin;
            } else if (fromHour > 9 && fromMin < 10) {
                return fromHour + ":0" + fromMin;
            } else if (fromHour > 9 && fromMin > 9) {
                return fromHour + ":" + fromMin;
            }
        }
        if ($scope.compData) { //partneri adatok értékadása
            $scope.compName = $scope.compData.compName;
            $scope.compNamePromo = $scope.compData.compNamePromo;
            $scope.isCompNamePromoSame = $scope.compData.isCompNamePromoSame;
            $scope.visibleInAppPartnersMenu = $scope.compData.visibleInAppPartnersMenu;
            $scope.companyStoreInfoAppVisibilityDetailLevel = $scope.compData.companyStoreInfoAppVisibilityDetailLevel;
            $rootScope.logoCropperData.compLogoImg = $scope.compData.compLogoImgURL;
            $rootScope.logoImage = $scope.compData.compLogoImgURL;
            $rootScope.companyCropperData.compImg = $scope.compData.compImgURL;
            $scope.isCompLogoPushNotificationImgSame = $scope.compData.isCompLogoPushNotificationImgSame;
            $rootScope.compLogoPushNotificationCropperData.compLogoPushNotificationImg = $scope.compData.compLogoPushNotificationImgURL;
            $rootScope.companyImage = $scope.compData.compImgURL;
            $scope.companyDescription = $scope.compData.compDescription;
            $scope.mainPromoCategory = $scope.compData.mainPromoCategory;
            $scope.taxNum = $scope.compData.taxNum;
            $scope.holidayOpeningTimes = $scope.compData.defHolidayOpeningTimes;
            $scope.holidayOpeningTimesUntil = $scope.compData.defHolidayOpeningTimesUntil;
            if ($scope.compData.webURL != null) {
                $scope.webURL = $scope.compData.webURL;
            } else {
                $scope.webURL = "";
            }
            if ($scope.compData.FBURL != null) {
                $scope.FBURL += $scope.compData.FBURL;
            } else {
                $scope.FBURL = $scope.facebookPrefix;
            }
            if ($scope.compData.publicContactPhone != null) {
                $scope.publicPhone = $scope.compData.publicContactPhone;
            } else {
                $scope.publicPhone = "";
            }
            $scope.defOpeningTimeFromHour = parseInt($scope.compData.defOpeningTimeFrom.split(":")[0]);
            $scope.defOpeningTimeFromMin = parseInt($scope.compData.defOpeningTimeFrom.split(":")[1]);
            $scope.defOpeningTimeUntilHour = parseInt($scope.compData.defOpeningTimeUntil.split(":")[0]);
            $scope.defOpeningTimeUntilMin = parseInt($scope.compData.defOpeningTimeUntil.split(":")[1]);
            if ($scope.compData.defOpeningTimeFrom == "00:00" && $scope.compData.defOpeningTimeUntil == "24:00") {
                $scope.isDefAroundTheClock = true;
            } else {
                $scope.isDefAroundTheClock = false;
            }
            if ($scope.compData.hqAddrCountry == "Magyarország") {
                $scope.hqAddrCountry = 1;
            }
            $scope.hqAddrZIP = parseInt($scope.compData.hqAddrZIP);
            let index = $scope.listOfCities.findIndex(record => record.placeName === $scope.compData.hqAddrCity)
            $scope.hqAddrCity = $scope.listOfCities[index];
            $scope.hqAddrStreet = $scope.compData.hqAddrStreet;
            $scope.hqAddrNo = $scope.compData.hqAddrNo;
            $scope.isBillingAddrSame = $scope.compData.isBillingAddrSame;
            if ($scope.compData.billingAddrCountry == "Magyarország") {
                $scope.billingAddrCountry = 1;
            }
            $scope.billingAddrZIP = parseInt($scope.compData.billingAddrZIP);
            index = $scope.listOfCities.findIndex(record => record.placeName === $scope.compData.billingAddrCity)
            $scope.billingAddrCity = $scope.listOfCities[index];
            $scope.billingAddrStreet = $scope.compData.billingAddrStreet;
            $scope.billingAddrNo = $scope.compData.billingAddrNo;
            if ($scope.compData.mapManuallyRepositioned != null) {
                $scope.mapManuallyRepositioned = $scope.compData.mapManuallyRepositioned;
                $scope.mapManuallyReposition = $scope.compData.mapManuallyRepositioned;
            } else {
                $scope.mapManuallyRepositioned = 0;
                $scope.mapManuallyReposition = 0;
            }
            if ($scope.compData.GPSCoordinatesAutoOrManual == 1) {
                $scope.coordinateMode = "manual";
            } else {
                $scope.coordinateMode = "automatic";
            }
            $scope.center.lat = $scope.compData.latitudeCoordinate;
            $scope.center.lng = $scope.compData.longitudeCoordinate;
            if ($scope.coordinateMode == "manual") {
                if ($scope.center.lat && $scope.center.lng) {
                    $scope.isAddress = false;
                    $scope.getAddressFromCoordinates();
                }
            } else if ($scope.coordinateMode == "automatic" && $scope.mapManuallyRepositioned == 0) {
                setMapView();
                refreshMapView();
                $scope.isAddress = false;
            } else {
                Map.init().then(function () {
                    googleMap = Map.getMap();
                    googleMap.addListener("zoom_changed", () => {
                        $scope.refreshMapView()
                    })
                    $scope.isAddress = false;
                    Map.setCenterFromCoordinates($scope.center.lat, $scope.center.lng);
                });
            }
            if ($scope.partnerUserData) {
                if($scope.partnerUserData.nameTitle == null || $scope.partnerUserData.nameTitle == ''){
                    $scope.defaultMainContactNameTitle = true;
                }else $scope.defaultMainContactNameTitle = false;
                $scope.mainContactNameTitle = $scope.partnerUserData.nameTitle;
                $scope.mainContactNameFirstName = $scope.partnerUserData.firstName;
                $scope.mainContactNameLastName = $scope.partnerUserData.lastName;
                $scope.mainContactEmail = $scope.partnerUserData.userName;
                $scope.mainContactPhone = $scope.partnerUserData.contactPhoneNumber;
            }
            if ($scope.compData.partnerRedeemHandlingStatus == 1) {
                $scope.partnerRedeemHandlingStatus = true;
            } else {
                $scope.partnerRedeemHandlingStatus = false;
            }
            $scope.acceptedGeneralConditions = $scope.compData.acceptedGeneralConditions;

            $rootScope.isDataSheetModified = false;
        }

        $scope.showConfirm = function (ev) {
            var confirm = $mdDialog.confirm()
                .title('Biztosan törölni szeretné partneri fiókját?')
                .textContent('Igen válasz esetén a törléshez szükséges linket a kapcsolattartási (mester) fiók email címére küldjük.')
                .ariaLabel('Törlés megerősítése')
                .targetEvent(ev)
                .ok('Igen')
                .cancel('Mégsem');

            $mdDialog.show(confirm).then(function () {
                $scope.emailSendingInProgress = true;
                //on('loadingOverlay');
                var company = {};
                company.companyId = $scope.compData.id;
                $http.post(API_PREFIX.url + "/partner/sendPartnerDeleteLink", company).then(function (result) {
                    $scope.emailSendingInProgress = false;
                    //off('loadingOverlay');
                    if (result.data.errorCode == 0) {
                        $mdDialog.show(
                            $mdDialog.alert()
                                .title('A törléshez szükséges linket kiküldtük a kapcsolattartási (mester) fiók email címére.')
                                .ok('Rendben')
                                .targetEvent(ev)
                        );
                    } else if (result.data.errorCode == 1) {
                        $mdDialog.show(
                            $mdDialog.alert()
                                .title('Az adatbázis elérése sikertelen, kérjük próbálja meg később.')
                                .ok('Rendben')
                                .targetEvent(ev)
                        );
                    } else if (result.data.errorCode == 2) {
                        $mdDialog.show(
                            $mdDialog.alert()
                                .title('A művelet elvégzéséhez önnek nincs jogosultsága.')
                                .ok('Rendben')
                                .targetEvent(ev)
                        );
                    }
                })
            }, function () {
                $scope.emailSendingInProgress = false;
            });
        };

        function isBiggerThanZero(fromHour, fromMin) {
            if (fromHour < 10 && fromMin > 9) {
                return "0" + fromHour + ":" + fromMin;
            } else if (fromHour < 10 && fromMin < 10) {
                return "0" + fromHour + ":0" + fromMin;
            } else if (fromHour > 9 && fromMin < 10) {
                return fromHour + ":0" + fromMin;
            } else if (fromHour > 9 && fromMin > 9) {
                return fromHour + ":" + fromMin;
            }
        }

        $scope.sendSetting = function (form) { //partneri adatok
            if($scope.holidayOpeningTimesUntil != null){
                $scope.setTimeToEndOfDay();
            }
            $scope.sendButtonDisabled = true;
            $scope.saveInProgress = true;
            if (!form.$valid) {
                $scope.saveInProgress = false;
                $scope.sendButtonDisabled = false;
                angular.forEach(form.$error, function (field) {
                    angular.forEach(field, function (errorField) {
                        errorField.$setTouched();
                    });
                });
                let errorInEnglishTranslation = null
                if (!form.translatedCompNamePromo.$valid) {
                    errorInEnglishTranslation = 'translatedCompNamePromo'
                } else if (!form.translatedCompDescription.$valid) {
                    errorInEnglishTranslation = 'translatedCompDescription'
                } else if (!form.translatedWebURL.$valid) {
                    errorInEnglishTranslation = 'translatedWebURL'
                } else if (!form.translatedFBURL.$valid) {
                    errorInEnglishTranslation = 'translatedFBURL'
                }

                if (errorInEnglishTranslation) {
                    $scope.langName = 'angol'
                    $scope.settingsForm.languageSelector.$touched = true
                    $scope.settingsForm.languageSelector.$valid = false
                    $scope.settingsForm.languageSelector.$invalid = true
                    $scope.settingsForm.languageSelector.$error = { required: true }
                    document.getElementById('languageSelector').scrollIntoView()
                }

                angular.element($document[0].querySelector('input.ng-invalid')).focus();
                angular.element($document[0].querySelector('md-select.ng-invalid')).focus();
                angular.element($document[0].querySelector('textarea.ng-invalid')).focus();
                angular.element($document[0].querySelector('md-datepicker.ng-invalid')).focus();
                $mdDialog.show(
                    $mdDialog.alert()
                        .title('A kijelölt mezők helyes kitöltése szükséges a mentéshez.')
                        .ok('Rendben')
                );
                return false;
            } else if ($rootScope.logoCropperData.compLogoImg == null || $rootScope.companyCropperData.compImg == null || ($rootScope.compLogoPushNotificationCropperData.compLogoPushNotificationImg == null && $scope.isCompLogoPushNotificationImgSame == false)) {
                $scope.sendButtonDisabled = false;
                $scope.saveInProgress = false;
                if ($rootScope.logoCropperData.compLogoImg == null) {
                    form.logoImage.$error.need = true;
                }
                if ($rootScope.companyCropperData.compImg == null) {
                    form.companyImage.$error.need = true;
                }
                if ($rootScope.compLogoPushNotificationCropperData.compLogoPushNotificationImg == null && $scope.isCompLogoPushNotificationImgSame == false) {
                    form.compLogoPushNotificationImage.$error.need = true;
                }
                if ($rootScope.logoCropperData.compLogoImg == null) {
                    $('.main').animate({ scrollTop: $('div#logoPreviewContainer').offset().top - 150 }, "slow");
                } else if ($rootScope.companyCropperData.compImg == null) {
                    $('.main').animate({ scrollTop: $('div#companyPreviewContainer').offset().top - 150 }, "slow");
                } else if ($rootScope.compLogoPushNotificationCropperData.compLogoPushNotificationImg == null && $scope.isCompLogoPushNotificationImgSame == false) {
                    $('.main').animate({ scrollTop: $('div#compLogoPushNotificationPreviewContainer').offset().top - 150 }, "slow");
                }
            } else {
                html2canvas(document.querySelector("#compLogoPushNotificationImgForCanvas"), { backgroundColor: null }).then(canvas => {
                    var settings = {};
                    settings.compName = $scope.compName;
                    settings.compNamePromo = $scope.compNamePromo;
                    settings.visibleInAppPartnersMenu = $scope.visibleInAppPartnersMenu;
                    settings.companyStoreInfoAppVisibilityDetailLevel = $scope.companyStoreInfoAppVisibilityDetailLevel;
                    settings.isCompNamePromoSame = $scope.isCompNamePromoSame;
                    settings.logoImg = $rootScope.logoCropperData.compLogoImg;
                    settings.compLogoPushNotificationImg = $scope.isCompLogoPushNotificationImgSame == true ? canvas.toDataURL('image/png') : $rootScope.compLogoPushNotificationCropperData.compLogoPushNotificationImg;
                    settings.isCompLogoPushNotificationImgSame = $scope.isCompLogoPushNotificationImgSame;
                    settings.compImg = $rootScope.companyCropperData.compImg;
                    //settings.companyDescription = $scope.companyDescription;
                    settings.mainPromoCategory = $scope.mainPromoCategory;
                    settings.taxNum = $scope.taxNum;
                    if ($scope.publicPhone) {
                        settings.publicContactPhone = $scope.publicPhone;
                    } else {
                        settings.publicContactPhone = "";
                    }
                    if ($scope.webURL) {
                        settings.webURL = $scope.webURL;
                    } else {
                        settings.webURL = "";
                    }
                    if ($scope.FBURL.split($scope.facebookPrefix)[1]) {
                        settings.FBURL = $scope.FBURL.split($scope.facebookPrefix)[1];
                    } else {
                        settings.FBURL = "";
                    }
                    settings.defOpeningTimeFrom = isBiggerThanZero($scope.defOpeningTimeFromHour, $scope.defOpeningTimeFromMin);
                    settings.defOpeningTimeUntil = isBiggerThanZero($scope.defOpeningTimeUntilHour, $scope.defOpeningTimeUntilMin);
                    if ($scope.hqAddrCountry == 1) {
                        settings.hqAddrCountry = "Magyarország";
                    }
                    settings.hqAddrZIP = $scope.hqAddrZIP;
                    settings.hqAddrCity = $scope.hqAddrCity.placeName;
                    settings.hqAddrStreet = $scope.hqAddrStreet;
                    settings.hqAddrNo = $scope.hqAddrNo;
                    settings.isBillingAddrSame = $scope.isBillingAddrSame;
                    if ($scope.billingAddrCountry == 1) {
                        settings.billingAddrCountry = "Magyarország";
                    }
                    settings.billingAddrZIP = $scope.billingAddrZIP;
                    settings.billingAddrCity = $scope.billingAddrCity.placeName;
                    settings.billingAddrStreet = $scope.billingAddrStreet;
                    settings.billingAddrNo = $scope.billingAddrNo;
                    settings.mainContactNameTitle = $scope.mainContactNameTitle;
                    settings.mainContactNameFirstName = $scope.mainContactNameFirstName;
                    settings.mainContactNameLastName = $scope.mainContactNameLastName;
                    settings.mainContactEmail = $scope.mainContactEmail;
                    settings.mainContactPhone = $scope.mainContactPhone;
                    settings.acceptedGeneralConditions = $scope.acceptedGeneralConditions;
                    settings.compDescription = $scope.companyDescription;
                    settings.latitudeCoordinate = $scope.center.lat.toFixed(7);
                    settings.longitudeCoordinate = $scope.center.lng.toFixed(7);
                    settings.mapManuallyRepositioned = $scope.mapManuallyRepositioned;
                    settings.compAddrMapImgURL = $scope.compStoreMapImgURL;
                    settings.holidayOpeningTimes = $scope.holidayOpeningTimes != "" ? $scope.holidayOpeningTimes : null;
                    settings.holidayOpeningTimesUntil = $scope.holidayOpeningTimesUntil;
                    if ($scope.coordinateMode == "automatic") {
                        settings.GPSCoordinatesAutoOrManual = 0;
                    } else {
                        settings.GPSCoordinatesAutoOrManual = 1;
                    }
                    if ($cookies.get('adminUserType')) {
                        if ($scope.partnerRedeemHandlingStatus == true) {
                            settings.partnerRedeemHandlingStatus = 1;
                        } else {
                            settings.partnerRedeemHandlingStatus = 0;
                        }
                    }

                    settings.isTranslation_EN_Enabled = $scope.isEnglishTranslationEnabled == 'bekapcsolt'
                    settings.compNamePromo_EN = $scope['EN'].compNamePromo
                    settings.compDescription_EN = $scope['EN'].compDescription
                    settings.webURL_EN = $scope['EN'].webURL ? $scope['EN'].webURL : "";
                    if ($scope['EN'].FBURL.split($scope.facebookPrefix)[1]) {
                        settings.FBURL_EN = $scope['EN'].FBURL.split($scope.facebookPrefix)[1];
                    } else {
                        settings.FBURL_EN = "";
                    }

                    //partner/updatePartnerData(partnerData)
                    $http.post(API_PREFIX.url + '/partner/updatePartnerData_v2', settings).then(function (result) {
                        $scope.saveInProgress = false;
                        $scope.sendButtonDisabled = false;
                        if (result.data.errorCode == 0) {
                            $mdDialog.show(
                                $mdDialog.alert()
                                    .title("A módosításokat sikeresen elmentettük!")
                                    .ok('Rendben')
                            ).then(() => {
                                GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/partneri_adatok/cégadat_módosítás_sikeres",
                                    "HU Partner [PRIVÁT] - Partneri adatok oldal - Sikeres cégadat módosítás")
                                $rootScope.isDataSheetModified = false;
                                $window.scrollTo(0, 0);
                                $state.reload();
                            })
                        } else if (result.data.errorCode == 3) {
                            const alert = $mdDialog.alert()
                                .title("Sikertelen művelet")
                                .textContent("A művelet elvégzéséhez nincs jogosultsága. Ezért kiléptetjük az oldalról.")
                                .ok('Rendben')
                            $mdDialog.show(alert).then(function () {
                                $rootScope.isDataSheetModified = false;
                                AuthService.logout()
                            });
                        } else if (result.data.errorCode == 4) {
                            const alert = $mdDialog.alert()
                                .title("Sikertelen művelet")
                                .textContent("A művelet elvégzéséhez nincs jogosultsága.")
                                .ok('Rendben')
                            $mdDialog.show(alert).then(function () {
                                $rootScope.isDataSheetModified = false;
                            });
                        } else {
                            GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/partneri_adatok/cégadat_módosítás_sikertelen",
                                "HU Partner [PRIVÁT] - Partneri adatok oldal - Sikertelen cégadat módosítás")
                        }
                    }, function () {
                        $scope.saveInProgress = false;
                        $scope.sendButtonDisabled = false;
                        GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/partneri_adatok/cégadat_módosítás_sikertelen",
                            "HU Partner [PRIVÁT] - Partneri adatok oldal - Sikertelen cégadat módosítás")
                    });
                });
            }
        }

        $scope.sendSettings = function (registerData) { //regisztráció
            if($scope.holidayOpeningTimesUntil != null){
                $scope.setTimeToEndOfDay();
            }
            registerData.$setSubmitted();
            if (!registerData.$valid) {
                if (Object.keys(registerData.compNamePromo.$error).length > 0) {
                    var element = document.getElementById("compNamePromo");
                    element.scrollIntoView();
                    return
                } else if ($rootScope.logoCropperData.compLogoImg == null && $rootScope.companyCropperData.compImg == null) {
                    registerData.logoImage.$error.need = true;
                    registerData.companyImage.$error.need = true;

                } else if ($rootScope.logoCropperData.compLogoImg == null) {
                    registerData.logoImage.$error.need = true;

                } else if ($rootScope.companyCropperData.compImg == null) {
                    registerData.companyImage.$error.need = true;
                }
                angular.element($document[0].querySelector('input.ng-invalid')).focus();
                angular.element($document[0].querySelector('md-select.ng-invalid')).focus();
                angular.element($document[0].querySelector('textarea.ng-invalid')).focus();
                $mdDialog.show(
                    $mdDialog.alert()
                        .title('A kijelölt mezők kitöltése szükséges a regisztrációhoz.')
                        .ok('Rendben')
                );
                return false;
            } else if ($rootScope.logoCropperData.compLogoImg == null || $rootScope.companyCropperData.compImg == null || ($rootScope.compLogoPushNotificationCropperData.compLogoPushNotificationImg == null && $scope.isCompLogoPushNotificationImgSame == false)) {
                if ($rootScope.logoCropperData.compLogoImg == null) {
                    registerData.logoImage.$error.need = true;
                }
                if ($rootScope.companyCropperData.compImg == null) {
                    registerData.companyImage.$error.need = true;
                }
                if ($rootScope.compLogoPushNotificationCropperData.compLogoPushNotificationImg == null && $scope.isCompLogoPushNotificationImgSame == false) {
                    registerData.compLogoPushNotificationImage.$error.need = true;
                }
                if ($rootScope.logoCropperData.compLogoImg == null) {
                    $('.main').animate({ scrollTop: $('div#logoPreviewContainer').offset().top - 150 }, "slow");
                } else if ($rootScope.companyCropperData.compImg == null) {
                    $('.main').animate({ scrollTop: $('div#companyPreviewContainer').offset().top - 150 }, "slow");
                } else if ($rootScope.compLogoPushNotificationCropperData.compLogoPushNotificationImg == null && $scope.isCompLogoPushNotificationImgSame == false) {
                    $('.main').animate({ scrollTop: $('div#compLogoPushNotificationPreviewContainer').offset().top - 150 }, "slow");
                }
            } else if (registerData.num.$error.invalidAddress) {
                $mdDialog.show(
                    $mdDialog.alert()
                        .title('Helytelen cím')
                        .ok('Rendben')
                );
            } else {
                $scope.sendButtonDisabled = true;
                if (document.getElementById('loadingOverlay')) {
                    document.getElementById('loadingOverlay').style.display = "block";
                    document.getElementById('innerPartnerPageContainer').style.overflow = "hidden";
                }
                setTimeout(function () {
                    html2canvas(document.querySelector("#compLogoPushNotificationImgForCanvas"), { backgroundColor: null }).then(canvas => {
                        var regData = {};
                        regData.compLogoImg = $rootScope.logoCropperData.compLogoImg;
                        regData.compImg = $rootScope.companyCropperData.compImg;
                        regData.compLogoPushNotificationImg = $scope.isCompLogoPushNotificationImgSame == true ? canvas.toDataURL('image/png') : $rootScope.compLogoPushNotificationCropperData.compLogoPushNotificationImg;
                        regData.isCompLogoPushNotificationImgSame = $scope.isCompLogoPushNotificationImgSame;
                        regData.defOpeningTimeFrom = isBiggerThanZero($scope.defOpeningTimeFromHour, $scope.defOpeningTimeFromMin);
                        regData.defOpeningTimeUntil = isBiggerThanZero($scope.defOpeningTimeUntilHour, $scope.defOpeningTimeUntilMin);
                        regData.compName = $scope.compName;
                        regData.compNamePromo = $scope.compNamePromo;
                        regData.visibleInAppPartnersMenu = $scope.visibleInAppPartnersMenu;
                        regData.companyStoreInfoAppVisibilityDetailLevel = $scope.companyStoreInfoAppVisibilityDetailLevel;
                        regData.taxNum = $scope.taxNum;
                        regData.mainPromoCategory = $scope.mainPromoCategory;
                        regData.mainContactNameTitle = $scope.mainContactNameTitle;
                        regData.mainContactNameLastName = $scope.mainContactNameLastName;
                        regData.mainContactNameFirstName = $scope.mainContactNameFirstName;
                        regData.mainContactEmail = $scope.mainContactEmail;
                        regData.mainContactPhone = $scope.mainContactPhone;
                        if ($scope.publicPhone) {
                            regData.publicContactPhone = $scope.publicPhone;
                        } else {
                            regData.publicContactPhone = "";
                        }
                        if ($scope.webURL) {
                            regData.webURL = $scope.webURL;
                        } else {
                            regData.webURL = "";
                        }
                        if ($scope.FBURL.split($scope.facebookPrefix)[1]) {
                            regData.FBURL = $scope.FBURL.split($scope.facebookPrefix)[1];
                        } else {
                            regData.FBURL = "";
                        }
                        regData.password = $scope.password;
                        regData.acceptedGeneralConditions = $scope.acceptedGeneralConditions;
                        regData.acceptedEmailMarketing = $scope.acceptedEmailMarketing;
                        if ($scope.hqAddrCountry == 1) {
                            regData.hqAddrCountry = "Magyarország";
                        }
                        regData.hqAddrZIP = $scope.hqAddrZIP;
                        regData.hqAddrCity = $scope.hqAddrCity.placeName;
                        regData.hqAddrStreet = $scope.hqAddrStreet;
                        regData.hqAddrNo = $scope.hqAddrNo;
    
                        if ($scope.billingAddrCountry == 1) {
                            regData.billingAddrCountry = "Magyarország";
                        }
                        regData.billingAddrZIP = $scope.billingAddrZIP;
                        regData.billingAddrCity = $scope.billingAddrCity.placeName;
                        regData.billingAddrStreet = $scope.billingAddrStreet;
                        regData.billingAddrNo = $scope.billingAddrNo;
                        regData.compStoreMapImgURL = $scope.compStoreMapImgURL;
                        regData.compDescription = $scope.companyDescription;
                        regData.isCompNamePromoSame = $scope.isCompNamePromoSame ? 1 : 0;
                        regData.isBillingAddrSame = $scope.isBillingAddrSame ? 1 : 0;
                        regData.latitudeCoordinate = $scope.center.lat.toFixed(7);
                        regData.longitudeCoordinate = $scope.center.lng.toFixed(7);
                        regData.mapManuallyRepositioned = $scope.mapManuallyRepositioned;
                        regData.compAddrMapImgURL = $scope.compStoreMapImgURL;
                        if ($scope.coordinateMode == "automatic") {
                            regData.GPSCoordinatesAutoOrManual = 0;
                        } else {
                            regData.GPSCoordinatesAutoOrManual = 1;
                        }
                        if ($cookies.get('adminUserType')) {
                            if ($scope.partnerRedeemHandlingStatus == true) {
                                regData.partnerRedeemHandlingStatus = 1;
                            } else {
                                regData.partnerRedeemHandlingStatus = 0;
                            }
                        }
                        if ($location.url() == "/yaltyadmin738/registerPartner") {
                            regData.isAdminRegister = true;
                        } else {
                            regData.isAdminRegister = false;
                        }
                        regData.defHolidayOpeningTimes = $scope.holidayOpeningTimes != "" ? $scope.holidayOpeningTimes : null;
                        regData.defHolidayOpeningTimesUntil = $scope.holidayOpeningTimesUntil;
                        $http.post(API_PREFIX.url + '/partner/registerPartner', regData).then(function (result) {
                            if (document.getElementById('loadingOverlay')) {
                                document.getElementById('loadingOverlay').style.display = "none";
                                document.getElementById('innerPartnerPageContainer').style.overflow = "unset";
                            }
                            if (result.data.errorCode == 0) {
                                if (!regData.isAdminRegister) {
                                    $mdDialog.show(
                                        $mdDialog.alert()
                                            .title("Cégét sikeresen regisztrálta! :)")
                                            .textContent("Kérjük válassza ki az Önnek megfelelő előfizetési csomagunkat a most megnyíló partneri előfizetések oldalon.")
                                            .ok('Rendben')
                                    ).then(() => {
                                        GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/partner_regisztráció/regisztráció_sikeres",
                                            "HU Partner [PRIVÁT] - Regisztráció - Sikeres regisztráció")
                                        $rootScope.isDataSheetModified = false;
                                        $rootScope.sub = null;
                                        $cookies.put('isPartnerRegistered', true);
                                        $cookies.put('userEmail', $scope.mainContactEmail);
                                        $state.go('home.preRegSubscription')
                                    });
                                } else {
                                    $mdDialog.show(
                                        $mdDialog.alert()
                                            .title("A partnert sikeresen regisztráltad! :)")
                                            .textContent("A következő lapról át tudsz kattintani a partner belső partneri oldalaira.")
                                            .ok('Rendben')
                                    ).then(() => {
                                        GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/partner_regisztráció/regisztráció_sikeres",
                                            "HU Partner [PRIVÁT] - Regisztráció - Sikeres regisztráció")
                                        $rootScope.isDataSheetModified = false;
                                        $scope.showOpenNewTabLink = true;
                                        $scope.$parent.companyId = result.data.companyId;
                                    });
                                }
                            } else if (result.data.errorCode == 1) {
                                $mdDialog.show(
                                    $mdDialog.alert()
                                        .title("Sikertelen regisztráció")
                                        .textContent("Váratlan rendszerhiba lépett fel regisztráció közben. Kérjük próbálja meg a regisztrációt kicsit később újra az oldalt újra betöltve. Újra előforduló hiba esetén kérjük keresse Yalty kapcsolattartóját.")
                                        .ok('Rendben')
                                ).then(() => {
                                    GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/partner_regisztráció/regisztráció_sikertelen",
                                        "HU Partner [PRIVÁT] - Regisztráció - Sikertelen regisztráció")
                                    $scope.sendButtonDisabled = false;
                                });
                            } else if (result.data.errorCode == 2) {
                                $mdDialog.show(
                                    $mdDialog.alert()
                                        .title("Kérjük töltsön ki minden kötelező mezőt!")
                                        .textContent("Az oldalon egy vagy több mező még töltetlen maradt.")
                                        .ok('Rendben')
                                ).then(() => {
                                    GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/partner_regisztráció/regisztráció_sikertelen",
                                        "HU Partner [PRIVÁT] - Regisztráció - Sikertelen regisztráció")
                                    $scope.sendButtonDisabled = false;
                                });
                            } else if (result.data.errorCode == 3) {
                                $mdDialog.show(
                                    $mdDialog.alert()
                                        .title("Érvénytelen aktivációs kód.")
                                        .ok('Rendben')
                                ).then(() => {
                                    GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/partner_regisztráció/regisztráció_sikertelen",
                                        "HU Partner [PRIVÁT] - Regisztráció - Sikertelen regisztráció")
                                    $scope.sendButtonDisabled = false;
                                });
                            } else if (result.data.errorCode == 4) {
                                $mdDialog.show(
                                    $mdDialog.alert()
                                        .title("A megadott email cím már foglalt")
                                        .htmlContent("A megadott email cím már egy meglévő partneri fiókhoz tartozik.<br><br>" +
                                            "Ez akkor fordulhat elő, ha korábban egy másik cégét már regisztrálta ezzel a címmel vagy ha címét egy partneri fiókhoz rendelte bármely - Önnel kapcsolatban álló - más cég esetén. A hozzárendelés bármely esetben csak az érintett email címről történő, explicit aktiváció megerősítés után jöhetett létre.<br><br>" +
                                            "Rendszerünkben egy email cím csak egy fiókhoz tartozhat.")
                                        .ok('Rendben')
                                ).then(() => {
                                    GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/partner_regisztráció/regisztráció_sikertelen",
                                        "HU Partner [PRIVÁT] - Regisztráció - Sikertelen regisztráció")
                                    $scope.sendButtonDisabled = false;
                                });
                            } else if (result.data.errorCode == 5) {
                                $mdDialog.show(
                                    $mdDialog.alert()
                                        .title("Érvénytelen paraméterezés (errorCode: 5)")
                                        .ok('Rendben')
                                ).then(() => {
                                    GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/partner_regisztráció/regisztráció_sikertelen",
                                        "HU Partner [PRIVÁT] - Regisztráció - Sikertelen regisztráció")
                                    $scope.sendButtonDisabled = false;
                                });
                            } else {
                                GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/partner_regisztráció/regisztráció_sikertelen",
                                    "HU Partner [PRIVÁT] - Regisztráció - Sikertelen regisztráció")
                                $scope.sendButtonDisabled = false;
                            }
                        }, function () {
                            GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/partner_regisztráció/regisztráció_sikertelen",
                                "HU Partner [PRIVÁT] - Regisztráció - Sikertelen regisztráció")
                        });
                    });
                }, 300);
            }
        }
    })

    .controller('RegisterController', function ($scope, AuthService, $window, GoogleAnalyticsService, $document) {
        $scope.successRegistration = false;
        $scope.sendButtonDisabled = false;
        /*if ($window.innerHeight < 800 && $window.innerHeight > 650) {
            $scope.signup_upper = { "height": "200vh" };
        } else if ($window.innerHeight < 650) {
            $scope.signup_upper = { "height": "220vh" };
        } else {
            $scope.signup_upper = { "height": "150vh" };
        }*/

        $scope.checkChanges = function () {
            if ($scope.partnerData.companyName) {
                $scope.partnerData.companyName = $scope.partnerData.companyName.trim();
            }
            if ($scope.partnerData.taxNumber) {
                $scope.partnerData.taxNumber = $scope.partnerData.taxNumber.trim();
            }
            if ($scope.partnerData.onlinePage) {
                $scope.partnerData.onlinePage = $scope.partnerData.onlinePage.trim();
            }
            if ($scope.partnerData.title) {
                $scope.partnerData.title = $scope.partnerData.title.trim();
            }
            if ($scope.partnerData.lastName) {
                $scope.partnerData.lastName = $scope.partnerData.lastName.trim();
            }
            if ($scope.partnerData.firstName) {
                $scope.partnerData.firstName = $scope.partnerData.firstName.trim();
            }
            if ($scope.partnerData.email) {
                $scope.partnerData.email = $scope.partnerData.email.trim();
            }
            if ($scope.partnerData.comments) {
                $scope.partnerData.comments = $scope.partnerData.comments.trim();
            }
        }

        $scope.signup = function (partnerData) {
            if (!$scope.signupForm.$valid) {
                if (angular.element($document[0].querySelector('input.ng-invalid')).length > 0) {
                    angular.element($document[0].querySelector('input.ng-invalid')).focus();
                } else {
                    angular.element($document[0].querySelector('textarea.ng-invalid')).focus();
                }
            } else {
                $scope.sendButtonDisabled = true;
                AuthService.signup(partnerData).then(function (result) {
                    if (result.data.errorCode == 0) {
                        GoogleAnalyticsService.sendDataToGA("/EN/partneri/kapcsolati_lap/elküldés_sikeres",
                            "EN Partner - Kapcsolati lap (CONTACT FORM) - Sikeres elküldés (szerver visszaigazolta)")
                        $scope.successRegistration = true;
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                    } else {
                        GoogleAnalyticsService.sendDataToGA("/EN/partneri/kapcsolati_lap/elküldés_sikertelen",
                            "EN Partner - Kapcsolati lap (CONTACT FORM) - Sikertelen elküldés (szerver hibajelzés)")
                        $scope.sendButtonDisabled = false;
                    }
                    //$scope.signup_upper = { "height": "100vh" };
                }, function (result) {
                    GoogleAnalyticsService.sendDataToGA("/EN/partneri/kapcsolati_lap/elküldés_sikertelen",
                        "EN Partner - Kapcsolati lap (CONTACT FORM) - Sikertelen elküldés (szerver hibajelzés)")
                });
            }
        }
    })
    .controller('RegisterController_HU', function ($scope, AuthService, $window, $document, GoogleAnalyticsService) {
        $scope.successRegistration = false;
        $scope.sendButtonDisabled = false;
        $scope.thanksForFilling = "";
        /*if ($window.innerHeight < 861 && $window.innerHeight > 650) {
            $scope.signup_upper = { "height": "235vh" };
        } else if ($window.innerHeight < 650) {
            $scope.signup_upper = { "height": "240vh" };
        } else {
            $scope.signup_upper = { "height": "170vh" };
        }*/

        $scope.checkChanges = function () {
            if ($scope.partnerData.companyName) {
                $scope.partnerData.companyName = $scope.partnerData.companyName.trim();
            }
            if ($scope.partnerData.taxNumber) {
                $scope.partnerData.taxNumber = $scope.partnerData.taxNumber.trim();
            }
            if ($scope.partnerData.onlinePage) {
                $scope.partnerData.onlinePage = $scope.partnerData.onlinePage.trim();
            }
            if ($scope.partnerData.title) {
                $scope.partnerData.title = $scope.partnerData.title.trim();
            }
            if ($scope.partnerData.lastName) {
                $scope.partnerData.lastName = $scope.partnerData.lastName.trim();
            }
            if ($scope.partnerData.firstName) {
                $scope.partnerData.firstName = $scope.partnerData.firstName.trim();
            }
            if ($scope.partnerData.email) {
                $scope.partnerData.email = $scope.partnerData.email.trim();
            }
            if ($scope.partnerData.comments) {
                $scope.partnerData.comments = $scope.partnerData.comments.trim();
            }
        }

        $scope.signup = function (partnerData) {
            if (!$scope.signupForm.$valid) {
                if (angular.element($document[0].querySelector('input.ng-invalid')).length > 0) {
                    angular.element($document[0].querySelector('input.ng-invalid')).focus();
                } else {
                    angular.element($document[0].querySelector('textarea.ng-invalid')).focus();
                }
            } else {
                $scope.sendButtonDisabled = true;
                AuthService.signup(partnerData).then(function (result) {
                    if (result.data.errorCode == 0) {
                        $scope.successRegistration = true;
                        $scope.thanksForFilling = "Köszönjük a megkeresését!";
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                        GoogleAnalyticsService.sendDataToGA("/HU/partneri/kapcsolati_lap/elküldés_sikeres",
                            "HU Partner - Kapcsolati lap (CONTACT FORM) - Sikeres elküldés (szerver visszaigazolta)")
                    } else {
                        $scope.sendButtonDisabled = false;
                        GoogleAnalyticsService.sendDataToGA("/HU/partneri/kapcsolati_lap/elküldés_sikertelen",
                            "HU Partner - Kapcsolati lap (CONTACT FORM) - Sikertelen elküldés (szerver hibajelzés)")
                    }
                    //$scope.signup_upper = { "height": "100vh" };
                }, function (result) {
                    GoogleAnalyticsService.sendDataToGA("/HU/partneri/kapcsolati_lap/elküldés_sikertelen",
                        "HU Partner - Kapcsolati lap (CONTACT FORM) - Sikertelen elküldés (szerver hibajelzés)")
                });
            }
        }
    })

    .controller('AppCtrl', function ($scope, $rootScope, $cookies, $location, $state, AuthService, $mdMedia, $mdDialog, $window) {
        $scope.$mdMedia = $mdMedia;
        var mySidebar = document.getElementById("mySidebar");
        var overlayBg = document.getElementById("myOverlay");
        var openMenu = document.getElementById("openMenu");
        $scope.isOpenedAlert = false;
        $scope.partnerType = $cookies.get('partnerType');

        var lastTime = (new Date()).getTime();

        setInterval(function () {
            var url = $location.absUrl();
            if (url != null && !url.includes("?preview")) {
                const companyId = $window.localStorage['companyId'];
                if (companyId) {
                    if (!$scope.isOpenedAlert && $scope.companyId != companyId) {
                        $scope.isOpenedAlert = true;
                        var alert = $mdDialog.alert()
                            .title('Egyszerre csak egy partner oldala lehet nyitva.')
                            .textContent('Ez az oldal egy korábban megnyitott partnerhez tartozik. Az OK gomb megnyomására lecsukjuk ezt az oldalt.')
                            .ariaLabel('Session expired.')
                            .ok('OK')
                        $mdDialog.show(alert).then(function () {
                            $window.close();
                            $scope.isOpenedAlert = false;
                        });
                    }
                }
            }
            var currentTime = (new Date()).getTime();
            if (currentTime > (lastTime + 2000 * 2)) {  // ignore small delays
                if (currentTime > $rootScope.endDate) {
                    setTimeout(function () {
                        $state.go('partner_HU', { 'errorText': 'Lejárt az időkorlát.' });
                    }, 2500);
                }
            }
            lastTime = currentTime;
        }, 2000);

        if ($cookies.get('nameTitle') != undefined && $cookies.get('lastName') != undefined && $cookies.get('firstName') != undefined) {
            $scope.userName = $cookies.get('nameTitle') != 'null' ? $cookies.get('nameTitle') + ' ' + $cookies.get('lastName') + ' ' + $cookies.get('firstName') : $cookies.get('lastName') + ' ' + $cookies.get('firstName');
        } else {
            $scope.userName = '';
        }

        $scope.companyId = $window.localStorage['companyId'];
        $scope.userEmail = $cookies.get('userEmail');
        $scope.userCompany = $cookies.get('userCompany');
        $scope.userCompLogo = $cookies.get('compLogo');
        $scope.authorizationLevel = $cookies.get('authorizationLevel');
        $scope.adminUserType = $cookies.get('adminUserType');
        $scope.isMarketingPackagePageVisible = $cookies.get('isMarketingPackagePageVisible');
        $scope.areCustomDisplaySettingsForRedeemHandlingSheetEnabled = $cookies.get('areCustomDisplaySettingsForRedeemHandlingSheetEnabled');
        $scope.currentUserType = $cookies.get('currentUserType');
        let partnerHasActiveSub = $cookies.get('sbcrtyp') ? $cookies.get('sbcrtyp').split('&')[5] : false;
        $rootScope.hasPartnerActiveSub = (partnerHasActiveSub == 'true') || ($cookies.get('adminUserType') != null)

        if ($scope.authorizationLevel == 'prereg' || $rootScope.authorizationLevel == 'prereg' || $scope.currentUserType == 'preActivator' || $rootScope.hasPartnerActiveSub == false) {
            $scope.isUserHover = "inactiveMenu";
            $scope.modifyDisabled = false;
        } else {
            $scope.isUserHover = "yalty-hover-turquoise";
            $scope.modifyDisabled = true;
        }

        $scope.logout = function () {
            AuthService.logout()
        };

        $scope.refreshToken = function () {
            var currentState = $state.current.name;
            var payload = {};
            payload.state = currentState;
            payload.fromState = currentState;
            AuthService.isUserAuthorized(payload).then(function (result) {
                if (result.authorized === true && result.errorCode === 0) {
                    AuthService.updateSessionData(result)
                }
            })
        }

        $scope.yalty_open = function () {
            if (mySidebar.style.display === 'block') {
                mySidebar.style.display = 'none';
                overlayBg.style.display = "none";
                openMenu.style.display = "block";
            } else {
                document.getElementById('innerPartnerPageContainer').style.overflow = "hidden";
                mySidebar.style.display = 'block';
                overlayBg.style.display = "block";
                openMenu.style.display = "none";
            }
        }
        $scope.yalty_close = function () {
            if ($scope.partnerType == 2) {
                $state.go('home.huseg5');
            } else {
                $state.go('home.huseg1');
            }
            if (overlayBg.style.display == "block") {
                document.getElementById('innerPartnerPageContainer').style.overflow = "unset";
                mySidebar.style.display = "none";
                overlayBg.style.display = "none";
                openMenu.style.display = "block";
            }
        }

    })

    .directive('imgcropper', function () {
        return {
            restrict: 'E',
            replace: true,
            scope: {
                canvasid: '@',
                startimage: '@',
                input: '@',
                movable: '@',
                aspectratio: "@",
                minaspectratio: '@',
                maxaspectratio: '@',
                slider: '=',
                logoslider: '=',
                unstampslider: '=',
                slidermin: '=',
                slidermax: '=',
                stampicontype: '=',
                showstampicon: '=',
                stampiconstampedsizing: '=',
                stampiconunstampedsizing: '=',
                minheight: '@',
                minwidth: '@',
                resultheight: '@',
                resultwidth: '@',
                quality: '@',
                islogo: '@',
                iscomplogopushnotification: '@',
                iscompany: '@',
                iscompanystore: '@',
                isdetail: '@',
                isstamp: '@',
                isunstamp: '@',
                islist: '@',
                form: '=',
                isbarcode: '@'
            },
            template: '<canvas style="max-width:100%;"></canvas>',
            controller: function ($scope, $rootScope, $mdDialog) {
                $rootScope.isSelectedFile = false;
                var minAspectRatio = parseFloat($scope.minaspectratio);
                var maxAspectRatio = parseFloat($scope.maxaspectratio);
                var aspectRatio = $scope.aspectratio;

                var canvas = $('canvas[canvasid=' + $scope.canvasid + ']');
                var context = canvas.get(0).getContext("2d");
                var listimg;
                var canvasList;
                var contextList;
                var showListImageFromDetailImage = function (croppedImg, cropBox) {
                    canvasList = $("canvas[canvasid=canvasList]");
                    contextList = canvasList.get(0).getContext("2d");
                    listimg = new Image();
                    listimg.src = croppedImg;
                    listimg.onload = function () {
                        if ($rootScope.listCropperData.listcropper) {
                            $rootScope.listCropperData.listcropper.replace(croppedImg, true);
                            if (cropBox) {
                                $rootScope.listCropperData.listcropper.setCropBoxData(cropBox);
                            }
                        } else {
                            contextList.canvas.height = listimg.height;
                            contextList.canvas.width = listimg.width;
                            contextList.drawImage(listimg, 0, 0);
                            $rootScope.listCropperData.listcropper = new Cropper(contextList.canvas, {
                                dragMode: 'none',
                                viewMode: 1,
                                autoCropArea: 1,
                                background: false,
                                modal: false,
                                zoomOnTouch: false,
                                zoomOnWheel: false,
                                aspectRatio: 70 / 35,
                                cropBoxResizable: false,
                                minContainerWidth: 450,
                                minContainerHeight: 300,
                                minCropBoxWidth: listimg.width
                            });
                        }
                    };
                }
                const cropReady = (cropperDataName) => {
                    var containerData = $rootScope[cropperDataName].cropper.getContainerData();
                    var cropBoxData = $rootScope[cropperDataName].cropper.getCropBoxData();
                    var aspectRatio = cropBoxData.width / cropBoxData.height;
                    var newCropBoxWidth;
                    if (aspectRatio < minAspectRatio || aspectRatio > maxAspectRatio) {
                        var sum = minAspectRatio + maxAspectRatio;
                        var halfSum = sum / 2;
                        var cropBoxHeight = cropBoxData.height;
                        newCropBoxWidth = cropBoxHeight * halfSum;
                        if (cropBoxData.height > cropBoxData.width) {
                            $rootScope[cropperDataName].cropper.setCropBoxData({
                                width: cropBoxData.height,
                                height: cropBoxData.width
                            });
                        } else {
                            $rootScope[cropperDataName].cropper.setCropBoxData({
                                left: (containerData.width - newCropBoxWidth) / 2,
                                width: newCropBoxWidth
                            });
                        }
                    }
                }

                const cropMove = (cropperDataName) => {
                    var cropBoxData = $rootScope[cropperDataName].cropper.getCropBoxData();
                    var cropBoxWidth = cropBoxData.width;
                    var cropBoxHeight = cropBoxData.height;
                    var aspectRatio = cropBoxWidth / cropBoxHeight;
                    if (aspectRatio < minAspectRatio) {
                        $rootScope[cropperDataName].cropper.setCropBoxData({
                            height: cropBoxWidth / minAspectRatio
                        });
                        cropBoxData = $rootScope[cropperDataName].cropper.getCropBoxData();
                        cropBoxWidth = cropBoxData.width;
                        cropBoxHeight = cropBoxData.height;
                        aspectRatio = cropBoxWidth / cropBoxHeight;
                        if (aspectRatio < minAspectRatio) {
                            $rootScope[cropperDataName].cropper.setCropBoxData({
                                width: cropBoxHeight * minAspectRatio
                            });
                        }
                    } else if (aspectRatio > maxAspectRatio) {
                        $rootScope[cropperDataName].cropper.setCropBoxData({
                            height: cropBoxWidth / maxAspectRatio
                        });
                        cropBoxData = $rootScope[cropperDataName].cropper.getCropBoxData();
                        cropBoxWidth = cropBoxData.width;
                        cropBoxHeight = cropBoxData.height;
                        aspectRatio = cropBoxWidth / cropBoxHeight;
                        if (aspectRatio > maxAspectRatio) {
                            $rootScope[cropperDataName].cropper.setCropBoxData({
                                width: cropBoxHeight * maxAspectRatio
                            });
                        }
                    }
                }

                const cropEnd = (cropperDataName) => {
                    var cropBoxData = $rootScope[cropperDataName].cropper.getCropBoxData();
                    var cropBoxWidth = cropBoxData.width;
                    var cropBoxHeight = cropBoxData.height;
                    var aspectRatio = cropBoxWidth / cropBoxHeight;

                    if (aspectRatio < minAspectRatio) {
                        $rootScope[cropperDataName].cropper.setCropBoxData({
                            height: cropBoxWidth / minAspectRatio
                        });
                        cropBoxData = $rootScope[cropperDataName].cropper.getCropBoxData();
                        cropBoxWidth = cropBoxData.width;
                        cropBoxHeight = cropBoxData.height;
                        aspectRatio = cropBoxWidth / cropBoxHeight;
                        if (aspectRatio < minAspectRatio) {
                            $rootScope[cropperDataName].cropper.setCropBoxData({
                                width: cropBoxHeight * minAspectRatio
                            });
                        }
                    } else if (aspectRatio > maxAspectRatio) {
                        $rootScope[cropperDataName].cropper.setCropBoxData({
                            height: cropBoxWidth / maxAspectRatio
                        });
                        cropBoxData = $rootScope[cropperDataName].cropper.getCropBoxData();
                        cropBoxWidth = cropBoxData.width;
                        cropBoxHeight = cropBoxData.height;
                        aspectRatio = cropBoxWidth / cropBoxHeight;
                        if (aspectRatio > maxAspectRatio) {
                            $rootScope[cropperDataName].cropper.setCropBoxData({
                                width: cropBoxHeight * maxAspectRatio
                            });
                        }
                    }
                }
                if ($scope.islogo) {
                    /*$scope.$watch('logoslider', function (current, previous) {
                      if ($rootScope.logoCropperData.cropper && current != previous) {
                        var currentCropBoxData = $rootScope.logoCropperData.cropper.getCropBoxData();
                        if ($rootScope.logoCropperData.loadPreviousParams == true) {
                          $rootScope.logoCropperData.cropper.setCanvasData($rootScope.logoCropperData.logoCanvasData);
                          var containerData = $rootScope.logoCropperData.cropper.getContainerData();
                          $rootScope.logoCropperData.cropper.zoomTo($rootScope.logoCropperData.logoZoomRatio, {
                            x: containerData.width / 2,
                            y: containerData.height / 2,
                          });
                          $rootScope.logoCropperData.cropper.setCropBoxData($rootScope.logoCropperData.cropLogoBox);
                          $rootScope.logoCropperData.loadPreviousParams = false;
                        } else if (current == 0) {
                          $rootScope.logoCropperData.cropper.reset();
                        } else {
                          $rootScope.logoCropperData.cropper.reset();
                          $rootScope.logoCropperData.cropper.zoom(current / 10);
                          $rootScope.logoCropperData.cropper.setCropBoxData(currentCropBoxData);
                        }
                      }
                    });*/
                    $("#logoZoomIn").click(function () {
                        if ($scope.logoslider < 10) {
                            $scope.logoslider++;
                            $rootScope.logoCropperData.cropper.zoom(0.1);
                        }
                    });
                    $("#logoZoomOut").click(function () {
                        if ($scope.logoslider > 0) {
                            $scope.logoslider--;
                            $rootScope.logoCropperData.cropper.zoom(-0.1);
                        }
                    });
                    $("#logoZoomTotal").click(function () {
                        if ($scope.logoslider) {
                            $scope.logoslider = 0;
                            //$rootScope.logoCropperData.cropper.reset();
                            if ($rootScope.logoCropperData.cropper) {
                                $rootScope.logoCropperData.cropper.destroy();
                            }
                            $rootScope.logoCropperData.cropper = new Cropper(context.canvas, {
                                dragMode: 'move',
                                movable: false,
                                toggleDragModeOnDblclick: false,
                                viewMode: 0,
                                autoCropArea: 1,
                                background: true,
                                modal: false,
                                zoomOnTouch: false,
                                zoomOnWheel: false,
                                minContainerWidth: 350,
                                minContainerHeight: 350,
                                minCropBoxWidth: 100,
                                minCropBoxHeight: 100,
                                ready: function () { cropReady('logoCropperData') },
                                cropmove: function () { cropMove('logoCropperData') },
                                cropend: function () { cropEnd('logoCropperData') }
                            });
                        }
                    });
                    $("#confirmLogoImg").click(function () {
                        if ($scope.islogo) {
                            var cropBoxData = $rootScope.logoCropperData.cropper.getCropBoxData();
                            var minMaxAspectRatio = cropBoxData.width / cropBoxData.height;
                            var croppedWidth = $scope.resultheight * minMaxAspectRatio;
                            var canvasData = $rootScope.logoCropperData.cropper.getCanvasData();
                            var zoomRatio = canvasData.width / canvasData.naturalWidth;
                            if ($rootScope.logoCropperData.imageType == 'image/png') {
                                $rootScope.logoCropperData.compLogoImg = $rootScope.logoCropperData.cropper.getCroppedCanvas({ fillColor: 'transparent', height: $scope.resultheight, width: croppedWidth }).toDataURL("image/png", $scope.quality);
                            } else {
                                $rootScope.logoCropperData.compLogoImg = $rootScope.logoCropperData.cropper.getCroppedCanvas({ fillColor: '#fff', height: $scope.resultheight, width: croppedWidth }).toDataURL("image/jpeg", $scope.quality).replace("image/jpeg", "image/jpg");
                            }
                            $rootScope.logoCropperData.logoZoomRatio = zoomRatio;
                            $rootScope.logoCropperData.cropLogoBox = cropBoxData;
                            $rootScope.logoCropperData.croppedLogoImage = $scope.img;
                            $rootScope.logoCropperData.logoCanvasData = $rootScope.logoCropperData.cropper.getCanvasData();
                            $rootScope.logoCropperData.logoSliderValue = $scope.logoslider;
                            if ($scope.form.logoImage.$error.need == true) {
                                $scope.form.logoImage.$error.need = false;
                            }
                            off('overlayLogo');
                        }
                    });
                    $("#cancelLogoImg").click(function () {
                        if ($rootScope.logoCropperData.croppedLogoImage) {
                            context = null;
                            context = canvas.get(0).getContext("2d");
                            $scope.img = $rootScope.logoCropperData.croppedLogoImage;
                            context.canvas.height = $scope.img.height;
                            context.canvas.width = $scope.img.width;
                            context.drawImage($scope.img, 0, 0);
                            $rootScope.logoCropperData.cropper.replace(context.canvas.toDataURL());
                            if ($scope.logoslider) { $scope.logoslider = 0; }
                        } else {
                            if ($scope.logoslider) { $scope.logoslider = 0; }
                        }
                        off('overlayLogo');
                    });
                    $("#compLogoImg").click(function () {
                        if (!$rootScope.logoCropperData.compLogoImg.includes('/imgs/')) {
                            if ($rootScope.logoCropperData.logoZoomRatio && $rootScope.logoCropperData.logoCanvasData && $rootScope.logoCropperData.loadPreviousParams == false) {
                                on('overlayLogo');
                                if ($scope.logoslider != $rootScope.logoCropperData.logoSliderValue) {
                                    $rootScope.logoCropperData.loadPreviousParams = true;
                                    $scope.logoslider = $rootScope.logoCropperData.logoSliderValue;
                                    var currentCropBoxData = $rootScope.logoCropperData.cropper.getCropBoxData();
                                    if ($rootScope.logoCropperData.loadPreviousParams == true) {
                                        $rootScope.logoCropperData.cropper.setCanvasData($rootScope.logoCropperData.logoCanvasData);
                                        const containerData = $rootScope.logoCropperData.cropper.getContainerData();
                                        $rootScope.logoCropperData.cropper.zoomTo($rootScope.logoCropperData.logoZoomRatio, {
                                            x: containerData.width / 2,
                                            y: containerData.height / 2,
                                        });
                                        $rootScope.logoCropperData.cropper.setCropBoxData($rootScope.logoCropperData.cropLogoBox);
                                        $rootScope.logoCropperData.loadPreviousParams = false;
                                    } else {
                                        $rootScope.logoCropperData.cropper.reset();
                                        //$rootScope.logoCropperData.cropper.zoom(current / 10);
                                        $rootScope.logoCropperData.cropper.setCropBoxData(currentCropBoxData);
                                    }
                                } else {
                                    $rootScope.logoCropperData.cropper.setCanvasData($rootScope.logoCropperData.logoCanvasData);
                                    const containerData = $rootScope.logoCropperData.cropper.getContainerData();
                                    $rootScope.logoCropperData.cropper.zoomTo($rootScope.logoCropperData.logoZoomRatio, {
                                        x: containerData.width / 2,
                                        y: containerData.height / 2,
                                    });
                                    $rootScope.logoCropperData.cropper.setCropBoxData($rootScope.logoCropperData.cropLogoBox);
                                }
                            } else {
                                $rootScope.logoCropperData.loadPreviousParams = false;
                            }
                        }
                    });
                } else if ($scope.iscomplogopushnotification) {
                    $("#compLogoPushNotificationZoomIn").click(function () {
                        if ($scope.logoslider < 10) {
                            $scope.logoslider++;
                            $rootScope.compLogoPushNotificationCropperData.cropper.zoom(0.1);
                        }
                    });
                    $("#compLogoPushNotificationZoomOut").click(function () {
                        if ($scope.logoslider > 0) {
                            $scope.logoslider--;
                            $rootScope.compLogoPushNotificationCropperData.cropper.zoom(-0.1);
                        }
                    });
                    $("#compLogoPushNotificationZoomTotal").click(function () {
                        if ($scope.logoslider) {
                            $scope.logoslider = 0;
                            //$rootScope.compLogoPushNotificationCropperData.cropper.reset();
                            if ($rootScope.compLogoPushNotificationCropperData.cropper) {
                                $rootScope.compLogoPushNotificationCropperData.cropper.destroy();
                            }
                            $rootScope.compLogoPushNotificationCropperData.cropper = new Cropper(context.canvas, {
                                aspectRatio: 1 / 1,
                                dragMode: 'move',
                                movable: false,
                                toggleDragModeOnDblclick: false,
                                viewMode: 0,
                                autoCropArea: 1,
                                background: true,
                                modal: false,
                                zoomOnTouch: false,
                                zoomOnWheel: false,
                                minContainerWidth: 350,
                                minContainerHeight: 350,
                                minCropBoxWidth: 100,
                                minCropBoxHeight: 100,
                                ready: function () { cropReady('compLogoPushNotificationCropperData') },
                                cropmove: function () { cropMove('compLogoPushNotificationCropperData') },
                                cropend: function () { cropEnd('compLogoPushNotificationCropperData') }
                            });
                        }
                    });
                    $("#confirmCompLogoPushNotificationImg").click(function () {
                        var cropBoxData = $rootScope.compLogoPushNotificationCropperData.cropper.getCropBoxData();
                        var minMaxAspectRatio = cropBoxData.width / cropBoxData.height;
                        var croppedWidth = $scope.resultheight * minMaxAspectRatio;
                        var canvasData = $rootScope.compLogoPushNotificationCropperData.cropper.getCanvasData();
                        var zoomRatio = canvasData.width / canvasData.naturalWidth;
                        if ($rootScope.compLogoPushNotificationCropperData.imageType == 'image/png') {
                            $rootScope.compLogoPushNotificationCropperData.compLogoPushNotificationImg = $rootScope.compLogoPushNotificationCropperData.cropper.getCroppedCanvas({ fillColor: 'transparent', height: $scope.resultheight, width: croppedWidth }).toDataURL("image/png", $scope.quality);
                        } else {
                            $rootScope.compLogoPushNotificationCropperData.compLogoPushNotificationImg = $rootScope.compLogoPushNotificationCropperData.cropper.getCroppedCanvas({ fillColor: '#fff', height: $scope.resultheight, width: croppedWidth }).toDataURL("image/jpeg", $scope.quality).replace("image/jpeg", "image/jpg");
                        }
                        $rootScope.compLogoPushNotificationCropperData.logoZoomRatio = zoomRatio;
                        $rootScope.compLogoPushNotificationCropperData.cropLogoBox = cropBoxData;
                        $rootScope.compLogoPushNotificationCropperData.croppedCompLogoPushNotificationImage = $scope.img;
                        $rootScope.compLogoPushNotificationCropperData.logoCanvasData = $rootScope.compLogoPushNotificationCropperData.cropper.getCanvasData();
                        $rootScope.compLogoPushNotificationCropperData.logoSliderValue = $scope.logoslider;
                        if ($scope.form.compLogoPushNotificationImage.$error.need == true) {
                            $scope.form.compLogoPushNotificationImage.$error.need = false;
                        }
                        off('overlayCompLogoPushNotification');
                    });
                    $("#cancelCompLogoPushNotificationImg").click(function () {
                        if ($rootScope.compLogoPushNotificationCropperData.croppedCompLogoPushNotificationImage) {
                            context = null;
                            context = canvas.get(0).getContext("2d");
                            $scope.img = $rootScope.compLogoPushNotificationCropperData.croppedCompLogoPushNotificationImage;
                            context.canvas.height = $scope.img.height;
                            context.canvas.width = $scope.img.width;
                            context.drawImage($scope.img, 0, 0);
                            $rootScope.compLogoPushNotificationCropperData.cropper.replace(context.canvas.toDataURL());
                            if ($scope.logoslider) { $scope.logoslider = 0; }
                        } else {
                            if ($scope.logoslider) { $scope.logoslider = 0; }
                        }
                        off('overlayCompLogoPushNotification');
                    });
                    $("#compLogoPushNotificationImg").click(function () {
                        if (!$rootScope.compLogoPushNotificationCropperData.compLogoPushNotificationImg.includes('/imgs/')) {
                            if ($rootScope.compLogoPushNotificationCropperData.logoZoomRatio && $rootScope.compLogoPushNotificationCropperData.logoCanvasData && $rootScope.compLogoPushNotificationCropperData.loadPreviousParams == false) {
                                on('overlayCompLogoPushNotification');
                                if ($scope.logoslider != $rootScope.compLogoPushNotificationCropperData.logoSliderValue) {
                                    $rootScope.compLogoPushNotificationCropperData.loadPreviousParams = true;
                                    $scope.logoslider = $rootScope.compLogoPushNotificationCropperData.logoSliderValue;
                                    var currentCropBoxData = $rootScope.compLogoPushNotificationCropperData.cropper.getCropBoxData();
                                    if ($rootScope.compLogoPushNotificationCropperData.loadPreviousParams == true) {
                                        $rootScope.compLogoPushNotificationCropperData.cropper.setCanvasData($rootScope.compLogoPushNotificationCropperData.logoCanvasData);
                                        const containerData = $rootScope.compLogoPushNotificationCropperData.cropper.getContainerData();
                                        $rootScope.compLogoPushNotificationCropperData.cropper.zoomTo($rootScope.compLogoPushNotificationCropperData.logoZoomRatio, {
                                            x: containerData.width / 2,
                                            y: containerData.height / 2,
                                        });
                                        $rootScope.compLogoPushNotificationCropperData.cropper.setCropBoxData($rootScope.compLogoPushNotificationCropperData.cropLogoBox);
                                        $rootScope.compLogoPushNotificationCropperData.loadPreviousParams = false;
                                    } else {
                                        $rootScope.compLogoPushNotificationCropperData.cropper.reset();
                                        //$rootScope.compLogoPushNotificationCropperData.cropper.zoom(current / 10);
                                        $rootScope.compLogoPushNotificationCropperData.cropper.setCropBoxData(currentCropBoxData);
                                    }
                                } else {
                                    $rootScope.compLogoPushNotificationCropperData.cropper.setCanvasData($rootScope.compLogoPushNotificationCropperData.logoCanvasData);
                                    const containerData = $rootScope.compLogoPushNotificationCropperData.cropper.getContainerData();
                                    $rootScope.compLogoPushNotificationCropperData.cropper.zoomTo($rootScope.compLogoPushNotificationCropperData.logoZoomRatio, {
                                        x: containerData.width / 2,
                                        y: containerData.height / 2,
                                    });
                                    $rootScope.compLogoPushNotificationCropperData.cropper.setCropBoxData($rootScope.compLogoPushNotificationCropperData.cropLogoBox);
                                }
                            } else {
                                $rootScope.compLogoPushNotificationCropperData.loadPreviousParams = false;
                            }
                        }
                    });
                } else if ($scope.iscompany || $scope.iscompanystore) {
                    /*$scope.$watch('slider', function (current, previous) {
                      if ($rootScope.companyCropperData.cropper && current != previous) {
                        var currentCropBoxData = $rootScope.companyCropperData.cropper.getCropBoxData();
                        if ($rootScope.companyCropperData.loadPreviousParams == true) {
                          $rootScope.companyCropperData.cropper.setCanvasData($rootScope.companyCropperData.compCanvasData);
                          var containerData = $rootScope.companyCropperData.cropper.getContainerData();
                          $rootScope.companyCropperData.cropper.zoomTo($rootScope.companyCropperData.compZoomRatio, {
                            x: containerData.width / 2,
                            y: containerData.height / 2,
                          });
                          $rootScope.companyCropperData.cropper.setCropBoxData($rootScope.companyCropperData.cropCompBox);
                          $rootScope.companyCropperData.loadPreviousParams = false;
                        } else if (current == 0) {
                          $rootScope.companyCropperData.cropper.reset();
                        } else {
                          $rootScope.companyCropperData.cropper.reset();
                          $rootScope.companyCropperData.cropper.zoom(current / 10);
                          $rootScope.companyCropperData.cropper.setCropBoxData(currentCropBoxData);
                        }
                      }
                    });*/
                    $("#zoomIn").click(function () {
                        if ($scope.slider < 10) {
                            $scope.slider++;
                            $rootScope.companyCropperData.cropper.zoom(0.1);
                        }
                    });
                    $("#zoomOut").click(function () {
                        if ($scope.slider > 0) {
                            $scope.slider--;
                            $rootScope.companyCropperData.cropper.zoom(-0.1);
                        }
                    });
                    $("#zoomTotal").click(function () {
                        if ($scope.slider) {
                            $scope.slider = 0;
                            $rootScope.companyCropperData.cropper.reset();
                        }
                    });
                    $("#confirmCompImg").click(function () {
                        var canvasData = $rootScope.companyCropperData.cropper.getCanvasData();
                        var zoomRatio = canvasData.width / canvasData.naturalWidth;
                        if ($scope.iscompany) {
                            $rootScope.companyCropperData.compImg = $rootScope.companyCropperData.cropper.getCroppedCanvas({ fillColor: '#fff', height: $scope.resultheight, width: $scope.resultwidth }).toDataURL("image/jpeg", $scope.quality).replace("image/jpeg", "image/jpg");
                            if ($scope.form.companyImage.$error.need == true) {
                                $scope.form.companyImage.$error.need = false;
                            }
                        } else if ($scope.iscompanystore) {
                            $rootScope.companyCropperData.compStoreImage = $rootScope.companyCropperData.cropper.getCroppedCanvas({ fillColor: '#fff', height: $scope.resultheight, width: $scope.resultwidth }).toDataURL("image/jpeg", $scope.quality).replace("image/jpeg", "image/jpg");
                            if ($scope.form.storeImg.$error.need == true) {
                                $scope.form.storeImg.$error.need = false;
                            }
                        }
                        $rootScope.companyCropperData.cropCompBox = $rootScope.companyCropperData.cropper.getCropBoxData();
                        $rootScope.companyCropperData.compCanvasData = $rootScope.companyCropperData.cropper.getCanvasData();
                        $rootScope.companyCropperData.compZoomRatio = zoomRatio;
                        $rootScope.companyCropperData.croppedCompImage = $scope.img;
                        $rootScope.companyCropperData.sliderValue = $scope.slider;
                        off('overlayCompany');
                    });
                    $("#cancelCompImg").click(function () {
                        if ($rootScope.companyCropperData.croppedCompImage) {
                            context = null;
                            context = canvas.get(0).getContext("2d");
                            $scope.img = $rootScope.companyCropperData.croppedCompImage;
                            context.canvas.height = $scope.img.height;
                            context.canvas.width = $scope.img.width;
                            context.drawImage($scope.img, 0, 0);
                            $rootScope.companyCropperData.cropper.replace(context.canvas.toDataURL());
                            if ($scope.slider) { $scope.slider = 0; }
                        } else {
                            context = null;
                            context = canvas.get(0).getContext("2d");
                            $scope.img = null;
                            $scope.file = null;
                            $scope.reader = null;
                            $rootScope.companyCropperData.cropper.destroy();
                            if ($scope.slider) { $scope.slider = 0; }
                        }
                        off('overlayCompany');
                    });
                    $("#compImg").click(function () {
                        if ($rootScope.companyCropperData.compImg) {
                            if (!$rootScope.companyCropperData.compImg.includes('/imgs/')) {
                                on('overlayCompany');
                                if ($rootScope.companyCropperData.compZoomRatio && $rootScope.companyCropperData.compCanvasData && $rootScope.companyCropperData.loadPreviousParams == false) {
                                    if ($scope.slider != $rootScope.companyCropperData.sliderValue) {
                                        $rootScope.companyCropperData.loadPreviousParams = true;
                                        $scope.slider = $rootScope.companyCropperData.sliderValue;
                                        const currentCropBoxData = $rootScope.companyCropperData.cropper.getCropBoxData();
                                        if ($rootScope.companyCropperData.loadPreviousParams == true) {
                                            $rootScope.companyCropperData.cropper.setCanvasData($rootScope.companyCropperData.compCanvasData);
                                            const containerData = $rootScope.companyCropperData.cropper.getContainerData();
                                            $rootScope.companyCropperData.cropper.zoomTo($rootScope.companyCropperData.compZoomRatio, {
                                                x: containerData.width / 2,
                                                y: containerData.height / 2,
                                            });
                                            $rootScope.companyCropperData.cropper.setCropBoxData($rootScope.companyCropperData.cropCompBox);
                                            $rootScope.companyCropperData.loadPreviousParams = false;
                                        } else {
                                            $rootScope.companyCropperData.cropper.reset();
                                            //$rootScope.companyCropperData.cropper.zoom(current / 10);
                                            $rootScope.companyCropperData.cropper.setCropBoxData(currentCropBoxData);
                                        }
                                    } else {
                                        $rootScope.companyCropperData.cropper.setCanvasData($rootScope.companyCropperData.compCanvasData);
                                        const containerData = $rootScope.companyCropperData.cropper.getContainerData();
                                        $rootScope.companyCropperData.cropper.zoomTo($rootScope.companyCropperData.compZoomRatio, {
                                            x: containerData.width / 2,
                                            y: containerData.height / 2,
                                        });
                                        $rootScope.companyCropperData.cropper.setCropBoxData($rootScope.companyCropperData.cropCompBox);
                                    }
                                } else {
                                    $rootScope.companyCropperData.loadPreviousParams = false;
                                }
                            }
                        } else if ($rootScope.companyCropperData.compStoreImage) {
                            if ($rootScope.isSelectedFile && !$rootScope.companyCropperData.compStoreImage.includes('/imgs/')) {
                                on('overlayCompany');
                                if ($rootScope.companyCropperData.compZoomRatio && $rootScope.companyCropperData.compCanvasData && $rootScope.companyCropperData.loadPreviousParams == false) {
                                    $scope.slider = $rootScope.companyCropperData.sliderValue;
                                    $rootScope.companyCropperData.loadPreviousParams = true;
                                    const currentCropBoxData = $rootScope.companyCropperData.cropper.getCropBoxData();
                                    if ($rootScope.companyCropperData.loadPreviousParams == true) {
                                        $rootScope.companyCropperData.cropper.setCanvasData($rootScope.companyCropperData.compCanvasData);
                                        const containerData = $rootScope.companyCropperData.cropper.getContainerData();
                                        $rootScope.companyCropperData.cropper.zoomTo($rootScope.companyCropperData.compZoomRatio, {
                                            x: containerData.width / 2,
                                            y: containerData.height / 2,
                                        });
                                        $rootScope.companyCropperData.cropper.setCropBoxData($rootScope.companyCropperData.cropCompBox);
                                        $rootScope.companyCropperData.loadPreviousParams = false;
                                    } else {
                                        $rootScope.companyCropperData.cropper.reset();
                                        //$rootScope.companyCropperData.cropper.zoom(current / 10);
                                        $rootScope.companyCropperData.cropper.setCropBoxData(currentCropBoxData);
                                    }
                                } else {
                                    $rootScope.companyCropperData.loadPreviousParams = false;
                                }
                                $rootScope.companyCropperData.cropper.setCropBoxData($rootScope.companyCropperData.cropCompBox);
                            }
                        }
                    });
                } else if ($scope.isdetail) {
                    $scope.$watch('slider', function (current, previous) {
                        if ($rootScope.detailCropperData.cropper && current != previous) {
                            var currentCropBoxData = $rootScope.detailCropperData.cropper.getCropBoxData();
                            if ($rootScope.detailCropperData.loadPreviousParams == true) {
                                $rootScope.detailCropperData.cropper.setCanvasData($rootScope.detailCropperData.detailCanvasData);
                                var containerData = $rootScope.detailCropperData.cropper.getContainerData();
                                $rootScope.detailCropperData.cropper.zoomTo($rootScope.detailCropperData.zoomRatio, {
                                    x: containerData.width / 2,
                                    y: containerData.height / 2,
                                });
                                $rootScope.detailCropperData.cropper.setCropBoxData($rootScope.detailCropperData.cropDetailBox);
                                showListImageFromDetailImage($rootScope.detailCropperData.cropper.getCroppedCanvas({ fillColor: '#fff', height: $scope.resultheight, width: $scope.resultwidth }).toDataURL("image/jpeg", $scope.quality).replace("image/jpeg", "image/jpg"), $rootScope.listCropperData.cropListBox);
                                $rootScope.detailCropperData.loadPreviousParams = false;
                            } else if (current == 0) {
                                $rootScope.detailCropperData.cropper.reset();
                                showListImageFromDetailImage($rootScope.detailCropperData.cropper.getCroppedCanvas({ fillColor: '#fff', height: $scope.resultheight, width: $scope.resultwidth }).toDataURL("image/jpeg", $scope.quality).replace("image/jpeg", "image/jpg"));
                            } else {
                                $rootScope.detailCropperData.cropper.reset();
                                $rootScope.detailCropperData.cropper.zoom(current / 10);
                                $rootScope.detailCropperData.cropper.setCropBoxData(currentCropBoxData);
                                showListImageFromDetailImage($rootScope.detailCropperData.cropper.getCroppedCanvas({ fillColor: '#fff', height: $scope.resultheight, width: $scope.resultwidth }).toDataURL("image/jpeg", $scope.quality).replace("image/jpeg", "image/jpg"));
                            }
                        }
                    });
                    $("#zoomIn").click(function () {
                        if ($scope.slider < 10) {
                            $scope.slider++;
                        }
                    });
                    $("#zoomOut").click(function () {
                        if ($scope.slider > 0) {
                            $scope.slider--;
                        }
                    });
                    $("#zoomTotal").click(function () {
                        if ($scope.slider) {
                            $scope.slider = 0;
                        }
                    });
                    $("#confirmCouponImgs").click(function () {
                        $rootScope.isDataSheetModified = true;
                        var canvasData = $rootScope.detailCropperData.cropper.getCanvasData();
                        var zoomRatio = canvasData.width / canvasData.naturalWidth;
                        $rootScope.detailCropperData.detailCroppedImage = $rootScope.detailCropperData.cropper.getCroppedCanvas({ fillColor: '#fff', height: $scope.resultheight, width: $scope.resultwidth }).toDataURL("image/jpeg", $scope.quality).replace("image/jpeg", "image/jpg");
                        $rootScope.detailCropperData.cropDetailBox = $rootScope.detailCropperData.cropper.getCropBoxData();
                        $rootScope.detailCropperData.detailCanvasData = canvasData;
                        $rootScope.detailCropperData.zoomRatio = zoomRatio;
                        $rootScope.detailCropperData.croppedImage = $scope.img;
                        $rootScope.detailCropperData.sliderValue = $scope.slider;
                        $rootScope.listCropperData.listCroppedImage = $rootScope.listCropperData.listcropper.getCroppedCanvas({ fillColor: '#fff', height: 350, width: 700 }).toDataURL("image/jpeg", 0.9).replace("image/jpeg", "image/jpg");
                        $rootScope.listCropperData.cropListBox = $rootScope.listCropperData.listcropper.getCropBoxData();
                        if ($scope.form.listImg.$error.need == true) {
                            $scope.form.listImg.$error.need = false;
                        }
                        if ($scope.form.detailImg.$error.need == true) {
                            $scope.form.detailImg.$error.need = false;
                        }
                        off('overlayList');
                    });
                    $("#cancelCouponImgs").click(function () {
                        if ($rootScope.detailCropperData.croppedImage) {
                            context = null;
                            context = canvas.get(0).getContext("2d");
                            $scope.img = $rootScope.detailCropperData.croppedImage;
                            context.canvas.height = $scope.img.height;
                            context.canvas.width = $scope.img.width;
                            context.drawImage($scope.img, 0, 0);
                            $rootScope.detailCropperData.cropper.replace(context.canvas.toDataURL());
                            if ($scope.slider) { $scope.slider = 0; }
                        } else {
                            context = null;
                            context = canvas.get(0).getContext("2d");
                            $scope.img = null;
                            $scope.file = null;
                            $scope.reader = null;
                            $rootScope.detailCropperData.cropper.destroy();
                            if ($scope.slider) { $scope.slider = 0; }
                        }
                        off('overlayList');
                    });
                    $("#listCroppedImage").click(function () {
                        if ($rootScope.isSelectedFile == true && !$rootScope.listCropperData.listCroppedImage.includes('/imgs/') && !$rootScope.detailCropperData.detailCroppedImage.includes('/imgs/')) {
                            on('overlayList');
                            if ($rootScope.detailCropperData.zoomRatio && $rootScope.detailCropperData.detailCanvasData && $rootScope.detailCropperData.loadPreviousParams == false) {
                                if ($scope.slider != $rootScope.detailCropperData.sliderValue) {
                                    $rootScope.detailCropperData.loadPreviousParams = true;
                                    $scope.slider = $rootScope.detailCropperData.sliderValue;
                                } else {
                                    $rootScope.detailCropperData.cropper.setCanvasData($rootScope.detailCropperData.detailCanvasData);
                                    var containerData = $rootScope.detailCropperData.cropper.getContainerData();
                                    $rootScope.detailCropperData.cropper.zoomTo($rootScope.detailCropperData.zoomRatio, {
                                        x: containerData.width / 2,
                                        y: containerData.height / 2,
                                    });
                                    $rootScope.detailCropperData.cropper.setCropBoxData($rootScope.detailCropperData.cropDetailBox);
                                    showListImageFromDetailImage($rootScope.detailCropperData.cropper.getCroppedCanvas({ fillColor: '#fff', height: $scope.resultheight, width: $scope.resultwidth }).toDataURL("image/jpeg", $scope.quality).replace("image/jpeg", "image/jpg"), $rootScope.listCropperData.cropListBox);
                                }
                            } else {
                                $rootScope.detailCropperData.loadPreviousParams = false;
                            }
                        }
                    });
                    $("#detailCroppedImage").click(function () {
                        if ($rootScope.isSelectedFile == true && !$rootScope.listCropperData.listCroppedImage.includes('/imgs/') && !$rootScope.detailCropperData.detailCroppedImage.includes('/imgs/')) {
                            on('overlayList');
                            if ($rootScope.detailCropperData.zoomRatio && $rootScope.detailCropperData.detailCanvasData && $rootScope.detailCropperData.loadPreviousParams == false) {
                                if ($scope.slider != $rootScope.detailCropperData.sliderValue) {
                                    $rootScope.detailCropperData.loadPreviousParams = true;
                                    $scope.slider = $rootScope.detailCropperData.sliderValue;
                                } else {
                                    $rootScope.detailCropperData.cropper.setCanvasData($rootScope.detailCropperData.detailCanvasData);
                                    var containerData = $rootScope.detailCropperData.cropper.getContainerData();
                                    $rootScope.detailCropperData.cropper.zoomTo($rootScope.detailCropperData.zoomRatio, {
                                        x: containerData.width / 2,
                                        y: containerData.height / 2,
                                    });
                                    $rootScope.detailCropperData.cropper.setCropBoxData($rootScope.detailCropperData.cropDetailBox);
                                    showListImageFromDetailImage($rootScope.detailCropperData.cropper.getCroppedCanvas({ fillColor: '#fff', height: $scope.resultheight, width: $scope.resultwidth }).toDataURL("image/jpeg", $scope.quality).replace("image/jpeg", "image/jpg"), $rootScope.listCropperData.cropListBox);
                                }
                            } else {
                                $rootScope.detailCropperData.loadPreviousParams = false;
                            }
                        }
                    });
                } else if ($scope.isunstamp) {
                    $scope.$watch('unstampslider', function (current, previous) {
                        if ($rootScope.unstampCropperData.cropper && current != previous) {
                            var currentCropBoxData = $rootScope.unstampCropperData.cropper.getCropBoxData();
                            if ($rootScope.unstampCropperData.loadPreviousParams == true) {
                                $rootScope.unstampCropperData.cropper.setCanvasData($rootScope.unstampCropperData.compCanvasData);
                                var containerData = $rootScope.unstampCropperData.cropper.getContainerData();
                                $rootScope.unstampCropperData.cropper.zoomTo($rootScope.unstampCropperData.compZoomRatio, {
                                    x: containerData.width / 2,
                                    y: containerData.height / 2,
                                });
                                $rootScope.unstampCropperData.cropper.setCropBoxData($rootScope.unstampCropperData.cropCompBox);
                                $rootScope.unstampCropperData.loadPreviousParams = false;
                            } else if (current == 0) {
                                $rootScope.unstampCropperData.cropper.reset();
                            } else {
                                $rootScope.unstampCropperData.cropper.reset();
                                $rootScope.unstampCropperData.cropper.zoom(current / 10);
                                $rootScope.unstampCropperData.cropper.setCropBoxData(currentCropBoxData);
                            }
                        }
                    });
                    $("#zoomIn").click(function () {
                        if ($scope.unstampslider < 10) {
                            $scope.unstampslider++;
                        }
                    });
                    $("#zoomOut").click(function () {
                        if ($scope.unstampslider > 0) {
                            $scope.unstampslider--;
                        }
                    });
                    $("#zoomTotal").click(function () {
                        if ($scope.unstampslider) {
                            $scope.unstampslider = 0;
                        }
                    });
                    $("#confirmUnstampImg").click(function () {
                        var canvasData = $rootScope.unstampCropperData.cropper.getCanvasData();
                        var zoomRatio = canvasData.width / canvasData.naturalWidth;
                        $rootScope.unstampCropperData.unstampImg = $rootScope.unstampCropperData.cropper.getCroppedCanvas({ fillColor: 'transparent', height: $scope.resultheight, width: $scope.resultwidth }).toDataURL("image/png", $scope.quality);
                        $rootScope.unstampCropperData.originalUnstampImg = $rootScope.unstampCropperData.cropper.getCroppedCanvas({ fillColor: 'transparent', height: $scope.resultheight, width: $scope.resultwidth }).toDataURL("image/png", $scope.quality);
                        $rootScope.unstampCropperData.previewUnstampImg = $rootScope.unstampCropperData.cropper.getCroppedCanvas({ fillColor: 'transparent', height: $scope.resultheight, width: $scope.resultwidth }).toDataURL("image/png", $scope.quality);
                        if ($scope.form.unstampImg.$error.need == true) {
                            $scope.form.unstampImg.$error.need = false;
                        }
                        $rootScope.unstampCropperData.cropCompBox = $rootScope.unstampCropperData.cropper.getCropBoxData();
                        $rootScope.unstampCropperData.compCanvasData = $rootScope.unstampCropperData.cropper.getCanvasData();
                        $rootScope.unstampCropperData.compZoomRatio = zoomRatio;
                        $rootScope.unstampCropperData.croppedUnstampImage = $scope.img;
                        $rootScope.unstampCropperData.sliderValue = $scope.unstampslider;
                        $scope.stampicontype = 2;
                        $scope.showstampicon = true;
                        if ($scope.stampiconstampedsizing == null) {
                            $scope.stampiconstampedsizing = 2;
                        }
                        if ($scope.stampiconunstampedsizing == null) {
                            $scope.stampiconunstampedsizing = 2;
                        }
                        off('overlayunstamp');
                    });
                    $("#cancelUnstampImg").click(function () {
                        if ($rootScope.unstampCropperData.croppedUnstampImage) {
                            context = null;
                            context = canvas.get(0).getContext("2d");
                            $scope.img = $rootScope.unstampCropperData.croppedUnstampImage;
                            context.canvas.height = $scope.img.height;
                            context.canvas.width = $scope.img.width;
                            context.drawImage($scope.img, 0, 0);
                            $rootScope.unstampCropperData.cropper.replace(context.canvas.toDataURL());
                            if ($scope.unstampslider) { $scope.unstampslider = 0; }
                        } else {
                            context = null;
                            context = canvas.get(0).getContext("2d");
                            $scope.img = null;
                            $scope.file = null;
                            $scope.reader = null;
                            $rootScope.unstampCropperData.cropper.destroy();
                            if ($scope.unstampslider) { $scope.unstampslider = 0; }
                        }
                        off('overlayunstamp');
                    });
                    $("#unstampImg").click(function () {
                        if ($rootScope.unstampCropperData.originalUnstampImg) {
                            if ($rootScope.isSelectedFile && !$rootScope.unstampCropperData.originalUnstampImg.includes('/imgs/')) {
                                on('overlayunstamp');
                                if ($rootScope.unstampCropperData.compZoomRatio && $rootScope.unstampCropperData.compCanvasData && $rootScope.unstampCropperData.loadPreviousParams == false) {
                                    if ($scope.unstampslider != $rootScope.unstampCropperData.sliderValue) {
                                        $rootScope.unstampCropperData.loadPreviousParams = true;
                                        $scope.unstampslider = $rootScope.unstampCropperData.sliderValue;
                                    } else {
                                        $rootScope.unstampCropperData.cropper.setCanvasData($rootScope.unstampCropperData.compCanvasData);
                                        var containerData = $rootScope.unstampCropperData.cropper.getContainerData();
                                        $rootScope.unstampCropperData.cropper.zoomTo($rootScope.unstampCropperData.compZoomRatio, {
                                            x: containerData.width / 2,
                                            y: containerData.height / 2,
                                        });
                                        $rootScope.unstampCropperData.cropper.setCropBoxData($rootScope.unstampCropperData.cropCompBox);
                                    }
                                } else {
                                    $rootScope.unstampCropperData.loadPreviousParams = false;
                                }
                            }
                        }
                    });
                } else if ($scope.isstamp) {
                    $scope.$watch('slider', function (current, previous) {
                        if ($rootScope.stampCropperData.cropper && current != previous) {
                            var currentCropBoxData = $rootScope.stampCropperData.cropper.getCropBoxData();
                            if ($rootScope.stampCropperData.loadPreviousParams == true) {
                                $rootScope.stampCropperData.cropper.setCanvasData($rootScope.stampCropperData.compCanvasData);
                                var containerData = $rootScope.stampCropperData.cropper.getContainerData();
                                $rootScope.stampCropperData.cropper.zoomTo($rootScope.stampCropperData.compZoomRatio, {
                                    x: containerData.width / 2,
                                    y: containerData.height / 2,
                                });
                                $rootScope.stampCropperData.cropper.setCropBoxData($rootScope.stampCropperData.cropCompBox);
                                $rootScope.stampCropperData.loadPreviousParams = false;
                            } else if (current == 0) {
                                $rootScope.stampCropperData.cropper.reset();
                            } else {
                                $rootScope.stampCropperData.cropper.reset();
                                $rootScope.stampCropperData.cropper.zoom(current / 10);
                                $rootScope.stampCropperData.cropper.setCropBoxData(currentCropBoxData);
                            }
                        }
                    });
                    $("#stampZoomIn").click(function () {
                        if ($scope.slider < 10) {
                            $scope.slider++;
                        }
                    });
                    $("#stampZoomOut").click(function () {
                        if ($scope.slider > 0) {
                            $scope.slider--;
                        }
                    });
                    $("#stampZoomTotal").click(function () {
                        if ($scope.slider) {
                            $scope.slider = 0;
                        }
                    });
                    $("#confirmStampImg").click(function () {
                        $scope.stampicontype = 2;
                        $scope.showstampicon = true;
                        if ($scope.stampiconstampedsizing == null) {
                            $scope.stampiconstampedsizing = 2;
                        }
                        if ($scope.stampiconunstampedsizing == null) {
                            $scope.stampiconunstampedsizing = 2;
                        }
                        var canvasData = $rootScope.stampCropperData.cropper.getCanvasData();
                        var zoomRatio = canvasData.width / canvasData.naturalWidth;
                        $rootScope.stampCropperData.stampImg = $rootScope.stampCropperData.cropper.getCroppedCanvas({ fillColor: 'transparent', height: $scope.resultheight, width: $scope.resultwidth }).toDataURL("image/png", $scope.quality);
                        $rootScope.stampCropperData.originalStampImg = $rootScope.stampCropperData.cropper.getCroppedCanvas({ fillColor: 'transparent', height: $scope.resultheight, width: $scope.resultwidth }).toDataURL("image/png", $scope.quality);
                        $rootScope.stampCropperData.previewStampImg = $rootScope.stampCropperData.cropper.getCroppedCanvas({ fillColor: 'transparent', height: $scope.resultheight, width: $scope.resultwidth }).toDataURL("image/png", $scope.quality);
                        if ($scope.form.stampImg.$error.need == true) {
                            $scope.form.stampImg.$error.need = false;
                        }
                        $rootScope.stampCropperData.cropCompBox = $rootScope.stampCropperData.cropper.getCropBoxData();
                        $rootScope.stampCropperData.compCanvasData = $rootScope.stampCropperData.cropper.getCanvasData();
                        $rootScope.stampCropperData.compZoomRatio = zoomRatio;
                        $rootScope.stampCropperData.croppedStampImage = $scope.img;
                        $rootScope.stampCropperData.sliderValue = $scope.slider;
                        off('overlayStamp');
                    });
                    $("#cancelStampImg").click(function () {
                        if ($rootScope.stampCropperData.croppedStampImage) {
                            context = null;
                            context = canvas.get(0).getContext("2d");
                            $scope.img = $rootScope.stampCropperData.croppedStampImage;
                            context.canvas.height = $scope.img.height;
                            context.canvas.width = $scope.img.width;
                            context.drawImage($scope.img, 0, 0);
                            $rootScope.stampCropperData.cropper.replace(context.canvas.toDataURL());
                            if ($scope.slider) { $scope.slider = 0; }
                        } else {
                            context = null;
                            context = canvas.get(0).getContext("2d");
                            $scope.img = null;
                            $scope.file = null;
                            $scope.reader = null;
                            $rootScope.stampCropperData.cropper.destroy();
                            if ($scope.slider) { $scope.slider = 0; }
                        }
                        off('overlayStamp');
                    });
                    $("#stampImg").click(function () {
                        if ($rootScope.stampCropperData.originalStampImg) {
                            if ($rootScope.isSelectedFile && !$rootScope.stampCropperData.originalStampImg.includes('/imgs/')) {
                                on('overlayStamp');
                                if ($rootScope.stampCropperData.compZoomRatio && $rootScope.stampCropperData.compCanvasData && $rootScope.stampCropperData.loadPreviousParams == false) {
                                    if ($scope.slider != $rootScope.stampCropperData.sliderValue) {
                                        $rootScope.stampCropperData.loadPreviousParams = true;
                                        $scope.slider = $rootScope.stampCropperData.sliderValue;
                                    } else {
                                        $rootScope.stampCropperData.cropper.setCanvasData($rootScope.stampCropperData.compCanvasData);
                                        var containerData = $rootScope.stampCropperData.cropper.getContainerData();
                                        $rootScope.stampCropperData.cropper.zoomTo($rootScope.stampCropperData.compZoomRatio, {
                                            x: containerData.width / 2,
                                            y: containerData.height / 2,
                                        });
                                        $rootScope.stampCropperData.cropper.setCropBoxData($rootScope.stampCropperData.cropCompBox);
                                    }
                                } else {
                                    $rootScope.stampCropperData.loadPreviousParams = false;
                                }
                            }
                        }
                    });
                } else if ($scope.isbarcode) {
                    $("#barcodeZoomIn").click(function () {
                        if ($scope.slider < 10) {
                            $scope.slider++;
                            $rootScope.barcodeCropperData.cropper.zoom(0.1);
                        }
                    });
                    $("#barcodeZoomOut").click(function () {
                        if ($scope.slider > 0) {
                            $scope.slider--;
                            $rootScope.barcodeCropperData.cropper.zoom(-0.1);
                        }
                    });
                    $("#barcodeZoomTotal").click(function () {
                        if ($scope.slider) {
                            $scope.slider = 0;
                            $rootScope.barcodeCropperData.cropper.reset();
                        }
                    });
                    $("#confirmbarcodeImg").click(function () {
                        if ($scope.isbarcode) {
                            var cropBoxData = $rootScope.barcodeCropperData.cropper.getCropBoxData();
                            var minMaxAspectRatio = cropBoxData.height / cropBoxData.width;
                            var croppedHeight = $scope.resultwidth * minMaxAspectRatio;
                            var canvasData = $rootScope.barcodeCropperData.cropper.getCanvasData();
                            var zoomRatio = canvasData.width / canvasData.naturalWidth;
                            if ($rootScope.barcodeCropperData.imageType == 'image/png') {
                                $rootScope.barcodeCropperData.barcodeImg = $rootScope.barcodeCropperData.cropper.getCroppedCanvas({ fillColor: 'transparent', height: croppedHeight, width: $scope.resultwidth }).toDataURL("image/png", $scope.quality);
                            } else {
                                $rootScope.barcodeCropperData.barcodeImg = $rootScope.barcodeCropperData.cropper.getCroppedCanvas({ fillColor: '#fff', height: croppedHeight, width: $scope.resultwidth }).toDataURL("image/jpeg", $scope.quality).replace("image/jpeg", "image/png");
                            }
                            $rootScope.barcodeCropperData.barcodeZoomRatio = zoomRatio;
                            $rootScope.barcodeCropperData.cropBarcodeBox = cropBoxData;
                            $rootScope.barcodeCropperData.croppedBarcodeImg = $scope.img;
                            $rootScope.barcodeCropperData.barcodeCanvasData = $rootScope.barcodeCropperData.cropper.getCanvasData();
                            $rootScope.barcodeCropperData.sliderValue = $scope.slider;
                            if ($scope.form.barcodeImg.$error.need == true) {
                                $scope.form.barcodeImg.$error.need = false;
                            }
                            off('overlaybarcode');
                        }
                    });
                    $("#cancelbarcodeImg").click(function () {
                        if ($rootScope.barcodeCropperData.croppedBarcodeImg) {
                            context = null;
                            context = canvas.get(0).getContext("2d");
                            $scope.img = $rootScope.barcodeCropperData.croppedBarcodeImg;
                            context.canvas.height = $scope.img.height;
                            context.canvas.width = $scope.img.width;
                            context.drawImage($scope.img, 0, 0);
                            $rootScope.barcodeCropperData.cropper.replace(context.canvas.toDataURL());
                            if ($scope.slider) { $scope.slider = 0; }
                        } else {
                            if ($scope.slider) { $scope.slider = 0; }
                        }
                        off('overlaybarcode');
                    });
                    $("#barcodeImg").click(function () {
                        if ($rootScope.isSelectedFile == true && !$rootScope.barcodeCropperData.barcodeImg.includes('/imgs/')) {
                            on('overlaybarcode');
                            if ($rootScope.barcodeCropperData.barcodeZoomRatio && $rootScope.barcodeCropperData.barcodeCanvasData && $rootScope.barcodeCropperData.loadPreviousParams == false) {
                                if ($scope.slider != $rootScope.barcodeCropperData.sliderValue) {
                                    $rootScope.barcodeCropperData.loadPreviousParams = true;
                                    $scope.slider = $rootScope.barcodeCropperData.sliderValue;
                                    var currentCropBoxData = $rootScope.barcodeCropperData.cropper.getCropBoxData();
                                    if ($rootScope.barcodeCropperData.loadPreviousParams == true) {
                                        $rootScope.barcodeCropperData.cropper.setCanvasData($rootScope.barcodeCropperData.barcodeCanvasData);
                                        const containerData = $rootScope.barcodeCropperData.cropper.getContainerData();
                                        $rootScope.barcodeCropperData.cropper.zoomTo($rootScope.barcodeCropperData.barcodeZoomRatio, {
                                            x: containerData.width / 2,
                                            y: containerData.height / 2,
                                        });
                                        $rootScope.barcodeCropperData.cropper.setCropBoxData($rootScope.barcodeCropperData.cropBarcodeBox);
                                        $rootScope.barcodeCropperData.loadPreviousParams = false;
                                    } else {
                                        $rootScope.barcodeCropperData.cropper.reset();
                                        //$rootScope.barcodeCropperData.cropper.zoom(current / 10);
                                        $rootScope.barcodeCropperData.cropper.setCropBoxData(currentCropBoxData);
                                    }
                                } else {
                                    $rootScope.barcodeCropperData.cropper.setCanvasData($rootScope.barcodeCropperData.barcodeCanvasData);
                                    const containerData = $rootScope.barcodeCropperData.cropper.getContainerData();
                                    $rootScope.barcodeCropperData.cropper.zoomTo($rootScope.barcodeCropperData.barcodeZoomRatio, {
                                        x: containerData.width / 2,
                                        y: containerData.height / 2,
                                    });
                                    $rootScope.barcodeCropperData.cropper.setCropBoxData($rootScope.barcodeCropperData.cropBarcodeBox);
                                }
                            } else {
                                $rootScope.barcodeCropperData.loadPreviousParams = false;
                            }
                        }
                    });
                }
                //angular.element(document.querySelector(inputValue)).on('change', function (e) {
                $scope.$watch('input', function (e) {
                    $rootScope.selectFile = function (selectedFile) {
                        if (selectedFile) {
                            if (selectedFile.type.match(/^image\//)) {
                                $scope.reader = new FileReader();
                                $scope.file = selectedFile;
                                $scope.reader.onloadstart = function () {
                                    on('loadingOverlay');
                                }
                                $scope.reader.onerror = function (err) {
                                    off('loadingOverlay');
                                    var alert = $mdDialog.alert()
                                        .title('Kép kiválasztási hiba.')
                                        .textContent('A kiválasztott fájl beolvasása sikertelen, kérjük ismételje meg!')
                                        .ariaLabel('')
                                        .ok('Rendben')
                                    $mdDialog.show(alert).then(function () { });
                                }
                                $scope.reader.onload = function (evt) {
                                    $rootScope.isSelectedFile = true;
                                    $scope.img = null;
                                    $scope.img = new Image();
                                    $scope.img.src = evt.target.result;
                                    $scope.img.onerror = function (err) {
                                        off('loadingOverlay');
                                        var alert = $mdDialog.alert()
                                            .title('Kép kiválasztási hiba.')
                                            .textContent('A kiválasztott fájl nem képfájl, vagy a fájl sérült!')
                                            .ariaLabel('')
                                            .ok('Rendben')
                                        $mdDialog.show(alert).then(function () { });
                                    }
                                    $scope.img.onload = function () {
                                        if ($scope.img.height < $scope.minheight || $scope.img.width < $scope.minwidth) {
                                            off('loadingOverlay');
                                            var alert = $mdDialog.alert()
                                                .title('Nagyobb felbontású kép megadása javasolt.')
                                                .htmlContent("<p>A minimális javasolt képméret " + $scope.minwidth + " x " + $scope.minheight + ' pixel (szélesség x magasság).<br>Jelenlegi képméret ' + $scope.img.width + " x " + $scope.img.height + " pixel (szélesség x magasság).</p>")
                                                .ariaLabel('')
                                                .ok('Rendben')
                                            $mdDialog.show(alert).then(function () { });
                                        }
                                        if ($scope.slider) { $scope.slider = 0; }
                                        if ($scope.logoslider) { $scope.logoslider = 0; }
                                        if ($scope.unstampslider) { $scope.unstampslider = 0; }
                                        if ($scope.islogo && minAspectRatio && maxAspectRatio) {
                                            off('loadingOverlay');
                                            on('overlayLogo');
                                            context.canvas.height = $scope.img.height;
                                            context.canvas.width = $scope.img.width;
                                            context.drawImage($scope.img, 0, 0);
                                            if ($rootScope.logoCropperData.cropper) {
                                                $rootScope.logoCropperData.cropper.destroy();
                                            }
                                            $rootScope.logoCropperData.cropper = new Cropper(context.canvas, {
                                                dragMode: 'move',
                                                movable: false,
                                                toggleDragModeOnDblclick: false,
                                                viewMode: 0,
                                                autoCropArea: 1,
                                                background: true,
                                                modal: false,
                                                zoomOnTouch: false,
                                                zoomOnWheel: false,
                                                minContainerWidth: 350,
                                                minContainerHeight: 350,
                                                minCropBoxWidth: 100,
                                                minCropBoxHeight: 100,
                                                ready: function () { cropReady('logoCropperData') },
                                                cropmove: function () { cropMove('logoCropperData') },
                                                cropend: function () { cropEnd('logoCropperData') }
                                            });
                                            $rootScope.logoCropperData.imageType = selectedFile.type;
                                        } else if ($scope.iscomplogopushnotification && minAspectRatio && maxAspectRatio) {
                                            off('loadingOverlay');
                                            on('overlayCompLogoPushNotification');
                                            context.canvas.height = $scope.img.height;
                                            context.canvas.width = $scope.img.width;
                                            context.drawImage($scope.img, 0, 0);
                                            if ($rootScope.compLogoPushNotificationCropperData.cropper) {
                                                $rootScope.compLogoPushNotificationCropperData.cropper.destroy();
                                            }
                                            $rootScope.compLogoPushNotificationCropperData.cropper = new Cropper(context.canvas, {
                                                aspectRatio: 1 / 1,
                                                dragMode: 'move',
                                                movable: false,
                                                toggleDragModeOnDblclick: false,
                                                viewMode: 0,
                                                autoCropArea: 1,
                                                background: true,
                                                modal: false,
                                                zoomOnTouch: false,
                                                zoomOnWheel: false,
                                                minContainerWidth: 350,
                                                minContainerHeight: 350,
                                                minCropBoxWidth: 100,
                                                minCropBoxHeight: 100,
                                                ready: function () { cropReady('compLogoPushNotificationCropperData') },
                                                cropmove: function () { cropMove('compLogoPushNotificationCropperData') },
                                                cropend: function () { cropEnd('compLogoPushNotificationCropperData') }
                                            });
                                            $rootScope.compLogoPushNotificationCropperData.imageType = selectedFile.type;
                                        } else if (aspectRatio && $scope.resultwidth && $scope.resultheight && $scope.iscompany || $scope.iscompanystore) {
                                            off('loadingOverlay');
                                            on('overlayCompany');
                                            context.canvas.height = $scope.img.height;
                                            context.canvas.width = $scope.img.width;
                                            context.drawImage($scope.img, 0, 0);
                                            const ratio = $scope.resultwidth / $scope.resultheight;
                                            if ($rootScope.companyCropperData.cropper) {
                                                $rootScope.companyCropperData.cropper.replace($scope.img.src);
                                            } else {
                                                $rootScope.companyCropperData.cropper = new Cropper(context.canvas, {
                                                    dragMode: 'move',
                                                    toggleDragModeOnDblclick: false,
                                                    viewMode: 1,
                                                    autoCropArea: 1,
                                                    background: false,
                                                    modal: false,
                                                    zoomOnTouch: false,
                                                    zoomOnWheel: false,
                                                    aspectRatio: ratio,
                                                    minContainerWidth: 470,
                                                    minContainerHeight: 310,
                                                    minCropBoxWidth: 144,
                                                    minCropBoxHeight: 100,
                                                });
                                            }
                                        } else if ($scope.isdetail) {
                                            off('loadingOverlay');
                                            on('overlayList');
                                            context.canvas.height = $scope.img.height;
                                            context.canvas.width = $scope.img.width;
                                            context.drawImage($scope.img, 0, 0);
                                            const ratio = $scope.resultwidth / $scope.resultheight;
                                            if ($rootScope.detailCropperData.cropper) {
                                                $rootScope.detailCropperData.cropper.replace($scope.img.src);
                                            } else {
                                                $rootScope.detailCropperData.cropper = new Cropper(context.canvas, {
                                                    dragMode: 'move',
                                                    toggleDragModeOnDblclick: false,
                                                    viewMode: 1,
                                                    autoCropArea: 1,
                                                    background: false,
                                                    modal: false,
                                                    zoomOnTouch: false,
                                                    zoomOnWheel: false,
                                                    aspectRatio: ratio,
                                                    minContainerWidth: 450,
                                                    minContainerHeight: 300,
                                                    minCropBoxWidth: 144,
                                                    minCropBoxHeight: 100,
                                                    ready: function () {
                                                        showListImageFromDetailImage($rootScope.detailCropperData.cropper.getCroppedCanvas({ fillColor: '#fff', height: $scope.resultheight, width: $scope.resultwidth }).toDataURL("image/jpeg", $scope.quality).replace("image/jpeg", "image/jpg"));
                                                    },
                                                    cropend: function () {
                                                        showListImageFromDetailImage($rootScope.detailCropperData.cropper.getCroppedCanvas({ fillColor: '#fff', height: $scope.resultheight, width: $scope.resultwidth }).toDataURL("image/jpeg", $scope.quality).replace("image/jpeg", "image/jpg"));
                                                    }
                                                });
                                            }
                                        } else if (aspectRatio && $scope.resultwidth && $scope.resultheight && $scope.isunstamp) {
                                            off('loadingOverlay');
                                            on('overlayunstamp');
                                            context.canvas.height = $scope.img.height;
                                            context.canvas.width = $scope.img.width;
                                            context.drawImage($scope.img, 0, 0);
                                            const ratio = $scope.resultwidth / $scope.resultheight;
                                            $rootScope.isDataSheetModified = true;
                                            if ($rootScope.unstampCropperData.cropper) {
                                                $rootScope.unstampCropperData.cropper.replace($scope.img.src);
                                            } else {
                                                $rootScope.unstampCropperData.cropper = new Cropper(context.canvas, {
                                                    dragMode: 'move',
                                                    movable: false,
                                                    toggleDragModeOnDblclick: false,
                                                    viewMode: 0,
                                                    autoCropArea: 1,
                                                    background: true,
                                                    modal: false,
                                                    zoomOnTouch: false,
                                                    zoomOnWheel: false,
                                                    aspectRatio: ratio,
                                                    minContainerWidth: 470,
                                                    minContainerHeight: 310,
                                                    minCropBoxWidth: 144,
                                                    minCropBoxHeight: 100,
                                                });
                                            }
                                        } else if (aspectRatio && $scope.resultwidth && $scope.resultheight && $scope.isstamp) {
                                            off('loadingOverlay');
                                            on('overlayStamp');
                                            context.canvas.height = $scope.img.height;
                                            context.canvas.width = $scope.img.width;
                                            context.drawImage($scope.img, 0, 0);
                                            const ratio = $scope.resultwidth / $scope.resultheight;
                                            $rootScope.isDataSheetModified = true;
                                            if ($rootScope.stampCropperData.cropper) {
                                                $rootScope.stampCropperData.cropper.replace($scope.img.src);
                                            } else {
                                                $rootScope.stampCropperData.cropper = new Cropper(context.canvas, {
                                                    dragMode: 'move',
                                                    movable: false,
                                                    toggleDragModeOnDblclick: false,
                                                    viewMode: 0,
                                                    autoCropArea: 1,
                                                    modal: false,
                                                    background: true,
                                                    zoomOnTouch: false,
                                                    zoomOnWheel: false,
                                                    aspectRatio: ratio,
                                                    minContainerWidth: 470,
                                                    minContainerHeight: 310,
                                                    minCropBoxWidth: 144,
                                                    minCropBoxHeight: 100,
                                                });
                                            }
                                        } else if ($scope.isbarcode) {
                                            off('loadingOverlay');
                                            on('overlaybarcode');
                                            context.canvas.height = $scope.img.height;
                                            context.canvas.width = $scope.img.width;
                                            context.drawImage($scope.img, 0, 0);
                                            if ($rootScope.barcodeCropperData.cropper) {
                                                $rootScope.barcodeCropperData.cropper.replace($scope.img.src);
                                                $rootScope.barcodeCropperData.imageType = selectedFile.type;
                                            } else {
                                                $rootScope.barcodeCropperData.cropper = new Cropper(context.canvas, {
                                                    dragMode: 'move',
                                                    toggleDragModeOnDblclick: false,
                                                    viewMode: 1,
                                                    autoCropArea: 1,
                                                    background: false,
                                                    modal: false,
                                                    zoomOnTouch: false,
                                                    zoomOnWheel: false,
                                                    minContainerWidth: 470,
                                                    minContainerHeight: 310,
                                                    minCropBoxWidth: 100,
                                                    minCropBoxHeight: 100,
                                                    ready: function () { cropReady('barcodeCropperData') },
                                                    cropmove: function () { cropMove('barcodeCropperData') },
                                                    cropend: function () { cropEnd('barcodeCropperData') }
                                                });
                                                $rootScope.barcodeCropperData.imageType = selectedFile.type;
                                            }
                                        }
                                    }
                                };
                                $scope.reader.readAsDataURL($scope.file);
                            } else {
                                var alert = $mdDialog.alert()
                                    .title('Kép kiválasztási hiba.')
                                    .textContent('Nem megfelelő vagy nem támogatott fájl formátum!')
                                    .ariaLabel('')
                                    .ok('Rendben')
                                $mdDialog.show(alert).then(function () { });
                            }
                        } else {
                            //alert("No $scope.file(s) selected.");
                        }

                    }
                });
            }
        }
    })

    .directive('consent', function ($cookies) {
        return {
            restrict: 'E',
            scope: {
                dataText: '@datatext',
                other: '@other',
                acceptedText: '@acceptedtext'
            },
            template:
                '<div style="position: relative; z-index: 1000">' +
                '<div class="cookie_policy" ng-hide="consent()">' +
                '<div layout="row">' +
                '<div flex="90">' +
                '<p style="color: white; padding-right: 90px; padding-left: 10px;">{{dataText}}' +
                '<a style="color: #00AEEF;text-decoration: none;" href="/views/cookie_szabalyzat.html" target="blank">{{other}}</a></p>' +
                '</div>' +
                '<div flex="10" style="margin: auto;">' +
                '<md-button class="acceptPolicyButton" ng-click="consent(true)">{{acceptedText}}</md-button>' +
                '</div>' +
                '</div>' +
                '</div>' +
                '</div>',
            controller: function ($scope) {
                var _consent = $cookies.get('com.yalty.www.cookieWarningAccepted');
                $scope.consent = function (consent) {
                    if (consent === undefined) {
                        return _consent;
                    } else if (consent) {
                        $cookies.put('com.yalty.www.cookieWarningAccepted', true);
                        _consent = true;
                    }
                };
            }
        };
    });

function on(elementId) {
    document.getElementById(elementId).style.display = "block";
    //document.getElementById('innerPartnerPageContainer').style.overflow = "hidden";
}
function off(elementId) {
    document.getElementById(elementId).style.display = "none";
    //document.getElementById('innerPartnerPageContainer').style.overflow = "unset";
}
function onDiscountOverlay(elementId) {
    document.getElementById(elementId).style.display = "block";
}
function offDiscountOverlay(elementId) {
    document.getElementById(elementId).style.display = "none";
}