import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { combineLatest, forkJoin, fromEvent, Observable, pipe, Subject, Subscription } from 'rxjs';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { HttpService } from 'src/app/shared/services/http.service';
import { ErrorHandlerService } from "src/app/shared/services/error-handler.service";
import { FormValidatorService } from "src/app/shared/services/form-validator.service";
import { CitiesService } from "src/app/shared/services/cities.service";
import { GetAllCompanyStoreListViewDataRequest, GetAllCompanyStoreListViewDataResponse, CompanyStoreListViewData } from "src/app/shared/interfaces/stores";
import { ErrorCodeResponse } from 'src/app/shared/interfaces/error-code-response';
import { TableHeader } from 'src/app/shared/interfaces/table';
import { GetCitiesRequest } from 'src/app/shared/interfaces/get-cities';
import { DEFAULT_COUNTRY_NAME } from 'src/app/shared/globals/variables';
import { GoogleMapsComponent } from 'src/app/shared/components/google-maps/google-maps.component';
import { debounceTime, distinctUntilChanged, filter, map, startWith, throttleTime } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { SelectInput } from 'src/app/shared/interfaces/input';
import { CommonService } from 'src/app/shared/services/common.service';
import { AddOrUpdateCompanyStoreRequest, AddOrUpdateCompanyStoreResponse } from 'src/app/shared/interfaces/stores';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogComponent } from 'src/app/shared/components/dialog/dialog.component';
import { RemoveCompanyStoreRequest } from 'src/app/shared/interfaces/stores';
import { merge } from 'rxjs';
import { SubscriptionService } from 'src/app/shared/services/subscription.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { OverlayContainer } from '@angular/cdk/overlay';
import { GoogleAnalyticsService } from 'src/app/shared/services/google-analytics.service';

@Component({
    selector: 'app-aggregate-stores',
    templateUrl: './aggregate-stores.component.html',
    styleUrls: ['./aggregate-stores.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AggregateStoresComponent implements OnInit {

    @ViewChild('googlemaps') private googleMapsChild: GoogleMapsComponent;
    //@ViewChild('table') private table: ElementRef;

    currentState = 'PARTNERI ADATOK';
    currentSubState = 'Üzletek csoportos kezelése';
    aggregateStoreWindowForm: FormGroup;
    filtersForm: FormGroup;
    sortForm: FormGroup;
    waitingForAllStoreAPIResult = false;
    waitingForAddOrUpdateAPIResult = false;
    waitingForDeleteAPIResult: boolean[] = [];
    getAllCompanyStoreListViewDataRequest: GetAllCompanyStoreListViewDataRequest = {
        getDataForCompanyStoreAggregateHandling: true
    };
    getAllCompanyStoreListViewDataResponse: GetAllCompanyStoreListViewDataResponse;
    addOrUpdateCompanyStoreRequest: AddOrUpdateCompanyStoreRequest;
    addOrUpdateCompanyStoreResponse: AddOrUpdateCompanyStoreResponse;
    allCompanyStore: CompanyStoreListViewData[] = [];
    filteredCompanyStores: CompanyStoreListViewData[] = [];
    storeTableHeader: TableHeader[] = [
        { label: 'id', contentRef: 'id', style: { 'width': '30px' } },
        { label: 'Üzlet neve', contentRef: 'compStoreName', style: { 'width': '130px' } },
        { label: 'Külső id', contentRef: 'externalStoreId', style: { 'width': '60px' } },
        { label: 'Ir. szám', contentRef: 'compStoreAddrZIP', style: { 'width': '50px' } },
        { label: 'Város', contentRef: 'compStoreAddrCity', style: { 'width': '130px' } },
        { label: 'Utca', contentRef: 'compStoreAddrStreet', style: { 'width': '130px' } },
        { label: 'Házszám', contentRef: 'compStoreAddrNo', style: { 'width': '55px' } },
        { label: 'GPS szél./lat.', contentRef: 'latitudeCoordinate', style: { 'width': '85px' } },
        { label: 'GPS h./long.', contentRef: 'longitudeCoordinate', style: { 'width': '85px' } }
        //20250214 Yalty városok kivezetése: "Yalty város" mező kivezetése miatt erre a mezőre már nincs szükség
        //{ label: 'Yalty város', contentRef: 'yaltyCity', style: { 'width': '80px' } },
    ]
    sortOptions: SelectInput[] = [
        { value: 1, display: 'Város A-Z > Isz. A-Z > Üzlet neve A-Z' },
        { value: 2, display: 'id A-Z' },
        { value: 3, display: 'Üzlet neve A-Z' },
        { value: 4, display: 'Külső id A-Z' }];
    countryOptions: SelectInput[] = [
        { value: 'Magyarország', display: 'Magyarország' }];
    showTable = false;

    showStoreWindow = false;
    storeWindowTitle: string;
    getAllCitiesOptions: GetCitiesRequest = {
        cityTypeSelector: 3,
        countryName: DEFAULT_COUNTRY_NAME,
        url: ''
    };
    getYaltyCitiesOptions: GetCitiesRequest = {
        cityTypeSelector: 1,
        countryName: DEFAULT_COUNTRY_NAME,
        url: ''
    };
    companyName = this.cookieService.get('userCompany');
    allCity: string[] = [];
    yaltyCities: string[] = [];
    allCity$ = this.citiesService.getCities(this.getAllCitiesOptions);
    yaltyCities$ = this.citiesService.getCities(this.getYaltyCitiesOptions);
    allAndYaltyCities$ = forkJoin([this.allCity$, this.yaltyCities$]);
    resizeListContainer = new Subject<void>();
    resizeObservable$ = merge(this.commonService.resizeObservable$, this.resizeListContainer.pipe(debounceTime(100)));
    isUpdate: boolean;
    mapManuallyDraged = false;
    mapUpdatePending = false;
    mapManuallyRepositioned: boolean;
    storeId: number | undefined;
    sortType: number;
    storeToLoad: formValues;
    formModified = false;
    storeListHeight: object;
    externalStoreIdType: 'string' | 'number' = 'number';
    private subscriptions: Subscription[] = [];

    defaultAggregateStoresFormValues = {
        storeName: null,
        storeNameSameAsCo: false,
        externalStoreId: null,
        storeAddrCountry: 'Magyarország',
        storeAddrZIP: null,
        storeAddrCity: null,
        storeAddrStreet: null,
        storeAddrNo: null,
        latitudeCoordinate: null,
        longitudeCoordinate: null,
        yaltyCity: null,
        GPSCoordinatesAutoOrManual: false
    };

    defaultFiltersFormValues = {
        storeName: null,
        externalStoreId: null,
        storeAddrZIP: null,
        storeAddrCity: null
    }

    storeNameValidationArray: ValidatorFn[] = [
        Validators.required,
        Validators.maxLength(100)
    ];

    observer: MutationObserver;
    isDisplayedCountrywide: boolean;
    yaltyCity: null;

    constructor(
        private formBuilder: FormBuilder,
        private httpService: HttpService,
        private errorHandlerService: ErrorHandlerService,
        private formValidatorService: FormValidatorService,
        private citiesService: CitiesService,
        private cookieService: CookieService,
        private commonService: CommonService,
        private dialogService: DialogService,
        private subscriptionService: SubscriptionService,
        private authService: AuthService,
        private overlayContainer: OverlayContainer,
        private googleAnalyticsService: GoogleAnalyticsService
    ) {
        const overlayContainerClasses = this.overlayContainer.getContainerElement().classList;
        overlayContainerClasses.add('gray-2-theme');
    }

    emitBlurEvent($event: FocusEvent, addressFieldName: string) {
        this.googleMapsChild.addressChanged(addressFieldName, ($event.target as (HTMLInputElement | HTMLTextAreaElement)).value);
    }

    ngOnInit() {
        this.aggregateStoreWindowForm = this.formBuilder.group({
            storeName: [this.defaultAggregateStoresFormValues.storeName, this.storeNameValidationArray],
            storeNameSameAsCo: [this.defaultAggregateStoresFormValues.storeNameSameAsCo],
            externalStoreId: [this.defaultAggregateStoresFormValues.externalStoreId, Validators.maxLength(20)],
            storeAddrCountry: [this.defaultAggregateStoresFormValues.storeAddrCountry],
            storeAddrZIP: [this.defaultAggregateStoresFormValues.storeAddrZIP, [Validators.maxLength(10), Validators.required]],
            storeAddrCity: [this.defaultAggregateStoresFormValues.storeAddrCity, Validators.required],
            storeAddrStreet: [this.defaultAggregateStoresFormValues.storeAddrStreet, [Validators.maxLength(60), Validators.required]],
            storeAddrNo: [this.defaultAggregateStoresFormValues.storeAddrNo, [Validators.maxLength(20), Validators.required]],
            latitudeCoordinate: [this.defaultAggregateStoresFormValues.latitudeCoordinate, Validators.required],
            longitudeCoordinate: [this.defaultAggregateStoresFormValues.longitudeCoordinate, Validators.required],
            //20250214 Yalty városok kivezetése: "Yalty város" mező kivezetése miatt ez a mező kikommentelésre kerül
            //yaltyCity: [this.defaultAggregateStoresFormValues.yaltyCity],
            GPSCoordinatesAutoOrManual: [this.defaultAggregateStoresFormValues.GPSCoordinatesAutoOrManual],
        });
        this.filtersForm = this.formBuilder.group({
            storeNameFilter: [this.defaultFiltersFormValues.storeName],
            externalStoreIdFilter: [this.defaultFiltersFormValues.externalStoreId],
            storeAddrZIPFilter: [this.defaultFiltersFormValues.storeAddrZIP],
            storeAddrCityFilter: [this.defaultFiltersFormValues.storeAddrCity]
        })
        this.sortForm = this.formBuilder.group({
            sort: [1]
        })
        this.subscriptions.push(
            this.allAndYaltyCities$.subscribe(results => {
                this.allCity = results[0];
                this.yaltyCities = results[1];
            }),
            combineLatest([
                this.storeNameFilterField.valueChanges.pipe(startWith(this.storeNameFilter)),
                this.externalStoreIdFilterField.valueChanges.pipe(startWith(this.externalStoreIdFilter)),
                this.storeAddrZIPFilterField.valueChanges.pipe(startWith(this.storeAddrZIPFilter)),
                this.storeAddrCityFilterField.valueChanges.pipe(startWith(this.storeAddrCityFilter))
            ]).pipe(
                debounceTime(500),
                distinctUntilChanged(),
                filter(() => (this.allCompanyStore && this.allCompanyStore.length > 0)),
                map(([name, id, zip, city]) => this.allCompanyStore.filter(
                    obj =>
                        (!name || obj.compStoreName && obj.compStoreName.toUpperCase().includes(name.toUpperCase())) &&
                        (!id || obj.externalStoreId && obj.externalStoreId.toUpperCase().startsWith(id.toUpperCase())) &&
                        (!zip || obj.compStoreAddrZIP && obj.compStoreAddrZIP.toUpperCase().startsWith(zip.toUpperCase())) &&
                        (!city || obj.compStoreAddrCity && obj.compStoreAddrCity.toUpperCase().startsWith(city.toUpperCase()))))
            ).subscribe(filteredStores => {
                this.filteredCompanyStores = filteredStores;
                this.sortField.setValue(this.sort);
                this.showTable = true;
                this.resizeListContainer.next();
            }),
            this.storeNameSameAsCoField.valueChanges.subscribe(val => {
                if (val) this.storeNameField.setValue(this.companyName);
                else if (this.storeName === this.companyName) {
                    this.storeNameField.setValue(null);
                }
            }),
            this.sortField.valueChanges.subscribe(val => {
                if (this.allCompanyStore && this.allCompanyStore.length !== 0) this.sortCompanyStores(val);
            }),
            this.storeNameField.valueChanges.subscribe(val => {
                if (val !== this.companyName) this.storeNameSameAsCoField.setValue(false, { emitEvent: false });
            }),
            
            //20250214 Yalty városok kivezetése: "Yalty város" mező kivezetése miatt már ez a metódus kikommentelésre kerül
            /*************************************************
            merge(
                this.storeAddrCityField.valueChanges,
                this.yaltyCityField.valueChanges,
            ).subscribe(() => {
                let error: ValidationErrors | null = null;
                if (this.storeAddrCity && this.yaltyCity &&
                    this.storeAddrCity === this.yaltyCity) {
                    error = {
                        customError: true,
                        message: 'A kiválasztott Yalty város nem egyezhet meg az Üzlet címe / Város mező értékével.'
                    }
                }
                this.yaltyCityField.markAllAsTouched();
                this.yaltyCityField.setErrors(error);
            }), 
            **********************************************************************/
            this.aggregateStoreWindowForm.valueChanges.subscribe(() => {
                const store = JSON.stringify(this.aggregateStoreWindowForm.getRawValue());
                this.formModified = this.storeId ?
                    store !== JSON.stringify(this.storeToLoad) :
                    store !== JSON.stringify(this.defaultAggregateStoresFormValues);
            }),
            this.resizeObservable$.subscribe(evt => {
                this.setStoreListHeight();
            })
        )
    }

    ngAfterViewInit() {
        this.setStoreListHeight();
    }

    setStoreListHeight() {
        const currentStateHeight = this.commonService.getAbsoluteHeightOfElement('currentStateDiv');
        // h2 element with margin top and bottom 20-20 px
        const currentStateMargins = 40;
        const topHeader = 52;
        const bottomPadding = 20;
        const buttonContainer = this.commonService.getAbsoluteHeightOfElement('button-container');
        const filtersForm = this.commonService.getAbsoluteHeightOfElement('filters-form');
        const sortFormContainer = this.commonService.getAbsoluteHeightOfElement('sort-form-container');
        if (!currentStateHeight || !buttonContainer || !filtersForm || !sortFormContainer) return;
        const windowsHeight = window.innerHeight - topHeader - (currentStateHeight + currentStateMargins) - buttonContainer - filtersForm - sortFormContainer - bottomPadding;
        this.storeListHeight = { 'height': windowsHeight + 'px' };
    }

    // ngAfterViewInit() {
    //     if (!this.table) return;
    //     this.observer = new MutationObserver(mutations => {
    //         mutations.forEach(function(mutation) {
    //         console.log(mutation);
    //     });   
    //     });
    //     var config = { attributes: true, childList: true, characterData: false };

    //     this.observer.observe(this.table.nativeElement, config);
    // }

    getAllCompanyStore() {
        this.waitingForAllStoreAPIResult = true;
        this.httpService.post<GetAllCompanyStoreListViewDataResponse>('/company/getAllCompanyStoreListViewData', this.getAllCompanyStoreListViewDataRequest)
            .subscribe((response: GetAllCompanyStoreListViewDataResponse | ErrorCodeResponse) => {
                this.waitingForAllStoreAPIResult = false;
                this.getAllCompanyStoreListViewDataResponse = this.errorHandlerService.handleError(response);
                // for (let i = 0; i < 5000; i++) {
                //     const r = Math.floor(Math.random() * this.GetAllCompanyStoreListViewDataResponse.companyStoreListViewData.length);
                //     this.allCompanyStore.push(this.GetAllCompanyStoreListViewDataResponse.companyStoreListViewData[r])
                // }
                this.allCompanyStore = this.getAllCompanyStoreListViewDataResponse.companyStoreListViewData;
                for (let i = 0; i < this.allCompanyStore.length; i++) {
                    if (this.isExternalStoreIdString(this.allCompanyStore[i].externalStoreId)) {
                        this.externalStoreIdType = 'string';
                        break;
                    }
                }
                this.storeNameFilterField.setValue(this.storeNameFilter);
                this.googleAnalyticsService.sendDataToGA(
                    response.errorCode === 0,
                    '/HU/partneri/privat/üzletek_csoportos_kezelése/üzletlista_lekérdezés_{{succesText}}',
                    'HU Partner [PRIVÁT] - Üzletek csoportos kezelése oldal - {{succesText}} üzletlista lekérdezés');
            });
    }

    sortCompanyStores(sortType: number) {
        this.sortType = sortType
        let sortBy: 'compStoreName' | 'externalStoreId';
        if (sortType === 1) {
            this.filteredCompanyStores = [...this.filteredCompanyStores.sort((a, b) =>
                this.commonService.strcmp(a.compStoreAddrCity, b.compStoreAddrCity) ||
                this.commonService.strcmp(a.compStoreAddrZIP, b.compStoreAddrZIP) ||
                this.commonService.strcmp(a.compStoreName, b.compStoreName)
            )];
            return
        }
        else if (sortType === 2) {
            this.filteredCompanyStores = [...this.filteredCompanyStores.sort((a, b) =>
                a.id - b.id
            )];
            return
        }
        else if (sortType === 3) sortBy = 'compStoreName';
        else if (sortType === 4) {
            if (this.externalStoreIdType === 'number') {
                this.filteredCompanyStores = [...this.filteredCompanyStores.sort((a, b) => {
                    return this.commonService.numbercmp(
                        a.externalStoreId ? Number(a.externalStoreId) : null,
                        b.externalStoreId ? Number(b.externalStoreId) : null);
                })];
                return
            }
            sortBy = 'externalStoreId'
        };
        this.filteredCompanyStores = [...this.filteredCompanyStores.sort((a, b) =>
            this.commonService.strcmp(a[sortBy]?.toString(), b[sortBy]?.toString()))];
    }


    openStoreWindow(storeId?: number) {
        this.isUpdate = storeId !== undefined;
        this.storeId = storeId;
        this.aggregateStoreWindowForm.reset(this.defaultAggregateStoresFormValues);
        this.mapManuallyRepositioned = false;
        if (storeId) {
            this.storeWindowTitle = 'ÜZLET ADATAINAK MÓDOSÍTÁSA';
            const store = this.filteredCompanyStores.find(x => x.id === storeId);
            if (!store) return;
            this.storeToLoad = {
                storeName: store.compStoreName,
                storeNameSameAsCo: store.isCompStoreNameSame,
                externalStoreId: store.externalStoreId,
                storeAddrCountry: 'Magyarország',
                storeAddrZIP: store.compStoreAddrZIP,
                storeAddrCity: store.compStoreAddrCity,
                storeAddrStreet: store.compStoreAddrStreet,
                storeAddrNo: store.compStoreAddrNo,
                latitudeCoordinate: parseFloat(store.latitudeCoordinate),
                longitudeCoordinate: parseFloat(store.longitudeCoordinate),
                //20250214 Yalty városok kivezetése: "Yalty városok" mező kivezetése miatt ez a mező kikommentelésre kerül
                //yaltyCity: store.yaltyCity,
                GPSCoordinatesAutoOrManual: store.GPSCoordinatesAutoOrManual
            }
            this.mapManuallyRepositioned = store.mapManuallyRepositioned;
            this.aggregateStoreWindowForm.setValue(this.storeToLoad);
        } else this.storeWindowTitle = 'ÚJ ÜZLET FELVÉTELE';
        this.showStoreWindow = true;
    }

    showMapNotLoadedError() {
        this.dialogService.openDialog('Kérjük várja meg, míg a térkép és a koordináták betöltődnek, utána próbálja újra a mentést.',
        undefined, 'Ok', undefined, '450px');
    }

    mapManuallyDragedChanged($event: boolean) {
        this.mapManuallyDraged = $event;
    }

    mapPending($event: boolean) {
        console.log($event);
        this.mapUpdatePending = $event;
    }

    saveButtonClicked() {
        if (!this.aggregateStoreWindowForm.valid) {
            this.formValidatorService.validateAllFormFields(this.aggregateStoreWindowForm);
        } else if (this.storeId === undefined &&
            !this.subscriptionService.isNewStoreCanBeAdded(this.allCompanyStore.length)) {
            this.subscriptionService.showMaxNrOfStoreError();
        } else if (this.mapUpdatePending) this.showMapNotLoadedError();
        else this.addOrUpdateCompanyStore();
    }

    addOrUpdateCompanyStore() {
        this.waitingForAddOrUpdateAPIResult = true;
        const compStoreMapImgURL = `https://maps.google.com/maps/api/staticmap?sensor=false&callback=initialize&center=${this.googleMapsChild.map.getCenter().lat()},${this.googleMapsChild.map.getCenter().lng()}&zoom=19&size=750x460&maptype=roadmap&format=png&visual_refresh=true&markers=size:lar%7Ccolor:0x00AEEF%7Clabel:Y%7C${this.googleMapsChild.map.getCenter().lat()},${this.googleMapsChild.map.getCenter().lng()}&key=AIzaSyAuMO2H5Jh1L92B1D7uKW4BneSzh6ywbMI`;
        //20250214 Yalty városok kivezetése: A kivezetés miatt új üzlet létrehozásánál vagy módosításánál mostantól automatikusan töltjük a ::companyStore::isDisplayedCountrywide mező értékét
        //Amennyiben az üzlet nem Yalty városban található, vagy virtuális (Csak "Üzletek"->"Üzlet létrehozása" oldalon megadható) úgy ::companyStore::isDisplayedCountrywide = 1 egyébként 0.
        this.isDisplayedCountrywide =  this.storeAddrCity ? !this.yaltyCities.includes(this.storeAddrCity) ? true : false : true;
        if(this.isDisplayedCountrywide){
            this.yaltyCity = null;
        }
        /********************* */
        this.addOrUpdateCompanyStoreRequest = {
            GPSCoordinatesAutoOrManual: this.GPSCoordinatesAutoOrManual,
            addOrUpdateAsAggregate: true,
            compStoreAddrCity: this.storeAddrCity != null ? this.storeAddrCity.trim() : null,
            compStoreAddrCountry: "Magyarország",
            compStoreAddrNo: this.storeAddrNo != null ? this.storeAddrNo.trim() : null,
            compStoreAddrStreet: this.storeAddrStreet != null ? this.storeAddrStreet.trim() : null,
            compStoreAddrZIP: this.storeAddrZIP != null ? this.storeAddrZIP.trim() : null,
            compStoreMapImgURL: compStoreMapImgURL,
            compStoreName: this.storeName.trim(),
            companyStoreId: this.storeId,
            externalStoreId: this.externalStoreId,
            isCompStoreImgSame: true,
            isCompStoreNameSame: this.storeNameSameAsCo,
            latitudeCoordinate: this.latitudeCoordinate!.toFixed(7),
            longitudeCoordinate: this.longitudeCoordinate!.toFixed(7),
            mapManuallyRepositioned: this.mapManuallyDraged,
            redeemRestrictionType: 2,
            redeemGeofenceRadius: 500,
            //20250214 Yalty városok kivezetése: "Yalty városok" mező kivezetése miatt ez a mező kikommentelésre kerül
            yaltyCity: this.yaltyCity,
            isDisplayedCountrywide: this.isDisplayedCountrywide
            /********************* */
        }
        this.httpService.post<AddOrUpdateCompanyStoreResponse>('/company/addOrUpdateCompanyStoreData_v2', this.addOrUpdateCompanyStoreRequest)
            .subscribe((resp: AddOrUpdateCompanyStoreResponse) => {
                this.waitingForAddOrUpdateAPIResult = false;
                let dialogRef: MatDialogRef<DialogComponent, any>;
                let title: string = '';
                let detail: string | undefined = undefined;
                if (resp.errorCode === 0) {
                    title = this.storeId ?
                        'Az üzlet módosításait rendben elmentettük.' :
                        'Az üzletet rendben létrehoztuk.';
                } else if (resp.errorCode === 1) {
                    title = 'Az üzlet mentése közben váratlan hiba történt. Kérjük próbálja meg a mentést később.';
                } else if (resp.errorCode === 2) {
                    title = 'Jogosulatlan hozzáférés (érvénytelen token, errorCode = 2)';
                } else if (resp.errorCode === 3) {
                    title = 'Az üzlet mentése sikertelen: ugyanezen a fizikai címen már van egy üzlete. Módosítsa valamelyik üzlet címét.';
                } else if (resp.errorCode === 4) {
                    this.subscriptionService.showMaxNrOfStoreError();
                    return
                } else if (resp.errorCode === 5) {
                    this.authService.logoutWithPopUp('Sikertelen művelet', 'A művelet elvégzéséhez nincs jogosultsága. Ezért kiléptetjük az oldalról.');
                    return
                } else if (resp.errorCode === 6) {
                    title = "Az üzlet mentése sikertelen: a 'Beváltási korlátozás' mező kiválasztott értéke nem választható! Módosítsa másik értékre.";
                }
                dialogRef = this.dialogService.openDialog(title,
                    detail, 'Rendben', undefined, '450px');
                if (dialogRef) dialogRef.afterClosed().subscribe(() => {
                    if (resp.errorCode === 0) {
                        const updatedStore: CompanyStoreListViewData = {
                            id: this.storeId !== undefined ? this.storeId : resp.companyStoreId,
                            compStoreName: this.storeName.trim(),
                            isCompStoreNameSame: this.storeNameSameAsCo,
                            externalStoreId: this.externalStoreId,
                            compStoreAddrZIP: this.storeAddrZIP!.trim(),
                            compStoreAddrCity: this.storeAddrCity!.trim(),
                            compStoreAddrStreet: this.storeAddrStreet!.trim(),
                            compStoreAddrNo: this.storeAddrNo!.trim(),
                            latitudeCoordinate: this.latitudeCoordinate!.toFixed(7),
                            longitudeCoordinate: this.longitudeCoordinate!.toFixed(7),
                            //20250214 Yalty városok kivezetése: "Yalty városok" mező kivezetése miatt ez a mező kikommentelésre kerül
                            yaltyCity: this.yaltyCity,
                            mapManuallyRepositioned: this.mapManuallyDraged,
                            GPSCoordinatesAutoOrManual: this.GPSCoordinatesAutoOrManual,
                        }
                        if (this.storeId) {
                            let storeIndex = this.filteredCompanyStores.findIndex(store => store.id === this.storeId);
                            if (storeIndex !== -1) this.filteredCompanyStores[storeIndex] = updatedStore;
                            storeIndex = this.allCompanyStore.findIndex(store => store.id === this.storeId);
                            if (storeIndex !== -1) this.allCompanyStore[storeIndex] = updatedStore;
                        } else {
                            this.filteredCompanyStores.push(updatedStore);
                            this.allCompanyStore.push(updatedStore);
                        }
                        if (this.isExternalStoreIdString(updatedStore.externalStoreId)) this.externalStoreIdType = 'string';
                        this.storeNameFilterField.setValue(this.storeNameFilter);
                        this.showStoreWindow = false;
                    }
                });
                this.googleAnalyticsService.sendDataToGA(
                    resp.errorCode === 0,
                    '/HU/partneri/privat/üzletek_csoportos_kezelése/üzlet_létrehozás_módosítás_{{succesText}}',
                    'HU Partner [PRIVÁT] - Üzletek csoportos kezelése oldal - {{succesText}} üzlet létrehozás, módosítás');
            });
    }

    deletionButtonClicked(storeId: number, rowIndex: number) {
        let dialogRef = this.dialogService.openDialog('Biztosan törli az üzletet?',
            undefined, 'Törlés', 'Mégsem', '450px');
        dialogRef.afterClosed().subscribe(confirmed => {
            if (!confirmed) return;
            this.waitingForDeleteAPIResult[rowIndex] = true;
            const removeCompanyStoreRequest: RemoveCompanyStoreRequest = {
                companyStoreId: storeId
            }
            this.httpService.post<ErrorCodeResponse>('/company/removeCompanyStore_v2', removeCompanyStoreRequest)
                .subscribe((resp: ErrorCodeResponse) => {
                    this.waitingForDeleteAPIResult[rowIndex] = false;
                    let title = '';
                    if (resp.errorCode === 0) {
                        this.filteredCompanyStores = this.filteredCompanyStores.filter(store => store.id !== storeId);
                        this.allCompanyStore = this.allCompanyStore.filter(store => store.id !== storeId);
                    } else if (resp.errorCode === 1) {
                        title = 'Az üzlet törlése közben váratlan hiba történ. Kérjük próbálja meg a törlést később.';
                    } else if (resp.errorCode === 1) {
                        title = 'Jogosulatlan hozzáférés (érvénytelen token, errorCode = 2)';
                    }
                    if (title) dialogRef = this.dialogService.openDialog(title,
                        undefined, 'Rendben', undefined, '450px');
                    this.googleAnalyticsService.sendDataToGA(
                        resp.errorCode === 0,
                        '/HU/partneri/privat/üzletek_csoportos_kezelése/üzlet_törlés_{{succesText}}',
                        'HU Partner [PRIVÁT] - Üzletek csoportos kezelése oldal - {{succesText}} üzlet törlés');
                })
        })
    }

    isExternalStoreIdString(externalStoreId: string | number | null): boolean {
        if (externalStoreId && !Number(externalStoreId)) {
            return true;
        }
        return false;
    }

    resetFilters() {
        this.filtersForm.reset(this.defaultFiltersFormValues);
    }

    closeStoreWindow() {
        const currentStoreValues: formValues = this.aggregateStoreWindowForm.getRawValue();
        if ((this.storeId !== undefined && JSON.stringify(currentStoreValues) !== JSON.stringify(this.storeToLoad)) ||
            (this.storeId === undefined && JSON.stringify(currentStoreValues) !== JSON.stringify(this.defaultAggregateStoresFormValues))) {
            const dialogRef = this.dialogService.openDialog('Biztosan be szeretné zárni az ablakot?',
                'Az adatlapon történt módosításai mentés nélkül elvesznek.', 'Igen', 'Mégsem', '450px');
            dialogRef.afterClosed().subscribe(confirmed => {
                if (confirmed) this.showStoreWindow = false;
            })
        } else this.showStoreWindow = false;
    }

    get storeNameSameAsCoField(): AbstractControl { return this.aggregateStoreWindowForm.get('storeNameSameAsCo')!; }
    get storeNameField(): AbstractControl { return this.aggregateStoreWindowForm.get('storeName')!; }
    get storeAddrCityField(): AbstractControl { return this.aggregateStoreWindowForm.get('storeAddrCity')!; }
    
    //20250214 Yalty városok kivezetése: "Yalty városok" mező kivezetése miatt ez a mező kikommentelésre kerül
    //get yaltyCityField(): AbstractControl { return this.aggregateStoreWindowForm.get('yaltyCity')!; }

    get storeName(): string { return this.storeNameField.value; }
    get storeNameSameAsCo(): boolean { return this.storeNameSameAsCoField.value; }
    get externalStoreId(): string { return this.aggregateStoreWindowForm.get('externalStoreId')!.value; }
    get latitudeCoordinate(): number | null { return this.aggregateStoreWindowForm.get('latitudeCoordinate')!.value; }
    get longitudeCoordinate(): number | null { return this.aggregateStoreWindowForm.get('longitudeCoordinate')!.value; }
    get GPSCoordinatesAutoOrManual(): boolean { return this.aggregateStoreWindowForm.get('GPSCoordinatesAutoOrManual')!.value; }
    get storeAddrZIP(): string | null { return this.aggregateStoreWindowForm.get('storeAddrZIP')!.value; }
    get storeAddrCity(): string | null { return this.storeAddrCityField.value; }
    get storeAddrStreet(): string | null { return this.aggregateStoreWindowForm.get('storeAddrStreet')!.value; }
    get storeAddrNo(): string | null { return this.aggregateStoreWindowForm.get('storeAddrNo')!.value; }
    //20250214 Yalty városok kivezetése: "Yalty városok" mező kivezetése miatt ez a mező kikommentelésre kerül
    //get yaltyCity(): string | null { return this.yaltyCityField.value; }

    get storeNameFilter(): string | null { return this.storeNameFilterField.value; }
    get externalStoreIdFilter(): string | null { return this.externalStoreIdFilterField.value; }
    get storeAddrZIPFilter(): string | null { return this.storeAddrZIPFilterField.value; }
    get storeAddrCityFilter(): string | null { return this.storeAddrCityFilterField.value; }

    get storeNameFilterField(): AbstractControl { return this.filtersForm.get('storeNameFilter')!; }
    get externalStoreIdFilterField(): AbstractControl { return this.filtersForm.get('externalStoreIdFilter')!; }
    get storeAddrZIPFilterField(): AbstractControl { return this.filtersForm.get('storeAddrZIPFilter')!; }
    get storeAddrCityFilterField(): AbstractControl { return this.filtersForm.get('storeAddrCityFilter')!; }

    get sort(): number { return this.sortForm.get('sort')!.value; }
    get sortField(): AbstractControl { return this.sortForm.get('sort')!; }


    ngOnDestroy() {
        this.subscriptions.forEach((sub) => {
            sub.unsubscribe();
        })
    }

}

interface formValues {
    storeName: string,
    storeNameSameAsCo: boolean,
    externalStoreId: string | null,
    storeAddrCountry: string,
    storeAddrZIP: string,
    storeAddrCity: string,
    storeAddrStreet: string,
    storeAddrNo: string,
    latitudeCoordinate: number,
    longitudeCoordinate: number,
    //20250214 Yalty városok kivezetése: "Yalty városok" mező kivezetése miatt ez a mező kikommentelésre kerül
    //yaltyCity: string | null,
    GPSCoordinatesAutoOrManual: boolean
}
