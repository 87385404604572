import { Injectable } from '@angular/core';
import { LanguageListItem } from '../interfaces/input';


@Injectable({
    providedIn: 'root'
})
export class LanguagesService {

    languages: LanguageListItem[] = [
        { value: 'HU', display: 'Magyar', imgURL: 'imgs/translation_flag_HU.png' },
        { value: 'EN', display: 'Angol', imgURL: 'imgs/translation_flag_EN.png' }
    ];
    
    constructor() { }
}
