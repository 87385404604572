// @ts-nocheck

angular.module('yaltyApp').component('loyaltyCardListItem', {
    templateUrl: 'js/components/loyaltyCardListItem.html?v=202012211018',
    bindings: {
        compNamePromo: '<',
        cardLogoImgURL: '<',
        cardBackgroundColor: '<',
        compNamePromoTextColor: '<',
        stampTextPlaceholderColor: '<',
        stampCurrentCountTextColor: '<',
        floorNrOfStampsNeeded: '<',
        sumNrOfStampsNeeded: '<',
        promotionName: '<',
        promotionNameTextColor: '<'
    }
});