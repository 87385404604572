// @ts-nocheck

function couponListItemController() {
    var ctrl = this;
    ctrl.$onChanges = function (changes) {
        const opacity = ctrl.opacity / 100
        if (changes.couponListViewImgURL || changes.opacity != null) {
            if (ctrl.couponListViewImgURL) {
                ctrl.backgroundStyle = {
                    'background-image': `linear-gradient(rgba(0, 0, 0, ${opacity}), rgba(0, 0, 0, ${opacity})), 
                url(${ctrl.couponListViewImgURL})`
                }
            } else {
                ctrl.backgroundStyle = { 'background-color': '#00AEEF' }
            }
        }
    }
}

angular.module('yaltyApp').component('couponListItem', {
    templateUrl: 'js/components/couponListItem.html?v=202010201330',
    controller: couponListItemController,
    bindings: {
        couponListViewImgURL: '<',
        compNamePromo: '<',
        promotionName: '<',
        oldDiscount: '<',
        discountAmount: '<',
        discountOldPrice: '<',
        discountText: '<',
        discountText2: '<',
        promotionType: '<',
        opacity: '<'
    }
}).filter('putDotsIntoNumber', () => {
    return (input) => {
        if (!input) return input
        return input.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
});