// @ts-nocheck

// /api/user/activateUser(URL)
angular.module('yaltyApp')
    .controller('activateAccountCtrl', function ($scope, $rootScope, $state, AuthService, $mdDialog) {
        $scope.mainContactNameTitle = $rootScope.userData.partnerUserData[0].nameTitle;
        $scope.mainContactNameFirstName = $rootScope.userData.partnerUserData[0].firstName;
        $scope.mainContactNameLastName = $rootScope.userData.partnerUserData[0].lastName;
        $scope.mainContactEmail = $rootScope.userData.partnerUserData[0].userName;

        $scope.sendActivateAccountsData = function (form) {
            $scope.sendButtonDisabled = true;
            var user = {};
            user.userName = $scope.mainContactEmail;
            user.nameTitle = $scope.mainContactNameTitle;
            user.firstName = $scope.mainContactNameFirstName;
            user.lastName = $scope.mainContactNameLastName;
            user.password = $scope.password1;
            user.acceptedEmailMarketing = $scope.acceptedEmailMarketing
            AuthService.activatePartnerUser(user).then(function (result) {
                if (result.data.errorCode == 0) {
                    const alert = $mdDialog.alert()
                        .title('A felhasználói fiókját sikeresen aktiváltuk!')
                        .ok('Rendben')
                    $mdDialog.show(alert).then(function () {
                        $state.go('partner_HU.login');
                    });
                } else {
                    const alert = $mdDialog.alert()
                        .title('Szerverhiba')
                        .textContent('Partneri regisztráció sikertelen')
                        .ok('Rendben')
                    $mdDialog.show(alert).then(function () {
                    });
                    $scope.sendButtonDisabled = false;
                }
            });
        }

        $scope.newUserChange = function () {
            if ($scope.mainContactNameFirstName != null && $scope.mainContactNameLastName != null) {
                $scope.newUser = null;
                if ($scope.mainContactNameTitle != null) {
                    $scope.mainContactName = $scope.mainContactNameTitle + " " + $scope.mainContactNameLastName + " " + $scope.mainContactNameFirstName;
                } else {
                    $scope.mainContactName = $scope.mainContactNameLastName + " " + $scope.mainContactNameFirstName;
                }
            } else if ($scope.mainContactNameTitle == null && $scope.mainContactNameFirstName == null && $scope.mainContactNameLastName == null) {
                $scope.newUser = "ÚJ FELHASZNÁLÓ";
                $scope.mainContactNameTitle = null;
                $scope.mainContactNameFirstName = null;
                $scope.mainContactNameLastName = null;
            }
        }
        $scope.newUserChange();
    })
    .controller('finallyRemoveCtrl', function ($scope, $timeout, $rootScope, $http, $state, $mdDialog, API_PREFIX, GoogleAnalyticsService) {
        if ($state.current.url == "/service/deletePartner?delete") {
            $scope.resetPassword = false;
            $scope.displayLoginButton = false;
            $scope.submitButtonIsDisabled = false;

            $scope.typePassword = true;
            $scope.togglePassword = function () {
                $scope.typePassword = !$scope.typePassword;
             };

            $scope.loadingText = "Az törlési link érvényességének ellenőrzése";
            if ($rootScope.errCode == 0 && $rootScope.expired == false) {
                $timeout(function () {
                    $scope.resetPassword = true;
                    $scope.successText = "";
                    $scope.loadingText = "";
                    $scope.loadingScreen = "display: none";
                }, 3000);
            } else {
                $timeout(function () {
                    $scope.successText = "Érvénytelen vagy már felhasznált törlési link.";
                    $scope.displayLoginButton = true;
                    $scope.loadingText = "";
                    $scope.loadingScreen = "display: none";
                }, 3000);
            }

            $scope.finallyDelete = function (ev) {
                $scope.submitButtonIsDisabled = true;
                var confirm = $mdDialog.confirm()
                    .title('Törlés megerősítése')
                    .textContent('Biztosan törölni szeretné partneri fiókját? Ez a lépés végleges és nem visszavonható.')
                    .ariaLabel('Törlés megerősítése')
                    .targetEvent(ev)
                    .ok('Törlés')
                    .cancel('Mégsem');

                $mdDialog.show(confirm).then(function () {
                    var user = {};
                    user.password = $scope.userPassword;
                    user.isCancelled = false;
                    $http.post(API_PREFIX.url + "/partner/removePartner_v2", user).then(function (result) {
                        if (result.data.errorCode == 0) {
                            GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/partner_felhasználó_fiók_törlés/sikeres",
                                "HU Partner [PRIVÁT] - Felhasználói fiók törlés - Sikeres törlés")
                            $mdDialog.show(
                                $mdDialog.alert()
                                    .title('A partneri fiók törlése sikeres.')
                                    .ok('Rendben')
                                    .targetEvent(ev)
                            ).then(function () {
                                $state.go('partner_HU.login');
                            })
                        } else if (result.data.errorCode == 1) {
                            GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/partner_felhasználó_fiók_törlés/sikertelen",
                                "HU Partner [PRIVÁT] - Felhasználói fiók törlés - Sikertelen törlés")
                            $scope.submitButtonIsDisabled = false;
                            $scope.loadingText = "Adatbázis elérési hiba, kérjük próbálja újra.";
                        } else if (result.data.errorCode == 2) {
                            GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/partner_felhasználó_fiók_törlés/sikertelen",
                                "HU Partner [PRIVÁT] - Felhasználói fiók törlés - Sikertelen törlés")
                            $scope.submitButtonIsDisabled = false;
                            $scope.loadingText = "Adatbázis elérési hiba, kérjük próbálja újra.";
                        } else if (result.data.errorCode == 3) {
                            GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/partner_felhasználó_fiók_törlés/sikertelen",
                                "HU Partner [PRIVÁT] - Felhasználói fiók törlés - Sikertelen törlés")
                            $scope.submitButtonIsDisabled = false;
                            $scope.loadingText = "A megadott jelszó helytelen";
                        }
                    }, function () {
                        GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/partner_felhasználó_fiók_törlés/sikertelen",
                            "HU Partner [PRIVÁT] - Felhasználói fiók törlés - Sikertelen törlés")
                    })
                }, function () {
                    var user = {};
                    user.isCancelled = true;
                    $http.post(API_PREFIX.url + "/partner/removePartner_v2", user).then(function (result) {
                        if (result.data.errorCode == 0) {
                            $state.go('partner_HU.login')
                        }
                    })
                });
            }
            $scope.cancelFinallyDelete = function () {
                var user = {};
                user.isCancelled = true;
                $http.post(API_PREFIX.url + "/partner/removePartner_v2", user).then(function (result) {
                    if (result.data.errorCode == 0) {
                        $state.go('partner_HU.login')
                    }
                })
            }
        }
    })
    .controller('activationCtrl', function ($scope, $timeout, $rootScope, $http, $state, $location, API_PREFIX, AuthService, $mdDialog, GoogleAnalyticsService, $cookies, $window, $filter) {
        $scope.typePassword = true;
        $scope.togglePassword = function () {
            $scope.typePassword = !$scope.typePassword;
        };
        if ($state.current.url == "/service/activateUser?activate&lang") {
            let lang = 'hu';
            if ($state.params.lang != null) {
                lang = $state.params.lang;
            }
            if (window.i18next) {
                window.i18next.use(window.i18nextXHRBackend);
                window.i18next.init({
                    debug: false,
                    lng: lang,
                    fallbackLng: 'hu',
                    backend: {
                        loadPath: '/api/translations/web_app_translation_{{lng}}.json'
                    }
                }, function (err, t) {
                    $scope.resetPassword = false;
                    $scope.loadingText = i18next.t('services.user.activation.check_link_expiry');
                    $scope.$apply();
                    if ($rootScope.errCode == 0 || $rootScope.errCode == 2) {
                        $timeout(function () {
                            /*if ((navigator.userAgent.match(/android/i)) || (navigator.userAgent.match(/iPhone/i)) || (navigator.userAgent.match(/iPod/i))) {
                                if (lang == 'hu') {
                                    $scope.successText = 'A felhasználói fiókod sikeresen aktiváltad! :)';
                                    $scope.loadingText = 'Indítsd el a yalty alkalmazást és lépj be a fiók adataiddal.';
                                } else {
                                    $scope.successText = 'Your account has been successfully activated! :)';
                                    $scope.loadingText = 'Launch the Yalty app and log in to your account.';
                                }
                                $scope.loadingScreen = "display: none";
                            }*/
                            if ((navigator.userAgent.match(/android/i))) {
                                $scope.successText = i18next.t('services.user.activation.success.title');
                                $scope.loadingText = i18next.t('services.user.activation.success.mobile_text');
                                $scope.loadingScreen = "display: none";
                                if ($rootScope.errCode != 0) return
                                $timeout(function () {
                                    window.open(`app://${window.location.host}/activateUser?emailAddress=${$rootScope.emailAddress.replace('+', '%2B')}`, '_self')
                                }, 2000);
                            } else if ((navigator.userAgent.match(/iPhone/i)) || (navigator.userAgent.match(/iPod/i))) {
                                if (lang == 'hu') {
                                    $scope.successText = 'A felhasználói fiókod sikeresen aktiváltad! :)';
                                    $scope.loadingText = 'Indítsd el a yalty alkalmazást és lépj be a fiók adataiddal.';
                                } else {
                                    $scope.successText = 'Your account has been successfully activated! :)';
                                    $scope.loadingText = 'Launch the Yalty app and log in to your account.';
                                }
                                $scope.loadingScreen = "display: none";
                            }
                            $scope.successText = i18next.t('services.user.activation.success.title');
                            if ((navigator.userAgent.match(/iPhone/i)) || (navigator.userAgent.match(/iPod/i)) || (navigator.userAgent.match(/android/i))) {
                                $scope.loadingText = i18next.t('services.user.activation.success.mobile_text');
                                $scope.loadingScreen = "display: none";
                                if ($rootScope.errCode == 0) {
                                    $timeout(function () {
                                        if ((navigator.userAgent.match(/android/i))) {
                                            window.open(`app://${window.location.host}/activateUser?emailAddress=${$rootScope.emailAddress.replace('+', '%2B')}`, '_self')
                                        } else {
                                            window.open(`com.yalty.yaltyapp://activateUser?emailAddress=${$rootScope.emailAddress.replace('+', '%2B')}`, '_self')
                                        }
                                    }, 2000);
                                }
                            } 
                            else {
                                $scope.successText = i18next.t('services.user.activation.success.title');
                                $scope.loadingText = i18next.t('services.user.activation.success.desktop_text');
                                $scope.loadingScreen = "display: none";
                            }
                        }, 3000);
                    } else if ($rootScope.errCode == 1) {
                        $timeout(function () {
                            $scope.successText = i18next.t('services.user.activation.unsuccess.text_part1');
                            $scope.loadingText = i18next.t('services.user.activation.unsuccess.text_part2');
                            $scope.loadingScreen = "display: none";
                        }, 3000);
                    }
                });
            }
        } else if ($state.current.url == "/service/resetUserPassword?reset&lang") {
            let lang = 'hu';
            if ($state.params.lang != null) {
                lang = $state.params.lang;
            }
            if (window.i18next) {
                window.i18next.use(window.i18nextXHRBackend);
                window.i18next.init({
                    debug: false,
                    lng: lang,
                    fallbackLng: 'hu',
                    backend: {
                        loadPath: '/api/translations/web_app_translation_{{lng}}.json'
                    }
                }, function (err, t) {
                    $scope.newPassword = i18next.t('form.new_password.label');
                    $scope.newPasswordAgain = i18next.t('form.new_password_again.label');
                    $scope.savePassword = i18next.t('services.user.reset_password.save_password');
                    $scope.requiredFieldError = i18next.t('form.required');
                    $scope.minCharError = i18next.t('form.new_password.invalid');
                    $scope.doesNotMatchError = i18next.t('form.new_password_again.does_not_match');
                    $scope.loadingText = i18next.t('services.user.reset_password.check_link_expiry');
                    $scope.resetPassword = false;
                    if ($rootScope.expired) {
                        $timeout(function () {
                            $scope.successText = i18next.t('services.user.reset_password.unsuccess.text_part1');
                            $scope.loadingText = i18next.t('services.user.reset_password.unsuccess.text_part2');
                            $scope.loadingScreen = "display: none";
                        }, 3000);
                    } else if (!$rootScope.expired) {
                        $timeout(function () {
                            $scope.resetPassword = true;
                            $scope.loadingText = "";
                            $scope.loadingScreen = "display: none";
                            $scope.resetUserPassword = function () {
                                var url = $location.url();
                                var payload = '{ "url": "' + url.split('&')[0] + '", "newPassword": "' + $scope.newUserPassword + '"}';
                                console.log("payload: " + payload);
                                var obj = JSON.parse(payload);
                                $http.post(API_PREFIX.url + '/user/resetPassword', obj).then(function (result) {
                                    if (result.data.errorCode == 1) {
                                        var alert = $mdDialog.alert()
                                            .title(i18next.t('services.user.reset_password.server_error.title'))
                                            .textContent(i18next.t('services.user.reset_password.server_error.text'))
                                            .ok(i18next.t('generic.ok'))
                                        $mdDialog.show(alert).then(function () {
                                        });
                                        GoogleAnalyticsService.sendDataToGA("/HU/felhasználói/privat/felhasználó_fiók_jelszó_reset/sikertelen",
                                            "HU Felhasználó [PRIVÁT] - Fiók jelszó reset - Sikertelen")
                                    } else if (result.data.errorCode == 0) {
                                        GoogleAnalyticsService.sendDataToGA("/HU/felhasználói/privat/felhasználó_fiók_jelszó_reset/sikeres",
                                            "HU Felhasználó [PRIVÁT] - Fiók jelszó reset - Sikeres")
                                        $scope.resetPassword = false;
                                        $scope.successText = i18next.t('services.user.reset_password.success.text_part1')
                                        $scope.loadingText = i18next.t('services.user.reset_password.success.text_part2')
                                        $scope.loadingScreen = "display: none";
                                    } else {
                                        GoogleAnalyticsService.sendDataToGA("/HU/felhasználói/privat/felhasználó_fiók_jelszó_reset/sikertelen",
                                            "HU Felhasználó [PRIVÁT] - Fiók jelszó reset - Sikertelen")
                                    }
                                }, function () {
                                    GoogleAnalyticsService.sendDataToGA("/HU/felhasználói/privat/felhasználó_fiók_jelszó_reset/sikertelen",
                                        "HU Felhasználó [PRIVÁT] - Fiók jelszó reset - Sikertelen")
                                });
                            }
                        }, 3000);
                    }
                    $scope.$apply();
                });
            }
        } else if ($state.current.url == "/service/resetPartnerUserPassword?reset") {
            $scope.resetPassword = false;
            $scope.loadingText = "A jelszó újrabeállítási link érvényességének ellenőrzése";
            if ($rootScope.expired) {
                $timeout(function () {
                    $scope.successText = "Hibás vagy lejárt jelszó újrabeállítási link.";
                    $scope.loadingText = "Ha Ön már regisztrált felhasználónk, akkor a belépés oldalon az “Elfelejtett jelszó”-ra kattintva tud új jelszó újrabeállítási linket kérni! (Sikeres belépés esetén nincs további teendője.)";
                    $scope.comment = "Egyéb, a fiókját érintő kérdésben keresse a partneri fiók (mester) adminisztrátorát.";
                    $scope.loadingScreen = "display: none";
                    $scope.displayForgottenButton = true;
                }, 3000);
            } else if (!$rootScope.expired) {
                $timeout(function () {
                    $scope.newPassword = "Új jelszó";
                    $scope.newPasswordAgain = "Új jelszó mégegyszer";
                    $scope.savePassword = "Jelszó mentése";
                    $scope.requiredFieldError = "Mező kitöltése kötelező!";
                    $scope.minCharError = "Legalább 6 karakter kell!"
                    $scope.doesNotMatchError = "A beírt jelszavak nem egyeznek meg!";
                    $scope.resetPassword = true;
                    $scope.loadingText = "";
                    $scope.loadingScreen = "display: none";
                    $scope.resetUserPassword = function () {
                        var url = $location.url();
                        var payload = '{ "url": "' + url + '", "newPassword": "' + $scope.newUserPassword + '"}';
                        console.log("payload: " + payload);
                        var obj = JSON.parse(payload);
                        $http.post(API_PREFIX.url + '/partner/resetPassword', obj).then(function (result) {
                            if (result.data.errorCode == 1) {
                                GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/partner_felhasználó_fiók_jelszó_reset/sikertelen",
                                    "HU Partner [PRIVÁT] - Felhasználói fiók jelszó reset - Sikertelen")
                                var alert = $mdDialog.alert()
                                    .title('Szerverhiba')
                                    .textContent('Az új jelszó beállítása sikertelen. Kérünk próbálja meg később újra.')
                                    .ok('Rendben')
                                $mdDialog.show(alert).then(function () {
                                });
                            } else if (result.data.errorCode == 0) {
                                GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/partner_felhasználó_fiók_jelszó_reset/sikeres",
                                    "HU Partner [PRIVÁT] - Felhasználói fiók jelszó reset - Sikeres")
                                $scope.resetPassword = false;
                                $scope.successText = "Az új jelszavát sikeresen beállítottuk.";
                                $scope.loadingText = "Lépjen be az új jelszavával.";
                                $scope.loadingScreen = "display: none";
                                $scope.displayLoginButton = true;
                            } else {
                                GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/partner_felhasználó_fiók_jelszó_reset/sikertelen",
                                    "HU Partner [PRIVÁT] - Felhasználói fiók jelszó reset - Sikertelen")
                            }
                        }, function () {
                            GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/partner_felhasználó_fiók_jelszó_reset/sikertelen",
                                "HU Partner [PRIVÁT] - Felhasználói fiók jelszó reset - Sikertelen")
                        });
                    }
                }, 3000);
            }
        } else if ($state.current.url == "/service/activatePartnerUser?activate") {
            $scope.resetPassword = false;
            $scope.loadingText = "Az aktivációs link érvényességének ellenőrzése ..";
            var link = '<a id="loadingText" href="' + $state.href('partner_HU.login') + '">lépjen be a partneri oldalon</a>'
            if ($rootScope.errCode == 0) {
                if ($rootScope.expired == false) {
                    if ($rootScope.isMaster) {
                        $timeout(function () {
                            $scope.resetPassword = false;
                            $scope.successText = "Sikeres mester fiók aktiváció!";
                            $scope.loadingText = ""
                            $scope.loadingScreen = "display: none";
                            $scope.displayLoginButton = true
                        }, 3000)
                    } else {
                        $timeout(function () {
                            $scope.resetPassword = false;
                            $scope.successText = "";
                            $scope.loadingText = "";
                            $scope.loadingScreen = "display: none";
                            $state.go('home.settingsActivation');
                        }, 3000);
                    }
                } else {
                    $timeout(function () {
                        $scope.successText = "Hibás vagy lejárt aktivációs link.";
                        $scope.loadingText = "Ha korábban már sikeresen aktiválta fiókját ezen a linken keresztül, akkor nincs további teendője." + '\n'
                            + "Új aktivációs link kiküldéséhez egyeztessen a partneri fiók adminisztrátorával." + '\n\n'
                            + "Fő partneri (mester) fiók lejárt aktivációs linkje esetén " + link + " mester fiókja" + '\n' + "adataival és a megjelenő oldalon küldje ki újra fiókja aktivációs linkjét."
                        $scope.loadingScreen = "display: none";
                        $scope.displayForgottenButton = false;
                    }, 3000);
                }
            } else {
                $timeout(function () {
                    $scope.successText = "Hibás vagy lejárt aktivációs link.";
                    $scope.loadingText = "Ha korábban már sikeresen aktiválta fiókját ezen a linken keresztül, akkor nincs további teendője. " + '\n'
                        + "Új aktivációs link kiküldéséhez egyeztessen a partneri fiók adminisztrátorával." + '\n\n'
                        + "Fő partneri (mester) fiók lejárt aktivációs linkje esetén " + link + " mester fiókja" + '\n' + "adataival és a megjelenő oldalon küldje ki újra fiókja aktivációs linkjét."
                    $scope.loadingScreen = "display: none";
                    $scope.displayForgottenButton = false;
                }, 3000);
            }
        } else if ($state.current.url == "/service/activateMasterUser") {
            $scope.isResendButtonDisabled = false;
            $scope.loadingScreen = "display: none";
            $scope.displayForgottenButton = false;
            $scope.showResendButton = true;
            $scope.successText = "Fiók aktiváció szükséges."
            $scope.loadingText = "A fiók (email cím) még nem aktivált. Aktiváláshoz kattintson az email címére kiküldött aktiválási linkre.";
            $scope.spamText = "Amennyiben levelünk nem érkezik meg a bejövő levelek mappájába, kérjük ellenőrizze a SPAM mappáját is."
            $scope.resendActivationEmail = function (event) {
                AuthService.resendActivationEmail().then(function (result) {
                    event.target.innerText = "Email kiküldve"
                    $scope.isResendButtonDisabled = true;
                }, function () { })
            }
        } else if ($state.current.url == "/service/subscription-expired") {
            $scope.isResendButtonDisabled = false;
            $scope.loadingScreen = "display: none";
            $scope.displayForgottenButton = false;
            $scope.showResendButton = false;
            $scope.successText = "Lejárt partneri előfizetés."
            $scope.loadingText = "A cégéhez tartozó partneri előfizetés lejárt. Az előfizetést csak a fő (mester) fiók kezelője tudja módosítani, " +
                "kezelni. Kérjük jelezze ezt cégénél a fő Yalty fiókkezelőnek. Ő majd a belépés után kezelni tudja a céges " +
                "előfizetés jellemzőit.";
        } else if ($state.current.url == "/service/active-transaction") {
            if ($cookies.get('trnsctncrtdtstmp') && $cookies.get('brnpymntd')) {
                $scope.isResendButtonDisabled = false;
                $scope.loadingScreen = "display: none";
                $scope.displayForgottenButton = false;
                $scope.showResendButton = false;
                $scope.displayTransactionFinaliseButton = true
                const link = '<a>Tranzakció törlése linkre</a>'
                const transactionCreatedTimestamp = $filter('date')($cookies.get('trnsctncrtdtstmp'), "yyyy. MMMM dd. HH:mm")
                $scope.successText = "Van folyamatban lévő, nyitott fizetési tranzakciója."
                $scope.loadingText = "Lehet, hogy véletlen lezárta a Barion fizetési oldalát? Rendszerünk adatai alapján Ön nemrég fizetési tranzakciót kezdeményezett, amit eddig azonban még nem fejezett be. " + '\n' + '\n' +
                    "A kezdeményezéstől (" + transactionCreatedTimestamp +
                    ") számított fél órán belül még folytathatja a tranzakció véglegesítését az alábbi gombra kattintva.";
                $scope.spamText = "Ha nem kívánja a megkezdett tranzakcióját véglegesíteni, akkor kattintson a " + link + ". Ekkor töröljük nyitott tranzakcióját és a Belépés oldalra irányítjuk. Belépés után új előfizetést tud indítani."
                $scope.resumeTransaction = function () {
                    const link = 'https://secure.test.barion.com/Pay?Id=' + $cookies.get('brnpymntd') + '&lang=hu_HU'
                    $window.location.href = link
                    AuthService.logout()
                }

                setTimeout(function () {
                    $("#spamText a").click(function () {
                        AuthService.closeTransaction($cookies.get('brnpymntd')).then(function (result) {
                            AuthService.logout()
                            $state.go('partner_HU.login')
                        }, function () { })
                    })
                }, 0)
            } else {
                AuthService.logout()
            }

        }
    });