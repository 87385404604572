<div id="currentStateDiv">
    <h2>{{ currentState }}
        <i class="fa fa-chevron-right" style="font-size:80%"></i> {{ currentSubState }}
    </h2>
</div>
<form [formGroup]="pushNotificationForm">
    <div>
        <app-yalty-city [showOnlyOneValueSelectedWarning]="true" class="gray-2-theme"></app-yalty-city>
        <div class="warning-message">Jelenleg egy város kiválasztása támogatott!</div>
    </div>
    <app-language-selector></app-language-selector>
    <app-input-text [inputName]="'messageSubject'" [label]="'Üzenet címe'"
        [maxLength]="60" (blurEvent)="messageTextField.updateValueAndValidity()">
    </app-input-text>
    <app-input-textarea [inputName]="'messageText'" [label]="'Üzenet szövege'"
        [maxLength]="500" (keyup)="setMessageTextToDisplay()" (blurEvent)="messageSubjectField.updateValueAndValidity()">
    </app-input-textarea>
    <div class="tooltip-container">
        <app-input-number [inputName]="'messageTextNotificationLimitCharNo'"
            [label]="'Megjelenítési korlát'" [placeholder]="'Nincs korlát'" [min]="1" (change)="setMessageTextToDisplay();">
        </app-input-number>
        <i class="fa fa-info-circle tooltip">
            <span class="yaltytiptextbottom tooltip-bottom">
                <p>
                    Ezzel a beállítással notificationként megjelenve a mobil üzenetek között, az üzenet szövegének csak az
                    eleje (az adott karakterszámig) jelenik meg.</p><br>
                <p>
                    Ha nincs beállított korlát (alapértelmezett) akkor a mobil
                    platform szerint automatikusan jeleníti meg a teljes üzenetet vagy amennyi kifér belőle. Akkor is ez
                    történik, ha az itt megadott korlát nagyobb, mint amit a platform megjelenít.
                </p>
            </span>
        </i>
    </div>
    <div class="display-message-text" *ngIf="messageTextToDisplay">
        {{messageTextToDisplay}}
    </div>
    <app-select [inputName]="'messageDetailScrAction'"
        [label]="'Üzenetek képernyő akció'" [objArray]="messageDetailScrActionOptions">
    </app-select>
    <app-select [inputName]="'actionSrcType'" [label]="'Képernyő típusa'"
        class="select-wide" [objArray]="actionSrcTypeOption" *ngIf="messageDetailScrAction === 2">
    </app-select>
    <app-input-number [inputName]="'actionSrcElementId'" [minErrorText]="minErrorText"
        [label]="'Képernyőn megjelenítendő elem azonosítója'" *ngIf="messageDetailScrAction === 2" [min]="1">
    </app-input-number>
    <app-input-number [inputName]="'actionSrcElementLCTmplId'" [minErrorText]="minErrorText2"
    [label]="'Képernyőn megjelenítendő elem template azonosítója'" *ngIf="messageDetailScrAction === 2 && (actionSrcType === 21 || actionSrcType === 31)" [min]="0">
</app-input-number>
    <app-datetime-picker [inputName]="'actionValidityTimestamp'"
        [label]="'Akció érvényességi ideje'" *ngIf="messageDetailScrAction === 2"
        [minDate]="actionValidityTimestampMin" [minDateErrorText]="'A kiválasztott dátum és idő az aktuálisnál későbbi kell legyen (jövőbeni).'"></app-datetime-picker>
    <button mat-flat-button color="primary" [disabled]="waitingForAPIResult" (click)="sendButtonClicked()">
        <span [hidden]="waitingForAPIResult">Küldés</span>
        <img *ngIf="waitingForAPIResult" class="loading" src="imgs/loading_indicator.gif">
    </button>
</form>