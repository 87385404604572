import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { DialogService } from './dialog.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor(
        private cookieService: CookieService,
        private dialogService: DialogService
    ) { }

    logoutWithPopUp(title: string, detail: string | undefined) {
        const dialogRef = this.dialogService.openDialog(title,
            detail, 'Rendben', undefined, '450px');
        dialogRef.afterClosed().subscribe(() => {
            // TODO call logout API
            window.location = '/partnerHU/loginHU' as unknown as Location;
            this.cookieService.deleteAll();
        });
    }
}
