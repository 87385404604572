// @ts-nocheck

angular.module('yaltyApp')
 
.constant('AUTH_EVENTS', {
  notAuthenticated: 'auth-not-authenticated',
  notAuthorized: 'auth-not-authorized'
})

.constant('USER_ROLES', {
  master: 'master',
  admin: 'admin',
  redeemer: 'redemeer'
})
 
.constant('API_PREFIX', {
  //url: 'http://127.0.0.1:1337/api'
  //  For a simulator use: 
  url: '/api'
})

.constant('SubscriptionNames', {
  yalty_start_free: "Yalty Start",
  yalty_start_paid: "Yalty Start",
  basic: "Design+",
  premium: "Premium",
  custom: "Egyedi"
})