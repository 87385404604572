import { Component, Input, OnInit } from '@angular/core';
import { CitiesService } from "src/app/shared/services/cities.service";
import { ErrorHandlerService } from "src/app/shared/services/error-handler.service";
import { HttpService } from "src/app/shared/services/http.service";
import { GetCitiesRequest, GetCitiesResponse } from 'src/app/shared/interfaces/get-cities';
import { ErrorCodeResponse } from 'src/app/shared/interfaces/error-code-response';
import { DEFAULT_COUNTRY_NAME } from 'src/app/shared/globals/variables';
import { AbstractControl, ControlContainer, FormGroup } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { OverlayContainer } from '@angular/cdk/overlay';


@Component({
    selector: 'app-yalty-city',
    templateUrl: './yalty-city.component.html',
    styleUrls: ['./yalty-city.component.scss']
})
export class YaltyCityComponent implements OnInit {

    form: FormGroup;
    @Input() showOnlyOneValueSelectedWarning: boolean;

    yaltyCityDisplayValue: string;
    yaltyCities: string[];
    getCitiesOptions: GetCitiesRequest = {
        cityTypeSelector: 1,
        countryName: DEFAULT_COUNTRY_NAME,
        url: ''
    };

    constructor(
        private citiesService: CitiesService,
        private errorHandlerService: ErrorHandlerService,
        private overlayContainer: OverlayContainer,
        private controlContainer: ControlContainer
    ) {
        const overlayContainerClasses = this.overlayContainer.getContainerElement().classList;
        // if (overlayContainerClasses.contains('gray-2-theme')) {
        //     overlayContainerClasses.remove('gray-2-theme');
        // }
        overlayContainerClasses.add('gray-2-theme');
    }

    ngOnInit() {
        this.form = <FormGroup>this.controlContainer.control;
        this.getYaltyCities();
    }

    getYaltyCities() {
        this.citiesService.getCities(this.getCitiesOptions).subscribe(resp => {
            this.yaltyCities = resp;
        })
    }

    setYaltyCityValue() {
        this.yaltyCity.setValue(this.citiesService.getYaltyCityValue(
            this.yaltyCities, this.yaltyCity.value,
            this.yaltyCity.value.includes('all')
        ));
        this.yaltyCityDisplayValue = this.citiesService.getYaltyCityDisplayValue(
            this.yaltyCity.value
        )
    }

    get yaltyCity(): AbstractControl { return this.form.get('yaltyCity')!; }

}
