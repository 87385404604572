<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml">

<head>
  <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
</head>

<div id="container" style="margin: 0; padding: 0;">
  <table id="tableContent" align="center" border="0" cellpadding="0" cellspacing="0">
    <tr>
      <td align="right" class="yaltyLabel">
        <span>
          <img src="https://yalty.com/imgs/yalty_logo_blue.png" style="width: 105px; margin-right: 10px; margin-left: 10px; margin-top: -3px;" id="yaltyLogo">
        </span>
      </td>
    </tr>
    <tr>
      <td style="padding: 10px 30px 30px 30px;background-color:#FFFFFF;border-radius:10px;">
        <table border="0" cellpadding="0" cellspacing="0" width="100%" style="color: #000000; font-family: Arial, sans-serif; font-size: 16px;">
            <tr><td style="white-space: pre-wrap;" [innerHtml]="emailBody"></td></tr>
        </table>
      </td>
    </tr>
    <tr>
      <td style="padding: 0px 30px 5px 30px;">
        <div class="footer">
          <table border="0" cellpadding="0" cellspacing="0" style="width: 100%;padding: 0">
            <tr>
              <td class="content-block">
                <span style="display:block;margin-top: 10px;">
                  <span *ngIf="type == 'noreply'">Ez egy automatikusan generált levél, kérjük ne válaszoljon rá, nem ellenőrizzük a hozzá tartozó fiókot.</span> A levelet a Yalty előfizetésének kezelése kapcsán küldtük Önnek.
                  <br><br>
                </span>
                <span>
                    <span *ngIf="type == 'noreply'">Yalty partneri oldalakkal kapcsolatos kérdéseivel, észrevételeivel keresse közvetlen a Yalty kapcsolattartóját vagy írjon nekünk az
                    <a href="mailto:ugyfelszolgalat@yalty.com?Subject=Yalty%20partneri%20oldalakkal%20kapcsolatos%20kérdés,%20észrevétel" target="_top">
                      ugyfelszolgalat@yalty.com
                    </a> 
                    címünkre.<br><br></span>
                    A Yalty Kft. székhelye és levelezési címe: 6726 Szeged, Tanács u. 1.<br><br>
                    &copy; {{actualYear}} Yalty Kft. | <a href="https://www.yalty.com"  style="text-decoration: none">www.yalty.com</a>
                </span>
              </td>
            </tr>
          </table>
        </div>
      </td>
    </tr>
  </table>
</div>
</html>
