import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, ControlContainer, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { CustomErrorStateMatcher } from 'src/app/config/error-state-matcher';
import { SelectInput } from '../../interfaces/input';
import { FormFieldValidatorService } from '../../services/form-field-validator.service';
//import { AutocompleteInput } from '../../interfaces/autocomplete-input';

@Component({
    selector: 'app-autocomplete',
    templateUrl: './autocomplete.component.html',
    styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent implements OnInit {

    form: FormGroup;
    @Input() inputName: string;
    myControl = new FormControl();
    @Input() label: string;
    @Input() placeholder: string;
    @Input() maxLength: number;
    @Input() options: string[];
    @Input() onlyValidFromList = true;
    filteredOptions: Observable<string[]>;
    matcher = new CustomErrorStateMatcher();
    @Output() blurEvent = new EventEmitter<FocusEvent>();

    constructor(
        private controlContainer: ControlContainer,
        private formFieldValidatorService: FormFieldValidatorService
    ) { }

    ngOnInit() {
        this.form = <FormGroup>this.controlContainer.control;
        this.filteredOptions = this.input.valueChanges
            .pipe(
                startWith(''),
                map((value: string) => this._filter(value))
            );
    }

    private _filter(value: string): string[] {
        if (!value) return this.options;
        const filterValue = value.toLowerCase();
        return this.options.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
    }

    emitBlurEvent($event: FocusEvent) {
        this.blurEvent.emit($event);
        this.checkValueSelectedFromList();
    }

    checkValueSelectedFromList() {
        if (!this.onlyValidFromList || !this.input.value) return;
        let error: ValidationErrors | null = null;
        if (!this.options.includes(this.input.value)) {
            error = {
                notSelected: true,
                message: `Kérjük válasszon a listából.`
            }
        }
        this.input.setErrors(error);
    }

    get required(): boolean { return this.formFieldValidatorService.isRequired(this.input); }

    get input(): AbstractControl { return this.form.get(this.inputName)!; }

}
