import { OverlayContainer } from '@angular/cdk/overlay';
import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, ControlContainer, FormGroup } from '@angular/forms';
import { FormFieldValidatorService } from 'src/app/shared/services/form-field-validator.service';
import { SelectInput } from '../../interfaces/input';

@Component({
    selector: 'app-multiple-select',
    templateUrl: './multiple-select.component.html',
    styleUrls: ['./multiple-select.component.scss']
})
export class MultipleSelectComponent implements OnInit {

    form: FormGroup;
    @Input() inputName: string;
    @Input() label: string;
    @Input() objArray: SelectInput[];

    constructor(private controlContainer: ControlContainer,
        private overlayContainer: OverlayContainer,
        private formFieldValidatorService: FormFieldValidatorService) {
        const overlayContainerClasses = this.overlayContainer.getContainerElement().classList;
        overlayContainerClasses.add('gray-2-theme');
    }

    ngOnInit() {
        this.form = <FormGroup>this.controlContainer.control;
    }

    get required(): boolean { return this.formFieldValidatorService.isRequired(this.input); }

    get input(): AbstractControl { return this.form.get(this.inputName)!; }

}
