// @ts-nocheck

angular.module('yaltyApp')
    
    .controller('PartnerModHistoryController', function($scope, $http, API_PREFIX, GoogleAnalyticsService, SubscriptionNames, $rootScope) {

        function setListHeight(){
            if($scope.activityHistoryData != undefined){
                if($scope.activityHistoryData.length == 0){
                    let tablazatFejlecHeight = document.getElementById('tablazatFejlec').offsetHeight;
                    $scope.listHeight = {'height': tablazatFejlecHeight + 'px'};
                } else {
                    let currentStateHeight = document.getElementById('currentStateDiv').offsetHeight;
                    let filterHeight = document.getElementById('filterDiv').offsetHeight;
                    let windowsHeight = window.innerHeight - 52 - currentStateHeight - filterHeight - 20;
                    $scope.listHeight = {'height': windowsHeight + 'px'};
                }
            } else {
                let tablazatFejlecHeight = document.getElementById('tablazatFejlec').offsetHeight;
                $scope.listHeight = {'height': tablazatFejlecHeight + 'px'};
            }
        }
        
        setListHeight();
        window.onresize = function(event) {
           setListHeight();
        };
        let date = new Date();
        let actualYear = date.getFullYear();
        //let actualYear = 2021;
        let actualMonth = date.getMonth();
        $scope.year = actualYear;
        $scope.allYear = [];
        $scope.allMonth = [];
        let monthList = ["Január", "Február", "Március", "Április", "Május", "Június", "Július", "Augusztus", "Szeptember", "Október", "November", "December"];
        $scope.month = actualMonth + 1;
        if(actualYear == 2019){
            for(let i = 2; i <= actualMonth; i++){
                let months = {};
                months.value = i+1;
                months.name = monthList[i];
                $scope.allMonth.push(months);
            }
        } else {
            for(let i = 0; i <= actualMonth; i++){
                let months = {};
                months.value = i+1;
                months.name = monthList[i];
                $scope.allMonth.push(months);
            }
        }
        for(let i = actualYear; i >= 2019; i--){
            let years = {};
            years.value = i;
            years.name = i;
            $scope.allYear.push(years);
        }

        $scope.changeMonth = function(){
            $scope.month = actualMonth + 1;
            $scope.allMonth = [];
            if($scope.year == 2019 && $scope.year == actualYear){
                for(let i = 2; i <= actualMonth; i++){
                    let months = {};
                    months.value = i+1;
                    months.name = monthList[i];
                    $scope.allMonth.push(months);
                }
            } else if($scope.year == 2019){
                for(let i = 2; i < monthList.length; i++){
                    let months = {};
                    months.value = i+1;
                    months.name = monthList[i];
                    $scope.allMonth.push(months);
                }
            } else if($scope.year == actualYear) {
                for(let i = 0; i <= actualMonth; i++){
                    let months = {};
                    months.value = i+1;
                    months.name = monthList[i];
                    $scope.allMonth.push(months);
                }
            } else {
                for(let i = 0; i < monthList.length; i++){
                    let months = {};
                    months.value = i+1;
                    months.name = monthList[i];
                    $scope.allMonth.push(months);
                }
            }
        }

        $scope.refreshList = function () {
            /*if(document.getElementById('loadingOverlay')){
                document.getElementById('loadingOverlay').style.display = "block";
                document.getElementById('innerPartnerPageContainer').style.overflow = "hidden";
            }*/
            let filters = {};
            if ($scope.year) {
                filters.year = $scope.year;
            }
            if ($scope.month) {
                filters.month = $scope.month;
            }
            if($rootScope.partnerSubscriptionType != 'yalty_start_free' && $rootScope.partnerSubscriptionType != 'yalty_start_paid'){
                $scope.refreshInProgress = true;
                return $http.post(API_PREFIX.url + '/partner/getPartnerActivityHistoryMonthly', filters).then(function (result) {
                    $scope.refreshInProgress = false;
                    /*if(document.getElementById('loadingOverlay')){
                        document.getElementById('loadingOverlay').style.display = "none";
                        document.getElementById('innerPartnerPageContainer').style.overflow = "unset";
                    }*/
                    if (result.data.errorCode == 0) {
                        $scope.activityHistoryData = result.data.partnerActivityHistoryArray;
                        GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/partneri_módosítás_történet/lista_lekérdezés_sikeres",
                            "HU Partner [PRIVÁT] - Partneri módosítás történet oldal - Sikeres lista lekérdezés")
                        if(result.data.partnerActivityHistoryArray.length == 0){
                            $scope.showHistoryData = false;
                            $scope.showInfo = true;
                            $scope.showInfo2 = false;
                        } else {
                            $scope.showHistoryData = true;
                            $scope.showInfo = false;
                            $scope.showInfo2 = false;
                        }
                    } else {
                        $scope.activityHistoryData = [];
                        GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/partneri_módosítás_történet/lista_lekérdezés_sikertelen",
                            "HU Partner [PRIVÁT] - Partneri módosítás történet oldal - Sikertelen lista lekérdezés")
                        $scope.showHistoryData = false;
                        $scope.showInfo = true;
                        $scope.showInfo2 = false;
                    }
                    setListHeight();
                });
            } else {
                $scope.showHistoryData = false;
                $scope.showInfo = false;
                $scope.showInfo2 = true;
                $scope.currentSubType = SubscriptionNames[$rootScope.partnerSubscriptionType];
                $scope.reqSubType = SubscriptionNames['basic'];
            }           
        }

        $scope.refreshList();
    })