// @ts-nocheck

angular.module('yaltyApp').component('thirdPartyLoyaltyCardListItem', {
    templateUrl: 'js/components/thirdPartyLoyaltyCardListItem.html?v=202105281535',
    bindings: {
        compNamePromo: '<',
        cardLogoImgURL: '<',
        cardBackgroundColor: '<',
        compNamePromoTextColor: '<',
        //stampTextPlaceholderColor: '<',
        //stampCurrentCountTextColor: '<',
        //floorNrOfStampsNeeded: '<',
        //sumNrOfStampsNeeded: '<',
        promotionName: '<',
        promotionNameTextColor: '<'
    }
});