import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { ErrorCodeResponse } from 'src/app/shared/interfaces/error-code-response';
import { SelectInput } from "src/app/shared/interfaces/input";
import { SendPushNotificationRequest, SendPushNotificationResponse } from 'src/app/shared/interfaces/send-push-notification';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { HttpService } from 'src/app/shared/services/http.service';
import { ErrorHandlerService } from "src/app/shared/services/error-handler.service";
import { FormValidatorService } from "src/app/shared/services/form-validator.service";
import { ViewEncapsulation } from '@angular/core';
import { MatInputModule } from '@angular/material/input'
import { merge, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';


@Component({
    selector: 'app-push-notification',
    templateUrl: './push-notification.component.html',
    styleUrls: ['./push-notification.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PushNotificationComponent implements OnInit {
    currentState = 'EGYÉB';
    currentSubState = 'Push üzenet kiküldése';
    pushNotificationForm: FormGroup;
    waitingForAPIResult = false;
    sendPushNotificationRequest: SendPushNotificationRequest;
    messageTextToDisplay: string;
    actionValidityTimestampMin = new Date();
    messageDetailScrActionOptions: SelectInput[] = [
        { value: 1, display: 'Nincs hozzárendelt akció' },
        { value: 2, display: 'Ugrás az adott elem részletes képernyőjére' }];
    actionSrcTypeOption: SelectInput[] = [
        //{ value: 1, display: 'Partner részletes oldala' },
        //{ value: 2, display: 'Üzlet részletes oldala' },
        //{ value: 10, display: 'Kuponnap belső, listanézete' },
        //{ value: 11, display: 'Kupon részletes oldala' },
        { value: 20, display: 'Yalty pecsételős hűségkártya template részletes oldala' },
        { value: 21, display: 'Yalty pecsételős hűségkártya instance részletes oldala' },
        { value: 30, display: 'Törzskártya template részletes oldala' },
        { value: 31, display: 'Törzskártya instance részletes oldala' }];
    minErrorText = 'Az azonosító egy 0-nál nagyobb egész szám.'
    minErrorText2 = 'Az azonosító egy 0 (template nélküli instance) vagy annál nagyobb egész szám.'
    private subscriptions: Subscription[] = [];

    defaultPushNotificationFormValues = {
        yaltyCity: [],
        languageSelector: 'HU',
        messageSubject: null,
        messageText: null,
        messageTextNotificationLimitCharNo: null,
        messageDetailScrAction: 1,
        actionSrcType: null,
        actionSrcElementId: null,
        actionSrcElementLCTmplId: null,
        actionValidityTimestamp: null
    }

    constructor(
        private formBuilder: FormBuilder,
        private dialogService: DialogService,
        private httpService: HttpService,
        private errorHandlerService: ErrorHandlerService,
        private formValidatorService: FormValidatorService
    ) { }

    ngOnInit() {
        this.pushNotificationForm = this.formBuilder.group({
            yaltyCity: [this.defaultPushNotificationFormValues.yaltyCity, Validators.required],
            languageSelector: [this.defaultPushNotificationFormValues.languageSelector],
            messageSubject: [this.defaultPushNotificationFormValues.messageSubject, [this.formValidatorService.conditionalValidator(() => !this.messageText && !this.messageSubject,
                Validators.required), Validators.maxLength(60)]],
            messageText: [this.defaultPushNotificationFormValues.messageText, [this.formValidatorService.conditionalValidator(() => !this.messageText && !this.messageSubject,
                Validators.required), Validators.maxLength(500)]],
            messageTextNotificationLimitCharNo: [this.defaultPushNotificationFormValues.messageTextNotificationLimitCharNo],
            messageDetailScrAction: [this.defaultPushNotificationFormValues.messageDetailScrAction],
            actionSrcType: [this.defaultPushNotificationFormValues.actionSrcType, this.formValidatorService.conditionalValidator(() => this.messageDetailScrAction === 2,
                Validators.required)],
            actionSrcElementId: [this.defaultPushNotificationFormValues.actionSrcElementId, [Validators.min(1), this.formValidatorService.conditionalValidator(() => this.messageDetailScrAction === 2,
                Validators.required)]],
            actionSrcElementLCTmplId: [this.defaultPushNotificationFormValues.actionSrcElementLCTmplId, [Validators.min(0), this.formValidatorService.conditionalValidator(() => this.messageDetailScrAction === 2 && (this.actionSrcType === 21 || this.actionSrcType === 31),
                Validators.required)]],
            actionValidityTimestamp: [this.defaultPushNotificationFormValues.actionValidityTimestamp, this.formValidatorService.conditionalValidator(() => this.messageDetailScrAction == 2,
                Validators.required)]
        });

        this.subscriptions.push(
            this.messageDetailScrActionField.valueChanges
                .pipe(
                    tap(() => {
                        this.actionSrcTypeField.updateValueAndValidity();
                        this.actionSrcElementIdField.updateValueAndValidity();
                        this.actionSrcElementLCTmplIdField.updateValueAndValidity();
                        this.actionValidityTimestampField.updateValueAndValidity();
                    })
                )
                .subscribe(),
            merge(this.messageTextField.valueChanges, this.messageSubjectField.valueChanges)
                .pipe(
                    tap(() => {
                        this.messageTextField.updateValueAndValidity({ emitEvent: false });
                        this.messageTextField.markAsTouched();
                        this.messageSubjectField.updateValueAndValidity({ emitEvent: false });
                        this.messageSubjectField.markAsTouched();
                    }))
                .subscribe()
        );
        //this.pushNotificationForm.setValidators(this.customValidator());
    }

    setMessageTextToDisplay() {
        if (this.messageText === null) {
            this.messageTextToDisplay = '';
            return
        }
        this.messageTextToDisplay = this.messageText.substring(0, this.messageTextNotificationLimitCharNo || undefined);
        this.setMessageTextNotificationLimitCharNoValidation();
    }

    setMessageTextNotificationLimitCharNoValidation() {
        let error: ValidationErrors | null;
        if (this.messageText !== null && this.messageTextNotificationLimitCharNo !== null && (this.messageTextNotificationLimitCharNo > this.messageText.length)) {
            error = {
                max: true,
                message: `A korlát legfeljebb az üzenet hossza (${this.messageText.length}) lehet.`
            }
        } else if (this.messageTextNotificationLimitCharNo != null && this.messageTextNotificationLimitCharNo < 1) {
            error = { min: true, message: 'A korlát egy 0-nál nagyobb egész szám.' };
        } else {
            error = null;
        }
        this.messageTextNotificationLimitCharNoField.setErrors(error);
    }

    sendButtonClicked() {
        this.actionValidityTimestampField.updateValueAndValidity();
        if (!this.pushNotificationForm.valid) {
            this.formValidatorService.validateAllFormFields(this.pushNotificationForm);
        } else {
            this.openDialog();
        }
    }

    openDialog() {
        const dialogRef = this.dialogService
            .openDialog('Biztosan ki akarod küldeni a Push üzenetet?',
                undefined, 'Igen', 'Mégsem');

        dialogRef.afterClosed().subscribe(confirmed => {
            if (!confirmed) return;
            this.waitingForAPIResult = true;
            this.sendPushNotification();
        });
    }

    sendPushNotification() {
        const yaltyCities: string[] = this.yaltyCity.includes('all') ? [] : this.yaltyCity;
        const messageDetailScrAction: number | null = this.messageDetailScrAction === 2 ? 0 : null
        let actionSrcElementLCTmplId: number | null;
        if(messageDetailScrAction === 0){
            if(this.actionSrcType === 21 || this.actionSrcType === 31){
                actionSrcElementLCTmplId = this.actionSrcElementLCTmplId;
            } else if(this.actionSrcType === 20 || this.actionSrcType === 30) {
                actionSrcElementLCTmplId = this.actionSrcElementId;
            } else {
                actionSrcElementLCTmplId = null;
            }
        } else {
            actionSrcElementLCTmplId = null;
        }
        this.sendPushNotificationRequest = {
            yaltyCity: yaltyCities,
            messageLang: this.languageSelector,
            messageSubject: this.messageSubject || null,
            messageText: this.messageText || null,
            messageTextNotificationLimitCharNo: this.messageTextNotificationLimitCharNo || null,
            messageDetailScrAction: messageDetailScrAction,
            messageSCRImgURL: messageDetailScrAction === 0 ? '##promotion_logo##' : null,
            actionSrcType: messageDetailScrAction === 0 ? this.actionSrcType : null,
            actionSrcElementId: messageDetailScrAction === 0 ? this.actionSrcElementId : null,
            actionSrcElementLCTmplId: actionSrcElementLCTmplId,
            actionValidityTimestamp: messageDetailScrAction === 0 ? this.actionValidityTimestamp : null

        };
        this.httpService.post<SendPushNotificationResponse>('/service/sendPushNotification', this.sendPushNotificationRequest)
            .subscribe((response: SendPushNotificationResponse | ErrorCodeResponse) => {
                this.waitingForAPIResult = false;
                const resp: SendPushNotificationResponse = this.errorHandlerService.handleError(response);
                let dialogRef;
                if (resp.errorCode === 0) {
                    dialogRef = this.dialogService.openDialog(`Az üzenetet sikeresen kiküldtük! Összes célzott eszköz: ${resp.nrOfSentMessages} (Ebből sikertelen: ${resp.nrOfSentMessagesFailed}).`,
                        '', 'OK', undefined, '450px');
                } else if (resp.nrOfSentBatchesFailed > 0) {
                    dialogRef = this.dialogService.openDialog(`Az üzenet kiküldése közben egy vagy több kiküldő “batch” sikertelen volt! Összes célzott eszköz: ${resp.nrOfSentMessages} (Ebből sikertelen: ${resp.nrOfSentMessagesFailed}). További infók az API logokban.`,
                        '', 'OK', undefined, '450px');
                }
                if (dialogRef) dialogRef.afterClosed().subscribe(() => {
                    this.pushNotificationForm.reset(this.defaultPushNotificationFormValues, { emitEvent: false });
                });
            });
    }

    get yaltyCity(): string[] { return this.pushNotificationForm.get('yaltyCity')!.value; }
    get languageSelector(): string { return this.pushNotificationForm.get('languageSelector')!.value; }
    get messageSubject(): string | null { return this.messageSubjectField.value; }
    get messageText(): string | null { return this.messageTextField.value; }
    get messageTextNotificationLimitCharNo(): number | null { return this.messageTextNotificationLimitCharNoField.value; }
    get messageDetailScrAction(): number { return this.messageDetailScrActionField.value; }
    get actionSrcType(): number | null { return this.actionSrcTypeField.value; }
    get actionSrcElementId(): number | null { return this.actionSrcElementIdField.value; }
    get actionSrcElementLCTmplId(): number | null { return this.actionSrcElementLCTmplIdField.value; }
    get actionValidityTimestamp(): string | null { return new Date(this.actionValidityTimestampField.value._d).toISOString(); }

    get messageSubjectField(): AbstractControl { return this.pushNotificationForm.get('messageSubject')!; }
    get messageTextField(): AbstractControl { return this.pushNotificationForm.get('messageText')!; }
    get messageTextNotificationLimitCharNoField(): AbstractControl { return this.pushNotificationForm.get('messageTextNotificationLimitCharNo')!; }
    get messageDetailScrActionField(): AbstractControl { return this.pushNotificationForm.get('messageDetailScrAction')!; }
    get actionSrcTypeField(): AbstractControl { return this.pushNotificationForm.get('actionSrcType')!; }
    get actionSrcElementIdField(): AbstractControl { return this.pushNotificationForm.get('actionSrcElementId')!; }
    get actionSrcElementLCTmplIdField(): AbstractControl { return this.pushNotificationForm.get('actionSrcElementLCTmplId')!; }
    get actionValidityTimestampField(): AbstractControl { return this.pushNotificationForm.get('actionValidityTimestamp')!; }

    ngOnDestroy() {
        this.subscriptions.forEach((sub) => {
            sub.unsubscribe();
        })
    }
}
