// @ts-nocheck

angular.module('yaltyApp')
    .directive('thirdPartyLoyaltyCardList', ['$window', function ($window) {
        return {
            restrict: "E",
            //transclude: true,
            //require: '^loyaltyCardListToExpose',
            scope: {
                loyaltyCards: '=',
                parentHtml: '=',
                openNew: '&?',
                animateMobile: '&',
                openExisting: '&'
            },
            link: function (scope, iElement, iAttributes, ctrl) {
                const virtualSize = $window.innerHeight - 300;
                scope.allLoyaltyCard = scope.loyaltyCards
                scope.containerSize = { "height": virtualSize + "px", "width": "287px", "margin-left": "8px" };

                scope.orderBy = [{ id: '1', value: 'Utolsó módosítás dátuma szerint legújabb elől', order: 'lastUpdatedTimestamp' },
                { id: '2', value: 'Utolsó módosítás dátuma szerint legrégebbi elől', order: 'lastUpdatedTimestamp' },
                { id: '3', value: 'Promóció megnevezése szerint A-Z', order: 'promotionName' },
                { id: '4', value: 'Promóció megnevezése szerint Z-A', order: 'promotionName' },
                { id: '5', value: 'Promóció kezdete szerint legújabb elől', order: 'displayStartDate' },
                { id: '6', value: 'Promóció kezdete szerint legrégebbi elől', order: 'displayStartDate' }];
                scope.order = scope.orderBy[0];

                scope.promoStatusText = [{ code: 0, text: "még nem promótált törzskártya" },
                { code: 1, text: "futó promóció" },
                { code: 2, text: "kifutó promóció" },
                { code: 3, text: "törölt promóció" }];

                scope.orderloyaltyCard = "'" + scope.orderBy[0].order + "'";
                scope.reverseloyaltyCard = false;
                scope.orderChange = function (id) {
                    if (id == 1 || id == 4 || id == 6) {
                        scope.orderloyaltyCard = "'" + scope.orderBy[(id - 1)].order + "'";
                        scope.reverseloyaltyCard = true;
                    } else {
                        scope.orderloyaltyCard = "'" + scope.orderBy[(id - 1)].order + "'";
                        scope.reverseloyaltyCard = false;
                    }
                }
                scope.orderChange(1);

                scope.setLCList = () => {
                    //if (scope.parentHtml === 'huseg') scope.$parent.getLoyaltys()
                    if (scope.showLCList) scope.hideLCList()
                    else showLCList()
                }
                scope.setLCList()
                //scope.$parent.getLoyaltys()

                function showLCList() {
                    scope.showLCList = true
                    scope.buttonClass = "activeBtn";
                    scope.newButtonClass = "btn";
                    scope.isOpened = "fa fa-chevron-up";
                }

                scope.hideLCList = () => {
                    scope.showLCList = false
                    scope.buttonClass = "btn";
                    scope.newButtonClass = "activeBtn";
                    scope.isOpened = "fa fa-chevron-down";
                }

                let parentScope = scope.$parent;
                parentScope.child = scope;

                /*scope.setLoyalty = () => {
                    //hideLCList()
                    if (scope.parentHtml === 'huseg')
                        scope.$parent.setLoyalty()
                }*/
            },
            templateUrl: 'js/directives/thirdPartyLoyaltyCardList.html?v=202305051057'
        };
    }])

/*.controller('loyaltyCardListCtrl', function (scope, window) {
    const virtualSize = window.innerHeight - 300;
    scope.allLoyaltyCard = scope.parent.loyaltyCards
    scope.containerSize = { "height": virtualSize + "px", "width": "300px", "margin-left": "8px" };

    scope.orderBy = [{ id: '1', value: 'Utolsó módosítás dátuma szerint legújabb elől', order: 'lastUpdatedTimestamp' },
        { id: '2', value: 'Utolsó módosítás dátuma szerint legrégebbi elől', order: 'lastUpdatedTimestamp' },
        { id: '3', value: 'Promóció megnevezése szerint A-Z', order: 'promotionName' },
        { id: '4', value: 'Promóció megnevezése szerint Z-A', order: 'promotionName' },
        { id: '5', value: 'Promóció kezdete szerint legújabb elől', order: 'displayStartDate' },
        { id: '6', value: 'Promóció kezdete szerint legrégebbi elől', order: 'displayStartDate' }];
    scope.order = scope.orderBy[0];

    scope.promoStatusText = [{ code: 0, text: "még nem promótált hűségkártya" },
        { code: 1, text: "futó promóció" },
        { code: 2, text: "kifutó promóció" },
        { code: 3, text: "törölt promóció" }];

    scope.orderloyaltyCard = "'" + scope.orderBy[0].order + "'";
    scope.reverseloyaltyCard = false;
    scope.orderChange = function (id) {
        if (id == 1 || id == 4 || id == 6) {
            scope.orderloyaltyCard = "'" + scope.orderBy[(id - 1)].order + "'";
            scope.reverseloyaltyCard = true;
        } else {
            scope.orderloyaltyCard = "'" + scope.orderBy[(id - 1)].order + "'";
            scope.reverseloyaltyCard = false;
        }
    }
    scope.orderChange(1);

    scope.setLCList = () => {
        if (scope.showLCList) hideLCList()
        else showLCList()
    }
    scope.setLCList()

    function showLCList() {
        scope.showLCList = true
        scope.buttonClass = "activeBtn";
        scope.newButtonClass = "btn";
        scope.animateMobile = "";
        scope.openVirtual = { "margin-top": "0px" };
        scope.isOpened = "fa fa-chevron-up";
    }

    function hideLCList() {
        scope.showLCList = false
        scope.buttonClass = "btn";
        scope.newButtonClass = "activeBtn";
        scope.animateMobile = "yalty-animateTo-mobile";
        scope.openVirtual = { "margin-top": "-" + (virtualSize) + "px" };
        scope.isOpened = "fa fa-chevron-down";
    }

});*/