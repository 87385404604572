import { Injectable } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

@Injectable({
    providedIn: 'root'
})
export class FormValidatorService {

    constructor() { }

    conditionalValidator(condition: Function, validator: ValidatorFn): ValidationErrors {
        return ((formControl: FormControl) => {
            if (!formControl.parent) {
                return null;
            }
            if (condition()) {
                return validator(formControl);
            }
            return null;
        })
    }

    validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {
                this.validateAllFormFields(control);
            }
        });
    }

}
