import { Injectable } from '@angular/core';
import { CommonService } from './common.service';

@Injectable({
    providedIn: 'root'
})
export class GoogleAnalyticsService {

    private host = document.location.hostname;

    constructor(private commonService: CommonService) { }

    sendDataToGA(success: boolean, path: string, title: string) {
        if (this.host !== 'www.yalty.com' &&
            this.host !== 'yalty-appserv-dev-development-002.azurewebsites.net') return;
        const successText = success ? 'sikeres' : 'sikertelen'
        const id = this.host == "www.yalty.com" ?
            'UA-112496735-1' : this.host == "yalty-appserv-dev-development-002.azurewebsites.net" ?
                'UA-112496735-2' : ''
        window.gtag('config', id, {
            'page_path': path.replace('{{succesText}}', successText),
            'page_title': title.replace('{{succesText}}', this.commonService.capitalizeFirstLetter(successText))
        });
    }
}
