import { Injectable } from '@angular/core';
import { SelectInput } from 'src/app/shared/interfaces/input';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class DatePeriodService {

    periodOptions: SelectInput[] = [
        { value: 1, display: 'Teljes időszak' },
        { value: 2, display: 'Aktuális hét' },
        { value: 3, display: 'Múlt hét' },
        { value: 4, display: 'Aktuális hónap' },
        { value: 5, display: 'Múlt hónap' },
        { value: 7, display: 'Elmúlt 30 nap' },
        { value: 6, display: 'Megadott időszak' }];

    constructor() { }

    getDatesFromSelectedPeriod(periodValue: number): PeriodStartEnd {
        let fromTimestamp = null, endTimestamp = null;
        if(periodValue == 1){
            return { fromTimestamp, endTimestamp };
        }
        else if (periodValue == 6) {
            return { fromTimestamp, endTimestamp };
        }
        else if (periodValue == 2) {
            fromTimestamp = moment().startOf('isoWeek').toDate();
            endTimestamp = new Date(new Date().setHours(23, 59, 59, 999));
        } else if (periodValue == 3) {
            fromTimestamp = moment().subtract(1, 'weeks').startOf('isoWeek').toDate();
            endTimestamp = moment().subtract(1, 'weeks').endOf('isoWeek').toDate();
        } else if (periodValue == 4) {
            fromTimestamp = moment().startOf('month').toDate();
            endTimestamp = new Date(new Date().setHours(23, 59, 59, 999));
        } else if (periodValue == 5) {
            fromTimestamp = moment().subtract(1, 'month').startOf('month').toDate();
            endTimestamp = moment().subtract(1, 'month').endOf('month').toDate();
        } else if (periodValue == 7) {
            fromTimestamp = new Date(new Date().setHours(0, 0, 0, 0));
            fromTimestamp.setDate(fromTimestamp.getDate() - 29);
            endTimestamp = new Date(new Date().setHours(23, 59, 59, 999));
        }
        return { fromTimestamp, endTimestamp }
    }

    getDatesByAggregation(aggregationType: number, fromTS: string | null, endTS: string | null): PeriodStartEnd {
        let fromTimestamp = fromTS ? new Date(fromTS) : null;
        let endTimestamp = endTS ? new Date(endTS) : null;
        if (aggregationType == 1) {
            if (fromTimestamp) {
                fromTimestamp = moment(fromTimestamp).startOf('day').toDate();
            }
            if (endTimestamp) {
                endTimestamp = moment(endTimestamp).endOf('day').toDate();
                endTimestamp.setHours(23, 59, 59, 999);
            }
        } else if (aggregationType == 2) {
            if (fromTimestamp) {
                fromTimestamp = moment(fromTimestamp).startOf('isoWeek').toDate();
            }
            if (endTimestamp) {
                endTimestamp = moment(endTimestamp).endOf('isoWeek').toDate();
                endTimestamp.setHours(23, 59, 59, 999);
            }
        } else if (aggregationType == 3) {
            if (fromTimestamp) {
                fromTimestamp = moment(fromTimestamp).startOf('month').toDate();
            }
            if (endTimestamp) {
                endTimestamp = moment(endTimestamp).endOf('month').toDate();
                endTimestamp.setHours(23, 59, 59, 999);
            }
        }
        return { fromTimestamp, endTimestamp }
    }
}

interface PeriodStartEnd {
    fromTimestamp: Date | null,
    endTimestamp: Date | null
}
