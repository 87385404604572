<mat-form-field [formGroup]="form" class="example-full-width" appearance="standard">
    <mat-label>{{label}}</mat-label>
    <input matInput [matDatepicker]="picker" [formControlName]="inputName" [placeholder]="placeholder"
        [disabled]="disabled">
    <!-- <button mat-icon-button matSuffix (click)="picker.open(); $event.stopPropagation();" class="mat-datepicker-toggle">
        <mat-icon>today</mat-icon>
    </button> -->
    <mat-datepicker-toggle matSuffix [for]="picker">
    </mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
</mat-form-field>