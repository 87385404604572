// @ts-nocheck

angular.module('yaltyApp')
    .directive("subscriptionTable", ['$cookies', '$rootScope', function ($cookies, $rootScope) {
        return {
            restrict: "E",
            scope: {
                subscriptionNames: '=',
                subscriptionData: '=',
                isprereg: '=',
                containerSize: '=',
                showPreviewButton: '=',
                registerSubscription: '&',
                paySubscription: '&',
                isPartnerRegistered: '=',
                type: '=',
                isEnd: '='
            },
            link: function (scope, cookies) {
                setTimeout(() => {
                    $('.scroller').scroll(function (e) {
                        $('.scroller').scrollTop($(this).scrollTop());
                    });
                    $(".scroller div").hover(
                        function () {
                            $("." + $(this).attr('id')).addClass('hoveredRow');
                        }, function () {
                            $("." + $(this).attr('id')).removeClass('hoveredRow');
                        }
                    );
                }, 500)
                scope.selectSubscription = function (subsciption) {
                    if (scope.isprereg) {
                        $cookies.put('sbcrtyp', subsciption.name, { 'expires': $rootScope.activationStringExpTimestamp });
                    }
                    subsciption.selectedSubscription = "selectedSubscription";
                    scope.subscriptionData.forEach(function (data) {
                        if (data.name != subsciption.name) {
                            data.selectedSubscription = "unselectedSubscription";
                        }
                    })
                }
            },
            templateUrl: "../../views/subscriptionTable.html?v=202410081450",
            replace: true
        };
    }])
    .directive("mobileSubscriptionTable", ['$cookies', '$rootScope', function ($cookies, $rootScope) {
        return {
            restrict: "E",
            scope: {
                subscriptionNames: '=',
                subscriptionData: '=',
                isprereg: '=',
                containerSize: '=',
                showPreviewButton: '=',
                registerSubscription: '&',
                paySubscription: '&',
                isPartnerRegistered: '=',
                type: '=',
                isEnd: '='
            },
            link: function (scope) {
                setTimeout(() => {
                    $('.scroller').scroll(function (e) {
                        $('.scroller').scrollTop($(this).scrollTop());
                    });
                    $(".scroller div").hover(
                        function () {
                            $("." + $(this).attr('id')).addClass('hoveredRow');
                        }, function () {
                            $("." + $(this).attr('id')).removeClass('hoveredRow');
                        }
                    );
                }, 500)
                scope.selectSubscription = function (subsciption) {
                    if (scope.isprereg) {
                        $cookies.put('sbcrtyp', subsciption.name, { 'expires': $rootScope.activationStringExpTimestamp });
                    }
                    subsciption.selectedSubscription = "selectedSubscription";
                    scope.subscriptionData.forEach(function (data) {
                        if (data.name != subsciption.name) {
                            data.selectedSubscription = "unselectedSubscription";
                        }
                    })
                }
            },
            templateUrl: "../../views/mobileSubscriptionTable.html?v=202410081450",
            replace: true
        };
    }])
    .directive("paymentSummary", ['$cookies', function ($cookies) {
        return {
            restrict: "E",
            scope: {
                subscriptionData: '=',
                startPaying: '&',
                index: '=',
                showSummary: '=',
            },
            link: function (scope) {
                scope.$parent.subscriptionPeriod = "monthly"
                scope.subscriptionEmail = $cookies.get('userEmail')
                scope.recurringPaymentEnabled = $cookies.get('recurringPaymentEnabled') === 'true'
                scope.$parent.morePartnerUser = 0;
                scope.$parent.moreStore = 0;
            },
            templateUrl: "../../views/payment.html?v=202308311031"
        }
    }])
    .directive("subscriptionCard", function () {
        return {
            restrict: "E",
            scope: {
                data: '=',
            },
            templateUrl: "../../views/subscriptionCard.html?v=202304271049",
            replace: true
        }
    })
    .controller('SubscriptionController', function ($scope, $rootScope, $mdMedia, $http, $cookies, $stateParams, $mdDialog, $location, $state, $window, API_PREFIX, SubscriptionNames, LoyaltyCardService, GoogleAnalyticsService, AuthService) {
        $scope.todayTime = new Date();
        $scope.SubscriptionNames = SubscriptionNames;
        $scope.e = $location.search().e;
        $scope.subscriptionData = [];
        $scope.showSummary = false;
        $scope.partnerSubscriptionType = $rootScope.partnerSubscriptionType;
        $scope.hasPartnerActiveSub = $cookies.get('sbcrtyp') ? ($cookies.get('sbcrtyp').split('&')[5] == 'true') : '' //NULL-ra kellene kiértékelődnie
        $scope.lastSubscriptionDisplayName = $cookies.get('sbcrtyp') ? $cookies.get('sbcrtyp').split('&')[4] : ''
        $scope.lastSubscriptionName = $cookies.get('sbcrtyp') ? $cookies.get('sbcrtyp').split('&')[0] : ''
        $scope.subscriptionEndTimestamp = $cookies.get('sbcrtyp') ? $cookies.get('sbcrtyp').split('&')[6] : ''
        $scope.freePackageSubscriptionExpLimited = $cookies.get('freePackageSubscriptionExpLimited');
        $scope.freePackageSubscriptionExpTimestamp = $cookies.get('freePackageSubscriptionExpTimestamp');
        $scope.freePackageExpired = 0;
        if($cookies.get('paymentFrequency') == 2){
            $scope.subscriptionPeriod = "yearly"
        } else {
            $scope.subscriptionPeriod = "monthly"
        }
        $scope.isPaymentForTheNextPeriodRequired = $cookies.get('isPaymentForTheNextPeriodRequired');
        if($scope.freePackageSubscriptionExpTimestamp != null){
            let freePackageSubscriptionExpTimestamp = new Date($scope.freePackageSubscriptionExpTimestamp);
            if(freePackageSubscriptionExpTimestamp < new Date()){
                $scope.freePackageExpired = 1;
            } else {
                $scope.checkToNow = new Date();
                const freePackageSubscriptionExpTime = new Date($scope.subscriptionEndTimestamp)
                // To calculate the time difference of two dates 
                const Difference_In_Time =  freePackageSubscriptionExpTime.getTime() - $scope.checkToNow.getTime(); 
      
                // To calculate the no. of days between two dates 
                $scope.differenceInDays = Math.ceil(Difference_In_Time / (1000 * 3600 * 24)); 
            }
        } else {
            if($scope.partnerSubscriptionType == 'yalty_start_free' && $scope.subscriptionEndTimestamp != null){
                let freePackageSubscriptionExpTimestamp = new Date($scope.subscriptionEndTimestamp);
                if(freePackageSubscriptionExpTimestamp < new Date()){
                    $scope.freePackageExpired = 1;
                } else {
                    $scope.checkToNow = new Date();
                    const freePackageSubscriptionExpTime = new Date($scope.subscriptionEndTimestamp)
                    // To calculate the time difference of two dates 
                    const Difference_In_Time =  freePackageSubscriptionExpTime.getTime() - $scope.checkToNow.getTime(); 
          
                    // To calculate the no. of days between two dates 
                    $scope.differenceInDays = Math.ceil(Difference_In_Time / (1000 * 3600 * 24)); 
                }
            }
        }
        $scope.$mdMedia = $mdMedia;
        if($scope.freePackageSubscriptionExpLimited == "true"){
            $scope.freePackageInfoStatus = 1;
        } else {
            $scope.freePackageInfoStatus = 0;
        }

        $scope.openPrefilledPaymentWindow = function() {
            if ($location.url().includes("prefillPaymentDetails")) {
                $scope.prefillPayment();
            } else {
                location.href = "/partner/home/partner-subscription?prefillPaymentDetails=true";
            }
        }

        $scope.setNextPeriodDates = function() {
            if($scope.subscriptionEndTimestamp == null || $scope.subscriptionEndTimestamp == '' || $scope.subscriptionEndTimestamp > '3000.01.01.'){
                if($scope.subscriptionPeriod == 'monthly'){
                    let d = new Date();
                    const currentMonth = d.getMonth()
                    const d2 = new Date(d)
                    d2.setMonth(currentMonth + 1)
                    const endMonth = d2.getMonth()
                    if ((endMonth - currentMonth) > 1 && (d.getYear() == d2.getYear())) {
                        const d3 = new Date(d2)
                        d3.setDate(0)
                        d = d2 <= d3 ? d2 : d3
                    } else {
                        d = d2
                    }
                    $scope.nextPeriodEndTimestamp = d;
                } else {
                    let d = new Date();
                    const currentYear = d.getFullYear()
                    const d2 = new Date(d)
                    d2.setFullYear(currentYear + 1)
                    const dMonth = d.getMonth()
                    const d2Month = d2.getMonth()
                    if ((d2Month - dMonth) > 0) {
                        const d3 = new Date(d2)
                        d3.setDate(0)
                        d = d2.getDay() <= d3.getDay() ? d2 : d3
                    } else {
                        d = d2
                    }
                    $scope.nextPeriodEndTimestamp = d;
                }
            } else {
                if($scope.subscriptionPeriod == 'monthly'){
                    let d = new Date($scope.subscriptionEndTimestamp);
                    const currentMonth = d.getMonth()
                    const d2 = new Date(d)
                    d2.setMonth(currentMonth + 1)
                    const endMonth = d2.getMonth()
                    if ((endMonth - currentMonth) > 1 && (d.getYear() == d2.getYear())) {
                        const d3 = new Date(d2)
                        d3.setDate(0)
                        d = d2 <= d3 ? d2 : d3
                    } else {
                        d = d2
                    }
                    $scope.nextPeriodEndTimestamp = d;
                } else {
                    let d = new Date($scope.subscriptionEndTimestamp);
                    const currentYear = d.getFullYear()
                    const d2 = new Date(d)
                    d2.setFullYear(currentYear + 1)
                    const dMonth = d.getMonth()
                    const d2Month = d2.getMonth()
                    if ((d2Month - dMonth) > 0) {
                        const d3 = new Date(d2)
                        d3.setDate(0)
                        d = d2.getDay() <= d3.getDay() ? d2 : d3
                    } else {
                        d = d2
                    }
                    $scope.nextPeriodEndTimestamp = d;
                }
            }
        }

        $scope.changeFreePackageInfoStatus = function () {
            if($scope.freePackageInfoStatus == 0){
                $scope.freePackageInfoStatus = 1;
            } else {
                $scope.freePackageInfoStatus = 0;
            }
        }

        $scope.registerSubscription = function (name) {
            $cookies.put('sbcrtyp', name, { 'expires': $rootScope.activationStringExpTimestamp });
            return name;
        }

        $scope.infoForYaltyStartDiscount = function(){
            $scope.checkToNow = new Date();
            var freePackageSubscriptionExpTimestamp = new Date($scope.freePackageSubscriptionExpTimestamp)
            // To calculate the time difference of two dates 
            var Difference_In_Time =   freePackageSubscriptionExpTimestamp.getTime() - $scope.checkToNow.getTime(); 
  
            // To calculate the no. of days between two dates 
            var differenceInDays = Math.ceil(Difference_In_Time / (1000 * 3600 * 24)); 
            $mdDialog.show({
                template:
                    '<md-dialog>' +
                    '  <md-dialog-content class="md-dialog-content">' +
                    //'Bevezetési kedvezményként Ön most 30 napig regisztrálhat <b>teljesen működő alapcsomagunkra, teljesen ingyen</b>. Ajánlatunk <b>{{freePackageSubscriptionExpTimestamp | date:\'yyyy. MMMM d\'}}-ig</b>, <b style="color: #ED7D31">még {{differenceInDays}} napig</b> él. Amennyiben ezen időn belül regisztrál és aktiválja a kártyáját, az ingyenes csomagot ezután teljeskörűen és korlátlan ideig tovább használhatja. Fizetős csomagjainkra természetesen a kedvezményes időszak után is tud regisztrálni.' +
                    '<p style="color: #00AEEF"><b>VÍRUSHELYZET - ÜZLETFEJLESZTÉSI EGYÜTTMŰKÖDÉSI CSOMAGAJÁNLATUNK</b></p>'+
                    'A vírusválság mindannyiunk vállalkozását érinti. A Yalty-nál hiszünk a közös építkezésben, ' +
                    'a fair megoldásokban és abban, hogy ezt az időszakot mindannyiunknak érdemes az építkezésre, üzletfejlesztésre használnunk. '+
                    'A vírushelyzetre való tekintettel olyan együttműködést ajánlunk Önnek, amivel most <b>költségmentesen bevezetheti és ezután korlátlan ideig használhatja teljesen működő induló csomagunkat.</b><br><br>' +
                    '<b>Miért jó ez nekünk?</b> Hiszünk abban, hogy szeretni fogja szolgáltatásunkat és meglátja majd a lehetőséget értéknövelt csomagjainkban és később, ahogy és amikor a vészhelyzet után már az Ön vállalkozása is újra tágabb anyagi lehetőségekkel rendelkezik egy magasabb csomagra vált majd. Ez persze opcionális, Ön dönti el.<br>' +
                    '<p style="color: #ED7D31; text-align: center"><b>Ajánlatunk ' +
                            '{{freePackageSubscriptionExpTimestamp | date:\'yyyy. MMMM d\'}}-ig, még {{differenceInDays}} napig él.</b></p>' +
                    '<p style="color: #4E525C; font-size: 14px;">Amennyiben ezen ' +
                            'időn belül regisztrál és aktiválja a hűségkártyáját, <b>az induló csomagunkat ezután ' +
                            'már teljeskörűen</b> és <b>korlátlan ideig</b> tovább használhatja költségmentesen. Fizetős csomagjainkra ' +
                            'természetesen az ajánlati időszak után is tud regisztrálni.</p>' +
                    '  </md-dialog-content>' +
                    '  <md-dialog-actions>' +
                    '    <md-button ng-click="closeDialog()" class="md-primary">' +
                    '      OK' +
                    '    </md-button>' +
                    '  </md-dialog-actions>' +
                    '</md-dialog>',
                locals: {
                    differenceInDays: differenceInDays,
                    freePackageSubscriptionExpTimestamp: freePackageSubscriptionExpTimestamp
                },
                controller: DialogCtrl
            });
            function DialogCtrl($scope, $mdDialog, freePackageSubscriptionExpTimestamp, differenceInDays) {
                $scope.freePackageSubscriptionExpTimestamp = freePackageSubscriptionExpTimestamp;
                $scope.differenceInDays = differenceInDays;
                $scope.closeDialog = function () {
                    $mdDialog.hide();
                }
            }
        }

        if ($location.url().includes('/service/partner-subscription')) {
            $rootScope.partnerSubscriptionType = $cookies.get('sbcrtyp') ? $cookies.get('sbcrtyp') : $scope.registerSubscription('premium');
            $scope.isPartnerRegistered = ($cookies.get('isPartnerRegistered') == 'true')
            if ((navigator.userAgent.match(/iPhone/i)) || (navigator.userAgent.match(/iPod/i)) || (navigator.userAgent.match(/android/i))) {
                $('.content').css("margin-top", "0px");
                $('.yalty_top').css("display", "none");
            }
        }
        changeSubscriptionTableSize();

        window.onresize = function (event) {
            changeSubscriptionTableSize()
        };

        window.addEventListener('orientationchange', changeSubscriptionTableSize);

        window.addEventListener("focus", function (event) {
            changeSubscriptionTableSize();
        }, false);

        function changeSubscriptionTableSize() {
            setTimeout(function () {
                if($location.url().startsWith("/service/partner-subscription") || $location.url().startsWith("/partner/home/partner-subscription")){
                    if ((navigator.userAgent.match(/iPhone/i)) || (navigator.userAgent.match(/iPod/i)) || (navigator.userAgent.match(/android/i))) {
                        let currentStateHeight = document.getElementById('currentStateDiv').offsetHeight;
                        let infoHeight = document.getElementById('infoDiv').offsetHeight;
                        $scope.windowHeight = window.innerHeight - (infoHeight == 0 ? 0 : 85) - currentStateHeight - infoHeight;
                        $scope.containerSize = { 'height': $scope.windowHeight + 'px' };
                        $scope.$apply($scope.containerSize)
                    } else {
                        let currentStateHeight = document.getElementById('currentStateDiv').offsetHeight;
                        let infoHeight = document.getElementById('infoDiv').offsetHeight;
                        $scope.windowHeight = window.innerHeight - 85 - currentStateHeight - infoHeight;
                        $scope.containerSize = { 'height': $scope.windowHeight + 'px' };
                        $scope.$apply($scope.containerSize)
                    }
                }
            }, 0);
        }

        $location.url().startsWith("/service/partner-subscription") ? $scope.showPreviewButton = false : $scope.showPreviewButton = true;

        Object.entries(SubscriptionNames).forEach(function (key, value) {
            let subsciption = {}
            subsciption.name = key[0];
            subsciption.displayName = key[1];
            if (key[0] == "yalty_start_free") {
                subsciption.monthlyCostInYear = "ingyenes";
                subsciption.monthlyCostInMonth = "ingyenes";
                subsciption.userCount = "korlátlan"
                subsciption.redeemCount = "korlátlan"
                subsciption.basicDesign = true;
                subsciption.cardColors = false;
                subsciption.basicStampLib = false;
                subsciption.premiumStampLib = false;
                subsciption.uniqueStamps = false;
                subsciption.sizeableStamps = false;
                subsciption.colorableStamps = false;
                subsciption.maxNoOfDaysBetweenStamping = false;
                subsciption.cardValidityDuration = false;
                subsciption.noOfRewards = 1;
                subsciption.loyaltyCardCount = 1;
                subsciption.companyStoresCount = 1;
                subsciption.moreStoreMonthlyCost = "-";
                subsciption.moreStoreMonthlyCostYearlyPayment = "-";
                subsciption.partnerUserCount = 1;
                subsciption.morePartnerUserMonthlyCost = "-";
                subsciption.morePartnerUserMonthlyCostYearlyPayment = "-";
                subsciption.statusHistory = true;
                subsciption.redeemHistory = false;
                subsciption.partnerModifyHistory = false;
                subsciption.loyaltyHistory = false;
                //subsciption.nrOfSendMessageYearlyPayment = "-";
                //subsciption.nrOfSendMessageMonthlyPayment = "-";
                subsciption.sendMessage = false;
                if($cookies.get('additionalPartnerUserNumber') != null && $cookies.get('additionalPartnerUserNumber') > 0 && $scope.partnerSubscriptionType == 'yalty_start_free'){
                    subsciption.additionalPartnerUserNumber = $cookies.get('additionalPartnerUserNumber');
                } else {
                    subsciption.additionalPartnerUserNumber = null;
                }
                if($cookies.get('additionalFreePartnerUserNumber') != null && $cookies.get('additionalFreePartnerUserNumber') > 0 && $scope.partnerSubscriptionType == 'yalty_start_free'){
                    subsciption.additionalFreePartnerUserNumber = $cookies.get('additionalFreePartnerUserNumber');
                } else {
                    subsciption.additionalFreePartnerUserNumber = null;
                }
                if($cookies.get('additionalCompanyStoreNumber') != null && $cookies.get('additionalCompanyStoreNumber') > 0 && $scope.partnerSubscriptionType == 'yalty_start_free'){
                    subsciption.additionalCompanyStoreNumber = $cookies.get('additionalCompanyStoreNumber');
                } else {
                    subsciption.additionalCompanyStoreNumber = null;
                }
                subsciption.selectedSubscription = key[0] == $rootScope.partnerSubscriptionType ? "selectedSubscription" : "unselectedSubscription";
                if(($scope.partnerSubscriptionType == "yalty_start_free" || $scope.subscriptionEndTimestamp == null || $scope.subscriptionEndTimestamp == "") && $scope.freePackageExpired == 0){
                    $scope.subscriptionData.push(subsciption);
                }
            } else if (key[0] == "yalty_start_paid" && ($rootScope.partnerSubscriptionType == "yalty_start_paid" || $cookies.get('isYaltyStartEnabled') == 'true')) {
                subsciption.monthlyCostInYear = "1 990Ft";
                subsciption.monthlyCostInYearNumber = 1990;
                subsciption.monthlyCostInMonth = "2 990Ft";
                subsciption.monthlyCostInMonthNumber = 2990;
                subsciption.userCount = "korlátlan"
                subsciption.redeemCount = "korlátlan"
                subsciption.basicDesign = true;
                subsciption.cardColors = false;
                subsciption.basicStampLib = false;
                subsciption.premiumStampLib = false;
                subsciption.uniqueStamps = false;
                subsciption.sizeableStamps = false;
                subsciption.colorableStamps = false;
                subsciption.maxNoOfDaysBetweenStamping = false;
                subsciption.cardValidityDuration = false;
                subsciption.noOfRewards = 1;
                subsciption.loyaltyCardCount = 1;
                subsciption.companyStoresCount = 1;
                subsciption.moreStoreMonthlyCost = "1 490 Ft /hó";
                subsciption.moreStoreMonthlyCostNumber = 1490;
                subsciption.moreStoreMonthlyCostYearlyPayment = "990 Ft /hó";
                subsciption.moreStoreMonthlyCostYearlyPaymentNumber = 990;
                subsciption.partnerUserCount = 1;
                subsciption.morePartnerUserMonthlyCost = "990 Ft /hó";
                subsciption.morePartnerUserMonthlyCostNumber = 990;
                subsciption.morePartnerUserMonthlyCostYearlyPayment = "490 Ft /hó";
                subsciption.morePartnerUserMonthlyCostYearlyPaymentNumber = 490;
                subsciption.statusHistory = true;
                subsciption.redeemHistory = false;
                subsciption.partnerModifyHistory = false;
                subsciption.loyaltyHistory = false;
                //subsciption.nrOfSendMessageYearlyPayment = "-";
                //subsciption.nrOfSendMessageMonthlyPayment = "-";
                subsciption.sendMessage = false;
                if($cookies.get('additionalPartnerUserNumber') != null && $cookies.get('additionalPartnerUserNumber') > 0 && $scope.partnerSubscriptionType == 'yalty_start_paid'){
                    subsciption.additionalPartnerUserNumber = $cookies.get('additionalPartnerUserNumber');
                } else {
                    subsciption.additionalPartnerUserNumber = null;
                }
                if($cookies.get('additionalFreePartnerUserNumber') != null && $cookies.get('additionalFreePartnerUserNumber') > 0 && $scope.partnerSubscriptionType == 'yalty_start_paid'){
                    subsciption.additionalFreePartnerUserNumber = $cookies.get('additionalFreePartnerUserNumber');
                } else {
                    subsciption.additionalFreePartnerUserNumber = null;
                }
                if($cookies.get('additionalCompanyStoreNumber') != null && $cookies.get('additionalCompanyStoreNumber') > 0 && $scope.partnerSubscriptionType == 'yalty_start_paid'){
                    subsciption.additionalCompanyStoreNumber = $cookies.get('additionalCompanyStoreNumber');
                } else {
                    subsciption.additionalCompanyStoreNumber = null;
                }
                subsciption.selectedSubscription = key[0] == $rootScope.partnerSubscriptionType ? "selectedSubscription" : "unselectedSubscription";
                if(($scope.partnerSubscriptionType != "yalty_start_free" && $scope.subscriptionEndTimestamp != null && $scope.subscriptionEndTimestamp != "") || $scope.freePackageExpired != 0){
                    $scope.subscriptionData.push(subsciption);
                }
            } else if (key[0] == "basic") {
                subsciption.monthlyCostInYear = "4 350Ft";
                subsciption.monthlyCostInYearNumber = 4350;
                subsciption.monthlyCostInMonth = "4 990Ft";
                subsciption.monthlyCostInMonthNumber = 4990;
                subsciption.userCount = "korlátlan"
                subsciption.redeemCount = "korlátlan"
                subsciption.basicDesign = true;
                subsciption.cardColors = true;
                subsciption.basicStampLib = true;
                subsciption.premiumStampLib = false;
                subsciption.uniqueStamps = false;
                subsciption.sizeableStamps = true;
                subsciption.colorableStamps = false;
                subsciption.maxNoOfDaysBetweenStamping = false;
                subsciption.cardValidityDuration = false;
                subsciption.noOfRewards = 1;
                subsciption.loyaltyCardCount = 1;
                subsciption.companyStoresCount = 1;
                subsciption.moreStoreMonthlyCost = "2 490 Ft /hó";
                subsciption.moreStoreMonthlyCostNumber = 2490;
                subsciption.moreStoreMonthlyCostYearlyPayment = "1 990 Ft /hó";
                subsciption.moreStoreMonthlyCostYearlyPaymentNumber = 1990;
                subsciption.partnerUserCount = 2;
                subsciption.morePartnerUserMonthlyCost = "1 990 Ft /hó";
                subsciption.morePartnerUserMonthlyCostNumber = 1990;
                subsciption.morePartnerUserMonthlyCostYearlyPayment = "990 Ft /hó";
                subsciption.morePartnerUserMonthlyCostYearlyPaymentNumber = 990;
                subsciption.statusHistory = true;
                subsciption.redeemHistory = false;
                subsciption.partnerModifyHistory = true;
                subsciption.loyaltyHistory = false;
                //subsciption.nrOfSendMessageYearlyPayment = "4";
                //subsciption.nrOfSendMessageMonthlyPayment = "3";
                subsciption.sendMessage = false;
                if($cookies.get('additionalPartnerUserNumber') != null && $cookies.get('additionalPartnerUserNumber') > 0 && $scope.partnerSubscriptionType == 'basic'){
                    subsciption.additionalPartnerUserNumber = $cookies.get('additionalPartnerUserNumber');
                } else {
                    subsciption.additionalPartnerUserNumber = null;
                }
                if($cookies.get('additionalFreePartnerUserNumber') != null && $cookies.get('additionalFreePartnerUserNumber') > 0 && $scope.partnerSubscriptionType == 'basic'){
                    subsciption.additionalFreePartnerUserNumber = $cookies.get('additionalFreePartnerUserNumber');
                } else {
                    subsciption.additionalFreePartnerUserNumber = null;
                }
                if($cookies.get('additionalCompanyStoreNumber') != null && $cookies.get('additionalCompanyStoreNumber') > 0 && $scope.partnerSubscriptionType == 'basic'){
                    subsciption.additionalCompanyStoreNumber = $cookies.get('additionalCompanyStoreNumber');
                } else {
                    subsciption.additionalCompanyStoreNumber = null;
                }
                subsciption.selectedSubscription = key[0] == $rootScope.partnerSubscriptionType ? "selectedSubscription" : "unselectedSubscription";
                $scope.subscriptionData.push(subsciption);
            } else if (key[0] == "premium") {
                subsciption.monthlyCostInYear = "7 990Ft";
                subsciption.monthlyCostInYearNumber = 7990;
                subsciption.monthlyCostInMonth = "9 990Ft";
                subsciption.monthlyCostInMonthNumber = 9990;
                subsciption.userCount = "korlátlan"
                subsciption.redeemCount = "korlátlan"
                subsciption.basicDesign = true;
                subsciption.cardColors = true;
                subsciption.basicStampLib = true;
                subsciption.premiumStampLib = true;
                subsciption.uniqueStamps = true;
                subsciption.sizeableStamps = true;
                subsciption.colorableStamps = true;
                subsciption.maxNoOfDaysBetweenStamping = true;
                subsciption.cardValidityDuration = true;
                subsciption.noOfRewards = 5;
                subsciption.loyaltyCardCount = 3;
                subsciption.companyStoresCount = 1;
                subsciption.moreStoreMonthlyCost = "7 990 Ft /hó";
                subsciption.moreStoreMonthlyCostNumber = 7990;
                subsciption.moreStoreMonthlyCostYearlyPayment = "5 990 Ft /hó";
                subsciption.moreStoreMonthlyCostYearlyPaymentNumber = 5990;
                subsciption.partnerUserCount = 3;
                subsciption.morePartnerUserMonthlyCost = "1 990 Ft /hó";
                subsciption.morePartnerUserMonthlyCostNumber = 1990;
                subsciption.morePartnerUserMonthlyCostYearlyPayment = "990 Ft /hó";
                subsciption.morePartnerUserMonthlyCostYearlyPaymentNumber = 990;
                subsciption.statusHistory = true;
                subsciption.redeemHistory = true;
                subsciption.partnerModifyHistory = true;
                subsciption.loyaltyHistory = true;
                //subsciption.nrOfSendMessageYearlyPayment = "15";
                //subsciption.nrOfSendMessageMonthlyPayment = "10";
                subsciption.sendMessage = true;
                if($cookies.get('additionalPartnerUserNumber') != null && $cookies.get('additionalPartnerUserNumber') > 0 && $scope.partnerSubscriptionType == 'premium'){
                    subsciption.additionalPartnerUserNumber = $cookies.get('additionalPartnerUserNumber');
                } else {
                    subsciption.additionalPartnerUserNumber = null;
                }
                if($cookies.get('additionalFreePartnerUserNumber') != null && $cookies.get('additionalFreePartnerUserNumber') > 0 && $scope.partnerSubscriptionType == 'premium'){
                    subsciption.additionalFreePartnerUserNumber = $cookies.get('additionalFreePartnerUserNumber');
                } else {
                    subsciption.additionalFreePartnerUserNumber = null;
                }
                if($cookies.get('additionalCompanyStoreNumber') != null && $cookies.get('additionalCompanyStoreNumber') > 0 && $scope.partnerSubscriptionType == 'premium'){
                    subsciption.additionalCompanyStoreNumber = $cookies.get('additionalCompanyStoreNumber');
                } else {
                    subsciption.additionalCompanyStoreNumber = null;
                }
                subsciption.selectedSubscription = (key[0] == $rootScope.partnerSubscriptionType || $rootScope.partnerSubscriptionType == 'null') ? "selectedSubscription" : "unselectedSubscription";
                $scope.subscriptionData.push(subsciption);
            } else if (key[0] == "custom" && $rootScope.partnerSubscriptionType == "custom") {
                subsciption.monthlyCostInYear = "-";
                subsciption.monthlyCostInMonth = "-";
                subsciption.userCount = "korlátlan"
                subsciption.redeemCount = "korlátlan"
                subsciption.basicDesign = true;
                subsciption.cardColors = true;
                subsciption.basicStampLib = true;
                subsciption.premiumStampLib = true;
                subsciption.uniqueStamps = true;
                subsciption.sizeableStamps = true;
                subsciption.colorableStamps = true;
                subsciption.maxNoOfDaysBetweenStamping = true;
                subsciption.cardValidityDuration = true;
                subsciption.noOfRewards = 5;
                subsciption.loyaltyCardCount = "korlátlan";
                subsciption.companyStoresCount = "korlátlan";
                subsciption.moreStoreMonthlyCost = "-";
                subsciption.moreStoreMonthlyCostYearlyPayment = "-";
                subsciption.partnerUserCount = "korlátlan";
                subsciption.morePartnerUserMonthlyCost = "-";
                subsciption.morePartnerUserMonthlyCostYearlyPayment = "-";
                subsciption.statusHistory = true;
                subsciption.redeemHistory = true;
                subsciption.partnerModifyHistory = true;
                subsciption.loyaltyHistory = true;
                //subsciption.nrOfSendMessageYearlyPayment = "15";
                //subsciption.nrOfSendMessageMonthlyPayment = "10";
                subsciption.sendMessage = true;
                subsciption.additionalPartnerUserNumber = null;
                subsciption.additionalFreePartnerUserNumber = null;
                subsciption.additionalCompanyStoreNumber = null;
                subsciption.selectedSubscription = key[0] == $rootScope.partnerSubscriptionType ? "selectedSubscription" : "unselectedSubscription";
                $scope.subscriptionData.push(subsciption);
            }
        })

        $scope.paySubscription = function (subscription) {
            if ($scope.hasPartnerActiveSub == false && $cookies.get('sbcrtyp') != 'yalty_start_free' && $cookies.get('sbcrtyp') != 'yalty_start_paid' && $cookies.get('sbcrtyp') != 'basic' && $cookies.get('sbcrtyp') != 'premium') {
                if (((subscription.name == 'yalty_start_free' || subscription.name == 'yalty_start_paid') && $scope.lastSubscriptionName == 'basic')
                    || ((subscription.name == 'yalty_start_free' || subscription.name == 'yalty_start_paid' || subscription.name == 'basic') && $scope.lastSubscriptionName == 'premium')
                    || ((subscription.name == 'yalty_start_free' || subscription.name == 'yalty_start_paid' || subscription.name == 'basic' || subscription.name == 'premium') && $scope.lastSubscriptionName == 'custom')) {
                    $mdDialog.show(
                        $mdDialog.alert()
                            .htmlContent('Jelenleg csak a korábbi csomagja szerinti vagy annál magasabb szintű előfizetést tud önkiszolgáló módon előfizetni. ' +
                                '<br><br>' +
                                'Ha kisebb csomagot szeretne választani kérjük jelezze ezt partneri kapcsolattartójának vagy ' +
                                '<a href="mailto:ugyfelszolgalat@yalty.com?subject=Partneri%20csomagváltás%20-%20' + $cookies.get('userCompany') + '">írjon a partneri ügyfélszolgálatunknak</a>. ' +
                                '<br><br>' +
                                'Kisebb csomag választásakor ellenőriznünk kell a jelenleg igénybevett szolgáltatásainak megfelelését, és szükség esetén átkonvertálását, ' +
                                'a kisebb szolgáltatási szint szerintire (ez a folyamat rendszerünkben még nem teljesen automatizált, jelenleg még ügyintézői felülvizsgálatot igényel).')
                            .ok('RENDBEN')
                    );
                } else {
                    if (subscription.name == 'yalty_start_free') {
                        let alert = $mdDialog.confirm()
                            .title('Ön a ' + SubscriptionNames['yalty_start_free'] + ' csomagot választotta. A csomag aktiválásához nyomja meg az AKTIVÁLÁS gombot.')
                            .ok('AKTIVÁLÁS')
                            .cancel('MÉGSEM');
                        $mdDialog.show(alert).then(function () {
                            $scope.startPaying(undefined, undefined, undefined, undefined, undefined, 'yalty_start_free')
                        }, function () { })
                    } else {
                        if($scope.lastSubscriptionName == subscription.name){
                            $scope.openPrefilledPaymentWindow();
                        } else {
                            $scope.index = $scope.subscriptionData.findIndex(x => x.name == subscription.name);
                            $scope.showSummary = true;
                            $scope.setNextPeriodDates();
                        }
                    }
                }
            } else if ($scope.hasPartnerActiveSub == true) {
                if ($rootScope.partnerSubscriptionType == 'yalty_start_free') {
                    $scope.index = $scope.subscriptionData.findIndex(x => x.name == subscription.name);
                    $scope.showSummary = true;
                    $scope.setNextPeriodDates();
                } else {
                    $mdDialog.show(
                        $mdDialog.alert()
                            .htmlContent('Önnek van érvényes előfizetése. Jelenleg még nem tud az oldalon, önkiszolgáló módon előfizetést váltani. ' +
                                '<br><br>' +
                                'Ha csomagot szeretne váltani kérjük jelezze ezt partneri kapcsolattartójának vagy ' +
                                '<a href="mailto:ugyfelszolgalat@yalty.com?subject=Partneri%20csomagváltás%20-%20' + $cookies.get('userCompany') + '">írjon a partneri ügyfélszolgálatunknak</a>. ' +
                                '<br><br>' +
                                'A csomagváltási folyamat rendszerünkben még nem teljesen automatizált, jelenleg még ügyintézői beavatkozást igényel.')
                            .ok('RENDBEN')
                    );
                }
            } else {
                if (subscription.name == 'yalty_start_free') {
                    let alert = $mdDialog.confirm()
                        .title('Ön a ' + SubscriptionNames['yalty_start_free'] + ' csomagot választotta. A csomag aktiválásához nyomja meg az AKTIVÁLÁS gombot.')
                        .ok('AKTIVÁLÁS')
                        .cancel('MÉGSEM');
                    $mdDialog.show(alert).then(function () {
                        $scope.startPaying(undefined, undefined, undefined, undefined, undefined, 'yalty_start_free')
                    }, function () { })
                } else {
                    $rootScope.partnerSubscriptionType = $scope.registerSubscription(subscription.name);
                    $scope.index = $scope.subscriptionData.findIndex(x => x.name == subscription.name);
                    $scope.showSummary = true;
                    $scope.setNextPeriodDates();
                }
            }
        }

        $scope.setDefValue = function () {
            if($scope.moreStore == undefined){
                $scope.moreStore = 0;   
            }
            if($scope.morePartnerUser == undefined){
                $scope.morePartnerUser = 0;
            }
        }

        $scope.startPaying = function (period, userEmail, morePartnerUser, moreStore, recurringPaymentEnabled, name) {
            let subscriptionPrice;
            let body = {};
            if (!name) {
                if (!userEmail) {
                    const alert = $mdDialog.alert()
                        .title('Kérjük töltse ki vagy javítsa a jelölt mező(ke)t!')
                        .ok('Rendben')
                    $mdDialog.show(alert)
                }
                else {
                    if (moreStore == undefined || morePartnerUser == undefined) {
                        const alert = $mdDialog.alert()
                            .title('További üzlet illetve felhasználó darabszáma 0 és 999 közötti egész szám lehet.')
                            .ok('Rendben')
                        $mdDialog.show(alert).then(function () {

                        })
                    } else {
                        if (period == 'monthly') {
                            subscriptionPrice = 1.27 * ($scope.subscriptionData[$scope.index].monthlyCostInMonthNumber + moreStore * $scope.subscriptionData[$scope.index].moreStoreMonthlyCostNumber + morePartnerUser * $scope.subscriptionData[$scope.index].morePartnerUserMonthlyCostNumber);
                        } else {
                            subscriptionPrice = 1.27 * 12 * ($scope.subscriptionData[$scope.index].monthlyCostInYearNumber + moreStore * $scope.subscriptionData[$scope.index].moreStoreMonthlyCostYearlyPaymentNumber + morePartnerUser * $scope.subscriptionData[$scope.index].morePartnerUserMonthlyCostYearlyPaymentNumber);
                        }
                        body = {
                            subscriptionPeriod: period,
                            subscriptionPackageName: name ? name : $scope.subscriptionData[$scope.index].name,
                            subscriptionPrice: name ? undefined : Math.round(subscriptionPrice),
                            userName: userEmail,
                            additionalPartnerUserNumber: morePartnerUser,
                            additionalCompanyStoreNumber: moreStore,
                            recurringPaymentEnabled: recurringPaymentEnabled
                        }
                        $scope.showSummary = false;
                        if (document.getElementById('transitionOverlay')) {
                            document.getElementById('transitionOverlay').style.display = "block";
                            document.getElementById('innerPartnerPageContainer').style.overflow = "hidden";
                        }
                        $http.post(API_PREFIX.url + '/partner/partnerSubscriptionPayment', body).then((result) => {
                            if (document.getElementById('transitionOverlay')) {
                                document.getElementById('transitionOverlay').style.display = "none";
                                document.getElementById('innerPartnerPageContainer').style.overflow = "unset";
                            }
                            if (result.data.errorCode == 0) {
                                AuthService.removeCookies();
                                window.location = result.data.gatewayURL;
                            } else if (result.data.errorCode == 1) {
                                showAlert('Szerver hiba. Kérjük próbálja meg később.', 'Rendben')
                            } else if (result.data.errorCode == 2) {
                                showAlert('Jogosulatlan hozzáférés (érvénytelen token, errorCode = 2)', 'Rendben')
                            } else if (result.data.errorCode == 3) {
                                showAlert('Jogosulatlan hozzáférés (nem master felhasználó, errorCode = 3)', 'Rendben')
                            } else if (result.data.errorCode == 4) {
                                showAlert('Jogosulatlan hozzáférés (előfizetési információk átírása, errorCode = 4)', 'Rendben')
                            }
                        })
                    }
                }
            } else {
                body = {
                    subscriptionPackageName: name ? name : $scope.subscriptionData[$scope.index].name
                }
                $scope.showSummary = false;
                if (document.getElementById('transitionOverlay')) {
                    document.getElementById('transitionOverlay').style.display = "block";
                    document.getElementById('innerPartnerPageContainer').style.overflow = "hidden";
                }
                $http.post(API_PREFIX.url + '/partner/partnerSubscriptionPayment', body).then((result) => {
                    if (document.getElementById('transitionOverlay')) {
                        document.getElementById('transitionOverlay').style.display = "none";
                        document.getElementById('innerPartnerPageContainer').style.overflow = "unset";
                    }
                    if (result.data.errorCode == 0) {
                        const title = 'A ' + SubscriptionNames['yalty_start_free'] + ' ingyenes csomagját sikeresen aktiváltuk.'
                        $cookies.get('isMasterUserActivated') == 'false' ? 
                        showAlert(title, 'BELÉPÉS', true, '<b>Mielőtt belépne a partneri oldalakra, kérjük aktiválja az email címét.</b><br><br>' +
                        'Regisztrációkor más email címet adott meg, mint amire az emailt kiküldtük. ' + 
                        'Ilyenkor ezt az új email címét első lépésben aktiválnia szükséges.' + 
                        'A megadott, új címére kiküldtünk egy aktivációs emailt. '+
                        'Az emailben található aktivációs linkre kattintva tudja a fiókját aktiválni. '+
                        'Ezután már rendben be tud majd lépni email címével és jelszavával a partneri oldalakra.<br><br>'+
                        'Ha levelünket nem találná, kérjük ellenőrizze levelezője SPAM mappáját is. '+
                        'Az aktivációs levél újbóli kiküldését úgy kérheti, ha a belépés oldalon belép. '+
                        'Ilyenkor, amíg még nem aktivált a fiókja, egy hasonló üzenetet jelenítünk meg. '+
                        'Az üzenet mellett találja majd a levél újrakiküldés gombot.') :
                        showAlert(title, 'BELÉPÉS', true, 'Kérjük lépjen be partneri oldalára a Yalty használatához.')
                    } else if (result.data.errorCode == 1) {
                        showAlert('Szerver hiba. Kérjük próbálja meg később.', 'Rendben')
                    } else if (result.data.errorCode == 2) {
                        showAlert('Jogosulatlan hozzáférés (érvénytelen token, errorCode = 2)', 'Rendben')
                    } else if (result.data.errorCode == 3) {
                        showAlert('Jogosulatlan hozzáférés (nem master felhasználó, errorCode = 3)', 'Rendben')
                    } else if (result.data.errorCode == 4) {
                        showAlert('Jogosulatlan hozzáférés (előfizetési információk átírása, errorCode = 4)', 'Rendben')
                    }
                })
            }
        }

        let showAlert = (title, buttonLabel, navigate, text) => {
            let alert
            if (text) {
                alert = $mdDialog.alert()
                .title(title)
                .htmlContent(text)
                .ok(buttonLabel)
            } else {
                alert = $mdDialog.alert()
                .title(title)
                .ok(buttonLabel)
            }
            $mdDialog.show(alert).then(function () {
                if (navigate) {
                    $cookies.remove('sbcrtyp');
                    $cookies.remove('isPartnerRegistered');
                    $cookies.remove('userEmail');
                    AuthService.logout().then(() => {
                        document.location = $location.protocol() + "://" + location.host + "/partnerHU/loginHU";
                    })
                }
            });
        }

        $scope.resetPage = function (state) {
            const queryParams = $location.search();
            if ($rootScope.isDataSheetModified == false) {
                if (state) {
                    if (queryParams.preview) {
                        $state.go(state, { preview: true });
                    } else if (queryParams.e) {
                        $state.go(state, { e: queryParams.e });
                    } else {
                        $state.go(state);
                    }
                } else {
                    $window.scrollTo(0, 0);
                    $state.reload();
                }
            } else {
                var confirm = $mdDialog.confirm()
                    .title('Biztosan el szeretné hagyni az oldalt?')
                    .textContent('Az adatlapon történt módosításai mentés nélkül elvesznek.')
                    .ariaLabel('Oldal elhagyás megerősítése')
                    .ok('Igen')
                    .cancel('Mégsem');
                $mdDialog.show(confirm).then(function () {
                    $rootScope.isDataSheetModified = false;
                    if (state) {
                        if (queryParams.preview) {
                            $state.go(state, { preview: true });
                        } else if (queryParams.e) {
                            $state.go(state, { e: queryParams.e });
                        } else {
                            $state.go(state);
                        }
                    } else {
                        $window.scrollTo(0, 0);
                        $state.reload();
                    }
                }, function () {
                });
            }
        }
        $scope.prefillPayment = function(){
            if($scope.lastSubscriptionName != ''){
                $scope.index = $scope.subscriptionData.findIndex(x => x.name == $scope.lastSubscriptionName);
                $scope.showSummary = true;
            }
            setTimeout(function () {
                if($cookies.get('paymentFrequency') == 2){
                    $scope.subscriptionPeriod = "yearly"
                } else {
                    $scope.subscriptionPeriod = "monthly"
                }
                if($cookies.get('additionalPartnerUserNumber') != null){
                    $scope.morePartnerUser = parseInt($cookies.get('additionalPartnerUserNumber'));
                } else {
                    $scope.morePartnerUser = 0;
                }
                if($cookies.get('additionalCompanyStoreNumber') != null){
                    $scope.moreStore = parseInt($cookies.get('additionalCompanyStoreNumber'));
                } else {
                    $scope.moreStore = 0;
                }
                $scope.setNextPeriodDates();
            }, 500)
        }
        if ($state.params.prefillPaymentDetails == "true") {
            $scope.prefillPayment();
        }
        $scope.setNextPeriodDates();
        function getDayDiff(date1, date2) {
            const d1 = new Date(date1)
            const d2 = new Date(date2)
            return Math.floor((Date.UTC(d1.getFullYear(), d1.getMonth(),
                d1.getDate()) - Date.UTC(d2.getFullYear(),
                    d2.getMonth(), d2.getDate())) / (1000 * 60 * 60 * 24));
        }
        if($scope.subscriptionEndTimestamp != null){
            $scope.dayDiffBetweenSubEndAndToday = getDayDiff($scope.subscriptionEndTimestamp, $scope.todayTime);
        } else {
            $scope.dayDiffBetweenSubEndAndToday = null;
        }
    })