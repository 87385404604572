// @ts-nocheck


angular.module('yaltyApp')
    .controller('marketingPackagesCtrl', function ($scope, $rootScope, $stateParams, $filter, $http, $state, $mdDialog, $window, $cookies, API_PREFIX, GoogleAnalyticsService) {

        const virtualSize = $window.innerHeight - 400;
        $scope.openVirtual = { "margin-top": "-" + (virtualSize) + "px" };
        $scope.LCListOpen = true

        $scope.models = {}

        $scope.promoStatusText = [
            { code: 0, text: "még nem promótált hűségkártya" },
            { code: 1, text: "futó promóció" },
            { code: 2, text: "kifutó promóció" },
            { code: 3, text: "törölt promóció" }];
        $scope.partnerSubscriptionType = $rootScope.partnerSubscriptionType;

        $scope.animate = () => {
            $scope.LCListOpen = !$scope.LCListOpen
            /*if ($scope.isLCSelected) {
                $scope.animateMobile = "yalty-animateTo-mobile";
                $scope.openVirtual = { "margin-top": "0px" };
            } else {
                $scope.animateMobile = "yalty-animateFrom-mobile"
                $scope.openVirtual = { "margin-top": "-" + (virtualSize) + "px" };
            }*/
        }

        $scope.adminUserType = $cookies.get('adminUserType')
        $scope.visibilitySettingEnabled = $scope.adminUserType == 'executive'
        $scope.emailSendingEnabled = $scope.visibilitySettingEnabled
        $scope.languages = [
            {
                id: 0,
                name: 'magyar',
                listName: 'Magyar',
                url: "../imgs/translation_flag_HU.png",
                code: 'HU'
            },
            {
                id: 1,
                name: 'angol',
                listName: `Angol`,
                url: "../imgs/translation_flag_EN.png", code: 'EN'
            }
        ]

        $scope.openExistingLC = async ({ id, status }) => {
            const isCanceled = await $scope.switchLoyaltyCard()
            if (isCanceled) return
            $scope.showMarketingGeneration = false
            $scope.loyaltyCardStatus = status
            $scope.loyaltyCardPromoStatusText = $scope.promoStatusText[$scope.loyaltyCardStatus].text
            $http.post(API_PREFIX.url + '/partner/getAllDataForPartnerMarketingPackageLCYGeneration',
                { loyaltyCardId: id }).then(async function (result) {
                    if (result.data.errorCode === 0) {
                        $scope.child.hideLCList()
                        $scope.isLCSelected = true
                        $scope.LCListOpen = false
                        $rootScope.isDataSheetModified = false
                        const resultObj = { ...result.data.loyaltyCard, ...result.data.marketingPackageLCY }
                        $scope.loyaltyCardId = id
                        $scope.compLogoImgURL = resultObj.cardLogoImgURL
                        $scope.floorNrOfStamps = resultObj.floorNrOfStamps
                        $scope.nrOfStamps = resultObj.nrOfStamps
                        $scope.reward1NrOfStampsNeeded = resultObj.reward1NrOfStampsNeeded;
                        $scope.reward2NrOfStampsNeeded = resultObj.reward2NrOfStampsNeeded;
                        $scope.reward3NrOfStampsNeeded = resultObj.reward3NrOfStampsNeeded;
                        $scope.reward4NrOfStampsNeeded = resultObj.reward4NrOfStampsNeeded;
                        $scope.reward5NrOfStampsNeeded = resultObj.reward5NrOfStampsNeeded;
                        $scope.languageSelector = 'HU'
                        $scope.initValues = {}
                        $scope.models['HU'] = {
                            A5PromotionName: resultObj.A5PromotionName_HU,
                            A5PromotionName2ndLine: resultObj.A5PromotionName2ndLine_HU,
                            promoNameAutoDistribution: resultObj.A5PromotionNameAutoFit_HU != null ?
                                resultObj.A5PromotionNameAutoFit_HU : true,
                            promoFreeText: resultObj.LCYPromoFreeText_HU
                        }
                        $scope.models['EN'] = {
                            A5PromotionName: resultObj.A5PromotionName_EN,
                            A5PromotionName2ndLine: resultObj.A5PromotionName2ndLine_EN,
                            promoNameAutoDistribution: resultObj.A5PromotionNameAutoFit_EN != null ?
                                resultObj.A5PromotionNameAutoFit_EN : true,
                            promoFreeText: resultObj.LCYPromoFreeText_EN
                        }
                        $scope.cardLogoImgURL = resultObj.cardLogoImgURL
                        $scope.cardBackgroundColor = resultObj.cardBackgroundColor
                        $scope.compNamePromoTextColor = resultObj.compNamePromoTextColor
                        $scope.promotionNameTextColor = resultObj.promotionNameTextColor
                        $scope.stampTextPlaceholderColor = resultObj.stampTextPlaceholderColor
                        $scope.stampCurrentCountTextColor = resultObj.stampCurrentCountTextColor
                        $scope.baseStampUnstampedFillColor = resultObj.baseStampUnstampedFillColor
                        $scope.baseStampUnstampedLineWeight = resultObj.baseStampUnstampedLineWeight
                        $scope.baseStampUnstampedLineColor = resultObj.baseStampUnstampedLineColor
                        $scope.baseStampStampedFillColor = resultObj.baseStampStampedFillColor
                        $scope.baseStampStampedLineWeight = resultObj.baseStampStampedLineWeight
                        $scope.baseStampStampedLineColor = resultObj.baseStampStampedLineColor
                        $scope.stampIconUnstampedColor = resultObj.stampIconUnstampedColor
                        $scope.stampIconStampedColor = resultObj.stampIconStampedColor
                        $scope.stampIconUnstampedSizing = resultObj.stampIconUnstampedSizing
                        $scope.stampIconStampedSizing = resultObj.stampIconStampedSizing
                        $scope.compNamePromo = {
                            'HU': resultObj.compNamePromo_HU || result.data.loyaltyCard.compNamePromo_HU,
                            'EN': resultObj.compNamePromo_EN || result.data.loyaltyCard.compNamePromo_EN
                        }
                        $scope.promotionName = {
                            'HU': resultObj.promotionName_HU || result.data.loyaltyCard.promotionName_HU,
                            'EN': resultObj.promotionName_EN || result.data.loyaltyCard.promotionName_EN
                        }
                        $scope.translationEnabled = {}
                        $scope.translationEnabled['HU'] = resultObj.compNamePromo_HU && resultObj.promotionName_HU
                        $scope.translationEnabled['EN'] = resultObj.compNamePromo_EN && resultObj.promotionName_EN
                        $scope.showBaseStampDesign = resultObj.showBaseStampDesign
                        $scope.showStampIcon = resultObj.showStampIcon
                        $scope.stampIconType = resultObj.stampIconType
                        $scope.stampIconURLPrefix = resultObj.stampIconURL
                        $scope.LCStampIconURLPrefix = result.data.loyaltyCard.stampIconURL
                        $scope.baseStampOverride = resultObj.baseStampOverride
                        $scope.baseStampOverrideStampIconURL = resultObj.baseStampOverrideStampIconURL
                        $scope.selectTemplateImg = null
                        $scope.selectedTemplateImg = null
                        $scope.stampIconTemplateListQueried = false
                        if (resultObj.baseStampOverride) {
                            $scope.getStampIconTemplateList(false)
                        }
                        $scope.models.showStampImgInsteadOfStampBase = resultObj.baseStampOverride == true
                        $scope.A5ImgDownloadURL = {
                            'HU': resultObj.A5PromoImageHighResURL_HU,
                            'EN': resultObj.A5PromoImageHighResURL_EN
                        }
                        $scope.LCYImgDownloadURL = {
                            'HU': resultObj.LCYPromoImageHighResURL_HU,
                            'EN': resultObj.LCYPromoImageHighResURL_EN
                        }

                        $scope.previousIndex = null
                        $scope.companyId = resultObj.companyId
                        $scope.LCCompNamePromoPreview = {}
                        $scope.LCCompNamePromoPreview['HU'] = splitTextToRowsForLCPromoImg($scope.compNamePromo['HU'], 770, 42, 'Helvetica-bold', 1)
                        $scope.LCCompNamePromoPreview['EN'] = splitTextToRowsForLCPromoImg($scope.compNamePromo['EN'], 770, 42, 'Helvetica-bold', 1)
                        $scope.LCPromotionNamePreview = {}
                        $scope.LCPromotionNamePreview['HU'] = splitTextToRowsForLCPromoImg($scope.promotionName['HU'], 770, 38, 'Helvetica', 1)
                        $scope.LCPromotionNamePreview['EN'] = splitTextToRowsForLCPromoImg($scope.promotionName['EN'], 770, 38, 'Helvetica', 1)
                        $scope.usingOneLineTemplate = {}
                        $scope.usingOneLineTemplate['HU'] = !$scope.LCPromotionNamePreview['HU'].secondLine
                        $scope.usingOneLineTemplate['EN'] = !$scope.LCPromotionNamePreview['EN'].secondLine
                        $scope.A5PromotionNamePreview = {}
                        $scope.preview = {}
                        $scope.preview.stampIconURLPrefix =
                            $scope.models.showStampImgInsteadOfStampBase ?
                                $scope.baseStampOverrideStampIconURL : $scope.showStampIcon == 0 ?
                                    null : $scope.stampIconURLPrefix

                        $scope.visibility = resultObj.packageVisibleToPartner || false
                        $scope.packageLastGenerationTS = {
                            'HU': resultObj.packageLastGenerationTS_HU,
                            'EN': resultObj.packageLastGenerationTS_EN
                        }
                        $scope.lastUpdatedTimestamp = resultObj.lastUpdatedTimestamp
                        $scope.LCHasMarketingPackage = {}
                        $scope.LCHasMarketingPackage['HU'] = resultObj.packageLastGenerationTS_HU != null
                        $scope.LCHasMarketingPackage['EN'] = resultObj.packageLastGenerationTS_EN != null
                        $scope.LCHasMarketingPackage['ANY'] = $scope.LCHasMarketingPackage['HU'] || $scope.LCHasMarketingPackage['EN'] ? true : false
                        $scope.showMarketingGeneration = [1, 2].includes($scope.loyaltyCardStatus) || ($scope.loyaltyCardStatus == 3 && $scope.LCHasMarketingPackage['ANY'])
                        if ($scope.loyaltyCardStatus == 3 && $scope.LCHasMarketingPackage['ANY']) {
                            if ($scope.LCHasMarketingPackage['HU']) $scope.languageSelector = 'HU'
                            else if ($scope.LCHasMarketingPackage['EN']) $scope.languageSelector = 'EN'
                        }
                        $scope.QRCode = `https://www.yalty.com/app-download-landing?cm=${$scope.companyId}&e=A5_${$scope.languageSelector}`
                        $scope.visibility = resultObj.packageVisibleToPartner || false
                        $scope.initValues = {
                            models: angular.copy($scope.models)
                        }
                        $scope.initValues.loyaltyCard = {
                            'HU': {
                                cardLogoImgURL: result.data.loyaltyCard.cardLogoImgURL,
                                compNamePromo: result.data.loyaltyCard.compNamePromo_HU,
                                promotionName: result.data.loyaltyCard.promotionName_HU,
                                cardBackgroundColor: result.data.loyaltyCard.cardBackgroundColor,
                                compNamePromoTextColor: result.data.loyaltyCard.compNamePromoTextColor,
                                promotionNameTextColor: result.data.loyaltyCard.promotionNameTextColor,
                                stampTextPlaceholderColor: result.data.loyaltyCard.stampTextPlaceholderColor,
                                stampCurrentCountTextColor: result.data.loyaltyCard.stampCurrentCountTextColor,
                                baseStampUnstampedFillColor: result.data.loyaltyCard.baseStampUnstampedFillColor,
                                baseStampUnstampedLineWeight: result.data.loyaltyCard.baseStampUnstampedLineWeight,
                                baseStampUnstampedLineColor: result.data.loyaltyCard.baseStampUnstampedLineColor,
                                baseStampStampedFillColor: result.data.loyaltyCard.baseStampStampedFillColor,
                                baseStampStampedLineWeight: result.data.loyaltyCard.baseStampStampedLineWeight,
                                baseStampStampedLineColor: result.data.loyaltyCard.baseStampStampedLineColor,
                                stampIconUnstampedColor: result.data.loyaltyCard.stampIconUnstampedColor,
                                stampIconStampedColor: result.data.loyaltyCard.stampIconStampedColor,
                                stampIconUnstampedSizing: result.data.loyaltyCard.stampIconUnstampedSizing,
                                stampIconStampedSizing: result.data.loyaltyCard.stampIconStampedSizing,
                                showBaseStampDesign: result.data.loyaltyCard.showBaseStampDesign,
                                showStampIcon: result.data.loyaltyCard.showStampIcon
                            },
                            'EN': {
                                cardLogoImgURL: result.data.loyaltyCard.cardLogoImgURL,
                                compNamePromo: result.data.loyaltyCard.compNamePromo_EN,
                                promotionName: result.data.loyaltyCard.promotionName_EN,
                                cardBackgroundColor: result.data.loyaltyCard.cardBackgroundColor,
                                compNamePromoTextColor: result.data.loyaltyCard.compNamePromoTextColor,
                                promotionNameTextColor: result.data.loyaltyCard.promotionNameTextColor,
                                stampTextPlaceholderColor: result.data.loyaltyCard.stampTextPlaceholderColor,
                                stampCurrentCountTextColor: result.data.loyaltyCard.stampCurrentCountTextColor,
                                baseStampUnstampedFillColor: result.data.loyaltyCard.baseStampUnstampedFillColor,
                                baseStampUnstampedLineWeight: result.data.loyaltyCard.baseStampUnstampedLineWeight,
                                baseStampUnstampedLineColor: result.data.loyaltyCard.baseStampUnstampedLineColor,
                                baseStampStampedFillColor: result.data.loyaltyCard.baseStampStampedFillColor,
                                baseStampStampedLineWeight: result.data.loyaltyCard.baseStampStampedLineWeight,
                                baseStampStampedLineColor: result.data.loyaltyCard.baseStampStampedLineColor,
                                stampIconUnstampedColor: result.data.loyaltyCard.stampIconUnstampedColor,
                                stampIconStampedColor: result.data.loyaltyCard.stampIconStampedColor,
                                stampIconUnstampedSizing: result.data.loyaltyCard.stampIconUnstampedSizing,
                                stampIconStampedSizing: result.data.loyaltyCard.stampIconStampedSizing,
                                showBaseStampDesign: result.data.loyaltyCard.showBaseStampDesign,
                                showStampIcon: result.data.loyaltyCard.showStampIcon
                            }
                        }
                        $scope.initValues.marketingPackageLCY = {
                            'HU': {
                                cardLogoImgURL: result.data.marketingPackageLCY.cardLogoImgURL,
                                compNamePromo: result.data.marketingPackageLCY.compNamePromo_HU,
                                promotionName: result.data.marketingPackageLCY.promotionName_HU,
                                cardBackgroundColor: result.data.marketingPackageLCY.cardBackgroundColor,
                                compNamePromoTextColor: result.data.marketingPackageLCY.compNamePromoTextColor,
                                promotionNameTextColor: result.data.marketingPackageLCY.promotionNameTextColor,
                                stampTextPlaceholderColor: result.data.marketingPackageLCY.stampTextPlaceholderColor,
                                stampCurrentCountTextColor: result.data.marketingPackageLCY.stampCurrentCountTextColor,
                                baseStampUnstampedFillColor: result.data.marketingPackageLCY.baseStampUnstampedFillColor,
                                baseStampUnstampedLineWeight: result.data.marketingPackageLCY.baseStampUnstampedLineWeight,
                                baseStampUnstampedLineColor: result.data.marketingPackageLCY.baseStampUnstampedLineColor,
                                baseStampStampedFillColor: result.data.marketingPackageLCY.baseStampStampedFillColor,
                                baseStampStampedLineWeight: result.data.marketingPackageLCY.baseStampStampedLineWeight,
                                baseStampStampedLineColor: result.data.marketingPackageLCY.baseStampStampedLineColor,
                                stampIconUnstampedColor: result.data.marketingPackageLCY.stampIconUnstampedColor,
                                stampIconStampedColor: result.data.marketingPackageLCY.stampIconStampedColor,
                                stampIconUnstampedSizing: result.data.marketingPackageLCY.stampIconUnstampedSizing,
                                stampIconStampedSizing: result.data.marketingPackageLCY.stampIconStampedSizing,
                                showBaseStampDesign: result.data.loyaltyCard.showBaseStampDesign,
                                showStampIcon: result.data.loyaltyCard.showStampIcon
                            },
                            'EN': {
                                cardLogoImgURL: result.data.marketingPackageLCY.cardLogoImgURL,
                                compNamePromo: result.data.marketingPackageLCY.compNamePromo_EN,
                                promotionName: result.data.marketingPackageLCY.promotionName_EN,
                                cardBackgroundColor: result.data.marketingPackageLCY.cardBackgroundColor,
                                compNamePromoTextColor: result.data.marketingPackageLCY.compNamePromoTextColor,
                                promotionNameTextColor: result.data.marketingPackageLCY.promotionNameTextColor,
                                stampTextPlaceholderColor: result.data.marketingPackageLCY.stampTextPlaceholderColor,
                                stampCurrentCountTextColor: result.data.marketingPackageLCY.stampCurrentCountTextColor,
                                baseStampUnstampedFillColor: result.data.marketingPackageLCY.baseStampUnstampedFillColor,
                                baseStampUnstampedLineWeight: result.data.marketingPackageLCY.baseStampUnstampedLineWeight,
                                baseStampUnstampedLineColor: result.data.marketingPackageLCY.baseStampUnstampedLineColor,
                                baseStampStampedFillColor: result.data.marketingPackageLCY.baseStampStampedFillColor,
                                baseStampStampedLineWeight: result.data.marketingPackageLCY.baseStampStampedLineWeight,
                                baseStampStampedLineColor: result.data.marketingPackageLCY.baseStampStampedLineColor,
                                stampIconUnstampedColor: result.data.marketingPackageLCY.stampIconUnstampedColor,
                                stampIconStampedColor: result.data.marketingPackageLCY.stampIconStampedColor,
                                stampIconUnstampedSizing: result.data.marketingPackageLCY.stampIconUnstampedSizing,
                                stampIconStampedSizing: result.data.marketingPackageLCY.stampIconStampedSizing,
                                showBaseStampDesign: result.data.loyaltyCard.showBaseStampDesign,
                                showStampIcon: result.data.loyaltyCard.showStampIcon
                            }
                        }
                        $scope.emailSendings = resultObj.notificationEmailSentInfoChain ? resultObj.notificationEmailSentInfoChain.split('#') : []
                        $scope.isLCDataModified = {}
                        $scope.isLCDataUpdateButtonPressed = {}
                        checkLCValuesModified()

                        if($scope.nrOfStamps < 6){
                            LCPreviewCanvas.height= 238;
                            LCPreviewCanvasToSend.height=238;
                            LCSourceCanvas.height = 595;
                            LCCanvasToSend.height = 595;
                            LCYOriginalHeight = 595
                            LCYScaledHeight = LCYOriginalHeight / LCYOriginalAndScaledRatio
                        } else {
                            LCPreviewCanvas.height= 288;
                            LCPreviewCanvasToSend.height=288;
                            LCSourceCanvas.height = 720;
                            LCCanvasToSend.height = 720;
                            LCYOriginalHeight = 720
                            LCYScaledHeight = LCYOriginalHeight / LCYOriginalAndScaledRatio
                        }
                        await drawBackground()
                        await drawStamps()
                        await drawCompLogo()
                        await writePromotionName()
                        await writeCompName()
                        writeStampNumbers()
                        copyToLCDestinationCanvas()
                        resultObj.packageLastGenerationTS_HU ? $scope.writeA5PromoName() : clearA5PromoName()
                        await $scope.placeQRCode()
                        $scope.copyLCToA5DestinationCanvas()

                        

                        for (const lang of $scope.languages) {
                            $(`#${lang.code}_A5PromotionName, #${lang.code}_promoFreeText, #${lang.code}_A5promotionName2ndLine`).keypress(function (e) {
                                if (e.keyCode == 13) {
                                    return false
                                }
                            });
                        }

                        GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/marketing_csomagok/marketing_csomag_lekérdezése_sikeres",
                            "HU Partner [PRIVÁT] - Hűségkártyák marketing csomagok oldal - Marketing csomag lekérdezése sikeres");
                        
                        

                        /*setTimeout(function () {
                            $(function () {
                                $(`#${$scope.languageSelector}_measurePromotionNameWidth`).text($(`#${$scope.languageSelector}_A5PromotionName`).val());
                                let width = $(`#${$scope.languageSelector}_measurePromotionNameWidth`).width() > 300 ?
                                    $(`#${$scope.languageSelector}_measurePromotionNameWidth`).width() : 300
                                $(`#${$scope.languageSelector}_promotionNameContainer`).width(width);
                            }).on('input', function () {
                                $(`#${$scope.languageSelector}_measurePromotionNameWidth`).text($(`#${$scope.languageSelector}_A5PromotionName`).val());
                                $(`#${$scope.languageSelector}_promotionNameContainer`).width($(`#${$scope.languageSelector}_measurePromotionNameWidth`).width());
                            });
                            $(function () {
                                $(`#${$scope.languageSelector}_measurePromotionName2ndLineWidth`).text($(`#${$scope.languageSelector}_A5promotionName2ndLine`).val());
                                let width = $(`#${$scope.languageSelector}_measurePromotionName2ndLineWidth`).width() > 300 ?
                                    $(`#${$scope.languageSelector}_measurePromotionName2ndLineWidth`).width() : 300
                                $(`#${$scope.languageSelector}_promotionName2ndLineContainer`).width(width);
                            }).on('input', function () {
                                $(`#${$scope.languageSelector}_measurePromotionName2ndLineWidth`).text($(`#${$scope.languageSelector}_A5promotionName2ndLine`).val());
                                let width = $(`#${$scope.languageSelector}_measurePromotionName2ndLineWidth`).width() > 300 ?
                                    $(`#${$scope.languageSelector}_measurePromotionName2ndLineWidth`).width() : 300
                                $(`#${$scope.languageSelector}_promotionName2ndLineContainer`).width(width);
                            });
                            $(function () {
                                $(`#${$scope.languageSelector}_measurePromoFreeTextWidth`).text($(`#${$scope.languageSelector}_promoFreeText`).val());
                                let width = $(`#${$scope.languageSelector}_measurePromoFreeTextWidth`).width() > 300 ?
                                    $(`#${$scope.languageSelector}_measurePromoFreeTextWidth`).width() : 300
                                $(`#${$scope.languageSelector}_promoFreeTextContainer`).width(width);
                            }).on('input', function () {
                                $(`#${$scope.languageSelector}_measurePromoFreeTextWidth`).text($(`#${$scope.languageSelector}_promoFreeText`).val());
                                $(`#${$scope.languageSelector}_promoFreeTextContainer`).width($(`#${$scope.languageSelector}_measurePromoFreeTextWidth`).width());
                            });
                        }, 0);*/
                    } else {
                        GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/marketing_csomagok/marketing_csomag_lekérdezése_sikertelen",
                            "HU Partner [PRIVÁT] - Hűségkártyák marketing csomagok oldal - Marketing csomag lekérdezése sikertelen");
                    }
                });
        }

        $scope.setUpdatedLCData = async (updateLCScopeValues) => {
            if (updateLCScopeValues === true) setLCScopeValues();
            await drawBackground()
            await drawStamps()
            await drawCompLogo()
            await writePromotionName()
            await writeCompName()
            writeStampNumbers()
            copyToLCDestinationCanvas()
            $scope.copyLCToA5DestinationCanvas()
            $scope.isLCDataModified[$scope.languageSelector] = false
            $scope.isLCDataUpdateButtonPressed[$scope.languageSelector] = true
            $rootScope.isDataSheetModified = true
        }

        const setLCScopeValues = () => {
            $scope.cardLogoImgURL = $scope.initValues.loyaltyCard[$scope.languageSelector].cardLogoImgURL
            $scope.cardBackgroundColor = $scope.initValues.loyaltyCard[$scope.languageSelector].cardBackgroundColor
            $scope.compNamePromoTextColor = $scope.initValues.loyaltyCard[$scope.languageSelector].compNamePromoTextColor
            $scope.promotionNameTextColor = $scope.initValues.loyaltyCard[$scope.languageSelector].promotionNameTextColor
            $scope.stampTextPlaceholderColor = $scope.initValues.loyaltyCard[$scope.languageSelector].stampTextPlaceholderColor
            $scope.stampCurrentCountTextColor = $scope.initValues.loyaltyCard[$scope.languageSelector].stampCurrentCountTextColor
            $scope.baseStampUnstampedFillColor = $scope.initValues.loyaltyCard[$scope.languageSelector].baseStampUnstampedFillColor
            $scope.baseStampUnstampedLineWeight = $scope.initValues.loyaltyCard[$scope.languageSelector].baseStampUnstampedLineWeight
            $scope.baseStampUnstampedLineColor = $scope.initValues.loyaltyCard[$scope.languageSelector].baseStampUnstampedLineColor
            $scope.baseStampStampedFillColor = $scope.initValues.loyaltyCard[$scope.languageSelector].baseStampStampedFillColor
            $scope.baseStampStampedLineWeight = $scope.initValues.loyaltyCard[$scope.languageSelector].baseStampStampedLineWeight
            $scope.baseStampStampedLineColor = $scope.initValues.loyaltyCard[$scope.languageSelector].baseStampStampedLineColor
            $scope.stampIconUnstampedColor = $scope.initValues.loyaltyCard[$scope.languageSelector].stampIconUnstampedColor
            $scope.stampIconStampedColor = $scope.initValues.loyaltyCard[$scope.languageSelector].stampIconStampedColor
            $scope.stampIconUnstampedSizing =  $scope.models.showStampImgInsteadOfStampBase ? 2 : $scope.initValues.loyaltyCard[$scope.languageSelector].stampIconUnstampedSizing
            $scope.stampIconStampedSizing = $scope.models.showStampImgInsteadOfStampBase ? 2 : $scope.initValues.loyaltyCard[$scope.languageSelector].stampIconStampedSizing
            $scope.showBaseStampDesign = $scope.initValues.loyaltyCard[$scope.languageSelector].showBaseStampDesign
            $scope.showStampIcon = $scope.initValues.loyaltyCard[$scope.languageSelector].showStampIcon
            $scope.compNamePromo[$scope.languageSelector] = $scope.initValues.loyaltyCard[$scope.languageSelector].compNamePromo
            $scope.promotionName[$scope.languageSelector] = $scope.initValues.loyaltyCard[$scope.languageSelector].promotionName
            if ($scope.partnerSubscriptionType != 'yalty_start_paid' && $scope.partnerSubscriptionType != 'yalty_start_free') {
                $scope.stampIconURLPrefix = $scope.LCStampIconURLPrefix
            }
            $scope.LCCompNamePromoPreview[$scope.languageSelector] = splitTextToRowsForLCPromoImg($scope.compNamePromo[$scope.languageSelector], 770, 42, 'Helvetica-bold', 1)
            $scope.LCPromotionNamePreview[$scope.languageSelector] = splitTextToRowsForLCPromoImg($scope.promotionName[$scope.languageSelector], 770, 38, 'Helvetica', 1)
            $scope.usingOneLineTemplate[$scope.languageSelector] = !$scope.LCPromotionNamePreview[$scope.languageSelector].secondLine
            $scope.preview.stampIconURLPrefix =
                $scope.models.showStampImgInsteadOfStampBase ?
                    $scope.baseStampOverrideStampIconURL : $scope.showStampIcon == 0 ?
                        null : $scope.stampIconURLPrefix
        }

        $scope.checkFormValuesModified = (langToCheck) => {
            const lang = langToCheck || $scope.languageSelector
            if (Object.keys($scope.initValues).length !== 0 && $scope.models) {
                $rootScope.isDataSheetModified = !angular.equals($scope.initValues.models[lang], $scope.models[lang]) ||
                    $scope.models.showStampImgInsteadOfStampBase != $scope.initValues.models.showStampImgInsteadOfStampBase ||
                    $scope.models.templateImgs != $scope.initValues.models.templateImgs ||
                    $scope.isLCDataUpdateButtonPressed[lang]
            } else {
                $rootScope.isDataSheetModified = false
            }
            return $rootScope.isDataSheetModified
        }

        const checkFormValuesModifiedForAllLang = () => {
            if (Object.keys($scope.initValues).length !== 0 && $scope.models) {
                return (!angular.equals($scope.initValues.models, $scope.models) || $scope.isLCDataUpdateButtonPressed[$scope.languageSelector])
            } else {
                return false
            }
        }

        const checkLCValuesModified = () => {
            if ($scope.isLCDataModified[$scope.languageSelector] === false) return false
            if (!$scope.LCHasMarketingPackage[$scope.languageSelector]) {
                $scope.isLCDataModified[$scope.languageSelector] = false
            } else {
                $scope.isLCDataModified[$scope.languageSelector] = !angular.equals($scope.initValues.loyaltyCard[$scope.languageSelector], $scope.initValues.marketingPackageLCY[$scope.languageSelector])
                    || ($scope.LCStampIconURLPrefix != $scope.stampIconURLPrefix && ($scope.partnerSubscriptionType != 'yalty_start_paid' || $scope.partnerSubscriptionType != 'yalty_start_free'))
            }
            return $scope.isLCDataModified[$scope.languageSelector]
        }

        $scope.switchLoyaltyCard = () => {
            return new Promise(resolve => {
                if ($rootScope.isDataSheetModified) {
                    const title = 'Biztosan elhagyja az oldalt?'
                    const content = 'Egy vagy több mezőt módosított. Ha szeretné a módosításait véglegesíteni, akkor nyomjon a Mégsem gombra, majd a Generálás gombbal véglegesítse a marketing csomaghoz beállított jellemzőket.'
                    var confirm = $mdDialog.confirm()
                        .title(title)
                        .textContent(content)
                        .ariaLabel('Oldal elhagyás megerősítése')
                        .ok('Igen')
                        .cancel('Mégsem');
                    $mdDialog.show(confirm).then(function () {
                        $rootScope.isDataSheetModified = false;
                        resolve()
                    }, function () {
                        resolve('canceled')
                    });
                } else resolve()
            })

        }

        $scope.exit = () => {
            if (checkFormValuesModifiedForAllLang() || $rootScope.isDataSheetModified) {
                const title = 'Biztosan elhagyja az oldalt?'
                const content = 'Egy vagy több mezőt módosított. Ha szeretné a módosításait véglegesíteni, akkor nyomjon a Mégsem gombra, majd a Generálás gombbal véglegesítse a marketing csomaghoz beállított jellemzőket.'
                var confirm = $mdDialog.confirm()
                    .title(title)
                    .textContent(content)
                    .ariaLabel('Oldal elhagyás megerősítése')
                    .ok('Igen')
                    .cancel('Mégsem');
                $mdDialog.show(confirm).then(function () {
                    $rootScope.isDataSheetModified = false;
                    $scope.isLCSelected = false
                    $scope.child.showLCList = true
                }, function () { });
            } else {
                $rootScope.isDataSheetModified = false;
                $scope.isLCSelected = false
                $scope.child.showLCList = true
            }
        }

        $scope.switchLanguage = async (previousSelectedLang) => {
            if ($scope.loyaltyCardStatus === 3 && !$scope.LCHasMarketingPackage[$scope.languageSelector]) {
                $scope.languageSelector = previousSelectedLang
                const confirm = $mdDialog.confirm()
                    .title('A kártya törölt állapotú. A most kiválasztott nyelvhez nem készült korábban marketing csomag. Törölt kártya esetén már nincs lehetőség marketing csomag (újra)generálásához.')
                    .ok('Rendben')
                await $mdDialog.show(confirm)
            } else if ($scope.checkFormValuesModified(previousSelectedLang)) {
                const confirm = $mdDialog.confirm()
                    .title('Egy vagy több mezőt módosított. Ezek a módosítások nyelvváltáskor elvesz(het)nek. Ha szeretné a módosításait véglegesíteni, akkor nyomjon a Mégsem gombra, majd a Generálás gombbal véglegesítse a marketing csomaghoz beállított jellemzőket.')
                    .ok('Tovább')
                    .cancel('Mégsem')
                $mdDialog.show(confirm).then(function () {
                    checkLanguageError(previousSelectedLang)
                }, function () {
                    $scope.languageSelector = previousSelectedLang
                    return
                })
            } else checkLanguageError(previousSelectedLang)
        }

        const checkLanguageError = async (previousSelectedLang) => {
            let startPartString = `<div class="md-title">A kiválasztott nyelvhez hűségkártyáján nincs kitöltve minden szükséges mező. A marketing csomag legenerálásához ehhez a nyelvhez először ezen adatok megadása szükséges. Lépjen át a Hűségkártya oldalára, ott válassza ki ezt a nyelvet és töltse ki az összes mezőt. Jelenleg a következő mező(k) fordításai hiányoznak:</div><br>`
            let listItemsString = '<ul class=\"newPreregAltertWindowList md-title\">'
            let needToShowDialog = false
            if (!$scope.initValues.loyaltyCard[$scope.languageSelector].compNamePromo) {
                listItemsString += '<li>Promóción megjelenő cég megnevezés</li>'
                needToShowDialog = true
            }
            if (!$scope.initValues.loyaltyCard[$scope.languageSelector].promotionName) {
                listItemsString += '<li>Kedvezmény rövid megnevezése</li>'
                needToShowDialog = true
            }
            if (needToShowDialog) {
                listItemsString += '</ul>'
                let endPartString = '<br><div class="md-title">Miután kitöltött minden mezőt az adott nyelvhez jöjjön vissza erre az oldalra és generálja le marketing csomagját az adott nyelvre is.</div>'
                var confirm = $mdDialog.confirm()
                    .htmlContent(startPartString + listItemsString + endPartString)
                    .ok('Hűségkártya oldal')
                    .cancel('Mégsem')
                $mdDialog.show(confirm).then(function () {
                    $rootScope.isDataSheetModified = false
                    $state.go('home.huseg2', { loyaltyCardId: $scope.loyaltyCardId })
                }, function () {
                    $scope.languageSelector = previousSelectedLang
                    return
                })
            } else {
                $scope.QRCode = `https://www.yalty.com/app-download-landing?cm=${$scope.companyId}&e=A5_${$scope.languageSelector}`
                checkLCValuesModified()
                await drawBackground()
                await writePromotionName()
                await writeCompName()
                await drawCompLogo()
                await drawStamps()
                writeStampNumbers()
                copyToLCDestinationCanvas()
                clearA5PromoName()
                $scope.writeA5PromoName()
                await $scope.placeQRCode()
                $scope.copyLCToA5DestinationCanvas()
            }
        }

        $scope.getStampIconTemplateList = (openWindow) => {
            if (!$scope.stampIconTemplateListQueried) {
                $scope.stampIconTemplateListQueried = true
                if (openWindow && document.getElementById('loadingOverlay')) {
                    document.getElementById('loadingOverlay').style.display = "block";
                    document.getElementById('innerPartnerPageContainer').style.overflow = "hidden";
                }
                $http.post(API_PREFIX.url + '/loyaltyCard/getLoyaltyCardYaltyStampIconTemplateList', {}).then(function (result) {
                    if (result.data.errorCode == 0) {
                        $scope.stampIconCategories = result.data.categoryList;
                        $scope.stampImages = result.data.loyaltyCardStampIconTemplateList;
                        $scope.selectedCategory = result.data.categoryList[0];
                        $scope.selectCategory = result.data.categoryList[0];
                        $scope.isSelectTemplateImg = openWindow;
                        for (var i = 0; i < $scope.stampImages.length; i++) {
                            $scope.stampImages[i].id = i;
                            $scope.stampImages[i].checked = false;
                        }
                        if (!openWindow) {
                            var index = $scope.stampImages.findIndex(x => x.stampIconURLPrefix == $scope.baseStampOverrideStampIconURL);
                            if (index != -1) {
                                $scope.selectTemplateImg = $scope.stampImages[index];
                                $scope.selectedTemplateImg = $scope.stampImages[index];
                            }
                        }
                        if (openWindow && document.getElementById('loadingOverlay')) {
                            document.getElementById('loadingOverlay').style.display = "none";
                            document.getElementById('innerPartnerPageContainer').style.overflow = "hidden";
                        }
                    }
                });
            } else {
                $scope.isSelectTemplateImg = true;
            }
        }

        $scope.selectingCategory = function (category) {
            $scope.selectedCategory = category;
            if ($scope.selectTemplateImg) {
                if ($scope.selectTemplateImg.categoryName == category.categoryName) {
                    var filteredImages = $filter('filter')($scope.stampImages, { categoryName: $scope.selectedCategory.categoryName });
                    var elementTop = filteredImages.findIndex(record => record.id === $scope.selectTemplateImg.id)
                    setTimeout(function () {
                        $('#templateStampContainer .md-virtual-repeat-scroller')[0].scrollTop = elementTop * 45;
                    }, 250)
                } else {
                    setTimeout(function () {
                        $('#templateStampContainer .md-virtual-repeat-scroller')[0].scrollTop = 0;
                    }, 250)
                }
            } else {
                $('#templateStampContainer .md-virtual-repeat-scroller')[0].scrollTop = 0;
            }
        }

        $scope.selectTemplateImage = function (stamp, index) {
            if ($scope.selectTemplateImg == stamp) {
                $scope.selectTemplateImg = null;
                $scope.stampImages[index].checked = false
            } else {
                $scope.selectTemplateImg = stamp;
                $scope.stampImages[index].checked = true
                if ($scope.previousIndex !== null && index != $scope.previousIndex)
                    $scope.stampImages[$scope.previousIndex].checked = false
            }
            $scope.previousIndex = index
        }

        $scope.cancelStampIconTemplate = function () {
            document.getElementById('innerPartnerPageContainer').style.overflow = "unset";
            $scope.isSelectTemplateImg = false;
            $scope.selectedCategory = $scope.selectCategory;
            if ($scope.selectTemplateImg) {
                $scope.stampImages[$scope.previousIndex].checked = false
                $scope.selectTemplateImg = null;
            }
            if ($scope.selectedTemplateImg) {
                $scope.selectTemplateImg = $scope.selectedTemplateImg;
            }
        }

        $scope.saveSelectedIconTemplate = async function () {
            $scope.isSelectTemplateImg = false;
            $scope.selectedTemplateImg = $scope.selectTemplateImg;
            $scope.preview.stampIconURLPrefix = $scope.selectedTemplateImg.stampIconURLPrefix
            $scope.selectCategory = $scope.selectedCategory;
            $scope.stampIconType = 1;
            $scope.showBaseStampDesign = false
            $scope.showTemplateStampIcon = true;
            $scope.marketingPackageForm.templateImgs.$setValidity('templateImgRequired', true);
            $scope.stampIconUnstampedSizing = 2;
            $scope.stampIconStampedSizing = 2;
            $scope.setUpdatedLCData(false);
            document.getElementById('innerPartnerPageContainer').style.overflow = "unset";
            $scope.checkFormValuesModified()
        }

        $scope.copyFacebookText = () => {
            var container = document.getElementById($scope.languageSelector == 'EN' ? 'facebookText_EN' : 'facebookText');
             // Gyűjtsük össze az összes gyermek elem szövegét
            var text = Array.from(container.childNodes)
            .filter(node => node.nodeType === Node.TEXT_NODE || node.tagName === 'BR')
            .map(node => node.nodeType === Node.TEXT_NODE ? node.textContent.trim() : '\n')
            .join('');

            // Most már a text változóban van a tiszta szöveg
            var dummy = document.createElement("textarea");
            document.body.appendChild(dummy);
            dummy.value = text;
            dummy.select();
            document.execCommand("copy");
            document.body.removeChild(dummy);


            $scope.successCopyText = `A Facebook poszt szövegét a vágólapra másoltuk.`

            if (document.getElementById('okMessageAfterCopy')) {
                document.getElementById('okMessageAfterCopy').style.display = "block";
                $('#okMessageAfterCopy').delay(2000).hide(0)
            }
        }

        const measureTextWidth = (text, fontSize, fontType) => {
            if (!text) return 0
            const canvas = document.createElement("canvas");
            let context = canvas.getContext("2d");
            context.font = `${fontSize}px ${fontType}`;
            let addedRoundedWidth = 0
            text = text.toUpperCase()
            for (let i = 0; i < text.length; i++) {
                addedRoundedWidth += Math.round(context.measureText(text[i]).width);
            }
            return addedRoundedWidth
        }

        const splitTextToRowsForLCPromoImg = (text, maxWidth, fontSize, fontType, rowSplitType) => {
            if (!text) return {
                firstLine: null,
                secondLine: null
            }
            text = text
            const words = text.split(' ')
            const nrOfWords = words.length
            let firstLine = [words[0]]
            let secondLine = []
            let nextWordIndex = 1
            let lastWordIndexOfFirstLine
            let currentWidth = measureTextWidth(firstLine.join(' '), fontSize, fontType)
            if (currentWidth > maxWidth)
                console.log('Első szó nem fér ki egy sorba')
            while (currentWidth <= maxWidth && nextWordIndex < nrOfWords) {
                firstLine.push(words[nextWordIndex])
                currentWidth = measureTextWidth(firstLine.join(' '), fontSize, fontType)
                nextWordIndex++
            }
            let needMultipleLine = currentWidth > maxWidth
            if (needMultipleLine) {
                lastWordIndexOfFirstLine = nextWordIndex - 1
                firstLine.pop()
                secondLine = words.slice(lastWordIndexOfFirstLine, nrOfWords)
            }
            if (rowSplitType === 2 && needMultipleLine) {
                while (
                    measureTextWidth(secondLine.join(' '), fontSize, fontType) <= maxWidth &&
                    measureTextWidth(firstLine.join(' '), fontSize, fontType) >
                    measureTextWidth(secondLine.join(' '), fontSize, fontType)) {
                    if (firstLine.length === 1) break
                    lastWordIndexOfFirstLine--
                    firstLine.pop()
                    secondLine.splice(0, 0, words[lastWordIndexOfFirstLine])
                }
                if (measureTextWidth(secondLine.join(' '), fontSize, fontType) > maxWidth &&
                    measureTextWidth(firstLine.join(' '), fontSize, fontType) < maxWidth) {
                    secondLine.splice(0, 1)
                    firstLine.push(words[lastWordIndexOfFirstLine])
                }
            }
            if (measureTextWidth(firstLine.join(' '), fontSize, fontType) > maxWidth ||
                measureTextWidth(secondLine.join(' '), fontSize, fontType) > maxWidth)
                return { err: 'Két sorban sem fér ki a szöveg' }
            return {
                firstLine: firstLine.join(' '),
                secondLine: secondLine.join(' ')
            }
        }

        const loadImage = (url) => {
            return new Promise(resolve => {
                let img = new Image();
                img.onload = (() => resolve(img));
                img.src = url;
            });
        }

        function roundRect(x0, y0, x1, y1, r, color)
        {
            var w = x1 - x0;
            var h = y1 - y0;
            if (r > w/2) r = w/2;
            if (r > h/2) r = h/2;
            LCSourceCtx.beginPath();
            LCSourceCtx.moveTo(x1 - r, y0);
            LCSourceCtx.quadraticCurveTo(x1, y0, x1, y0 + r);
            LCSourceCtx.lineTo(x1, y1);
            LCSourceCtx.lineTo(x0, y1);
            LCSourceCtx.lineTo(x0, y0 + r);
            LCSourceCtx.quadraticCurveTo(x0, y0, x0 + r, y0);
            LCSourceCtx.closePath();
            LCSourceCtx.fillStyle = color;
            LCSourceCtx.fill();
            LCSourceCtx.lineWidth = 3;
            LCSourceCtx.stroke();
        }

        const drawBackground = async () => {
            LCSourceCtx.strokeStyle =  $scope.cardBackgroundColor == '#FFFFFF' ? '#dddddd' : $scope.cardBackgroundColor;
            LCSourceCtx.fillStyle = $scope.cardBackgroundColor;
            roundRect(20, 95, 1060, 728, 60, true);
            LCSourceCtx.drawImage(hearth, 950, 20, 80, 70);
        }

        const drawStamps = () => {
            return new Promise(async resolve => {
                let stampedIcon;
                let unstampedIcon;
                if($scope.preview.stampIconURLPrefix != null && $scope.preview.stampIconURLPrefix != ''){
                    stampedIcon = await loadImage($scope.preview.stampIconURLPrefix + '_stamped.png')
                    unstampedIcon = await loadImage($scope.preview.stampIconURLPrefix + '_unstamped.png')
                }
                let giftIcon = await loadImage('/imgs/gift_icon.svg')

                //LCSourceCtx.clearRect(150, 340, 900, 290);
                //LCPreviewCtx.clearRect(150 / LCYOriginalAndScaledRatio, 340 / LCYOriginalAndScaledRatio, 900 / LCYOriginalAndScaledRatio, 290 / LCYOriginalAndScaledRatio);

                const totalStampNr = $scope.nrOfStamps
                const stampedNr = $scope.floorNrOfStamps
                const nrOfStampsToDraw = totalStampNr > 10 ?
                    10 : totalStampNr
                let stampStartX;
                let stampStartY;
                for (let i = 0; i < nrOfStampsToDraw; i++) {
                    if(i === 0){
                        stampStartX = 164
                        stampStartY = 451
                    } else if (i === 5) {
                        stampStartX = 164
                        stampStartY = 615
                    } else {
                        stampStartX += 188
                    }
                    let iconToDraw = i < stampedNr ?
                        stampedIcon : unstampedIcon
                    let baseStampLineColor = i < stampedNr ?
                    $scope.baseStampStampedLineColor : $scope.baseStampUnstampedLineColor
                    let baseStampFillColor = i < stampedNr ?
                    $scope.baseStampStampedFillColor : $scope.baseStampUnstampedFillColor
                    let baseStampLineWeight = i < stampedNr ?
                    $scope.baseStampStampedLineWeight : $scope.baseStampUnstampedLineWeight
                    let stampIconColor = i < stampedNr ?
                    $scope.stampIconStampedColor : $scope.stampIconUnstampedColor
                    if($scope.showBaseStampDesign == true && $scope.models.showStampImgInsteadOfStampBase != true){
                        LCSourceCtx.beginPath();
                        LCSourceCtx.arc(stampStartX, stampStartY, 61 - ((3 * baseStampLineWeight) / 2), 0, 2 * Math.PI);
                        LCSourceCtx.fillStyle = baseStampFillColor;
                        LCSourceCtx.fill();
                        LCSourceCtx.lineWidth = 3 * baseStampLineWeight;
                        LCSourceCtx.strokeStyle = baseStampLineWeight != 0 ? baseStampLineColor : baseStampFillColor;
                        LCSourceCtx.stroke();
                    }   
                    let stampIconWidth;
                    if(i < stampedNr){ //Pecsételt/jutalmat már beváltott
                        if($scope.stampIconStampedSizing == 1){
                            stampIconWidth = 70;
                        } else if($scope.stampIconStampedSizing == 2){
                            stampIconWidth = 90;
                        } else if($scope.stampIconStampedSizing == 3){
                            stampIconWidth = 106;
                        } else if($scope.stampIconStampedSizing == 4){
                            stampIconWidth = 122;
                        } else if($scope.stampIconStampedSizing == 5){
                            stampIconWidth = 138;
                        }
                    } else { //Még pecséletlen
                        if($scope.stampIconUnstampedSizing == 1){
                            stampIconWidth = 70;
                        } else if($scope.stampIconUnstampedSizing == 2){
                            stampIconWidth = 90;
                        } else if($scope.stampIconUnstampedSizing == 3){
                            stampIconWidth = 106;
                        } else if($scope.stampIconUnstampedSizing == 4){
                            stampIconWidth = 122;
                        } else if($scope.stampIconUnstampedSizing == 5){
                            stampIconWidth = 138;
                        }
                    }
                    if($scope.showStampIcon == true || $scope.models.showStampImgInsteadOfStampBase == true){
                        if(stampIconColor != null){
                            const copy = document.createElement("canvas");
                            copy.width = LCYOriginalWidth;
                            copy.height = LCYOriginalHeight;
    
                            const copyCtx = copy.getContext("2d");
                            copyCtx.fillStyle = stampIconColor;
                            copyCtx.fillRect(0, 0, LCYOriginalWidth, LCYOriginalHeight);
                            copyCtx.globalCompositeOperation = "destination-in";
                            copyCtx.drawImage(iconToDraw, stampStartX - stampIconWidth / 2, stampStartY - stampIconWidth / 2, stampIconWidth, stampIconWidth);
    
                            LCSourceCtx.drawImage(copy, 0, 0);
                        } else {
                            LCSourceCtx.drawImage(iconToDraw, stampStartX - stampIconWidth / 2, stampStartY - stampIconWidth / 2, stampIconWidth, stampIconWidth);
                        }
                    }
                    if(i < stampedNr){ //Pecsételt/jutalmat már beváltott
                        if(i+1 == $scope.reward1NrOfStampsNeeded || i+1 == $scope.reward2NrOfStampsNeeded || i+1 == $scope.reward3NrOfStampsNeeded || i+1 == $scope.reward4NrOfStampsNeeded || i+1 == $scope.reward5NrOfStampsNeeded){
                            if($scope.cardBackgroundColor == "#FFFFFF"){
                                LCSourceCtx.beginPath();
                                LCSourceCtx.arc(stampStartX + 63, stampStartY - 59, 22, 0, 2 * Math.PI);
                                LCSourceCtx.fillStyle = '#00AEEF';
                                LCSourceCtx.fill();
                                const copy = document.createElement("canvas");
                                copy.width = LCYOriginalWidth;
                                copy.height = LCYOriginalHeight;
        
                                const copyCtx = copy.getContext("2d");
                                copyCtx.fillStyle = '#8B8B8E';
                                copyCtx.fillRect(0, 0, LCYOriginalWidth, LCYOriginalHeight);
                                copyCtx.globalCompositeOperation = "destination-in";
                                copyCtx.drawImage(giftIcon, stampStartX + 48, stampStartY - 74, 30, 30);
        
                                LCSourceCtx.drawImage(copy, 0, 0);
                            } else {
                                LCSourceCtx.beginPath();
                                LCSourceCtx.arc(stampStartX + 63, stampStartY - 59, 22, 0, 2 * Math.PI);
                                LCSourceCtx.fillStyle = 'rgba(139, 139, 142, 0.7)';
                                LCSourceCtx.fill();
                                const copy = document.createElement("canvas");
                                copy.width = LCYOriginalWidth;
                                copy.height = LCYOriginalHeight;
        
                                const copyCtx = copy.getContext("2d");
                                copyCtx.fillStyle = '#00AEEF';
                                copyCtx.fillRect(0, 0, LCYOriginalWidth, LCYOriginalHeight);
                                copyCtx.globalCompositeOperation = "destination-in";
                                copyCtx.drawImage(giftIcon, stampStartX + 48, stampStartY - 74, 30, 30);
        
                                LCSourceCtx.drawImage(copy, 0, 0);
                            }
                        }
                    } else { //Még pecséletlen
                        if(i+1 == $scope.reward1NrOfStampsNeeded || i+1 == $scope.reward2NrOfStampsNeeded || i+1 == $scope.reward3NrOfStampsNeeded || i+1 == $scope.reward4NrOfStampsNeeded || i+1 == $scope.reward5NrOfStampsNeeded){
                            if($scope.cardBackgroundColor == "#FFFFFF"){
                                LCSourceCtx.beginPath();
                                LCSourceCtx.arc(stampStartX + 63, stampStartY - 59, 22, 0, 2 * Math.PI);
                                LCSourceCtx.fillStyle = '#DFDFDF';
                                LCSourceCtx.fill();
                                const copy = document.createElement("canvas");
                                copy.width = LCYOriginalWidth;
                                copy.height = LCYOriginalHeight;
        
                                const copyCtx = copy.getContext("2d");
                                copyCtx.fillStyle = '#FFFFFF';
                                copyCtx.fillRect(0, 0, LCYOriginalWidth, LCYOriginalHeight);
                                copyCtx.globalCompositeOperation = "destination-in";
                                copyCtx.drawImage(giftIcon, stampStartX + 48, stampStartY - 74, 30, 30);
        
                                LCSourceCtx.drawImage(copy, 0, 0);
                            } else {
                                LCSourceCtx.beginPath();
                                LCSourceCtx.arc(stampStartX + 63, stampStartY - 59, 22, 0, 2 * Math.PI);
                                LCSourceCtx.fillStyle = 'rgba(139, 139, 142, 0.3)';
                                LCSourceCtx.fill();
                                const copy = document.createElement("canvas");
                                copy.width = LCYOriginalWidth;
                                copy.height = LCYOriginalHeight;
        
                                const copyCtx = copy.getContext("2d");
                                copyCtx.fillStyle = '#FFFFFF';
                                copyCtx.fillRect(0, 0, LCYOriginalWidth, LCYOriginalHeight);
                                copyCtx.globalCompositeOperation = "destination-in";
                                copyCtx.drawImage(giftIcon, stampStartX + 48, stampStartY - 74, 30, 30);
        
                                LCSourceCtx.drawImage(copy, 0, 0);
                            }
                        }
                    }
                }
                resolve()
            })
        }

        const drawCompLogo = async () => {
            let logo = await loadImage($scope.cardLogoImgURL)
            let width, height, startX, startY;
            if (logo.naturalWidth > logo.naturalHeight) {
                width = 150;
                height = width / (logo.naturalWidth / logo.naturalHeight)
                startX = 64;
                startY = ((150 - height) / 2) + 139
            } else {
                height = 150
                width = height * (logo.naturalWidth / logo.naturalHeight);
                startX = ((150 - width) / 2) + 64
                startY = 139;
            }
            LCSourceCtx.drawImage(logo, startX, startY, width, height);
        }

        const writePromotionName = async () => {
            LCSourceCtx.font = "38px Helvetica";
            LCSourceCtx.fillStyle = $scope.promotionNameTextColor;
            if (!$scope.LCPromotionNamePreview[$scope.languageSelector].secondLine) {
                if (!$scope.LCCompNamePromoPreview[$scope.languageSelector].secondLine) {
                    LCSourceCtx.fillText($scope.LCPromotionNamePreview[$scope.languageSelector].firstLine, 258, 225 + yAxisShiftForText);
                } else {
                    LCSourceCtx.fillText($scope.LCPromotionNamePreview[$scope.languageSelector].firstLine, 258, 247 + yAxisShiftForText);
                }
            } else {
                if (!$scope.LCCompNamePromoPreview[$scope.languageSelector].secondLine) {
                    LCSourceCtx.fillText($scope.LCPromotionNamePreview[$scope.languageSelector].firstLine, 258, 203 + yAxisShiftForText);
                    LCSourceCtx.fillText($scope.LCPromotionNamePreview[$scope.languageSelector].secondLine, 258, 248 + yAxisShiftForText);
                } else {
                    LCSourceCtx.fillText($scope.LCPromotionNamePreview[$scope.languageSelector].firstLine, 258, 225 + yAxisShiftForText);
                    LCSourceCtx.fillText($scope.LCPromotionNamePreview[$scope.languageSelector].secondLine, 258, 270 + yAxisShiftForText);
                }
            }
        }

        const writeCompName = async () => {
            LCSourceCtx.font = '42px Helvetica-bold';
            LCSourceCtx.fillStyle = $scope.compNamePromoTextColor;
            LCSourceCtx.textAlign = "left";
            if (!$scope.LCCompNamePromoPreview[$scope.languageSelector].secondLine) {
                if(!$scope.LCPromotionNamePreview[$scope.languageSelector].secondLine){
                    LCSourceCtx.fillText($scope.LCCompNamePromoPreview[$scope.languageSelector].firstLine, 258, 173 + yAxisShiftForText);
                } else {
                    LCSourceCtx.fillText($scope.LCCompNamePromoPreview[$scope.languageSelector].firstLine, 258, 150 + yAxisShiftForText);
                }
            } else {
                if (!$scope.LCCompNamePromoPreview[$scope.languageSelector].secondLine) {
                    LCSourceCtx.fillText($scope.LCCompNamePromoPreview[$scope.languageSelector].firstLine, 258, 150 + yAxisShiftForText);
                    LCSourceCtx.fillText($scope.LCCompNamePromoPreview[$scope.languageSelector].secondLine, 258, 195 + yAxisShiftForText);
                } else {
                    LCSourceCtx.fillText($scope.LCCompNamePromoPreview[$scope.languageSelector].firstLine, 258, 140 + yAxisShiftForText);
                    LCSourceCtx.fillText($scope.LCCompNamePromoPreview[$scope.languageSelector].secondLine, 258, 185 + yAxisShiftForText);
                }
            }
        }

        const writeStampNumbers = () => {
            LCSourceCtx.font = "38px Helvetica";
            LCSourceCtx.fillStyle = $scope.stampTextPlaceholderColor;
            LCSourceCtx.fillText($scope.languageSelector == 'HU' ? `pecsét` : 'stamp', $scope.languageSelector == 'HU' ? 910 : 917, 170 + yAxisShiftForText);
            const displayStampNrX = $scope.nrOfStamps > 9 ? 922 : 947
            LCSourceCtx.font = "50px Helvetica-bold";
            LCSourceCtx.fillStyle = $scope.stampCurrentCountTextColor;
            LCSourceCtx.fillText(`${$scope.floorNrOfStamps}/${$scope.nrOfStamps}`, displayStampNrX, 222 + yAxisShiftForText);
        }

        const copyToLCDestinationCanvas = () => {
            LCPreviewCtx.clearRect(0, 0, LCYScaledWidth, LCYScaledHeight);
            LCPreviewCtx.drawImage(LCSourceCanvas, 0, 0, LCYScaledWidth, LCYScaledHeight);
        }

        $scope.writeA5PromoName = (copyToResult, oldPromoNameAutoDistributionValue) => {
            const margin = 48
            const writablePart = A5OriginalWidth - 2 * margin
            let promoNameError
            $scope.checkFormValuesModified()
            if ($scope.models[$scope.languageSelector].promoNameAutoDistribution) {
                if (oldPromoNameAutoDistributionValue === false && $scope.models[$scope.languageSelector].A5PromotionName2ndLine) {
                    $scope.models[$scope.languageSelector].A5PromotionName = $scope.models[$scope.languageSelector].A5PromotionName + ' ' + $scope.models[$scope.languageSelector].A5PromotionName2ndLine
                    $scope.models[$scope.languageSelector].A5PromotionName2ndLine = null
                    $(`#${$scope.languageSelector}A5PromotionName`).val($scope.models[$scope.languageSelector].A5PromotionName).trigger("input");
                }
                $scope.A5PromotionNamePreview = splitTextToRowsForLCPromoImg($scope.models[$scope.languageSelector].A5PromotionName, writablePart, 98, 'Helvetica-bold', 2)
                if ($scope.A5PromotionNamePreview.err) {
                    $scope.marketingPackageForm[$scope.languageSelector].A5PromotionName.$setTouched();
                    $scope.marketingPackageForm[$scope.languageSelector].A5PromotionName.$setValidity('maxLengthComparedToMargin', false);
                    promoNameError = true
                } else {
                    $scope.marketingPackageForm[$scope.languageSelector].A5PromotionName.$setValidity('maxLengthComparedToMargin', true);
                    $scope.marketingPackageForm[$scope.languageSelector].A5PromotionName2ndLine.$setValidity('maxLengthComparedToMargin', true);
                }
            } else {
                $scope.A5PromotionNamePreview.firstLine = $scope.models[$scope.languageSelector].A5PromotionName
                $scope.A5PromotionNamePreview.secondLine = $scope.models[$scope.languageSelector].A5PromotionName2ndLine
                if (measureTextWidth($scope.A5PromotionNamePreview.firstLine, 98, 'Helvetica-bold') > writablePart) {
                    $scope.marketingPackageForm[$scope.languageSelector].A5PromotionName.$setTouched();
                    $scope.marketingPackageForm[$scope.languageSelector].A5PromotionName.$setValidity('maxLengthComparedToMargin', false);
                    promoNameError = true
                } else $scope.marketingPackageForm[$scope.languageSelector].A5PromotionName.$setValidity('maxLengthComparedToMargin', true);
                if (measureTextWidth($scope.A5PromotionNamePreview.secondLine, 98, 'Helvetica-bold') > writablePart) {
                    $scope.marketingPackageForm[$scope.languageSelector].A5PromotionName2ndLine.$setTouched();
                    $scope.marketingPackageForm[$scope.languageSelector].A5PromotionName2ndLine.$setValidity('maxLengthComparedToMargin', false);
                    promoNameError = true
                } else $scope.marketingPackageForm[$scope.languageSelector].A5PromotionName2ndLine.$setValidity('maxLengthComparedToMargin', true);
            }
            if (promoNameError) return
            A5SourceCtx.font = "98px Helvetica-bold";
            A5SourceCtx.fillStyle = "#00AEEF";
            A5SourceCtx.textAlign = "center";
            clearA5PromoName()
            if ($scope.A5PromotionNamePreview.firstLine) {
                if (!$scope.A5PromotionNamePreview.secondLine) {
                    A5SourceCtx.fillText($scope.A5PromotionNamePreview.firstLine.toUpperCase(), A5OriginalWidth / 2, 261 + yAxisShiftForText);
                } else {
                    A5SourceCtx.fillText($scope.A5PromotionNamePreview.firstLine.toUpperCase(), A5OriginalWidth / 2, 201 + yAxisShiftForText);
                    A5SourceCtx.fillText($scope.A5PromotionNamePreview.secondLine.toUpperCase(), A5OriginalWidth / 2, 329 + yAxisShiftForText);
                }
            }
            if (copyToResult) $scope.copyToA5ScaledCanvas()
        }

        const clearA5PromoName = () => {
            A5SourceCtx.clearRect(0, 50, A5OriginalWidth, 352);
            A5PreviewCtx.clearRect(0, 50, A5ScaledHeight, 352 / A5OriginalAndScaledRatio);
        }

        const generateQRCode = () => {
            return new Promise(resolve => {
                QRCode.toDataURL($scope.QRCode, {
                    width: 300,
                    margin: 1,
                    color: {
                        dark: "#FFFFFF",
                        light: "#00AEEF"
                    }
                }, function (err, url) {
                    resolve(url)
                })
            })
        }

        $scope.placeQRCode = async (copyToResult) => {
            let qrCodeURL = await generateQRCode()
            let qrCode = await loadImage(qrCodeURL)
            A5SourceCtx.drawImage(qrCode, 698, 1766, 399, 399);
            if (copyToResult) $scope.copyToA5ScaledCanvas()
        }

        $scope.copyToA5ScaledCanvas = () => {
            A5PreviewCtx.clearRect(0, 0, A5ScaledWidth, A5ScaledHeight);
            A5PreviewCtx.drawImage(A5SourceCanvas, 0, 0, A5ScaledWidth, A5ScaledHeight);
        }

        $scope.copyLCToA5DestinationCanvas = () => {
            const startX = 303, startY = $scope.nrOfStamps < 6 ? 663 : 512, width = 1189, height = $scope.nrOfStamps < 6 ? 642 : 793
            A5SourceCtx.clearRect(startX, startY, width, height)
            //A5SourceCtx.drawImage($scope.usingOneLineTemplate[$scope.languageSelector] ? oneLineLCBackground[$scope.languageSelector] : twoLineLCBackground[$scope.languageSelector], startX, startY, width, height);
            A5SourceCtx.drawImage(LCSourceCanvas, startX, startY, width, height);
            $scope.copyToA5ScaledCanvas()
            $scope.showLCPreview = true
        }

        const loadLCBackgrounds = async () => {
            /*oneLineLCBackground = {
                'HU': await loadImage('/templates/image/promo/social/YLC_marketing_template_FB_1_line_HU_v20200604.png'),
                'EN': await loadImage('/templates/image/promo/social/YLC_marketing_template_FB_1_line_HU_v20200604.png')
            }
            twoLineLCBackground = {
                'HU': await loadImage('/templates/image/promo/social/YLC_marketing_template_FB_2_line_HU_v20200604.png'),
                'EN': await loadImage('/templates/image/promo/social/YLC_marketing_template_FB_2_line_EN_v20200904.png')
            }*/
            A5Background = {
                'HU': await loadImage('/templates/image/promo/YLC_marketing_template_store_A5_HU_v20231126.png'),
                'EN': await loadImage('/templates/image/promo/YLC_marketing_template_store_A5_EN_v20231126.png')
            }
            hearth = await loadImage('/imgs/partner_registration_lovable_loyalty_program.png');
            font = new FontFace('Helvetica-bold', 'url(/fonts/Helvetica-Bold.ttf)');
            await font.load();
            document.fonts.add(font);
            document.body.classList.add('fonts-loaded');
        }



        // Draw template to canvas
        let LCSourceCanvas = document.getElementById("LCPromoOriginal");
        let LCPreviewCanvas = document.getElementById("LCPromoScaled");
        let LCCanvasToSend = document.getElementById("LCPromoToSend");
        let LCPreviewCanvasToSend = document.createElement("canvas");
        let A5SourceCanvas = document.getElementById("A5PromoOriginal");
        let A5PreviewCanvas = document.getElementById("A5PromoScaled");
        let A5CanvasToSend = document.getElementById("A5PromoToSend");
        let A5PreviewCanvasToSend = document.createElement("canvas");
        let LCSourceCtx = LCSourceCanvas.getContext("2d");
        let LCPreviewCtx = LCPreviewCanvas.getContext('2d');
        let LCCtxToSend = LCCanvasToSend.getContext('2d');
        let LCPreviewCtxToSend = LCPreviewCanvasToSend.getContext('2d');
        let A5SourceCtx = A5SourceCanvas.getContext("2d");
        let A5PreviewCtx = A5PreviewCanvas.getContext('2d');
        let A5CtxToSend = A5CanvasToSend.getContext('2d');
        let A5PreviewCtxToSend = A5PreviewCanvasToSend.getContext('2d');
        LCPreviewCanvasToSend.height = 288
        LCPreviewCanvasToSend.width = 432
        A5PreviewCanvasToSend.height = 632
        A5PreviewCanvasToSend.width = 449
        const yAxisShiftForText = 33
        const LCYOriginalAndScaledRatio = 2.5
        const A5OriginalAndScaledRatio = 4
        let LCYOriginalHeight = 720
        let LCYOriginalWidth = 1080
        let LCYScaledHeight = LCYOriginalHeight / LCYOriginalAndScaledRatio
        let LCYScaledWidth = LCYOriginalWidth / LCYOriginalAndScaledRatio
        const A5OriginalHeight = 2528
        const A5OriginalWidth = 1795
        const A5ScaledHeight = A5OriginalHeight / A5OriginalAndScaledRatio
        const A5ScaledWidth = A5OriginalWidth / A5OriginalAndScaledRatio
        let A5Background, /*oneLineLCBackground, twoLineLCBackground,*/ font, hearth
        loadLCBackgrounds()

        $scope.generateMarketingPackage = () => {
            if ($scope.marketingPackageForm.languageSelector.$invalid) {
                const element = document.getElementById(`languageSelectorContainer`);
                element.scrollIntoView();
                return
            } else if ($scope.models.showStampImgInsteadOfStampBase && !$scope.selectedTemplateImg) {
                $scope.marketingPackageForm.templateImgs.$setTouched();
                $scope.marketingPackageForm.templateImgs.$setValidity('templateImgRequired', false);
                const element = document.getElementById(`fb-section`);
                element.scrollIntoView();
                return
            } else if ($scope.marketingPackageForm[$scope.languageSelector].promoFreeText.$invalid) {
                $scope.marketingPackageForm[$scope.languageSelector].promoFreeText.$setTouched();
                const element = document.getElementById(`fb-section`);
                element.scrollIntoView();
                return
            } else if ($scope.marketingPackageForm[$scope.languageSelector].A5PromotionName.$invalid) {
                $scope.marketingPackageForm[$scope.languageSelector].A5PromotionName.$setTouched();
                const element = document.getElementById(`${$scope.languageSelector}_A5MarketingPackageSettings`);
                element.scrollIntoView();
                return
            } else if ($scope.marketingPackageForm[$scope.languageSelector].A5PromotionName2ndLine.$invalid) {
                $scope.marketingPackageForm[$scope.languageSelector].A5PromotionName2ndLine.$setTouched();
                const element = document.getElementById(`${$scope.languageSelector}_A5MarketingPackageSettings`);
                element.scrollIntoView();
                return
            }
            /*if ($scope.marketingPackageForm[$scope.languageSelector].$invalid) {
                let isScrolled = false
                angular.forEach($scope.marketingPackageForm[$scope.languageSelector].$error, function (field) {
                    angular.forEach(field, function (errorField) {
                        errorField.$setTouched();
                        if (!isScrolled) {
                            var element = document.getElementById(errorField.$$attr.id);
                            element.scrollIntoView()
                            //document.getElementById(errorField.$name).scrollTop -= 100;
                            //$('html, body').animate({ scrollTop: $(`#${errorField.$name}`).offset().top - 100 }, 'slow');
                            //$([document.documentElement, document.body]).animate({
                            //    scrollTop: $(`#${errorField.$name}`).offset().top - 100
                            //}, 2000);
                            //window.scrollTo(0, $(`#${errorField.$name}`).offset().top - 100);
                            //const yOffset = -100;
                            //const element = document.getElementById(errorField.$name);
                            //const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                            //window.scrollTo({ top: y, behavior: 'smooth' });
                            isScrolled = true
                        }
                    });
                });
                return
            }*/
            $scope.generationButtonDisabled = true
            A5CtxToSend.clearRect(0, 0, A5OriginalWidth, A5OriginalHeight)
            A5CtxToSend.drawImage(A5Background[$scope.languageSelector], 0, 0, A5OriginalWidth, A5OriginalHeight);
            A5CtxToSend.drawImage(A5SourceCanvas, 0, 0, A5OriginalWidth, A5OriginalHeight);
            LCCtxToSend.clearRect(0, 0, LCYOriginalWidth, LCYOriginalHeight)
            LCCtxToSend.fillStyle = "white";
            LCCtxToSend.fillRect(0, 0, LCYOriginalWidth, LCYOriginalHeight);
            //LCCtxToSend.drawImage($scope.usingOneLineTemplate[$scope.languageSelector] ? oneLineLCBackground[$scope.languageSelector] : twoLineLCBackground[$scope.languageSelector], 0, 0, LCYOriginalWidth, LCYOriginalHeight);
            LCCtxToSend.drawImage(LCSourceCanvas, 0, 0, LCYOriginalWidth, LCYOriginalHeight);
            A5PreviewCtxToSend.clearRect(0, 0, A5ScaledWidth, A5ScaledHeight)
            A5PreviewCtxToSend.fillStyle = "white";
            A5PreviewCtxToSend.fillRect(0, 0, A5ScaledWidth, A5ScaledHeight);
            A5PreviewCtxToSend.drawImage(A5Background[$scope.languageSelector], 0, 0, A5ScaledWidth, A5ScaledHeight);
            A5PreviewCtxToSend.drawImage(A5PreviewCanvas, 0, 0, A5ScaledWidth, A5ScaledHeight);
            LCPreviewCtxToSend.clearRect(0, 0, LCYScaledWidth, LCYScaledHeight)
            LCPreviewCtxToSend.fillStyle = "white";
            LCPreviewCtxToSend.fillRect(0, 0, LCYScaledWidth, LCYScaledHeight);
            //LCPreviewCtxToSend.drawImage($scope.usingOneLineTemplate[$scope.languageSelector] ? oneLineLCBackground[$scope.languageSelector] : twoLineLCBackground[$scope.languageSelector], 0, 0, LCYScaledWidth, LCYScaledHeight);
            LCPreviewCtxToSend.drawImage(LCPreviewCanvas, 0, 0, LCYScaledWidth, LCYScaledHeight);
            let marketingPackageDataToSend = {
                loyaltyCardId: $scope.loyaltyCardId,
                cardLogoImgURL: $scope.cardLogoImgURL,
                compNamePromo: $scope.compNamePromo[$scope.languageSelector],
                promotionName: $scope.promotionName[$scope.languageSelector],
                showBaseStampDesign: $scope.models.showStampImgInsteadOfStampBase ?
                    false : $scope.showBaseStampDesign,
                baseStampOverride: $scope.models.showStampImgInsteadOfStampBase,
                baseStampOverrideStampIconURL: $scope.models.showStampImgInsteadOfStampBase ?
                    $scope.selectedTemplateImg.stampIconURLPrefix : null,
                stampIconURL: $scope.stampIconURLPrefix,
                A5PromoImg: A5CanvasToSend.toDataURL('image/png'),
                A5LowResPromoImg: A5PreviewCanvasToSend.toDataURL('image/jpeg', 0.9),
                LCPromoImg: LCCanvasToSend.toDataURL('image/png'),
                LCLowResPromoImg: LCPreviewCanvasToSend.toDataURL('image/jpeg', 0.9),
                A5PromotionNameAutoFit: $scope.models[$scope.languageSelector].promoNameAutoDistribution,
                A5PromotionName: $scope.models[$scope.languageSelector].A5PromotionName.toUpperCase(),
                A5PromotionName2ndLine: $scope.models[$scope.languageSelector].promoNameAutoDistribution ?
                    null : $scope.models[$scope.languageSelector].A5PromotionName2ndLine.toUpperCase(),
                QRCodeURL: $scope.QRCode,
                nrOfStamps: $scope.nrOfStamps,
                selectedLanguage: $scope.languageSelector,
                LCYPromoFreeText: $scope.models[$scope.languageSelector].promoFreeText,
                cardBackgroundColor: $scope.cardBackgroundColor,
                compNamePromoTextColor: $scope.compNamePromoTextColor,
                promotionNameTextColor: $scope.promotionNameTextColor,
                stampTextPlaceholderColor: $scope.stampTextPlaceholderColor,
                stampCurrentCountTextColor: $scope.stampCurrentCountTextColor,
                baseStampUnstampedFillColor: $scope.baseStampUnstampedFillColor,
                baseStampUnstampedLineWeight: $scope.baseStampUnstampedLineWeight,
                baseStampUnstampedLineColor: $scope.baseStampUnstampedLineColor,
                baseStampStampedFillColor: $scope.baseStampStampedFillColor,
                baseStampStampedLineWeight: $scope.baseStampStampedLineWeight,
                baseStampStampedLineColor: $scope.baseStampStampedLineColor,
                stampIconUnstampedColor: $scope.stampIconUnstampedColor,
                stampIconStampedColor: $scope.stampIconStampedColor,
                stampIconUnstampedSizing: $scope.stampIconUnstampedSizing,
                stampIconStampedSizing: $scope.stampIconStampedSizing
            }
            $http.post(API_PREFIX.url + '/partner/generatePartnerMarketingPackageLCY',
                marketingPackageDataToSend).then(function (result) {
                    $scope.generationButtonDisabled = false
                    if (result.data.errorCode === 0) {
                        $scope.LCHasMarketingPackage[$scope.languageSelector] = true
                        $scope.LCHasMarketingPackage['ANY'] = true
                        const alert = $mdDialog.alert()
                            .title('A promó anyag generálása sikeres volt.')
                            .ok('Rendben')
                        $mdDialog.show(alert).then(() => {
                            $scope.A5ImgDownloadURL[marketingPackageDataToSend.selectedLanguage] = result.data.A5ImgURL
                            $scope.LCYImgDownloadURL[marketingPackageDataToSend.selectedLanguage] = result.data.LCYImgURL
                            $rootScope.isDataSheetModified = false
                            $scope.isLCDataUpdateButtonPressed[$scope.languageSelector] = false
                            $scope.initValues.models = angular.copy($scope.models)
                        })
                        GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/marketing_csomagok/marketing_csomag_legenerálása_sikeres",
                            "HU Partner [PRIVÁT] - Hűségkártyák marketing csomagok oldal - Marketing csomag legenerálása sikeres");
                    } else {
                        const alert = $mdDialog.alert()
                            .title('Adatbázis művelet során váratlan hiba lépett fel, kérjük ismételje meg a műveletet.')
                            .ok('Rendben')
                        $mdDialog.show(alert)
                        GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/marketing_csomagok/marketing_csomag_legenerálása_sikertelen",
                            "HU Partner [PRIVÁT] - Hűségkártyák marketing csomagok oldal - Marketing csomag legenerálása sikertelen");
                    }
                })

        }

        $scope.setVisibility = () => {
            $scope.setVisibilityButtonDisabled = true
            const visibilitySetting = {
                loyaltyCardId: $scope.loyaltyCardId,
                setMarketingPackageVisible: !$scope.visibility
            }
            $http.post(API_PREFIX.url + '/partner/setPartnerMarketingPackageLCYVisibility',
                visibilitySetting).then(function (result) {
                    $scope.setVisibilityButtonDisabled = false
                    if (result.data.errorCode === 0) {
                        $scope.visibility = !$scope.visibility
                        GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/marketing_csomagok/marketing_csomag_partneri_láthatóság_beállítása_sikeres",
                            "HU Partner [PRIVÁT] - Hűségkártyák marketing csomagok oldal - Marketing csomag partneri láthatóság beállítása sikeres");
                    } else if (result.data.errorCode === 3) {
                        const alert = $mdDialog.alert()
                            .title('Nem tartozik a hűségkártyához generált marketing csomag. ' +
                                'A láthatóság beállításának feltétele, hogy legalább az egyik nyelvhez legyen már generált marketing csomag.')
                            .ok('Rendben')
                        $mdDialog.show(alert)
                    } else {
                        const alert = $mdDialog.alert()
                            .title('Adatbázis művelet során váratlan hiba lépett fel, kérjük ismételje meg a műveletet.')
                            .ok('Rendben')
                        $mdDialog.show(alert)
                    }
                    if (result.data.errorCode !== 0) {
                        GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/marketing_csomagok/marketing_csomag_partneri_láthatóság_beállítása_sikertelen",
                            "HU Partner [PRIVÁT] - Hűségkártyák marketing csomagok oldal - Marketing csomag partneri láthatóság beállítása sikertelen");
                    }
                })
        }

        $scope.sendEmail = () => {
            $scope.emailButtonDisabled = true
            const lcData = {
                loyaltyCardYaltyId: $scope.loyaltyCardId,
                companyId: $scope.companyId,
                compNamePromo: $scope.initValues.loyaltyCard['HU'].compNamePromo,
                promotionName: $scope.initValues.loyaltyCard['HU'].promotionName
            }
            $http.post(API_PREFIX.url + '/partner/sendPartnerMarketingPackageLCYPartnerNotificationEmail',
                lcData).then(function (result) {
                    $scope.emailButtonDisabled = false
                    if (result.data.errorCode === 0) {
                        $scope.emailSendings = result.data.notificationEmailSentInfoChain.split('#')
                        const alert = $mdDialog.alert()
                            .title('A marketing csomag értesítő emailt sikeresen kiküldtük a partneri mester fiókra.')
                            .ok('OK')
                        $mdDialog.show(alert)
                        GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/marketing_csomagok/marketing_csomag_email_értesítés_kiküldése_sikeres",
                            "HU Partner [PRIVÁT] - Hűségkártyák marketing csomagok oldal - Marketing csomagról partneri email értesítés kiküldése sikeres");
                    } else {
                        const alert = $mdDialog.alert()
                            .title('Adatbázis művelet során váratlan hiba lépett fel, kérjük ismételje meg a műveletet.')
                            .ok('Rendben')
                        $mdDialog.show(alert)
                        GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/marketing_csomagok/marketing_csomag_email_értesítés_kiküldése_sikertelen",
                            "HU Partner [PRIVÁT] - Hűségkártyák marketing csomagok oldal - Marketing csomagról partneri email értesítés kiküldése sikertelen");
                    }
                })
        }

        $scope.setShowBaseStampDesign = async () => {
            $scope.showBaseStampDesign = !$scope.models.showStampImgInsteadOfStampBase
            $scope.preview.stampIconURLPrefix = $scope.selectedTemplateImg ? $scope.selectedTemplateImg.stampIconURLPrefix : null
            $scope.setUpdatedLCData(false);
            $scope.checkFormValuesModified()
        }

        const setDefaultStampDesign = () => {
            $scope.baseStampUnstampedFillColor = "#FFFFFF";
            $scope.baseStampUnstampedLineWeight = 1;
            $scope.baseStampUnstampedLineColor = "#e5e8eb";
            $scope.baseStampStampedFillColor = "#00AEEF";
            $scope.baseStampStampedLineWeight = 1;
            $scope.baseStampStampedLineColor = "#e5e8eb";

            $scope.unstampedStyle = {
                //"background-color": $scope.baseStampUnstampedFillColor,
                "border": "solid " + $scope.baseStampUnstampedLineWeight + "px",
                "border-color": $scope.baseStampUnstampedLineColor,
                "padding": (5 - $scope.baseStampUnstampedLineWeight) + "px",
                "position": "relative"
            };
            $scope.stampedStyle = {
                //"background-color": $scope.baseStampStampedFillColor,
                "border": "solid " + $scope.baseStampStampedLineWeight + "px",
                "border-color": $scope.baseStampStampedLineColor,
                "padding": (5 - $scope.baseStampStampedLineWeight) + "px",
                "position": "relative"
            };
            $scope.sizedPreviewStampStyle = {
                "width": "30px",
                "height": "30px",
                "margin-top": "0px",
                "margin-left": "0px"
            };
            $scope.sizedPreviewUnstampStyle = {
                "width": "30px",
                "height": "30px",
                "margin-top": "0px",
                "margin-left": "0px"
            }
        }

        setDefaultStampDesign()

        if ($stateParams.loyaltyCardId && $stateParams.loyaltyCardId != 0) {
            const status = $scope.$parent.allLoyaltyCard.find(card => card.id = $stateParams.loyaltyCardId).loyaltyCardPromoStatus
            $scope.openExistingLC({ id: $stateParams.loyaltyCardId, status: status });
        }
    });