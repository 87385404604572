// @ts-nocheck

function couponDetailViewController() {
    var ctrl = this;
    ctrl.$onChanges = function (changes) {
        if (changes.detailViewImgURL) {
            if (ctrl.detailViewImgURL) {
                ctrl.backgroundStyle = { 'background-image': `url(${ctrl.detailViewImgURL})` }
            } else {
                ctrl.backgroundStyle = {
                    'background-image': `url('/imgs/yalty_logo_white.svg')`,
                    'background-color': '#E5E8EB',
                    'background-size': '70px'
                }
            }
        }
    }
}

angular.module('yaltyApp')
    .component('couponDetailView', {
        templateUrl: 'js/components/couponDetailView.html?v=202405171545',
        controller: couponDetailViewController,
        bindings: {
            detailViewImgURL: '<',
            companyLogoURL: '<',
            compNamePromo: '<',
            promotionName: '<',
            promotionDetailedDescr: '<',
            promotionConditionsDescr: '<',
            promotionType: '<',
            redeemEndDate: '<',
            nrOfCompanyStores: '<',
            oldDiscount: '<',
            discountAmount: '<',
            discountOldPrice: '<',
            discountText: '<',
            discountText2: '<',
            redeemOveralLimitation: '<',
            redeemOveralAmount: '<',
            redeemPerUserLimitation: '<',
            redeemPerUserAmount: '<',
            discountTextStyle: '<',
            discountText2Style: '<',
            discountTextAndLabelStyle: '<'
        }
    });