<mat-form-field [formGroup]="form" class="datetime-picker">
    <mat-label>{{label}}</mat-label>
    <input matInput [ngxMatDatetimePicker]="picker" [placeholder]="placeholder" [formControlName]="inputName"
        [min]="minDate" [max]="maxDate" [disabled]="disabled" autocomplete="off">
    <button mat-icon-button matSuffix (click)="picker.open(); $event.stopPropagation();" class="mat-datepicker-toggle">
        <mat-icon>today</mat-icon>
    </button>
    <!-- <mat-datepicker-toggle matSuffix [for]="picker" class="mat-datepicker-toggle"></mat-datepicker-toggle> -->
    <ngx-mat-datetime-picker #picker [showSpinners]="showSpinners" [showSeconds]="showSeconds" [stepHour]="stepHour"
        [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi" color="primary"
        [enableMeridian]="enableMeridian">
    </ngx-mat-datetime-picker>
    <mat-error *ngIf="input.hasError('required')">Mező kitöltése kötelező!</mat-error>
    <mat-error *ngIf="input.hasError('minDate')">{{minDateErrorText}}</mat-error>
</mat-form-field>