// @ts-nocheck

import { downgradeComponent } from '@angular/upgrade/static';
import { PushNotificationComponent } from 'src/app/pages/push-notification/push-notification.component';
import { SendingPartnerEmailComponent } from 'src/app/pages/sending-partner-email/sending-partner-email.component';

angular.module('yaltyApp')
    .directive('pushNotification', downgradeComponent({ component: PushNotificationComponent }) as angular.IDirectiveFactory)
    .directive('sendingPartnerEmail', downgradeComponent({ component: SendingPartnerEmailComponent }) as angular.IDirectiveFactory)
    .service('YaltyAuthService', function ($q, $http, API_PREFIX) {
        this.isYaltyAdminUserAuthorized = function (state) {
            return $q(function (resolve, reject) {
                $http.post(API_PREFIX.url + '/yaltyadmin/isYaltyAdminUserAuthorized', state).then(function (result) {
                    resolve(result.data);
                }, function (result) {
                    reject(result.data);
                });
            });
        };
    })
    .controller('AdminLoginController', function ($scope, $http, $state, $cookies, $stateParams, API_PREFIX, commonService) {
        commonService.setFloatingLabelInCaseOfAutoFill();
        $scope.sendButtonDisabled = false;
        $scope.errorText = $stateParams.errorText;
        $scope.user = {
            userName: '',
            password: ''
        };

        $scope.typePassword = true;
        $scope.togglePassword = function () {
            $scope.typePassword = !$scope.typePassword;
         };

        $scope.login = function () {
            $scope.sendButtonDisabled = true;
            $http.post(API_PREFIX.url + '/yaltyadmin/loginYaltyAdminUser', $scope.user).then(function (result) {
                if (result.data.errorCode == 0) {
                    $cookies.put('adminUserName', result.data.adminData.adminName);
                    $scope.adminUserName = $cookies.get('adminUserName');
                    $cookies.put('adminUserEmail', result.data.adminData.adminEmail);
                    $scope.adminUserEmail = $cookies.get('adminUserEmail');
                    $cookies.put('adminUserType', result.data.adminData.adminUserType);
                    $scope.adminUserType = $cookies.get('adminUserType');
                    $cookies.put('adminUserId', result.data.adminData.adminId);
                    $cookies.put('adminPhoneNumber', result.data.adminData.adminPhoneNumber);
                    $state.go('adminPages');
                } else {
                    $scope.sendButtonDisabled = false;
                    $scope.errorText = result.data.msg;
                }
            }, function () { });
        };
    })
    .controller('AdminPageController', function ($scope, $state, $cookies, $rootScope, YaltyAuthService, AuthService, $location) {
        var mySidebar = document.getElementById("mySidebar");
        //var overlayBg = document.getElementById("myOverlay");
        var openMenu = document.getElementById("openMenuYaltyAdmin");

        var lastTime = (new Date()).getTime();

        document.addEventListener("mousemove", function(event) {
            if(window.innerWidth <= 1400) {
                let leftSideOpen = 5;
                let leftSideClose = 205;
                
                if (event.clientX <= leftSideOpen) {
                    $scope.yalty_open(true);
                } else if(event.clientX >= leftSideClose) {
                    $scope.yalty_close();
                }
            }
        });

        $scope.innerWidth = window.innerWidth;

		function reportWindowSize() {
			if(window.innerWidth > 1400) {
                $scope.yalty_open(true);
            }
		}

		window.addEventListener("resize", reportWindowSize);

        setInterval(function () {
            var currentTime = (new Date()).getTime();
            if (currentTime > (lastTime + 2000 * 2)) {  // ignore small delays
                if (currentTime > $rootScope.endDate) {
                    setTimeout(function () {
                        $state.go('adminLogin', { 'errorText': 'Lejárt az időkorlát.' });
                    }, 2500);
                }
            }
            lastTime = currentTime;
            if($cookies.get('expdt') != null){
                $rootScope.endDate = parseInt($cookies.get('expdt'));
            }
            if($cookies.get('alrtdt') != null){
                $rootScope.deadlineInMilli = parseInt($cookies.get('alrtdt'));
            }
        }, 2000);
        const host = $location.host()
        $scope.isAccessResetShowm = host == 'localhost' ||
            host == 'yalty-appserv-dev-development-002.azurewebsites.net' ||
            host == 'yalty-appserv-tst-test-master-a.azurewebsites.net'

        $scope.isPreregistrations = function() {
            return $location.absUrl().includes('/preregistrations/');
        }

        $scope.isPreregModification = function() {
            return $location.absUrl().includes('/preregisterPartner?preregId=');
        }

        $scope.adminUserName = $cookies.get('adminUserName');
        $scope.adminUserEmail = $cookies.get('adminUserEmail');
        $scope.adminUserType = $cookies.get('adminUserType');
        $scope.adminPhoneNumber = $cookies.get('adminPhoneNumber');


        $scope.logout = function () {
            $rootScope.isDataSheetModified = false;
            $cookies.remove('adminUserName');
            $cookies.remove('adminUserEmail');
            $cookies.remove('adminUserType');
            $cookies.remove('adminPhoneNumber')
            $cookies.remove('adminUserId');
            $cookies.remove('yaltyCity');
            $cookies.remove('expdt');
            $cookies.remove('alrtdt');
            $rootScope.adminUserType = null;
            $state.go('adminLogin', { 'errorText': 'Sikeres kijelentkezés' });
        };

        $scope.refreshAdminToken = function () {
            var currentState = $state.current.name;
            var payload = {};
            payload.state = currentState;
            payload.fromState = currentState;
            YaltyAuthService.isYaltyAdminUserAuthorized(payload).then(function (result) {
                if (result.authorized === true && result.errorCode === 0) {
                    AuthService.updateSessionData(result)
                    $rootScope.endDate = result.endDate;
                    $rootScope.deadlineInMilli = result.alertDate;
                    $rootScope.isTimerSet = true;
                    $rootScope.$broadcast('timer-clear');
                    $rootScope.$broadcast('timer-start');
                    $rootScope.timerRunning = true;
                }
            })
        }

        $scope.yalty_open = function (isAutomatic) {
            if (mySidebar.style.display === 'block' && isAutomatic == false) {
                mySidebar.style.display = 'none';
                //overlayBg.style.display = "none";
                openMenu.style.display = "block";
            } else {
                mySidebar.style.display = 'block';
                //overlayBg.style.display = "block";
                openMenu.style.display = "none";
            }
        }
        $scope.yalty_close = function () {
            mySidebar.style.display = "none";
            //overlayBg.style.display = "none";
            openMenu.style.display = "block";
        }
    })

    .controller('AdminPartnerPagesController', function ($scope, $rootScope, $cookies, $window, $http, API_PREFIX, $mdDialog) {
        $scope.selectedPartner = false;

        $scope.subPackages = [
            {
                id: 0,
                name: "Előfizetett csomag"
            },
            {
                id: 1,
                name: "Premium csomag"
            }
        ]

        $scope.adminAuthorizationLevel = $cookies.get('adminUserType');

        $scope.clearFilters = function () {
            $scope.compName = undefined;
            $scope.taxNum = undefined;
            $scope.companyId = undefined;
            $scope.compLogoImgURL = undefined;
            $scope.compNamePromo = undefined;
            $scope.selectedPartner = false;
            $scope.selectedIndex = null;
        }

        $scope.setInputFields = function (compName, taxNum, companyId, compLogoImgURL, compNamePromo, packageDisplayName) {
            $scope.compName = compName;
            $scope.taxNum = taxNum;
            $scope.companyId = companyId;
            $scope.compLogoImgURL = compLogoImgURL;
            $scope.compNamePromo = compNamePromo;
            $scope.subPackages = [
                {
                    id: 0,
                    name: "Előfizetett csomag (" + (packageDisplayName != null ? packageDisplayName : "nincs aktív") + ")"
                },
                {
                    id: 1,
                    name: "Premium csomag"
                }
            ]
            $scope.subscriptionPackage = 0;
            $scope.isSubscriptionPackageDisabled = packageDisplayName == 'Premium'
            $scope.selectedPartner = true;
            $scope.selectedIndex = companyId;
        }

        $scope.openPartnerNewTab = function () {
            $scope.sendButtonDisabled = true;
            if ($scope.selectedPartner == true) {
                var company = {};
                company.partnerId = $scope.companyId;
                company.subscriptionPackage = $scope.subscriptionPackage;
                $http.post(API_PREFIX.url + '/yaltyadmin/getYaltyAdminUserToken', company).then(function (result) {
                    if (result.data.errorCode == 0) {
                        /*
                            Ez a törlés azért kell ide a partner megnyitása elé, mert ha megnyitjuk a
                            Reg. link landing oldal előnézetet az előregisztrációk listájából korábban,
                            akkor az alábbi cookie-k töltve maradnak, és ezek gondot okozhatnak
                            partner megnyitása után.
                            Példa: megnyitunk egy olyan partnert, akinek ingyenes előfizetése van úgy,
                            hogy közben töltve vannak az alábbi cookie-k, akkor az előfizetések oldalon
                            az előfizetések táblában nem jelenik meg az ingyenes csomag (saját csomagja). 
                        */
                        $cookies.remove('freePackageSubscriptionExpLimited')
                        $cookies.remove('freePackageSubscriptionExpTimestamp')
                        $cookies.remove('isPartnerRegistered')
                        var newTab = $window.open("", "_blank");
                        $scope.sendButtonDisabled = false;
                        $rootScope.isDataSheetModified = false;
                        $cookies.put('authorizationLevel', result.data.authorizationLevel);
                        $scope.authorizationLevel = $cookies.get('authorizationLevel');
                        newTab.location = result.data.hasPartnerActiveSub ? "/partner/home" : "/partner/home/partner-subscription"
                        $cookies.put('userCompany', $scope.compNamePromo);
                        $cookies.put('compLogo', $scope.compLogoImgURL);
                        $cookies.put('userName', "YALTY ADMIN");
                        $cookies.put('sbcrtyp', result.data.partnerData.subData + "&" + result.data.hasPartnerActiveSub + "&" + result.data.partnerData.subscriptionEndTimestamp);
                        $cookies.put('additionalCompanyStoreNumber', result.data.partnerData.additionalCompanyStoreNumber);
                        $cookies.put('additionalPartnerUserNumber', result.data.partnerData.additionalPartnerUserNumber);
                        $cookies.put('additionalFreePartnerUserNumber', result.data.partnerData.additionalFreePartnerUserNumber);
                        $cookies.put('limitMonthlyPushMessageMaxNo', result.data.partnerData.limitMonthlyPushMessageMaxNo);
                        $cookies.put('limitMonthlyIndividualPushMessageMaxNo', result.data.partnerData.limitMonthlyIndividualPushMessageMaxNo);
                        $cookies.put('paymentFrequency', result.data.partnerData.paymentFrequency)
                        $cookies.put('isPaymentForTheNextPeriodRequired', result.data.partnerData.isPaymentForTheNextPeriodRequired)
                        $cookies.put('areCustomDisplaySettingsForRedeemHandlingSheetEnabled', result.data.partnerData.areCustomDisplaySettingsForRedeemHandlingSheetEnabled);
                        $cookies.put('canSendIndividualPushMessages', result.data.partnerData.canSendIndividualPushMessages);
                        $cookies.put('isYaltyStartEnabled', result.data.partnerData.isYaltyStartPaidPackageEnabled);
                        $rootScope.partnerSubscriptionType = $cookies.get('sbcrtyp');
                        $rootScope.hasPartnerActiveSub = result.data.hasPartnerActiveSub;
                        $window.localStorage['compNamePromo'] = $scope.compNamePromo;
                        $window.localStorage['compLogoImgURL'] = $scope.compLogoImgURL;
                        $window.localStorage['companyId'] = $scope.companyId;
                    } else if (result.data.errorCode == 3) {
                        var alert = $mdDialog.alert()
                            .title('A partner oldalai még nem nyithatók meg!')
                            .textContent('Ehhez a partnerhez még nem tartozik előfizetés. A partner már regisztrált, de még sosem választott előfizetést.')
                            .ok('Rendben')
                        $mdDialog.show(alert).then(function () {
                            $scope.sendButtonDisabled = false;
                        });
                    } else {
                        $scope.sendButtonDisabled = false;
                    }
                });
            } else {
                var alert = $mdDialog.alert()
                    .title('Partner kiválasztása sikertelen')
                    .textContent('Kérjük válassz egy partnert!')
                    .ariaLabel('Session expired.')
                    .ok('Rendben')
                $mdDialog.show(alert).then(function () {
                    $scope.sendButtonDisabled = false;
                });
            }
        }
    })

    .controller('AdminUsersController', function ($scope, $state, $http, API_PREFIX, $mdDialog) {

        $scope.typePassword = true;
        $scope.togglePassword = function () {
            $scope.typePassword = !$scope.typePassword;
         };

        $scope.registerAdminUser = function () {
            $scope.sendButtonDisabled = true;
            var user = {};
            user.userName = $scope.userNameMail;
            user.password = $scope.password;
            user.authorizationLevel = "admin";
            user.nameTitle = $scope.nameTitle;
            user.firstName = $scope.firstName;
            user.lastName = $scope.lastName;
            user.contactPhoneNumber = $scope.contactPhoneNumber;

            $http.post(API_PREFIX.url + '/yaltyadmin/registerAdminUser', user).then(function (result) {
                if (result.data.errorCode == 0) {
                    const alert = $mdDialog.alert()
                        .textContent('Az admin létrehozása sikeresen megtörtént.')
                        .ariaLabel('Az admin létrehozása sikeresen megtörtént.')
                        .ok('Rendben')
                    $mdDialog.show(alert).then(function () {
                        $scope.sendButtonDisabled = false;
                        $state.reload();
                    });
                } else if (result.data.errorCode == 1) {
                    const alert = $mdDialog.alert()
                        .textContent('Sikertelen adatbázis művelet miatt a folyamat megszakadt, kérjük ismételd meg a műveletet.')
                        .ok('Rendben')
                    $mdDialog.show(alert).then(function () {
                        $scope.sendButtonDisabled = false;
                    });
                } else if (result.data.errorCode == 2 || result.data.errorCode == 3) {
                    const alert = $mdDialog.alert()
                        .textContent('Az adatok feldolgozása során váratlan hiba lépett fel, kérjük ismételd meg a műveletet.')
                        .ok('Rendben')
                    $mdDialog.show(alert).then(function () {
                        $scope.sendButtonDisabled = false;
                    });
                } else if (result.data.errorCode == 4) {
                    const alert = $mdDialog.alert()
                        .textContent('Felhasználó azonosítása sikertelen, ezért kijelentkeztetjük az oldalról.')
                        .ok('Rendben')
                    $mdDialog.show(alert).then(function () {
                        $scope.sendButtonDisabled = false;
                        $state.go('adminLogin', {}, { reload: true });
                    });
                } else if (result.data.errorCode == 5) {
                    const alert = $mdDialog.alert()
                        .textContent('A létrehozáshoz nincs jogosultsága.')
                        .ok('Rendben')
                    $mdDialog.show(alert).then(function () {
                        $scope.sendButtonDisabled = false;
                        $state.go('adminPages', {}, { reload: true });
                    });
                }
            });
        }
    })

    .controller('NewPreregistrationController', function ($scope, $rootScope, $state, $stateParams, $http, API_PREFIX, $document, $mdDialog, $window, $cookies, $location, $anchorScroll, commonService) {
        $scope.preregistrationId = $stateParams.preregId;
        $scope.$parent.partnerSubscriptionType = "custom";
        $scope.splitTextToRowsError = false;
        //var potentialCompanyMainId = $cookies.get('potentialCompanyMainId');
        var potentialCompanyMainId = $stateParams.mainId;
        var parentScope = $scope.$parent;
        parentScope.child = $scope;
        $scope.getChoiceIdFromStampIconType = [0, 2, 3, 1]
        $scope.getStampIconTypeFromChoiceId = [0, 3, 1, 2]
        $scope.authorizationLevel = $cookies.get('adminUserType');
        if($scope.authorizationLevel != 'executive' && $scope.preregistrationId == null && potentialCompanyMainId == null){
            $state.go("adminPages.preregistrations", { preregistrationId: 0 }, { reload: true })
        }


        $scope.setCanvas = function() {
            if($scope.LCYIsDemoActive == true) {
                $scope.LCYIsPromotionNameBasedOnCompProfile = $scope.compProfile != null && $scope.compProfile.compProfile == 'Egyéb' ? false : true;
                $scope.stampIconType = $scope.compProfile != null && $scope.compProfile.compProfile == 'Egyéb' ? 1 : 3;
                $scope.LCYPromotionName = $scope.compProfile != null ? $scope.compProfile.LCYPromotionName : null;
                $scope.LCYCardBackgroundColor = '#FFFFFF';
                $scope.LCYCompNamePromoTextColor = '#4E525C';
                $scope.LCYPromotionNameTextColor = '#000000'
                $scope.LCYStampTextPlaceholderColor = '#4E525C';
                $scope.LCYStampCurrentCountTextColor = '#000000';
                $scope.LCYDetailedDisplayBackgroundColor = '#000000';
                $scope.LCYBackButtonColor = '#FFFFFF';
                $scope.LCYNrOfStamps = 9;
                $scope.setSumNrOfStampsNeeded();
                $scope.LCYShowBaseStampDesign = false;
                $scope.LCYBaseStampUnstampedFillColor = '#FFFFFF';
                $scope.LCYBaseStampUnstampedLineWeight = 0;
                $scope.LCYBaseStampUnstampedLineColor = '#E5E8EB';
                $scope.LCYBaseStampStampedFillColor = '#DFDFDF';
                $scope.LCYBaseStampStampedLineWeight = 0;
                $scope.LCYBaseStampStampedLineColor = '#E5E8EB';
                $scope.LCYShowStampIcon = true;
                $scope.stampedStyle = {
                    "background-color": "initial",
                    "border": "initial",
                    "border-color": "initial",
                    "padding": "5px",
                    "position": "relative"
                };
                $scope.sizedPreviewStampStyle = {
                    "width": "30px",
                    "height": "30px",
                    "margin-top": "0px",
                    "margin-left": "0px"
                };
                $scope.previewStampedStyle = {
                    "background-color": $scope.LCYBaseStampStampedFillColor,
                    "border": "solid " + $scope.LCYBaseStampStampedLineWeight + "px",
                    "border-color": $scope.LCYBaseStampStampedLineColor,
                    "padding": (5 - $scope.LCYBaseStampStampedLineWeight) + "px",
                    "position": "relative"
                };
                $scope.unstampedStyle = {
                    "background-color": "initial",
                    "border": "initial",
                    "border-color": "initial",
                    "padding": "5px",
                    "position": "relative"
                };
                $scope.sizedPreviewUnstampStyle = {
                    "width": "30px",
                    "height": "30px",
                    "margin-top": "0px",
                    "margin-left": "0px"
                }
                $scope.sizedUnstampStyle = {
                    "width": "20px",
                    "height": "20px",
                    "position": "absolute",
                    "top": "50%",
                    "left": "50%",
                    "transform": "translate(-50%,-50%)"
                };
                $scope.sizedStampStyle = {
                    "width": "20px",
                    "height": "20px",
                    "position": "absolute",
                    "top": "50%",
                    "left": "50%",
                    "transform": "translate(-50%,-50%)"
                };
                $scope.previweUnstampedStyle = {
                    "background-color": $scope.LCYBaseStampUnstampedFillColor,
                    "border": "solid " + $scope.LCYBaseStampUnstampedLineWeight + "px",
                    "border-color": $scope.LCYBaseStampUnstampedLineColor,
                    "padding": (5 - $scope.LCYBaseStampUnstampedLineWeight) + "px",
                    "position": "relative"
                };
                if($scope.stampIconType == 3) {
                    $scope.getStampIconTemplateList('set', $scope.compProfile.LCYStampIconURLPrefix);
                }
                $rootScope.stampCropperData = {};
                $rootScope.unstampCropperData = {};
            }
        }

        const loadImage = (url) => {
            return new Promise(resolve => {
                let img = new Image();
                img.onload = (() => resolve(img));
                img.src = url;
            });
        }

        const loadLCBackgrounds = async () => {
            hearth = await loadImage('/imgs/partner_registration_lovable_loyalty_program.png');
            font = new FontFace('Helvetica-bold', 'url(/fonts/Helvetica-Bold.ttf)');
            await font.load();
            document.fonts.add(font);
            document.body.classList.add('fonts-loaded');
            drawBackground();
        }

        function roundRect(x0, y0, x1, y1, r, color)
        {
            var w = x1 - x0;
            var h = y1 - y0;
            if (r > w/2) r = w/2;
            if (r > h/2) r = h/2;
            LCSourceCtx.beginPath();
            LCSourceCtx.moveTo(x1 - r, y0);
            LCSourceCtx.quadraticCurveTo(x1, y0, x1, y0 + r);
            LCSourceCtx.lineTo(x1, y1);
            LCSourceCtx.lineTo(x0, y1);
            LCSourceCtx.lineTo(x0, y0 + r);
            LCSourceCtx.quadraticCurveTo(x0, y0, x0 + r, y0);
            LCSourceCtx.closePath();
            LCSourceCtx.fillStyle = color;
            LCSourceCtx.fill();
            LCSourceCtx.lineWidth = 3;
            LCSourceCtx.stroke();
        }

        const copyToLCDestinationCanvas = () => {
            LCPreviewCtx.clearRect(0, 0, LCYScaledWidth, LCYScaledHeight);
            LCPreviewCtx.drawImage(LCSourceCanvas, 0, 0, LCYScaledWidth, LCYScaledHeight);
        }

        const drawStamps = () => {
            return new Promise(async resolve => {
                let stampedIcon;
                let unstampedIcon;
                if(($scope.selectedTemplateImg != null && $scope.selectedTemplateImg.stampIconURLPrefix != null && $scope.stampIconType != 2) || ($rootScope.stampCropperData.stampImg != null && $rootScope.unstampCropperData.unstampImg != null && $scope.stampIconType == 2)){
                    if($scope.stampIconType == 2){
                        stampedIcon = await loadImage($rootScope.stampCropperData.stampImg)
                        unstampedIcon = await loadImage($rootScope.unstampCropperData.unstampImg)
                    } else {
                        stampedIcon = await loadImage($scope.selectedTemplateImg.stampIconURLPrefix + '_stamped.png')
                        unstampedIcon = await loadImage($scope.selectedTemplateImg.stampIconURLPrefix + '_unstamped.png')
                    }
                }
                let giftIcon = await loadImage('/imgs/gift_icon.svg')
                const stampedNr = $scope.floorNrOfStampsNeeded;
                const nrOfStampsToDraw = $scope.sumNrOfStampsNeeded < 10 ? $scope.sumNrOfStampsNeeded : 10
                let stampStartX;
                let stampStartY;
                for (let i = 0; i < nrOfStampsToDraw; i++) {
                    if(i === 0){
                        stampStartX = 164
                        stampStartY = 451
                    } else if (i === 5) {
                        stampStartX = 164
                        stampStartY = 615
                    } else {
                        stampStartX += 188
                    }
                    let iconToDraw = i < stampedNr ?
                        stampedIcon : unstampedIcon
                    let stampIconWidth = 90;
                    let baseStampLineColor = i < stampedNr ?
                    $scope.LCYBaseStampStampedLineColor : $scope.LCYBaseStampUnstampedLineColor
                    let baseStampFillColor = i < stampedNr ?
                    $scope.LCYBaseStampStampedFillColor : $scope.LCYBaseStampUnstampedFillColor
                    let baseStampLineWeight = i < stampedNr ?
                    $scope.LCYBaseStampStampedLineWeight : $scope.LCYBaseStampUnstampedLineWeight
                    if($scope.LCYShowBaseStampDesign == true){
                        LCSourceCtx.beginPath();
                        LCSourceCtx.arc(stampStartX, stampStartY, 61 - ((3 * baseStampLineWeight) / 2), 0, 2 * Math.PI);
                        LCSourceCtx.fillStyle = baseStampFillColor;
                        LCSourceCtx.fill();
                        LCSourceCtx.lineWidth = 3 * baseStampLineWeight;
                        LCSourceCtx.strokeStyle = baseStampLineWeight != 0 ? baseStampLineColor : baseStampFillColor;
                        LCSourceCtx.stroke();
                    } 

                    if(i+1 == nrOfStampsToDraw && $scope.sumNrOfStampsNeeded <= 10){
                        if($scope.LCYCardBackgroundColor == "#FFFFFF"){
                            LCSourceCtx.beginPath();
                            LCSourceCtx.arc(stampStartX + 63, stampStartY - 59, 22, 0, 2 * Math.PI);
                            LCSourceCtx.fillStyle = '#DFDFDF';
                            LCSourceCtx.fill();
        
        
                            const copy = document.createElement("canvas");
                            copy.width = LCYOriginalWidth;
                            copy.height = LCYOriginalHeight;
        
                            const copyCtx = copy.getContext("2d");
                            copyCtx.fillStyle = '#FFFFFF';
                            copyCtx.fillRect(0, 0, LCYOriginalWidth, LCYOriginalHeight);
                            copyCtx.globalCompositeOperation = "destination-in";
                            copyCtx.drawImage(giftIcon, stampStartX + 48, stampStartY - 74, 30, 30);
        
                            LCSourceCtx.drawImage(copy, 0, 0);
                        } else {
                            LCSourceCtx.beginPath();
                            LCSourceCtx.arc(stampStartX + 63, stampStartY - 59, 22, 0, 2 * Math.PI);
                            LCSourceCtx.fillStyle = 'rgba(139, 139, 142, 0.3)';
                            LCSourceCtx.fill();
        
        
                            const copy = document.createElement("canvas");
                            copy.width = LCYOriginalWidth;
                            copy.height = LCYOriginalHeight;
        
                            const copyCtx = copy.getContext("2d");
                            copyCtx.fillStyle = '#FFFFFF';
                            copyCtx.fillRect(0, 0, LCYOriginalWidth, LCYOriginalHeight);
                            copyCtx.globalCompositeOperation = "destination-in";
                            copyCtx.drawImage(giftIcon, stampStartX + 48, stampStartY - 74, 30, 30);
        
                            LCSourceCtx.drawImage(copy, 0, 0);
                        }
                    }
                    if($scope.LCYShowStampIcon == true && iconToDraw != null){
                        LCSourceCtx.drawImage(iconToDraw, stampStartX - stampIconWidth / 2, stampStartY - stampIconWidth / 2, stampIconWidth, stampIconWidth);
                    }
                }
                writeStampNumbers();
                resolve()
            })
        }

        const drawCompLogo = async () => {
            if($scope.logoCropperData.compLogoImg != null){
                let logo = await loadImage($scope.logoCropperData.compLogoImg)
                let width, height, startX, startY;
                if (logo.naturalWidth > logo.naturalHeight) {
                    width = 150;
                    height = width / (logo.naturalWidth / logo.naturalHeight)
                    startX = 64;
                    startY = ((150 - height) / 2) + 139
                } else {
                    height = 150
                    width = height * (logo.naturalWidth / logo.naturalHeight);
                    startX = ((150 - width) / 2) + 64
                    startY = 139;
                }
                LCSourceCtx.drawImage(logo, startX, startY, width, height);
            }
            drawStamps();
        }

        const writePromotionName = async () => {
            if($scope.LCYPromotionName != null){
                LCSourceCtx.font = "38px Helvetica";
                //LCSourceCtx.fillStyle = "#000000";
                LCSourceCtx.fillStyle = $scope.LCYPromotionNameTextColor;
                LCSourceCtx.textAlign = "left";
                if (!$scope.LCPromotionNamePreview.secondLine) {
                    if (!$scope.LCCompNamePromoPreview.secondLine) {
                        LCSourceCtx.fillText($scope.LCPromotionNamePreview.firstLine, 258, 225 + yAxisShiftForText);
                    } else {
                        LCSourceCtx.fillText($scope.LCPromotionNamePreview.firstLine, 258, 247 + yAxisShiftForText);
                    }
                } else {
                    if (!$scope.LCCompNamePromoPreview.secondLine) {
                        LCSourceCtx.fillText($scope.LCPromotionNamePreview.firstLine, 258, 203 + yAxisShiftForText);
                        LCSourceCtx.fillText($scope.LCPromotionNamePreview.secondLine, 258, 248 + yAxisShiftForText);
                    } else {
                        LCSourceCtx.fillText($scope.LCPromotionNamePreview.firstLine, 258, 225 + yAxisShiftForText);
                        LCSourceCtx.fillText($scope.LCPromotionNamePreview.secondLine, 258, 270 + yAxisShiftForText);
                    }
                }
            }
            drawCompLogo();
        }

        const writeCompName = async () => {
            if($scope.compNamePromo != null){
                LCSourceCtx.font = '42px Helvetica-bold';
                LCSourceCtx.fillStyle = $scope.LCYCompNamePromoTextColor;
                //LCSourceCtx.fillStyle = "#000000";
                LCSourceCtx.textAlign = "left";
                if (!$scope.LCCompNamePromoPreview.secondLine) {
                    if(!$scope.LCPromotionNamePreview.secondLine){
                        LCSourceCtx.fillText($scope.LCCompNamePromoPreview.firstLine, 258, 173 + yAxisShiftForText);
                    } else {
                        LCSourceCtx.fillText($scope.LCCompNamePromoPreview.firstLine, 258, 150 + yAxisShiftForText);
                    }
                } else {
                    if (!$scope.LCCompNamePromoPreview.secondLine) {
                        LCSourceCtx.fillText($scope.LCCompNamePromoPreview.firstLine, 258, 150 + yAxisShiftForText);
                        LCSourceCtx.fillText($scope.LCCompNamePromoPreview.secondLine, 258, 195 + yAxisShiftForText);
                    } else {
                        LCSourceCtx.fillText($scope.LCCompNamePromoPreview.firstLine, 258, 140 + yAxisShiftForText);
                        LCSourceCtx.fillText($scope.LCCompNamePromoPreview.secondLine, 258, 185 + yAxisShiftForText);
                    }
                }
            }
            writePromotionName();
        }

        const writeStampNumbers = () => {
            LCSourceCtx.font = "38px Helvetica";
            //LCSourceCtx.fillStyle = "#000000";
            LCSourceCtx.fillStyle = $scope.LCYStampTextPlaceholderColor;
            LCSourceCtx.fillText(`pecsét`, 910, 170 + yAxisShiftForText);
            const displayStampNrX = $scope.LCYNrOfStamps > 9 ? 922 : 947
            LCSourceCtx.font = "50px Helvetica-bold";
            //LCSourceCtx.fillStyle = "$#000000";
            LCSourceCtx.fillStyle = $scope.LCYStampCurrentCountTextColor;
            let sumNrOfStampsNeeded = $scope.sumNrOfStampsNeeded != null && $scope.sumNrOfStampsNeeded != undefined ? $scope.sumNrOfStampsNeeded : '';
            let floorNrOfStampsNeeded = $scope.floorNrOfStampsNeeded != null && $scope.floorNrOfStampsNeeded != undefined ? $scope.floorNrOfStampsNeeded : '';
            LCSourceCtx.fillText(floorNrOfStampsNeeded + `/` + sumNrOfStampsNeeded, displayStampNrX, 222 + yAxisShiftForText);
            copyToLCDestinationCanvas();
        }

        const drawBackground = async () => {
            if(hearth != null){
                LCSourceCtx.strokeStyle =  $scope.LCYCardBackgroundColor == '#FFFFFF' ? '#dddddd' : $scope.LCYCardBackgroundColor;
                LCSourceCtx.fillStyle = $scope.LCYCardBackgroundColor;
                //LCSourceCtx.strokeStyle =  '#dddddd';
                //LCSourceCtx.fillStyle = '#FFFFFF';
                roundRect(20, 95, 1060, 728, 60, true);
                LCSourceCtx.drawImage(hearth, 950, 20, 80, 70);
                writeCompName();
            }
        }

        const measureTextWidth = (text, fontSize, fontType) => {
            if (!text) return 0
            const canvas = document.createElement("canvas");
            let context = canvas.getContext("2d");
            context.font = `${fontSize}px ${fontType}`;
            let addedRoundedWidth = 0
            text = text.toUpperCase()
            for (let i = 0; i < text.length; i++) {
                addedRoundedWidth += Math.round(context.measureText(text[i]).width);
            }
            return addedRoundedWidth
        }

        const splitTextToRowsForLCPromoImg = (text, maxWidth, fontSize, fontType, rowSplitType) => {
            if (!text) return {
                firstLine: null,
                secondLine: null
            }
            text = text
            const words = text.split(' ')
            const nrOfWords = words.length
            let firstLine = [words[0]]
            let secondLine = []
            let nextWordIndex = 1
            let lastWordIndexOfFirstLine
            let currentWidth = measureTextWidth(firstLine.join(' '), fontSize, fontType)
            if (currentWidth > maxWidth) {
                $scope.splitTextToRowsError = true;
                console.log('Első szó nem fér ki egy sorba')
            } else {
                $scope.splitTextToRowsError = false;
            }
            while (currentWidth <= maxWidth && nextWordIndex < nrOfWords) {
                firstLine.push(words[nextWordIndex])
                currentWidth = measureTextWidth(firstLine.join(' '), fontSize, fontType)
                nextWordIndex++
            }
            let needMultipleLine = currentWidth > maxWidth
            if (needMultipleLine) {
                lastWordIndexOfFirstLine = nextWordIndex - 1
                firstLine.pop()
                secondLine = words.slice(lastWordIndexOfFirstLine, nrOfWords)
            }
            if (rowSplitType === 2 && needMultipleLine) {
                while (
                    measureTextWidth(secondLine.join(' '), fontSize, fontType) <= maxWidth &&
                    measureTextWidth(firstLine.join(' '), fontSize, fontType) >
                    measureTextWidth(secondLine.join(' '), fontSize, fontType)) {
                    if (firstLine.length === 1) break
                    lastWordIndexOfFirstLine--
                    firstLine.pop()
                    secondLine.splice(0, 0, words[lastWordIndexOfFirstLine])
                }
                if (measureTextWidth(secondLine.join(' '), fontSize, fontType) > maxWidth &&
                    measureTextWidth(firstLine.join(' '), fontSize, fontType) < maxWidth) {
                    secondLine.splice(0, 1)
                    firstLine.push(words[lastWordIndexOfFirstLine])
                }
            }
            if (measureTextWidth(firstLine.join(' '), fontSize, fontType) > maxWidth ||
                measureTextWidth(secondLine.join(' '), fontSize, fontType) > maxWidth) {
                    $scope.splitTextToRowsError = true;
                    return { err: 'Két sorban sem fér ki a szöveg' }
                } else {
                    $scope.splitTextToRowsError = false;
                }
            return {
                firstLine: firstLine.join(' '),
                secondLine: secondLine.join(' ')
            }
        }


        $scope.$watch("compNamePromo", function() {
            $scope.LCCompNamePromoPreview = splitTextToRowsForLCPromoImg($scope.compNamePromo, 770, 42, 'Helvetica-bold', 1)
            drawBackground()
         }, true);

         $scope.$watch("LCYPromotionName", function() {
            $scope.LCPromotionNamePreview = splitTextToRowsForLCPromoImg($scope.LCYPromotionName, 770, 38, 'Helvetica', 1)
            drawBackground()
         }, true);

         $scope.$watch("logoCropperData.compLogoImg", function() {
            drawBackground()
         }, true);

         
         $scope.$watch("selectedTemplateImg.stampIconURLPrefix", function() {
            drawBackground()
         }, true);

         $scope.$watch("stampIconType", function() {
            drawBackground()
         }, true);

         $rootScope.$watch("stampCropperData.stampImg", function() {
            drawBackground()
         }, true);


         $rootScope.$watch("unstampCropperData.unstampImg", function() {
            drawBackground()
         }, true);

         $scope.$watch("LCYCardBackgroundColor", function() {
            drawBackground()
         }, true);

         
         $scope.$watch("LCYCompNamePromoTextColor", function() {
            drawBackground()
         }, true);

         
         $scope.$watch("LCYPromotionNameTextColor", function() {
            drawBackground()
         }, true);

         
         $scope.$watch("LCYStampTextPlaceholderColor", function() {
            drawBackground()
         }, true);

         
         $scope.$watch("LCYStampCurrentCountTextColor", function() {
            drawBackground()
         }, true);

         
         $scope.$watch("LCYDetailedDisplayBackgroundColor", function() {
            drawBackground()
         }, true);

         
         $scope.$watch("LCYBackButtonColor", function() {
            drawBackground()
         }, true);

         $scope.$watch("LCYNrOfStamps", function() {
            drawBackground()
         }, true);

         $scope.$watch("LCYShowBaseStampDesign", function() {
            drawBackground()
         }, true);

         $scope.$watch("LCYBaseStampUnstampedFillColor", function() {
            drawBackground()
         }, true);

         $scope.$watch("LCYBaseStampUnstampedLineWeight", function() {
            drawBackground()
         }, true);

         $scope.$watch("LCYBaseStampUnstampedLineColor", function() {
            drawBackground()
         }, true);
         
         $scope.$watch("LCYBaseStampStampedFillColor", function() {
            drawBackground()
         }, true);

         $scope.$watch("LCYBaseStampStampedLineWeight", function() {
            drawBackground()
         }, true);

         $scope.$watch("LCYBaseStampStampedLineColor", function() {
            drawBackground()
         }, true);
         
         $scope.$watch("LCYShowStampIcon", function() {
            drawBackground()
         }, true);

         $scope.setSumNrOfStampsNeeded = function () {
            $scope.sumNrOfStampsNeeded = $scope.LCYNrOfStamps;
            if($scope.sumNrOfStampsNeeded == undefined || $scope.sumNrOfStampsNeeded == null) {
                $scope.floorNrOfStampsNeeded = 6;
            } else if ($scope.sumNrOfStampsNeeded < 10) {
                $scope.floorNrOfStampsNeeded = Math.ceil($scope.sumNrOfStampsNeeded / 2);
            } else {
                $scope.floorNrOfStampsNeeded = 6;
            }
        }

        $scope.checkStampStyles = function () {
            if ($scope.LCYBaseStampStampedLineWeight == null && $scope.LCYBaseStampUnstampedLineWeight == null
                && $scope.LCYBaseStampUnstampedFillColor == null && $scope.LCYBaseStampUnstampedLineColor == null
                && $scope.LCYBaseStampStampedFillColor == null && $scope.LCYBaseStampStampedLineColor == null) {
                $scope.LCYBaseStampUnstampedFillColor = "#FFFFFF";
                $scope.LCYBaseStampUnstampedLineWeight = 0;
                $scope.LCYBaseStampUnstampedLineColor = "#e5e8eb";
                $scope.LCYBaseStampStampedFillColor = "#DFDFDF";
                $scope.LCYBaseStampStampedLineWeight = 0;
                $scope.LCYBaseStampStampedLineColor = "#e5e8eb";
            }
            $scope.checkStampedStyle();
            $scope.checkUnstampedStyle();
        }

        $scope.checkStampedStyle = function () {
            if ($scope.LCYShowBaseStampDesign == true) {
                $scope.stampedStyle = {
                    "background-color": $scope.LCYBaseStampStampedFillColor,
                    "border": "solid " + $scope.LCYBaseStampStampedLineWeight + "px",
                    "border-color": $scope.LCYBaseStampStampedLineColor,
                    "padding": (5 - $scope.LCYBaseStampStampedLineWeight) + "px",
                    "position": "relative"
                };
                $scope.previewStampedStyle = $scope.stampedStyle;
            } else {
                $scope.stampedStyle = {
                    "background-color": "initial",
                    "border": "initial",
                    "border-color": "initial",
                    "padding": "5px",
                    "position": "relative"
                };
            }
        }

        $scope.checkUnstampedStyle = function () {
            if ($scope.LCYShowBaseStampDesign == true) {
                $scope.unstampedStyle = {
                    "background-color": $scope.LCYBaseStampUnstampedFillColor,
                    "border": "solid " + $scope.LCYBaseStampUnstampedLineWeight + "px",
                    "border-color": $scope.LCYBaseStampUnstampedLineColor,
                    "padding": (5 - $scope.LCYBaseStampUnstampedLineWeight) + "px",
                    "position": "relative"
                };
                $scope.previweUnstampedStyle = $scope.unstampedStyle;
            } else {
                $scope.unstampedStyle = {
                    "background-color": "initial",
                    "border": "initial",
                    "border-color": "initial",
                    "padding": "5px",
                    "position": "relative"
                };
            }
        }


        /*****************************************/
        // Draw template to canvas
        let LCSourceCanvas = document.getElementById("LCPromoOriginal");
        let LCPreviewCanvas = document.getElementById("LCPromoScaled");
        let LCCanvasToSend = document.getElementById("LCPromoToSend");
        let LCPreviewCanvasToSend = document.createElement("canvas");
        let LCSourceCtx = LCSourceCanvas.getContext("2d");
        let LCPreviewCtx = LCPreviewCanvas.getContext('2d');
        let LCCtxToSend = LCCanvasToSend.getContext('2d');
        let LCPreviewCtxToSend = LCPreviewCanvasToSend.getContext('2d');
        LCPreviewCanvasToSend.height = 180
        LCPreviewCanvasToSend.width = 270
        const yAxisShiftForText = 33
        const LCYOriginalAndScaledRatio = 4
        const A5OriginalAndScaledRatio = 2
        let LCYOriginalHeight = 720
        let LCYOriginalWidth = 1080
        let LCYScaledHeight = LCYOriginalHeight / LCYOriginalAndScaledRatio
        let LCYScaledWidth = LCYOriginalWidth / LCYOriginalAndScaledRatio
        let font, hearth
        $scope.LCCompNamePromoPreview = {firstLine: '', secondLine: ''};
        $scope.LCPromotionNamePreview = {firstLine: '', secondLine: ''};

        loadLCBackgrounds();

        /****************************************/

        if (potentialCompanyMainId != null) {
            if (document.getElementById('loadingOverlay')) {
                document.getElementById('loadingOverlay').style.display = "block";
                document.getElementById('innerPartnerPageContainer').style.overflow = "hidden";
            }
            $scope.registrationType = 'yaltyAdmin';
            let filters = {};
            filters.mainId = potentialCompanyMainId;
            filters.queryType = 2;
            $http.post(API_PREFIX.url + '/yaltyadmin/getSalesPotentialCompanyRecords', filters).then(function (result) {
                if (document.getElementById('loadingOverlay')) {
                    document.getElementById('loadingOverlay').style.display = "none";
                    document.getElementById('innerPartnerPageContainer').style.overflow = "unset";
                }
                if (result.data.errorCode == 0) {
                    $scope.salesPotentialCompanyMainId = potentialCompanyMainId;
                    $scope.originalSalesPotentialCompanyMainId = potentialCompanyMainId;
                    if (result.data.potentialCompanySearchResult[0].compName != null) {
                        $scope.compName = result.data.potentialCompanySearchResult[0].compName;
                    } else {
                        $scope.compName = result.data.potentialCompanySearchResult[0].facebookPageName;
                    }
                    $scope.compNamePromo = null;
                    $scope.isCompNamePromoSame = false;
                    $scope.facebookPageName = result.data.potentialCompanySearchResult[0].facebookPageName;
                    $scope.taxNum = result.data.potentialCompanySearchResult[0].taxNum;
                    let index = $scope.promoCompProfiles.findIndex(record => record.compProfile === result.data.potentialCompanySearchResult[0].compProfile);
                    if (index != -1) {
                        $scope.selectedCompanyProfile = $scope.promoCompProfiles[index];
                        $scope.compProfile = $scope.promoCompProfiles[index];
                    }
                    $scope.LCYIsDemoActive = true;
                    $scope.LCYIsPromotionNameBasedOnCompProfile = $scope.compProfile != null && $scope.compProfile.compProfile == 'Egyéb' ? false : true;
                    $scope.stampIconType = $scope.compProfile != null && $scope.compProfile.compProfile == 'Egyéb' ? 1 : 3;
                    $scope.LCYPromotionName = $scope.compProfile.LCYPromotionName;
                    $scope.LCYCardBackgroundColor = '#FFFFFF';
                    $scope.LCYCompNamePromoTextColor = '#4E525C';
                    $scope.LCYPromotionNameTextColor = '#000000'
                    $scope.LCYStampTextPlaceholderColor = '#4E525C';
                    $scope.LCYStampCurrentCountTextColor = '#000000';
                    $scope.LCYDetailedDisplayBackgroundColor = '#000000';
                    $scope.LCYBackButtonColor = '#FFFFFF';
                    $scope.LCYNrOfStamps = 9;
                    $scope.setSumNrOfStampsNeeded();
                    $scope.LCYShowBaseStampDesign = false;
                    $scope.LCYBaseStampUnstampedFillColor = '#FFFFFF';
                    $scope.LCYBaseStampUnstampedLineWeight = 0;
                    $scope.LCYBaseStampUnstampedLineColor = '#E5E8EB';
                    $scope.LCYBaseStampStampedFillColor = '#DFDFDF';
                    $scope.LCYBaseStampStampedLineWeight = 0;
                    $scope.LCYBaseStampStampedLineColor = '#E5E8EB';
                    $scope.LCYShowStampIcon = true;
                    $scope.stampedStyle = {
                        "background-color": "initial",
                        "border": "initial",
                        "border-color": "initial",
                        "padding": "5px",
                        "position": "relative"
                    };
                    $scope.sizedPreviewStampStyle = {
                        "width": "30px",
                        "height": "30px",
                        "margin-top": "0px",
                        "margin-left": "0px"
                    };
                    $scope.previewStampedStyle = {
                        "background-color": $scope.LCYBaseStampStampedFillColor,
                        "border": "solid " + $scope.LCYBaseStampStampedLineWeight + "px",
                        "border-color": $scope.LCYBaseStampStampedLineColor,
                        "padding": (5 - $scope.LCYBaseStampStampedLineWeight) + "px",
                        "position": "relative"
                    };
                    $scope.unstampedStyle = {
                        "background-color": "initial",
                        "border": "initial",
                        "border-color": "initial",
                        "padding": "5px",
                        "position": "relative"
                    };
                    $scope.sizedPreviewUnstampStyle = {
                        "width": "30px",
                        "height": "30px",
                        "margin-top": "0px",
                        "margin-left": "0px"
                    }
                    $scope.sizedUnstampStyle = {
                        "width": "20px",
                        "height": "20px",
                        "position": "absolute",
                        "top": "50%",
                        "left": "50%",
                        "transform": "translate(-50%,-50%)"
                    };
                    $scope.sizedStampStyle = {
                        "width": "20px",
                        "height": "20px",
                        "position": "absolute",
                        "top": "50%",
                        "left": "50%",
                        "transform": "translate(-50%,-50%)"
                    };
                    $scope.previweUnstampedStyle = {
                        "background-color": $scope.LCYBaseStampUnstampedFillColor,
                        "border": "solid " + $scope.LCYBaseStampUnstampedLineWeight + "px",
                        "border-color": $scope.LCYBaseStampUnstampedLineColor,
                        "padding": (5 - $scope.LCYBaseStampUnstampedLineWeight) + "px",
                        "position": "relative"
                    };
                    $scope.getStampIconTemplateList('set', $scope.compProfile.LCYStampIconURLPrefix);
                    // $scope.setIconCanvases("#FFFFFF");
                    $scope.isYaltyStartFreePackageEnabled = result.data.potentialCompanySearchResult[0].isYaltyStartFreePackageEnabled;
                    $rootScope.logoCropperData = {};
                    $rootScope.stampCropperData = {};
                    $rootScope.unstampCropperData = {};
                    $scope.openPreReg = function (mode) {
                        if (mode == 1) {
                            let newTab = $window.open("", "_blank");
                            newTab.location = "/yaltyadmin738/preregistrations/" + $scope.companyPreRegistrationId;
                        } else if (mode == 2) {
                            let newTab = $window.open("", "_blank");
                            newTab.location = "/yaltyadmin738/preregisterPartner?preregId=" + $scope.companyPreRegistrationId;
                        }
                    }

                    $scope.createPreregistration = function (form) {
                        $scope.PreregistrationForm.compNamePromo.$touched = true
                        if (!form.$valid) {
                            if (angular.element($document[0].querySelector('input.ng-invalid')).length > 0) {
                                if (form.compNamePromo.$invalid) {
                                    let element = document.getElementById("compNamePromo");
                                    element.scrollIntoView();
                                } else if ($scope.compProfile == null && $scope.LCYIsDemoActive == true) {
                                    $('html,body').animate({ scrollTop: angular.element($document[0].querySelector('#compProfile'))[0].offsetParent.offsetTop }, "slow");
                                } else {
                                    $('html,body').animate({ scrollTop: angular.element($document[0].querySelector('input.ng-invalid'))[0].offsetParent.offsetTop }, "slow");
                                    angular.element($document[0].querySelector('input.ng-invalid')).focus();
                                }
                            } else if (angular.element($document[0].querySelector('md-select.ng-invalid')).length > 0) {
                                angular.element($document[0].querySelector('md-select.ng-invalid')).focus();
                            } else {
                                angular.element($document[0].querySelector('textarea.ng-invalid')).focus();
                            }
                            return false;
                        } else if ($rootScope.logoCropperData.compLogoImg == null) {
                            form.logoImage.$error.need = true;
                            $('html,body').animate({ scrollTop: $('div#logoImage').offset().top - 150 }, "slow");
                            return false;
                        } else if ($rootScope.stampCropperData.stampImg == null && $scope.stampIconType == 2 && $scope.LCYIsDemoActive == true) {
                            form.stampImg.$error.need = true;
                            $scope.needTemplateImage.need = null;
                            $('html,body').animate({ scrollTop: $('div#templateImgContainer').offset().top - 150 }, "slow");
                            return false;
                        } else if (!$scope.selectedTemplateImg && $scope.LCYIsDemoActive == true && ($scope.stampIconType == 1 || $scope.stampIconType == 3)) {
                            $scope.needTemplateImage.need = true;
                            $('html,body').animate({ scrollTop: $('div#templateImgContainer').offset().top - 150 }, "slow");
                            return false;
                        } else if ($scope.splitTextToRowsError == true) {
                            const alert = $mdDialog.alert()
                            .title('A "Promóciókon megjelenő cég megnevezés" vagy a "Kedvezmény rövid megnevezése" nem megfelelő.')
                            .textContent('Az említett két mezőben megadott szövegek közül az egyik túl hosszú ahhoz, hogy 2 sorban kiférjen az "Emailben megjelenő kép"-en (vagy a szöveg valamely szava túl hosszú ahhoz, hogy egy sorban kiférjen). Ekkor az "Emailben megjelenő kép"-en nem a mezőben megadott szöveg jelenik meg. Ellenőrizd és rövidítsd az adott szövege(ke)t.')
                            .ok('Rendben')
                            $mdDialog.show(alert).then(function () {});
                        } else {
                            const LCYStampIconChoiceId = $scope.getChoiceIdFromStampIconType[$scope.stampIconType]
                            let preRegistrationData = {};
                            if ($scope.salesPotentialCompanyMainId) {
                                preRegistrationData.salesPotentialCompanyMainId = $scope.salesPotentialCompanyMainId;
                            }
                            preRegistrationData.preregistrationId = $scope.preregistrationId;
                            preRegistrationData.companyName = $scope.compName;
                            preRegistrationData.compNamePromo = $scope.compNamePromo;
                            preRegistrationData.isCompNamePromoSame = $scope.isCompNamePromoSame;
                            preRegistrationData.compLogoImg = $rootScope.logoCropperData.compLogoImg;
                            preRegistrationData.taxNumber = $scope.taxNum;
                            preRegistrationData.onlinePage = $scope.onlinePage;
                            if ($scope.compProfile != null) {
                                preRegistrationData.compProfile = $scope.compProfile.compProfile;
                            } else {
                                preRegistrationData.compProfile = $scope.compProfile;
                            }
                            preRegistrationData.title = $scope.mainContactNameTitle;
                            preRegistrationData.lastName = $scope.mainContactNameLastName;
                            preRegistrationData.firstName = $scope.mainContactNameFirstName;
                            preRegistrationData.email = $scope.mainContactEmail;
                            preRegistrationData.contactPhoneNumber = $scope.mainContactPhone;
                            preRegistrationData.contactChoiceId = $scope.contactChoiceId;
                            preRegistrationData.comments = $scope.comments;
                            preRegistrationData.LCYIsDemoActive = $scope.LCYIsDemoActive;
                            if ($scope.LCYPromotionName) {
                                preRegistrationData.LCYPromotionName = $scope.LCYPromotionName;
                            } else {
                                preRegistrationData.LCYPromotionName = $scope.LCYPromotionName;
                            }
                            preRegistrationData.LCYCardBackgroundColor = $scope.LCYCardBackgroundColor;
                            preRegistrationData.LCYCompNamePromoTextColor = $scope.LCYCompNamePromoTextColor;
                            preRegistrationData.LCYPromotionNameTextColor = $scope.LCYPromotionNameTextColor;
                            preRegistrationData.LCYDetailedDisplayBackgroundColor = $scope.LCYDetailedDisplayBackgroundColor;
                            preRegistrationData.LCYBackButtonColor = $scope.LCYBackButtonColor;
                            preRegistrationData.LCYNrOfStamps = $scope.LCYNrOfStamps;
                            preRegistrationData.LCYShowBaseStampDesign = $scope.LCYShowBaseStampDesign;
                            if($scope.LCYShowBaseStampDesign){
                                preRegistrationData.LCYBaseStampUnstampedFillColor = $scope.LCYBaseStampUnstampedFillColor;
                                preRegistrationData.LCYBaseStampUnstampedLineWeight = $scope.LCYBaseStampUnstampedLineWeight;
                                preRegistrationData.LCYBaseStampUnstampedLineColor = $scope.LCYBaseStampUnstampedLineColor;
                                preRegistrationData.LCYBaseStampStampedFillColor = $scope.LCYBaseStampStampedFillColor;
                                preRegistrationData.LCYBaseStampStampedLineWeight = $scope.LCYBaseStampStampedLineWeight;
                                preRegistrationData.LCYBaseStampStampedLineColor = $scope.LCYBaseStampStampedLineColor;
                            } else {
                                preRegistrationData.LCYBaseStampUnstampedFillColor = null;
                                preRegistrationData.LCYBaseStampUnstampedLineWeight = null;
                                preRegistrationData.LCYBaseStampUnstampedLineColor = null;
                                preRegistrationData.LCYBaseStampStampedFillColor = null;
                                preRegistrationData.LCYBaseStampStampedLineWeight = null;
                                preRegistrationData.LCYBaseStampStampedLineColor = null;
                            }
                            preRegistrationData.LCYShowStampIcon = $scope.LCYShowStampIcon;
                            preRegistrationData.LCYStampTextPlaceholderColor = $scope.LCYStampTextPlaceholderColor;
                            preRegistrationData.LCYStampCurrentCountTextColor = $scope.LCYStampCurrentCountTextColor;
                            preRegistrationData.LCYIsPromotionNameBasedOnCompProfile = $scope.LCYIsPromotionNameBasedOnCompProfile;
                            preRegistrationData.LCYStampIconChoiceId = LCYStampIconChoiceId;
                            if (LCYStampIconChoiceId == 1 || LCYStampIconChoiceId == 2) {
                                preRegistrationData.LCYStampIconURL = $scope.selectedTemplateImg.stampIconURLPrefix;
                            } else {
                                if ($rootScope.stampCropperData && $rootScope.stampCropperData.stampImg && $rootScope.unstampCropperData && $rootScope.unstampCropperData.unstampImg) {
                                    if ($rootScope.stampCropperData.stampImg.includes('/imgs/')) {
                                        preRegistrationData.LCYStampIconURL = $rootScope.stampCropperData.stampImg.split("_stamped.png")[0];
                                    } else if ($rootScope.stampCropperData.originalStampImg.includes('/imgs/')) {
                                        preRegistrationData.LCYStampIconURL = $rootScope.stampCropperData.originalStampImg.split("_stamped.png")[0];
                                    } else {
                                        preRegistrationData.LCYStampIconURL = $rootScope.stampCropperData.originalStampImg;
                                    }
                                    if ($rootScope.unstampCropperData.unstampImg.includes('/imgs/')) {
                                        preRegistrationData.LCYUnstampIconURL = $rootScope.unstampCropperData.unstampImg.split("_unstamped.png")[0];
                                    } else if ($rootScope.unstampCropperData.originalUnstampImg.includes('/imgs/')) {
                                        preRegistrationData.LCYUnstampIconURL = $rootScope.unstampCropperData.originalUnstampImg.split("_unstamped.png")[0];
                                    } else {
                                        preRegistrationData.LCYUnstampIconURL = $rootScope.unstampCropperData.originalUnstampImg;
                                    }
                                }
                            }
                            preRegistrationData.registrationType = $scope.registrationType;
                            if ($scope.salesPotentialCompanyMainId) {
                                preRegistrationData.salesPotentialCompanyMainId = $scope.salesPotentialCompanyMainId;
                                preRegistrationData.isYaltyStartFreePackageEnabled = $scope.isYaltyStartFreePackageEnabled;
                            }
                            LCCtxToSend.clearRect(0, 0, LCYOriginalWidth, LCYOriginalHeight)
                            LCCtxToSend.fillStyle = "white";
                            LCCtxToSend.fillRect(0, 0, LCYOriginalWidth, LCYOriginalHeight);
                            LCCtxToSend.drawImage(LCSourceCanvas, 0, 0, LCYOriginalWidth, LCYOriginalHeight);
                            preRegistrationData.LCPromoImg = LCCanvasToSend.toDataURL('image/png');
                            if (document.getElementById('transitionOverlay')) {
                                document.getElementById('transitionOverlay').style.display = "block";
                            }
                            $http.post(API_PREFIX.url + "/yaltyadmin/preregisterPartner", preRegistrationData).then(function (result) {
                                if (document.getElementById('transitionOverlay')) {
                                    document.getElementById('transitionOverlay').style.display = "none";
                                }
                                if (result.data.errorCode == 0 && result.data.preRegId) {
                                    $state.go("adminPages.preregistrations", { preregistrationId: result.data.preRegId })
                                } else if (result.data.errorCode == 1) {
                                    const alert = $mdDialog.alert()
                                        .title("Sikertelen művelet")
                                        .textContent("Adatbázis művelet során váratlan hiba lépett fel, kérjük ismételje meg a műveletet.")
                                        .ok('Rendben')
                                    $mdDialog.show(alert).then(function () {
                                        $scope.sendButtonDisabled = false;
                                    });
                                } else if (result.data.errorCode == 2) {
                                    const alert = $mdDialog.alert()
                                        .title("Sikertelen művelet")
                                        .textContent("A művelet elvégzéséhez nincs jogosultsága. Ezért kiléptetjük az oldalról.")
                                        .ok('Rendben')
                                    $mdDialog.show(alert).then(function () {
                                        $scope.sendButtonDisabled = false;
                                        $state.go('adminLogin');
                                    });
                                } else if (result.data.errorCode == 3) {
                                    $scope.companyPreRegistrationId = result.data.companyPreRegistrationId;
                                    var confirm = $mdDialog.confirm()
                                        .textContent("A potenciális partnerhez már létezik előregisztráció. A Listázás gomb megnyomására listázzuk neked. Ha szerkeszteni szeretnéd, nyomd meg a Szerkesztés gombot.")
                                        .ok('Listázás')
                                        .cancel('Szerkesztés');
                                    $mdDialog.show(confirm).then(function () {
                                        $scope.openPreReg(1);
                                    }, function () {
                                        $scope.openPreReg(2);
                                    });
                                }
                            })
                        }
                    }
                } else {
                    commonService.showDialogWindowWithTitleOnly('Szerver hiba. Próbáld meg egy kicsit később újra.', 'Rendben')
                }
            });
        } else if ($scope.preregistrationId != null) {
            let param = { id: $scope.preregistrationId }
            $http.post(API_PREFIX.url + "/yaltyadmin/getPartnerPreregistrationData", param).then(function (result) {
                if (result.data.errorCode == 0) {
                    $scope.partnerPreregistrationData = result.data.partnerPreregistrationData;
                    $scope.salesPotentialCompanyMainId = result.data.partnerPreregistrationData.salesPotentialCompanyMainId;
                    $scope.originalSalesPotentialCompanyMainId = result.data.partnerPreregistrationData.salesPotentialCompanyMainId;
                    $scope.LCYIsPromotionNameBasedOnCompProfile = $scope.partnerPreregistrationData.LCYIsPromotionNameBasedOnCompProfile;
                    $scope.stampIconType = $scope.getStampIconTypeFromChoiceId[$scope.partnerPreregistrationData.LCYStampIconChoiceId];
                    $scope.LCYPromotionName = $scope.partnerPreregistrationData.LCYPromotionName;
                    $scope.LCYCardBackgroundColor = $scope.partnerPreregistrationData.LCYCardBackgroundColor;
                    $scope.LCYCompNamePromoTextColor = $scope.partnerPreregistrationData.LCYCompNamePromoTextColor;
                    $scope.LCYPromotionNameTextColor = $scope.partnerPreregistrationData.LCYPromotionNameTextColor;
                    $scope.LCYDetailedDisplayBackgroundColor = $scope.partnerPreregistrationData.LCYDetailedDisplayBackgroundColor;
                    $scope.LCYBackButtonColor = $scope.partnerPreregistrationData.LCYBackButtonColor;
                    $scope.LCYNrOfStamps = $scope.partnerPreregistrationData.LCYNrOfStamps;
                    $scope.setSumNrOfStampsNeeded();
                    $scope.LCYShowBaseStampDesign = $scope.partnerPreregistrationData.LCYShowBaseStampDesign;
                    $scope.LCYBaseStampUnstampedFillColor = $scope.partnerPreregistrationData.LCYBaseStampUnstampedFillColor;
                    $scope.LCYBaseStampUnstampedLineWeight = $scope.partnerPreregistrationData.LCYBaseStampUnstampedLineWeight;
                    $scope.LCYBaseStampUnstampedLineColor = $scope.partnerPreregistrationData.LCYBaseStampUnstampedLineColor;
                    $scope.LCYBaseStampStampedFillColor = $scope.partnerPreregistrationData.LCYBaseStampStampedFillColor;
                    $scope.LCYBaseStampStampedLineWeight = $scope.partnerPreregistrationData.LCYBaseStampStampedLineWeight;
                    $scope.LCYBaseStampStampedLineColor = $scope.partnerPreregistrationData.LCYBaseStampStampedLineColor;
                    if ($scope.LCYBaseStampStampedLineWeight == null && $scope.LCYBaseStampUnstampedLineWeight == null
                        && $scope.LCYBaseStampUnstampedFillColor == null && $scope.LCYBaseStampUnstampedLineColor == null
                        && $scope.LCYBaseStampStampedFillColor == null && $scope.LCYBaseStampStampedLineColor == null) {
                        $scope.LCYBaseStampUnstampedFillColor = "#FFFFFF";
                        $scope.LCYBaseStampUnstampedLineWeight = 0;
                        $scope.LCYBaseStampUnstampedLineColor = "#e5e8eb";
                        $scope.LCYBaseStampStampedFillColor = "#DFDFDF";
                        $scope.LCYBaseStampStampedLineWeight = 0;
                        $scope.LCYBaseStampStampedLineColor = "#e5e8eb";
                    }
                    $scope.LCYShowStampIcon = $scope.partnerPreregistrationData.LCYShowStampIcon;
                    $scope.stampedStyle = {
                        "background-color": $scope.LCYBaseStampStampedFillColor,
                        "border": "solid " + $scope.LCYBaseStampStampedLineWeight + "px",
                        "border-color": $scope.LCYBaseStampStampedLineColor,
                        "padding": (5 - $scope.LCYBaseStampStampedLineWeight) + "px",
                        "position": "relative"
                    };
                    $scope.sizedPreviewStampStyle = {
                        "width": "30px",
                        "height": "30px",
                        "margin-top": "0px",
                        "margin-left": "0px"
                    };
                    $scope.previewStampedStyle = $scope.stampedStyle;
                    $scope.unstampedStyle = {
                        "background-color": $scope.LCYBaseStampUnstampedFillColor,
                        "border": "solid " + $scope.LCYBaseStampUnstampedLineWeight + "px",
                        "border-color": $scope.LCYBaseStampUnstampedLineColor,
                        "padding": (5 - $scope.LCYBaseStampUnstampedLineWeight) + "px",
                        "position": "relative"
                    };
                    $scope.sizedPreviewUnstampStyle = {
                        "width": "30px",
                        "height": "30px",
                        "margin-top": "0px",
                        "margin-left": "0px"
                    }
                    $scope.sizedUnstampStyle = {
                        "width": "20px",
                        "height": "20px",
                        "position": "absolute",
                        "top": "50%",
                        "left": "50%",
                        "transform": "translate(-50%,-50%)"
                    };
                    $scope.sizedStampStyle = {
                        "width": "20px",
                        "height": "20px",
                        "position": "absolute",
                        "top": "50%",
                        "left": "50%",
                        "transform": "translate(-50%,-50%)"
                    };
                    $scope.previweUnstampedStyle = $scope.unstampedStyle;
                    $scope.LCYStampTextPlaceholderColor = $scope.partnerPreregistrationData.LCYStampTextPlaceholderColor;
                    $scope.LCYStampCurrentCountTextColor = $scope.partnerPreregistrationData.LCYStampCurrentCountTextColor;
                    if ($scope.stampIconType == 1 || $scope.stampIconType == 3) {
                        $scope.getStampIconTemplateList('set', $scope.partnerPreregistrationData.LCYStampIconURL);
                    } else if($scope.partnerPreregistrationData.LCYStampIconURL != null) {
                        $rootScope.unstampCropperData.unstampImg = $scope.partnerPreregistrationData.LCYStampIconURL + "_unstamped.png";
                        $rootScope.unstampCropperData.originalUnstampImg = $scope.partnerPreregistrationData.LCYStampIconURL + "_unstamped.png";
                        $rootScope.unstampCropperData.previewUnstampImg = $rootScope.unstampCropperData.unstampImg;
                        $rootScope.stampCropperData.stampImg = $scope.partnerPreregistrationData.LCYStampIconURL + "_stamped.png";
                        $rootScope.stampCropperData.originalStampImg = $scope.partnerPreregistrationData.LCYStampIconURL + "_stamped.png";
                        $rootScope.stampCropperData.previewStampImg = $rootScope.stampCropperData.stampImg;
                    }
                    $scope.LCYIsDemoActive = $scope.partnerPreregistrationData.LCYIsDemoActive;
                    $rootScope.logoCropperData.compLogoImg = $scope.partnerPreregistrationData.compLogoImgURL;
                    $scope.compName = $scope.partnerPreregistrationData.compName;
                    $scope.compNamePromo = $scope.partnerPreregistrationData.compNamePromo;
                    $scope.status = $scope.partnerPreregistrationData.status;
                    if ($scope.partnerPreregistrationData.compProfile != null) {
                        let index = $scope.promoCompProfiles.findIndex(record => record.compProfile === $scope.partnerPreregistrationData.compProfile);
                        if (index != -1) {
                            $scope.selectedCompanyProfile = $scope.promoCompProfiles[index];
                            $scope.compProfile = $scope.promoCompProfiles[index];
                        }
                    } else {
                        $scope.compProfile = $scope.partnerPreregistrationData.compProfile;
                    }
                    $scope.contactChoiceId = $scope.partnerPreregistrationData.contactChoiceId;
                    $scope.comments = $scope.partnerPreregistrationData.comments;
                    $scope.isCompNamePromoSame = $scope.partnerPreregistrationData.isCompNamePromoSame;
                    $scope.mainContactEmail = $scope.partnerPreregistrationData.mainContactEmail;
                    $scope.mainContactNameFirstName = $scope.partnerPreregistrationData.mainContactNameFirstName;
                    $scope.mainContactNameLastName = $scope.partnerPreregistrationData.mainContactNameLastName;
                    $scope.mainContactNameTitle = $scope.partnerPreregistrationData.mainContactNameTitle;
                    $scope.mainContactPhone = $scope.partnerPreregistrationData.mainContactPhone;
                    $scope.registrationType = $scope.partnerPreregistrationData.registrationType;
                    $scope.taxNum = $scope.partnerPreregistrationData.taxNum;
                    $scope.onlinePage = $scope.partnerPreregistrationData.onlinePage;
                    if($scope.salesPotentialCompanyMainId != null) {
                        let filters = {};
                        filters.mainId = $scope.salesPotentialCompanyMainId;
                        filters.queryType = 2;
                        $http.post(API_PREFIX.url + '/yaltyadmin/getSalesPotentialCompanyRecords', filters).then(function (result) {
                            if (result.data.errorCode == 0) {
                                $scope.facebookPageName = result.data.potentialCompanySearchResult[0].facebookPageName;
                            }
                        })
                    }
                } else if (result.data.errorCode == 1) {
                    const alert = $mdDialog.alert()
                        .title("Sikertelen művelet")
                        .textContent("Adatbázis művelet során váratlan hiba lépett fel, kérjük ismételje meg a műveletet.")
                        .ok('Rendben')
                    $mdDialog.show(alert).then(function () {
                        $scope.sendButtonDisabled = false;
                    });
                } else if (result.data.errorCode == 2) {
                    const alert = $mdDialog.alert()
                        .title("Sikertelen művelet")
                        .textContent("A művelet elvégzéséhez nincs jogosultsága. Ezért kiléptetjük az oldalról.")
                        .ok('Rendben')
                    $mdDialog.show(alert).then(function () {
                        $rootScope.isDataSheetModified = false;
                        $scope.sendButtonDisabled = false;
                        $state.go('adminLogin');
                    });
                }
            })
        } else {
            $scope.registrationType = 'yaltyAdmin';
            $scope.isCompNamePromoSame = false;
            $scope.LCYIsPromotionNameBasedOnCompProfile = true;
            $scope.stampIconType = 3;
            $scope.LCYIsDemoActive = false;
            $scope.compProfile = null;
            $scope.LCYCardBackgroundColor = '#FFFFFF';
            $scope.LCYCompNamePromoTextColor = '#4E525C';
            $scope.LCYPromotionNameTextColor = '#000000';
            $scope.LCYStampTextPlaceholderColor = '#4E525C';
            $scope.LCYStampCurrentCountTextColor = '#000000';
            $scope.LCYDetailedDisplayBackgroundColor = '#000000';
            $scope.LCYBackButtonColor = '#FFFFFF';
            $scope.LCYNrOfStamps = 9;
            $scope.setSumNrOfStampsNeeded();
            $scope.LCYShowBaseStampDesign = false;
            $scope.LCYBaseStampUnstampedFillColor = '#FFFFFF';
            $scope.LCYBaseStampUnstampedLineWeight = 0;
            $scope.LCYBaseStampUnstampedLineColor = '#E5E8EB';
            $scope.LCYBaseStampStampedFillColor = '#DFDFDF';
            $scope.LCYBaseStampStampedLineWeight = 0;
            $scope.LCYBaseStampStampedLineColor = '#E5E8EB';
            $scope.LCYShowStampIcon = true;
            $scope.stampedStyle = {
                "background-color": "initial",
                "border": "initial",
                "border-color": "initial",
                "padding": "5px",
                "position": "relative"
            };
            $scope.sizedPreviewStampStyle = {
                "width": "30px",
                "height": "30px",
                "margin-top": "0px",
                "margin-left": "0px"
            };
            $scope.previewStampedStyle = {
                "background-color": $scope.LCYBaseStampStampedFillColor,
                "border": "solid " + $scope.LCYBaseStampStampedLineWeight + "px",
                "border-color": $scope.LCYBaseStampStampedLineColor,
                "padding": (5 - $scope.LCYBaseStampStampedLineWeight) + "px",
                "position": "relative"
            };
            $scope.unstampedStyle = {
                "background-color": "initial",
                "border": "initial",
                "border-color": "initial",
                "padding": "5px",
                "position": "relative"
            };
            $scope.sizedPreviewUnstampStyle = {
                "width": "30px",
                "height": "30px",
                "margin-top": "0px",
                "margin-left": "0px"
            }
            $scope.sizedUnstampStyle = {
                "width": "20px",
                "height": "20px",
                "position": "absolute",
                "top": "50%",
                "left": "50%",
                "transform": "translate(-50%,-50%)"
            };
            $scope.sizedStampStyle = {
                "width": "20px",
                "height": "20px",
                "position": "absolute",
                "top": "50%",
                "left": "50%",
                "transform": "translate(-50%,-50%)"
            };
            $scope.previweUnstampedStyle = {
                "background-color": $scope.LCYBaseStampUnstampedFillColor,
                "border": "solid " + $scope.LCYBaseStampUnstampedLineWeight + "px",
                "border-color": $scope.LCYBaseStampUnstampedLineColor,
                "padding": (5 - $scope.LCYBaseStampUnstampedLineWeight) + "px",
                "position": "relative"
            };
        }
        $scope.needTemplateImage = {};
        // $scope.setIconCanvases("#FFFFFF");
        $rootScope.logoCropperData = {};
        $rootScope.stampCropperData = {};
        $rootScope.unstampCropperData = {};

        
        $scope.isSameCompName = function () {
            if ($scope.isCompNamePromoSame == true) {
                $scope.compNamePromo = $scope.compName ? $scope.compName.trim() : "";
                $rootScope.isDataSheetModified = true;
            } else {
                $rootScope.isDataSheetModified = true;
            }
            if ($scope.compNamePromo != null && $scope.compNamePromo.length > 35) {
                $scope.PreregistrationForm.compNamePromo.$touched = true
                //$scope.PreregistrationForm.compNamePromo.$invalid = true
            }
        }
        $scope.setValuesFromProfile = function () {
            if ($scope.compProfile != null && $scope.compProfile.compProfile != 'Egyéb') {
                if ($scope.LCYIsPromotionNameBasedOnCompProfile == true) {
                    $scope.LCYPromotionName = $scope.compProfile.LCYPromotionName;
                }
                if ($scope.stampIconType == 3) {
                    $scope.getStampIconTemplateList('set', $scope.compProfile.LCYStampIconURLPrefix);
                }
            } else if($scope.compProfile != null && $scope.compProfile.compProfile == 'Egyéb') {
                $scope.LCYIsPromotionNameBasedOnCompProfile = false;
                if($scope.stampIconType == 3){
                    $scope.stampIconType = 1;
                }
                //$scope.LCYPromotionName = null;
                if ($scope.stampIconCategories) {
                    $scope.$parent.selectedCategory = $scope.stampIconCategories[0];
                    $scope.$parent.selectCategory = $scope.stampIconCategories[0];
                }
                $scope.$parent.selectTemplateImg = undefined;
                $scope.$parent.selectedTemplateImg = undefined;
                angular.forEach($scope.stampImages, function (subscription, index) {
                    subscription.checked = false;
                });
            } else {
                $scope.LCYPromotionName = "";
                if ($scope.stampIconCategories) {
                    $scope.$parent.selectedCategory = $scope.stampIconCategories[0];
                    $scope.$parent.selectCategory = $scope.stampIconCategories[0];
                }
                $scope.$parent.selectTemplateImg = undefined;
                $scope.$parent.selectedTemplateImg = undefined;
                angular.forEach($scope.stampImages, function (subscription, index) {
                    subscription.checked = false;
                });
            }
        }
        $scope.cancelForm = function () {
            if ($rootScope.isDataSheetModified == false) {
                $window.scrollTo(0, 0);
                $state.go("adminPages.preregistrations", { preregistrationId: 0 }, { reload: true })
            } else {
                var confirm = $mdDialog.confirm()
                    .title('Biztosan el szeretné hagyni az oldalt?')
                    .textContent('Az adatlapon történt módosításai mentés nélkül elvesznek.')
                    .ariaLabel('Oldal elhagyás megerősítése')
                    .ok('Igen')
                    .cancel('Mégsem');
                $mdDialog.show(confirm).then(function () {
                    $rootScope.isDataSheetModified = false;
                    $window.scrollTo(0, 0);
                    $state.go("adminPages.preregistrations", { preregistrationId: 0 }, { reload: true })
                }, function () {
                });
            }
        }

        let scrollToElement = (id) => {
            $location.hash(id);
            $anchorScroll()
            $location.hash('');
            $location.replace();
        }
        $scope.querySearchCompanyProfiles = function (query) {
            if (query) {
                var filtered = $scope.promoCompProfiles.filter(function (i) {
                    var lowercaseQuery = angular.lowercase(query);
                    return ((i.compProfile.toLowerCase()).indexOf(lowercaseQuery) === 0);
                })
                if (filtered.length > 0) {
                    return filtered;
                } else {
                    return $scope.promoCompProfiles;
                }
            } else {
                return $scope.promoCompProfiles;
            }
        }

        $scope.$watch('selectedCompanyProfile', function (value) {
            if(value){
                $scope.compProfile = value;
                $scope.setValuesFromProfile();
            }
        })

        $scope.$watch('stampIconType', function (value) {
            if (!value) return
            if (value == 1) {
                $scope.PreregistrationForm.stampImg.$error.need = false
            } else if (value == 2) {
                $scope.needTemplateImage.need = false;
            } else if (value == 3) {
                $scope.PreregistrationForm.stampImg.$error.need = false
                $scope.needTemplateImage.need = false;
            }
        })

        $scope.openPreReg = function (mode) {
            if (mode == 1) {
                let newTab = $window.open("", "_blank");
                newTab.location = "/yaltyadmin738/preregistrations/" + $scope.companyPreRegistrationId;
            } else if (mode == 2) {
                let newTab = $window.open("", "_blank");
                newTab.location = "/yaltyadmin738/preregisterPartner?preregId=" + $scope.companyPreRegistrationId;
            }
        }

        $scope.createPreregistration = function (form) {
            /*splitTextToRowsForLCPromoImg($scope.compNamePromo,
            470, 40, 1)
            splitTextToRowsForLCPromoImg($scope.compNamePromo,
            470, 40, 2)
            return false*/
            if (!form.$valid) {
                angular.forEach(form.$error, function (field) {
                    angular.forEach(field, function (errorField) {
                        errorField.$setTouched();
                    });
                });
                if (angular.element($document[0].querySelector('input.ng-invalid')).length > 0) {
                    if (form.compName.$invalid) {
                        let element = document.getElementById("compName");
                        element.scrollIntoView();
                    } else if (form.compNamePromo.$invalid) {
                        let element = document.getElementById("compNamePromo");
                        element.scrollIntoView();
                    } else if ($scope.compProfile == null && $scope.LCYIsDemoActive == true) {
                        let element = document.getElementById("selectedCompanyProfile");
                        element.scrollIntoView();
                        //$('html,body').animate({ scrollTop: angular.element($document[0].querySelector('#selectedCompanyProfile'))[0].offsetParent.offsetTop }, "slow");
                    } else {
                        $('html,body').animate({ scrollTop: angular.element($document[0].querySelector('input.ng-invalid'))[0].offsetParent.offsetTop }, "slow");
                        angular.element($document[0].querySelector('input.ng-invalid')).focus();
                    }
                } else if (angular.element($document[0].querySelector('md-select.ng-invalid')).length > 0) {
                    angular.element($document[0].querySelector('md-select.ng-invalid')).focus();
                } else {
                    angular.element($document[0].querySelector('textarea.ng-invalid')).focus();
                }
                return false;
            } else if ($rootScope.logoCropperData.compLogoImg == null) {
                form.logoImage.$error.need = true;
                //$('html,body').animate({ scrollTop: $('div#logoImage').offset().top - 150 }, "slow");
                scrollToElement('logoDiv')
                return false;
            } else if ($rootScope.stampCropperData.stampImg == null && $scope.stampIconType == 2 && $scope.LCYIsDemoActive == true) {
                form.stampImg.$error.need = true;
                $scope.needTemplateImage.need = null;
                $('html,body').animate({ scrollTop: $('div#templateImgContainer').offset().top - 150 }, "slow");
                return false;
            } else if (!$scope.selectedTemplateImg && $scope.LCYIsDemoActive == true && ($scope.stampIconType == 1 || $scope.stampIconType == 3)) {
                $scope.needTemplateImage.need = true;
                $('html,body').animate({ scrollTop: $('div#templateImgContainer').offset().top - 150 }, "slow");
                return false;
            } else if ($scope.splitTextToRowsError == true) {
                const alert = $mdDialog.alert()
                .title('A "Promóciókon megjelenő cég megnevezés" vagy a "Kedvezmény rövid megnevezése" nem megfelelő.')
                .textContent('Az említett két mezőben megadott szövegek közül az egyik túl hosszú ahhoz, hogy 2 sorban kiférjen az "Emailben megjelenő kép"-en (vagy a szöveg valamely szava túl hosszú ahhoz, hogy egy sorban kiférjen). Ekkor az "Emailben megjelenő kép"-en nem a mezőben megadott szöveg jelenik meg. Ellenőrizd és rövidítsd az adott szövege(ke)t.')
                .ok('Rendben')
                $mdDialog.show(alert).then(function () {});
            } else {
                const LCYStampIconChoiceId = $scope.getChoiceIdFromStampIconType[$scope.stampIconType]
                let preRegistrationData = {};
                if ($scope.salesPotentialCompanyMainId) {
                    preRegistrationData.salesPotentialCompanyMainId = $scope.salesPotentialCompanyMainId;
                }
                preRegistrationData.preregistrationId = $scope.preregistrationId;
                preRegistrationData.companyName = $scope.compName;
                preRegistrationData.compNamePromo = $scope.compNamePromo;
                preRegistrationData.isCompNamePromoSame = $scope.isCompNamePromoSame;
                preRegistrationData.compLogoImg = $rootScope.logoCropperData.compLogoImg;
                preRegistrationData.taxNumber = $scope.taxNum;
                preRegistrationData.onlinePage = $scope.onlinePage;
                if ($scope.compProfile != null) {
                    preRegistrationData.compProfile = $scope.compProfile.compProfile;
                } else {
                    preRegistrationData.compProfile = $scope.compProfile;
                }
                preRegistrationData.title = $scope.mainContactNameTitle;
                preRegistrationData.lastName = $scope.mainContactNameLastName;
                preRegistrationData.firstName = $scope.mainContactNameFirstName;
                preRegistrationData.email = $scope.mainContactEmail;
                preRegistrationData.contactPhoneNumber = $scope.mainContactPhone;
                preRegistrationData.contactChoiceId = $scope.contactChoiceId;
                preRegistrationData.comments = $scope.comments;
                preRegistrationData.LCYIsDemoActive = $scope.LCYIsDemoActive;
                if ($scope.LCYPromotionName) {
                    preRegistrationData.LCYPromotionName = $scope.LCYPromotionName;
                } else {
                    preRegistrationData.LCYPromotionName = $scope.LCYPromotionName;
                }
                preRegistrationData.LCYCardBackgroundColor = $scope.LCYCardBackgroundColor;
                preRegistrationData.LCYCompNamePromoTextColor = $scope.LCYCompNamePromoTextColor;
                preRegistrationData.LCYPromotionNameTextColor = $scope.LCYPromotionNameTextColor;
                preRegistrationData.LCYDetailedDisplayBackgroundColor = $scope.LCYDetailedDisplayBackgroundColor;
                preRegistrationData.LCYBackButtonColor = $scope.LCYBackButtonColor;
                preRegistrationData.LCYNrOfStamps = $scope.LCYNrOfStamps;
                preRegistrationData.LCYShowBaseStampDesign = $scope.LCYShowBaseStampDesign;
                if($scope.LCYShowBaseStampDesign){
                    preRegistrationData.LCYBaseStampUnstampedFillColor = $scope.LCYBaseStampUnstampedFillColor;
                    preRegistrationData.LCYBaseStampUnstampedLineWeight = $scope.LCYBaseStampUnstampedLineWeight;
                    preRegistrationData.LCYBaseStampUnstampedLineColor = $scope.LCYBaseStampUnstampedLineColor;
                    preRegistrationData.LCYBaseStampStampedFillColor = $scope.LCYBaseStampStampedFillColor;
                    preRegistrationData.LCYBaseStampStampedLineWeight = $scope.LCYBaseStampStampedLineWeight;
                    preRegistrationData.LCYBaseStampStampedLineColor = $scope.LCYBaseStampStampedLineColor;
                } else {
                    preRegistrationData.LCYBaseStampUnstampedFillColor = null;
                    preRegistrationData.LCYBaseStampUnstampedLineWeight = null;
                    preRegistrationData.LCYBaseStampUnstampedLineColor = null;
                    preRegistrationData.LCYBaseStampStampedFillColor = null;
                    preRegistrationData.LCYBaseStampStampedLineWeight = null;
                    preRegistrationData.LCYBaseStampStampedLineColor = null;
                }
                preRegistrationData.LCYShowStampIcon = $scope.LCYShowStampIcon;
                preRegistrationData.LCYStampTextPlaceholderColor = $scope.LCYStampTextPlaceholderColor;
                preRegistrationData.LCYStampCurrentCountTextColor = $scope.LCYStampCurrentCountTextColor;
                preRegistrationData.LCYIsPromotionNameBasedOnCompProfile = $scope.LCYIsPromotionNameBasedOnCompProfile;
                preRegistrationData.LCYStampIconChoiceId = LCYStampIconChoiceId;
                if (LCYStampIconChoiceId == 1 || LCYStampIconChoiceId == 2) {
                    preRegistrationData.LCYStampIconURL = $scope.selectedTemplateImg.stampIconURLPrefix;
                } else {
                    if ($rootScope.stampCropperData && $rootScope.stampCropperData.stampImg && $rootScope.unstampCropperData && $rootScope.unstampCropperData.unstampImg) {
                        if ($rootScope.stampCropperData.stampImg.includes('/imgs/')) {
                            preRegistrationData.LCYStampIconURL = $rootScope.stampCropperData.stampImg.split("_stamped.png")[0];
                        } else if ($rootScope.stampCropperData.originalStampImg.includes('/imgs/')) {
                            preRegistrationData.LCYStampIconURL = $rootScope.stampCropperData.originalStampImg.split("_stamped.png")[0];
                        } else {
                            preRegistrationData.LCYStampIconURL = $rootScope.stampCropperData.originalStampImg;
                        }
                        if ($rootScope.unstampCropperData.unstampImg.includes('/imgs/')) {
                            preRegistrationData.LCYUnstampIconURL = $rootScope.unstampCropperData.unstampImg.split("_unstamped.png")[0];
                        } else if ($rootScope.unstampCropperData.originalUnstampImg.includes('/imgs/')) {
                            preRegistrationData.LCYUnstampIconURL = $rootScope.unstampCropperData.originalUnstampImg.split("_unstamped.png")[0];
                        } else {
                            preRegistrationData.LCYUnstampIconURL = $rootScope.unstampCropperData.originalUnstampImg;
                        }
                    }
                }
                preRegistrationData.registrationType = $scope.registrationType;
                LCCtxToSend.clearRect(0, 0, LCYOriginalWidth, LCYOriginalHeight)
                LCCtxToSend.fillStyle = "white";
                LCCtxToSend.fillRect(0, 0, LCYOriginalWidth, LCYOriginalHeight);
                LCCtxToSend.drawImage(LCSourceCanvas, 0, 0, LCYOriginalWidth, LCYOriginalHeight);
                preRegistrationData.LCPromoImg = LCCanvasToSend.toDataURL('image/png');
                if (document.getElementById('transitionOverlay')) {
                    document.getElementById('transitionOverlay').style.display = "block";
                }
                $http.post(API_PREFIX.url + "/yaltyadmin/preregisterPartner", preRegistrationData).then(function (result) {
                    if (document.getElementById('transitionOverlay')) {
                        document.getElementById('transitionOverlay').style.display = "none";
                    }
                    if (result.data.errorCode == 0 && result.data.preRegId) {
                        $state.go("adminPages.preregistrations", { preregistrationId: result.data.preRegId })
                    } else if (result.data.errorCode == 1) {
                        const alert = $mdDialog.alert()
                            .title("Sikertelen művelet")
                            .textContent("Adatbázis művelet során váratlan hiba lépett fel, kérjük ismételje meg a műveletet.")
                            .ok('Rendben')
                        $mdDialog.show(alert).then(function () {
                            $scope.sendButtonDisabled = false;
                        });
                    } else if (result.data.errorCode == 2) {
                        const alert = $mdDialog.alert()
                            .title("Sikertelen művelet")
                            .textContent("A művelet elvégzéséhez nincs jogosultsága. Ezért kiléptetjük az oldalról.")
                            .ok('Rendben')
                        $mdDialog.show(alert).then(function () {
                            $scope.sendButtonDisabled = false;
                            $state.go('adminLogin');
                        });
                    } else if (result.data.errorCode == 3) {
                        $scope.companyPreRegistrationId = result.data.companyPreRegistrationId;
                        var confirm = $mdDialog.confirm()
                            .textContent("A potenciális partnerhez már létezik előregisztráció. A Listázás gomb megnyomására listázzuk neked. Ha szerkeszteni szeretnéd, nyomd meg a Szerkesztés gombot.")
                            .ok('Listázás')
                            .cancel('Szerkesztés');
                        $mdDialog.show(confirm).then(function () {
                            $scope.openPreReg(1);
                        }, function () {
                            $scope.openPreReg(2);
                        });
                    }
                })
            }
        }
    })

    .controller('PreregistrationsController', function ($rootScope, $scope, $filter, $state, $stateParams, $http, $window, API_PREFIX, $mdDialog, $mdToast, $cookies) {
        function setListHeight() {
            setTimeout(function () {
                /*if ($scope.companylist != undefined && $scope.companylist.length != 0) {*/
                let currentStateHeight = document.getElementById('currentStateDiv').offsetHeight;
                let infoHeight = document.getElementById('infoDiv').offsetHeight;
                let windowsHeight = window.innerHeight - currentStateHeight - infoHeight - 70;
                $scope.listHeight = { 'height': windowsHeight + 'px' };
            }, 100);
        }

        setListHeight();
        window.onresize = function (event) {
            setListHeight();
        };

        $scope.choices = [
            { id: 0, text: "-" },
            { id: 1, text: "Érdeklődöm a szolgáltatás iránt, visszahívást kérek." },
            { id: 2, text: "Az alábbi kérdésemre szeretnék választ kapni." },
            { id: 3, text: "Használnám a szolgáltatást, regisztrálok." }
        ]
        $scope.options = [{
            "optionId": 1,
            "optionName": "Magázás"
        },
        {
            "optionId": 2,
            "optionName": "Tegezés"
        }];

        $scope.emailSendType = 1;

        $scope.optionExample = $scope.options[0];

        $scope.createdTimestamp = null;
        $scope.checkAll = true;
        $scope.checkAllStatus = false;
        $scope.isLinkGeneratorWindow = false;
        let registrationType = ($scope.adminUserType == 'executive' ? ['publicWeb'/*, 'yaltyAdmin'*/] : ['yaltyAdmin']);
        $scope.registrationType = ($scope.adminUserType == 'executive' ? ['publicWeb'/*, 'yaltyAdmin'*/] : ['yaltyAdmin']);
        let status = [0];
        $scope.status = [0];
        $scope.preregistrations = [];
        $scope.allRegistrationType = [
            { value: "'publicWeb'", name: "Partneri kapcsolatfelvétel (publikus kapcsolat oldalról)" },
            { value: "'yaltyAdmin'", name: "Yalty admin előregisztráció" },
        ]
        $scope.allStatus = [
            { value: 0, name: "Új, kezelésre vár" },
            { value: 1, name: "A partneri információ kérésre telefonon vagy emailben válaszoltunk" },
            { value: 5, name: "A regisztrációs link emailben kiküldve, partneri regisztrációra vár" },
            { value: 6, name: "A regisztrációs link FB Messengeren kiküldve, partneri regisztrációra vár" },
            { value: 10, name: "A partner sikeresen regisztrált" }
        ]
        $scope.setAllOptions = function (checkValue, array, model) {
            if (checkValue == true) {
                if (model == "registrationType") {
                    for (let i = 0; i < array.length; i++) {
                        registrationType.push(array[i].value.split("'")[1]);
                    }
                    $scope.registrationType = registrationType;
                } else {
                    let status = [];
                    $scope.status = [];
                    for (let i = 0; i < array.length; i++) {
                        status.push(array[i].value);
                    }
                    $scope.status = status;
                }
            } else {
                if (model == "registrationType") {
                    registrationType = [];
                    $scope.registrationType = [];
                } else {
                    status = [];
                    $scope.status = [];
                }
            }
        }

        $scope.$watch('registrationType', function (value) {
            if (value) {
                if ($scope.registrationType.length == $scope.allRegistrationType.length) {
                    $scope.checkAll = true;
                } else {
                    $scope.checkAll = false;
                }
            }
        })
        $scope.$watch('status', function (value) {
            if (value) {
                if ($scope.status.length == $scope.allStatus.length) {
                    $scope.checkAllStatus = true;
                } else {
                    $scope.checkAllStatus = false;
                }
            }
        })
        $scope.refreshList = function () {
            let filters = {};
            if ($scope.preregistrationId) {
                filters.id = $scope.preregistrationId;
            }
            if ($scope.registrationType.length != 0) {
                filters.registrationType = $scope.registrationType;
            }
            if ($scope.createdTimestamp) {
                filters.createdTimestamp = $scope.createdTimestamp;
            }
            if ($scope.status.length != 0) {
                filters.status = $scope.status;
            }
            return $http.post(API_PREFIX.url + '/yaltyadmin/getPartnerPreregistrationList', filters).then(function (result) {
                if (result.data.errorCode == 0) {
                    $scope.preregistrations = result.data.partnerPreregistrationList;
                    for (let data of $scope.preregistrations) {
                        if (data.registrationLinkSentByEmailTimestamp) {
                            let replaced = data.registrationLinkSentByEmailTimestamp.replace(/\.[0-9]{7}/g, "");
                            replaced = replaced.replace(/[#]/g, "\n");
                            data.registrationLinkSentByEmailTimestamp = replaced;
                        }
                        if (data.registrationLinkSentByFBMSGRTimestamp) {
                            let replaced = data.registrationLinkSentByFBMSGRTimestamp.replace(/\.[0-9]{7}/g, "");
                            replaced = replaced.replace(/[#]/g, "\n");
                            data.registrationLinkSentByFBMSGRTimestamp = replaced;
                        }
                        for (let stat of $scope.allStatus) {
                            if (data.status == stat.value) {
                                data.status = stat.name;
                            }
                        }
                        for (let type of $scope.allRegistrationType) {
                            if (data.registrationType == type.value.split("'")[1]) {
                                data.registrationType = type.name;
                            }
                        }
                    }
                } else if (result.data.errorCode == 1) {
                    const alert = $mdDialog.alert()
                        .title("Sikertelen művelet")
                        .textContent("Adatbázis művelet során váratlan hiba lépett fel, kérjük ismételje meg a műveletet.")
                        .ok('Rendben')
                    $mdDialog.show(alert).then(function () {
                        $scope.sendButtonDisabled = false;
                    });
                } else if (result.data.errorCode == 2) {
                    const alert = $mdDialog.alert()
                        .title("Sikertelen művelet")
                        .textContent("A művelet elvégzéséhez nincs jogosultsága. Ezért kiléptetjük az oldalról.")
                        .ok('Rendben')
                    $mdDialog.show(alert).then(function () {
                        $rootScope.isDataSheetModified = false;
                        $scope.sendButtonDisabled = false;
                        $state.go('adminLogin');
                    });
                }
            });
        }
        $scope.previewEmail = function (id, email, title, firstName, lastName, YLCFBMLSPImgURL, promoName, freePackageSubscriptionExpLimited, freePackageSubscriptionExpTimestamp) {
            if (email) {
                $scope.isEmailSendingWindow = true
                $scope.formal = true
                $scope.to = email
                $scope.preregId = id
                $scope.freePackageSubscriptionExpLimited = freePackageSubscriptionExpLimited;
                $scope.freePackageSubscriptionExpTimestamp = freePackageSubscriptionExpTimestamp;
                $scope.freePackageExpired = 0;
                if ($scope.freePackageSubscriptionExpTimestamp != null) {
                    $scope.freePackageSubscriptionExpTimestamp = new Date($scope.freePackageSubscriptionExpTimestamp);
                    if ($scope.freePackageSubscriptionExpTimestamp < new Date()) {
                        $scope.freePackageExpired = 1;
                    }
                } else if ($scope.freePackageSubscriptionExpLimited == true) {
                    let date = new Date();
                    $scope.freePackageSubscriptionExpTimestampLocal = new Date(date);
                    $scope.freePackageSubscriptionExpTimestampLocal.setDate($scope.freePackageSubscriptionExpTimestampLocal.getDate() + 30);
                }
                $scope.actualYearForCopyright = new Date().getFullYear();
                $scope.PARTNERUSER_LAST_AND_FIRST_NAME = (!firstName || !lastName) ? promoName : title ? `${title} ${lastName} ${firstName}` : `${lastName} ${firstName}`
                $scope.COMPANY_PREREG_YLC_PROMO_IMG_URL = YLCFBMLSPImgURL + '?preview=true'
                $scope.YADMIN_LAST_AND_FIRST_NAME = $cookies.get('adminUserName');
                $scope.YADMIN_TELEPHONE_NUMBER = ($cookies.get('adminPhoneNumber') != "null" && $cookies.get('adminPhoneNumber') != null) ? $cookies.get('adminPhoneNumber') : "";
                $scope.YADMIN_EMAIL_ADDRESS = $cookies.get('adminUserEmail');
                $scope.COMPANY_PROMO_NAME = promoName;
                if ($scope.freePackageExpired == 1) {
                    $scope.FREE_PACKAGE_STRING_FORMAL = '';
                    $scope.FREE_PACKAGE_STRING_INFORMAL = '';
                } else if ($scope.freePackageSubscriptionExpLimited == false) {
                    $scope.FREE_PACKAGE_STRING_FORMAL = '<b>Teljesen működő alapcsomagunk teljesen ingyenes. És az is marad.</b><br><br>';
                    $scope.FREE_PACKAGE_STRING_INFORMAL = '<b>Teljesen működő alapcsomagunk teljesen ingyenes. És az is marad.</b><br><br>';
                } else {
                    let displayDate = $scope.freePackageSubscriptionExpTimestamp != null ?
                        $scope.freePackageSubscriptionExpTimestamp : $scope.freePackageSubscriptionExpTimestampLocal
                    displayDate = displayDate.toLocaleString('hu-HU', { year: 'numeric', month: 'long', day: 'numeric' })
                    displayDate = displayDate.substring(0, displayDate.length - 1);
                    $scope.FREE_PACKAGE_STRING_FORMAL = '<div style="background-color: #DDF0FC; padding: 10px 10px; border-radius: 5px;"><div>' +
                        '<b>Vírushelyzet - Üzletfejlesztési együttműködési csomagajánlatunk</b><br><br>' +
                        '<p style="margin: 0">A vírusválság mindannyiunk vállalkozását érinti. A Yalty-nál hiszünk a közös építkezésben, '+ 
                          'a fair megoldásokban és abban, hogy ezt az időszakot mindannyiunknak érdemes az építkezésre, üzletfejlesztésre használnunk.</p><br>'+
                        '<p style="margin: 0">A vírushelyzetre való tekintettel olyan együttműködést ajánlunk Önnek, amivel most <b>költségmentesen bevezetheti '+ 
                          'és ezután korlátlan ideig használhatja teljesen működő induló csomagunkat.</b></p><br>'+
                        '<p style="margin: 0"><b>Miért jó ez nekünk?</b> Hiszünk abban, hogy szeretni fogja szolgáltatásunkat és meglátja majd a lehetőséget '+
                          'értéknövelt csomagjainkban és később, ahogy és amikor a vészhelyzet után már az Ön vállalkozása '+
                          'is újra tágabb anyagi lehetőségekkel rendelkezik egy magasabb csomagra vált majd. Ez persze opcionális, Ön dönti el.</p><br>'+
                        '<div style="color: #ED7D31"><b>Ajánlatunk ' + displayDate + '-ig él.</b></div><br>' +
                        '<div style="color: #4E525C; font-size: 14px;">Amennyiben ezen időn belül regisztrál és aktiválja a hűségkártyáját, <b>az induló csomagunkat '+
                        'ezután már teljeskörűen</b> és <b>korlátlan ideig</b> tovább használhatja költségmentesen. ' +
                      'Fizetős csomagjainkra természetesen az ajánlati időszak után is tud regisztrálni.</div></div></div><br>';

                    $scope.FREE_PACKAGE_STRING_INFORMAL = '<div style="background-color: #DDF0FC; padding: 10px 10px; border-radius: 5px;"><div>' +
                        '<b>Vírushelyzet - Üzletfejlesztési együttműködési csomagajánlatunk</b><br><br>'+
                        '<p style="margin: 0">A vírusválság mindannyiunk vállalkozását érinti. A Yalty-nál hiszünk a közös építkezésben, '+
                        'a fair megoldásokban és abban, hogy ezt az időszakot mindannyiunknak érdemes az építkezésre, üzletfejlesztésre használnunk.</p><br>'+
                        '<p style="margin: 0">A vírushelyzetre való tekintettel olyan együttműködést ajánlunk Nektek, amivel most <b>költségmentesen bevezethetitek '+
                        'és ezután korlátlan ideig használhatjátok teljesen működő induló csomagunkat.</b></p><br>'+
                        '<p style="margin: 0"><b>Miért jó ez nekünk?</b> Hiszünk abban, hogy szeretni fogjátok szolgáltatásunkat és meglátjátok majd a lehetőséget '+
                        'értéknövelt csomagjainkban és később, ahogy és amikor a vészhelyzet után már a Ti vállalkozásotok '+
                        'is újra tágabb anyagi lehetőségekkel rendelkezik egy magasabb csomagra váltotok majd. Ez persze opcionális, Ti döntitek el.</p><br>'+
                        '<div style="color: #ED7D31"><b>Ajánlatunk ' + displayDate + '-ig él.</b></div><br>' +
                        '<div style="color: #4E525C; font-size: 14px;">Amennyiben ezen időn belül regisztrálsz és aktiválod hűségkártyádat, <b>az induló csomagunkat '+
                        'ezután már teljeskörűen</b> és <b>korlátlan ideig</b> tovább használhatod költségmentesen. '+
                      'Fizetős csomagjainkra természetesen az ajánlati időszak után is tudsz regisztrálni.</div></div></div><br>';
                    /*$scope.FREE_PACKAGE_STRING_FORMAL = '<div style="background-color: #DDF0FC; padding: 10px 10px; border-radius: 5px;"><div>Bevezetési kedvezményként ' +
                        '<b>Ön most a következő 30 napban regisztrálhat teljesen működő alapcsomagunkra, teljesen ingyen. </b> <div style="color: #ED7D31;">' +
                        '<b>Ajánlatunk ' + displayDate + '-ig él.</b></div> ' +
                        '<div style="color: #4E525C; font-size: 14px;">Amennyiben ezen időn belül regisztrál és aktiválja a ' +
                        'hűségkártyáját, <b>az ingyenes csomagot ezután teljeskörűen</b> és <b>korlátlan ideig</b> tovább használhatja. ' +
                        'Fizetős csomagjainkra természetesen a kedvezményes időszak után is tud regisztrálni.</div></div></div><br>';

                    $scope.FREE_PACKAGE_STRING_INFORMAL = '<div style="background-color: #DDF0FC; padding: 10px 10px; border-radius: 5px;"><div>Bevezetési kedvezményként ' +
                        '<b>most a következő 30 napban regisztrálhatsz teljesen működő alapcsomagunkra, teljesen ingyen. </b> <div style="color: #ED7D31">' +
                        '<b>Ajánlatunk ' + displayDate + '-ig él.</b></div> ' +
                        '<div style="color: #4E525C; font-size: 14px;">Amennyiben ezen időn belül regisztrálsz és aktiválod ' +
                        'hűségkártyádat, <b>az ingyenes csomagot ezután teljeskörűen</b> és <b>korlátlan ideig</b> tovább használhatod. ' +
                        'Fizetős csomagjainkra természetesen a kedvezményes időszak után is tudsz regisztrálni.</div></div></div><br>';*/
                }
            } else {
                var alert = $mdDialog.alert()
                    .title("Sikertelen művelet")
                    .textContent('A meghívót a kapcsolattartó email címére küldjük ki. Szerkeszd az előregisztrációt és add meg ott először a kapcsolattartó email címét.')
                    .ok('Rendben')
                $mdDialog.show(alert).then(function () {

                })
            }
        }

        $scope.sendEmail = function () {
            if ($scope.to) {
                if ($scope.emailSendingForm.contactDate.$invalid) {
                    $scope.emailSendingForm.contactDate.$touched = true
                } else {
                    var isFormalOrInformal = $scope.optionExample.optionId == 1 ? 0 : 1
                    var FREE_PACKAGE_STRING = "";
                    if (isFormalOrInformal == 0) {
                        FREE_PACKAGE_STRING = $scope.FREE_PACKAGE_STRING_FORMAL;
                    } else if (isFormalOrInformal == 1) {
                        FREE_PACKAGE_STRING = $scope.FREE_PACKAGE_STRING_INFORMAL;
                    }
                    var confirm = $mdDialog.confirm()
                        .title(' A potenciális partner megadott címére (' + $scope.to + ') kiküldünk egy információs levelet a regisztrációs linkkel. Biztosan kiküldöd?')
                        .ok('KÜLDÉS')
                        .cancel('MÉGSEM');
                    $mdDialog.show(confirm).then(function () {
                        let request = {
                            id: $scope.preregId,
                            adminPhoneNumber: $scope.YADMIN_TELEPHONE_NUMBER,
                            FREE_PACKAGE_STRING: FREE_PACKAGE_STRING,
                            contactDateString: $scope.emailSendingForm.contactDate.$modelValue,
                            isFormalOrInformal: isFormalOrInformal,
                            emailSendType: $scope.emailSendType
                        };
                        $http.post(API_PREFIX.url + "/yaltyadmin/sendRegistrationLink", request).then(function (result) {
                            if (result.data.errorCode == 0) {
                                var alert = $mdDialog.alert()
                                    .title("Az információs emailt sikeresen kiküldtük a megadott címre!")
                                    .ok('Rendben')
                                $mdDialog.show(alert).then(function () {
                                    $scope.refreshList();
                                    $scope.isEmailSendingWindow = false
                                    $scope.contactDate = null
                                    $scope.emailSendingForm.contactDate.$touched = false
                                    //document.getElementById("emailSendingForm").reset();
                                    $scope.optionExample = $scope.options[0];
                                    $scope.emailSendType = 1;
                                })
                            }
                        })
                    }, function () {
                        console.log("Send cancelled");
                    })
                }
            } else {
                var alert = $mdDialog.alert()
                    .title("Sikertelen művelet")
                    .textContent('A meghívót a kapcsolattartó email címére küldjük ki. Szerkeszd az előregisztrációt és add meg ott először a kapcsolattartó email címét.')
                    .ok('Rendben')
                $mdDialog.show(alert).then(function () {
                    $scope.isEmailSendingWindow = false
                })
            }
        }
        $scope.showLinkGeneratorWindow = function (prereg) {
            $scope.isLinkGeneratorWindow = true;
            $scope.selectedPreregistration = prereg;
        }
        $scope.generateMessage = function (prereg) {
            let response = {};
            response.id = prereg.id;
            $http.post(API_PREFIX.url + "/yaltyadmin/getRegistrationLink", response).then(function (result) {
                if (result.data.errorCode == 0) {
                    $scope.messageGenerated = true;
                    $scope.messageToCompName = prereg.compName;
                    $scope.generatedRegLink = result.data.link;
                }
            })
        }
        $scope.copyLink = function () {
            var range = document.createRange();
            range.selectNode(document.getElementById("generatedMessage"));
            window.getSelection().removeAllRanges();
            window.getSelection().addRange(range);
            document.execCommand("copy");
            window.getSelection().removeAllRanges()
            $mdToast.show(
                $mdToast.simple()
                    .parent(document.querySelectorAll('#generatedMessageContainer'))
                    .textContent('Az üzenetet a vágólapra másoltuk.')
                    .position('top center')
                    .hideDelay(3000))
                .then(function () { }).catch(function () { });
        }
        $scope.messageSent = function (prereg) {
            var confirm = $mdDialog.confirm()
                .title('Biztos, hogy elküldted az üzenetet? Csak akkor nyomd meg az IGEN gombot, ha a kimásolt üzenetet már tényleg elküldted a partnernek FB Messenger üzenetként.')
                .ok('IGEN')
                .cancel('MÉGSEM');
            $mdDialog.show(confirm).then(function () {
                let response = {};
                response.id = prereg.id;
                $http.post(API_PREFIX.url + "/yaltyadmin/FBMSGRMessageSent", response).then(function (result) {
                    if (result.data.errorCode == 0) {
                        $scope.isLinkGeneratorWindow = false;
                        $scope.messageGenerated = false;
                        $scope.refreshList();
                    }
                })
            }, function () {
                console.log("Send cancelled");
            })
        }

        $scope.contactDates = ["mai", "tegnapi", "tegnapelőtti"];
        for (let i = 3; i < 8; i++) {
            let currentDate = new Date()
            $scope.contactDates.push($filter('date')(currentDate.setDate(currentDate.getDate() - i), "yyyy. MMMM dd-i"))
        }


        $scope.querySearchContactDate = function (query) {
            if (query) {
                var filtered = $scope.contactDates.filter(function (i) {
                    var lowercaseQuery = angular.lowercase(query);
                    return ((i.toLowerCase()).indexOf(lowercaseQuery) === 0);
                })
                if (filtered.length > 0) {
                    return filtered;
                } else {
                    return $scope.contactDates;
                }
            } else {
                return $scope.contactDates;
            }
        }
        $scope.refreshContantDate = () => {
            $scope.CONTACT_DATE_STRING = $scope.emailSendingForm.contactDate.$modelValue
        }
        $scope.cancelLinkGenerate = function () {
            if ($scope.messageGenerated == true) {
                let alert = $mdDialog.confirm()
                    .title('Biztos nem küldted ki az üzenetet?')
                    .textContent('Az üzenetet legeneráltad, de nem erősítetted meg, hogy kiküldted volna azt. FONTOS: ha kiküldted lépj vissza és mindenképp erősítsd meg a kiküldést, különben a priorizált listázásban a cég nem fog megjelenni.')
                    .cancel('Ok, nem küldtem még ki az üzenetet')
                    .ok('Vissza, megerősítem a kiküldést');
                $mdDialog.show(alert).then(function () {

                }, function () {
                    $scope.isLinkGeneratorWindow = false;
                    $scope.messageGenerated = false;
                })
            } else {
                $scope.isLinkGeneratorWindow = false;
                $scope.messageGenerated = false;
            }
        }
        $scope.cancelEmailSending = function () {
            $scope.isEmailSendingWindow = false;
            //document.getElementById("emailSendingForm").reset();
            $scope.contactDate = null
            $scope.emailSendingForm.contactDate.$touched = false
            $scope.optionExample = $scope.options[0];
            $scope.emailSendType = 1;
        }

        $scope.openPreregistration = function (preregId){
            location.href = "/yaltyadmin738/preregisterPartner?preregId=" + preregId;
        }

        $scope.openPreregistrationPreviewNewTab = function (preregId) {
            var prereg = {};
            prereg.preregId = preregId;
            var newTab = $window.open("", "_blank");
            $http.post(API_PREFIX.url + '/yaltyadmin/getYaltyAdminPreregistrationToken', prereg).then(function (result) {
                if (result.data.errorCode == 0) {
                    newTab.location = result.data.link;
                } else {
                    newTab.close();
                    $scope.sendButtonDisabled = false;
                }
            });
        }
        /*$scope.openPromotionImage = function (YLCFBMLSPImgURL) {
            var newTab = $window.open("", "_blank");
            newTab.location = document.location.origin + YLCFBMLSPImgURL;
        }*/
        if ($stateParams.preregistrationId != 0) {
            $scope.preregistrationId = $stateParams.preregistrationId;
            $scope.checkAll = false;
            $scope.checkAllStatus = false;
            let registrationType = [];
            $scope.registrationType = [];
            let status = [];
            $scope.status = [];
        }
        $scope.refreshList();
    })

    .controller('PotCompPortfolioController', function ($scope, $cookies, $http, API_PREFIX, $mdDialog, $window, commonService) {

        function setListHeight() {
            setTimeout(function () {
                if ($scope.companylist != undefined && $scope.companylist.length != 0) {
                    let currentStateHeight = document.getElementById('currentStateDiv').offsetHeight;
                    let infoHeight = document.getElementById('infoDiv').offsetHeight;
                    let windowsHeight = window.innerHeight - currentStateHeight - infoHeight - 70;
                    $scope.listHeight = { 'height': windowsHeight + 'px' };
                } else {
                    $scope.listHeight = { 'height': 90 + 'px' };
                }
            }, 100);
        }

        setListHeight();
        window.onresize = function (event) {
            setListHeight();
        };

        $scope.zeroResult = false;
        $scope.adminUserType = $cookies.get('adminUserType');
        $scope.adminUserId = $cookies.get('adminUserId');
        $scope.adminUserName = $cookies.get('adminUserName');
        $scope.allAdminId = $scope.yaltyAdminUserList;
        $scope.adminId = [];
        if ($scope.adminUserType == 'sales') {
            $scope.checkAllAdminId = false;
            $scope.adminId.push($scope.adminUserId);
        } else {
            $scope.checkAllAdminId = true;
            for (let i = 0; i < $scope.allAdminId.length; i++) {
                $scope.adminId.push($scope.allAdminId[i].id);
            }
        }
        $scope.getSelectedAdminIds = function () {
            if ($scope.adminId == null || $scope.adminId.length == 0) {
                return "YAdmin"
            } else if ($scope.adminId.length != $scope.allAdminId.length) {
                let text = "";
                for (let i = 0; i < $scope.adminId.length; i++) {
                    text += $scope.allAdminId.find(element => element.id == $scope.adminId[i]).name;
                    if ((i + 1) != $scope.adminId.length) {
                        text += ", "
                    }
                }
                return text;
            } else {
                return "Összes";
            }
        };
        $scope.selectAdminId = function () {
            if ($scope.adminId == null || $scope.adminId.length != $scope.allAdminId.length) {
                $scope.checkAllAdminId = false;
            } else {
                $scope.checkAllAdminId = true;
            }
        };
        $scope.allCompanyFilter = [
            { id: 1, name: 'Összes' },
            { id: 5, name: 'Adott időszakban felvett (létrehozott)' },
            { id: 6, name: 'Adott időszakban történt a cégnél a legelső ügyintézés' },
            { id: 7, name: 'Adott időszakban volt kimenő hívás' },
            { id: 10, name: 'Adott időszakban bármilyen változás történt a cégnél' }
        ];
        $scope.companyFilter = 10;
        $scope.allPeriod = [
            { id: 1, name: 'Teljes időszak' },
            { id: 7, name: 'Mai nap' },
            { id: 8, name: 'Tegnapi nap' },
            { id: 2, name: 'Aktuális hét' },
            { id: 3, name: 'Múlt hét' },
            { id: 4, name: 'Aktuális hónap' },
            { id: 5, name: 'Múlt hónap' },
            { id: 6, name: 'Megadott időszak' }
        ];
        $scope.period = 2;
        $scope.allYaltyCity = $scope.yaltyCities.listOfCities;
        for (let i = 0; i < $scope.allYaltyCity.length; i++) {
            $scope.allYaltyCity[i].id = i;
        }
        $scope.checkAllYaltyCity = true;
        $scope.selectedYaltyCities = [];
        for (let i = 0; i < $scope.allYaltyCity.length; i++) {
            $scope.selectedYaltyCities.push($scope.allYaltyCity[i].id);
        }

        $scope.setAllOptions = function (checkValue, array, model) {
            if (checkValue == true) {
                if (model == "selectedYaltyCities") {
                    $scope.selectedYaltyCities = [];
                    for (let i = 0; i < $scope.allYaltyCity.length; i++) {
                        $scope.selectedYaltyCities.push($scope.allYaltyCity[i].id);
                    }
                } else if (model == "adminId") {
                    $scope.adminId = [];
                    for (let i = 0; i < $scope.allAdminId.length; i++) {
                        $scope.adminId.push($scope.allAdminId[i].id);
                    }
                }
            } else {
                if (model == "selectedYaltyCities") {
                    $scope.selectedYaltyCities = [];
                } else if (model == "adminId") {
                    $scope.adminId = [];
                }
            }
        }
        $scope.getSelectedCities = function () {
            if ($scope.selectedYaltyCities == null || $scope.selectedYaltyCities.length == 0) {
                return "Yalty város"
            } else if ($scope.selectedYaltyCities.length != $scope.allYaltyCity.length) {
                let text = "";
                for (let i = 0; i < $scope.selectedYaltyCities.length; i++) {
                    text += $scope.allYaltyCity[$scope.selectedYaltyCities[i]].placeName;
                    if ((i + 1) != $scope.selectedYaltyCities.length) {
                        text += ", "
                    }
                }
                return text;
            } else {
                return "Összes";
            }
        };
        $scope.selectYaltyCity = function () {
            if ($scope.selectedYaltyCities == null || $scope.selectedYaltyCities.length != $scope.allYaltyCity.length) {
                $scope.checkAllYaltyCity = false;
            } else {
                $scope.checkAllYaltyCity = true;
            }
        };
        $scope.selectPeriod = function () {
            if ($scope.period == 1) {
                $scope.fromTimestamp = null;
                $scope.untilTimestamp = null;
            } else if ($scope.period == 2) {
                let startOfWeek = moment().startOf('week').toDate();
                let endOfToday = new Date();
                endOfToday.setHours(23, 59, 59, 999);
                $scope.fromTimestamp = startOfWeek;
                $scope.untilTimestamp = endOfToday;
            } else if ($scope.period == 3) {
                let startOfWeek = moment().subtract(1, 'weeks').startOf('week').toDate();
                let endOfWeek = moment().subtract(1, 'weeks').endOf('week').toDate();
                $scope.fromTimestamp = startOfWeek;
                $scope.untilTimestamp = endOfWeek;
            } else if ($scope.period == 4) {
                let startOfMonth = moment().startOf('month').toDate();
                let endOfToday = new Date();
                endOfToday.setHours(23, 59, 59, 999);
                $scope.fromTimestamp = startOfMonth;
                $scope.untilTimestamp = endOfToday;
            } else if ($scope.period == 5) {
                let startOfMonth = moment().subtract(1, 'month').startOf('month').toDate();
                let endOfMonth = moment().subtract(1, 'month').endOf('month').toDate();
                $scope.fromTimestamp = startOfMonth;
                $scope.untilTimestamp = endOfMonth;
            } else if ($scope.period == 6) {
                $scope.fromTimestamp = null;
                $scope.untilTimestamp = null;
            } else if ($scope.period == 7) {
                let startOfToday = new Date();
                startOfToday.setHours(0, 0, 0, 0);
                let endOfToday = new Date();
                endOfToday.setHours(23, 59, 59, 999);
                $scope.fromTimestamp = startOfToday;
                $scope.untilTimestamp = endOfToday;
            } else if ($scope.period == 8) {
                let startOfYesterday = moment().subtract(1, 'day').startOf('day').toDate();
                let endOfYesterday = moment().subtract(1, 'day').endOf('day').toDate();
                $scope.fromTimestamp = startOfYesterday;
                $scope.untilTimestamp = endOfYesterday;
            }
        }

        $scope.selectPeriod();

        $scope.setTime = function () {
            if ($scope.untilTimestamp != null) {
                let date = new Date($scope.untilTimestamp);
                date.setHours(23, 59, 59, 999);
                $scope.untilTimestamp = date;
            }
        }
        $scope.refreshList = function (PotCompPortfolioForm, isQuickSearch, quickSearchDate) {
            $scope.zeroResult = false;
            if (!isQuickSearch && !PotCompPortfolioForm.$valid) {
                angular.forEach(PotCompPortfolioForm.$error, function (field) {
                    angular.forEach(field, function (errorField) {
                        errorField.$setTouched();
                    });
                });
            } else {
                if (!isQuickSearch && $scope.period == 6 && $scope.fromTimestamp != null && $scope.untilTimestamp != null && new Date($scope.fromTimestamp) > new Date($scope.untilTimestamp)) {
                    var confirm = $mdDialog.confirm()
                        .textContent('A kezdő időszak (Időszak -Tól) nem lehet nagyobb a vég időszaknál (Időszak -Ig)')
                        .ok('OK')
                    $mdDialog.show(confirm).then(function () {

                    }, function () {

                    });
                } else {
                    let filters = {};
                    if (isQuickSearch) {
                        filters.yaltyAdminUserIdArray = []
                        $scope.adminId = [];
                        if ($scope.adminUserType == 'sales') {
                            filters.yaltyAdminUserIdArray.push(parseInt($scope.adminUserId));
                            $scope.checkAllAdminId = false;
                            $scope.adminId.push($scope.adminUserId);
                        } else {
                            for (let i = 0; i < $scope.allAdminId.length; i++) {
                                $scope.checkAllAdminId = true;
                                filters.yaltyAdminUserIdArray.push($scope.allAdminId[i].id)
                                $scope.adminId.push($scope.allAdminId[i].id);
                            }
                        }
                        filters.storeAddrCityArray = []
                        filters.isFilteredByStoreAddrCity = false
                        $scope.checkAllYaltyCity = true;
                        $scope.selectedYaltyCities = [];
                        for (let i = 0; i < $scope.allYaltyCity.length; i++) {
                            $scope.selectedYaltyCities.push($scope.allYaltyCity[i].id);
                        }
                        filters.potentialCompanySelectionType = 10
                        $scope.companyFilter = 10;
                        if (quickSearchDate == 'today') {
                            $scope.period = 7;
                            let startOfToday = new Date();
                            startOfToday.setHours(0, 0, 0, 0)
                            startOfToday.toISOString()
                            filters.modifiedFrom = startOfToday
                            let endOfToday = new Date();
                            endOfToday.setHours(23, 59, 59, 999);
                            endOfToday.toISOString()
                            filters.modifiedUntil = endOfToday
                        } else if (quickSearchDate == 'yesterday') {
                            $scope.period = 8;
                            let startOfYesterday = new Date();
                            startOfYesterday.setDate(startOfYesterday.getDate() - 1)
                            startOfYesterday.setHours(0, 0, 0, 0)
                            startOfYesterday.toISOString()
                            filters.modifiedFrom = startOfYesterday
                            let endOfYesterday = new Date();
                            endOfYesterday.setDate(endOfYesterday.getDate() - 1)
                            endOfYesterday.setHours(23, 59, 59, 999)
                            endOfYesterday.toISOString()
                            filters.modifiedUntil = endOfYesterday;
                        } else if (quickSearchDate == 'currentWeek') {
                            $scope.period = 2;
                            let startOfWeek = moment().startOf('week').toDate();
                            let endOfToday = new Date();
                            endOfToday.setHours(23, 59, 59, 999);
                            filters.modifiedFrom = startOfWeek;
                            filters.modifiedUntil = endOfToday;
                        } else if (quickSearchDate == 'lastWeek') {
                            $scope.period = 3;
                            let startOfWeek = moment().subtract(1, 'weeks').startOf('week').toDate();
                            let endOfWeek = moment().subtract(1, 'weeks').endOf('week').toDate();
                            filters.modifiedFrom = startOfWeek;
                            filters.modifiedUntil = endOfWeek;
                        }
                        $scope.selectPeriod();
                    } else {
                        filters.yaltyAdminUserIdArray = $scope.adminId;
                        filters.storeAddrCityArray = [];
                        if ($scope.selectedYaltyCities.length != $scope.allYaltyCity.length) {
                            filters.isFilteredByStoreAddrCity = true;
                            for (let i = 0; i < $scope.selectedYaltyCities.length; i++) {
                                filters.storeAddrCityArray.push($scope.allYaltyCity[$scope.selectedYaltyCities[i]].placeName);
                            }
                        } else {
                            filters.isFilteredByStoreAddrCity = false;
                        }
                        filters.potentialCompanySelectionType = $scope.companyFilter;
                        if ($scope.companyFilter == 5) {
                            if ($scope.period == 1) {
                                filters.createdFrom = new Date("1970-01-01");
                                let endOfToday = new Date();
                                endOfToday.setHours(23, 59, 59, 999);
                                filters.createdUntil = endOfToday;
                            } else {
                                filters.createdFrom = $scope.fromTimestamp;
                                filters.createdUntil = $scope.untilTimestamp;
                            }
                        } else if ($scope.companyFilter == 6) {
                            if ($scope.period == 1) {
                                filters.firstPartnerContactFrom = new Date("1970-01-01");
                                let endOfToday = new Date();
                                endOfToday.setHours(23, 59, 59, 999);
                                filters.firstPartnerContactUntil = endOfToday;
                            } else {
                                filters.firstPartnerContactFrom = $scope.fromTimestamp;
                                filters.firstPartnerContactUntil = $scope.untilTimestamp;
                            }
                        } else if ($scope.companyFilter == 7) {
                            if ($scope.period == 1) {
                                filters.outgoingCallFrom = new Date("1970-01-01");
                                let endOfToday = new Date();
                                endOfToday.setHours(23, 59, 59, 999);
                                filters.outgoingCallUntil = endOfToday;
                            } else {
                                filters.outgoingCallFrom = $scope.fromTimestamp;
                                filters.outgoingCallUntil = $scope.untilTimestamp;
                            }
                        } else if ($scope.companyFilter == 10) {
                            if ($scope.period == 1) {
                                filters.modifiedFrom = new Date("1970-01-01");
                                let endOfToday = new Date();
                                endOfToday.setHours(23, 59, 59, 999);
                                filters.modifiedUntil = endOfToday;
                            } else {
                                filters.modifiedFrom = $scope.fromTimestamp;
                                filters.modifiedUntil = $scope.untilTimestamp;
                            }
                        }
                    }
                    $scope.checkDate = new Date();
                    $scope.checkToNow = new Date();
                    let numberOfDaysToAdd = -5;
                    $scope.checkDate.setDate($scope.checkDate.getDate() + numberOfDaysToAdd);
                    isQuickSearch ?
                        $scope.quickSearchButtonDisabled = true :
                        $scope.sendButtonDisabled = true;
                    return $http.post(API_PREFIX.url + '/yaltyadmin/getPotentialCompanyPortfolioInfo', filters).then(function (result) {
                        isQuickSearch ?
                            $scope.quickSearchButtonDisabled = false :
                            $scope.sendButtonDisabled = false;
                        if (result.data.errorCode == 0) {
                            resetSelection();
                            if (result.data.potentialCompanySearchResult.length == 0) {
                                $scope.zeroResult = true;
                                $scope.companylist = null;
                                //$scope.showCompanyList = false;
                                setListHeight();
                            } else {
                                for (let i = 0; i < result.data.potentialCompanySearchResult.length; i++) {
                                    if (result.data.potentialCompanySearchResult[i].firstLCYActivatedTimestamp != null) {
                                        result.data.potentialCompanySearchResult[i].saleStatus = '6. Aktiválta a kártyáját'
                                    } else if (result.data.potentialCompanySearchResult[i].firstLCYCreatedTimestamp != null) {
                                        result.data.potentialCompanySearchResult[i].saleStatus = '5. Létrehozta a kártyáját'
                                    } else if (result.data.potentialCompanySearchResult[i].status == 10) {
                                        result.data.potentialCompanySearchResult[i].saleStatus = '4. Beregisztrált'
                                    } else if (result.data.potentialCompanySearchResult[i].registrationLinkSentFirstTimeTimestamp != null && $scope.checkDate > new Date(result.data.potentialCompanySearchResult[i].registrationLinkSentFirstTimeTimestamp)) {
                                        result.data.potentialCompanySearchResult[i].saleStatus = '3. Emailt követő egyeztetés'
                                    } else if (result.data.potentialCompanySearchResult[i].registrationLinkSentFirstTimeTimestamp != null && $scope.checkDate < new Date(result.data.potentialCompanySearchResult[i].registrationLinkSentFirstTimeTimestamp)) {
                                        result.data.potentialCompanySearchResult[i].saleStatus = '2. Email kiküldve 5 napon belül'
                                    } else if (result.data.potentialCompanySearchResult[i].lastOutgoingCallTimestamp != null) {
                                        result.data.potentialCompanySearchResult[i].saleStatus = '1. Első megkeresés folyamatban'
                                    } else {
                                        result.data.potentialCompanySearchResult[i].saleStatus = '0. Még nem kerestük'
                                    }
                                    if (result.data.potentialCompanySearchResult[i].compName == null) {
                                        result.data.potentialCompanySearchResult[i].compName = result.data.potentialCompanySearchResult[i].facebookPageName;
                                    }
                                    if (result.data.potentialCompanySearchResult[i].status == 10) {
                                        result.data.potentialCompanySearchResult[i].activationStringExpTimestamp = null;
                                    }
                                    if(result.data.potentialCompanySearchResult[i].freePackageSubscriptionExpTimestamp != null && new Date("2000-01-01") > new Date(result.data.potentialCompanySearchResult[i].freePackageSubscriptionExpTimestamp)){
                                        result.data.potentialCompanySearchResult[i].freePackageSubscriptionNotEnabled = true;
                                    } else {
                                        result.data.potentialCompanySearchResult[i].freePackageSubscriptionNotEnabled = false;
                                    }
                                    if (result.data.potentialCompanySearchResult[i].sumOfDecisionMakerPerceivedInterestLevelValuesBeforeFirstEmailSent != null && result.data.potentialCompanySearchResult[i].nrOfAnsweredOutgoingCallsBeforeFirstEmailSent > 0) {
                                        result.data.potentialCompanySearchResult[i].avgOfDecisionMakerPerceivedInterestLevelValuesBeforeFirstEmailSent = result.data.potentialCompanySearchResult[i].sumOfDecisionMakerPerceivedInterestLevelValuesBeforeFirstEmailSent / result.data.potentialCompanySearchResult[i].nrOfAnsweredOutgoingCallsBeforeFirstEmailSent;
                                    } else {
                                        result.data.potentialCompanySearchResult[i].avgOfDecisionMakerPerceivedInterestLevelValuesBeforeFirstEmailSent = 0;
                                    }
                                    if (result.data.potentialCompanySearchResult[i].sumOfDecisionMakerPerceivedInterestLevelValuesAfterFirstEmailSent != null && result.data.potentialCompanySearchResult[i].nrOfAnsweredOutgoingCallsAfterFirstEmailSent > 0) {
                                        result.data.potentialCompanySearchResult[i].avgOfDecisionMakerPerceivedInterestLevelValuesAfterFirstEmailSent = result.data.potentialCompanySearchResult[i].sumOfDecisionMakerPerceivedInterestLevelValuesAfterFirstEmailSent / result.data.potentialCompanySearchResult[i].nrOfAnsweredOutgoingCallsAfterFirstEmailSent;
                                    } else {
                                        result.data.potentialCompanySearchResult[i].avgOfDecisionMakerPerceivedInterestLevelValuesAfterFirstEmailSent = 0;
                                    }
                                    if (result.data.potentialCompanySearchResult[i].sumOfNonDecisionMakerPerceivedInterestLevelValuesBeforeFirstEmailSent != null && (result.data.potentialCompanySearchResult[i].nrOfAnsweredOutgoingCallsBeforeFirstEmailSent - result.data.potentialCompanySearchResult[i].nrOfTimesDecisionMakerReachedBeforeFirstEmailSent) > 0) {
                                        result.data.potentialCompanySearchResult[i].avgOfNonDecisionMakerPerceivedInterestLevelValuesBeforeFirstEmailSent = result.data.potentialCompanySearchResult[i].sumOfNonDecisionMakerPerceivedInterestLevelValuesBeforeFirstEmailSent / (result.data.potentialCompanySearchResult[i].nrOfAnsweredOutgoingCallsBeforeFirstEmailSent - result.data.potentialCompanySearchResult[i].nrOfTimesDecisionMakerReachedBeforeFirstEmailSent);
                                    } else {
                                        result.data.potentialCompanySearchResult[i].avgOfNonDecisionMakerPerceivedInterestLevelValuesBeforeFirstEmailSent = 0;
                                    }
                                    if (result.data.potentialCompanySearchResult[i].sumOfNonDecisionMakerPerceivedInterestLevelValuesAfterFirstEmailSent != null && (result.data.potentialCompanySearchResult[i].nrOfAnsweredOutgoingCallsAfterFirstEmailSent - result.data.potentialCompanySearchResult[i].nrOfTimesDecisionMakerReachedAfterFirstEmailSent) > 0) {
                                        result.data.potentialCompanySearchResult[i].avgOfNonDecisionMakerPerceivedInterestLevelValuesAfterFirstEmailSent = result.data.potentialCompanySearchResult[i].sumOfNonDecisionMakerPerceivedInterestLevelValuesAfterFirstEmailSent / (result.data.potentialCompanySearchResult[i].nrOfAnsweredOutgoingCallsAfterFirstEmailSent - result.data.potentialCompanySearchResult[i].nrOfTimesDecisionMakerReachedAfterFirstEmailSent);
                                    } else {
                                        result.data.potentialCompanySearchResult[i].avgOfNonDecisionMakerPerceivedInterestLevelValuesAfterFirstEmailSent = 0;
                                    }
                                    if (result.data.potentialCompanySearchResult[i].firstAdministrationTimestamp != null || result.data.potentialCompanySearchResult[i].registrationLinkSentFirstTimeTimestamp != null) {
                                        let startTimestamp;
                                        if(result.data.potentialCompanySearchResult[i].firstAdministrationTimestamp != null && result.data.potentialCompanySearchResult[i].registrationLinkSentFirstTimeTimestamp == null) {
                                            startTimestamp = result.data.potentialCompanySearchResult[i].firstAdministrationTimestamp;
                                        } else if(result.data.potentialCompanySearchResult[i].firstAdministrationTimestamp == null && result.data.potentialCompanySearchResult[i].registrationLinkSentFirstTimeTimestamp != null) {
                                            startTimestamp = result.data.potentialCompanySearchResult[i].registrationLinkSentFirstTimeTimestamp;
                                        } else if(new Date(result.data.potentialCompanySearchResult[i].firstAdministrationTimestamp) < new Date(result.data.potentialCompanySearchResult[i].registrationLinkSentFirstTimeTimestamp)) {
                                            startTimestamp = result.data.potentialCompanySearchResult[i].firstAdministrationTimestamp;
                                        } else {
                                            startTimestamp = result.data.potentialCompanySearchResult[i].registrationLinkSentFirstTimeTimestamp;
                                        }
                                        if (result.data.potentialCompanySearchResult[i].subscriptionPackageFinalisedTimestampAtRegistration == null || (result.data.potentialCompanySearchResult[i].subscriptionPackagesIdAtRegistration == 1 && result.data.potentialCompanySearchResult[i].firstLCYActivatedTimestamp == null)) {
                                            let date1 = new Date(startTimestamp);
                                            let date2 = new Date();
                                            let diffTime = Math.abs(date2 - date1);
                                            let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                                            result.data.potentialCompanySearchResult[i].saleTime = diffDays;
                                        } else if (result.data.potentialCompanySearchResult[i].subscriptionPackagesIdAtRegistration == 1) {
                                            let date1 = new Date(startTimestamp);
                                            let date2 = new Date(result.data.potentialCompanySearchResult[i].firstLCYActivatedTimestamp);
                                            let diffTime = Math.abs(date2 - date1);
                                            let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                                            result.data.potentialCompanySearchResult[i].saleTime = diffDays;
                                        } else {
                                            let date1 = new Date(startTimestamp);
                                            let date2 = new Date(result.data.potentialCompanySearchResult[i].subscriptionPackageFinalisedTimestampAtRegistration);
                                            let diffTime = Math.abs(date2 - date1);
                                            let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                                            result.data.potentialCompanySearchResult[i].saleTime = diffDays;
                                        }
                                    } else {
                                        result.data.potentialCompanySearchResult[i].saleTime = null;
                                    }
                                }

                                $scope.companylist = result.data.potentialCompanySearchResult;
                                if (isQuickSearch) {
                                    $scope.automaticSort();
                                    $scope.actualSort = 'YAdminAsc'
                                    $scope.secondarySort = 'lastModificationAsc';
                                } else {
                                    $scope.actualSort = 'mainIdDesc'
                                    $scope.secondarySort = null;
                                }
                                $scope.showCompanyList = true;
                                setListHeight();
                            }
                        } else {
                            /*GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/partneri_módosítás_történet/lista_lekérdezés_sikertelen",
                                "HU Partner [PRIVÁT] - Partneri módosítás történet oldal - Sikertelen lista lekérdezés")*/
                            commonService.showDialogWindowWithTitleOnly('Szerver hiba. Próbáld meg egy kicsit később újra.', 'Rendben')
                        }
                    });
                }
            }
        }

        $scope.automaticSort = function () {
            $scope.companylist.sort(function (a, b) {
                if (a.YAdmin.localeCompare(b.YAdmin) == 1) return 1;
                else if (a.YAdmin.localeCompare(b.YAdmin) == -1) return -1;
                else if (a.YAdmin.localeCompare(b.YAdmin) == 0) return a.createdTimestamp > b.createdTimestamp ? 1 : -1;
            });
        }

        /*setTimeout(function () {
            $scope.refreshList($scope.PotCompPortfolioForm);
        }, 100);*/

        $scope.sortByMainId = function (sortType) {
            if (sortType == 'asc') {
                $scope.companylist.sort((a, b) => (a.mainId > b.mainId) ? 1 : -1)
                $scope.actualSort = 'mainIdAsc'
            } else {
                $scope.companylist.sort((a, b) => (a.mainId < b.mainId) ? 1 : -1)
                $scope.actualSort = 'mainIdDesc'
            }
            $scope.secondarySort = null;
        }

        $scope.sortByPotentialValue = function (sortType) {
            if (sortType == 'asc') {
                $scope.companylist.sort((a, b) => (a.potentialValue > b.potentialValue) ? 1 : -1)
                $scope.actualSort = 'potentialValueAsc'
            } else {
                $scope.companylist.sort((a, b) => (a.potentialValue < b.potentialValue) ? 1 : -1)
                $scope.actualSort = 'potentialValueDesc'
            }
            $scope.secondarySort = null;
        }

        $scope.sortByCompanyStatus = function (sortType) {
            if (sortType == 'asc') {
                $scope.companylist.sort((a, b) => (a.companyStatus > b.companyStatus) ? 1 : -1)
                $scope.actualSort = 'compStatusAsc'
            } else {
                $scope.companylist.sort((a, b) => (a.companyStatus < b.companyStatus) ? 1 : -1)
                $scope.actualSort = 'compStatusDesc'
            }
            $scope.secondarySort = null;
        }

        $scope.sortByAddedOn = function (sortType) {
            if (sortType == 'asc') {
                $scope.companylist.sort((a, b) => (a.addedOn > b.addedOn) ? 1 : -1)
                $scope.actualSort = 'addedOnAsc'
            } else {
                $scope.companylist.sort((a, b) => (a.addedOn < b.addedOn) ? 1 : -1)
                $scope.actualSort = 'addedOnDesc'
            }
            $scope.secondarySort = null;
        }

        $scope.sortByLastModification = function (sortType) {
            if (sortType == 'asc') {
                $scope.companylist.sort((a, b) => (a.createdTimestamp > b.createdTimestamp) ? 1 : -1)
                $scope.actualSort = 'lastModificationAsc'
            } else {
                $scope.companylist.sort((a, b) => (a.createdTimestamp < b.createdTimestamp) ? 1 : -1)
                $scope.actualSort = 'lastModificationDesc'
            }
            $scope.secondarySort = null;
        }
        
        $scope.sortByLastModType = function (sortType) {
            if (sortType == 'asc') {
                $scope.companylist.sort(function (a, b) {
                    if (!a.lastModType && b.lastModType) return 1;
                    else if (a.lastModType && !b.lastModType) return -1;
                    else if (a.lastModType === b.lastModType) return 0;
                    else return (a.lastModType > b.lastModType) ? 1 : (b.lastModType > a.lastModType ? -1 : 0);
                });
                $scope.actualSort = 'lastModTypeAsc'
            } else {
                $scope.companylist.sort(function (a, b) {
                    if (!a.lastModType && b.lastModType) return 1;
                    else if (a.lastModType && !b.lastModType) return -1;
                    else if (a.lastModType === b.lastModType) return 0;
                    else return (a.lastModType < b.lastModType) ? 1 : (b.lastModType < a.lastModType ? -1 : 0);
                });
                $scope.actualSort = 'lastModTypeDesc'
            }
            $scope.secondarySort = null;
        }

        $scope.sortByYAdmin = function (sortType) {
            if (sortType == 'asc') {
                $scope.companylist.sort((a, b) => a.YAdmin.localeCompare(b.YAdmin))
                $scope.actualSort = 'YAdminAsc'
            } else {
                $scope.companylist.sort((a, b) => b.YAdmin.localeCompare(a.YAdmin))
                $scope.actualSort = 'YAdminDesc'
            }
            $scope.secondarySort = null;
        }

        $scope.sortByCompName = function (sortType) {
            if (sortType == 'asc') {
                $scope.companylist.sort((a, b) => a.compName.localeCompare(b.compName))
                $scope.actualSort = 'compNameAsc'
            } else {
                $scope.companylist.sort((a, b) => b.compName.localeCompare(a.compName))
                $scope.actualSort = 'compNameDesc'
            }
            $scope.secondarySort = null;
        }

        $scope.sortByCity = function (sortType) {
            if (sortType == 'asc') {
                $scope.companylist.sort((a, b) => a.storeAddrCity.localeCompare(b.storeAddrCity))
                $scope.actualSort = 'cityAsc'
            } else {
                $scope.companylist.sort((a, b) => b.storeAddrCity.localeCompare(a.storeAddrCity))
                $scope.actualSort = 'cityDesc'
            }
            $scope.secondarySort = null;
        }

        $scope.sortByCompProfile = function (sortType) {
            if (sortType == 'asc') {
                $scope.companylist.sort((a, b) => a.compProfile.localeCompare(b.compProfile))
                $scope.actualSort = 'compProfileAsc'
            } else {
                $scope.companylist.sort((a, b) => b.compProfile.localeCompare(a.compProfile))
                $scope.actualSort = 'compProfileDesc'
            }
            $scope.secondarySort = null;
        }

        $scope.sortBySaleTime = function (sortType) {
            if (sortType == 'asc') {
                $scope.companylist.sort(function (a, b) {
                    if (!a.saleTime && b.saleTime) return 1;
                    else if (a.saleTime && !b.saleTime) return -1;
                    else if (a.saleTime === b.saleTime) return 0;
                    else return (a.saleTime > b.saleTime) ? 1 : (b.saleTime > a.saleTime ? -1 : 0);
                });
                $scope.actualSort = 'saleTimeAsc'
            } else {
                $scope.companylist.sort(function (a, b) {
                    if (!a.saleTime && b.saleTime) return 1;
                    else if (a.saleTime && !b.saleTime) return -1;
                    else if (a.saleTime === b.saleTime) return 0;
                    else return (a.saleTime < b.saleTime) ? 1 : (b.saleTime < a.saleTime ? -1 : 0);
                });
                $scope.actualSort = 'saleTimeDesc'
            }
            $scope.secondarySort = null;
        }

        $scope.sortByAnsweredOutgoingCallsBeforeFirstEmailSent = function (sortType) {
            if (sortType == 'asc') {
                $scope.companylist.sort((a, b) => (a.nrOfAnsweredOutgoingCallsBeforeFirstEmailSent > b.nrOfAnsweredOutgoingCallsBeforeFirstEmailSent) ? 1 : -1)
                $scope.actualSort = 'answeredOutgoingCallsBeforeFirstEmailSentAsc'
            } else {
                $scope.companylist.sort((a, b) => (a.nrOfAnsweredOutgoingCallsBeforeFirstEmailSent < b.nrOfAnsweredOutgoingCallsBeforeFirstEmailSent) ? 1 : -1)
                $scope.actualSort = 'answeredOutgoingCallsBeforeFirstEmailSentDesc'
            }
            $scope.secondarySort = null;
        }

        $scope.sortByNotAnsweredOutgoingCallsBeforeFirstEmailSent = function (sortType) {
            if (sortType == 'asc') {
                $scope.companylist.sort((a, b) => (a.nrOfNotAnsweredOutgoingCallsBeforeFirstEmailSent > b.nrOfNotAnsweredOutgoingCallsBeforeFirstEmailSent) ? 1 : -1)
                $scope.actualSort = 'notAnsweredOutgoingCallsBeforeFirstEmailSentAsc'
            } else {
                $scope.companylist.sort((a, b) => (a.nrOfNotAnsweredOutgoingCallsBeforeFirstEmailSent < b.nrOfNotAnsweredOutgoingCallsBeforeFirstEmailSent) ? 1 : -1)
                $scope.actualSort = 'notAnsweredOutgoingCallsBeforeFirstEmailSentDesc'
            }
            $scope.secondarySort = null;
        }

        $scope.sortByAnsweredOutgoingCallsAfterFirstEmailSent = function (sortType) {
            if (sortType == 'asc') {
                $scope.companylist.sort((a, b) => (a.nrOfAnsweredOutgoingCallsAfterFirstEmailSent > b.nrOfAnsweredOutgoingCallsAfterFirstEmailSent) ? 1 : -1)
                $scope.actualSort = 'answeredOutgoingCallsAfterFirstEmailSentAsc'
            } else {
                $scope.companylist.sort((a, b) => (a.nrOfAnsweredOutgoingCallsAfterFirstEmailSent < b.nrOfAnsweredOutgoingCallsAfterFirstEmailSent) ? 1 : -1)
                $scope.actualSort = 'answeredOutgoingCallsAfterFirstEmailSentDesc'
            }
            $scope.secondarySort = null;
        }

        $scope.sortByNotAnsweredOutgoingCallsAfterFirstEmailSent = function (sortType) {
            if (sortType == 'asc') {
                $scope.companylist.sort((a, b) => (a.nrOfNotAnsweredOutgoingCallsAfterFirstEmailSent > b.nrOfNotAnsweredOutgoingCallsAfterFirstEmailSent) ? 1 : -1)
                $scope.actualSort = 'notAnsweredOutgoingCallsAfterFirstEmailSentAsc'
            } else {
                $scope.companylist.sort((a, b) => (a.nrOfNotAnsweredOutgoingCallsAfterFirstEmailSent < b.nrOfNotAnsweredOutgoingCallsAfterFirstEmailSent) ? 1 : -1)
                $scope.actualSort = 'notAnsweredOutgoingCallsAfterFirstEmailSentDesc'
            }
            $scope.secondarySort = null;
        }

        $scope.sortByLastOutgoingCallTimestamp = function (sortType) {
            if (sortType == 'asc') {
                $scope.companylist.sort(function (a, b) {
                    if (!a.lastOutgoingCallTimestamp && b.lastOutgoingCallTimestamp) return 1;
                    else if (a.lastOutgoingCallTimestamp && !b.lastOutgoingCallTimestamp) return -1;
                    else if (a.lastOutgoingCallTimestamp === b.lastOutgoingCallTimestamp) return 0;
                    else return (a.lastOutgoingCallTimestamp > b.lastOutgoingCallTimestamp) ? 1 : (b.lastOutgoingCallTimestamp > a.lastOutgoingCallTimestamp ? -1 : 0);
                });
                $scope.actualSort = 'lastOutgoingCallTimestampAsc'
            } else {
                $scope.companylist.sort(function (a, b) {
                    if (!a.lastOutgoingCallTimestamp && b.lastOutgoingCallTimestamp) return 1;
                    else if (a.lastOutgoingCallTimestamp && !b.lastOutgoingCallTimestamp) return -1;
                    else if (a.lastOutgoingCallTimestamp === b.lastOutgoingCallTimestamp) return 0;
                    else return (a.lastOutgoingCallTimestamp < b.lastOutgoingCallTimestamp) ? 1 : (b.lastOutgoingCallTimestamp < a.lastOutgoingCallTimestamp ? -1 : 0);
                });
                $scope.actualSort = 'lastOutgoingCallTimestampDesc'
            }
            $scope.secondarySort = null;
        }

        $scope.sortByDecisionMakerReachedBeforeFirstEmailSent = function (sortType) {
            if (sortType == 'asc') {
                $scope.companylist.sort(function (a, b) {
                    if (a.nrOfTimesDecisionMakerReachedBeforeFirstEmailSent == null && b.nrOfTimesDecisionMakerReachedBeforeFirstEmailSent != null) return 1;
                    else if (a.nrOfTimesDecisionMakerReachedBeforeFirstEmailSent != null && b.nrOfTimesDecisionMakerReachedBeforeFirstEmailSent == null) return -1;
                    else if (a.nrOfTimesDecisionMakerReachedBeforeFirstEmailSent === b.nrOfTimesDecisionMakerReachedBeforeFirstEmailSent) return 0;
                    else return (a.nrOfTimesDecisionMakerReachedBeforeFirstEmailSent > b.nrOfTimesDecisionMakerReachedBeforeFirstEmailSent) ? 1 : (b.nrOfTimesDecisionMakerReachedBeforeFirstEmailSent > a.nrOfTimesDecisionMakerReachedBeforeFirstEmailSent ? -1 : 0);
                });
                $scope.actualSort = 'decisionMakerReachedBeforeFirstEmailSentAsc'
            } else {
                $scope.companylist.sort(function (a, b) {
                    if (a.nrOfTimesDecisionMakerReachedBeforeFirstEmailSent == null && b.nrOfTimesDecisionMakerReachedBeforeFirstEmailSent != null) return 1;
                    else if (a.nrOfTimesDecisionMakerReachedBeforeFirstEmailSent != null && b.nrOfTimesDecisionMakerReachedBeforeFirstEmailSent == null) return -1;
                    else if (a.nrOfTimesDecisionMakerReachedBeforeFirstEmailSent === b.nrOfTimesDecisionMakerReachedBeforeFirstEmailSent) return 0;
                    else return (a.nrOfTimesDecisionMakerReachedBeforeFirstEmailSent < b.nrOfTimesDecisionMakerReachedBeforeFirstEmailSent) ? 1 : (b.nrOfTimesDecisionMakerReachedBeforeFirstEmailSent < a.nrOfTimesDecisionMakerReachedBeforeFirstEmailSent ? -1 : 0);
                });
                $scope.actualSort = 'decisionMakerReachedBeforeFirstEmailSentDesc'
            }
            $scope.secondarySort = null;
        }

        $scope.sortByDecisionMakerReachedAfterFirstEmailSent = function (sortType) {
            if (sortType == 'asc') {
                $scope.companylist.sort(function (a, b) {
                    if (a.nrOfTimesDecisionMakerReachedAfterFirstEmailSent == null && b.nrOfTimesDecisionMakerReachedAfterFirstEmailSent != null) return 1;
                    else if (a.nrOfTimesDecisionMakerReachedAfterFirstEmailSent != null && b.nrOfTimesDecisionMakerReachedAfterFirstEmailSent == null) return -1;
                    else if (a.nrOfTimesDecisionMakerReachedAfterFirstEmailSent === b.nrOfTimesDecisionMakerReachedAfterFirstEmailSent) return 0;
                    else return (a.nrOfTimesDecisionMakerReachedAfterFirstEmailSent > b.nrOfTimesDecisionMakerReachedAfterFirstEmailSent) ? 1 : (b.nrOfTimesDecisionMakerReachedAfterFirstEmailSent > a.nrOfTimesDecisionMakerReachedAfterFirstEmailSent ? -1 : 0);
                });
                $scope.actualSort = 'decisionMakerReachedAfterFirstEmailSentAsc'
            } else {
                $scope.companylist.sort(function (a, b) {
                    if (a.nrOfTimesDecisionMakerReachedAfterFirstEmailSent == null && b.nrOfTimesDecisionMakerReachedAfterFirstEmailSent != null) return 1;
                    else if (a.nrOfTimesDecisionMakerReachedAfterFirstEmailSent != null && b.nrOfTimesDecisionMakerReachedAfterFirstEmailSent == null) return -1;
                    else if (a.nrOfTimesDecisionMakerReachedAfterFirstEmailSent === b.nrOfTimesDecisionMakerReachedAfterFirstEmailSent) return 0;
                    else return (a.nrOfTimesDecisionMakerReachedAfterFirstEmailSent < b.nrOfTimesDecisionMakerReachedAfterFirstEmailSent) ? 1 : (b.nrOfTimesDecisionMakerReachedAfterFirstEmailSent < a.nrOfTimesDecisionMakerReachedAfterFirstEmailSent ? -1 : 0);
                });
                $scope.actualSort = 'decisionMakerReachedAfterFirstEmailSentDesc'
            }
            $scope.secondarySort = null;
        }

        $scope.sortByRegistrationLinkSentFirstTimeTimestamp = function (sortType) {
            if (sortType == 'asc') {
                $scope.companylist.sort(function (a, b) {
                    if (!a.registrationLinkSentFirstTimeTimestamp && b.registrationLinkSentFirstTimeTimestamp) return 1;
                    else if (a.registrationLinkSentFirstTimeTimestamp && !b.registrationLinkSentFirstTimeTimestamp) return -1;
                    else if (a.registrationLinkSentFirstTimeTimestamp === b.registrationLinkSentFirstTimeTimestamp) return 0;
                    else return (a.registrationLinkSentFirstTimeTimestamp > b.registrationLinkSentFirstTimeTimestamp) ? 1 : (b.registrationLinkSentFirstTimeTimestamp > a.registrationLinkSentFirstTimeTimestamp ? -1 : 0);
                });
                $scope.actualSort = 'registrationLinkSentFirstTimeTimestampAsc'
            } else {
                $scope.companylist.sort(function (a, b) {
                    if (!a.registrationLinkSentFirstTimeTimestamp && b.registrationLinkSentFirstTimeTimestamp) return 1;
                    else if (a.registrationLinkSentFirstTimeTimestamp && !b.registrationLinkSentFirstTimeTimestamp) return -1;
                    else if (a.registrationLinkSentFirstTimeTimestamp === b.registrationLinkSentFirstTimeTimestamp) return 0;
                    else return (a.registrationLinkSentFirstTimeTimestamp < b.registrationLinkSentFirstTimeTimestamp) ? 1 : (b.registrationLinkSentFirstTimeTimestamp < a.registrationLinkSentFirstTimeTimestamp ? -1 : 0);
                });
                $scope.actualSort = 'registrationLinkSentFirstTimeTimestampDesc'
            }
            $scope.secondarySort = null;
        }

        $scope.sortByScore = function (sortType) {
            if (sortType == 'asc') {
                $scope.companylist.sort((a, b) => (a.score > b.score) ? 1 : -1)
                $scope.actualSort = 'scoreAsc'
            } else {
                $scope.companylist.sort((a, b) => (a.score < b.score) ? 1 : -1)
                $scope.actualSort = 'scoreDesc'
            }
            $scope.secondarySort = null;
        }

        $scope.sortByNumberOfEmailViews = function (sortType) {
            if (sortType == 'asc') {
                $scope.companylist.sort((a, b) => (a.numberOfEmailViews > b.numberOfEmailViews) ? 1 : -1)
                $scope.actualSort = 'numberOfEmailViewsAsc'
            } else {
                $scope.companylist.sort((a, b) => (a.numberOfEmailViews < b.numberOfEmailViews) ? 1 : -1)
                $scope.actualSort = 'numberOfEmailViewsDesc'
            }
            $scope.secondarySort = null;
        }

        $scope.sortByNextTODODeadline = function (sortType) {
            if (sortType == 'asc') {
                $scope.companylist.sort(function (a, b) {
                    if (!a.nextTODODeadline && b.nextTODODeadline) return 1;
                    else if (a.nextTODODeadline && !b.nextTODODeadline) return -1;
                    else if (a.nextTODODeadline === b.nextTODODeadline) return 0;
                    else return (a.nextTODODeadline > b.nextTODODeadline) ? 1 : (b.nextTODODeadline > a.nextTODODeadline ? -1 : 0);
                });
                $scope.actualSort = 'nextTODODeadlineAsc'
            } else {
                $scope.companylist.sort(function (a, b) {
                    if (!a.nextTODODeadline && b.nextTODODeadline) return 1;
                    else if (a.nextTODODeadline && !b.nextTODODeadline) return -1;
                    else if (a.nextTODODeadline === b.nextTODODeadline) return 0;
                    else return (a.nextTODODeadline < b.nextTODODeadline) ? 1 : (b.nextTODODeadline < a.nextTODODeadline ? -1 : 0);
                });
                $scope.actualSort = 'nextTODODeadlineDesc'
            }
            $scope.secondarySort = null;
        }

        $scope.sortByActivationStringExpTimestamp = function (sortType) {
            if (sortType == 'asc') {
                $scope.companylist.sort(function (a, b) {
                    if (!a.activationStringExpTimestamp && b.activationStringExpTimestamp) return 1;
                    else if (a.activationStringExpTimestamp && !b.activationStringExpTimestamp) return -1;
                    else if (a.activationStringExpTimestamp === b.activationStringExpTimestamp) return 0;
                    else return (a.activationStringExpTimestamp > b.activationStringExpTimestamp) ? 1 : (b.activationStringExpTimestamp > a.activationStringExpTimestamp ? -1 : 0);
                });
                $scope.actualSort = 'activationStringExpTimestampAsc'
            } else {
                $scope.companylist.sort(function (a, b) {
                    if (!a.activationStringExpTimestamp && b.activationStringExpTimestamp) return 1;
                    else if (a.activationStringExpTimestamp && !b.activationStringExpTimestamp) return -1;
                    else if (a.activationStringExpTimestamp === b.activationStringExpTimestamp) return 0;
                    else return (a.activationStringExpTimestamp < b.activationStringExpTimestamp) ? 1 : (b.activationStringExpTimestamp < a.activationStringExpTimestamp ? -1 : 0);
                });
                $scope.actualSort = 'activationStringExpTimestampDesc'
            }
            $scope.secondarySort = null;
        }

        $scope.sortByFreePackageSubscriptionExpTimestamp = function (sortType) {
            if (sortType == 'asc') {
                $scope.companylist.sort(function (a, b) {
                    if (!a.freePackageSubscriptionExpTimestamp && b.freePackageSubscriptionExpTimestamp) return 1;
                    else if (a.freePackageSubscriptionExpTimestamp && !b.freePackageSubscriptionExpTimestamp) return -1;
                    else if (a.freePackageSubscriptionExpTimestamp === b.freePackageSubscriptionExpTimestamp) return 0;
                    else return (a.freePackageSubscriptionExpTimestamp > b.freePackageSubscriptionExpTimestamp) ? 1 : (b.freePackageSubscriptionExpTimestamp > a.freePackageSubscriptionExpTimestamp ? -1 : 0);
                });
                $scope.actualSort = 'freePackageSubscriptionExpTimestampAsc'
            } else {
                $scope.companylist.sort(function (a, b) {
                    if (!a.freePackageSubscriptionExpTimestamp && b.freePackageSubscriptionExpTimestamp) return 1;
                    else if (a.freePackageSubscriptionExpTimestamp && !b.freePackageSubscriptionExpTimestamp) return -1;
                    else if (a.freePackageSubscriptionExpTimestamp === b.freePackageSubscriptionExpTimestamp) return 0;
                    else return (a.freePackageSubscriptionExpTimestamp < b.freePackageSubscriptionExpTimestamp) ? 1 : (b.freePackageSubscriptionExpTimestamp < a.freePackageSubscriptionExpTimestamp ? -1 : 0);
                });
                $scope.actualSort = 'freePackageSubscriptionExpTimestampDesc'
            }
            $scope.secondarySort = null;
        }

        $scope.sortByPreregStatus = function (sortType) {
            if (sortType == 'asc') {
                $scope.companylist.sort((a, b) => (a.companyPreRegistrationStatusText > b.companyPreRegistrationStatusText) ? 1 : -1)
                $scope.actualSort = 'preregStatusAsc'
            } else {
                $scope.companylist.sort((a, b) => (a.companyPreRegistrationStatusText < b.companyPreRegistrationStatusText) ? 1 : -1)
                $scope.actualSort = 'preregStatusDesc'
            }
            $scope.secondarySort = null;
        }

        $scope.sortBySaleStatus = function (sortType) {
            if (sortType == 'asc') {
                $scope.companylist.sort((a, b) => (a.saleStatus > b.saleStatus) ? 1 : -1)
                $scope.actualSort = 'saleStatusAsc'
            } else {
                $scope.companylist.sort((a, b) => (a.saleStatus < b.saleStatus) ? 1 : -1)
                $scope.actualSort = 'saleStatusDesc'
            }
            $scope.secondarySort = null;
        }

        $scope.sortByAvgOfDecisionMakerPerceivedInterestLevelValuesBeforeFirstEmailSent = function (sortType) {
            if (sortType == 'asc') {
                $scope.companylist.sort((a, b) => (a.avgOfDecisionMakerPerceivedInterestLevelValuesBeforeFirstEmailSent > b.avgOfDecisionMakerPerceivedInterestLevelValuesBeforeFirstEmailSent) ? 1 : -1)
                $scope.actualSort = 'avgOfDecisionMakerPerceivedInterestLevelValuesBeforeFirstEmailSentAsc'
            } else {
                $scope.companylist.sort((a, b) => (a.avgOfDecisionMakerPerceivedInterestLevelValuesBeforeFirstEmailSent < b.avgOfDecisionMakerPerceivedInterestLevelValuesBeforeFirstEmailSent) ? 1 : -1)
                $scope.actualSort = 'avgOfDecisionMakerPerceivedInterestLevelValuesBeforeFirstEmailSentDesc'
            }
            $scope.secondarySort = null;
        }

        $scope.sortByAvgOfDecisionMakerPerceivedInterestLevelValuesAfterFirstEmailSent = function (sortType) {
            if (sortType == 'asc') {
                $scope.companylist.sort((a, b) => (a.avgOfDecisionMakerPerceivedInterestLevelValuesAfterFirstEmailSent > b.avgOfDecisionMakerPerceivedInterestLevelValuesAfterFirstEmailSent) ? 1 : -1)
                $scope.actualSort = 'avgOfDecisionMakerPerceivedInterestLevelValuesAfterFirstEmailSentAsc'
            } else {
                $scope.companylist.sort((a, b) => (a.avgOfDecisionMakerPerceivedInterestLevelValuesAfterFirstEmailSent < b.avgOfDecisionMakerPerceivedInterestLevelValuesAfterFirstEmailSent) ? 1 : -1)
                $scope.actualSort = 'avgOfDecisionMakerPerceivedInterestLevelValuesAfterFirstEmailSentDesc'
            }
            $scope.secondarySort = null;
        }

        $scope.sortByAvgOfNonDecisionMakerPerceivedInterestLevelValuesBeforeFirstEmailSent = function (sortType) {
            if (sortType == 'asc') {
                $scope.companylist.sort((a, b) => (a.avgOfNonDecisionMakerPerceivedInterestLevelValuesBeforeFirstEmailSent > b.avgOfNonDecisionMakerPerceivedInterestLevelValuesBeforeFirstEmailSent) ? 1 : -1)
                $scope.actualSort = 'avgOfNonDecisionMakerPerceivedInterestLevelValuesBeforeFirstEmailSentAsc'
            } else {
                $scope.companylist.sort((a, b) => (a.avgOfNonDecisionMakerPerceivedInterestLevelValuesBeforeFirstEmailSent < b.avgOfNonDecisionMakerPerceivedInterestLevelValuesBeforeFirstEmailSent) ? 1 : -1)
                $scope.actualSort = 'avgOfNonDecisionMakerPerceivedInterestLevelValuesBeforeFirstEmailSentDesc'
            }
            $scope.secondarySort = null;
        }

        $scope.sortByAvgOfNonDecisionMakerPerceivedInterestLevelValuesAfterFirstEmailSent = function (sortType) {
            if (sortType == 'asc') {
                $scope.companylist.sort((a, b) => (a.avgOfNonDecisionMakerPerceivedInterestLevelValuesAfterFirstEmailSent > b.avgOfNonDecisionMakerPerceivedInterestLevelValuesAfterFirstEmailSent) ? 1 : -1)
                $scope.actualSort = 'avgOfNonDecisionMakerPerceivedInterestLevelValuesAfterFirstEmailSentAsc'
            } else {
                $scope.companylist.sort((a, b) => (a.avgOfNonDecisionMakerPerceivedInterestLevelValuesAfterFirstEmailSent < b.avgOfNonDecisionMakerPerceivedInterestLevelValuesAfterFirstEmailSent) ? 1 : -1)
                $scope.actualSort = 'avgOfNonDecisionMakerPerceivedInterestLevelValuesAfterFirstEmailSentDesc'
            }
            $scope.secondarySort = null;
        }

        $scope.openCompanyNewTab = function (mainId) {
            $cookies.put('mainId', mainId);
            var newTab = $window.open("", "_blank");
            newTab.location = "/yaltyadmin738/adminPotentialCompanies";
        }

        var selectedRowsIndexes = [];
        
        
        $scope.selectRow = function(event, rowIndex) {
          if(event.ctrlKey) {
              changeSelectionStatus(rowIndex);
          } else if(event.shiftKey) {
              selectWithShift(rowIndex);
          } else {
              //selectedRowsIndexes = [rowIndex];
              changeSelectionStatus(rowIndex);
          }
        };
        
        function selectWithShift(rowIndex) {
          var lastSelectedRowIndexInSelectedRowsList = selectedRowsIndexes.length - 1;
          var lastSelectedRowIndex = selectedRowsIndexes[lastSelectedRowIndexInSelectedRowsList];
          var selectFromIndex = Math.min(rowIndex, lastSelectedRowIndex);
          var selectToIndex = Math.max(rowIndex, lastSelectedRowIndex);
          selectRows(selectFromIndex, selectToIndex);
        }
        
        function getSelectedRows() {
          var selectedRows = [];
          angular.forEach(selectedRowsIndexes, function(rowIndex) {
            selectedRows.push($scope.companylist[rowIndex]);
          });
          return selectedRows;
        }
        
        function getFirstSelectedRow() {
          var firstSelectedRowIndex = selectedRowsIndexes[0];
          return $scope.companylist[firstSelectedRowIndex];
        }
        
        function selectRows(selectFromIndex, selectToIndex) {
          for(var rowToSelect = selectFromIndex; rowToSelect <= selectToIndex; rowToSelect++) {
            select(rowToSelect);
          }
        }
        
        function changeSelectionStatus(rowIndex) {
          if($scope.isRowSelected(rowIndex)) {
              unselect(rowIndex);
          } else {
              select(rowIndex);
          }
        }
        
        function select(rowIndex) {
          if(!$scope.isRowSelected(rowIndex)) {
              selectedRowsIndexes.push(rowIndex)
          }
        }
        
        function unselect(rowIndex) {
          var rowIndexInSelectedRowsList = selectedRowsIndexes.indexOf(rowIndex);
          var unselectOnlyOneRow = 1;
          selectedRowsIndexes.splice(rowIndexInSelectedRowsList, unselectOnlyOneRow);
        }
        
        function resetSelection() {
          selectedRowsIndexes = [];
        }
        
        $scope.isRowSelected = function(rowIndex) {
          return selectedRowsIndexes.indexOf(rowIndex) > -1;
        };

    })

    
    .controller('ManagingRegisteredPartnersController', function ($scope, $state, $cookies, $http, API_PREFIX, $mdDialog, $window, commonService) {

        function setListHeight() {
            setTimeout(function () {
                if ($scope.companylist != undefined && $scope.companylist.length != 0) {
                    let currentStateHeight = document.getElementById('currentStateDiv').offsetHeight;
                    let infoHeight = document.getElementById('infoDiv').offsetHeight;
                    let windowsHeight = window.innerHeight - currentStateHeight - infoHeight - 70;
                    $scope.listHeight = { 'height': windowsHeight + 'px' };
                } else {
                    $scope.listHeight = { 'height': 50 + 'px' };
                }
            }, 100);
        }

        setListHeight();
        window.onresize = function (event) {
            setListHeight();
        };

        $scope.forms = {};

        function getWeekNumber(date) {
            // Létrehozunk egy új Date objektumot a jelenlegi dátummal
            var currentDate = date
            
            // Beállítjuk a hetek azonosításának kezdetét
            currentDate.setHours(0, 0, 0, 0);
            currentDate.setDate(currentDate.getDate() + 4 - (currentDate.getDay() || 7));
            
            // Számoljuk a napokat az év elejétől
            var yearStart = new Date(currentDate.getFullYear(), 0, 1);
            
            // Számoljuk a napokat az év elejétől és adjuk hozzá az egész hetek számát
            var weekNumber = Math.ceil((((currentDate - yearStart) / 86400000) + 1) / 7);
            
            return weekNumber;
        }

        function getDateWeeksAgo(weeks) {
            var date = new Date();
            date.setDate(date.getDate() - (weeks * 7)); // Csökkentjük a dátumot a megadott számú héttel
            return date;
        }

        Date.prototype.addDays = function(days) {
            var date = new Date(this.valueOf());
            date.setDate(date.getDate() + days);
            return date;
        }

        $scope.setTimeToEndOfDay = function() {
            $scope.extendedSubscription.subscriptionEndTimestamp = setDateToEndOfTheDay(moment($scope.extendedSubscription.subscriptionEndTimestamp).toDate(), true);
            $scope.extendedSubscription.subscriptionEndTimestampInLocalTime = setDateToEndOfTheDay(moment($scope.extendedSubscription.subscriptionEndTimestamp).toDate(), false);
        }

        function setDateToEndOfTheDay(date, isUTC) {
            date.setUTCHours(23)
            date.setUTCMinutes(59)
            date.setUTCSeconds(59)
            date.setUTCMilliseconds(999)
            if(isUTC) {
                date.setHours(date.getHours() - getHungarianOffset(date))
            }
            return date
        }

        function getLastSundayOfMarch(year) {
            var timezoneChangeDate = new Date(year, 2, 31);
            while (timezoneChangeDate.getDay() !== 0) {
                timezoneChangeDate.setDate(timezoneChangeDate.getDate() - 1);
            }
            return timezoneChangeDate.getDate()
        }
        
        function getLastSundayOfOctober(year) {
            var timezoneChangeDate = new Date(year, 9, 31);
            while (timezoneChangeDate.getDay() !== 0) {
                timezoneChangeDate.setDate(timezoneChangeDate.getDate() - 1);
            }
            return timezoneChangeDate.getDate();
        }

        function getHungarianOffset(date) {
            const timezoneChangeSummerMonth = 2
            const timezoneChangeSummerDay = getLastSundayOfMarch(date.getFullYear());
            const timezoneChangeAutumnMonth = 9
            const timezoneChangeAutumnDay = getLastSundayOfOctober(date.getFullYear());
            const resMonth = date.getMonth()
            const resDay = date.getDate()
            let offsetInHour
            if (resMonth > timezoneChangeSummerMonth && resMonth < timezoneChangeAutumnMonth) {
                offsetInHour = 2
            } else if (resMonth == timezoneChangeSummerMonth && resDay >= timezoneChangeSummerDay) {
                offsetInHour = 2
            } else if (resMonth == timezoneChangeAutumnMonth && resDay <= timezoneChangeAutumnDay) {
                offsetInHour = 2
            } else {
                offsetInHour = 1
            }
            return offsetInHour
        }

        $scope.extendSubscription = function (record, extendSubscriptionForm) {
            extendSubscriptionForm.$setUntouched()
            $scope.extendedSubscription.companyId = record.id;
            $scope.extendedSubscription.compName = record.compName;
            $scope.extendedSubscription.previousSubscriptionEndTimestamp = record.subscriptionEndTimestamp;
            $scope.extendedSubscription.subscriptionEndTimestamp = moment(record.subscriptionEndTimestamp).toDate()
            $scope.extendedSubscription.subscriptionEndTimestampInLocalTime = setDateToEndOfTheDay(moment(record.subscriptionEndTimestamp).toDate(), false);
            $scope.extendedSubscription.minDate = moment(record.subscriptionEndTimestamp).toDate();
        }

        $scope.createNewSubscription = function (record, newSubscriptionForm) {
            newSubscriptionForm.$setUntouched()
            $scope.newSubscription.companyId = record.id;
            $scope.newSubscription.compName = record.compName;
            $scope.newSubscription.recurringPaymentEnabled = record.recurringPaymentEnabled;
            $scope.newSubscription.barionPaymentToken = record.barionPaymentToken;
            $scope.newSubscription.barionTraceIdCarriedOver = record.barionTraceIdCarriedOver;
            $scope.newSubscription.minLimitLoyaltyCardYaltyMaxNo = 1;
            $scope.newSubscription.minLimitCompanyStoreMaxNo = 1;
            $scope.newSubscription.minLimitPartnerUserMaxNo = 1;
            $scope.newSubscription.payedTotal = 0;
            $scope.newSubscription.defaultPayedTotal = 0;
            if(record.paymentFrequency != null && record.subscriptionPackagesId != null && record.limitCompanyStoreMaxNo != null && record.limitPartnerUserMaxNo != null) {
                $scope.newSubscription.paymentFrequency = record.paymentFrequency;
                $scope.newSubscription.subscriptionPackagesId = record.subscriptionPackagesId;
                $scope.changeSubscriptionPackagesId();
                $scope.newSubscription.limitCompanyStoreMaxNo = record.limitCompanyStoreMaxNo;
                $scope.limitCompanyStoreMaxNoChanged();
                $scope.newSubscription.limitPartnerUserMaxNo = record.limitPartnerUserMaxNo;
                $scope.limitPartnerUserMaxNoChanged();
                $scope.newSubscription.limitMonthlyPushMessageMaxNo = record.limitMonthlyPushMessageMaxNo;
                $scope.newSubscription.limitMonthlyIndividualPushMessageMaxNo = record.limitMonthlyIndividualPushMessageMaxNo;
                if(record.fundingSource != null) {
                    $scope.newSubscription.fundingSource = record.fundingSource == 'BankCard' ? 1 : 2
                }
            }
            let actualDate = new Date();
            $scope.newSubscription.yaltyPaymentIdPrefix = actualDate.getFullYear().toString() + (actualDate.getMonth() + 1 < 10 ? '0' : '') + (actualDate.getMonth() + 1).toString() + (actualDate.getDate()< 10 ? '0' : '') + (actualDate.getDate()).toString() + '120000000'
        }

        $scope.cancelNewSubscription = function () {
            $scope.newSubscription = {};
        }

        $scope.cancelExtendSubscription = function () {
            $scope.extendedSubscription = {};
        }

        $scope.paymentFrequencyChanged = function () {
            if($scope.newSubscription.paymentFrequency != null && $scope.newSubscription.subscriptionPackagesId != null && $scope.newSubscription.limitCompanyStoreMaxNo != null && $scope.newSubscription.limitPartnerUserMaxNo != null) {
                calculatePrice();
            }
        }

        let calculatePrice = function () {
            if($scope.newSubscription.paymentFrequency == 1) {
                if($scope.newSubscription.subscriptionPackagesId == 5) {
                    let subscriptionPrice = 2990;
                    let additionalPartnerUserPrice = ($scope.newSubscription.limitPartnerUserMaxNo - $scope.newSubscription.minLimitPartnerUserMaxNo) * 990;
                    let additionalCompanyStorePrice = ($scope.newSubscription.limitCompanyStoreMaxNo - $scope.newSubscription.minLimitCompanyStoreMaxNo) * 1490;
                    $scope.newSubscription.payedTotal = Math.round((subscriptionPrice + additionalPartnerUserPrice + additionalCompanyStorePrice) * 1.27);
                    $scope.newSubscription.defaultPayedTotal = $scope.newSubscription.payedTotal;
                } else if($scope.newSubscription.subscriptionPackagesId == 2) {
                    let subscriptionPrice = 4990;
                    let additionalPartnerUserPrice = ($scope.newSubscription.limitPartnerUserMaxNo - $scope.newSubscription.minLimitPartnerUserMaxNo) * 1990;
                    let additionalCompanyStorePrice = ($scope.newSubscription.limitCompanyStoreMaxNo - $scope.newSubscription.minLimitCompanyStoreMaxNo) * 2490;
                    $scope.newSubscription.payedTotal = Math.round((subscriptionPrice + additionalPartnerUserPrice + additionalCompanyStorePrice) * 1.27);
                    $scope.newSubscription.defaultPayedTotal = $scope.newSubscription.payedTotal;
                } else if($scope.newSubscription.subscriptionPackagesId == 3) {
                    let subscriptionPrice = 9990;
                    let additionalPartnerUserPrice = ($scope.newSubscription.limitPartnerUserMaxNo - $scope.newSubscription.minLimitPartnerUserMaxNo) * 1990;
                    let additionalCompanyStorePrice = ($scope.newSubscription.limitCompanyStoreMaxNo - $scope.newSubscription.minLimitCompanyStoreMaxNo) * 7990;
                    $scope.newSubscription.payedTotal = Math.round((subscriptionPrice + additionalPartnerUserPrice + additionalCompanyStorePrice) * 1.27);
                    $scope.newSubscription.defaultPayedTotal = $scope.newSubscription.payedTotal;
                }
            } else {
                if($scope.newSubscription.subscriptionPackagesId == 5) {
                    let subscriptionPrice = 1990;
                    let additionalPartnerUserPrice = ($scope.newSubscription.limitPartnerUserMaxNo - $scope.newSubscription.minLimitPartnerUserMaxNo) * 490;
                    let additionalCompanyStorePrice = ($scope.newSubscription.limitCompanyStoreMaxNo - $scope.newSubscription.minLimitCompanyStoreMaxNo) * 990;
                    $scope.newSubscription.payedTotal = Math.round((subscriptionPrice + additionalPartnerUserPrice + additionalCompanyStorePrice) * 12 * 1.27);
                    $scope.newSubscription.defaultPayedTotal = $scope.newSubscription.payedTotal;
                } else if($scope.newSubscription.subscriptionPackagesId == 2) {
                    let subscriptionPrice = 4350;
                    let additionalPartnerUserPrice = ($scope.newSubscription.limitPartnerUserMaxNo - $scope.newSubscription.minLimitPartnerUserMaxNo) * 990;
                    let additionalCompanyStorePrice = ($scope.newSubscription.limitCompanyStoreMaxNo - $scope.newSubscription.minLimitCompanyStoreMaxNo) * 1990;
                    $scope.newSubscription.payedTotal = Math.round((subscriptionPrice + additionalPartnerUserPrice + additionalCompanyStorePrice) * 12 * 1.27);
                    $scope.newSubscription.defaultPayedTotal = $scope.newSubscription.payedTotal;
                } else if($scope.newSubscription.subscriptionPackagesId == 3) {
                    let subscriptionPrice = 7990;
                    let additionalPartnerUserPrice = ($scope.newSubscription.limitPartnerUserMaxNo - $scope.newSubscription.minLimitPartnerUserMaxNo) * 990;
                    let additionalCompanyStorePrice = ($scope.newSubscription.limitCompanyStoreMaxNo - $scope.newSubscription.minLimitCompanyStoreMaxNo) * 5990;
                    $scope.newSubscription.payedTotal = Math.round((subscriptionPrice + additionalPartnerUserPrice + additionalCompanyStorePrice) * 12 * 1.27);
                    $scope.newSubscription.defaultPayedTotal = $scope.newSubscription.payedTotal;
                }
            }
        }

        $scope.limitPartnerUserMaxNoChanged = function () {
            if($scope.newSubscription.paymentFrequency != null && $scope.newSubscription.subscriptionPackagesId != null && $scope.newSubscription.limitCompanyStoreMaxNo != null && $scope.newSubscription.limitPartnerUserMaxNo != null) {
                calculatePrice();
            }
        }

        $scope.limitCompanyStoreMaxNoChanged = function () {
            if($scope.newSubscription.limitCompanyStoreMaxNo > $scope.newSubscription.minLimitCompanyStoreMaxNo) {
                if($scope.newSubscription.subscriptionPackagesId == 5) {
                    $scope.newSubscription.minLimitPartnerUserMaxNo = 1 + ($scope.newSubscription.limitCompanyStoreMaxNo - $scope.newSubscription.minLimitCompanyStoreMaxNo);
                    $scope.newSubscription.limitPartnerUserMaxNo = $scope.newSubscription.minLimitPartnerUserMaxNo;
                } else if($scope.newSubscription.subscriptionPackagesId == 2) {
                    $scope.newSubscription.minLimitPartnerUserMaxNo = 2 + ($scope.newSubscription.limitCompanyStoreMaxNo - $scope.newSubscription.minLimitCompanyStoreMaxNo);
                    $scope.newSubscription.limitPartnerUserMaxNo = $scope.newSubscription.minLimitPartnerUserMaxNo;
                } else if($scope.newSubscription.subscriptionPackagesId == 3) {
                    $scope.newSubscription.minLimitPartnerUserMaxNo = 3 + ($scope.newSubscription.limitCompanyStoreMaxNo - $scope.newSubscription.minLimitCompanyStoreMaxNo);
                    $scope.newSubscription.limitPartnerUserMaxNo = $scope.newSubscription.minLimitPartnerUserMaxNo;
                }
            } else {
                if($scope.newSubscription.subscriptionPackagesId == 5) {
                    $scope.newSubscription.minLimitPartnerUserMaxNo = 1;
                    $scope.newSubscription.limitPartnerUserMaxNo = $scope.newSubscription.minLimitPartnerUserMaxNo;
                } else if($scope.newSubscription.subscriptionPackagesId == 2) {
                    $scope.newSubscription.minLimitPartnerUserMaxNo = 2;
                    $scope.newSubscription.limitPartnerUserMaxNo = $scope.newSubscription.minLimitPartnerUserMaxNo;
                } else if($scope.newSubscription.subscriptionPackagesId == 3) {
                    $scope.newSubscription.minLimitPartnerUserMaxNo = 3;
                    $scope.newSubscription.limitPartnerUserMaxNo = $scope.newSubscription.minLimitPartnerUserMaxNo;
                }
            }
            if($scope.newSubscription.paymentFrequency != null && $scope.newSubscription.subscriptionPackagesId != null && $scope.newSubscription.limitCompanyStoreMaxNo != null && $scope.newSubscription.limitPartnerUserMaxNo != null) {
                calculatePrice();
            }
        }

        $scope.changeSubscriptionPackagesId = function () {
            if($scope.newSubscription.subscriptionPackagesId == 5) {
                $scope.newSubscription.packageName = 'yalty_start_paid'
                $scope.newSubscription.packageDisplayName = 'Yalty Start'
                $scope.newSubscription.limitLoyaltyCardYaltyMaxNo = 1;
                $scope.newSubscription.minLimitLoyaltyCardYaltyMaxNo = 1;
                $scope.newSubscription.limitCompanyStoreMaxNo = 1;
                $scope.newSubscription.minLimitCompanyStoreMaxNo = 1;
                $scope.newSubscription.limitPartnerUserMaxNo = 1;
                $scope.newSubscription.minLimitPartnerUserMaxNo = 1;
                $scope.newSubscription.limitMonthlyPushMessageMaxNo = 0;
                $scope.newSubscription.limitMonthlyIndividualPushMessageMaxNo = 0;
            } else if($scope.newSubscription.subscriptionPackagesId == 2) {
                $scope.newSubscription.packageName = 'basic'
                $scope.newSubscription.packageDisplayName = 'Design+'
                $scope.newSubscription.limitLoyaltyCardYaltyMaxNo = 1;
                $scope.newSubscription.minLimitLoyaltyCardYaltyMaxNo = 1;
                $scope.newSubscription.limitCompanyStoreMaxNo = 1;
                $scope.newSubscription.minLimitCompanyStoreMaxNo = 1;
                $scope.newSubscription.limitPartnerUserMaxNo = 2;
                $scope.newSubscription.minLimitPartnerUserMaxNo = 2;
                $scope.newSubscription.limitMonthlyPushMessageMaxNo = 0;
                $scope.newSubscription.limitMonthlyIndividualPushMessageMaxNo = 0;
            } else if($scope.newSubscription.subscriptionPackagesId == 3) {
                $scope.newSubscription.packageName = 'premium'
                $scope.newSubscription.packageDisplayName = 'Premium'
                $scope.newSubscription.limitLoyaltyCardYaltyMaxNo = 3;
                $scope.newSubscription.minLimitLoyaltyCardYaltyMaxNo = 3;
                $scope.newSubscription.limitCompanyStoreMaxNo = 1;
                $scope.newSubscription.minLimitCompanyStoreMaxNo = 1;
                $scope.newSubscription.limitPartnerUserMaxNo = 3;
                $scope.newSubscription.minLimitPartnerUserMaxNo = 3;
                $scope.newSubscription.limitMonthlyPushMessageMaxNo = 5;
                $scope.newSubscription.limitMonthlyIndividualPushMessageMaxNo = 100;
            }
            if($scope.newSubscription.paymentFrequency != null && $scope.newSubscription.subscriptionPackagesId != null && $scope.newSubscription.limitCompanyStoreMaxNo != null && $scope.newSubscription.limitPartnerUserMaxNo != null) {
                calculatePrice();
            }
        }

        $scope.extendPartnerSubscription = function (extendSubscriptionForm) {
            if (!extendSubscriptionForm.$valid) {
                angular.forEach(extendSubscriptionForm.$error, function (field) {
                    angular.forEach(field, function (errorField) {
                        errorField.$setTouched();
                    });
                });
                var error = $mdDialog.confirm()
                .title('Hiányos vagy helytelen mező érték')
                .textContent('Kérlek ellenőrizd a megadott értékeket!')
                .ok('Rendben')
                $mdDialog.show(error);
            } else {
                var confirm = $mdDialog.confirm()
                .title('Előfizetés hosszabbításának megerősítése')
                .htmlContent('<p>Szeretnéd a meglévő előfizetést meghosszabbítani az alábbiak szerint?<br><br><b>Cég neve:</b> ' + $scope.extendedSubscription.compName + '<br><b>Cég azonosítója:</b> ' + $scope.extendedSubscription.companyId + '<br><b>Előfizetés eddigi lejárata:</b> ' + moment.tz($scope.extendedSubscription.previousSubscriptionEndTimestamp, 'GMT').format('YYYY-MM-DD HH:mm:ss.SSSSSSS Z') + 
                '<br><b>Előfizetés új lejárata:</b> ' + moment.tz($scope.extendedSubscription.subscriptionEndTimestamp, 'GMT').format('YYYY-MM-DD HH:mm:ss.SSSSSSS Z') + '</p>')
                .ok('IGEN')
                .cancel('MÉGSEM');
                $mdDialog.show(confirm).then(function () {
                    let data = {};
                    data.companyId = $scope.extendedSubscription.companyId;
                    data.previousSubscriptionEndTimestamp = $scope.extendedSubscription.previousSubscriptionEndTimestamp;
                    data.newSubscriptionEndTimestamp = $scope.extendedSubscription.subscriptionEndTimestamp;
                    if($scope.adminUserType == "executive"){
                        $scope.extendSubscriptionInProgress = $scope.extendedSubscription.companyId;
                        $scope.cancelExtendSubscription();
                        return $http.post(API_PREFIX.url + '/yaltyadmin/extendPartnerSubscription', data).then(function (result) {
                            $scope.extendSubscriptionInProgress = null;
                            if (result.data.errorCode == 0) {
                                var message = $mdDialog.confirm()
                                .title('Sikeres előfizetés hosszabbítás!')
                                .ok('Rendben')
                                $mdDialog.show(message).then(function () {
                                    $scope.refreshList($scope.forms.managingRegisteredPartnersForm);
                                });
                            } else {
                                var message = $mdDialog.confirm()
                                .title('Sikertelen előfizetés hosszabbítás')
                                .textContent('Lehetséges, hogy nem a legfrissebb adatok voltak listázva. Frissítsd a listát, majd próbáld később újra.')
                                .ok('Rendben')
                                $mdDialog.show(message).then(function () {
                                    $scope.refreshList($scope.forms.managingRegisteredPartnersForm);
                                });
                            }
                        }, function () {
                            $scope.newSubscriptionInProgress = null;
                            var message = $mdDialog.confirm()
                            .title('Sikertelen előfizetés hosszabbítás')
                            .textContent('Szerver hiba. Próbáld meg egy kicsit később újra.')
                            .ok('Rendben')
                            $mdDialog.show(message);
                        });
                    }   
                }, function () {
                    
                });
            }
        }

        $scope.createNewPartnerSubscription = function (newSubscriptionForm) { 
            if (!newSubscriptionForm.$valid) {
                angular.forEach(newSubscriptionForm.$error, function (field) {
                    angular.forEach(field, function (errorField) {
                        errorField.$setTouched();
                    });
                });
                var error = $mdDialog.confirm()
                .title('Hiányos vagy helytelen mező érték')
                .textContent('Kérlek ellenőrizd a megadott értékeket!')
                .ok('Rendben')
                $mdDialog.show(error);
            } else {
                var confirm = $mdDialog.confirm()
                .title('Új előfizetés megerősítése')
                .htmlContent('<p>Szeretnél új előfizetést rögzíteni az alábbi tranzakciós adatokkal?<br><br><b>Cég neve:</b> ' + $scope.newSubscription.compName + '<br><b>Cég azonosítója:</b> ' + $scope.newSubscription.companyId + '<br><b>Csomag neve:</b> ' + $scope.newSubscription.packageDisplayName + 
                '<br><b>Előfizetés peridódusa:</b> ' + ($scope.newSubscription.paymentFrequency == 1 ? 'Havi' : 'Éves') + '<br><b>Hűségkártyák száma:</b> ' + $scope.newSubscription.limitLoyaltyCardYaltyMaxNo + 
                ' db<br><b>Üzletek száma:</b> ' + $scope.newSubscription.limitCompanyStoreMaxNo + ' db<br><b>Felhasználók száma:</b> ' + $scope.newSubscription.limitPartnerUserMaxNo + 
                ' db<br><b>Havi push üzenetek promociós ügyfeleknek: </b> ' + $scope.newSubscription.limitMonthlyPushMessageMaxNo + ' db<br><b>Havi push üzenetek egyedi ügyfélnek: </b> ' + $scope.newSubscription.limitMonthlyIndividualPushMessageMaxNo + 
                ' db<br><b>Előfizetés bruttó összege:</b> ' + $scope.newSubscription.payedTotal + ' Ft<br><b>Fizetés módja: </b>' + ($scope.newSubscription.fundingSource == 1 ? 'Bankkártyás' : 'Banki átutalás')  + '<br><b>Tranzakció megjegyzése: </b>' + ($scope.newSubscription.etc != null ? $scope.newSubscription.etc : '-')  + '<br><b>Yalty fizetési azonosító prefix: </b> ' + $scope.newSubscription.yaltyPaymentIdPrefix + '</p>')
                .ok('IGEN')
                .cancel('MÉGSEM');
                $mdDialog.show(confirm).then(function () {
                    let data = {};
                    data.companyId = $scope.newSubscription.companyId;
                    data.paymentFrequency = $scope.newSubscription.paymentFrequency;
                    data.packageName = $scope.newSubscription.packageName;
                    data.packageDisplayName = $scope.newSubscription.packageDisplayName;
                    data.subscriptionPackagesId = $scope.newSubscription.subscriptionPackagesId;
                    data.limitLoyaltyCardYaltyMaxNo = $scope.newSubscription.limitLoyaltyCardYaltyMaxNo;
                    data.limitCompanyStoreMaxNo = $scope.newSubscription.limitCompanyStoreMaxNo;
                    data.limitPartnerUserMaxNo = $scope.newSubscription.limitPartnerUserMaxNo;
                    data.limitMonthlyPushMessageMaxNo = $scope.newSubscription.limitMonthlyPushMessageMaxNo;
                    data.limitMonthlyIndividualPushMessageMaxNo = $scope.newSubscription.limitMonthlyIndividualPushMessageMaxNo;
                    data.payedTotal = $scope.newSubscription.payedTotal;
                    data.etc = $scope.newSubscription.etc;
                    data.yaltyPaymentIdPrefix = $scope.newSubscription.yaltyPaymentIdPrefix;
                    data.recurringPaymentEnabled = $scope.newSubscription.recurringPaymentEnabled
                    data.barionPaymentToken = $scope.newSubscription.barionPaymentToken;
                    data.barionTraceIdCarriedOver = $scope.newSubscription.barionTraceIdCarriedOver;
                    data.fundingSource = $scope.newSubscription.fundingSource == 1 ? 'BankCard' : 'BankTransfer';
                    if($scope.adminUserType == "executive"){
                        $scope.newSubscriptionInProgress = $scope.newSubscription.companyId;
                        $scope.cancelNewSubscription();
                        return $http.post(API_PREFIX.url + '/yaltyadmin/createNewPartnerSubscription', data).then(function (result) {
                            $scope.newSubscriptionInProgress = null;
                            if (result.data.errorCode == 0) {
                                var message = $mdDialog.confirm()
                                .title('Sikeres új előfizetés!')
                                .htmlContent('<p>Előfizetés tranzakció azonosítója: <b>' + result.data.partnerPaymentTransactionsId  + '</b></p>')
                                .ok('Rendben')
                                .cancel('Számlázás')
                                $mdDialog.show(message).then(function () {
                                    $scope.refreshList($scope.forms.managingRegisteredPartnersForm);
                                }, function () {
                                    $scope.refreshList($scope.forms.managingRegisteredPartnersForm);
                                    var url = $state.href("adminPages.invoicingSupport", { transactionId: result.data.partnerPaymentTransactionsId }, { reload: true });
                                    $window.open(url,'_blank');
                                });
                            } else {
                                var message = $mdDialog.confirm()
                                .title('Sikertelen új előfizetés')
                                .textContent('Szerver hiba. Próbáld meg egy kicsit később újra.')
                                .ok('Rendben')
                                $mdDialog.show(message);
                            }
                        }, function () {
                            $scope.newSubscriptionInProgress = null;
                            var message = $mdDialog.confirm()
                            .title('Sikertelen új előfizetés')
                            .textContent('Szerver hiba. Próbáld meg egy kicsit később újra.')
                            .ok('Rendben')
                            $mdDialog.show(message);
                        });
                    }   
                }, function () {
                    
                });
            }
        }

        $scope.payByToken = function (record) { 
            var confirm = $mdDialog.confirm()
                            .title('Tokenes fizetés megerősítése')
                            .htmlContent('<p>Szeretnéd elindítani a tokenes fizetést az alábbi tranzakciós adatokkal?<br><br><b>Cég neve:</b> ' + record.compName + '<br><b>Cég azonosítója:</b> ' + record.id + '<br><b>Master felhasználó email címe:</b> ' + record.userName + '<br><b>Csomag neve:</b> ' + record.packageDisplayName + '<br><b>Előfizetés peridódusa:</b> ' + (record.paymentFrequency == 1 ? 'Havi' : 'Éves') + '<br><b>További vásárolt üzletek:</b> ' + record.additionalCompanyStoreNumber + ' db<br><b>További vásárolt felhasználók:</b> ' + record.additionalPartnerUserNumber + ' db<br><b>Fizetendő bruttó összeg:</b> ' + record.subscriptionPrice + ' Ft</p>')
                            .ok('IGEN')
                            .cancel('MÉGSEM');
            $mdDialog.show(confirm).then(function () {
                let data = {};
                data.subscriptionPackageName = record.packageName;
                data.subscriptionPeriod = (record.paymentFrequency == 1 ? 'monthly' : 'yearly');
                data.subscriptionPrice = record.subscriptionPrice;
                data.userName = record.userName;
                data.additionalPartnerUserNumber = record.additionalPartnerUserNumber;
                data.additionalCompanyStoreNumber = record.additionalCompanyStoreNumber;
                data.recurringPaymentEnabled = 1;
                data.paymentToken = record.barionPaymentToken;
                data.traceId = record.barionTraceIdCarriedOver;
                data.companyId = record.id;
                if($scope.adminUserType == "executive"){
                    $scope.payInProgress = record.id;
                    return $http.post(API_PREFIX.url + '/partner/partnerSubscriptionPayment', data).then(function (result) {
                        $scope.payInProgress = null;
                        if (result.data.errorCode == 0) {
                            var message = $mdDialog.confirm()
                            .title('Sikeres tokenes fizetés!')
                            .htmlContent('<p>Fizetés tranzakció azonosítója: <b>' + result.data.paymentTransactionId  + '</b></p>')
                            .ok('Rendben')
                            .cancel('Számlázás')
                            $mdDialog.show(message).then(function () {
                                $scope.refreshList($scope.forms.managingRegisteredPartnersForm);
                            }, function () {
                                $scope.refreshList($scope.forms.managingRegisteredPartnersForm);
                                var url = $state.href("adminPages.invoicingSupport", { transactionId: result.data.paymentTransactionId }, { reload: true });
                                $window.open(url,'_blank');
                            });
                        } else if(result.data.errorCode == 1) {
                            var message = $mdDialog.confirm()
                            .title('Sikertelen tokenes fizetés')
                            .textContent('Hiba történt. (errorCode = 1) Hibaüzenet: ' + result.data.errorMsg)
                            .ok('Rendben')
                            $mdDialog.show(message).then(function () {
                                $scope.refreshList($scope.forms.managingRegisteredPartnersForm);
                            });
                        } else if(result.data.errorCode == 2) {
                            var message = $mdDialog.confirm()
                            .title('Sikertelen tokenes fizetés')
                            .textContent('Jogosulatlan hozzáférés (érvénytelen token, errorCode = 2)')
                            .ok('Rendben')
                            $mdDialog.show(message);
                        } else if(result.data.errorCode == 3) {
                            var message = $mdDialog.confirm()
                            .title('Sikertelen tokenes fizetés')
                            .textContent('Jogosulatlan hozzáférés (nem executive felhasználó, errorCode = 3)')
                            .ok('Rendben')
                            $mdDialog.show(message);
                        } else if(result.data.errorCode == 4) {
                            var message = $mdDialog.confirm()
                            .title('Sikertelen tokenes fizetés')
                            .textContent('Jogosulatlan hozzáférés (helytelen előfizetési információk, errorCode = 4')
                            .ok('Rendben')
                            $mdDialog.show(message);
                        } else if(result.data.errorCode == 5) {
                            var message = $mdDialog.confirm()
                            .title('Sikertelen tokenes fizetés')
                            .textContent('A következő időszakra már van előfizetés. (errorCode = 5)')
                            .ok('Rendben')
                            $mdDialog.show(message).then(function () {
                                $scope.refreshList($scope.forms.managingRegisteredPartnersForm);
                            });
                        } else {
                            var message = $mdDialog.confirm()
                            .title('Sikertelen tokenes fizetés')
                            .textContent('Szerver hiba. Próbáld meg egy kicsit később újra.')
                            .ok('Rendben')
                            $mdDialog.show(message);
                        }
                    }, function () {
                        $scope.payInProgress = null;
                        var message = $mdDialog.confirm()
                        .title('Sikertelen tokenes fizetés')
                        .textContent('Szerver hiba. Próbáld meg egy kicsit később újra.')
                        .ok('Rendben')
                        $mdDialog.show(message);
                    });
                }   
            }, function () {
                
            });
        }

        $scope.isTokenPaymentAvailable = function (record) {
            if(record.subscriptionEndTimestamp != null && record.recurringPaymentEnabled == true && record.barionPaymentToken != null && record.packageName != 'yalty_start_free' && record.packageName != 'custom' && record.removalTimestamp == null) {
                return true;
            } else {
                return false;
            }
        }

        $scope.isTokenPaymentDisabled = function (record) {
            if(record.subscriptionEndTimestamp != null && new Date(record.subscriptionEndTimestamp).addDays(-10) > new Date() && record.recurringPaymentEnabled == true && record.barionPaymentToken != null && record.packageName != 'yalty_start_free' && record.packageName != 'custom') {
                return true;
            } else {
                return false;
            }
        }

        $scope.getFieldBackgroundColor = function (subscriptionEndTimestamp) {
            if(subscriptionEndTimestamp == null) {
                return '#FFFFFF';
            } else {
                if(new Date(subscriptionEndTimestamp) < new Date()) {
                    return '#ffb3b3';
                } else if(new Date(subscriptionEndTimestamp).addDays(-3) < new Date()) {
                    return '#ffb733';
                } else if(new Date(subscriptionEndTimestamp).addDays(-5) < new Date()) {
                    return '#ffe4b3';
                } else {
                    return '#FFFFFF';
                }
            }
        }

        $scope.adminUserType = $cookies.get('adminUserType');

        $scope.newSubscription = {};
        $scope.extendedSubscription = {};
        $scope.inactiveCompanies = false;

        $scope.showOnlyCompaniesWithLoyaltyCardYaltys = true;
        $scope.showOnlyNewCompanies = true;
        $scope.actualYear = new Date().getFullYear();
        $scope.actualMonth = new Date().getMonth() + 1;
        $scope.weekNumberActual = getWeekNumber(new Date());
        $scope.weekNumberActual = getWeekNumber(new Date());
        $scope.weekNumberOneWeekAgo = getWeekNumber(getDateWeeksAgo(1));
        $scope.weekNumberTwoWeekAgo = getWeekNumber(getDateWeeksAgo(2));
        $scope.weekNumberThreeWeekAgo = getWeekNumber(getDateWeeksAgo(3));

        $scope.filterCompanies = function () {
            return function( item ) {
                if($scope.inactiveCompanies == false && (item.inactive == true || item.removalTimestamp != null)) {
                    return false;
                } else if($scope.checkAllLoyaltyCardTypes == true) {
                    return true;
                } else if($scope.loyaltyCardTypes.length == 1 && $scope.loyaltyCardTypes[0] == 1) {
                    if($scope.checkAllCompanyActivity == true) {
                        return item.loyaltyCardYaltys.length > 0;
                    } else if($scope.companyActivity.length == 0) {
                        return false;
                    } else if($scope.companyActivity[0] == 1) { //Csak aktív cégek
                        if(item.loyaltyCardYaltys.length > 0 && (item.actualWeekTransactions > 0 || item.oneWeekAgoTransactions > 0 || item.twoWeekAgoTransactions > 0 || item.threeWeekAgoTransactions > 0)) {
                            let result = item.loyaltyCardYaltys.find((loyaltyCardYalty) => loyaltyCardYalty.activatedTimestamp != null && loyaltyCardYalty.newCardIssuingStoppedTimestamp == null && loyaltyCardYalty.deactivatedTimestamp == null);
                            if(result != null) {
                                return true;
                            } else {
                                return false;
                            }
                        } else {
                            return false;
                        }
                    } else { //Csak inaktív cégek
                        if(item.loyaltyCardYaltys.length > 0) {
                            let result = item.loyaltyCardYaltys.find((loyaltyCardYalty) => loyaltyCardYalty.activatedTimestamp != null && loyaltyCardYalty.newCardIssuingStoppedTimestamp == null && loyaltyCardYalty.deactivatedTimestamp == null);
                            if(result == null) {
                                return true;
                            } else {
                                if (item.actualWeekTransactions == 0 && item.oneWeekAgoTransactions == 0 && item.twoWeekAgoTransactions == 0 && item.threeWeekAgoTransactions == 0) {
                                    return true;
                                } else {
                                    return false;
                                }
                            }
                        } else {
                            return false;
                        }
                    }
                } else {
                    return ($scope.loyaltyCardTypes.includes(1) && item.loyaltyCardYaltys.length > 0) || 
                    ($scope.loyaltyCardTypes.includes(2) && item.loyaltyCards.length > 0) || 
                    ($scope.loyaltyCardTypes.includes(3) && item.loyaltyCards.length == 0 && item.loyaltyCardYaltys.length == 0);
                }
            };
        }

        $scope.openCompanyNewTab = function (mainId) {
            $cookies.put('mainId', mainId);
            var newTab = $window.open("", "_blank");
            newTab.location = "/yaltyadmin738/adminPotentialCompanies";
        }

        $scope.zeroResult = false;
        $scope.adminUserType = $cookies.get('adminUserType');
        $scope.adminUserId = $cookies.get('adminUserId');
        $scope.adminUserName = $cookies.get('adminUserName');
        $scope.allAdminId = $scope.yaltyAdminUserList;
        $scope.allLoyaltyCardTypes = [
            {
                id: 1,
                name: 'Van pecsételős kártyája'
            },
            {
                id: 2,
                name: 'Van törzskártyája'
            },
            {
                id: 3,
                name: 'Nincs sem pecsételős sem törzskártyája'
            }
        ];
        $scope.allCompanyActivity = [
            {
                id: 1,
                name: 'Aktív cég'
            },
            {
                id: 2,
                name: 'Inaktív cég'
            }
        ];
        $scope.adminId = [];
        $scope.loyaltyCardTypes = [1, 2, 3];
        $scope.companyActivity = [1, 2];
        $scope.checkAllLoyaltyCardTypes = true;
        $scope.checkAllCompanyActivity = true;
        if ($scope.adminUserType == 'sales') {
            $scope.hasNotYAdminContactOwner = false;
            $scope.checkAllAdminId = false;
            $scope.adminId.push($scope.adminUserId);
        } else {
            $scope.checkAllAdminId = true;
            $scope.hasNotYAdminContactOwner = true;
            for (let i = 0; i < $scope.allAdminId.length; i++) {
                $scope.adminId.push($scope.allAdminId[i].id);
            }
        }
        $scope.getSelectedAdminIds = function () {
            if (($scope.adminId == null || $scope.adminId.length == 0) && $scope.hasNotYAdminContactOwner == false) {
                return "YAdmin"
            } else if ($scope.adminId.length != $scope.allAdminId.length || $scope.hasNotYAdminContactOwner == false) {
                let text = "";
                for (let i = 0; i < $scope.adminId.length; i++) {
                    text += $scope.allAdminId.find(element => element.id == $scope.adminId[i]).name;
                    if ((i + 1) != $scope.adminId.length) {
                        text += ", "
                    }
                }
                if($scope.hasNotYAdminContactOwner == true) {
                    if(text == "") {
                        text += "YAdmin-hoz nem rendeltek"
                    } else {
                        text += ", YAdmin-hoz nem rendeltek"
                    }
                }
                return text;
            } else {
                return "Összes";
            }
        };
        $scope.getSelectedLoyaltyCardTypes = function () {
            if ($scope.loyaltyCardTypes == null || $scope.loyaltyCardTypes.length == 0) {
                return "Kártya típusok"
            } else if ($scope.loyaltyCardTypes.length != $scope.allLoyaltyCardTypes.length) {
                let text = "";
                for (let i = 0; i < $scope.loyaltyCardTypes.length; i++) {
                    text += $scope.allLoyaltyCardTypes.find(element => element.id == $scope.loyaltyCardTypes[i]).name;
                    if ((i + 1) != $scope.loyaltyCardTypes.length) {
                        text += ", "
                    }
                }
                return text;
            } else {
                return "Összes";
            }
        };
        $scope.getSelectedCompanyActivity = function () {
            if ($scope.companyActivity == null || $scope.companyActivity.length == 0) {
                return "Cég aktivitás"
            } else if ($scope.companyActivity.length != $scope.allCompanyActivity.length) {
                let text = "";
                for (let i = 0; i < $scope.companyActivity.length; i++) {
                    text += $scope.allCompanyActivity.find(element => element.id == $scope.companyActivity[i]).name;
                    if ((i + 1) != $scope.companyActivity.length) {
                        text += ", "
                    }
                }
                return text;
            } else {
                return "Összes";
            }
        };
        $scope.selectAdminId = function () {
            if ($scope.adminId == null || $scope.adminId.length != $scope.allAdminId.length || $scope.hasNotYAdminContactOwner == false) {
                $scope.checkAllAdminId = false;
            } else {
                $scope.checkAllAdminId = true;
            }
        };
        $scope.selectLoyaltyCardTypes = function () {
            if ($scope.loyaltyCardTypes == null || $scope.loyaltyCardTypes.length != $scope.allLoyaltyCardTypes.length) {
                $scope.checkAllLoyaltyCardTypes = false;
            } else {
                $scope.checkAllLoyaltyCardTypes = true;
            }
        };
        $scope.selectCompanyActivity = function () {
            if ($scope.companyActivity == null || $scope.companyActivity.length != $scope.allCompanyActivity.length) {
                $scope.checkAllCompanyActivity = false;
            } else {
                $scope.checkAllCompanyActivity = true;
            }
        };

        $scope.setAllOptions = function (checkValue, array, model) {
            if (checkValue == true) {
                if (model == "adminId") {
                    $scope.adminId = [];
                    for (let i = 0; i < $scope.allAdminId.length; i++) {
                        $scope.adminId.push($scope.allAdminId[i].id);
                    }
                    $scope.hasNotYAdminContactOwner = true;
                } else if(model == "loyaltyCardTypes") {
                    $scope.loyaltyCardTypes = [];
                    for (let i = 0; i < $scope.allLoyaltyCardTypes.length; i++) {
                        $scope.loyaltyCardTypes.push($scope.allLoyaltyCardTypes[i].id);
                    }
                } else if(model == "companyActivity") {
                    $scope.companyActivity = [];
                    for (let i = 0; i < $scope.allCompanyActivity.length; i++) {
                        $scope.companyActivity.push($scope.allCompanyActivity[i].id);
                    }
                }
            } else {
                if (model == "adminId") {
                    $scope.adminId = [];
                    $scope.hasNotYAdminContactOwner = false;
                } else if(model == "loyaltyCardTypes") {
                    $scope.loyaltyCardTypes = [];
                } else if(model == "companyActivity") {
                    $scope.companyActivity = [];
                }
            }
        }
        
        $scope.refreshList = function (ManagingRegisteredPartnersForm) {
            $scope.zeroResult = false;
            if (!ManagingRegisteredPartnersForm.$valid) {
                angular.forEach(ManagingRegisteredPartnersForm.$error, function (field) {
                    angular.forEach(field, function (errorField) {
                        errorField.$setTouched();
                    });
                });
            } else {
                let filters = {};
                filters.yaltyAdminUserIdArray = $scope.adminId;
                filters.hasNotYAdminContactOwner = $scope.hasNotYAdminContactOwner;
                filters.showOnlyCompaniesWithLoyaltyCardYaltys = $scope.showOnlyCompaniesWithLoyaltyCardYaltys;
                filters.showOnlyNewCompanies = $scope.showOnlyNewCompanies;
                $scope.sendButtonDisabled = true;
                return $http.post(API_PREFIX.url + '/yaltyadmin/getRegisteredPartnersInfo', filters).then(function (result) {
                    $scope.sendButtonDisabled = false;
                    if (result.data.errorCode == 0) {
                        if (result.data.companiesData.length == 0) {
                            $scope.zeroResult = true;
                            $scope.companylist = null;
                            setListHeight();
                        } else {
                            $scope.companylist = result.data.companiesData;
                            $scope.showCompanyList = true;
                            setListHeight();
                        }
                    } else {
                        commonService.showDialogWindowWithTitleOnly('Szerver hiba. Próbáld meg egy kicsit később újra.', 'Rendben')
                    }
                });
            }
        }

    })

    .controller('SalesLeaderboardController', function ($scope, $cookies, $http, API_PREFIX, $mdDialog, commonService) {

        function setListHeight() {
            setTimeout(function () {
                if ($scope.companylist != undefined && $scope.companylist.length != 0) {
                    let table = document.getElementById("statTable");
                    $scope.listHeight = { 'height': table.offsetHeight + 5 + 'px'};
                }
            }, 100);
        }

        setListHeight();
        window.onresize = function (event) {
            setListHeight();
        };

        $scope.adminUserType = $cookies.get('adminUserType');
        $scope.adminUserId = $cookies.get('adminUserId');
        $scope.adminUserName = $cookies.get('adminUserName');
        $scope.allAdminId = $scope.yaltyAdminUserList;
        $scope.adminId = [];
        $scope.checkAllAdminId = true;
        for (let i = 0; i < $scope.allAdminId.length; i++) {
            $scope.adminId.push($scope.allAdminId[i].id);
        }
        $scope.getSelectedAdminIds = function () {
            if ($scope.adminId == null || $scope.adminId.length == 0) {
                return "YAdmin"
            } else if ($scope.adminId.length != $scope.allAdminId.length) {
                let text = "";
                for (let i = 0; i < $scope.adminId.length; i++) {
                    text += $scope.allAdminId.find(element => element.id == $scope.adminId[i]).name;
                    if ((i + 1) != $scope.adminId.length) {
                        text += ", "
                    }
                }
                return text;
            } else {
                return "Összes";
            }
        };
        $scope.selectAdminId = function () {
            if ($scope.adminId == null || $scope.adminId.length != $scope.allAdminId.length) {
                $scope.checkAllAdminId = false;
            } else {
                $scope.checkAllAdminId = true;
            }
        };
        $scope.allCompanyFilter = [
            { id: 5, name: 'Adott időszakban felvett (létrehozott)' },
            { id: 6, name: 'Adott időszakban történt a cégnél a legelső ügyintézés' },
            { id: 7, name: 'Adott időszakban volt kimenő hívás' },
            { id: 10, name: 'Adott időszakban bármilyen változás történt a cégnél' }
        ];
        $scope.companyFilter = 7;

        if($scope.adminUserType == 'sales'){
            $scope.allPeriod = [
                { id: 2, name: 'Aktuális hét' },
                { id: 3, name: 'Múlt hét' },
                { id: 7, name: 'Elmúlt 30 nap' }
            ];
        } else {
            $scope.allPeriod = [
                { id: 1, name: 'Teljes időszak' },
                { id: 2, name: 'Aktuális hét' },
                { id: 3, name: 'Múlt hét' },
                { id: 4, name: 'Aktuális hónap' },
                { id: 5, name: 'Múlt hónap' },
                { id: 7, name: 'Elmúlt 30 nap' },
                { id: 6, name: 'Megadott időszak' }
            ];
        }



        $scope.period = 7;
        let date = new Date();
        date.setHours(0, 0, 0, 0);
        date.setDate(date.getDate() - 29);
        let endOfToday = new Date();
        endOfToday.setHours(23, 59, 59, 999);
        $scope.fromTimestamp = date;
        $scope.untilTimestamp = endOfToday;


        $scope.allYaltyCity = $scope.yaltyCities.listOfCities;
        for (let i = 0; i < $scope.allYaltyCity.length; i++) {
            $scope.allYaltyCity[i].id = i;
        }
        $scope.checkAllYaltyCity = true;
        $scope.selectedYaltyCities = [];
        for (let i = 0; i < $scope.allYaltyCity.length; i++) {
            $scope.selectedYaltyCities.push($scope.allYaltyCity[i].id);
        }

        $scope.setAllOptions = function (checkValue, array, model) {
            if (checkValue == true) {
                if (model == "selectedYaltyCities") {
                    $scope.selectedYaltyCities = [];
                    for (let i = 0; i < $scope.allYaltyCity.length; i++) {
                        $scope.selectedYaltyCities.push($scope.allYaltyCity[i].id);
                    }
                } else if (model == "adminId") {
                    $scope.adminId = [];
                    for (let i = 0; i < $scope.allAdminId.length; i++) {
                        $scope.adminId.push($scope.allAdminId[i].id);
                    }
                }
            } else {
                if (model == "selectedYaltyCities") {
                    $scope.selectedYaltyCities = [];
                } else if (model == "adminId") {
                    $scope.adminId = [];
                }
            }
        }
        $scope.getSelectedCities = function () {
            if ($scope.selectedYaltyCities == null || $scope.selectedYaltyCities.length == 0) {
                return "Yalty város"
            } else if ($scope.selectedYaltyCities.length != $scope.allYaltyCity.length) {
                let text = "";
                for (let i = 0; i < $scope.selectedYaltyCities.length; i++) {
                    text += $scope.allYaltyCity[$scope.selectedYaltyCities[i]].placeName;
                    if ((i + 1) != $scope.selectedYaltyCities.length) {
                        text += ", "
                    }
                }
                return text;
            } else {
                return "Összes";
            }
        };
        $scope.selectYaltyCity = function () {
            if ($scope.selectedYaltyCities == null || $scope.selectedYaltyCities.length != $scope.allYaltyCity.length) {
                $scope.checkAllYaltyCity = false;
            } else {
                $scope.checkAllYaltyCity = true;
            }
        };
        $scope.selectPeriod = function () {
            if ($scope.period == 1) {
                $scope.fromTimestamp = null;
                $scope.untilTimestamp = null;
            } else if ($scope.period == 2) {
                let startOfWeek = moment().startOf('week').toDate();
                let endOfToday = new Date();
                endOfToday.setHours(23, 59, 59, 999);
                $scope.fromTimestamp = startOfWeek;
                $scope.untilTimestamp = endOfToday;
            } else if ($scope.period == 3) {
                let startOfWeek = moment().subtract(1, 'weeks').startOf('week').toDate();
                let endOfWeek = moment().subtract(1, 'weeks').endOf('week').toDate();
                $scope.fromTimestamp = startOfWeek;
                $scope.untilTimestamp = endOfWeek;
            } else if ($scope.period == 4) {
                let startOfMonth = moment().startOf('month').toDate();
                let endOfToday = new Date();
                endOfToday.setHours(23, 59, 59, 999);
                $scope.fromTimestamp = startOfMonth;
                $scope.untilTimestamp = endOfToday;
            } else if ($scope.period == 5) {
                let startOfMonth = moment().subtract(1, 'month').startOf('month').toDate();
                let endOfMonth = moment().subtract(1, 'month').endOf('month').toDate();
                $scope.fromTimestamp = startOfMonth;
                $scope.untilTimestamp = endOfMonth;
            } else if ($scope.period == 6) {
                $scope.fromTimestamp = null;
                $scope.untilTimestamp = null;
            } else if ($scope.period == 7) {
                let date = new Date();
                date.setHours(0, 0, 0, 0);
                date.setDate(date.getDate() - 29);
                let endOfToday = new Date();
                endOfToday.setHours(23, 59, 59, 999);
                $scope.fromTimestamp = date;
                $scope.untilTimestamp = endOfToday;
            }
        }
        $scope.setTime = function () {
            if ($scope.untilTimestamp != null) {
                let date = new Date($scope.untilTimestamp);
                date.setHours(23, 59, 59, 999);
                $scope.untilTimestamp = date;
            }
        }

        $scope.refreshList = function (PotCompPortfolioForm) {
            if (!PotCompPortfolioForm.$valid) {
                angular.forEach(PotCompPortfolioForm.$error, function (field) {
                    angular.forEach(field, function (errorField) {
                        errorField.$setTouched();
                    });
                });
            } else {
                if ($scope.period == 6 && $scope.fromTimestamp != null && $scope.untilTimestamp != null && new Date($scope.fromTimestamp) > new Date($scope.untilTimestamp)) {
                    var confirm = $mdDialog.confirm()
                        .textContent('A kezdő időszak (Időszak -Tól) nem lehet nagyobb a vég időszaknál (Időszak -Ig)')
                        .ok('OK')
                    $mdDialog.show(confirm).then(function () {

                    }, function () {

                    });
                } else {
                    let filters = {};
                    filters.yaltyAdminUserIdArray = $scope.adminId;
                    filters.storeAddrCityArray = [];
                    if ($scope.selectedYaltyCities.length != $scope.allYaltyCity.length) {
                        filters.isFilteredByStoreAddrCity = true;
                        for (let i = 0; i < $scope.selectedYaltyCities.length; i++) {
                            filters.storeAddrCityArray.push($scope.allYaltyCity[$scope.selectedYaltyCities[i]].placeName);
                        }
                    } else {
                        filters.isFilteredByStoreAddrCity = false;
                    }
                    filters.potentialCompanySelectionType = $scope.companyFilter;
                    if ($scope.period == 1) {
                        filters.startDate = new Date("1970-01-01");
                        let endOfToday = new Date();
                        endOfToday.setHours(23, 59, 59, 999);
                        filters.endDate = endOfToday;
                    } else {
                        filters.startDate = $scope.fromTimestamp;
                        filters.endDate = $scope.untilTimestamp;
                    }
                    filters.aggregation = 0;
                    filters.salesMetricsScope = 1;
                    filters.frontendTimezone = moment.tz.guess();
                    $scope.checkDate = new Date();
                    $scope.checkToNow = new Date();
                    let numberOfDaysToAdd = -5;
                    $scope.checkDate.setDate($scope.checkDate.getDate() + numberOfDaysToAdd);
                    $scope.sendButtonDisabled = true;
                    return $http.post(API_PREFIX.url + '/yaltyadmin/getLCYSalesMetrics', filters).then(function (result) {
                        $scope.sendButtonDisabled = false;
                        if (result.data.errorCode == 0) {
                            /*if (result.data.potentialCompanySearchResult.length == 0) {
                                var confirm = $mdDialog.confirm()
                                    .title('Nincs a keresésnek megfelelő találat')
                                    .textContent('Módosítod a keresési feltételeket?')
                                    //.ariaLabel('Oldal elhagyás megerősítése')
                                    .ok('KERESÉS LEZÁRÁSA')
                                    .cancel('KERESÉSI FELTÉTELEK MÓDOSÍTÁSA');
                                $mdDialog.show(confirm).then(function () {

                                }, function () {

                                });
                            } else {*/
                            for (let i = 0; i < result.data.salesMetrics.length; i++) {
                                if (result.data.salesMetrics[i].nrOfOutgoingCalls == 0) {
                                    result.data.salesMetrics[i].callConv = 0;
                                } else {
                                    result.data.salesMetrics[i].callConv = result.data.salesMetrics[i].nrOfAnsweredAndDecisionMakerReachedOutgoingCalls / result.data.salesMetrics[i].nrOfOutgoingCalls;
                                }
                                result.data.salesMetrics[i].yaltyAdminUserName = $scope.allAdminId.find(element => element.id == result.data.salesMetrics[i].yaltyAdminUserId).name
                                result.data.salesMetrics[i].score = result.data.salesMetrics[i].nrOfYaltyStartFreeRegistrations + result.data.salesMetrics[i].nrOfYaltyStartPaidRegistrations + 2 * result.data.salesMetrics[i].nrOfBasicRegistrations + 4 * result.data.salesMetrics[i].nrOfPremiumRegistrations;
                            }
                            $scope.companylist = result.data.salesMetrics;
                            $scope.automaticSort();
                            $scope.showCompanyList = true;
                            setListHeight();
                            //}
                        } else {
                            /*GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/partneri_módosítás_történet/lista_lekérdezés_sikertelen",
                                "HU Partner [PRIVÁT] - Partneri módosítás történet oldal - Sikertelen lista lekérdezés")*/
                            commonService.showDialogWindowWithTitleOnly('Szerver hiba. Próbáld meg egy kicsit később újra.', 'Rendben')
                        }
                    });
                }
            }
        }

        $scope.automaticSort = function () {
            $scope.companylist.sort(function (a, b) {
                if (a.score < b.score) return 1;
                else if (a.score > b.score) return -1;
                else if (a.score == b.score) return a.callConv < b.callConv ? 1 : -1;
            });
        }

        setTimeout(function () {
            $scope.refreshList($scope.PotCompPortfolioForm);
        }, 100);

    })

    .controller('SalesMetricsController', function ($scope, $cookies, $http, API_PREFIX, $mdDialog, commonService) {

        function setListHeight() {
            setTimeout(function () {
                if ($scope.companylist != undefined && $scope.companylist.length != 0) {
                    let currentStateHeight = document.getElementById('currentStateDiv').offsetHeight;
                    let infoHeight = document.getElementById('infoDiv').offsetHeight;
                    let windowsHeight = window.innerHeight - currentStateHeight - infoHeight - 70;
                    $scope.listHeight = { 'height': windowsHeight + 'px' };
                } else {
                    $scope.listHeight = { 'height': 195 + 'px' };
                }
            }, 100);
        }

        setListHeight();
        window.onresize = function (event) {
            setListHeight();
        };

        $scope.adminUserType = $cookies.get('adminUserType');
        $scope.adminUserId = $cookies.get('adminUserId');
        $scope.adminUserName = $cookies.get('adminUserName');
        $scope.allAdminId = $scope.yaltyAdminUserList;
        $scope.adminId = [];
        $scope.checkAllAdminId = true;
        for (let i = 0; i < $scope.allAdminId.length; i++) {
            $scope.adminId.push($scope.allAdminId[i].id);
        }
        $scope.getSelectedAdminIds = function () {
            if ($scope.adminId == null || $scope.adminId.length == 0) {
                return "YAdmin"
            } else if ($scope.adminId.length != $scope.allAdminId.length) {
                let text = "";
                for (let i = 0; i < $scope.adminId.length; i++) {
                    text += $scope.allAdminId.find(element => element.id == $scope.adminId[i]).name;
                    if ((i + 1) != $scope.adminId.length) {
                        text += ", "
                    }
                }
                return text;
            } else {
                return "Összes";
            }
        };
        $scope.selectAdminId = function () {
            if ($scope.adminId == null || $scope.adminId.length != $scope.allAdminId.length) {
                $scope.checkAllAdminId = false;
            } else {
                $scope.checkAllAdminId = true;
            }
        };
        $scope.allCompanyFilter = [
            { id: 5, name: 'Adott időszakban felvett (létrehozott)' },
            { id: 6, name: 'Adott időszakban történt a cégnél a legelső ügyintézés' },
            { id: 7, name: 'Adott időszakban volt kimenő hívás' },
            { id: 10, name: 'Adott időszakban bármilyen változás történt a cégnél' }
        ];
        $scope.companyFilter = 6;
        $scope.allPeriod = [
            { id: 1, name: 'Teljes időszak' },
            { id: 2, name: 'Aktuális hét' },
            { id: 3, name: 'Múlt hét' },
            { id: 4, name: 'Aktuális hónap' },
            { id: 5, name: 'Múlt hónap' },
            { id: 7, name: 'Elmúlt 30 nap' },
            { id: 6, name: 'Megadott időszak' }
        ];


        $scope.period = 7;

        $scope.aggregationTypes = [
            { id: 5, type: 'Nincs' },
            { id: 1, type: 'Napi' },
            { id: 2, type: 'Heti' },
            { id: 3, type: 'Havi' }
        ]

        $scope.aggregationType = 5;

        let date = new Date();
        date.setHours(0, 0, 0, 0);
        date.setDate(date.getDate() - 29);
        let endOfToday = new Date();
        endOfToday.setHours(23, 59, 59, 999);
        $scope.fromTimestamp = date;
        $scope.untilTimestamp = endOfToday;


        $scope.allYaltyCity = $scope.yaltyCities.listOfCities;
        for (let i = 0; i < $scope.allYaltyCity.length; i++) {
            $scope.allYaltyCity[i].id = i;
        }
        $scope.checkAllYaltyCity = true;
        $scope.selectedYaltyCities = [];
        for (let i = 0; i < $scope.allYaltyCity.length; i++) {
            $scope.selectedYaltyCities.push($scope.allYaltyCity[i].id);
        }

        $scope.setAllOptions = function (checkValue, array, model) {
            if (checkValue == true) {
                if (model == "selectedYaltyCities") {
                    $scope.selectedYaltyCities = [];
                    for (let i = 0; i < $scope.allYaltyCity.length; i++) {
                        $scope.selectedYaltyCities.push($scope.allYaltyCity[i].id);
                    }
                } else if (model == "adminId") {
                    $scope.adminId = [];
                    for (let i = 0; i < $scope.allAdminId.length; i++) {
                        $scope.adminId.push($scope.allAdminId[i].id);
                    }
                }
            } else {
                if (model == "selectedYaltyCities") {
                    $scope.selectedYaltyCities = [];
                } else if (model == "adminId") {
                    $scope.adminId = [];
                }
            }
        }
        $scope.getSelectedCities = function () {
            if ($scope.selectedYaltyCities == null || $scope.selectedYaltyCities.length == 0) {
                return "Yalty város"
            } else if ($scope.selectedYaltyCities.length != $scope.allYaltyCity.length) {
                let text = "";
                for (let i = 0; i < $scope.selectedYaltyCities.length; i++) {
                    text += $scope.allYaltyCity[$scope.selectedYaltyCities[i]].placeName;
                    if ((i + 1) != $scope.selectedYaltyCities.length) {
                        text += ", "
                    }
                }
                return text;
            } else {
                return "Összes";
            }
        };

        $scope.selectYaltyCity = function () {
            if ($scope.selectedYaltyCities == null || $scope.selectedYaltyCities.length != $scope.allYaltyCity.length) {
                $scope.checkAllYaltyCity = false;
            } else {
                $scope.checkAllYaltyCity = true;
            }
        };

        const setAggregationAfterPeriodChanges = () => {
            if ($scope.period == 5) {
                if ($scope.aggregationType != null &&
                    $scope.aggregationType != 1 &&
                    $scope.aggregationType != 3 &&
                    $scope.aggregationType != 5) {
                    $scope.aggregationType = 1
                }
            } else if($scope.period == 3){
                if ($scope.aggregationType != null &&
                    $scope.aggregationType != 1 &&
                    $scope.aggregationType != 2 &&
                    $scope.aggregationType != 5) {
                    $scope.aggregationType = 1
                }
            } else {
                if ($scope.aggregationType != null &&
                    $scope.aggregationType != 1 &&
                    $scope.aggregationType != 5) {
                    $scope.aggregationType = 1
                }
            }
        }

        $scope.selectPeriod = function () {
            if ($scope.period == 1) {
                $scope.fromTimestamp = null;
                $scope.untilTimestamp = null;
            } else if ($scope.period == 2) {
                let startOfWeek = moment().startOf('week').toDate();
                let endOfToday = new Date();
                endOfToday.setHours(23, 59, 59, 999);
                $scope.fromTimestamp = startOfWeek;
                $scope.untilTimestamp = endOfToday;
                setAggregationAfterPeriodChanges()
            } else if ($scope.period == 3) {
                let startOfWeek = moment().subtract(1, 'weeks').startOf('week').toDate();
                let endOfWeek = moment().subtract(1, 'weeks').endOf('week').toDate();
                $scope.fromTimestamp = startOfWeek;
                $scope.untilTimestamp = endOfWeek;
                setAggregationAfterPeriodChanges()
            } else if ($scope.period == 4) {
                let startOfMonth = moment().startOf('month').toDate();
                let endOfToday = new Date();
                endOfToday.setHours(23, 59, 59, 999);
                $scope.fromTimestamp = startOfMonth;
                $scope.untilTimestamp = endOfToday;
                setAggregationAfterPeriodChanges()
            } else if ($scope.period == 5) {
                let startOfMonth = moment().subtract(1, 'month').startOf('month').toDate();
                let endOfMonth = moment().subtract(1, 'month').endOf('month').toDate();
                $scope.fromTimestamp = startOfMonth;
                $scope.untilTimestamp = endOfMonth;
                setAggregationAfterPeriodChanges()
            } else if ($scope.period == 6) {
                $scope.fromTimestamp = null;
                $scope.untilTimestamp = null;
                setAggregationAfterPeriodChanges()
            } else if ($scope.period == 7) {
                let date = new Date();
                date.setHours(0, 0, 0, 0);
                date.setDate(date.getDate() - 29);
                let endOfToday = new Date();
                endOfToday.setHours(23, 59, 59, 999);
                $scope.fromTimestamp = date;
                $scope.untilTimestamp = endOfToday;
                setAggregationAfterPeriodChanges()
            }
        }
        $scope.setTime = function () {
            if ($scope.untilTimestamp != null) {
                let date = new Date($scope.untilTimestamp);
                date.setHours(23, 59, 59, 999);
                $scope.untilTimestamp = date;
            }
        }

        const getDateFromAggrType = (aggrType) => {
            if (aggrType == 1 || aggrType == 5 || aggrType == null || aggrType == '') {
                return {
                    fromTimestamp: $scope.fromTimestamp,
                    untilTimestamp: $scope.untilTimestamp
                }
            } else if (aggrType == 2) {
                return {
                    fromTimestamp: $scope.fromTimestampWeek,
                    untilTimestamp: $scope.untilTimestampWeek
                }
            } else if (aggrType == 3) {
                return {
                    fromTimestamp: $scope.fromTimestampMonth,
                    untilTimestamp: $scope.untilTimestampMonth
                }
            } else if (aggrType == 4) {
                return {
                    fromTimestamp: $scope.fromTimestampYear != null ? $scope.startYearArray[$scope.fromTimestampYear].value : null,
                    untilTimestamp: $scope.untilTimestampYear != null ? $scope.endYearArray[$scope.untilTimestampYear].value : null
                }
            }
        }

        $scope.chartOptions = {
            scales: {
                yAxes: [{ 
                    ticks: {
                        beginAtZero: true,
                        callback: function(value) {
                            if (value % 1 === 0) {
                                return value;
                            }
                        }
                    }
                }]
            },
            legend: {
                display: true,
                position: 'right',
                onClick: function(){}
            }
        }

        $scope.setDatesAfterAggregationChanged = (previousAggrType) => {
            if ($scope.period == 6) {
                setDatesByAggregation(previousAggrType)
            } else {
                if ($scope.period != 1 && (($scope.aggregationType == 2 && $scope.period != 3) || ($scope.period != 5 && $scope.aggregationType == 3))) {
                    setDatesByAggregation(previousAggrType)
                    $scope.period = 6
                } else if (($scope.period == 5 && $scope.aggregationType == 3) || ($scope.aggregationType == 2 && $scope.period == 3)) {
                    setDatesByAggregation(previousAggrType)
                }
            }
        }

        $scope.checkStartOfMonth = () => {
            setTimeout(function () {
                if ($scope.aggregationType == 3 && $scope.fromTimestampMonth) {
                    let date = new Date($scope.fromTimestampMonth)
                    if (date.getDate() !== 1) {
                        $scope.PotCompPortfolioForm.fromTimestampMonth.$setValidity('startOfMonth', false);
                    } else {
                        $scope.PotCompPortfolioForm.fromTimestampMonth.$setValidity('startOfMonth', true);
                    }
                }
            }, 400);
        }

        $scope.checkMonthValidation = function () {
            setTimeout(function () {
                if ($scope.aggregationType == 3 && $scope.untilTimestampMonth) {
                    let date = new Date($scope.untilTimestampMonth)
                    if (date.getDate() !== 1 && date.getDate() != moment(date).daysInMonth()) {
                        $scope.PotCompPortfolioForm.untilTimestampMonth.$setValidity('endOfMonth', false);
                    } else {
                        $scope.PotCompPortfolioForm.untilTimestampMonth.$setValidity('endOfMonth', true);
                        let endOfMonth = moment($scope.untilTimestampMonth).endOf('month').toDate();
                        //endOfMonth.setHours(23, 59, 59, 999);
                        $scope.untilTimestampMonth = endOfMonth;
                    }
                }
            }, 400);
        }

        const setDatesByAggregation = (previousAggrType) => {
            let dates = getDateFromAggrType(previousAggrType)
            if ($scope.aggregationType == 1) {
                if (dates.fromTimestamp) {
                    let startOfDay = moment(dates.fromTimestamp).startOf('day').toDate();
                    $scope.fromTimestamp = startOfDay;
                }
                if (dates.untilTimestamp) {
                    let endOfDay = moment(dates.untilTimestamp).endOf('day').toDate();
                    endOfDay.setHours(23, 59, 59, 999);
                    $scope.untilTimestamp = endOfDay;
                }
            } else if ($scope.aggregationType == 2) {
                if (dates.fromTimestamp) {
                    let startOfWeek = moment(dates.fromTimestamp).startOf('week').toDate();
                    $scope.fromTimestampWeek = startOfWeek;
                } else {
                    $scope.fromTimestampWeek = null;
                }
                if (dates.untilTimestamp) {
                    let endOfWeek = moment(dates.untilTimestamp).endOf('week').toDate();
                    endOfWeek.setHours(23, 59, 59, 999);
                    $scope.untilTimestampWeek = endOfWeek;
                } else {
                    $scope.untilTimestampWeek = null;
                }
            } else if ($scope.aggregationType == 3) {
                if (dates.fromTimestamp) {
                    let startOfMonth = moment(dates.fromTimestamp).startOf('month').toDate();
                    $scope.fromTimestampMonth = startOfMonth;
                } else {
                    $scope.fromTimestampMonth = null;
                }
                if (dates.untilTimestamp) {
                    let endOfMonth = moment(dates.untilTimestamp).endOf('month').toDate();
                    endOfMonth.setHours(23, 59, 59, 999);
                    $scope.untilTimestampMonth = endOfMonth;
                } else {
                    $scope.untilTimestampMonth = null;
                }
            } else if ($scope.aggregationType == 5) {
                if (dates.fromTimestamp) {
                    $scope.fromTimestamp = dates.fromTimestamp;
                }
                if (dates.untilTimestamp) {
                    $scope.untilTimestamp = dates.untilTimestamp;
                }
            } 
        }

        $scope.selectField = function(){
            if($scope.aggregated != null && $scope.aggregated != 0){
                $scope.data = [];
                for(let i = 0; i < $scope.companylist.length; i++){
                    for(let j = 0; j < $scope.companylist[i].yaltyAdminUserStats.length; j++){
                        if(i == 0){
                            let adminAllStat = [];
                            if($scope.selectedField == 1){
                                adminAllStat.push($scope.companylist[i].yaltyAdminUserStats[j].nrOfAddedComps);
                            } else if($scope.selectedField == 2){
                                adminAllStat.push($scope.companylist[i].yaltyAdminUserStats[j].nrOfModifiedComps);
                            } else if($scope.selectedField == 3){
                                adminAllStat.push($scope.companylist[i].yaltyAdminUserStats[j].nrOfRegistrations);
                            } else if($scope.selectedField == 4){
                                adminAllStat.push($scope.companylist[i].yaltyAdminUserStats[j].regConv.toFixed(1));
                            } else if($scope.selectedField == 5){
                                adminAllStat.push($scope.companylist[i].yaltyAdminUserStats[j].nrOfFreeRegistrations);
                            } else if($scope.selectedField == 6){
                                adminAllStat.push($scope.companylist[i].yaltyAdminUserStats[j].yaltyStartFreeRegConv.toFixed(1));
                            } else if($scope.selectedField == 7){
                                adminAllStat.push($scope.companylist[i].yaltyAdminUserStats[j].nrOfBasicRegistrations);
                            } else if($scope.selectedField == 8){
                                adminAllStat.push($scope.companylist[i].yaltyAdminUserStats[j].basicRegConv.toFixed(1));
                            } else if($scope.selectedField == 9){
                                adminAllStat.push($scope.companylist[i].yaltyAdminUserStats[j].nrOfPremiumRegistrations);
                            } else if($scope.selectedField == 10){
                                adminAllStat.push($scope.companylist[i].yaltyAdminUserStats[j].premiumRegConv.toFixed(1));
                            } else if($scope.selectedField == 11){
                                adminAllStat.push($scope.companylist[i].yaltyAdminUserStats[j].nrOfOutgoingCalls);
                            } else if($scope.selectedField == 12){
                                adminAllStat.push($scope.companylist[i].yaltyAdminUserStats[j].nrOfAnsweredOutgoingCalls);
                            } else if($scope.selectedField == 13){
                                adminAllStat.push($scope.companylist[i].yaltyAdminUserStats[j].answeredOutgoingCallConv.toFixed(1));
                            } else if($scope.selectedField == 14){
                                adminAllStat.push($scope.companylist[i].yaltyAdminUserStats[j].nrOfAnsweredAndDecisionMakerReachedOutgoingCalls);
                            } else if($scope.selectedField == 15){
                                adminAllStat.push($scope.companylist[i].yaltyAdminUserStats[j].answeredAndDecisionMakerReachedOutgoingCallConv.toFixed(1));
                            } else if($scope.selectedField == 16){
                                adminAllStat.push($scope.companylist[i].yaltyAdminUserStats[j].nrOfCalledComps);
                            } else if($scope.selectedField == 17){
                                adminAllStat.push($scope.companylist[i].yaltyAdminUserStats[j].nrOfReachedComps);
                            } else if($scope.selectedField == 18){
                                adminAllStat.push($scope.companylist[i].yaltyAdminUserStats[j].reachedCompConv.toFixed(1));
                            } else if($scope.selectedField == 19){
                                adminAllStat.push($scope.companylist[i].yaltyAdminUserStats[j].outgoingCallConv.toFixed(1));
                            } else if($scope.selectedField == 20){
                                adminAllStat.push($scope.companylist[i].yaltyAdminUserStats[j].nrOfReachedDecisionMakers);
                            } else if($scope.selectedField == 21){
                                adminAllStat.push($scope.companylist[i].yaltyAdminUserStats[j].reachedDecisionMakerConv.toFixed(1));
                            } else if($scope.selectedField == 22){
                                adminAllStat.push($scope.companylist[i].yaltyAdminUserStats[j].nrOfCompsReceivedEmail);
                            } else if($scope.selectedField == 23){
                                adminAllStat.push($scope.companylist[i].yaltyAdminUserStats[j].compsReceivedEmailConv.toFixed(1));
                            } else if($scope.selectedField == 24){
                                adminAllStat.push($scope.companylist[i].yaltyAdminUserStats[j].nrOfModifications);
                            } else if($scope.selectedField == 25){
                                adminAllStat.push($scope.companylist[i].yaltyAdminUserStats[j].nrOfPartnerContacts);
                            } else if($scope.selectedField == 26){
                                adminAllStat.push($scope.companylist[i].yaltyAdminUserStats[j].modificationConv.toFixed(1));
                            } else if($scope.selectedField == 27){
                                adminAllStat.push($scope.companylist[i].yaltyAdminUserStats[j].partnerContactConv.toFixed(1));
                            } else if($scope.selectedField == 28){
                                adminAllStat.push($scope.companylist[i].nrOfYaltyStartPaidRegistrations);
                            } else if($scope.selectedField == 29){
                                adminAllStat.push($scope.companylist[i].yaltyStartPaidRegConv.toFixed(1));
                            }
                            $scope.data.push(adminAllStat);
                        } else {
                            if($scope.selectedField == 1){
                                $scope.data[j].push($scope.companylist[i].yaltyAdminUserStats[j].nrOfAddedComps);
                            } else if($scope.selectedField == 2){
                                $scope.data[j].push($scope.companylist[i].yaltyAdminUserStats[j].nrOfModifiedComps);
                            } else if($scope.selectedField == 3){
                                $scope.data[j].push($scope.companylist[i].yaltyAdminUserStats[j].nrOfRegistrations);
                            } else if($scope.selectedField == 4){
                                $scope.data[j].push($scope.companylist[i].yaltyAdminUserStats[j].regConv.toFixed(1));
                            } else if($scope.selectedField == 5){
                                $scope.data[j].push($scope.companylist[i].yaltyAdminUserStats[j].nrOfFreeRegistrations);
                            } else if($scope.selectedField == 6){
                                $scope.data[j].push($scope.companylist[i].yaltyAdminUserStats[j].yaltyStartFreeRegConv.toFixed(1));
                            } else if($scope.selectedField == 7){
                                $scope.data[j].push($scope.companylist[i].yaltyAdminUserStats[j].nrOfBasicRegistrations);
                            } else if($scope.selectedField == 8){
                                $scope.data[j].push($scope.companylist[i].yaltyAdminUserStats[j].basicRegConv.toFixed(1));
                            } else if($scope.selectedField == 9){
                                $scope.data[j].push($scope.companylist[i].yaltyAdminUserStats[j].nrOfPremiumRegistrations);
                            } else if($scope.selectedField == 10){
                                $scope.data[j].push($scope.companylist[i].yaltyAdminUserStats[j].premiumRegConv.toFixed(1));
                            } else if($scope.selectedField == 11){
                                $scope.data[j].push($scope.companylist[i].yaltyAdminUserStats[j].nrOfOutgoingCalls);
                            } else if($scope.selectedField == 12){
                                $scope.data[j].push($scope.companylist[i].yaltyAdminUserStats[j].nrOfAnsweredOutgoingCalls);
                            } else if($scope.selectedField == 13){
                                $scope.data[j].push($scope.companylist[i].yaltyAdminUserStats[j].answeredOutgoingCallConv.toFixed(1));
                            } else if($scope.selectedField == 14){
                                $scope.data[j].push($scope.companylist[i].yaltyAdminUserStats[j].nrOfAnsweredAndDecisionMakerReachedOutgoingCalls);
                            } else if($scope.selectedField == 15){
                                $scope.data[j].push($scope.companylist[i].yaltyAdminUserStats[j].answeredAndDecisionMakerReachedOutgoingCallConv.toFixed(1));
                            } else if($scope.selectedField == 16){
                                $scope.data[j].push($scope.companylist[i].yaltyAdminUserStats[j].nrOfCalledComps);
                            } else if($scope.selectedField == 17){
                                $scope.data[j].push($scope.companylist[i].yaltyAdminUserStats[j].nrOfReachedComps);
                            } else if($scope.selectedField == 18){
                                $scope.data[j].push($scope.companylist[i].yaltyAdminUserStats[j].reachedCompConv.toFixed(1));
                            } else if($scope.selectedField == 19){
                                $scope.data[j].push($scope.companylist[i].yaltyAdminUserStats[j].outgoingCallConv.toFixed(1));
                            } else if($scope.selectedField == 20){
                                $scope.data[j].push($scope.companylist[i].yaltyAdminUserStats[j].nrOfReachedDecisionMakers);
                            } else if($scope.selectedField == 21){
                                $scope.data[j].push($scope.companylist[i].yaltyAdminUserStats[j].reachedDecisionMakerConv.toFixed(1));
                            } else if($scope.selectedField == 22){
                                $scope.data[j].push($scope.companylist[i].yaltyAdminUserStats[j].nrOfCompsReceivedEmail);
                            } else if($scope.selectedField == 23){
                                $scope.data[j].push($scope.companylist[i].yaltyAdminUserStats[j].compsReceivedEmailConv.toFixed(1));
                            } else if($scope.selectedField == 24){
                                $scope.data[j].push($scope.companylist[i].yaltyAdminUserStats[j].nrOfModifications);
                            } else if($scope.selectedField == 25){
                                $scope.data[j].push($scope.companylist[i].yaltyAdminUserStats[j].nrOfPartnerContacts);
                            } else if($scope.selectedField == 26){
                                $scope.data[j].push($scope.companylist[i].yaltyAdminUserStats[j].modificationConv.toFixed(1));
                            } else if($scope.selectedField == 27){
                                $scope.data[j].push($scope.companylist[i].yaltyAdminUserStats[j].partnerContactConv.toFixed(1));
                            } else if($scope.selectedField == 28){
                                $scope.data[j].push($scope.companylist[i].nrOfYaltyStartPaidRegistrations);
                            } else if($scope.selectedField == 29){
                                $scope.data[j].push($scope.companylist[i].yaltyStartPaidRegConv.toFixed(1));
                            }
                        }
                    }
                }
            } else if($scope.aggregated == 0){
                $scope.data = [];
                for(let i = 0; i < $scope.companylist.length; i++){
                    let adminAllStat = [];
                    if($scope.selectedField == 1){
                        adminAllStat.push($scope.companylist[i].nrOfAddedComps);
                    } else if($scope.selectedField == 2){
                        adminAllStat.push($scope.companylist[i].nrOfModifiedComps);
                    } else if($scope.selectedField == 3){
                        adminAllStat.push($scope.companylist[i].nrOfRegistrations);
                    } else if($scope.selectedField == 4){
                        adminAllStat.push($scope.companylist[i].regConv.toFixed(1));
                    } else if($scope.selectedField == 5){
                        adminAllStat.push($scope.companylist[i].nrOfYaltyStartFreeRegistrations);
                    } else if($scope.selectedField == 6){
                        adminAllStat.push($scope.companylist[i].yaltyStartFreeRegConv.toFixed(1));
                    } else if($scope.selectedField == 7){
                        adminAllStat.push($scope.companylist[i].nrOfBasicRegistrations);
                    } else if($scope.selectedField == 8){
                        adminAllStat.push($scope.companylist[i].basicRegConv.toFixed(1));
                    } else if($scope.selectedField == 9){
                        adminAllStat.push($scope.companylist[i].nrOfPremiumRegistrations);
                    } else if($scope.selectedField == 10){
                        adminAllStat.push($scope.companylist[i].premiumRegConv.toFixed(1));
                    } else if($scope.selectedField == 11){
                        adminAllStat.push($scope.companylist[i].nrOfOutgoingCalls);
                    } else if($scope.selectedField == 12){
                        adminAllStat.push($scope.companylist[i].nrOfAnsweredOutgoingCalls);
                    } else if($scope.selectedField == 13){
                        adminAllStat.push($scope.companylist[i].answeredOutgoingCallConv.toFixed(1));
                    } else if($scope.selectedField == 14){
                        adminAllStat.push($scope.companylist[i].nrOfAnsweredAndDecisionMakerReachedOutgoingCalls);
                    } else if($scope.selectedField == 15){
                        adminAllStat.push($scope.companylist[i].answeredAndDecisionMakerReachedOutgoingCallConv.toFixed(1));
                    } else if($scope.selectedField == 16){
                        adminAllStat.push($scope.companylist[i].nrOfCalledComps);
                    } else if($scope.selectedField == 17){
                        adminAllStat.push($scope.companylist[i].nrOfReachedComps);
                    } else if($scope.selectedField == 18){
                        adminAllStat.push($scope.companylist[i].reachedCompConv.toFixed(1));
                    } else if($scope.selectedField == 19){
                        adminAllStat.push($scope.companylist[i].outgoingCallConv.toFixed(1));
                    } else if($scope.selectedField == 20){
                        adminAllStat.push($scope.companylist[i].nrOfReachedDecisionMakers);
                    } else if($scope.selectedField == 21){
                        adminAllStat.push($scope.companylist[i].reachedDecisionMakerConv.toFixed(1));
                    } else if($scope.selectedField == 22){
                        adminAllStat.push($scope.companylist[i].nrOfCompsReceivedEmail);
                    } else if($scope.selectedField == 23){
                        adminAllStat.push($scope.companylist[i].compsReceivedEmailConv.toFixed(1));
                    } else if($scope.selectedField == 24){
                        adminAllStat.push($scope.companylist[i].nrOfModifications);
                    } else if($scope.selectedField == 25){
                        adminAllStat.push($scope.companylist[i].nrOfPartnerContacts);
                    } else if($scope.selectedField == 26){
                        adminAllStat.push($scope.companylist[i].modificationConv.toFixed(1));
                    } else if($scope.selectedField == 27){
                        adminAllStat.push($scope.companylist[i].partnerContactConv.toFixed(1));
                    } else if($scope.selectedField == 28){
                        adminAllStat.push($scope.companylist[i].nrOfYaltyStartPaidRegistrations);
                    } else if($scope.selectedField == 29){
                        adminAllStat.push($scope.companylist[i].yaltyStartPaidRegConv.toFixed(1));
                    }
                    $scope.data.push(adminAllStat);
                }
            }
        }

        $scope.onlyMondaysPredicate = function (date) {
            var day = date.getDay();
            return day === 1
        };

        $scope.onlySundaysPredicate = function (date) {
            var day = date.getDay();
            return day === 0
        };

        $scope.selectedField = 1;

        //Sales metrics lista:
        $scope.refreshList = function (PotCompPortfolioForm) {
            if (!PotCompPortfolioForm.$valid) {
                angular.forEach(PotCompPortfolioForm.$error, function (field) {
                    angular.forEach(field, function (errorField) {
                        errorField.$setTouched();
                    });
                });
            } else {
                if($scope.aggregationType == 2){
                    $scope.fromTimestamp = $scope.fromTimestampWeek;
                    $scope.untilTimestamp = $scope.untilTimestampWeek;
                } else if($scope.aggregationType == 3){
                    $scope.fromTimestamp = $scope.fromTimestampMonth;
                    $scope.untilTimestamp = $scope.untilTimestampMonth;
                }
                if ($scope.period == 6 && $scope.fromTimestamp != null && $scope.untilTimestamp != null && new Date($scope.fromTimestamp) > new Date($scope.untilTimestamp)) {
                    var confirm = $mdDialog.confirm()
                        .textContent('A kezdő időszak (Időszak -Tól) nem lehet nagyobb a vég időszaknál (Időszak -Ig)')
                        .ok('OK')
                    $mdDialog.show(confirm).then(function () {

                    }, function () {

                    });
                } else {
                    let filters = {};
                    filters.yaltyAdminUserIdArray = $scope.adminId;
                    filters.storeAddrCityArray = [];
                    if ($scope.selectedYaltyCities.length != $scope.allYaltyCity.length) {
                        filters.isFilteredByStoreAddrCity = true;
                        for (let i = 0; i < $scope.selectedYaltyCities.length; i++) {
                            filters.storeAddrCityArray.push($scope.allYaltyCity[$scope.selectedYaltyCities[i]].placeName);
                        }
                    } else {
                        filters.isFilteredByStoreAddrCity = false;
                    }
                    filters.potentialCompanySelectionType = $scope.companyFilter;
                    if ($scope.period == 1) {
                        filters.startDate = new Date("1970-01-01");
                        let endOfToday = new Date();
                        endOfToday.setHours(23, 59, 59, 999);
                        filters.endDate = endOfToday;
                    } else {
                        filters.startDate = $scope.fromTimestamp;
                        filters.endDate = $scope.untilTimestamp;
                    }
                    if(filters.startDate == null){
                        filters.startDate = new Date("1970-01-01");
                    } 
                    if(filters.endDate == null) {
                        let endOfToday = new Date();
                        endOfToday.setHours(23, 59, 59, 999);
                        filters.endDate = endOfToday;
                    }
                    if($scope.aggregationType == 5){
                        filters.aggregation = 0;
                    } else {
                        filters.aggregation = $scope.aggregationType;
                    }
                    filters.salesMetricsScope = 2;
                    filters.frontendTimezone = moment.tz.guess();
                    $scope.checkDate = new Date();
                    $scope.checkToNow = new Date();
                    let numberOfDaysToAdd = -5;
                    $scope.checkDate.setDate($scope.checkDate.getDate() + numberOfDaysToAdd);
                    $scope.sendButtonDisabled = true;
                    return $http.post(API_PREFIX.url + '/yaltyadmin/getLCYSalesMetrics', filters).then(function (result) {
                        $scope.sendButtonDisabled = false;
                        if (result.data.errorCode == 0) {
                            $scope.aggregated = result.data.aggregated;
                            if($scope.aggregated == 0){
                                for (let i = 0; i < result.data.salesMetrics.length; i++) {
                                    if (result.data.salesMetrics[i].nrOfModifiedComps == 0) {
                                        result.data.salesMetrics[i].regConv = 0;
                                        result.data.salesMetrics[i].yaltyStartFreeRegConv = 0;
                                        result.data.salesMetrics[i].yaltyStartPaidRegConv = 0;
                                        result.data.salesMetrics[i].basicRegConv = 0;
                                        result.data.salesMetrics[i].premiumRegConv = 0;
                                        result.data.salesMetrics[i].outgoingCallConv = 0;
                                        result.data.salesMetrics[i].modificationConv = 0;
                                        result.data.salesMetrics[i].partnerContactConv = 0;
                                    } else {
                                        result.data.salesMetrics[i].regConv = result.data.salesMetrics[i].nrOfRegistrations / result.data.salesMetrics[i].nrOfModifiedComps * 100;
                                        result.data.salesMetrics[i].yaltyStartFreeRegConv = result.data.salesMetrics[i].nrOfYaltyStartFreeRegistrations / result.data.salesMetrics[i].nrOfModifiedComps * 100;
                                        result.data.salesMetrics[i].yaltyStartPaidRegConv = result.data.salesMetrics[i].nrOfYaltyStartPaidRegistrations / result.data.salesMetrics[i].nrOfModifiedComps * 100;
                                        result.data.salesMetrics[i].basicRegConv = result.data.salesMetrics[i].nrOfBasicRegistrations / result.data.salesMetrics[i].nrOfModifiedComps * 100;
                                        result.data.salesMetrics[i].premiumRegConv = result.data.salesMetrics[i].nrOfPremiumRegistrations / result.data.salesMetrics[i].nrOfModifiedComps * 100;
                                        result.data.salesMetrics[i].outgoingCallConv = result.data.salesMetrics[i].nrOfOutgoingCalls / result.data.salesMetrics[i].nrOfModifiedComps;
                                        result.data.salesMetrics[i].modificationConv = result.data.salesMetrics[i].nrOfModifications / result.data.salesMetrics[i].nrOfModifiedComps;
                                        result.data.salesMetrics[i].partnerContactConv = result.data.salesMetrics[i].nrOfPartnerContacts / result.data.salesMetrics[i].nrOfModifiedComps;
                                    }
                                    if (result.data.salesMetrics[i].nrOfOutgoingCalls == 0) {
                                        result.data.salesMetrics[i].answeredOutgoingCallConv = 0;
                                        result.data.salesMetrics[i].answeredAndDecisionMakerReachedOutgoingCallConv = 0;
                                    } else {
                                        result.data.salesMetrics[i].answeredOutgoingCallConv = result.data.salesMetrics[i].nrOfAnsweredOutgoingCalls / result.data.salesMetrics[i].nrOfOutgoingCalls * 100;
                                        result.data.salesMetrics[i].answeredAndDecisionMakerReachedOutgoingCallConv = result.data.salesMetrics[i].nrOfAnsweredAndDecisionMakerReachedOutgoingCalls / result.data.salesMetrics[i].nrOfOutgoingCalls * 100;
                                    }
                                    if(result.data.salesMetrics[i].nrOfCalledComps == 0){
                                        result.data.salesMetrics[i].reachedCompConv = 0;
                                        result.data.salesMetrics[i].reachedDecisionMakerConv = 0;
                                        result.data.salesMetrics[i].compsReceivedEmailConv = 0;
                                    } else {
                                        result.data.salesMetrics[i].reachedCompConv = result.data.salesMetrics[i].nrOfReachedComps / result.data.salesMetrics[i].nrOfCalledComps * 100;
                                        result.data.salesMetrics[i].reachedDecisionMakerConv = result.data.salesMetrics[i].nrOfReachedDecisionMakers / result.data.salesMetrics[i].nrOfCalledComps * 100;
                                        result.data.salesMetrics[i].compsReceivedEmailConv = result.data.salesMetrics[i].nrOfCompsReceivedEmail / result.data.salesMetrics[i].nrOfCalledComps * 100;
                                    }
                                    result.data.salesMetrics[i].yaltyAdminUserName = $scope.allAdminId.find(element => element.id == result.data.salesMetrics[i].yaltyAdminUserId).name
                                }
                                $scope.companylist = result.data.salesMetrics;
                                $scope.automaticSort($scope.companylist);
                                $scope.showCompanyList = true;
                                setListHeight();
                                $scope.series = [];
                                for(let i = 0; i < $scope.companylist.length; i++){ 
                                    $scope.series.push($scope.companylist[i].yaltyAdminUserName + " (" + $scope.companylist[i].yaltyAdminUserId + ")");
                                }
                                $scope.labels = [];
                                $scope.labels.push($scope.allPeriod.find(element => element.id == $scope.period).name)
                                $scope.selectField();
                            } else {
                                $scope.listWidth = (300 + result.data.salesMetrics[0].yaltyAdminUserStats.length * 60) + 'px';
                                $scope.yaltyAdminUserList = [];
                                for(let i = 0; i < result.data.salesMetrics[0].yaltyAdminUserStats.length; i++){
                                    let admin = {};
                                    admin.yaltyAdminUserId = result.data.salesMetrics[0].yaltyAdminUserStats[i].yaltyAdminUserId;
                                    admin.yaltyAdminUserName = $scope.allAdminId.find(element => element.id == result.data.salesMetrics[0].yaltyAdminUserStats[i].yaltyAdminUserId).name
                                    $scope.yaltyAdminUserList.push(admin)
                                }
                                for (let i = 0; i < result.data.salesMetrics.length; i++) {
                                    for(let j = 0; j < result.data.salesMetrics[i].yaltyAdminUserStats.length; j++){
                                        if (result.data.salesMetrics[i].yaltyAdminUserStats[j].nrOfModifiedComps == 0) {
                                            result.data.salesMetrics[i].yaltyAdminUserStats[j].regConv = 0;
                                            result.data.salesMetrics[i].yaltyAdminUserStats[j].yaltyStartFreeRegConv = 0;
                                            result.data.salesMetrics[i].yaltyAdminUserStats[j].yaltyStartPaidRegConv = 0;
                                            result.data.salesMetrics[i].yaltyAdminUserStats[j].basicRegConv = 0;
                                            result.data.salesMetrics[i].yaltyAdminUserStats[j].premiumRegConv = 0;
                                            result.data.salesMetrics[i].yaltyAdminUserStats[j].outgoingCallConv = 0;
                                            result.data.salesMetrics[i].yaltyAdminUserStats[j].modificationConv = 0;
                                            result.data.salesMetrics[i].yaltyAdminUserStats[j].partnerContactConv = 0;
                                        } else {
                                            result.data.salesMetrics[i].yaltyAdminUserStats[j].regConv = result.data.salesMetrics[i].yaltyAdminUserStats[j].nrOfRegistrations / result.data.salesMetrics[i].yaltyAdminUserStats[j].nrOfModifiedComps * 100;
                                            result.data.salesMetrics[i].yaltyAdminUserStats[j].yaltyStartFreeRegConv = result.data.salesMetrics[i].yaltyAdminUserStats[j].nrOfYaltyStartFreeRegistrations / result.data.salesMetrics[i].yaltyAdminUserStats[j].nrOfModifiedComps * 100;
                                            result.data.salesMetrics[i].yaltyAdminUserStats[j].yaltyStartPaidRegConv = result.data.salesMetrics[i].yaltyAdminUserStats[j].nrOfYaltyStartPaidRegistrations / result.data.salesMetrics[i].yaltyAdminUserStats[j].nrOfModifiedComps * 100;
                                            result.data.salesMetrics[i].yaltyAdminUserStats[j].basicRegConv = result.data.salesMetrics[i].yaltyAdminUserStats[j].nrOfBasicRegistrations / result.data.salesMetrics[i].yaltyAdminUserStats[j].nrOfModifiedComps * 100;
                                            result.data.salesMetrics[i].yaltyAdminUserStats[j].premiumRegConv = result.data.salesMetrics[i].yaltyAdminUserStats[j].nrOfPremiumRegistrations / result.data.salesMetrics[i].yaltyAdminUserStats[j].nrOfModifiedComps * 100;
                                            result.data.salesMetrics[i].yaltyAdminUserStats[j].outgoingCallConv = result.data.salesMetrics[i].yaltyAdminUserStats[j].nrOfOutgoingCalls / result.data.salesMetrics[i].yaltyAdminUserStats[j].nrOfModifiedComps;
                                            result.data.salesMetrics[i].yaltyAdminUserStats[j].modificationConv = result.data.salesMetrics[i].yaltyAdminUserStats[j].nrOfModifications / result.data.salesMetrics[i].yaltyAdminUserStats[j].nrOfModifiedComps;
                                            result.data.salesMetrics[i].yaltyAdminUserStats[j].partnerContactConv = result.data.salesMetrics[i].yaltyAdminUserStats[j].nrOfPartnerContacts / result.data.salesMetrics[i].yaltyAdminUserStats[j].nrOfModifiedComps;
                                        }
                                        if (result.data.salesMetrics[i].yaltyAdminUserStats[j].nrOfOutgoingCalls == 0) {
                                            result.data.salesMetrics[i].yaltyAdminUserStats[j].answeredOutgoingCallConv = 0;
                                            result.data.salesMetrics[i].yaltyAdminUserStats[j].answeredAndDecisionMakerReachedOutgoingCallConv = 0;
                                        } else {
                                            result.data.salesMetrics[i].yaltyAdminUserStats[j].answeredOutgoingCallConv = result.data.salesMetrics[i].yaltyAdminUserStats[j].nrOfAnsweredOutgoingCalls / result.data.salesMetrics[i].yaltyAdminUserStats[j].nrOfOutgoingCalls * 100;
                                            result.data.salesMetrics[i].yaltyAdminUserStats[j].answeredAndDecisionMakerReachedOutgoingCallConv = result.data.salesMetrics[i].yaltyAdminUserStats[j].nrOfAnsweredAndDecisionMakerReachedOutgoingCalls / result.data.salesMetrics[i].yaltyAdminUserStats[j].nrOfOutgoingCalls * 100;
                                        }
                                        if(result.data.salesMetrics[i].yaltyAdminUserStats[j].nrOfCalledComps == 0){
                                            result.data.salesMetrics[i].yaltyAdminUserStats[j].reachedCompConv = 0;
                                            result.data.salesMetrics[i].yaltyAdminUserStats[j].reachedDecisionMakerConv = 0;
                                            result.data.salesMetrics[i].yaltyAdminUserStats[j].compsReceivedEmailConv = 0;
                                        } else {
                                            result.data.salesMetrics[i].yaltyAdminUserStats[j].reachedCompConv = result.data.salesMetrics[i].yaltyAdminUserStats[j].nrOfReachedComps / result.data.salesMetrics[i].yaltyAdminUserStats[j].nrOfCalledComps * 100;
                                            result.data.salesMetrics[i].yaltyAdminUserStats[j].reachedDecisionMakerConv = result.data.salesMetrics[i].yaltyAdminUserStats[j].nrOfReachedDecisionMakers / result.data.salesMetrics[i].yaltyAdminUserStats[j].nrOfCalledComps * 100;
                                            result.data.salesMetrics[i].yaltyAdminUserStats[j].compsReceivedEmailConv = result.data.salesMetrics[i].yaltyAdminUserStats[j].nrOfCompsReceivedEmail / result.data.salesMetrics[i].yaltyAdminUserStats[j].nrOfCalledComps * 100;
                                        }
                                        result.data.salesMetrics[i].yaltyAdminUserStats[j].yaltyAdminUserName = $scope.allAdminId.find(element => element.id == result.data.salesMetrics[i].yaltyAdminUserStats[j].yaltyAdminUserId).name
                                    }
                                }
                                $scope.companylist = result.data.salesMetrics;
                                $scope.series = [];
                                $scope.automaticSort($scope.yaltyAdminUserList);
                                for(let i = 0; i < $scope.yaltyAdminUserList.length; i++){
                                    $scope.series.push($scope.yaltyAdminUserList[i].yaltyAdminUserName + " (" + $scope.yaltyAdminUserList[i].yaltyAdminUserId + ")");
                                }
                                for(let i = 0; i < $scope.companylist.length; i++){
                                    $scope.automaticSort($scope.companylist[i].yaltyAdminUserStats)
                                }
                                $scope.showCompanyList = true;
                                setListHeight();
                                $scope.labels = [];
                                for(let i = 0; i < $scope.companylist.length; i++){
                                    $scope.labels.push($scope.companylist[i].interval);
                                }
                                $scope.selectField();
                            }
                        } else {
                            commonService.showDialogWindowWithTitleOnly('Szerver hiba. Próbáld meg egy kicsit később újra.', 'Rendben')
                        }
                    });
                }
            }
        }

        $scope.automaticSort = function (arrayForSort) {
            arrayForSort.sort((a, b) => a.yaltyAdminUserName.localeCompare(b.yaltyAdminUserName))
        }

        setTimeout(function () {
            $scope.refreshList($scope.PotCompPortfolioForm);
        }, 100);

    })

    .controller('invoicingSupportController', function ($scope, $stateParams, $http, API_PREFIX, $mdDialog, $state, commonService) {
        $scope.selectPDF = (file) => {
            if (!file) return
            if (file.type !== "application/pdf") {
                const confirm = $mdDialog.confirm()
                    .title('A fájl nem PDF.')
                    .ok('OK')
                $mdDialog.show(confirm)
            } else {
                const fileReader = new FileReader();
                fileReader.onload = function(fileLoadedEvent) {
                    $scope.pdf = file;
                    $scope.base64PDF = fileLoadedEvent.target.result;
                    $scope.uploadInvoicingPDFButtonDisabled = false
                    $scope.successfulUpload = false
                };
                fileReader.readAsDataURL(file);
            }
        }

        $scope.uploadInvoicePDF = () => {
            $scope.uploadInvoicingPDFButtonLoading = true
            const params = {
                transactionId: $scope.transactionId,
                invoicingPDF: $scope.base64PDF,
                companyId: $scope.invoicingInfo.transactionData.companyId,
                isPostUpload: $scope.isPostUpload,
                transactionFinalisationTimestamp: $scope.invoicingInfo.transactionData.transactionFinalisationTimestamp
            }
            $http.post(API_PREFIX.url + '/yaltyadmin/uploadInvoice', params)
            .then(function (result) {
                $scope.uploadInvoicingPDFButtonLoading = false
                if (result.data.errorCode == 0) {
                    $scope.successfulUpload = true
                    $scope.uploadInvoicingPDFButtonDisabled = true
                    commonService.showDialogWindowWithTitleOnly('Sikeres feltöltés.', 'OK');
                    $scope.getInvoicingInfo('update');
                } else if (result.data.errorCode == 1) {
                    commonService.showDialogWindowWithTitleOnly('Szerver hiba. Kérjük próbáld meg később újra.', 'OK');
                } else if (result.data.errorCode == 2 || result.data.errorCode == 3) {
                    commonService.showDialogWindow('Sikertelen művelet', 
                        'Nem megfelelő jogosultság a művelet elvégzéséhez. Kiléptetünk az oldalról.', 'OK', () => {
                            $state.go('adminLogin');
                    });
                }
            })
            /*const formData = new FormData();
            const fileField = document.querySelector('input[type="file"]');
            formData.append('invoicingPDF', fileField.files[0]);
            formData.append('transactionId', $scope.transactionId);

            console.log(fileField);
            fetch(API_PREFIX.url + '/yaltyadmin/uploadInvoice', {
                method: 'POST',
                body: formData
            })
            .then(response => response.json())
            .then(result => {
                console.log('Success:', result);
            })
            .catch(error => {
                console.error('Error:', error);
            });*/
        }

        $scope.checkEnter = function(event) {
            if (event.which === 13) {
                $scope.getInvoicingInfo();
            }
        };

        $scope.cursorStyle = { cursor: 'auto' };
        $scope.changeCursorToCopy = function(isHovered) {
            if (isHovered) {
                $scope.cursorStyle = { cursor: 'url(../../imgs/copy_to_clipboard_small.png), auto' };
            } else {
                $scope.cursorStyle = { cursor: 'auto' };
            }
        };

        function formatDate(timestamp) {// Timestamp-et átalakító Date objektummá YYYY-MM-DD
            let date = new Date(timestamp);
            let year = date.getFullYear();
            let month = (date.getMonth() + 1).toString().padStart(2, '0');
            let day = date.getDate().toString().padStart(2, '0');
        
            return `${year}-${month}-${day}`;
        }

            let clickStartTime = 0;
            const maxClickDuration = 200; // 200ms, ennél rövidebb kattintásokat számítunk rövid kattintásnak

            $scope.startClickTimer = () => {
                clickStartTime = new Date().getTime();
            };

            $scope.endClickTimer = ($event, whichValue) => {
                let clickDuration = new Date().getTime() - clickStartTime;
            
                if (clickDuration < maxClickDuration && window.getSelection().toString() === '') {
                    $scope.copyInvoicingInfoPartName(whichValue);
                }
            };

        $scope.copyInvoicingInfoPartName = (whichValue) => {
            var dummy = document.createElement("textarea");
            document.body.appendChild(dummy);
            switch(whichValue){
                case 1:
                    dummy.value = $scope.invoicingInfo.companyData.taxNum;
                    $scope.successCopyText = 'A '+$scope.invoicingInfo.companyData.taxNum+' adószámot vágólapra másoltuk.';
                    break;
                case 2:
                    dummy.value = $scope.invoicingInfo.transactionData.yaltyPaymentId;
                    $scope.successCopyText = 'A '+$scope.invoicingInfo.transactionData.yaltyPaymentId +' rendelésszámot vágólapra másoltuk.';
                    break;
                case 3:
                    dummy.value = formatDate($scope.invoicingInfo.subscriptionData.subscriptionStartTimestamp)+" - "+formatDate($scope.invoicingInfo.subscriptionData.subscriptionEndTimestamp);
                    $scope.successCopyText ="A "+dummy.value+" időszakot vágólapra másoltuk.";
                    break; 
                case 4:
                    dummy.value = "Yalty Hűségkártya szolgáltatás - "+$scope.invoicingInfo.subscriptionData.packageDisplayName +" csomag a "+
                    formatDate($scope.invoicingInfo.subscriptionData.subscriptionStartTimestamp)+" - "+formatDate($scope.invoicingInfo.subscriptionData.subscriptionEndTimestamp)+" időszakra";
                    $scope.successCopyText ="A "+dummy.value+" tétel nevét vágólapra másoltuk.";
                    break; 
                case 5:
                    dummy.value = $scope.invoicingInfo.subscriptionData.paymentFrequency == 1 ? $scope.invoicingInfo.subscriptionData.packageMonthlyNetPriceInCaseOfMonthlyPayment : $scope.invoicingInfo.subscriptionData.paymentFrequency == 2 ?  $scope.invoicingInfo.subscriptionData.packageMonthlyNetPriceInCaseOfYearlyPayment : null;
                    $scope.successCopyText = $scope.invoicingInfo.subscriptionData.paymentFrequency == 1 ? 'A '+$scope.invoicingInfo.subscriptionData.packageMonthlyNetPriceInCaseOfMonthlyPayment+' nettó egységárat vágólapra másoltuk.' : $scope.invoicingInfo.subscriptionData.paymentFrequency == 2 ? 'A '+$scope.invoicingInfo.subscriptionData.packageMonthlyNetPriceInCaseOfYearlyPayment+' nettó egységárat vágólapra másoltuk.' : null;
                    break;
                case 6:
                    dummy.value = $scope.invoicingInfo.transactionData.payedTotal;
                    $scope.successCopyText = 'A '+$scope.invoicingInfo.transactionData.payedTotal+' fizetett (bruttó) értékét vágólapra másoltuk.';
                    break;
                case 7:
                    dummy.value = "A számla már kiegyenlített, pénzügyi teljesítést nem igényel.";
                    $scope.successCopyText = 'A "A számla már kiegyenlített, pénzügyi teljesítést nem igényel." megjegyzés vágólapra másoltuk.';
                    break;
            }
            dummy.select();
            document.execCommand("copy");
            document.body.removeChild(dummy);
            
            if (document.getElementById('okMessageAfterCopy')) {
                document.getElementById('okMessageAfterCopy').style.display = "block";
                $('#okMessageAfterCopy').delay(2000).hide(0)
            }
        }
        
        $scope.getInvoicingInfo = (update: false) => {
            update ? $scope.updateButtonDisabled = true : $scope.getInvoicingInfoButtonDisabled = true;
            $http.post(API_PREFIX.url + '/yaltyadmin/getInvoicingInfo', 
            { partnerPaymentTransactionId: $scope.transactionId }).then(function (result) {
                update ? $scope.updateButtonDisabled = false : $scope.getInvoicingInfoButtonDisabled = false;
                if (result.data.errorCode == 0) { 
                    $scope.invoicingInfo = result.data;
                    $scope.invoicePDFName = $scope.invoicingInfo.transactionData.invoicePDFURL ?
                        $scope.invoicingInfo.transactionData.invoicePDFURL.split('/partner_invoices/')[1] : '';
                    
                    $scope.paymentByToken = $scope.invoicingInfo.transactionData.paymentByToken;
                    $scope.isPostUpload = false;
                    $scope.uploadInvoicingPDFButtonDisabled = true;
                    $scope.emailSendingButtonDisabled = false;
                    $scope.successfulUpload = false;
                    $scope.successfulEmailSending = false;
                    // reset previously selected pdf file
                    $scope.pdf = null;
                    $scope.base64PDF = null;
                } else if (result.data.errorCode == 1) {
                    commonService.showDialogWindowWithTitleOnly('Szerver hiba. Kérjük próbáld meg később újra.', 'OK');
                } else if (result.data.errorCode == 2 || result.data.errorCode == 3) {
                    commonService.showDialogWindow('Sikertelen művelet', 
                        'Nem megfelelő jogosultság a művelet elvégzéséhez. Kiléptetünk az oldalról.', 'OK', () => {
                            $state.go('adminLogin');
                    });
                } else if (result.data.errorCode == 4) {
                    commonService.showDialogWindowWithTitleOnly('Azonosító alapján nincs ilyen tranzakció!', 'OK');
                }
            })
        }

        $scope.openEmailSendingWindow = () => {
            $mdDialog.show({
                template:
                    `<md-dialog> 
                      <md-dialog-content class="md-dialog-content"> 
                        <h2 class="md-title">Biztosan kiküldi a számla emailt a partnernek?</h2>
                        <form id="optionalNoteForm" name="optionalNoteForm" novalidate>
                            <div class="container">
                            <md-input-container class="md-block" style="width: 400px;">
                                <label>Opcionális megjegyzés</label>
                                <input type="text" name="optionalNote" md-maxlength="25" ng-model="optionalNote">
                                <div ng-messages="optionalNoteForm.optionalNote.$error">
                                    <div id="maxlengthErrorInCompStoreName" ng-message="md-maxlength">
                                        Mező maximálisan 25 karakter hosszúságú lehet!
                                    </div>
                                </div>
                            </md-input-container>
                            </div>
                        </form>
                      </md-dialog-content> 
                      <md-dialog-actions> 
                        <md-button ng-click="closeEmailSendingWindow()" class="md-primary"> 
                          Mégsem 
                        </md-button> 
                        <md-button ng-click="sendEmail()" class="md-primary"> 
                          Igen 
                        </md-button> 
                      </md-dialog-actions> 
                    </md-dialog>`,
                controller: emailSendingCtrl
            });
        }

        function emailSendingCtrl($scope, $mdDialog) {
            $scope.sendEmail = () => {
                if ($scope.optionalNoteForm.$invalid === true) return;
                $mdDialog.hide();
                sendEmail($scope.optionalNote);
            }
            $scope.closeEmailSendingWindow = () => {
                $mdDialog.hide();
            }
        }

        function sendEmail(optionalNote) {
            $scope.emailSendingButtonLoading = true;
            const params = {
                paymentTransactionId: $scope.transactionId,
                taxNum: $scope.invoicingInfo.companyData.taxNum,
                packageDisplayName: $scope.invoicingInfo.subscriptionData.packageDisplayName,
                paymentFrequency: $scope.invoicingInfo.subscriptionData.paymentFrequency,
                subscriptionStartTimestamp: $scope.invoicingInfo.subscriptionData.subscriptionStartTimestamp,
                subscriptionEndTimestamp: $scope.invoicingInfo.subscriptionData.subscriptionEndTimestamp,
                optionalNote: optionalNote,
                companyId: $scope.invoicingInfo.transactionData.companyId,
                paymentByToken: $scope.paymentByToken
            }
            $http.post(API_PREFIX.url + '/yaltyadmin/sendInvoiceEmail', params)
            .then(function (result) {
                $scope.emailSendingButtonLoading = false;
                if (result.data.errorCode == 0) {
                    $scope.successfulEmailSending = true
                    $scope.emailSendingButtonDisabled = true;
                    $scope.invoicingInfo.transactionData.lastInvoiceEmailTimestamp = new Date().toISOString();
                } else if (result.data.errorCode == 1) {
                    commonService.showDialogWindowWithTitleOnly('Szerver hiba. Kérjük próbáld meg később újra.', 'OK');
                } else if (result.data.errorCode == 2 || result.data.errorCode == 3) {
                    commonService.showDialogWindow('Sikertelen művelet', 
                        'Nem megfelelő jogosultság a művelet elvégzéséhez. Kiléptetünk az oldalról.', 'OK', () => {
                            $state.go('adminLogin');
                    });
                } else if (result.data.errorCode == 4) {
                    commonService.showDialogWindowWithTitleOnly('Nem található (nincs még feltöltve) számla (PDF) a tranzakcióhoz.', 'OK');
                } else if (result.data.errorCode == 5) {
                    commonService.showDialogWindowWithTitleOnly('A céghez nem található mester felhasználó (companyId alapján).', 'OK');
                }
            })
        }

        if (parseInt($stateParams.transactionId) != 0) {
            $scope.transactionId = parseInt($stateParams.transactionId);
            $scope.getInvoicingInfo();
        }
    })

    .controller('developEnvironmentAccessResetController', function ($scope, $http, API_PREFIX, $mdDialog) {
        $scope.resetAccess = () => {
            let confirm = $mdDialog.confirm()
                .title('Biztos, hogy reseteled a hozzáféréseket? A kapcsolódó adatbáziskörnyezetben (DEV vagy TST) a partneri felhasználók és a YAdmin felhasználók tábla felhasználói újra beállítódnak (a teszt adatbázis táblái szerint) és a jelszavaik prefixe is újrabeállítódik.')
                .ok('OK')
                .cancel('Mégsem');
            $mdDialog.show(confirm).then(function () {
                $http.post(API_PREFIX.url + '/yaltyadmin/yaltyDevEnvironmentsAccessReset', {}).then(function (result) {
                    if (result.data.errorCode == 0) {
                        confirm = $mdDialog.confirm()
                            .title('Sikeres reset.')
                            .ok('OK')
                        $mdDialog.show(confirm)
                    } else {
                        confirm = $mdDialog.confirm()
                            .title('Szerver hiba.')
                            .ok('OK')
                        $mdDialog.show(confirm)
                    }
                })
            }, function () { });
        }
    })

    .controller('userNumberStatsController', function ($scope, $http, API_PREFIX, $mdDialog) {

        function setListHeight() {
            setTimeout(function () {
                if ($scope.userNumberStats != undefined && $scope.userNumberStats.length != 0) {
                    $scope.userNumberStatsTableHeight = { 'height': '380px' };
                }
                if ($scope.appUsageStats != undefined && $scope.appUsageStats.length != 0) {
                    $scope.appUsageStatsTableHeight = { 'height': '300px'};
                }
                if($scope.contentType == 3){
                    if ($scope.retentionStat1 != undefined && $scope.retentionStat1.length != 0) {
                        let table = document.getElementById("stat1Table");
                        $scope.retentionStat1TableHeight = { 'height': (table.offsetHeight + 18) + 'px'}; //Infó box miatt +18px hozzáadva
                    }
                    if ($scope.retentionStat2 != undefined && $scope.retentionStat2.length != 0) {
                        let table = document.getElementById("stat2Table");
                        $scope.retentionStat2TableHeight = { 'height': table.offsetHeight + 'px'};
                    }
                    if ($scope.retentionStat3 != undefined && $scope.retentionStat3.length != 0) {
                        let table = document.getElementById("stat3Table");
                        $scope.retentionStat3TableHeight = { 'height': table.offsetHeight + 'px'};
                    }
                }
            }, 100);
        }

        setListHeight();
        window.onresize = function (event) {
            setListHeight();
        };

        let uStats = $scope.userNumberStats[0]

        $scope.colors1 = [{
            backgroundColor: '#00AEEF',
            pointBackgroundColor: '#00AEEF',
            pointHoverBackgroundColor: '#00AEEF',
            borderColor: '#00AEEF',
            pointBorderColor: '#00AEEF',
            pointHoverBorderColor: '#00AEEF'
        }]

        $scope.colors2 = ["#00AEEF", "#DFDFDF", "#4E525C"];
        $scope.colorsForStat1 = ["#4E525C", "#00AEEF", "#ED7D31", "#DFDFDF"];
        $scope.colorsForStat2 = ["#4E525C", "#00AEEF", "#ED7D31", "#DFDFDF"];
        $scope.colorsForStat3_1 = ["#4E525C", "#00AEEF", "#ED7D31", "#DFDFDF"];

        $scope.mainUserStats = []
        /* for testing bigger user numbers
        uStats.totalUserRegNr = 10000000
        uStats.totalAnonymRegNr = 4000000
        uStats.totalFullUserRegNr = 6000000
        uStats.totalFullUserEmailRegNr = 1000000
        uStats.totalFullUserFBRegNr = 4000000
        uStats.totalFullUserGoogleRegNr = 1000000*/
        $scope.mainUserStats.push(
            {
                number: uStats.totalUserRegNr.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                percent: '',
                label: 'Felhasználók össz.',
                style: { 'background-color': '#00AEEF', 'color': 'white', 'font-size': '16px' }
            },
            {
                number: `${uStats.totalAnonymRegNr.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
                percent: ` (${((uStats.totalAnonymRegNr / uStats.totalUserRegNr) * 100).toFixed(1)}%)`,
                label: 'Anonim',
                percentSymbol: ' (%)',
                style: { 'background-color': '#ADE9FF', 'color': '#4E525C' }
            },
            {
                number: `${uStats.totalFullUserRegNr.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
                percent: ` (${((uStats.totalFullUserRegNr / uStats.totalUserRegNr) * 100).toFixed(1)}%)`,
                label: 'Teljes',
                percentSymbol: ' (%)',
                style: { 'background-color': '#ADE9FF', 'color': '#4E525C' }
            },
            {
                number: `${uStats.totalFullUserEmailRegNr.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
                percent: ` (${((uStats.totalFullUserEmailRegNr / uStats.totalFullUserRegNr) * 100).toFixed(1)}%)`,
                label: 'Teljes - Email',
                percentSymbol: ' (%)',
                style: { 'background-color': '#DFDFDF', 'color': '#4E525C' }
            },
            {
                number: `${uStats.totalFullUserFBRegNr.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
                percent:` (${((uStats.totalFullUserFBRegNr / uStats.totalFullUserRegNr) * 100).toFixed(1)}%)`,
                label: 'Teljes - Facebook',
                percentSymbol: ' (%)',
                style: { 'background-color': '#DFDFDF', 'color': '#4E525C' }
            },
            {
                number: `${uStats.totalFullUserGoogleRegNr.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
                percent: ` (${((uStats.totalFullUserGoogleRegNr / uStats.totalFullUserRegNr) * 100).toFixed(1)}%)`,
                label: 'Teljes - Google',
                percentSymbol: ' (%)',
                style: { 'background-color': '#DFDFDF', 'color': '#4E525C' }
            }
        )

        $scope.allYaltyCity = $scope.yaltyCities.listOfCities;
        for (let i = 0; i < $scope.allYaltyCity.length; i++) {
            $scope.allYaltyCity[i].id = i;
        }
        $scope.checkAllYaltyCity = true;
        $scope.selectedYaltyCities = [];
        for (let i = 0; i < $scope.allYaltyCity.length; i++) {
            $scope.selectedYaltyCities.push($scope.allYaltyCity[i].id);
        }

        $scope.periodList = [
            { id: 1, period: 'Teljes időszak' },
            { id: 2, period: 'Aktuális hónap' },
            { id: 3, period: 'Múlt hónap' },
            { id: 4, period: 'Elmúlt 30 nap' },
            { id: 5, period: 'Elmúlt 60 nap' },
            { id: 6, period: 'Elmúlt 90 nap' },
            { id: 7, period: 'Megadott időszak' }
        ];

        $scope.retentionPeriodList = [
            { id: 8, period: 'Elmúlt 10 időszak' },
            { id: 7, period: 'Megadott időszak' }
        ];

        $scope.retentionPeriodListForStat3 = [
            { id: 8, period: 'Előző 9 időszak' },
            { id: 9, period: 'Előző 6 időszak' },
            { id: 7, period: 'Megadott időszak' }
        ];

        $scope.retentionGroupList = [
            { id: 0, name: 'Összes' },
            { id: 1, name: 'Hűségkártyás' },
            { id: 2, name: 'Kuponos' },
            { id: 3, name: 'Egyéb' }
        ];

        $scope.retentionGroup = 0;

        $scope.aggregationTypes = [
            { id: 5, type: 'Nincs' },
            { id: 1, type: 'Napi' },
            { id: 2, type: 'Heti' },
            { id: 3, type: 'Havi' },
            { id: 4, type: 'Éves' }
        ]

        $scope.retentionAggregationTypes = [
            { id: 2, type: 'Heti' },
            { id: 3, type: 'Havi' }
        ]

        $scope.maxDateForStat3Week = moment().subtract(1, 'week').endOf('week').toDate();
        $scope.maxDateForStat3Month = moment().subtract(1, 'month').endOf('month').toDate();
        $scope.period = 6
        $scope.retentionPeriod = 8
        $scope.aggregationType = 1
        $scope.retentionAggregationType = 3

        $scope.startYearArray = []
        $scope.endYearArray = []

        let firstYear = 2018
        let lastYear = new Date().getFullYear()
        let index = 0
        for (let i = lastYear; i >= firstYear; i--) {
            let currentDate = new Date(i, 0)
            $scope.startYearArray.push({
                id: index,
                year: i,
                value: moment(currentDate).startOf('year').toDate()
            })
            let endOfYear = moment(currentDate).endOf('year').toDate();
            endOfYear.setHours(23, 59, 59, 999);
            $scope.endYearArray.push({
                id: index,
                year: i,
                value: endOfYear
            })
            index++
        }
        /*$scope.startYearArray.push({
            id: -1,
            year: 'Nincs megadva',
            value: null
        })
        $scope.endYearArray.push({
            id: -1,
            year: 'Nincs megadva',
            value: null
        })*/

        $scope.labels = [];
        $scope.appUsageLabels = []
        $scope.series = ['Össz új regisztrációk db'];
        $scope.appUsageSeries = ['Össz felhasználószám db', 'Össz eszközszám db', 'Össz adatfrissítés db'];
        $scope.data = []
        $scope.appUsageData = []
        $scope.chartOptions = {
            scales: {
                yAxes: [{ 
                    ticks: {
                        beginAtZero: true,
                        precision: 0
                        //max: 0,
                        //maxTicksLimit: 5,
                        /*callback: function(value) {
                            if (value % 1 === 0) {
                                return value;
                            }
                        }*/
                    }
                }]/*,
                xAxes: [{
                    maxBarThickness: 30
                }]*/
            },/*,
            responsive: false,*/
            maintainAspectRatio: false
        }
        $scope.appUsageChartOptions = {
            scales: {
                yAxes: [{ 
                    ticks: {
                        beginAtZero: true,
                        precision: 0
                    }
                }]
            },
            legend: {
                display: true,
                position: 'right',
                onClick: function(){}
            },
            maintainAspectRatio: false
        }

        $scope.stat1ChartOptions = {
            scales: {
                yAxes: [{ 
                    ticks: {
                        beginAtZero: true,
                        precision: 0,
                        maxValueForYAxis: 100
                    }
                }]
            },
            legend: {
                display: true,
                position: 'right',
                onClick: function(){}
            },
            maintainAspectRatio: false
        }

        $scope.stat2ChartOptions = {
            scales: {
                yAxes: [{ 
                    ticks: {
                        beginAtZero: true,
                        precision: 0,
                        maxValueForYAxis: 100
                    }
                }]
            },
            legend: {
                display: true,
                position: 'right',
                onClick: function(){}
            },
            maintainAspectRatio: false
        }

        $scope.stat3_1ChartOptions = {
            scales: {
                yAxes: [{ 
                    ticks: {
                        beginAtZero: true,
                        precision: 0,
                        maxValueForYAxis: 100
                    }
                }]
            },
            legend: {
                display: true,
                position: 'right',
                onClick: function(){}
            },
            maintainAspectRatio: false
        }

        $scope.stat3_2ChartOptions = {
            scales: {
                yAxes: [{ 
                    ticks: {
                        beginAtZero: true,
                        precision: 0,
                        maxValueForYAxis: 100
                    }
                }]
            },
            legend: {
                display: true,
                position: 'right',
                onClick: function(){}
            },
            maintainAspectRatio: false
        }

        $scope.checkEarlyDate = () => {
            if($scope.contentType == 3){
                let endDateForStat3;
                if($scope.retentionAggregationType == 2){
                    endDateForStat3 = moment().subtract(1, 'week').endOf('week').toDate();
                } else {
                    endDateForStat3 = moment().subtract(1, 'month').endOf('month').toDate();
                }
                if($scope.sameAsMainPeriod1 == true){
                    if($scope.retentionAggregationType == 2){
                        $scope.fromTimestampWeekForStat1 = $scope.fromTimestampWeek;
                        $scope.untilTimestampWeekForStat1 = $scope.untilTimestampWeek;
                    } else if($scope.retentionAggregationType == 3){
                        $scope.fromTimestampMonthForStat1 = $scope.fromTimestampMonth;
                        $scope.untilTimestampMonthForStat1 = $scope.untilTimestampMonth;
                    }
                }
                if($scope.sameAsMainPeriod2 == true){
                    if($scope.retentionAggregationType == 2){
                        $scope.fromTimestampWeekForStat2 = $scope.fromTimestampWeek;
                        $scope.untilTimestampWeekForStat2 = $scope.untilTimestampWeek;
                    } else if($scope.retentionAggregationType == 3){
                        $scope.fromTimestampMonthForStat2 = $scope.fromTimestampMonth;
                        $scope.untilTimestampMonthForStat2 = $scope.untilTimestampMonth;
                    }
                }
                if($scope.sameAsMainPeriod3 == true){
                    if($scope.retentionAggregationType == 2){
                        $scope.fromTimestampWeekForStat3 = $scope.fromTimestampWeek;
                        if(endDateForStat3 < $scope.untilTimestampWeek){
                            $scope.untilTimestampWeekForStat3 = endDateForStat3;
                        } else {
                            $scope.untilTimestampWeekForStat3 = $scope.untilTimestampWeek;
                        }
                    } else if($scope.retentionAggregationType == 3){
                        $scope.fromTimestampMonthForStat3 = $scope.fromTimestampMonth;
                        if(endDateForStat3 < $scope.untilTimestampMonth){
                            setTimeout(function () {
                                $scope.untilTimestampMonthForStat3 = endDateForStat3;
                            }, 0)
                        } else {
                            $scope.untilTimestampMonthForStat3 = $scope.untilTimestampMonth;
                        }
                    }
                }
                $scope.userNumberStatsForm.fromTimestampWeek.$setValidity('minPeriodUnits', true);
                $scope.userNumberStatsForm.untilTimestampWeek.$setValidity('minPeriodUnits', true);
                $scope.userNumberStatsForm.fromTimestampMonth.$setValidity('minPeriodUnits', true);
                $scope.userNumberStatsForm.untilTimestampMonth.$setValidity('minPeriodUnits', true);
                $scope.userNumberSubStat2Form.fromTimestampWeekForStat2.$setValidity('minPeriodUnits', true);
                $scope.userNumberSubStat2Form.untilTimestampWeekForStat2.$setValidity('minPeriodUnits', true);
                $scope.userNumberSubStat2Form.fromTimestampMonthForStat2.$setValidity('minPeriodUnits', true);
                $scope.userNumberSubStat2Form.untilTimestampMonthForStat2.$setValidity('minPeriodUnits', true);
            }
            if (($scope.contentType == 2 || $scope.contentType == 1) && ($scope.period == 7 || $scope.period == 1) && $scope.selectedYaltyCities.length > 0 && $scope.selectedYaltyCities.length != $scope.allYaltyCity.length) {
                const startDate = new Date(2018, 5, 19)
                if (($scope.aggregationType == 1 || $scope.aggregationType == 5) && 
                    (($scope.fromTimestamp && $scope.fromTimestamp < startDate) ||
                    ($scope.fromTimestamp == null))) {
                        $scope.showEarlyDateError = true
                } else if ($scope.aggregationType == 2 && 
                    (($scope.fromTimestampWeek && $scope.fromTimestampWeek < startDate) ||
                    ($scope.fromTimestampWeek == null))) {
                        $scope.showEarlyDateError = true
                } else if ($scope.aggregationType == 3 && 
                    (($scope.fromTimestampMonth && $scope.fromTimestampMonth < startDate) ||
                    ($scope.fromTimestampMonth == null))) {
                        $scope.showEarlyDateError = true
                } else if ($scope.aggregationType == 4 && 
                    (($scope.fromTimestampYear && $scope.startYearArray[$scope.fromTimestampYear].value < startDate) ||
                    ($scope.fromTimestampYear == null))) {
                        $scope.showEarlyDateError = true
                } else {
                    $scope.showEarlyDateError = false
                }
            } else if($scope.contentType == 3 && $scope.selectedYaltyCities.length > 0 && $scope.selectedYaltyCities.length != $scope.allYaltyCity.length){
                const startDate = new Date(2018, 5, 19)
                if($scope.retentionPeriod == 7){
                    if ($scope.retentionAggregationType == 2 && 
                        (($scope.fromTimestampWeek && $scope.fromTimestampWeek < startDate) ||
                        ($scope.fromTimestampWeek == null))) {
                            $scope.showEarlyDateError = true
                    } else if ($scope.retentionAggregationType == 3 && 
                        (($scope.fromTimestampMonth && $scope.fromTimestampMonth < startDate) ||
                        ($scope.fromTimestampMonth == null))) {
                            $scope.showEarlyDateError = true
                    } else {
                        $scope.showEarlyDateError = false
                    }
                } else {
                    $scope.showEarlyDateError = false
                }
                if($scope.periodForStat1 == 7){
                    if ($scope.retentionAggregationType == 2 && 
                        (($scope.fromTimestampWeekForStat1 && $scope.fromTimestampWeekForStat1 < startDate) ||
                        ($scope.fromTimestampWeekForStat1 == null))) {
                            $scope.showEarlyDateErrorForStat1 = true
                    } else if ($scope.retentionAggregationType == 3 && 
                        (($scope.fromTimestampMonthForStat1 && $scope.fromTimestampMonthForStat1 < startDate) ||
                        ($scope.fromTimestampMonthForStat1 == null))) {
                            $scope.showEarlyDateErrorForStat1 = true
                    } else {
                        $scope.showEarlyDateErrorForStat1 = false
                    }
                } else {
                    $scope.showEarlyDateErrorForStat1 = false;
                }
                if($scope.periodForStat2 == 7){
                    if ($scope.retentionAggregationType == 2 && 
                        (($scope.fromTimestampWeekForStat2 && $scope.fromTimestampWeekForStat2 < startDate) ||
                        ($scope.fromTimestampWeekForStat2 == null))) {
                            $scope.showEarlyDateErrorForStat2 = true
                    } else if ($scope.retentionAggregationType == 3 && 
                        (($scope.fromTimestampMonthForStat2 && $scope.fromTimestampMonthForStat2 < startDate) ||
                        ($scope.fromTimestampMonthForStat2 == null))) {
                            $scope.showEarlyDateErrorForStat2 = true
                    } else {
                        $scope.showEarlyDateErrorForStat2 = false
                    }
                } else {
                    $scope.showEarlyDateErrorForStat2 = false;
                }
                if($scope.periodForStat3 == 7){
                    if ($scope.retentionAggregationType == 2 && 
                        (($scope.fromTimestampWeekForStat3 && $scope.fromTimestampWeekForStat3 < startDate) ||
                        ($scope.fromTimestampWeekForStat3 == null))) {
                            $scope.showEarlyDateErrorForStat3 = true
                    } else if ($scope.retentionAggregationType == 3 && 
                        (($scope.fromTimestampMonthForStat3 && $scope.fromTimestampMonthForStat3 < startDate) ||
                        ($scope.fromTimestampMonthForStat3 == null))) {
                            $scope.showEarlyDateErrorForStat3 = true
                    } else {
                        $scope.showEarlyDateErrorForStat3 = false
                    }
                } else {
                    $scope.showEarlyDateErrorForStat3 = false;
                }
            } else {
                $scope.showEarlyDateError = false
                $scope.showEarlyDateErrorForStat1 = false
                $scope.showEarlyDateErrorForStat2 = false
                $scope.showEarlyDateErrorForStat3 = false
            }
        }

        $scope.setSelectedTextPeriodFrom = () => {
            if ($scope.fromTimestampYear != null) {
                return `${$scope.startYearArray[$scope.fromTimestampYear].year}. január 01.`
            } else {
                return 'Időszak -Tól'
            }
        }

        $scope.setSelectedTextPeriodUntil = () => {
            if ($scope.untilTimestampYear != null) {
                return `${$scope.endYearArray[$scope.untilTimestampYear].year}. december 31.`
            } else {
                return 'Időszak -Ig'
            }
        }

        $scope.getSelectedCities = function () {
            if ($scope.selectedYaltyCities == null || $scope.selectedYaltyCities.length == 0) {
                return "Yalty város"
            } else if ($scope.selectedYaltyCities.length != $scope.allYaltyCity.length) {
                let text = "";
                for (let i = 0; i < $scope.selectedYaltyCities.length; i++) {
                    text += $scope.allYaltyCity[$scope.selectedYaltyCities[i]].placeName;
                    if ((i + 1) != $scope.selectedYaltyCities.length) {
                        text += ", "
                    }
                }
                return text;
            } else {
                return "Összes";
            }
        };

        $scope.selectYaltyCity = function () {
            if ($scope.selectedYaltyCities == null || $scope.selectedYaltyCities.length != $scope.allYaltyCity.length) {
                $scope.checkAllYaltyCity = false;
            } else {
                $scope.checkAllYaltyCity = true;
            }
            $scope.checkEarlyDate()
        };

        $scope.setAllOptions = function (checkValue, model) {
            if (checkValue == true) {
                if (model == "selectedYaltyCities") {
                    $scope.selectedYaltyCities = [];
                    for (let i = 0; i < $scope.allYaltyCity.length; i++) {
                        $scope.selectedYaltyCities.push($scope.allYaltyCity[i].id);
                    }
                }
            } else {
                if (model == "selectedYaltyCities") {
                    $scope.selectedYaltyCities = [];
                } else if (model == "adminId") {
                    $scope.adminId = [];
                }
            }
            $scope.checkEarlyDate()
        }

        const setAggregationAfterPeriodChanges = () => {
            if ($scope.period == 3) {
                if ($scope.aggregationType != 1 &&
                    $scope.aggregationType != 3 &&
                    $scope.aggregationType != 5) {
                    $scope.aggregationType = 1
                }
            }
            else {
                if ($scope.aggregationType != 1 &&
                    $scope.aggregationType != 5) {
                    $scope.aggregationType = 1
                }
            }
        }

        $scope.setDatesAfterPeriodChanged = function () {
            if ($scope.contentType != 3) {
                if ($scope.period == 1) {
                    $scope.fromTimestamp = null;
                    $scope.untilTimestamp = null;
                } else if ($scope.period == 2) {
                    let startOfMonth = moment().startOf('month').toDate();
                    let endOfToday = new Date();
                    endOfToday.setHours(23, 59, 59, 999);
                    $scope.fromTimestamp = startOfMonth;
                    $scope.untilTimestamp = endOfToday;
                    setAggregationAfterPeriodChanges()
                } else if ($scope.period == 3) {
                    let startOfLastMonth = moment().subtract(1, 'months').startOf('month').toDate();
                    let endOfLastMonth = moment().subtract(1, 'months').endOf('month').toDate();
                    $scope.fromTimestamp = startOfLastMonth;
                    $scope.untilTimestamp = endOfLastMonth;
                    $scope.fromTimestampMonth = startOfLastMonth;
                    $scope.untilTimestampMonth = endOfLastMonth;
                    setAggregationAfterPeriodChanges()
                } else if ($scope.period == 4) {
                    let date = new Date();
                    date.setHours(0, 0, 0, 0);
                    date.setDate(date.getDate() - 29);
                    let endOfToday = new Date();
                    endOfToday.setHours(23, 59, 59, 999);
                    $scope.fromTimestamp = date;
                    $scope.untilTimestamp = endOfToday;
                    setAggregationAfterPeriodChanges()
                } else if ($scope.period == 5) {
                    let date = new Date();
                    date.setHours(0, 0, 0, 0);
                    date.setDate(date.getDate() - 59);
                    let endOfToday = new Date();
                    endOfToday.setHours(23, 59, 59, 999);
                    $scope.fromTimestamp = date;
                    $scope.untilTimestamp = endOfToday;
                    setAggregationAfterPeriodChanges()
                } else if ($scope.period == 6) {
                    let date = new Date();
                    date.setHours(0, 0, 0, 0);
                    date.setDate(date.getDate() - 89);
                    let endOfToday = new Date();
                    endOfToday.setHours(23, 59, 59, 999);
                    $scope.fromTimestamp = date;
                    $scope.untilTimestamp = endOfToday;
                    setAggregationAfterPeriodChanges()
                }
            } else {
                if ($scope.retentionPeriod == 8) {
                    if ($scope.retentionAggregationType == 2) {
                        let tenWeeksEarlier = new Date()
                        tenWeeksEarlier.setDate(tenWeeksEarlier.getDate() - 63)
                        tenWeeksEarlier = moment(tenWeeksEarlier).startOf('week').toDate();
                        let endOfWeek = new Date();
                        endOfWeek = moment(endOfWeek).endOf('week').toDate();
                        endOfWeek.setHours(23, 59, 59, 999);
                        $scope.fromTimestampWeek = tenWeeksEarlier;
                        $scope.untilTimestampWeek = endOfWeek;
                    } else {
                        let tenMonthEarlier = new Date()
                        tenMonthEarlier.setMonth(tenMonthEarlier.getMonth() - 9)
                        tenMonthEarlier = moment(tenMonthEarlier).startOf('month').toDate();
                        let endOfMonth = new Date();
                        endOfMonth = moment(endOfMonth).endOf('month').toDate();
                        endOfMonth.setHours(23, 59, 59, 999);
                        $scope.fromTimestampMonth = tenMonthEarlier;
                        $scope.untilTimestampMonth = endOfMonth;
                    }
                }
                let endDateForStat3;
                if($scope.retentionAggregationType == 2){
                    endDateForStat3 = moment().subtract(1, 'week').endOf('week').toDate();
                } else {
                    endDateForStat3 = moment().subtract(1, 'month').endOf('month').toDate();
                }
                if($scope.sameAsMainPeriod1 == true){
                    $scope.periodForStat1 = $scope.retentionPeriod;
                    if($scope.retentionAggregationType == 2){
                        $scope.fromTimestampWeekForStat1 = $scope.fromTimestampWeek;
                        $scope.untilTimestampWeekForStat1 = $scope.untilTimestampWeek;
                    } else if($scope.retentionAggregationType == 3){
                        $scope.fromTimestampMonthForStat1 = $scope.fromTimestampMonth;
                        $scope.untilTimestampMonthForStat1 = $scope.untilTimestampMonth;
                    }
                }
                if($scope.sameAsMainPeriod2 == true){
                    $scope.periodForStat2 = $scope.retentionPeriod;
                    if($scope.retentionAggregationType == 2){
                        $scope.fromTimestampWeekForStat2 = $scope.fromTimestampWeek;
                        $scope.untilTimestampWeekForStat2 = $scope.untilTimestampWeek;
                    } else if($scope.retentionAggregationType == 3){
                        $scope.fromTimestampMonthForStat2 = $scope.fromTimestampMonth;
                        $scope.untilTimestampMonthForStat2 = $scope.untilTimestampMonth;
                    }
                }
                if($scope.sameAsMainPeriod3 == true){
                    $scope.periodForStat3 = $scope.retentionPeriod;
                    if($scope.retentionAggregationType == 2){
                        $scope.fromTimestampWeekForStat3 = $scope.fromTimestampWeek;
                        if(endDateForStat3 < $scope.untilTimestampWeek){
                            $scope.untilTimestampWeekForStat3 = endDateForStat3;
                        } else {
                            $scope.untilTimestampWeekForStat3 = $scope.untilTimestampWeek;
                        }
                    } else if($scope.retentionAggregationType == 3){
                        $scope.fromTimestampMonthForStat3 = $scope.fromTimestampMonth;
                        if(endDateForStat3 < $scope.untilTimestampMonth){
                            $scope.untilTimestampMonthForStat3 = endDateForStat3;
                        } else {
                            $scope.untilTimestampMonthForStat3 = $scope.untilTimestampMonth;
                        }
                    }
                }
            }
            $scope.checkEarlyDate();
        }

        $scope.setDatesAfterPeriodChangedForStat1 = function () {
            if ($scope.periodForStat1 == 8) {
                if ($scope.retentionAggregationType == 2) {
                    let tenWeeksEarlier = new Date()
                    tenWeeksEarlier.setDate(tenWeeksEarlier.getDate() - 63)
                    tenWeeksEarlier = moment(tenWeeksEarlier).startOf('week').toDate();
                    let endOfWeek = new Date();
                    endOfWeek = moment(endOfWeek).endOf('week').toDate();
                    endOfWeek.setHours(23, 59, 59, 999);
                    $scope.fromTimestampWeekForStat1 = tenWeeksEarlier;
                    $scope.untilTimestampWeekForStat1 = endOfWeek;
                } else {
                    let tenMonthEarlier = new Date()
                    tenMonthEarlier.setMonth(tenMonthEarlier.getMonth() - 9)
                    tenMonthEarlier = moment(tenMonthEarlier).startOf('month').toDate();
                    let endOfMonth = new Date();
                    endOfMonth = moment(endOfMonth).endOf('month').toDate();
                    endOfMonth.setHours(23, 59, 59, 999);
                    $scope.fromTimestampMonthForStat1 = tenMonthEarlier;
                    $scope.untilTimestampMonthForStat1 = endOfMonth;
                }
            }
            $scope.checkEarlyDate();
        }

        $scope.setDatesAfterPeriodChangedForStat2 = function () {
            if ($scope.periodForStat2 == 8) {
                if ($scope.retentionAggregationType == 2) {
                    let tenWeeksEarlier = new Date()
                    tenWeeksEarlier.setDate(tenWeeksEarlier.getDate() - 63)
                    tenWeeksEarlier = moment(tenWeeksEarlier).startOf('week').toDate();
                    let endOfWeek = new Date();
                    endOfWeek = moment(endOfWeek).endOf('week').toDate();
                    endOfWeek.setHours(23, 59, 59, 999);
                    $scope.fromTimestampWeekForStat2 = tenWeeksEarlier;
                    $scope.untilTimestampWeekForStat2 = endOfWeek;
                } else {
                    let tenMonthEarlier = new Date()
                    tenMonthEarlier.setMonth(tenMonthEarlier.getMonth() - 9)
                    tenMonthEarlier = moment(tenMonthEarlier).startOf('month').toDate();
                    let endOfMonth = new Date();
                    endOfMonth = moment(endOfMonth).endOf('month').toDate();
                    endOfMonth.setHours(23, 59, 59, 999);
                    $scope.fromTimestampMonthForStat2 = tenMonthEarlier;
                    $scope.untilTimestampMonthForStat2 = endOfMonth;
                }
            }
            $scope.checkEarlyDate();
        }

        $scope.setDatesAfterPeriodChangedForStat3 = function () {
            if ($scope.periodForStat3 == 8) {
                if ($scope.retentionAggregationType == 2) {
                    let tenWeeksEarlier = new Date()
                    tenWeeksEarlier.setDate(tenWeeksEarlier.getDate() - 63)
                    tenWeeksEarlier = moment(tenWeeksEarlier).startOf('week').toDate();
                    let endOfWeek = new Date();
                    endOfWeek.setDate(endOfWeek.getDate() - 7)
                    endOfWeek = moment(endOfWeek).endOf('week').toDate();
                    endOfWeek.setHours(23, 59, 59, 999);
                    $scope.fromTimestampWeekForStat3 = tenWeeksEarlier;
                    $scope.untilTimestampWeekForStat3 = endOfWeek;
                } else {
                    let tenMonthEarlier = new Date()
                    tenMonthEarlier.setMonth(tenMonthEarlier.getMonth() - 9)
                    tenMonthEarlier = moment(tenMonthEarlier).startOf('month').toDate();
                    let endOfMonth = new Date();
                    endOfMonth.setMonth(endOfMonth.getMonth() - 1)
                    endOfMonth = moment(endOfMonth).endOf('month').toDate();
                    endOfMonth.setHours(23, 59, 59, 999);
                    $scope.fromTimestampMonthForStat3 = tenMonthEarlier;
                    $scope.untilTimestampMonthForStat3 = endOfMonth;

                }
            } else if($scope.periodForStat3 == 9){
                if ($scope.retentionAggregationType == 2) {
                    let sevenWeeksEarlier = new Date()
                    sevenWeeksEarlier.setDate(sevenWeeksEarlier.getDate() - 42)
                    sevenWeeksEarlier = moment(sevenWeeksEarlier).startOf('week').toDate();
                    let endOfWeek = new Date();
                    endOfWeek.setDate(endOfWeek.getDate() - 7)
                    endOfWeek = moment(endOfWeek).endOf('week').toDate();
                    endOfWeek.setHours(23, 59, 59, 999);
                    $scope.fromTimestampWeekForStat3 = sevenWeeksEarlier;
                    $scope.untilTimestampWeekForStat3 = endOfWeek;
                } else {
                    let sevenMonthEarlier = new Date()
                    sevenMonthEarlier.setMonth(sevenMonthEarlier.getMonth() - 6)
                    sevenMonthEarlier = moment(sevenMonthEarlier).startOf('month').toDate();
                    let endOfMonth = new Date();
                    endOfMonth.setMonth(endOfMonth.getMonth() - 1)
                    endOfMonth = moment(endOfMonth).endOf('month').toDate();
                    endOfMonth.setHours(23, 59, 59, 999);
                    $scope.fromTimestampMonthForStat3 = sevenMonthEarlier;
                    $scope.untilTimestampMonthForStat3 = endOfMonth;
                }
            }
            $scope.checkEarlyDate();
        }

        $scope.setDatesAfterPeriodChanged()

        $scope.setDatesAfterAggregationChanged = (previousAggrType) => {
            if ($scope.contentType != 3) {
                if ($scope.period == 7) {
                    setDatesByAggregation(previousAggrType)
                } else {
                    if ($scope.period != 1 && ($scope.aggregationType == 2 || ($scope.period != 3 && $scope.aggregationType == 3) || $scope.aggregationType == 4)) {
                        setDatesByAggregation(previousAggrType)
                        $scope.period = 7
                    } else if ($scope.period == 3 && $scope.aggregationType == 3) {
                        setDatesByAggregation(previousAggrType)
                    }
                }
            } else {
                if ($scope.retentionPeriod == 7) {
                    setDatesByAggregation(previousAggrType)
                } else {
                    $scope.setDatesAfterPeriodChanged()
                }
            }
        }

        const setDatesByAggregation = (previousAggrType) => {
            let dates = getDateFromAggrType(previousAggrType)
            if ($scope.contentType != 3) {
                if ($scope.aggregationType == 1) {
                    if (dates.fromTimestamp) {
                        let startOfDay = moment(dates.fromTimestamp).startOf('day').toDate();
                        $scope.fromTimestamp = startOfDay;
                    }
                    if (dates.untilTimestamp) {
                        let endOfDay = moment(dates.untilTimestamp).endOf('day').toDate();
                        endOfDay.setHours(23, 59, 59, 999);
                        $scope.untilTimestamp = endOfDay;
                    }
                } else if ($scope.aggregationType == 2) {
                    if (dates.fromTimestamp) {
                        let startOfWeek = moment(dates.fromTimestamp).startOf('week').toDate();
                        $scope.fromTimestampWeek = startOfWeek;
                    } else {
                        $scope.fromTimestampWeek = null;
                    }
                    if (dates.untilTimestamp) {
                        let endOfWeek = moment(dates.untilTimestamp).endOf('week').toDate();
                        endOfWeek.setHours(23, 59, 59, 999);
                        $scope.untilTimestampWeek = endOfWeek;
                    } else {
                        $scope.untilTimestampWeek = null;
                    }
                } else if ($scope.aggregationType == 3) {
                    if (dates.fromTimestamp) {
                        let startOfMonth = moment(dates.fromTimestamp).startOf('month').toDate();
                        $scope.fromTimestampMonth = startOfMonth;
                    } else {
                        $scope.fromTimestampMonth = null;
                    }
                    if (dates.untilTimestamp) {
                        let endOfMonth = moment(dates.untilTimestamp).endOf('month').toDate();
                        endOfMonth.setHours(23, 59, 59, 999);
                        $scope.untilTimestampMonth = endOfMonth;
                    } else {
                        $scope.untilTimestampMonth = null;
                    }
                } else if ($scope.aggregationType == 4) {
                    if (dates.fromTimestamp) {
                        let year = new Date(dates.fromTimestamp).getFullYear()
                        let index = $scope.startYearArray.findIndex(obj => obj.year == year)
                        if (index != -1) $scope.fromTimestampYear = $scope.startYearArray[index].id
                    } else {
                        $scope.fromTimestampYear = null;
                    }
                    if (dates.untilTimestamp) {
                        let year = new Date(dates.untilTimestamp).getFullYear()
                        let index = $scope.endYearArray.findIndex(obj => obj.year == year)
                        if (index != -1) $scope.untilTimestampYear = $scope.endYearArray[index].id
                    } else {
                        $scope.untilTimestampYear = null;
                    }
                } else if ($scope.aggregationType == 5) {
                    if (dates.fromTimestamp) {
                        $scope.fromTimestamp = dates.fromTimestamp;
                    }
                    if (dates.untilTimestamp) {
                        $scope.untilTimestamp = dates.untilTimestamp;
                    }
                } 
            } else {
                if ($scope.retentionAggregationType == 2) {
                    if (dates.fromTimestamp) {
                        let startOfWeek = moment(dates.fromTimestamp).startOf('week').toDate();
                        $scope.fromTimestampWeek = startOfWeek;
                    } else {
                        $scope.fromTimestampWeek = null;
                    }
                    if (dates.untilTimestamp) {
                        let endOfWeek = moment(dates.untilTimestamp).endOf('week').toDate();
                        endOfWeek.setHours(23, 59, 59, 999);
                        $scope.untilTimestampWeek = endOfWeek;
                    } else {
                        $scope.untilTimestampWeek = null;
                    }
                } else if ($scope.retentionAggregationType == 3) {
                    if (dates.fromTimestamp) {
                        let startOfMonth = moment(dates.fromTimestamp).startOf('month').toDate();
                        $scope.fromTimestampMonth = startOfMonth;
                    } else {
                        $scope.fromTimestampMonth = null;
                    }
                    if (dates.untilTimestamp) {
                        let endOfMonth = moment(dates.untilTimestamp).endOf('month').toDate();
                        endOfMonth.setHours(23, 59, 59, 999);
                        $scope.untilTimestampMonth = endOfMonth;
                    } else {
                        $scope.untilTimestampMonth = null;
                    }
                }
                let endDateForStat3;
                if($scope.retentionAggregationType == 2){
                    endDateForStat3 = moment().subtract(1, 'week').endOf('week').toDate();
                } else {
                    endDateForStat3 = moment().subtract(1, 'month').endOf('month').toDate();
                } 
                if($scope.sameAsMainPeriod1 == true){
                    $scope.periodForStat1 = $scope.retentionPeriod;
                    if($scope.retentionAggregationType == 2){
                        $scope.fromTimestampWeekForStat1 = $scope.fromTimestampWeek;
                        $scope.untilTimestampWeekForStat1 = $scope.untilTimestampWeek;
                    } else if($scope.retentionAggregationType == 3){
                        $scope.fromTimestampMonthForStat1 = $scope.fromTimestampMonth;
                        $scope.untilTimestampMonthForStat1 = $scope.untilTimestampMonth;
                    }
                }
                if($scope.sameAsMainPeriod2 == true){
                    $scope.periodForStat2 = $scope.retentionPeriod;
                    if($scope.retentionAggregationType == 2){
                        $scope.fromTimestampWeekForStat2 = $scope.fromTimestampWeek;
                        $scope.untilTimestampWeekForStat2 = $scope.untilTimestampWeek;
                    } else if($scope.retentionAggregationType == 3){
                        $scope.fromTimestampMonthForStat2 = $scope.fromTimestampMonth;
                        $scope.untilTimestampMonthForStat2 = $scope.untilTimestampMonth;
                    }
                }
                if($scope.sameAsMainPeriod3 == true){
                    $scope.periodForStat3 = $scope.retentionPeriod;
                    if($scope.retentionAggregationType == 2){
                        $scope.fromTimestampWeekForStat3 = $scope.fromTimestampWeek;
                        if(endDateForStat3 < $scope.untilTimestampWeek){
                            $scope.untilTimestampWeekForStat3 = endDateForStat3;
                        } else {
                            $scope.untilTimestampWeekForStat3 = $scope.untilTimestampWeek;
                        }
                    } else if($scope.retentionAggregationType == 3){
                        $scope.fromTimestampMonthForStat3 = $scope.fromTimestampMonth;
                        if(endDateForStat3 < $scope.untilTimestampMonth){
                            $scope.untilTimestampMonthForStat3 = endDateForStat3;
                        } else {
                            $scope.untilTimestampMonthForStat3 = $scope.untilTimestampMonth;
                        }
                    }
                }
            }
        }

        const getDateFromAggrType = (aggrType) => {
            if (aggrType == 1 || aggrType == 5 || aggrType == null || aggrType == '') {
                return {
                    fromTimestamp: $scope.fromTimestamp,
                    untilTimestamp: $scope.untilTimestamp
                }
            } else if (aggrType == 2) {
                return {
                    fromTimestamp: $scope.fromTimestampWeek,
                    untilTimestamp: $scope.untilTimestampWeek
                }
            } else if (aggrType == 3) {
                return {
                    fromTimestamp: $scope.fromTimestampMonth,
                    untilTimestamp: $scope.untilTimestampMonth
                }
            } else if (aggrType == 4) {
                return {
                    fromTimestamp: $scope.fromTimestampYear != null ? $scope.startYearArray[$scope.fromTimestampYear].value : null,
                    untilTimestamp: $scope.untilTimestampYear != null ? $scope.endYearArray[$scope.untilTimestampYear].value : null
                }
            }
        }

        $scope.onlyMondaysPredicate = function (date) {
            var day = date.getDay();
            return day === 1
        };

        $scope.onlySundaysPredicate = function (date) {
            var day = date.getDay();
            return day === 0
        };

        $scope.onlyFirstMonthOfYear = function (date) {
            return date.getMonth() == 0;
        };

        $scope.checkStartOfMonth = () => {
            setTimeout(function () {
                $scope.checkEarlyDate()
                if ($scope.aggregationType == 3 && $scope.fromTimestampMonth) {
                    let date = new Date($scope.fromTimestampMonth)
                    if (date.getDate() !== 1) {
                        $scope.userNumberStatsForm.fromTimestampMonth.$setValidity('startOfMonth', false);
                    } else {
                        $scope.userNumberStatsForm.fromTimestampMonth.$setValidity('startOfMonth', true);
                    }
                }
            }, 400);
        }

        $scope.checkMonthValidation = function () {
            setTimeout(function () {
                $scope.checkEarlyDate()
                if($scope.contentType != 3){
                    if ($scope.aggregationType == 3 && $scope.untilTimestampMonth) {
                        let date = new Date($scope.untilTimestampMonth)
                        if (date.getDate() !== 1 && date.getDate() != moment(date).daysInMonth()) {
                            $scope.userNumberStatsForm.untilTimestampMonth.$setValidity('endOfMonth', false);
                        } else {
                            $scope.userNumberStatsForm.untilTimestampMonth.$setValidity('endOfMonth', true);
                            let endOfMonth = moment($scope.untilTimestampMonth).endOf('month').toDate();
                            //endOfMonth.setHours(23, 59, 59, 999);
                            $scope.untilTimestampMonth = endOfMonth;
                        }
                    }
                } else {
                    if ($scope.retentionAggregationType == 3 && $scope.untilTimestampMonth) {
                        let date = new Date($scope.untilTimestampMonth)
                        if (date.getDate() !== 1 && date.getDate() != moment(date).daysInMonth()) {
                            $scope.userNumberStatsForm.untilTimestampMonth.$setValidity('endOfMonth', false);
                        } else {
                            $scope.userNumberStatsForm.untilTimestampMonth.$setValidity('endOfMonth', true);
                            let endOfMonth = moment($scope.untilTimestampMonth).endOf('month').toDate();
                            $scope.untilTimestampMonth = endOfMonth;
                        }
                        if($scope.sameAsMainPeriod1 == true){
                            $scope.fromTimestampMonthForStat1 = $scope.fromTimestampMonth;
                            $scope.untilTimestampMonthForStat1 = $scope.untilTimestampMonth;
                        }
                        if($scope.sameAsMainPeriod2 == true){
                            $scope.fromTimestampMonthForStat2 = $scope.fromTimestampMonth;
                            $scope.untilTimestampMonthForStat2 = $scope.untilTimestampMonth;
                        }
                        if($scope.sameAsMainPeriod3 == true){
                            $scope.fromTimestampMonthForStat3 = $scope.fromTimestampMonth;
                            $scope.untilTimestampMonthForStat3 = $scope.untilTimestampMonth;
                        }
                    }
                    if($scope.retentionAggregationType == 3 && $scope.untilTimestampMonthForStat1){
                        let date = new Date($scope.untilTimestampMonthForStat1)
                        if (date.getDate() !== 1 && date.getDate() != moment(date).daysInMonth()) {
                            $scope.userNumberSubStat1Form.untilTimestampMonthForStat1.$setValidity('endOfMonth', false);
                        } else {
                            $scope.userNumberSubStat1Form.untilTimestampMonthForStat1.$setValidity('endOfMonth', true);
                            let endOfMonth = moment($scope.untilTimestampMonthForStat1).endOf('month').toDate();
                            $scope.untilTimestampMonthForStat1 = endOfMonth;
                        }
                    }
                    if($scope.retentionAggregationType == 3 && $scope.untilTimestampMonthForStat2){
                        let date = new Date($scope.untilTimestampMonthForStat2)
                        if (date.getDate() !== 1 && date.getDate() != moment(date).daysInMonth()) {
                            $scope.userNumberSubStat2Form.untilTimestampMonthForStat2.$setValidity('endOfMonth', false);
                        } else {
                            $scope.userNumberSubStat2Form.untilTimestampMonthForStat2.$setValidity('endOfMonth', true);
                            let endOfMonth = moment($scope.untilTimestampMonthForStat2).endOf('month').toDate();
                            $scope.untilTimestampMonthForStat2 = endOfMonth;
                        }
                    }
                    if($scope.retentionAggregationType == 3 && $scope.untilTimestampMonthForStat3){
                        let date = new Date($scope.untilTimestampMonthForStat3)
                        if (date.getDate() !== 1 && date.getDate() != moment(date).daysInMonth()) {
                            $scope.userNumberSubStat3Form.untilTimestampMonthForStat3.$setValidity('endOfMonth', false);
                        } else {
                            $scope.userNumberSubStat3Form.untilTimestampMonthForStat3.$setValidity('endOfMonth', true);
                            let endOfMonth = moment($scope.untilTimestampMonthForStat3).endOf('month').toDate();
                            $scope.untilTimestampMonthForStat3 = endOfMonth;
                        }
                    }
                }
            }, 200);
        }

        $scope.setContentType = (type) => {
            if (type === 1) {
                if($scope.contentType == 2){
                    $scope.selectedYaltyCitiesForSubPage2 = $scope.selectedYaltyCities;
                    $scope.periodForSubPage2 = $scope.period;
                    $scope.fromTimestampForSubPage2 = $scope.fromTimestamp;
                    $scope.fromTimestampWeekForSubPage2 = $scope.fromTimestampWeek;
                    $scope.fromTimestampMonthForSubPage2 = $scope.fromTimestampMonth;
                    $scope.fromTimestampYearForSubPage2 = $scope.fromTimestampYear;
                    $scope.untilTimestampForSubPage2 = $scope.untilTimestamp;
                    $scope.untilTimestampWeekForSubPage2 = $scope.untilTimestampWeek;
                    $scope.untilTimestampMonthForSubPage2 = $scope.untilTimestampMonth;
                    $scope.untilTimestampYearForSubPage2 = $scope.untilTimestampYear;
                    $scope.aggregationTypeForSubPage2 = $scope.aggregationType;
                } else if($scope.contentType == 3){
                    $scope.selectedYaltyCitiesForSubPage3 = $scope.selectedYaltyCities;
                    $scope.fromTimestampForSubPage3 = $scope.fromTimestamp;
                    $scope.fromTimestampWeekForSubPage3 = $scope.fromTimestampWeek;
                    $scope.fromTimestampMonthForSubPage3 = $scope.fromTimestampMonth;
                    $scope.untilTimestampForSubPage3 = $scope.untilTimestamp;
                    $scope.untilTimestampWeekForSubPage3 = $scope.untilTimestampWeek;
                    $scope.untilTimestampMonthForSubPage3 = $scope.untilTimestampMonth;
                }
                if($scope.selectedYaltyCitiesForSubPage1 != null){
                    $scope.selectedYaltyCities = $scope.selectedYaltyCitiesForSubPage1;
                }
                if($scope.periodForSubPage1 != null){
                    $scope.period = $scope.periodForSubPage1;
                }
                if($scope.fromTimestampForSubPage1 != null){
                    $scope.fromTimestamp = $scope.fromTimestampForSubPage1;
                }
                if($scope.fromTimestampWeekForSubPage1 != null){
                    $scope.fromTimestampWeek = $scope.fromTimestampWeekForSubPage1;
                }
                if($scope.fromTimestampMonthForSubPage1 != null){
                    $scope.fromTimestampMonth = $scope.fromTimestampMonthForSubPage1;
                }
                if($scope.fromTimestampYearForSubPage1 != null){
                    $scope.fromTimestampYear = $scope.fromTimestampYearForSubPage1;
                }
                if($scope.untilTimestampForSubPage1 != null){
                    $scope.untilTimestamp = $scope.untilTimestampForSubPage1;
                }
                if($scope.untilTimestampWeekForSubPage1 != null){
                    $scope.untilTimestampWeek = $scope.untilTimestampWeekForSubPage1;
                }
                if($scope.untilTimestampMonthForSubPage1 != null){
                    $scope.untilTimestampMonth = $scope.untilTimestampMonthForSubPage1;
                }
                if($scope.untilTimestampYearForSubPage1 != null){
                    $scope.untilTimestampYear = $scope.untilTimestampYearForSubPage1;
                }
                if($scope.aggregationTypeForSubPage1 != null){
                    $scope.aggregationType = $scope.aggregationTypeForSubPage1;
                }
                $scope.contentType = 1
                $scope.subTitle = 'REGISZTRÁCIÓK'
                $scope.checkEarlyDate()
            } else if (type === 2) {
                if($scope.contentType == 1){
                    $scope.selectedYaltyCitiesForSubPage1 = $scope.selectedYaltyCities;
                    $scope.periodForSubPage1 = $scope.period;
                    $scope.fromTimestampForSubPage1 = $scope.fromTimestamp;
                    $scope.fromTimestampWeekForSubPage1 = $scope.fromTimestampWeek;
                    $scope.fromTimestampMonthForSubPage1 = $scope.fromTimestampMonth;
                    $scope.fromTimestampYearForSubPage1 = $scope.fromTimestampYear;
                    $scope.untilTimestampForSubPage1 = $scope.untilTimestamp;
                    $scope.untilTimestampWeekForSubPage1 = $scope.untilTimestampWeek;
                    $scope.untilTimestampMonthForSubPage1 = $scope.untilTimestampMonth;
                    $scope.untilTimestampYearForSubPage1 = $scope.untilTimestampYear;
                    $scope.aggregationTypeForSubPage1 = $scope.aggregationType;
                } else if($scope.contentType == 3){
                    $scope.selectedYaltyCitiesForSubPage3 = $scope.selectedYaltyCities;
                    $scope.fromTimestampForSubPage3 = $scope.fromTimestamp;
                    $scope.fromTimestampWeekForSubPage3 = $scope.fromTimestampWeek;
                    $scope.fromTimestampMonthForSubPage3 = $scope.fromTimestampMonth;
                    $scope.untilTimestampForSubPage3 = $scope.untilTimestamp;
                    $scope.untilTimestampWeekForSubPage3 = $scope.untilTimestampWeek;
                    $scope.untilTimestampMonthForSubPage3 = $scope.untilTimestampMonth;
                }
                if($scope.selectedYaltyCitiesForSubPage2 != null){
                    $scope.selectedYaltyCities = $scope.selectedYaltyCitiesForSubPage2;
                } else {
                    $scope.checkAllYaltyCity = true;
                    $scope.selectedYaltyCities = [];
                    for (let i = 0; i < $scope.allYaltyCity.length; i++) {
                        $scope.selectedYaltyCities.push($scope.allYaltyCity[i].id);
                    }            
                }
                if($scope.periodForSubPage2 != null){
                    $scope.period = $scope.periodForSubPage2;
                } else {
                    $scope.period = 6
                }
                if($scope.fromTimestampForSubPage2 != null){
                    $scope.fromTimestamp = $scope.fromTimestampForSubPage2;
                }
                if($scope.fromTimestampWeekForSubPage2 != null){
                    $scope.fromTimestampWeek = $scope.fromTimestampWeekForSubPage2;
                }
                if($scope.fromTimestampMonthForSubPage2 != null){
                    $scope.fromTimestampMonth = $scope.fromTimestampMonthForSubPage2;
                }
                if($scope.fromTimestampYearForSubPage2 != null){
                    $scope.fromTimestampYear = $scope.fromTimestampYearForSubPage2;
                }
                if($scope.untilTimestampForSubPage2 != null){
                    $scope.untilTimestamp = $scope.untilTimestampForSubPage2;
                }
                if($scope.untilTimestampWeekForSubPage2 != null){
                    $scope.untilTimestampWeek = $scope.untilTimestampWeekForSubPage2;
                }
                if($scope.untilTimestampMonthForSubPage2 != null){
                    $scope.untilTimestampMonth = $scope.untilTimestampMonthForSubPage2;
                }
                if($scope.untilTimestampYearForSubPage2 != null){
                    $scope.untilTimestampYear = $scope.untilTimestampYearForSubPage2;
                }
                if($scope.aggregationTypeForSubPage2 != null){
                    $scope.aggregationType = $scope.aggregationTypeForSubPage2;
                }
                $scope.contentType = 2
                $scope.subTitle = 'APP FELHASZNÁLÁS'
            } else if (type === 3) {
                if($scope.contentType == 1){
                    $scope.selectedYaltyCitiesForSubPage1 = $scope.selectedYaltyCities;
                    $scope.periodForSubPage1 = $scope.period;
                    $scope.fromTimestampForSubPage1 = $scope.fromTimestamp;
                    $scope.fromTimestampWeekForSubPage1 = $scope.fromTimestampWeek;
                    $scope.fromTimestampMonthForSubPage1 = $scope.fromTimestampMonth;
                    $scope.fromTimestampYearForSubPage1 = $scope.fromTimestampYear;
                    $scope.untilTimestampForSubPage1 = $scope.untilTimestamp;
                    $scope.untilTimestampWeekForSubPage1 = $scope.untilTimestampWeek;
                    $scope.untilTimestampMonthForSubPage1 = $scope.untilTimestampMonth;
                    $scope.untilTimestampYearForSubPage1 = $scope.untilTimestampYear;
                    $scope.aggregationTypeForSubPage1 = $scope.aggregationType;
                } else if($scope.contentType == 2){
                    $scope.selectedYaltyCitiesForSubPage2 = $scope.selectedYaltyCities;
                    $scope.periodForSubPage2 = $scope.period;
                    $scope.fromTimestampForSubPage2 = $scope.fromTimestamp;
                    $scope.fromTimestampWeekForSubPage2 = $scope.fromTimestampWeek;
                    $scope.fromTimestampMonthForSubPage2 = $scope.fromTimestampMonth;
                    $scope.fromTimestampYearForSubPage2 = $scope.fromTimestampYear;
                    $scope.untilTimestampForSubPage2 = $scope.untilTimestamp;
                    $scope.untilTimestampWeekForSubPage2 = $scope.untilTimestampWeek;
                    $scope.untilTimestampMonthForSubPage2 = $scope.untilTimestampMonth;
                    $scope.untilTimestampYearForSubPage2 = $scope.untilTimestampYear;
                    $scope.aggregationTypeForSubPage2 = $scope.aggregationType;
                }
                if($scope.selectedYaltyCitiesForSubPage3 != null){
                    $scope.selectedYaltyCities = $scope.selectedYaltyCitiesForSubPage3;
                } else {
                    $scope.checkAllYaltyCity = true;
                    $scope.selectedYaltyCities = [];
                    for (let i = 0; i < $scope.allYaltyCity.length; i++) {
                        $scope.selectedYaltyCities.push($scope.allYaltyCity[i].id);
                    }            
                }
                if($scope.fromTimestampForSubPage3 != null){
                    $scope.fromTimestamp = $scope.fromTimestampForSubPage3;
                }
                if($scope.fromTimestampWeekForSubPage3 != null){
                    $scope.fromTimestampWeek = $scope.fromTimestampWeekForSubPage3;
                }
                if($scope.fromTimestampMonthForSubPage3 != null){
                    $scope.fromTimestampMonth = $scope.fromTimestampMonthForSubPage3;
                }
                if($scope.untilTimestampForSubPage3 != null){
                    $scope.untilTimestamp = $scope.untilTimestampForSubPage3;
                }
                if($scope.untilTimestampWeekForSubPage3 != null){
                    $scope.untilTimestampWeek = $scope.untilTimestampWeekForSubPage3;
                }
                if($scope.untilTimestampMonthForSubPage3 != null){
                    $scope.untilTimestampMonth = $scope.untilTimestampMonthForSubPage3;
                }
                $scope.contentType = 3
                $scope.subTitle = 'RETENCIÓ'
                $scope.sameAsMainPeriod1 = true;
                $scope.sameAsMainPeriod2 = true;
                $scope.sameAsMainPeriod3 = true;
                $scope.checkEarlyDate()
            }
            $scope.setDatesAfterPeriodChanged()
            for (let i = 1; i < 4; i++) {
                $scope[`periodForStat${i}`] = $scope.retentionPeriod
                $scope[`fromTimestampWeekForStat${i}`] = $scope.fromTimestampWeek
                $scope[`fromTimestampMonthForStat${i}`] = $scope.fromTimestampMonth
                $scope[`untilTimestampWeekForStat${i}`] = $scope.untilTimestampWeek
                $scope[`untilTimestampMonthForStat${i}`] = $scope.untilTimestampMonth
            }
            const container = document.getElementById("userNumberStatsNavButtonContainer");
            const buttons = container.getElementsByClassName("userNumberStatsNavButton");
            type--;
            for (let i = 0; i < buttons.length; i++) {
                if (i == type) {
                    if (!buttons[i].classList.contains('userNumberStatsNavButtonActive')) {
                        buttons[i].className += " userNumberStatsNavButtonActive";
                    }
                } else {
                    buttons[i].className = buttons[0].className.replace(" userNumberStatsNavButtonActive", "");
                }
            }
        }
        
        $scope.setContentType(1)

        $scope.sameAsMainPeriodChanged = function(statNumber){
            let endDateForStat3;
            if($scope.retentionAggregationType == 2){
                endDateForStat3 = moment().subtract(1, 'week').endOf('week').toDate();
            } else {
                endDateForStat3 = moment().subtract(1, 'month').endOf('month').toDate();
            } 
            if(statNumber == 1 && $scope.sameAsMainPeriod1 == true){
                $scope.periodForStat1 = $scope.retentionPeriod;
                if($scope.retentionAggregationType == 2){
                    $scope.fromTimestampWeekForStat1 = $scope.fromTimestampWeek;
                    $scope.untilTimestampWeekForStat1 = $scope.untilTimestampWeek;
                } else if($scope.retentionAggregationType == 3){
                    $scope.fromTimestampMonthForStat1 = $scope.fromTimestampMonth;
                    $scope.untilTimestampMonthForStat1 = $scope.untilTimestampMonth;
                }
            } else if(statNumber == 2 && $scope.sameAsMainPeriod2 == true){
                $scope.periodForStat2 = $scope.retentionPeriod;
                $scope.checkEarlyDate();
                if($scope.retentionAggregationType == 2){
                    $scope.fromTimestampWeekForStat2 = $scope.fromTimestampWeek;
                    $scope.untilTimestampWeekForStat2 = $scope.untilTimestampWeek;
                } else if($scope.retentionAggregationType == 3){
                    $scope.fromTimestampMonthForStat2 = $scope.fromTimestampMonth;
                    $scope.untilTimestampMonthForStat2 = $scope.untilTimestampMonth;
                }
            } else if(statNumber == 3 && $scope.sameAsMainPeriod3 == true){
                $scope.periodForStat3 = $scope.retentionPeriod;
                if($scope.retentionAggregationType == 2){
                    $scope.fromTimestampWeekForStat3 = $scope.fromTimestampWeek;
                    if(endDateForStat3 < $scope.untilTimestampWeek){
                        $scope.untilTimestampWeekForStat3 = endDateForStat3;
                    } else {
                        $scope.untilTimestampWeekForStat3 = $scope.untilTimestampWeek;
                    }
                } else if($scope.retentionAggregationType == 3){
                    $scope.fromTimestampMonthForStat3 = $scope.fromTimestampMonth;
                    if(endDateForStat3 < $scope.untilTimestampMonth){
                        $scope.untilTimestampMonthForStat3 = endDateForStat3;
                    } else {
                        $scope.untilTimestampMonthForStat3 = $scope.untilTimestampMonth;
                    }
                }
            }
            
        }

        $scope.getUserNumberStats = function (userNumberStatsForm) {
            if (!userNumberStatsForm.$valid) {
                angular.forEach(userNumberStatsForm.$error, function (field) {
                    angular.forEach(field, function (errorField) {
                        console.log(errorField);
                        errorField.$setTouched();
                    });
                });
            } else {
                let startDate, endDate
                if ($scope.period != 1) {
                    if ($scope.aggregationType == 1 || $scope.aggregationType == 5) {
                        startDate = $scope.fromTimestamp
                        endDate = $scope.untilTimestamp
                    } else if ($scope.aggregationType == 2) {
                        startDate = $scope.fromTimestampWeek
                        endDate = $scope.untilTimestampWeek
                    } else if ($scope.aggregationType == 3) {
                        startDate = $scope.fromTimestampMonth
                        endDate = $scope.untilTimestampMonth
                    } else if ($scope.aggregationType == 4) {
                        if ($scope.fromTimestampYear != null) {
                            startDate = $scope.startYearArray[$scope.fromTimestampYear].value
                        }
                        if ($scope.untilTimestampYear != null) {
                            endDate = $scope.endYearArray[$scope.untilTimestampYear].value
                        }
                    }
                } else {
                    let date = new Date()
                    if ($scope.aggregationType == 2) {
                        let endOfWeek = moment(date).endOf('week').toDate();
                        date = endOfWeek
                    } else if ($scope.aggregationType == 3) {
                        let endOfMonth = moment(date).endOf('month').toDate();
                        date = endOfMonth
                    } else if ($scope.aggregationType == 4) {
                        let endOfYear = moment(date).endOf('year').toDate();
                        date = endOfYear
                    }
                    endDate = date
                }
                
                if (endDate) {
                    let endOfDay = new Date(endDate)
                    endOfDay.setHours(23, 59, 59, 999);
                    endDate = endOfDay
                }
                if ($scope.period == 7 && startDate && endDate
                    && new Date(startDate) > new Date(endDate)) {
                    var confirm = $mdDialog.confirm()
                        .textContent('A kezdő időszak (Időszak -Tól) nem lehet nagyobb a vég időszaknál (Időszak -Ig)')
                        .ok('OK')
                    $mdDialog.show(confirm).then(function () { }, function () { });
                } else {
                    let filters = {};
                    filters.yaltyCities = [];
                    if ($scope.selectedYaltyCities.length != $scope.allYaltyCity.length) {
                        for (let i = 0; i < $scope.selectedYaltyCities.length; i++) {
                            filters.yaltyCities.push($scope.allYaltyCity[$scope.selectedYaltyCities[i]].placeName);
                        }
                    }
                    filters.periodStartTimestamp = startDate;
                    filters.periodEndTimestamp = endDate;
                    if ($scope.contentType != 3) {
                        filters.period = $scope.period
                        filters.aggregationType = $scope.aggregationType == 5 ? null : $scope.aggregationType;
                    } else {
                        filters.period = $scope.retentionPeriod == 8 ? 1 : $scope.retentionPeriod
                        filters.aggregationType = $scope.retentionAggregationType
                    }
                    filters.frontendTimezone = 'Central European Standard Time'
                    $scope.sendButtonDisabled = true;
                    let APIName
                    if ($scope.contentType == 1) APIName = '/yaltyadmin/getUserNumberRegistrationStats'
                    else if ($scope.contentType == 2) APIName = '/yaltyadmin/getUserNumberAppUsageStats'
                    return $http.post(API_PREFIX.url + APIName, filters).then(function (result) {
                        $scope.sendButtonDisabled = false;
                        if (result.data.errorCode == 0) {
                            $scope.thHeight = $scope.aggregationType
                            if ($scope.contentType == 1) {
                                $scope.labels = []
                                let dataArray = []
                                $scope.userNumberStats = result.data.userNumberStats;
                                let maxValueForYAxis = $scope.userNumberStats[0].totalUserRegNr
                                for (let i = 0; i < $scope.userNumberStats.length; i++) {
                                    dataArray.push($scope.userNumberStats[i].totalUserRegNr)
                                    maxValueForYAxis = $scope.userNumberStats[i].totalUserRegNr > maxValueForYAxis ?
                                        $scope.userNumberStats[i].totalUserRegNr : maxValueForYAxis
                                    if ($scope.userNumberStats[i].label != null) {
                                        $scope.labels.push($scope.userNumberStats[i].label)
                                    }
                                }
                                maxValueForYAxis = Math.ceil(maxValueForYAxis *= 1.1)
                                //$scope.chartOptions.scales.yAxes[0].ticks.max = maxValueForYAxis
                                if ($scope.labels.length == 0) {
                                    $scope.labels.push($scope.periodList[$scope.period - 1].period)
                                }
                                $scope.data = [dataArray]
                                $scope.showUserNumberStats = true;
                                $scope.showUserNumberChart = true
                            } else if ($scope.contentType == 2) {
                                $scope.appUsageLabels = []
                                let userNrArray = [], deviceNrArray = [], queryNrArray = []
                                $scope.appUsageStats = result.data.appUsageStats;
                                for (let i = 0; i < $scope.appUsageStats.length; i++) {
                                    userNrArray.push($scope.appUsageStats[i].totalUserNr)
                                    deviceNrArray.push($scope.appUsageStats[i].totalDeviceNr)
                                    queryNrArray.push($scope.appUsageStats[i].totalQueryNr)
                                    if ($scope.appUsageStats[i].label != null) {
                                        $scope.appUsageLabels.push($scope.appUsageStats[i].label)
                                    }
                                }
                                if ($scope.appUsageLabels.length == 0) {
                                    $scope.appUsageLabels.push($scope.periodList[$scope.period - 1].period)
                                }
                                $scope.appUsageData = [userNrArray, deviceNrArray, queryNrArray]
                                $scope.showAppUsageChart = true;
                                $scope.showAppUsageStats = true
                            }
                            setListHeight()
                        } else {
                            //commonService.showDialogWindowWithTitleOnly('Szerver hiba. Próbáld meg egy kicsit később újra.', 'Rendben')
                        }
                    });
                }
            }
        }

        $scope.getRetentionStats = (retentionStatSelection) => {
            if (retentionStatSelection == 0 && (!$scope.userNumberStatsForm.$valid && !$scope.userNumberSubStat1Form.$valid || !$scope.userNumberSubStat2Form.$valid || !$scope.userNumberSubStat3Form.$valid)) {
                angular.forEach($scope.userNumberStatsForm.$error, function (field) {
                    angular.forEach(field, function (errorField) {
                        console.log(errorField);
                        errorField.$setTouched();
                    });
                });
                angular.forEach($scope.userNumberSubStat1Form.$error, function (field) {
                    angular.forEach(field, function (errorField) {
                        console.log(errorField);
                        errorField.$setTouched();
                    });
                });
                angular.forEach($scope.userNumberSubStat2Form.$error, function (field) {
                    angular.forEach(field, function (errorField) {
                        console.log(errorField);
                        errorField.$setTouched();
                    });
                });
                angular.forEach($scope.userNumberSubStat3Form.$error, function (field) {
                    angular.forEach(field, function (errorField) {
                        console.log(errorField);
                        errorField.$setTouched();
                    });
                });
            } else if(retentionStatSelection == 1 && !$scope.userNumberSubStat1Form.$valid){
                angular.forEach($scope.userNumberSubStat1Form.$error, function (field) {
                    angular.forEach(field, function (errorField) {
                        console.log(errorField);
                        errorField.$setTouched();
                    });
                });
            } else if(retentionStatSelection == 2 && !$scope.userNumberSubStat2Form.$valid){
                angular.forEach($scope.userNumberSubStat1Form.$error, function (field) {
                    angular.forEach(field, function (errorField) {
                        console.log(errorField);
                        errorField.$setTouched();
                    });
                });
            } else if(retentionStatSelection == 3 && !$scope.userNumberSubStat3Form.$valid){
                angular.forEach($scope.userNumberSubStat1Form.$error, function (field) {
                    angular.forEach(field, function (errorField) {
                        console.log(errorField);
                        errorField.$setTouched();
                    });
                });
            } else {
                let firstDate;
                let lastDate;
                let firstDateForStat1;
                let lastDateForStat1;
                let firstDateForStat2;
                let lastDateForStat2;
                let firstDateForStat3;
                let lastDateForStat3;
                if($scope.retentionAggregationType == 2){
                    firstDate = new Date(2018, 4, 14)
                    lastDate = moment().endOf('week').toDate();
                    firstDateForStat1 = new Date(2018, 4, 14)
                    lastDateForStat1 = moment().endOf('week').toDate();
                    firstDateForStat2 = new Date(2018, 4, 14)
                    lastDateForStat2 = moment().endOf('week').toDate();
                    firstDateForStat3 = new Date(2018, 4, 14)
                    lastDateForStat3 = moment().endOf('week').toDate();
                } else {
                    firstDate = new Date(2018, 4, 1)
                    lastDate = moment().endOf('month').toDate();
                    firstDateForStat1 = new Date(2018, 4, 1)
                    lastDateForStat1 = moment().endOf('month').toDate();
                    firstDateForStat2 = new Date(2018, 4, 1)
                    lastDateForStat2 = moment().endOf('month').toDate();
                    firstDateForStat3 = new Date(2018, 4, 1)
                    lastDateForStat3 = moment().endOf('month').toDate();
                }
                if(retentionStatSelection == 0){
                    if($scope.sameAsMainPeriod1 == true && $scope.sameAsMainPeriod2 == true && $scope.sameAsMainPeriod3 == true){
                        if($scope.retentionAggregationType == 2){
                            if($scope.fromTimestampWeek == null && $scope.untilTimestampWeek != null){
                                lastDate = moment($scope.untilTimestampWeek)
                            } else if($scope.fromTimestampWeek != null && $scope.untilTimestampWeek == null){
                                firstDate = moment($scope.fromTimestampWeek)
                            } else if($scope.fromTimestampWeek != null && $scope.untilTimestampWeek != null){
                                firstDate = moment($scope.fromTimestampWeek)
                                lastDate = moment($scope.untilTimestampWeek)
                            }
                            if(firstDate > lastDate){
                                const confirm = $mdDialog.confirm()
                                .textContent('A kezdő időszak (Időszak -Tól) nem lehet nagyobb a vég időszaknál (Időszak -Ig)')
                                .ok('OK')
                                $mdDialog.show(confirm).then(function () {
        
                                }, function () {
        
                                });
                            } else {
                                $scope.selectedPeriodLong = (lastDate.diff(firstDate, 'weeks')) + 1;
                                if($scope.selectedPeriodLong < 10){
                                    $scope.userNumberStatsForm.fromTimestampWeek.$setValidity('minPeriodUnits', false);
                                    $scope.userNumberStatsForm.untilTimestampWeek.$setValidity('minPeriodUnits', false);
                                    $scope.userNumberStatsForm.fromTimestampWeek.$setTouched();
                                    $scope.userNumberStatsForm.untilTimestampWeek.$setTouched();
                                } else {
                                    $scope.userNumberStatsForm.fromTimestampWeek.$setValidity('minPeriodUnits', true);
                                    $scope.userNumberStatsForm.untilTimestampWeek.$setValidity('minPeriodUnits', true);
                                    getRetentionData(retentionStatSelection);
                                }
                            }
                        } else if($scope.retentionAggregationType == 3){
                            if($scope.fromTimestampMonth == null && $scope.untilTimestampMonth != null){
                                lastDate = moment($scope.untilTimestampMonth)
                            } else if($scope.fromTimestampMonth != null && $scope.untilTimestampMonth == null){
                                firstDate = moment($scope.fromTimestampMonth)
                            } else if($scope.fromTimestampMonth != null && $scope.untilTimestampMonth != null){
                                firstDate = moment($scope.fromTimestampMonth)
                                lastDate = moment($scope.untilTimestampMonth)
                            }
                            if(firstDate > lastDate){
                                const confirm = $mdDialog.confirm()
                                .textContent('A kezdő időszak (Időszak -Tól) nem lehet nagyobb a vég időszaknál (Időszak -Ig)')
                                .ok('OK')
                                $mdDialog.show(confirm).then(function () {
        
                                }, function () {
        
                                });
                            } else {
                                $scope.selectedPeriodLong = (lastDate.diff(firstDate, 'months')) + 1;
                                if($scope.selectedPeriodLong < 10){
                                    $scope.userNumberStatsForm.fromTimestampMonth.$setValidity('minPeriodUnits', false);
                                    $scope.userNumberStatsForm.untilTimestampMonth.$setValidity('minPeriodUnits', false);
                                    $scope.userNumberStatsForm.fromTimestampMonth.$setTouched();
                                    $scope.userNumberStatsForm.untilTimestampMonth.$setTouched();
                                } else {
                                    $scope.userNumberStatsForm.fromTimestampMonth.$setValidity('minPeriodUnits', true);
                                    $scope.userNumberStatsForm.untilTimestampMonth.$setValidity('minPeriodUnits', true);
                                    getRetentionData(retentionStatSelection);
                                }
                            }
                        }
                    } else if(($scope.sameAsMainPeriod1 == true || $scope.sameAsMainPeriod2 == true || $scope.sameAsMainPeriod3 == true) && ($scope.sameAsMainPeriod1 == false || $scope.sameAsMainPeriod2 == false || $scope.sameAsMainPeriod3 == false)){
                        if($scope.retentionAggregationType == 2){
                            if($scope.fromTimestampWeek == null && $scope.untilTimestampWeek != null){
                                lastDate = moment($scope.untilTimestampWeek)
                            } else if($scope.fromTimestampWeek != null && $scope.untilTimestampWeek == null){
                                firstDate = moment($scope.fromTimestampWeek)
                            } else if($scope.fromTimestampWeek != null && $scope.untilTimestampWeek != null){
                                firstDate = moment($scope.fromTimestampWeek)
                                lastDate = moment($scope.untilTimestampWeek)
                            }
                            if($scope.fromTimestampWeekForStat1 == null && $scope.untilTimestampWeekForStat1 != null){
                                lastDateForStat1 = moment($scope.untilTimestampWeekForStat1)
                            } else if($scope.fromTimestampWeekForStat1 != null && $scope.untilTimestampWeekForStat1 == null){
                                firstDateForStat1 = moment($scope.fromTimestampWeekForStat1)
                            } else if($scope.fromTimestampWeekForStat1 != null && $scope.untilTimestampWeekForStat1 != null){
                                firstDateForStat1 = moment($scope.fromTimestampWeekForStat1)
                                lastDateForStat1= moment($scope.untilTimestampWeekForStat1)
                            }
                            if($scope.fromTimestampWeekForStat2 == null && $scope.untilTimestampWeekForStat2 != null){
                                lastDateForStat2 = moment($scope.untilTimestampWeekForStat2)
                            } else if($scope.fromTimestampWeekForStat2 != null && $scope.untilTimestampWeekForStat2 == null){
                                firstDateForStat2 = moment($scope.fromTimestampWeekForStat2)
                            } else if($scope.fromTimestampWeekForStat2 != null && $scope.untilTimestampWeekForStat2 != null){
                                firstDateForStat2 = moment($scope.fromTimestampWeekForStat2)
                                lastDateForStat2= moment($scope.untilTimestampWeekForStat2)
                            }
                            if($scope.fromTimestampWeekForStat3 == null && $scope.untilTimestampWeekForStat3 != null){
                                lastDateForStat3 = moment($scope.untilTimestampWeekForStat3)
                            } else if($scope.fromTimestampWeekForStat3 != null && $scope.untilTimestampWeekForStat3 == null){
                                firstDateForStat3 = moment($scope.fromTimestampWeekForStat3)
                            } else if($scope.fromTimestampWeekForStat3 != null && $scope.untilTimestampWeekForStat3 != null){
                                firstDateForStat3 = moment($scope.fromTimestampWeekForStat3)
                                lastDateForStat3= moment($scope.untilTimestampWeekForStat3)
                            }
                            if(firstDate > lastDate){
                                const confirm = $mdDialog.confirm()
                                .textContent('A kezdő időszak (Időszak -Tól) nem lehet nagyobb a vég időszaknál (Időszak -Ig)')
                                .ok('OK')
                                $mdDialog.show(confirm).then(function () {
        
                                }, function () {
        
                                });
                            } else if(firstDateForStat1 > lastDateForStat1){
                                const confirm = $mdDialog.confirm()
                                .title('STAT #1 - Helytelen időszaki beállítás(ok):')
                                .textContent('A kezdő időszak (Időszak -Tól) nem lehet nagyobb a vég időszaknál (Időszak -Ig)')
                                .ok('OK')
                                $mdDialog.show(confirm).then(function () {
        
                                }, function () {
        
                                });
                            } else if(firstDateForStat2 > lastDateForStat2){
                                const confirm = $mdDialog.confirm()
                                .title('STAT #2 - Helytelen időszaki beállítás(ok):')
                                .textContent('A kezdő időszak (Időszak -Tól) nem lehet nagyobb a vég időszaknál (Időszak -Ig)')
                                .ok('OK')
                                $mdDialog.show(confirm).then(function () {
        
                                }, function () {
        
                                });
                            } else if(firstDateForStat3 > lastDateForStat3){
                                const confirm = $mdDialog.confirm()
                                .title('STAT #3 - Helytelen időszaki beállítás(ok):')
                                .textContent('A kezdő időszak (Időszak -Tól) nem lehet nagyobb a vég időszaknál (Időszak -Ig)')
                                .ok('OK')
                                $mdDialog.show(confirm).then(function () {
        
                                }, function () {
        
                                });
                            } else {
                                $scope.selectedPeriodLongForStat2 = (lastDateForStat2.diff(firstDateForStat2, 'weeks')) + 1;
                                if($scope.selectedPeriodLongForStat2 < 10){
                                    $scope.userNumberSubStat2Form.fromTimestampWeekForStat2.$setValidity('minPeriodUnits', false);
                                    $scope.userNumberSubStat2Form.untilTimestampWeekForStat2.$setValidity('minPeriodUnits', false);
                                    $scope.userNumberSubStat2Form.fromTimestampWeekForStat2.$setTouched();
                                    $scope.userNumberSubStat2Form.untilTimestampWeekForStat2.$setTouched();
                                } else {
                                    $scope.userNumberSubStat2Form.fromTimestampWeekForStat2.$setValidity('minPeriodUnits', true);
                                    $scope.userNumberSubStat2Form.untilTimestampWeekForStat2.$setValidity('minPeriodUnits', true);
                                    getRetentionData(retentionStatSelection);
                                }
                            }
                        } else if($scope.retentionAggregationType == 3){
                            if($scope.fromTimestampMonth == null && $scope.untilTimestampMonth != null){
                                lastDate = moment($scope.untilTimestampMonth)
                            } else if($scope.fromTimestampMonth != null && $scope.untilTimestampMonth == null){
                                firstDate = moment($scope.fromTimestampMonth)
                            } else if($scope.fromTimestampMonth != null && $scope.untilTimestampMonth != null){
                                firstDate = moment($scope.fromTimestampMonth)
                                lastDate = moment($scope.untilTimestampMonth)
                            }
                            if($scope.fromTimestampMonthForStat1 == null && $scope.untilTimestampMonthForStat1 != null){
                                lastDateForStat1 = moment($scope.untilTimestampMonthForStat1)
                            } else if($scope.fromTimestampMonthForStat1 != null && $scope.untilTimestampMonthForStat1 == null){
                                firstDateForStat1 = moment($scope.fromTimestampMonthForStat1)
                            } else if($scope.fromTimestampMonthForStat1 != null && $scope.untilTimestampMonthForStat1 != null){
                                firstDateForStat1 = moment($scope.fromTimestampMonthForStat1)
                                lastDateForStat1= moment($scope.untilTimestampMonthForStat1)
                            }
                            if($scope.fromTimestampMonthForStat2 == null && $scope.untilTimestampMonthForStat2 != null){
                                lastDateForStat2 = moment($scope.untilTimestampMonthForStat2)
                            } else if($scope.fromTimestampMonthForStat2 != null && $scope.untilTimestampMonthForStat2 == null){
                                firstDateForStat2 = moment($scope.fromTimestampMonthForStat2)
                            } else if($scope.fromTimestampMonthForStat2 != null && $scope.untilTimestampMonthForStat2 != null){
                                firstDateForStat2 = moment($scope.fromTimestampMonthForStat2)
                                lastDateForStat2= moment($scope.untilTimestampMonthForStat2)
                            }
                            if($scope.fromTimestampMonthForStat3 == null && $scope.untilTimestampMonthForStat3 != null){
                                lastDateForStat3 = moment($scope.untilTimestampMonthForStat3)
                            } else if($scope.fromTimestampMonthForStat3 != null && $scope.untilTimestampMonthForStat3 == null){
                                firstDateForStat3 = moment($scope.fromTimestampMonthForStat3)
                            } else if($scope.fromTimestampMonthForStat3 != null && $scope.untilTimestampMonthForStat3 != null){
                                firstDateForStat3 = moment($scope.fromTimestampMonthForStat3)
                                lastDateForStat3= moment($scope.untilTimestampMonthForStat3)
                            }
                            if(firstDate > lastDate){
                                const confirm = $mdDialog.confirm()
                                .textContent('A kezdő időszak (Időszak -Tól) nem lehet nagyobb a vég időszaknál (Időszak -Ig)')
                                .ok('OK')
                                $mdDialog.show(confirm).then(function () {
        
                                }, function () {
        
                                });
                            } else if(firstDateForStat1 > lastDateForStat1){
                                const confirm = $mdDialog.confirm()
                                .title('STAT #1 - Helytelen időszaki beállítás(ok):')
                                .textContent('A kezdő időszak (Időszak -Tól) nem lehet nagyobb a vég időszaknál (Időszak -Ig)')
                                .ok('OK')
                                $mdDialog.show(confirm).then(function () {
        
                                }, function () {
        
                                });
                            } else if(firstDateForStat2 > lastDateForStat2){
                                const confirm = $mdDialog.confirm()
                                .title('STAT #2 - Helytelen időszaki beállítás(ok):')
                                .textContent('A kezdő időszak (Időszak -Tól) nem lehet nagyobb a vég időszaknál (Időszak -Ig)')
                                .ok('OK')
                                $mdDialog.show(confirm).then(function () {
        
                                }, function () {
        
                                });
                            } else if(firstDateForStat3 > lastDateForStat3){
                                const confirm = $mdDialog.confirm()
                                .title('STAT #3 - Helytelen időszaki beállítás(ok):')
                                .textContent('A kezdő időszak (Időszak -Tól) nem lehet nagyobb a vég időszaknál (Időszak -Ig)')
                                .ok('OK')
                                $mdDialog.show(confirm).then(function () {
        
                                }, function () {
        
                                });
                            } else {
                                $scope.selectedPeriodLongForStat2 = (lastDateForStat2.diff(firstDateForStat2, 'months')) + 1;
                                if($scope.selectedPeriodLongForStat2 < 10){
                                    $scope.userNumberSubStat2Form.fromTimestampMonthForStat2.$setValidity('minPeriodUnits', false);
                                    $scope.userNumberSubStat2Form.untilTimestampMonthForStat2.$setValidity('minPeriodUnits', false);
                                    $scope.userNumberSubStat2Form.fromTimestampMonthForStat2.$setTouched();
                                    $scope.userNumberSubStat2Form.untilTimestampMonthForStat2.$setTouched();
                                } else {
                                    $scope.userNumberSubStat2Form.fromTimestampMonthForStat2.$setValidity('minPeriodUnits', true);
                                    $scope.userNumberSubStat2Form.untilTimestampMonthForStat2.$setValidity('minPeriodUnits', true);
                                    getRetentionData(retentionStatSelection);
                                }
                            }
                        }
                    } else {
                        if($scope.retentionAggregationType == 2){
                            if($scope.fromTimestampWeekForStat1 == null && $scope.untilTimestampWeekForStat1 != null){
                                lastDateForStat1 = moment($scope.untilTimestampWeekForStat1)
                            } else if($scope.fromTimestampWeekForStat1 != null && $scope.untilTimestampWeekForStat1 == null){
                                firstDateForStat1 = moment($scope.fromTimestampWeekForStat1)
                            } else if($scope.fromTimestampWeekForStat1 != null && $scope.untilTimestampWeekForStat1 != null){
                                firstDateForStat1 = moment($scope.fromTimestampWeekForStat1)
                                lastDateForStat1= moment($scope.untilTimestampWeekForStat1)
                            }
                            if($scope.fromTimestampWeekForStat2 == null && $scope.untilTimestampWeekForStat2 != null){
                                lastDateForStat2 = moment($scope.untilTimestampWeekForStat2)
                            } else if($scope.fromTimestampWeekForStat2 != null && $scope.untilTimestampWeekForStat2 == null){
                                firstDateForStat2 = moment($scope.fromTimestampWeekForStat2)
                            } else if($scope.fromTimestampWeekForStat2 != null && $scope.untilTimestampWeekForStat2 != null){
                                firstDateForStat2 = moment($scope.fromTimestampWeekForStat2)
                                lastDateForStat2= moment($scope.untilTimestampWeekForStat2)
                            }
                            if($scope.fromTimestampWeekForStat3 == null && $scope.untilTimestampWeekForStat3 != null){
                                lastDateForStat3 = moment($scope.untilTimestampWeekForStat3)
                            } else if($scope.fromTimestampWeekForStat3 != null && $scope.untilTimestampWeekForStat3 == null){
                                firstDateForStat3 = moment($scope.fromTimestampWeekForStat3)
                            } else if($scope.fromTimestampWeekForStat3 != null && $scope.untilTimestampWeekForStat3 != null){
                                firstDateForStat3 = moment($scope.fromTimestampWeekForStat3)
                                lastDateForStat3= moment($scope.untilTimestampWeekForStat3)
                            }
                            if(firstDateForStat1 > lastDateForStat1){
                                const confirm = $mdDialog.confirm()
                                .title('STAT #1 - Helytelen időszaki beállítás(ok):')
                                .textContent('A kezdő időszak (Időszak -Tól) nem lehet nagyobb a vég időszaknál (Időszak -Ig)')
                                .ok('OK')
                                $mdDialog.show(confirm).then(function () {
        
                                }, function () {
        
                                });
                            } else if(firstDateForStat2 > lastDateForStat2){
                                const confirm = $mdDialog.confirm()
                                .title('STAT #2 - Helytelen időszaki beállítás(ok):')
                                .textContent('A kezdő időszak (Időszak -Tól) nem lehet nagyobb a vég időszaknál (Időszak -Ig)')
                                .ok('OK')
                                $mdDialog.show(confirm).then(function () {
        
                                }, function () {
        
                                });
                            } else if(firstDateForStat3 > lastDateForStat3){
                                const confirm = $mdDialog.confirm()
                                .title('STAT #3 - Helytelen időszaki beállítás(ok):')
                                .textContent('A kezdő időszak (Időszak -Tól) nem lehet nagyobb a vég időszaknál (Időszak -Ig)')
                                .ok('OK')
                                $mdDialog.show(confirm).then(function () {
        
                                }, function () {
        
                                });
                            } else {
                                $scope.selectedPeriodLongForStat2 = (lastDateForStat2.diff(firstDateForStat2, 'weeks')) + 1;
                                if($scope.selectedPeriodLongForStat2 < 10){
                                    $scope.userNumberSubStat2Form.fromTimestampWeekForStat2.$setValidity('minPeriodUnits', false);
                                    $scope.userNumberSubStat2Form.untilTimestampWeekForStat2.$setValidity('minPeriodUnits', false);
                                    $scope.userNumberSubStat2Form.fromTimestampWeekForStat2.$setTouched();
                                    $scope.userNumberSubStat2Form.untilTimestampWeekForStat2.$setTouched();
                                } else {
                                    $scope.userNumberSubStat2Form.fromTimestampWeekForStat2.$setValidity('minPeriodUnits', true);
                                    $scope.userNumberSubStat2Form.untilTimestampWeekForStat2.$setValidity('minPeriodUnits', true);
                                    getRetentionData(retentionStatSelection);
                                }
                            }
                        } else if($scope.retentionAggregationType == 3){
                            if($scope.fromTimestampMonthForStat1 == null && $scope.untilTimestampMonthForStat1 != null){
                                lastDateForStat1 = moment($scope.untilTimestampMonthForStat1)
                            } else if($scope.fromTimestampMonthForStat1 != null && $scope.untilTimestampMonthForStat1 == null){
                                firstDateForStat1 = moment($scope.fromTimestampMonthForStat1)
                            } else if($scope.fromTimestampMonthForStat1 != null && $scope.untilTimestampMonthForStat1 != null){
                                firstDateForStat1 = moment($scope.fromTimestampMonthForStat1)
                                lastDateForStat1= moment($scope.untilTimestampMonthForStat1)
                            }
                            if($scope.fromTimestampMonthForStat2 == null && $scope.untilTimestampMonthForStat2 != null){
                                lastDateForStat2 = moment($scope.untilTimestampMonthForStat2)
                            } else if($scope.fromTimestampMonthForStat2 != null && $scope.untilTimestampMonthForStat2 == null){
                                firstDateForStat2 = moment($scope.fromTimestampMonthForStat2)
                            } else if($scope.fromTimestampMonthForStat2 != null && $scope.untilTimestampMonthForStat2 != null){
                                firstDateForStat2 = moment($scope.fromTimestampMonthForStat2)
                                lastDateForStat2= moment($scope.untilTimestampMonthForStat2)
                            }
                            if($scope.fromTimestampMonthForStat3 == null && $scope.untilTimestampMonthForStat3 != null){
                                lastDateForStat3 = moment($scope.untilTimestampMonthForStat3)
                            } else if($scope.fromTimestampMonthForStat3 != null && $scope.untilTimestampMonthForStat3 == null){
                                firstDateForStat3 = moment($scope.fromTimestampMonthForStat3)
                            } else if($scope.fromTimestampMonthForStat3 != null && $scope.untilTimestampMonthForStat3 != null){
                                firstDateForStat3 = moment($scope.fromTimestampMonthForStat3)
                                lastDateForStat3= moment($scope.untilTimestampMonthForStat3)
                            }
                            if(firstDateForStat1 > lastDateForStat1){
                                const confirm = $mdDialog.confirm()
                                .title('STAT #1 - Helytelen időszaki beállítás(ok):')
                                .textContent('A kezdő időszak (Időszak -Tól) nem lehet nagyobb a vég időszaknál (Időszak -Ig)')
                                .ok('OK')
                                $mdDialog.show(confirm).then(function () {
        
                                }, function () {
        
                                });
                            } else if(firstDateForStat2 > lastDateForStat2){
                                const confirm = $mdDialog.confirm()
                                .title('STAT #2 - Helytelen időszaki beállítás(ok):')
                                .textContent('A kezdő időszak (Időszak -Tól) nem lehet nagyobb a vég időszaknál (Időszak -Ig)')
                                .ok('OK')
                                $mdDialog.show(confirm).then(function () {
        
                                }, function () {
        
                                });
                            } else if(firstDateForStat3 > lastDateForStat3){
                                const confirm = $mdDialog.confirm()
                                .title('STAT #3 - Helytelen időszaki beállítás(ok):')
                                .textContent('A kezdő időszak (Időszak -Tól) nem lehet nagyobb a vég időszaknál (Időszak -Ig)')
                                .ok('OK')
                                $mdDialog.show(confirm).then(function () {
        
                                }, function () {
        
                                });
                            } else {
                                $scope.selectedPeriodLongForStat2 = (lastDateForStat2.diff(firstDateForStat2, 'months')) + 1;
                                if($scope.selectedPeriodLongForStat2 < 10){
                                    $scope.userNumberSubStat2Form.fromTimestampMonthForStat2.$setValidity('minPeriodUnits', false);
                                    $scope.userNumberSubStat2Form.untilTimestampMonthForStat2.$setValidity('minPeriodUnits', false);
                                    $scope.userNumberSubStat2Form.fromTimestampMonthForStat2.$setTouched();
                                    $scope.userNumberSubStat2Form.untilTimestampMonthForStat2.$setTouched();
                                } else {
                                    $scope.userNumberSubStat2Form.fromTimestampMonthForStat2.$setValidity('minPeriodUnits', true);
                                    $scope.userNumberSubStat2Form.untilTimestampMonthForStat2.$setValidity('minPeriodUnits', true);
                                    getRetentionData(retentionStatSelection);
                                }
                            }
                        }
                    }
                } else if(retentionStatSelection == 1){
                    if($scope.retentionAggregationType == 2){
                        if($scope.fromTimestampWeekForStat1 == null && $scope.untilTimestampWeekForStat1 != null){
                            lastDateForStat1 = moment($scope.untilTimestampWeekForStat1)
                        } else if($scope.fromTimestampWeekForStat1 != null && $scope.untilTimestampWeekForStat1 == null){
                            firstDateForStat1 = moment($scope.fromTimestampWeekForStat1)
                        } else if($scope.fromTimestampWeekForStat1 != null && $scope.untilTimestampWeekForStat1 != null){
                            firstDateForStat1 = moment($scope.fromTimestampWeekForStat1)
                            lastDateForStat1= moment($scope.untilTimestampWeekForStat1)
                        }
                        if(firstDateForStat1 > lastDateForStat1){
                            const confirm = $mdDialog.confirm()
                            .title('STAT #1 - Helytelen időszaki beállítás(ok):')
                            .textContent('A kezdő időszak (Időszak -Tól) nem lehet nagyobb a vég időszaknál (Időszak -Ig)')
                            .ok('OK')
                            $mdDialog.show(confirm).then(function () {
    
                            }, function () {
    
                            });
                        } else {
                            getRetentionData(retentionStatSelection);
                        } 
                    } else if($scope.retentionAggregationType == 3){
                        if($scope.fromTimestampMonthForStat1 == null && $scope.untilTimestampMonthForStat1 != null){
                            lastDateForStat1 = moment($scope.untilTimestampMonthForStat1)
                        } else if($scope.fromTimestampMonthForStat1 != null && $scope.untilTimestampMonthForStat1 == null){
                            firstDateForStat1 = moment($scope.fromTimestampMonthForStat1)
                        } else if($scope.fromTimestampMonthForStat1 != null && $scope.untilTimestampMonthForStat1 != null){
                            firstDateForStat1 = moment($scope.fromTimestampMonthForStat1)
                            lastDateForStat1= moment($scope.untilTimestampMonthForStat1)
                        }
                        if(firstDateForStat1 > lastDateForStat1){
                            const confirm = $mdDialog.confirm()
                            .title('STAT #1 - Helytelen időszaki beállítás(ok):')
                            .textContent('A kezdő időszak (Időszak -Tól) nem lehet nagyobb a vég időszaknál (Időszak -Ig)')
                            .ok('OK')
                            $mdDialog.show(confirm).then(function () {
    
                            }, function () {
    
                            });
                        } else {
                            getRetentionData(retentionStatSelection);
                        } 
                    }
                } else if(retentionStatSelection == 2){
                    if($scope.retentionAggregationType == 2){
                        if($scope.fromTimestampWeekForStat2 == null && $scope.untilTimestampWeekForStat2 != null){
                            lastDateForStat2 = moment($scope.untilTimestampWeekForStat2)
                        } else if($scope.fromTimestampWeekForStat2 != null && $scope.untilTimestampWeekForStat2 == null){
                            firstDateForStat2 = moment($scope.fromTimestampWeekForStat2)
                        } else if($scope.fromTimestampWeekForStat2 != null && $scope.untilTimestampWeekForStat2 != null){
                            firstDateForStat2 = moment($scope.fromTimestampWeekForStat2)
                            lastDateForStat2= moment($scope.untilTimestampWeekForStat2)
                        }
                        if(firstDateForStat2 > lastDateForStat2){
                            const confirm = $mdDialog.confirm()
                            .title('STAT #2 - Helytelen időszaki beállítás(ok):')
                            .textContent('A kezdő időszak (Időszak -Tól) nem lehet nagyobb a vég időszaknál (Időszak -Ig)')
                            .ok('OK')
                            $mdDialog.show(confirm).then(function () {
    
                            }, function () {
    
                            });
                        } else {
                            $scope.selectedPeriodLongForStat2 = (lastDateForStat2.diff(firstDateForStat2, 'weeks')) + 1;
                            if($scope.selectedPeriodLongForStat2 < 10){
                                $scope.userNumberSubStat2Form.fromTimestampWeekForStat2.$setValidity('minPeriodUnits', false);
                                $scope.userNumberSubStat2Form.untilTimestampWeekForStat2.$setValidity('minPeriodUnits', false);
                                $scope.userNumberSubStat2Form.fromTimestampWeekForStat2.$setTouched();
                                $scope.userNumberSubStat2Form.untilTimestampWeekForStat2.$setTouched();
                            } else {
                                $scope.userNumberSubStat2Form.fromTimestampWeekForStat2.$setValidity('minPeriodUnits', true);
                                $scope.userNumberSubStat2Form.untilTimestampWeekForStat2.$setValidity('minPeriodUnits', true);
                                getRetentionData(retentionStatSelection);
                            }
                        }
                    } else if($scope.retentionAggregationType == 3){
                        if($scope.fromTimestampMonthForStat2 == null && $scope.untilTimestampMonthForStat2 != null){
                            lastDateForStat2 = moment($scope.untilTimestampMonthForStat2)
                        } else if($scope.fromTimestampMonthForStat2 != null && $scope.untilTimestampMonthForStat2 == null){
                            firstDateForStat2 = moment($scope.fromTimestampMonthForStat2)
                        } else if($scope.fromTimestampMonthForStat2 != null && $scope.untilTimestampMonthForStat2 != null){
                            firstDateForStat2 = moment($scope.fromTimestampMonthForStat2)
                            lastDateForStat2= moment($scope.untilTimestampMonthForStat2)
                        }
                        if(firstDateForStat2 > lastDateForStat2){
                            const confirm = $mdDialog.confirm()
                            .title('STAT #2 - Helytelen időszaki beállítás(ok):')
                            .textContent('A kezdő időszak (Időszak -Tól) nem lehet nagyobb a vég időszaknál (Időszak -Ig)')
                            .ok('OK')
                            $mdDialog.show(confirm).then(function () {
    
                            }, function () {
    
                            });
                        } else {
                            $scope.selectedPeriodLongForStat2 = (lastDateForStat2.diff(firstDateForStat2, 'months')) + 1;
                            if($scope.selectedPeriodLongForStat2 < 10){
                                $scope.userNumberSubStat2Form.fromTimestampMonthForStat2.$setValidity('minPeriodUnits', false);
                                $scope.userNumberSubStat2Form.untilTimestampMonthForStat2.$setValidity('minPeriodUnits', false);
                                $scope.userNumberSubStat2Form.fromTimestampMonthForStat2.$setTouched();
                                $scope.userNumberSubStat2Form.untilTimestampMonthForStat2.$setTouched();
                            } else {
                                $scope.userNumberSubStat2Form.fromTimestampMonthForStat2.$setValidity('minPeriodUnits', true);
                                $scope.userNumberSubStat2Form.untilTimestampMonthForStat2.$setValidity('minPeriodUnits', true);
                                getRetentionData(retentionStatSelection);
                            }
                        }
                    }
                } else if(retentionStatSelection == 3){
                    if($scope.retentionAggregationType == 2){
                            if($scope.fromTimestampWeekForStat3 == null && $scope.untilTimestampWeekForStat3 != null){
                                lastDateForStat3 = moment($scope.untilTimestampWeekForStat3)
                            } else if($scope.fromTimestampWeekForStat3 != null && $scope.untilTimestampWeekForStat3 == null){
                                firstDateForStat3 = moment($scope.fromTimestampWeekForStat3)
                            } else if($scope.fromTimestampWeekForStat3 != null && $scope.untilTimestampWeekForStat3 != null){
                                firstDateForStat3 = moment($scope.fromTimestampWeekForStat3)
                                lastDateForStat3= moment($scope.untilTimestampWeekForStat3)
                            }
                            if(firstDateForStat3 > lastDateForStat3){
                                const confirm = $mdDialog.confirm()
                                .title('STAT #3 - Helytelen időszaki beállítás(ok):')
                                .textContent('A kezdő időszak (Időszak -Tól) nem lehet nagyobb a vég időszaknál (Időszak -Ig)')
                                .ok('OK')
                                $mdDialog.show(confirm).then(function () {
        
                                }, function () {
        
                                });
                            } else {
                                getRetentionData(retentionStatSelection);
                            } 
                        } else if($scope.retentionAggregationType == 3){
                            if($scope.fromTimestampMonthForStat3 == null && $scope.untilTimestampMonthForStat3 != null){
                                lastDateForStat3 = moment($scope.untilTimestampMonthForStat3)
                            } else if($scope.fromTimestampMonthForStat3 != null && $scope.untilTimestampMonthForStat3 == null){
                                firstDateForStat3 = moment($scope.fromTimestampMonthForStat3)
                            } else if($scope.fromTimestampMonthForStat3 != null && $scope.untilTimestampMonthForStat3 != null){
                                firstDateForStat3 = moment($scope.fromTimestampMonthForStat3)
                                lastDateForStat3= moment($scope.untilTimestampMonthForStat3)
                            }
                            if(firstDateForStat3 > lastDateForStat3){
                                const confirm = $mdDialog.confirm()
                                .title('STAT #3 - Helytelen időszaki beállítás(ok):')
                                .textContent('A kezdő időszak (Időszak -Tól) nem lehet nagyobb a vég időszaknál (Időszak -Ig)')
                                .ok('OK')
                                $mdDialog.show(confirm).then(function () {
        
                                }, function () {
        
                                });
                            } else {
                                getRetentionData(retentionStatSelection);
                            } 
                        }
                }
            }
        }

        $scope.retentionStat3BackgroundColor = function(row, column){
            if(column == 0 && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel != "-" && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel == $scope.retentionStat3ByRetentionGroup[8].cellsContent[row].cellLabel){
                return '#d5a6bd'
            } else if(column == 0 && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel != "-" && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel == $scope.retentionStat3ByRetentionGroup[7].cellsContent[row].cellLabel){
                return '#b4a7d6'
            } else if(column == 0 && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel != "-" && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel == $scope.retentionStat3ByRetentionGroup[6].cellsContent[row].cellLabel){
                return '#a4c2f4'
            } else if(column == 1 && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel != "-" && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel == $scope.retentionStat3ByRetentionGroup[9].cellsContent[row].cellLabel){
                return '#ddb8ca'
            } else if(column == 1 && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel != "-" && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel == $scope.retentionStat3ByRetentionGroup[8].cellsContent[row].cellLabel){
                return '#c3b9de'
            } else if(column == 1 && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel != "-" && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel == $scope.retentionStat3ByRetentionGroup[7].cellsContent[row].cellLabel){
                return '#b6cef6'
            } else if(column == 1 && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel != "-" && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel == $scope.retentionStat3ByRetentionGroup[6].cellsContent[row].cellLabel){
                return '#85d8f7'
            } else if(column == 2 && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel != "-" && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel == $scope.retentionStat3ByRetentionGroup[9].cellsContent[row].cellLabel){
                return '#d2cae6'
            } else if(column == 2 && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel != "-" && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel == $scope.retentionStat3ByRetentionGroup[8].cellsContent[row].cellLabel){
                return '#c8daf8'
            } else if(column == 2 && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel != "-" && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel == $scope.retentionStat3ByRetentionGroup[7].cellsContent[row].cellLabel){
                return '#a3e2f9'
            } else if(column == 2 && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel != "-" && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel == $scope.retentionStat3ByRetentionGroup[6].cellsContent[row].cellLabel){
                return '#d3e7cb'
            } else if(column == 3 && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel != "-" && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel == $scope.retentionStat3ByRetentionGroup[9].cellsContent[row].cellLabel){
                return '#dbe7fb'
            } else if(column == 3 && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel != "-" && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel == $scope.retentionStat3ByRetentionGroup[8].cellsContent[row].cellLabel){
                return '#c2ebfb'
            } else if(column == 3 && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel != "-" && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel == $scope.retentionStat3ByRetentionGroup[7].cellsContent[row].cellLabel){
                return '#e2efdc'
            } else if(column == 3 && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel != "-" && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel == $scope.retentionStat3ByRetentionGroup[6].cellsContent[row].cellLabel){
                return '#fdead7'
            } else if(column == 4 && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel != "-" && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel == $scope.retentionStat3ByRetentionGroup[9].cellsContent[row].cellLabel){
                return '#e0f5fd'
            } else if(column == 4 && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel != "-" && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel == $scope.retentionStat3ByRetentionGroup[8].cellsContent[row].cellLabel){
                return '#f0f7ee'
            } else if(column == 4 && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel != "-" && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel == $scope.retentionStat3ByRetentionGroup[7].cellsContent[row].cellLabel){
                return '#fef5eb'
            } else if(column == 4 && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel != "-" && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel == $scope.retentionStat3ByRetentionGroup[6].cellsContent[row].cellLabel){
                return '#fbebeb'
            } else if(column == 6 && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel != "-" && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel == $scope.retentionStat3ByRetentionGroup[0].cellsContent[row].cellLabel){
                return '#a4c2f4'
            } else if(column == 6 && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel != "-" && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel == $scope.retentionStat3ByRetentionGroup[1].cellsContent[row].cellLabel){
                return '#85d8f7'
            } else if(column == 6 && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel != "-" && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel == $scope.retentionStat3ByRetentionGroup[2].cellsContent[row].cellLabel){
                return '#d3e7cb'
            } else if(column == 6 && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel != "-" && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel == $scope.retentionStat3ByRetentionGroup[3].cellsContent[row].cellLabel){
                return '#fdead7'
            } else if(column == 6 && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel != "-" && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel == $scope.retentionStat3ByRetentionGroup[4].cellsContent[row].cellLabel){
                return '#fbebeb'
            } else if(column == 7 && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel != "-" && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel == $scope.retentionStat3ByRetentionGroup[0].cellsContent[row].cellLabel){
                return '#b4a7d6'
            } else if(column == 7 && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel != "-" && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel == $scope.retentionStat3ByRetentionGroup[1].cellsContent[row].cellLabel){
                return '#b6cef6'
            } else if(column == 7 && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel != "-" && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel == $scope.retentionStat3ByRetentionGroup[2].cellsContent[row].cellLabel){
                return '#a3e2f9'
            } else if(column == 7 && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel != "-" && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel == $scope.retentionStat3ByRetentionGroup[3].cellsContent[row].cellLabel){
                return '#e2efdc'
            } else if(column == 7 && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel != "-" && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel == $scope.retentionStat3ByRetentionGroup[4].cellsContent[row].cellLabel){
                return '#fef5eb'
            } else if(column == 8 && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel != "-" && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel == $scope.retentionStat3ByRetentionGroup[0].cellsContent[row].cellLabel){
                return '#d5a6bd'
            } else if(column == 8 && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel != "-" && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel == $scope.retentionStat3ByRetentionGroup[1].cellsContent[row].cellLabel){
                return '#c3b9de'
            } else if(column == 8 && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel != "-" && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel == $scope.retentionStat3ByRetentionGroup[2].cellsContent[row].cellLabel){
                return '#c8daf8'
            } else if(column == 8 && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel != "-" && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel == $scope.retentionStat3ByRetentionGroup[3].cellsContent[row].cellLabel){
                return '#c2ebfb'
            } else if(column == 8 && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel != "-" && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel == $scope.retentionStat3ByRetentionGroup[4].cellsContent[row].cellLabel){
                return '#f0f7ee'
            } else if(column == 9 && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel != "-" && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel == $scope.retentionStat3ByRetentionGroup[1].cellsContent[row].cellLabel){
                return '#ddb8ca'
            } else if(column == 9 && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel != "-" && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel == $scope.retentionStat3ByRetentionGroup[2].cellsContent[row].cellLabel){
                return '#d2cae6'
            } else if(column == 9 && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel != "-" && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel == $scope.retentionStat3ByRetentionGroup[3].cellsContent[row].cellLabel){
                return '#dbe7fb'
            } else if(column == 9 && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel != "-" && $scope.retentionStat3ByRetentionGroup[column].cellsContent[row].cellLabel == $scope.retentionStat3ByRetentionGroup[4].cellsContent[row].cellLabel){
                return '#e0f5fd'
            }
        }

        $scope.retentionGroupChanged = function(){
            if($scope.retentionGroup == 0 && $scope.retentionStat3 != null){
                $scope.retentionStat3ByRetentionGroup = $scope.retentionStat3.total;
            } else if($scope.retentionGroup == 1 && $scope.retentionStat3 != null){
                $scope.retentionStat3ByRetentionGroup = $scope.retentionStat3.loyalty;
            } else if($scope.retentionGroup == 2 && $scope.retentionStat3 != null){
                $scope.retentionStat3ByRetentionGroup = $scope.retentionStat3.coupon;
            } else if($scope.retentionGroup == 3 && $scope.retentionStat3 != null){
                $scope.retentionStat3ByRetentionGroup = $scope.retentionStat3.other;
            }
        }

       function getRetentionData(retentionStatSelection) {
            let filters = {};
            filters.yaltyCities = [];
            if ($scope.selectedYaltyCities.length != $scope.allYaltyCity.length) {
                for (let i = 0; i < $scope.selectedYaltyCities.length; i++) {
                    filters.yaltyCities.push($scope.allYaltyCity[$scope.selectedYaltyCities[i]].placeName);
                }
            }
            let firstDate;
            let lastDate;
            let endDateForStat3;
            if($scope.retentionAggregationType == 2){
                firstDate = new Date(2018, 4, 14)
                lastDate = moment().endOf('week').toDate();
                endDateForStat3 = moment().subtract(1, 'week').endOf('week').toDate();
            } else {
                firstDate = new Date(2018, 4, 1)
                lastDate = moment().endOf('month').toDate();
                endDateForStat3 = moment().subtract(1, 'month').endOf('month').toDate();
            }
            if ($scope.retentionAggregationType == 2) {
                if (retentionStatSelection == 0 || retentionStatSelection == 1) {
                    if($scope.fromTimestampWeekForStat1 == null){
                        filters.stat1PeriodStartTimestamp = firstDate;
                    } else {
                        filters.stat1PeriodStartTimestamp = $scope.fromTimestampWeekForStat1
                    }
                    if($scope.untilTimestampWeekForStat1 == null){
                        filters.stat1PeriodEndTimestamp = lastDate;
                    } else {
                        filters.stat1PeriodEndTimestamp = $scope.untilTimestampWeekForStat1
                    }
                }
                if (retentionStatSelection == 0 || retentionStatSelection == 2) {
                    if($scope.fromTimestampWeekForStat2 == null){
                        filters.stat2PeriodStartTimestamp = firstDate;
                    } else {
                        filters.stat2PeriodStartTimestamp = $scope.fromTimestampWeekForStat2
                    }
                    if($scope.untilTimestampWeekForStat2 == null){
                        filters.stat2PeriodEndTimestamp = lastDate;
                    } else {
                        filters.stat2PeriodEndTimestamp = $scope.untilTimestampWeekForStat2
                    }
                }
                if (retentionStatSelection == 0 || retentionStatSelection == 3) {
                    if($scope.fromTimestampWeekForStat3 == null){
                        filters.stat3PeriodStartTimestamp = firstDate;
                    } else {
                        filters.stat3PeriodStartTimestamp = $scope.fromTimestampWeekForStat3
                    }
                    if($scope.untilTimestampWeekForStat3 == null){
                        filters.stat3PeriodEndTimestamp = endDateForStat3;
                    } else {
                        filters.stat3PeriodEndTimestamp = $scope.untilTimestampWeekForStat3
                    }
                }
            } else {
                if (retentionStatSelection == 0 || retentionStatSelection == 1) {
                    if($scope.fromTimestampMonthForStat1 == null){
                        filters.stat1PeriodStartTimestamp = firstDate;
                    } else {
                        filters.stat1PeriodStartTimestamp = $scope.fromTimestampMonthForStat1
                    }
                    if($scope.untilTimestampMonthForStat1 == null){
                        filters.stat1PeriodEndTimestamp = lastDate;
                    } else {
                        filters.stat1PeriodEndTimestamp = $scope.untilTimestampMonthForStat1
                    }
                }
                if (retentionStatSelection == 0 || retentionStatSelection == 2) {
                    if($scope.fromTimestampMonthForStat2 == null){
                        filters.stat2PeriodStartTimestamp = firstDate;
                    } else {
                        filters.stat2PeriodStartTimestamp = $scope.fromTimestampMonthForStat2
                    }
                    if($scope.untilTimestampMonthForStat2 == null){
                        filters.stat2PeriodEndTimestamp = lastDate;
                    } else {
                        filters.stat2PeriodEndTimestamp = $scope.untilTimestampMonthForStat2
                    }
                }
                if (retentionStatSelection == 0 || retentionStatSelection == 3) {
                    if($scope.fromTimestampMonthForStat3 == null){
                        filters.stat3PeriodStartTimestamp = firstDate;
                    } else {
                        filters.stat3PeriodStartTimestamp = $scope.fromTimestampMonthForStat3
                    }
                    if($scope.untilTimestampMonthForStat3 == null){
                        filters.stat3PeriodEndTimestamp = endDateForStat3;
                    } else {
                        filters.stat3PeriodEndTimestamp = $scope.untilTimestampMonthForStat3
                    }
                }
            } 
            if (retentionStatSelection == 0 || retentionStatSelection == 2) {
                let startDate = moment(filters.stat2PeriodStartTimestamp)
                let endDate = moment(filters.stat2PeriodEndTimestamp)
                const period = $scope.retentionAggregationType == 2 ? 'weeks' : 'months'
                const diff = endDate.diff(startDate, period)
                filters.nrOfPeriodUnitsAtStat2 = (diff + 1)
            }
            filters.aggregationType = $scope.retentionAggregationType
            filters.frontendTimezone = 'Central European Standard Time'
            filters.retentionStatScope = retentionStatSelection
            if(retentionStatSelection == 0) {
                $scope.sendButtonDisabled = true;
            } else if(retentionStatSelection == 1){
                $scope.sendButtonDisabled1 = true;
            } else if(retentionStatSelection == 2){
                $scope.sendButtonDisabled2 = true;
            } else if(retentionStatSelection == 3){
                $scope.sendButtonDisabled3 = true;
            }
            let APIName = '/yaltyadmin/getAppUsageUserRetentionStats'
            return $http.post(API_PREFIX.url + APIName, filters).then(function (result) {
                $scope.sendButtonDisabled = false;
                if (result.data.errorCode == 0) {
                    $scope.thHeight = $scope.retentionAggregationType
                    $scope.retentionStat1 = result.data.appUsageUserRetentionStats.stat1 ? 
                        result.data.appUsageUserRetentionStats.stat1 : $scope.retentionStat1
                    $scope.retentionStat2 = result.data.appUsageUserRetentionStats.stat2 ?
                    result.data.appUsageUserRetentionStats.stat2 : $scope.retentionStat2
                    $scope.retentionStat3 = result.data.appUsageUserRetentionStats.stat3 ?
                    result.data.appUsageUserRetentionStats.stat3 : $scope.retentionStat3
                    $scope.retentionGroupChanged();
                    $scope.lastAggregationType = result.data.aggregationType;
                    if ($scope.retentionStat1 != null) {
                        $scope.sendButtonDisabled1 = false;
                        $scope.dataForStat1 = [];
                        let array1 = [];
                        let array2 = [];
                        let array3 = [];
                        let array4 = [];
                        $scope.labelsForStat1 = [];
                        for (let i = 0; i < $scope.retentionStat1.length; i++) {
                            $scope.retentionStat1[i].totalActiveUserNrPerAll = $scope.retentionStat1[i].totalUserNr != 0 ? ($scope.retentionStat1[i].totalActiveUserNr * 100 / $scope.retentionStat1[i].totalUserNr).toFixed(1) : 0
                            $scope.retentionStat1[i].totalActiveUserNrWithLoyaltyCardRedeemPerAll = $scope.retentionStat1[i].totalUserNrWithLoyaltyCardRedeem != 0 ? ($scope.retentionStat1[i].totalActiveUserNrWithLoyaltyCardRedeem * 100 / $scope.retentionStat1[i].totalUserNrWithLoyaltyCardRedeem).toFixed(1) : 0
                            $scope.retentionStat1[i].totalActiveUserNrWithCouponViewPerAll = $scope.retentionStat1[i].totalUserNrWithCouponView != 0 ? ($scope.retentionStat1[i].totalActiveUserNrWithCouponView * 100 / $scope.retentionStat1[i].totalUserNrWithCouponView).toFixed(1) : 0
                            $scope.retentionStat1[i].otherActiveUserNrPerAll = $scope.retentionStat1[i].totalOtherUserNr != 0 ? ($scope.retentionStat1[i].otherActiveUserNr * 100 / $scope.retentionStat1[i].totalOtherUserNr).toFixed(1) : 0
                            array1.push($scope.retentionStat1[i].totalActiveUserNrPerAll);
                            array2.push($scope.retentionStat1[i].totalActiveUserNrWithLoyaltyCardRedeemPerAll)
                            array3.push($scope.retentionStat1[i].totalActiveUserNrWithCouponViewPerAll)
                            array4.push($scope.retentionStat1[i].otherActiveUserNrPerAll)
                            $scope.labelsForStat1.push($scope.retentionStat1[i].label)
                        }
                        $scope.dataForStat1.push(array1)
                        $scope.dataForStat1.push(array2)
                        $scope.dataForStat1.push(array3)
                        $scope.dataForStat1.push(array4)
                        $scope.seriesForStat1 = ["Összes", "Hűségkártyás", "Kuponos", "Egyéb"]
                    }
                    let totalUserNr = 0, totalUserNrWithLoyaltyCardRedeem = 0, totalUserNrWithCouponView = 0, otherUserNr = 0
                    if ($scope.retentionStat2 != null) {
                        $scope.sendButtonDisabled2 = false;
                        $scope.dataForStat2 = [];
                        let array1 = [];
                        let array2 = [];
                        let array3 = [];
                        let array4 = [];
                        $scope.labelsForStat2 = [];
                        for (let i = 0; i < $scope.retentionStat2.length; i++) {
                            totalUserNr += $scope.retentionStat2[i].totalUserNr
                            totalUserNrWithLoyaltyCardRedeem += $scope.retentionStat2[i].totalUserNrWithLoyaltyCardRedeem
                            totalUserNrWithCouponView += $scope.retentionStat2[i].totalUserNrWithCouponView
                            otherUserNr += $scope.retentionStat2[i].otherUserNr
                            
                        }
                        $scope.totalUserNrForStat2 = totalUserNr;
                        $scope.totalUserNrWithLoyaltyCardRedeemForStat2 = totalUserNrWithLoyaltyCardRedeem;
                        $scope.totalUserNrWithCouponViewForStat2 = totalUserNrWithCouponView;
                        $scope.otherUserNrForStat2 = otherUserNr;
                        for (let i = 0; i < $scope.retentionStat2.length; i++) {
                            $scope.retentionStat2[i].totalUserNrPerAll = totalUserNr != 0 ? ($scope.retentionStat2[i].totalUserNr * 100 / totalUserNr).toFixed(1) : 0
                            $scope.retentionStat2[i].totalUserNrWithLoyaltyCardRedeemPerAll = totalUserNrWithLoyaltyCardRedeem != 0 ? ($scope.retentionStat2[i].totalUserNrWithLoyaltyCardRedeem * 100 / totalUserNrWithLoyaltyCardRedeem).toFixed(1) : 0
                            $scope.retentionStat2[i].totalUserNrWithCouponViewPerAll = totalUserNrWithCouponView != 0 ? ($scope.retentionStat2[i].totalUserNrWithCouponView * 100 / totalUserNrWithCouponView).toFixed(1) : 0
                            $scope.retentionStat2[i].otherUserNrPerAll = otherUserNr != 0 ? ($scope.retentionStat2[i].otherUserNr * 100 / otherUserNr).toFixed(1) : 0
                            array1.push($scope.retentionStat2[i].totalUserNrPerAll)
                            array2.push($scope.retentionStat2[i].totalUserNrWithLoyaltyCardRedeemPerAll)
                            array3.push($scope.retentionStat2[i].totalUserNrWithCouponViewPerAll)
                            array4.push($scope.retentionStat2[i].otherUserNrPerAll)
                            $scope.labelsForStat2.push($scope.retentionStat2[i].label)
                        }
                        $scope.dataForStat2.push(array1)
                        $scope.dataForStat2.push(array2)
                        $scope.dataForStat2.push(array3)
                        $scope.dataForStat2.push(array4)
                        $scope.seriesForStat2 = ["Összes", "Hűségkártyás", "Kuponos", "Egyéb"]
                    }
                    if($scope.retentionStat3 != null){
                        $scope.sendButtonDisabled3 = false;
                        $scope.retentionStat3Periods = [];
                        $scope.dataForStat3_1 = [];
                        $scope.dataForStat3_2 = [];
                        let array1 = [];
                        let array2 = [];
                        let array3 = [];
                        let array4 = [];
                        $scope.referencePeriodList = [];
                        for(let i = 0; i < $scope.retentionStat3ByRetentionGroup[0].cellsContent.length; i++){
                            let period = {};
                            $scope.retentionStat3Periods.push($scope.retentionStat3ByRetentionGroup[0].cellsContent[i].cellLabel)
                            period.id = i;
                            period.name = $scope.retentionStat3ByRetentionGroup[0].cellsContent[i].cellLabel;
                            $scope.referencePeriodList.push(period);
                        }
                        $scope.referencePeriod = 0;
                        for(let i = 0; i < $scope.retentionStat3.total.length; i++){
                            if($scope.retentionStat3.total[i].cellsContent[$scope.referencePeriod].activityPercent == "-"){
                                array1.push(NaN)
                            } else {
                                array1.push(parseInt(($scope.retentionStat3.total[i].cellsContent[$scope.referencePeriod].activityPercent).slice(0, -1)))
                            }
                            if($scope.retentionStat3.loyalty[i].cellsContent[$scope.referencePeriod].activityPercent == "-"){
                                array2.push(NaN)
                            } else {
                                array2.push(parseInt(($scope.retentionStat3.loyalty[i].cellsContent[$scope.referencePeriod].activityPercent).slice(0, -1)))
                            }
                            if($scope.retentionStat3.coupon[i].cellsContent[$scope.referencePeriod].activityPercent == "-"){
                                array3.push(NaN)
                            } else {
                                array3.push(parseInt(($scope.retentionStat3.coupon[i].cellsContent[$scope.referencePeriod].activityPercent).slice(0, -1)))
                            }
                            if($scope.retentionStat3.other[i].cellsContent[$scope.referencePeriod].activityPercent == "-"){
                                array4.push(NaN)
                            } else {
                                array4.push(parseInt(($scope.retentionStat3.other[i].cellsContent[$scope.referencePeriod].activityPercent).slice(0, -1)))
                            }
                        }
                        $scope.dataForStat3_1.push(array1)
                        $scope.dataForStat3_1.push(array2)
                        $scope.dataForStat3_1.push(array3)
                        $scope.dataForStat3_1.push(array4)
                        $scope.seriesForStat3_1 = ["Összes", "Hűségkártyás", "Kuponos", "Egyéb"]
                        $scope.labelsForStat3_1 = [];
                        $scope.labelsForStat3_2 = [];
                        $scope.seriesForStat3_2 = $scope.retentionStat3Periods;
                        //$scope.seriesForStat3_2 = $scope.referencePeriodList;
                        for(let i = 0; i < $scope.retentionStat3ByRetentionGroup.length; i++){
                            $scope.labelsForStat3_1.push($scope.retentionStat3ByRetentionGroup[i].columnLabel);
                            $scope.labelsForStat3_2.push($scope.retentionStat3ByRetentionGroup[i].columnLabel);
                        }
                        for(let j = 0; j < $scope.seriesForStat3_2.length; j++){
                            let array = [];
                            for(let i = 0; i < $scope.retentionStat3ByRetentionGroup.length; i++){
                                if($scope.retentionStat3ByRetentionGroup[i].cellsContent[j].activityPercent == "-"){
                                    array.push(NaN)
                                } else {
                                    array.push(parseInt(($scope.retentionStat3ByRetentionGroup[i].cellsContent[j].activityPercent).slice(0, -1)))
                                }
                            }
                            $scope.dataForStat3_2.push(array);
                        }
                    }
                    setListHeight()
                    $scope.showRetentionStats = true
                }
            })
       }

       $scope.referencePeriodChanged = function(){
            $scope.dataForStat3_1 = [];
            let array1 = [];
            let array2 = [];
            let array3 = [];
            let array4 = [];
            for(let i = 0; i < $scope.retentionStat3.total.length; i++){
                if($scope.retentionStat3.total[i].cellsContent[$scope.referencePeriod].activityPercent == "-"){
                    array1.push(NaN)
                } else {
                    array1.push(parseInt(($scope.retentionStat3.total[i].cellsContent[$scope.referencePeriod].activityPercent).slice(0, -1)))
                }
                if($scope.retentionStat3.loyalty[i].cellsContent[$scope.referencePeriod].activityPercent == "-"){
                    array2.push(NaN)
                } else {
                    array2.push(parseInt(($scope.retentionStat3.loyalty[i].cellsContent[$scope.referencePeriod].activityPercent).slice(0, -1)))
                }
                if($scope.retentionStat3.coupon[i].cellsContent[$scope.referencePeriod].activityPercent == "-"){
                    array3.push(NaN)
                } else {
                    array3.push(parseInt(($scope.retentionStat3.coupon[i].cellsContent[$scope.referencePeriod].activityPercent).slice(0, -1)))
                }
                if($scope.retentionStat3.other[i].cellsContent[$scope.referencePeriod].activityPercent == "-"){
                    array4.push(NaN)
                } else {
                    array4.push(parseInt(($scope.retentionStat3.other[i].cellsContent[$scope.referencePeriod].activityPercent).slice(0, -1)))
                }
            }
            $scope.dataForStat3_1.push(array1)
            $scope.dataForStat3_1.push(array2)
            $scope.dataForStat3_1.push(array3)
            $scope.dataForStat3_1.push(array4)
       }

    })

    .controller('PotentialCompaniesController', function ($rootScope, $scope, $state, $cookies, $http, API_PREFIX, $mdDialog, $anchorScroll, $location, $window, YaltyAuthService, AuthService, commonService) {
        $scope.delayInDays = 5;
        function setListHeight() {
            setTimeout(function () {
                if ($scope.companylist != undefined && $scope.companylist.length != 0) {
                    let currentStateHeight = document.getElementById('currentStateDiv').offsetHeight;
                    let infoHeight = document.getElementById('infoDiv').offsetHeight;
                    let windowsHeight = window.innerHeight - currentStateHeight - infoHeight - 70;
                    $scope.listHeight = { 'height': windowsHeight + 'px' };
                } else {
                    $scope.listHeight = { 'height': 30 + 'px' };
                }
            }, 100);
        }

        setListHeight();
        window.onresize = function (event) {
            setListHeight();
        };
        document.getElementById("cimke").innerHTML = "";
        document.getElementById("cimke2").innerHTML = "";
        $scope.country = 'Magyarország';
        $scope.storesCount1 = "1";
        $scope.storesCount2 = "1";
        $scope.listing = "0";
        var city = $cookies.get('yaltyCity');
        var adminUserId = parseInt($cookies.get('adminUserId'));
        if (city != null) {
            $scope.compStoreAddrCity = city;
        }

        function range(start, end, step, offset) {

            var len = (Math.abs(end - start) + ((offset || 0) * 2)) / (step || 1) + 1;
            var direction = start < end ? 1 : -1;
            var startingPoint = start - (direction * (offset || 0));
            var stepSize = direction * (step || 1);

            return Array(len).fill(0).map(function (_, index) {
                return startingPoint + (stepSize * index);
            });
        }
        $scope.nextTodo = "";
        $scope.range = range(1, 90);
        $scope.decisionMakerStrings = []
        $scope.decisionMakerStrings.push({ id: 0, value: 'Nem egyeztettünk a döntéshozóval a beszélgetés során.' })
        $scope.decisionMakerStrings.push({ id: 1, value: 'A döntéshozóval (is) egyeztettünk a beszélgetés során.' })
        $scope.decisionMakerStrings.push({ id: 2, value: 'Nem derült ki a beszélgetés során, hogy döntéshozó volt-e.' })
        $scope.enquiryStrings = []
        $scope.enquiryStrings.push({ id: 1, value: '1 - Nagyon negatív volt' })
        $scope.enquiryStrings.push({ id: 2, value: '2 - Inkább negatív' })
        $scope.enquiryStrings.push({ id: 3, value: '3 - Semleges' })
        $scope.enquiryStrings.push({ id: 4, value: '4 - Inkább érdeklődő volt' })
        $scope.enquiryStrings.push({ id: 5, value: '5 - Nagyon érdeklődő volt' })
        $scope.administrationCheckbox = false;
        $scope.isAdministrationWindow = false;
        $scope.showCompanyList = false;
        $scope.hideCalledComps = null;
        $scope.showInfo = false;
        $scope.showFirstCall = false;
        $scope.showSendLink = false;
        $scope.showSendLink2 = false;
        $scope.showTodos = false;
        $scope.isAddCompanyWindow = false;
        $scope.isFirstAddCompanyWindow = false;
        $scope.isSearchWindow = false;
        $scope.isAddCompanyWindow2 = false;
        $scope.isEnabledModComp = false;
        $scope.isSearchResults = false;
        $scope.facebookPrefix = "facebook.com/";
        $scope.fbURL = $scope.facebookPrefix;
        $scope.webPrefix = "http://www.";
        $scope.webURL = $scope.webPrefix;
        $scope.companyProfiles = [];
        $scope.allCompanyProfile = [];
        var companyMainId;
        if ($cookies.get('mainId') != null) {
            companyMainId = parseInt($cookies.get('mainId'));
        }
        $(document).on("wheel", "input[type=number]", function (e) {
            $(this).blur();
        });
        $scope.validFBURL = function () {
            if ($scope.fbURL != undefined) {
                if ($scope.fbURL.split($scope.facebookPrefix)[1]) {
                    var fbURL = $scope.facebookPrefix + $scope.fbURL.split($scope.facebookPrefix)[1];
                    $scope.fbURL = fbURL;
                } else {
                    $scope.fbURL = $scope.facebookPrefix;
                }
            }
        }

        $scope.refreshAdminToken = function () {
            var currentState = $state.current.name;
            var payload = {};
            payload.state = currentState;
            payload.fromState = currentState;
            YaltyAuthService.isYaltyAdminUserAuthorized(payload).then(function (result) {
                if (result.authorized === true && result.errorCode === 0) {
                    AuthService.updateSessionData(result)
                    $rootScope.endDate = result.endDate;
                    $rootScope.deadlineInMilli = result.alertDate;
                    $rootScope.isTimerSet = true;
                    $rootScope.$broadcast('timer-clear');
                    $rootScope.$broadcast('timer-start');
                    $rootScope.timerRunning = true;
                }
            })
        }

        $scope.newPreReg = function () {
            const potPartner = $scope.companylist[0]
            let listItemsString = '<ul class=\"newPreregAltertWindowList\">'
            if (potPartner.compSize == 3) {
                listItemsString += '<li>Cégméret: <b class=\"redbold\">“Hazai lánc (>= 5 üzlet és csak itthon van üzlete)”</b> - Tipikusan őket még ne keressük. Egyedi esetben egyeztessünk.</li>'
            } else if (potPartner.compSize == 4) {
                listItemsString += '<li>Cégméret: <b class=\"redbold\">“Nemzetközi lánc (>= 5 üzlet és külföldön is van üzlete)”</b> - Tipikusan őket még ne keressük. Egyedi esetben egyeztessünk.</li>'
            } else if (potPartner.numberOfStores >= 5) {
                listItemsString += '<li>A cégnek <b class=\"redbold\">“5 vagy több üzlete van.”</b> Tipikusan őket még ne keressük. Egyedi esetben egyeztessünk.</li>'
            }
            if (potPartner.ratingCompClass == 1) {
                listItemsString += '<li>A Cég klassz (osztály/stílus) = “<b class=\"redbold\">1 - Idejétmúlt</b>” kategória tipikusan számunkra nem célcsoport. Hacsak nem valami kivételes ügyről van szó vélhetően nem érdemes keresnünk.</li>'
            } else if (potPartner.ratingCompClass == 5) {
                listItemsString += '<li>A Cég klassz (osztály/stílus) = “<b class=\"redbold\">5 - Exkluzív</b>” kategória tipikusan számunkra nem célcsoport. Hacsak nem valami kivételes ügyről van szó vélhetően nem érdemes keresnünk.</li>'
            }
            if (potPartner.facebookPagePriceClass == 3) {
                listItemsString += '<li>A FB árszint = “<b class=\"redbold\">$$$</b>” - Ebben az árkategóriában vannak túl drága cégek is és olyanok is, amelyek árszintje még ok. Ellenőrizd, hogy ennél a cégnél melyik esetről van szó. Ha nagyon drágák, akkor valószínű nem érdemes ezt a céget keressük.</li>'
            } else if (potPartner.facebookPagePriceClass == 4) {
                listItemsString += '<li>A FB árszint = “<b class=\"redbold\">$$$$</b>” - Ellenőrizd! Ha valós ez az árkategória, akkor valószínű nem érdemes ezt a céget keressük.</li>'
            }
            if (potPartner.googlePriceClass == 3) {
                listItemsString += '<li>A Google árszint = “<b class=\"redbold\">$$$</b>” - Ebben az árkategóriában vannak túl drága cégek is és olyanok is, amelyek árszintje még ok. Ellenőrizd, hogy ennél a cégnél melyik esetről van szó. Ha nagyon drágák, akkor valószínű nem érdemes ezt a céget keressük.</li>'
            } else if (potPartner.googlePriceClass == 4) {
                listItemsString += '<li>A Google árszint = “<b class=\"redbold\">$$$$</b>” - Ellenőrizd! Ha valós ez az árkategória, akkor valószínű nem érdemes ezt a céget keressük.</li>'
            }
            listItemsString += '</ul>'
            if (listItemsString.length != 43) {
                var confirm = $mdDialog.confirm()
                    .title('A cég egy vagy több jellemzője alapján nem tartozik a (jelenlegi) potenciális HK cégeink körébe. Nézd át az alábbi pontokat és ez alapján gondold át, hogy mégis előregisztrálod-e. Kérdés esetén egyeztessük az esetet.')
                    .htmlContent(listItemsString)
                    .ok('Tovább')
                    .cancel('Mégsem')
                $mdDialog.show(confirm).then(function () {
                    openNewPrereg();
                }, function () { })
            } else {
                openNewPrereg();
            }

        }

        var openNewPrereg = function () {
            let obj = {};
            obj.salesPotentialCompanyMainId = $scope.mainId;
            $http.post(API_PREFIX.url + '/yaltyadmin/checkExistingPreregistrationForPotPartner', obj).then(function (result) {
                if (result.data.errorCode == 0) {
                    if (result.data.exist) {
                        $scope.companyPreRegistrationId = result.data.companyPreRegistrationId;
                        var confirm = $mdDialog.confirm()
                            .textContent("A potenciális partnerhez már létezik előregisztráció. A Listázás gomb megnyomására listázzuk neked. Ha szerkeszteni szeretnéd, nyomd meg a Szerkesztés gombot.")
                            .ok('Listázás')
                            .cancel('Szerkesztés');
                        $mdDialog.show(confirm).then(function () {
                            $scope.openPreReg(1);
                        }, function () {
                            $scope.openPreReg(2);
                        });
                    } else {
                        //$cookies.put('potentialCompanyMainId', $scope.mainId);
                        var newTab = $window.open("", "_blank");
                        newTab.location = "/yaltyadmin738/preregisterPartner?mainId=" + $scope.mainId;
                    }
                } else {

                }
            }, function () { });
        }

        $scope.openPreReg = function (mode) {
            if (mode == 1) {
                let newTab = $window.open("", "_blank");
                newTab.location = "/yaltyadmin738/preregistrations/" + $scope.companyPreRegistrationId;
            } else if (mode == 2) {
                let newTab = $window.open("", "_blank");
                newTab.location = "/yaltyadmin738/preregisterPartner?preregId=" + $scope.companyPreRegistrationId;
            }
        }

        $scope.goals = ["Első kapcsolatfelvétel (ismertetés)", "Első kapcsolatfelvétel (ismertetés) - Újra/Visszahívás", "Első kapcsolatfelvétel (ismertetés) - Új kontakt hívása", "Email kiküldése utáni kapcsolatfelvétel - Követő hívás"];

        $scope.querySearchGoal = function (query) {
            if (query) {
                var filtered = $scope.goals.filter(function (i) {
                    var lowercaseQuery = angular.lowercase(query);
                    return ((i.toLowerCase()).indexOf(lowercaseQuery) === 0);
                })
                if (filtered.length > 0) {
                    return filtered;
                } else {
                    return $scope.goals;
                }
            } else {
                return $scope.goals;
            }
        }

        $scope.results = ["Nem vették fel - Sok kicsörgés után kisípolt",
            "Nem vették fel - Ki sem csöngött (rögtön leállt)",
            "Nem vették fel - A számon előfizető nem kapcsolható",
            "Nem vették fel - Rögtön hangpostára ment",
            "Nem vették fel - Ez a szám jelenleg nem elérhető",
            "Nem vették fel - Elutasították (“kinyomták”)",
            "Nem ért rá - Visszahívást kért",
            "Nem ért rá - De további infót elfogad (kiküldjük)",
            "Nem érdeklődik kifejezetten – De még átgondolja",
            "Érdeklődik – Még átgondolja",
            "Érdeklődik – Egyeztetnie kell még (valakivel)",
            "Érdeklődik – További információt kért",
            "Kolléga vette fel – Megadta az illetékes elérhetőségét",
            "Kolléga vette fel – Új időpontot javasolt az újrahívásra",
            "Kolléga vette fel – Továbbítja az elérhetőségemet",
            "A hívott szám (már) egy magánszám",
            "A cég már megszűnt",
            "Sikertelen értékesítés"];


        $scope.querySearchResult = function (query) {
            if (query) {
                var filtered = $scope.results.filter(function (i) {
                    var lowercaseQuery = angular.lowercase(query);
                    return ((i.toLowerCase()).indexOf(lowercaseQuery) === 0);
                })
                if (filtered.length > 0) {
                    return filtered;
                } else {
                    return $scope.results;
                }
            } else {
                return $scope.results;
            }
        }

        $scope.reasons = [];


        $scope.querySearchReason = function (query) {
            if (query) {
                var filtered = $scope.reasons.filter(function (i) {
                    var lowercaseQuery = angular.lowercase(query);
                    return ((i.toLowerCase()).indexOf(lowercaseQuery) === 0);
                })
                if (filtered.length > 0) {
                    return filtered;
                } else {
                    return $scope.reasons;
                }
            } else {
                return $scope.reasons;
            }
        }

        $scope.nextTodos = ["Újra/Visszahívás", "Regisztrációs link / Információs email kiküldése", "Új telefonszámot keresni hozzá és újra hívni", "A kapott, új számon a döntéshozót felhívni"];


        $scope.querySearchNextTodo = function (query) {
            if (query) {
                var filtered = $scope.nextTodos.filter(function (i) {
                    var lowercaseQuery = angular.lowercase(query);
                    return ((i.toLowerCase()).indexOf(lowercaseQuery) === 0);
                })
                if (filtered.length > 0) {
                    return filtered;
                } else {
                    return $scope.nextTodos;
                }
            } else {
                return $scope.nextTodos;
            }
        }

        $scope.querySearchCompanyProfiles = function (query) {
            if (query) {
                var filtered = $scope.promoCompProfiles.filter(function (i) {
                    var lowercaseQuery = angular.lowercase(query);
                    return ((i.compProfile.toLowerCase()).indexOf(lowercaseQuery) === 0);
                })
                if (filtered.length > 0) {
                    return filtered;
                } else {
                    return $scope.promoCompProfiles;
                }
            } else {
                return $scope.promoCompProfiles;
            }
        }

        $scope.querySearch = function (query) {
            if (query) {
                var filtered = $scope.listOfCities.filter(function (i) {
                    var lowercaseQuery = angular.lowercase(query);
                    return ((i.placeName.toLowerCase()).indexOf(lowercaseQuery) === 0);
                })
                if(filtered.length > 50){
                    return filtered.slice(0, 50);
                } else if (filtered.length > 0) {
                    return filtered;
                } else {
                    return $scope.listOfCities.slice(0, 50);
                }
            } else {
                return $scope.listOfCities.slice(0, 50);
            }
        }
        $scope.validWebURL = function () {
            if ($scope.webURL != undefined) {
                if ($scope.webURL.split($scope.webPrefix)[1]) {
                    var webURL = $scope.webPrefix + $scope.webURL.split($scope.webPrefix)[1];
                    $scope.webURL = webURL;
                } else {
                    $scope.webURL = $scope.webPrefix;
                }
            }
            if ($scope.webURL.split($scope.webPrefix)[1] == '') {
                $scope.webPageDesign = 0
            } else {
                if ($scope.webPageDesign == 0) $scope.webPageDesign = null
            }
        }

        $scope.changeCheckbox = function () {
            if ($scope.administrationCheckbox) {
                document.getElementById("administrationDiv").style.backgroundColor = "#00AEEF";
                //20230921 partnerContactObservations mező kivezetése:
                /*document.getElementById("experiencesButton").style.backgroundColor = "#00AEEF";
                document.getElementById("experiencesTitle").style.color = "#FFF";
                document.getElementById("experiencesButton").style.cursor = "pointer";*/
            } else {
                document.getElementById("administrationDiv").style.backgroundColor = "#DFDFDF";
                //20230921 partnerContactObservations mező kivezetése:
                /*document.getElementById("experiencesButton").style.backgroundColor = "#DFDFDF";
                document.getElementById("experiencesTitle").style.color = "#FFF";
                document.getElementById("experiencesButton").style.cursor = "not-allowed";
                $scope.clickOnNotesButton();*/
            }
        }

        //20230921 partnerContactObservations mező kivezetése:
        /*$scope.clickOnExperiencesButton = function () {
            if ($scope.administrationCheckbox) {
                $scope.textAreaMode = 1;
                document.getElementById("experiencesButton").style.backgroundColor = "#FFF";
                document.getElementById("experiencesTitle").style.color = "#00AEEF";
                document.getElementById("experiencesButton").style.cursor = "pointer";
                document.getElementById("notesButton").style.backgroundColor = "#00AEEF";
                document.getElementById("notesTitle").style.color = "#FFF";
                document.getElementById("insertDateButton").style.display = "none";
            }
        }*/

        $scope.clickOnMasterDataButton = function () {
            $scope.textAreaMode = 1;
            document.getElementById("experiencesButton").style.backgroundColor = "#FFF";
            document.getElementById("experiencesTitle").style.color = "#00AEEF";
            document.getElementById("experiencesButton").style.cursor = "pointer";
            document.getElementById("notesButton").style.backgroundColor = "#00AEEF";
            document.getElementById("notesTitle").style.color = "#FFF";
            //document.getElementById("insertDateButton").style.display = "none";
            document.getElementById("insertDateButton").style.display = "flex";
        }

        $scope.clickOnNotesButton = function () {
            $scope.textAreaMode = 2;
            document.getElementById("notesButton").style.backgroundColor = "#FFF";
            document.getElementById("notesTitle").style.color = "#00AEEF";
            //20230921 partnerContactObservations mező kivezetése:
            //if ($scope.administrationCheckbox) {
                document.getElementById("experiencesButton").style.backgroundColor = "#00AEEF";
                document.getElementById("experiencesTitle").style.color = "#FFF";
                document.getElementById("experiencesButton").style.cursor = "pointer";
            //}
            document.getElementById("insertDateButton").style.display = "flex";
        }

        $scope.clickOnInsertDateButton = function () {
            var now = new Date();
            var date = now.getFullYear() + "." + ("0" + (now.getMonth() + 1)).slice(-2) + "." + ("0" + now.getDate()).slice(-2) + ". " + ("0" + now.getHours()).slice(-2) + ":" + ("0" + now.getMinutes()).slice(-2);
            if($scope.textAreaMode == 2) {
                $scope.textArea2 = date + "\n\n" + $scope.textArea2;
            } else if($scope.textAreaMode == 1) {
                $scope.textArea1 = date + "\n\n" + $scope.textArea1;
            }
        }

        $scope.clickOnTextArea1Button1 = function () {
            let text = "Vezetéknév Keresztnév \"Becenév\" (tegeződünk/magázódunk)\nTulajdonos / Üzletvezető / stb.\n+361234567890\nemail@email.com\nCím: Irányítószám Város, Pontos közterület - ahova személyes egyeztetés során megyünk hozzá"+
            "\n\nKiajánlott kedvezmény: - (pl. \"Budapesti indulás egy évig 50%\" | \"nincs, a partneri kontakt formról keresett meg minket\")\nKiajánlási érvényesség: - (\"202x.xx.xx - 202x.xx.xx\")\nKedvezmény érvényessége: - (\"kifutott az időszakból\" | \"202x.xx.xx - 202x.xx.xx\")\n" +
            "Fizetés: - (itt írjuk le részletesen hogyan egyeztünk meg pl. havonta átutalja / 5 hónapot átutalt stb. / 2 havonta fizet a rendszeren keresztül normál díjat és a 2. hó díjmentes stb. | \"normál módon fizet\")" +
            "\n\nMarketing anyag(ok):\n- \"Kártya neve\" - EMAIL: 202x.xx.xx kiküldve | NYOMTATOTT:  202x.xx.xx átadva (vagy pl.\"nem vittünk (egyelőre vidékre nem viszünk)\")\n- Ajtómatrica: - (202x.xx.xx átadva, típus: QR kódos / kompakt | (még) nem vittünk)";
            $scope.textArea1 = text + "\n\n" + $scope.textArea1;
        }

        $scope.clickOnTextArea1Button2 = function () {
            let text = "Vezetéknév Keresztnév \"Becenév\" (tegeződünk/magázódunk)\nTulajdonos / Üzletvezető / stb.\n+361234567890\nemail@email.com\n\nCím: Irányítószám Város, Pontos közterület - ahova személyes egyeztetés során megyünk hozzá"+
            "\n\nKiajánlott kedvezmény: - \nKiajánlási érvényesség: - \nKedvezmény érvényessége: - \n" +
            "Fizetés: - normál módon fizet, ha előfizet" +
            "\n\nMarketing anyag(ok):\n- \"Kártya neve\" - EMAIL: 202x.xx.xx kiküldve | NYOMTATOTT:  202x.xx.xx átadva (vagy pl.\"nem vittünk (egyelőre vidékre nem viszünk)\")\n- Ajtómatrica: - (202x.xx.xx átadva, típus: QR kódos / kompakt | (még) nem vittünk)";
            $scope.textArea1 = text + "\n\n" + $scope.textArea1;
        }

        $scope.clickOnTextArea1Button3 = function () {
            navigator.clipboard.writeText("Vezetéknév Keresztnév \"Becenév\" (tegeződünk/magázódunk)\nTulajdonos / Üzletvezető / stb.\n+361234567890\nemail@email.com\nCím: Irányítószám Város, Pontos közterület - ahova személyes egyeztetés során megyünk hozzá");
        }

        $scope.clickOnTextArea1Button4 = function () {
            navigator.clipboard.writeText("Kiajánlott kedvezmény: - (pl. \"Budapesti indulás egy évig 50%\" | \"nincs, a partneri kontakt formról keresett meg minket\")\nKiajánlási érvényesség: - (\"202x.xx.xx - 202x.xx.xx\")\nKedvezmény érvényessége: - (\"kifutott az időszakból\" | \"202x.xx.xx - 202x.xx.xx\")\nFizetés: - (itt írjuk le részletesen hogyan egyeztünk meg pl. havonta átutalja / 5 hónapot átutalt stb. / 2 havonta fizet a rendszeren keresztül normál díjat és a 2. hó díjmentes stb. | \"normál módon fizet\")");
        }

        $scope.clickOnTextArea1Button5 = function () {
            navigator.clipboard.writeText("- \"Kártya neve\" - EMAIL: 202x.xx.xx kiküldve | NYOMTATOTT:  202x.xx.xx átadva (vagy pl.\"nem vittünk (egyelőre vidékre nem viszünk)\")");
        }

        $scope.todoCompleted = function () {
            var now = new Date();
            var date = now.getFullYear() + "." + ("0" + (now.getMonth() + 1)).slice(-2) + "." + ("0" + now.getDate()).slice(-2) + ". " + ("0" + now.getHours()).slice(-2) + ":" + ("0" + now.getMinutes()).slice(-2);
            var date2 = $scope.deadlineDate.getFullYear() + "." + ("0" + ($scope.deadlineDate.getMonth() + 1)).slice(-2) + "." + ("0" + $scope.deadlineDate.getDate()).slice(-2) + ". " + ("0" + $scope.deadlineTime.getHours()).slice(-2) + ":" + ("0" + $scope.deadlineTime.getMinutes()).slice(-2);
            $scope.textArea2 = date + ": TEENDŐ ELVÉGEZVE - \"" + $scope.ctrl.searchTextNextTodo + "\" (Határidő: " + date2 + ")\n\n" + $scope.textArea2;
            $scope.ctrl.searchTextNextTodo = "";
            $scope.deadlineDate = null;
            $scope.deadlineTime = null;
            $scope.isDeadLineDateLabelToShow = true
            $scope.isDeadLineTimeLabelToShow = true
        }
        
        $scope.$watch('quickSearchText', function (current) {
            if($scope.isPhoneNumberSearch === true) {
                var regexp = /^((\+[0-9]{1,})|([0-9]{6,}))\w$/
                if (!regexp.test(current)) {
                    $scope.quickSearchForm.quickSearchText.$setValidity('pattern', false);
                    $scope.quickSearchForm.quickSearchText.$setValidity('pattern2', true);
                    return 
                } else {
                    $scope.quickSearchForm.quickSearchText.$setValidity('pattern', true);
                    $scope.quickSearchForm.quickSearchText.$setValidity('pattern2', true);
                }
            } else if($scope.isEmailAddressSearch === true) {
                var regexp = /^(?=.{6,})[a-zA-Z0-9.!#$%&'*+/=?^_{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$/
                if (!regexp.test(current)) {
                    $scope.quickSearchForm.quickSearchText.$setValidity('pattern', true);
                    $scope.quickSearchForm.quickSearchText.$setValidity('pattern2', false);
                    return 
                } else {
                    $scope.quickSearchForm.quickSearchText.$setValidity('pattern', true);
                    $scope.quickSearchForm.quickSearchText.$setValidity('pattern2', true);
                }
            } else {
                $scope.quickSearchForm.quickSearchText.$setValidity('pattern', true);
                $scope.quickSearchForm.quickSearchText.$setValidity('pattern2', true);
            }
        })

        $scope.quickSearchTextIsNumber = function() {
            if($scope.quickSearchText == null || $scope.quickSearchText == '' || isNaN($scope.quickSearchText) || $scope.quickSearchText.startsWith('+') || $scope.quickSearchText.startsWith('0')) {
                return false;
            } else {
                return true;
            }
        }

        $scope.search = function (isQuickSearch, quickSearchType) {
            let filters = {};
            if (!isQuickSearch) {
                if (document.getElementById('loadingOverlay')) {
                    document.getElementById('loadingOverlay').style.display = "block";
                    document.getElementById('innerPartnerPageContainer').style.overflow = "hidden";
                }
                if ($scope.fbURL.split($scope.facebookPrefix)[1]) {
                    filters.facebookPageUserName = $scope.fbURL.split($scope.facebookPrefix)[1];
                }
                if ($scope.webURL.split($scope.webPrefix)[1]) {
                    filters.webPageNakedDomain = $scope.webURL.split($scope.webPrefix)[1];
                }
                if ($scope.taxNumber) {
                    filters.taxNum = $scope.taxNumber;
                }
                if ($scope.forekordAzon) {
                    filters.mainId = $scope.forekordAzon;
                }
                if ($scope.compName) {
                    filters.compName = $scope.compName;
                }
                if ($scope.fbMinLike) {
                    filters.facebookPageLikeNumber = $scope.fbMinLike;
                }
                if ($scope.country) {
                    filters.storeAddrCountry = $scope.country;
                }
                if ($scope.compStoreAddrCity2) {
                    filters.storeAddrCity = $scope.compStoreAddrCity2.placeName;
                }
                if ($scope.minStore) {
                    filters.numberOfStores = $scope.minStore;
                }
                if ($scope.storesCount1 != 1) {
                    filters.multipleStoresInCity = ($scope.storesCount1 - 2);
                } else {
                    filters.multipleStoresInCity = null;
                }
                if ($scope.storesCount2 != 1) {
                    filters.storesInMultipleCities = ($scope.storesCount2 - 2);
                } else {
                    filters.storesInMultipleCities = null;
                }
                if ($scope.mainContactPersonIdSearch) {
                    filters.contactOwnerYaltyAdminUserId = $scope.mainContactPersonIdSearch;
                }
                if ($scope.companyAddedBy) {
                    filters.yaltyAdminUserId1 = $scope.companyAddedBy;
                }
                if ($scope.companyModifiedBy) {
                    filters.yaltyAdminUserId2 = $scope.companyModifiedBy;
                }
                if ($scope.fromTimestamp) {
                    filters.fromTimestamp = $scope.fromTimestamp;
                }
                if ($scope.endTimestamp) {
                    filters.endTimestamp = $scope.endTimestamp;
                }
                filters.compProfiles = $scope.companyProfiles;
                filters.returnAllMatchingRecords = $scope.listing;
                $scope.isPhoneNumberSearch = false
                $scope.isEmailAddressSearch = false
                $scope.sendButtonDisabled = true;
            } else {
                if (!$scope.quickSearchText || $scope.quickSearchText == '') return
                var advancedQuickSearchDiv = document.getElementById("advancedQuickSearchBottom");
                advancedQuickSearchDiv.style.display = "none";
                setTimeout(function () { advancedQuickSearchDiv.style.display = "unset"; }, 100);
                if (quickSearchType == 0) {
                    if($scope.quickSearchTextIsNumber() == false) {
                        filters.compName = $scope.quickSearchText
                        $scope.isPhoneNumberSearch = false
                        $scope.isEmailAddressSearch = false
                    } else {
                        $scope.isPhoneNumberSearch = false
                        $scope.isEmailAddressSearch = false
                        filters.mainId = $scope.quickSearchText
                    }
                } else if (quickSearchType == 1) {
                    filters.compName = $scope.quickSearchText
                    $scope.isPhoneNumberSearch = false
                    $scope.isEmailAddressSearch = false
                } else if (quickSearchType == 2) {
                    filters.facebookPageUserName = $scope.quickSearchText
                    $scope.isPhoneNumberSearch = false
                    $scope.isEmailAddressSearch = false
                } else if (quickSearchType == 3) {
                    $scope.isPhoneNumberSearch = false
                    $scope.isEmailAddressSearch = false
                    if($scope.quickSearchTextIsNumber() == false) {
                        filters.mainId = -1
                    } else {
                        filters.mainId = $scope.quickSearchText
                    }
                } else if (quickSearchType == 4) {
                    filters.phoneNumber = $scope.quickSearchText
                    $scope.isPhoneNumberSearch = true
                    $scope.isEmailAddressSearch = false
                    var regexp = /^((\+[0-9]{1,})|([0-9]{6,}))\w$/
                    if (!regexp.test($scope.quickSearchText)) {
                        $scope.quickSearchForm.quickSearchText.$setValidity('pattern', false);
                        $scope.quickSearchForm.quickSearchText.$setValidity('pattern2', true);
                        return 
                    }
                } else if (quickSearchType == 5) {
                    filters.emailAddress = $scope.quickSearchText
                    $scope.isPhoneNumberSearch = false
                    $scope.isEmailAddressSearch = true
                    var regexp = /^(?=.{6,})[a-zA-Z0-9.!#$%&'*+/=?^_{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$/
                    if (!regexp.test($scope.quickSearchText)) {
                        $scope.quickSearchForm.quickSearchText.$setValidity('pattern', true);
                        $scope.quickSearchForm.quickSearchText.$setValidity('pattern2', false);
                        return 
                    }
                }
                $scope.quickSearchForm.quickSearchText.$setValidity('pattern', true);
                $scope.quickSearchForm.quickSearchText.$setValidity('pattern2', true);
                filters.returnAllMatchingRecords = 0
                $scope.waitingForQuickSearchResult = true
            }
            return $http.post(API_PREFIX.url + '/yaltyadmin/salesPotentialCompanySearch', filters).then(function (result) {
                if (document.getElementById('loadingOverlay')) {
                    document.getElementById('loadingOverlay').style.display = "none";
                    document.getElementById('innerPartnerPageContainer').style.overflow = "unset";
                }
                $scope.waitingForQuickSearchResult = false
                $scope.sendButtonDisabled = false;
                if (result.data.errorCode == 0) {
                    /*GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/partneri_módosítás_történet/lista_lekérdezés_sikeres",
                        "HU Partner [PRIVÁT] - Partneri módosítás történet oldal - Sikeres lista lekérdezés")*/
                    if (result.data.potentialCompanySearchResult.length == 0) {
                        var confirm
                        if (!isQuickSearch) {
                            confirm = $mdDialog.confirm()
                                .title('Nincs a keresésnek megfelelő találat')
                                .textContent('Módosítod a keresési feltételeket?')
                                .ok('KERESÉS LEZÁRÁSA')
                                .cancel('KERESÉSI FELTÉTELEK MÓDOSÍTÁSA');
                        } else {
                            confirm = $mdDialog.confirm()
                                .title('Nincs a keresésnek megfelelő találat')
                                .ok('OK')
                        }
                        $mdDialog.show(confirm).then(function () {
                            if (!isQuickSearch) {
                                $scope.isSearchWindow = false;
                                $scope.showCompanyList = false;
                                $scope.hideCalledComps = null;
                                $scope.showInfo = false;
                                $scope.showFirstCall = false;
                                $scope.showSendLink = false;
                                $scope.showSendLink2 = false;
                                $scope.showTodos = false;
                                $scope.isEnabledModComp = false;
                                $scope.isSearchResults = false;
                            }
                        }, function () {
                        });
                    } else {
                        $scope.companylist = result.data.potentialCompanySearchResult;
                        $scope.showCompanyList = true;
                        $scope.hideCalledComps = null;
                        $scope.showInfo = false;
                        $scope.showFirstCall = false;
                        $scope.showSendLink = false;
                        $scope.showSendLink2 = false;
                        $scope.showTodos = false;
                        $scope.isSearchWindow = false;
                        $scope.isEnabledModComp = false;
                        $scope.isSearchResults = true;
                        $scope.orderBy = 1;
                        let array = [];
                        for (let i = 0; i < result.data.potentialCompanySearchResult.length; i++) {
                            if (array.indexOf(result.data.potentialCompanySearchResult[i].mainId) === -1) {
                                array.push(result.data.potentialCompanySearchResult[i].mainId);
                            }
                        }
                        document.getElementById("cimke").innerHTML = "<b>Keresési találatok: " + array.length + "</b> cég <b>" + result.data.potentialCompanySearchResult.length + "</b> rekordja";
                        document.getElementById("cimke2").innerHTML = "<b>Keresési találatok: " + array.length + "</b> cég <b>" + result.data.potentialCompanySearchResult.length + "</b> rekordja";
                        setListHeight();
                        if(array.length == 1) {
                            $cookies.put('mainId', array[0]);
                            /*var newTab = $window.open("", "_blank");
                            newTab.location = "/yaltyadmin738/adminPotentialCompanies";*/
                            $window.location.reload();
                        }
                    }
                } else {
                    /*GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/partneri_módosítás_történet/lista_lekérdezés_sikertelen",
                        "HU Partner [PRIVÁT] - Partneri módosítás történet oldal - Sikertelen lista lekérdezés")*/
                    commonService.showDialogWindowWithTitleOnly('Szerver hiba. Próbáld meg egy kicsit később újra.', 'Rendben')
                }
            });
        }

        $scope.$watch('fromTimestamp', function (newValue, oldValue) { $scope.minDate = newValue; });

        $scope.$watch('endTimestamp', function (newValue, oldValue) { $scope.maxDate = newValue; });

        $scope.checkDates = function () {
            $scope.minDate = $scope.fromTimestamp;
        }

        $scope.orderSearchList = function () {
            if ($scope.orderBy == 1) {
                $scope.companylist.sort((a, b) => (a.mainId < b.mainId) ? 1 : -1)
            } else if ($scope.orderBy == 2) {
                $scope.companylist.sort((a, b) => (a.facebookPageLikeNumber < b.facebookPageLikeNumber) ? 1 : -1)
            } else if ($scope.orderBy == 3) {
                $scope.companylist.sort((a, b) => (a.createdTimestamp < b.createdTimestamp) ? 1 : -1)
            }
        }

        $scope.companySearch = function (searchType) {
            if (document.getElementById('loadingOverlay')) {
                document.getElementById('loadingOverlay').style.display = "block";
                document.getElementById('innerPartnerPageContainer').style.overflow = "hidden";
            }
            let filters = {};
            if ($scope.fbURL.split($scope.facebookPrefix)[1]) {
                filters.facebookPageUserName = $scope.fbURL.split($scope.facebookPrefix)[1];
            }
            if ($scope.webURL.split($scope.webPrefix)[1]) {
                filters.webPageNakedDomain = $scope.webURL.split($scope.webPrefix)[1];
            }
            if ($scope.taxNumber) {
                filters.taxNum = $scope.taxNumber;
            }
            filters.compProfiles = [];
            filters.returnAllMatchingRecords = 0;
            filters.searchType = searchType;
            $scope.sendButtonDisabled = true;
            return $http.post(API_PREFIX.url + '/yaltyadmin/salesPotentialCompanySearch', filters).then(function (result) {
                if (document.getElementById('loadingOverlay')) {
                    document.getElementById('loadingOverlay').style.display = "none";
                    document.getElementById('innerPartnerPageContainer').style.overflow = "unset";
                }
                $scope.sendButtonDisabled = false;
                if (result.data.errorCode == 0) {
                    setCompanyStatuses(false)
                    /*GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/partneri_módosítás_történet/lista_lekérdezés_sikeres",
                        "HU Partner [PRIVÁT] - Partneri módosítás történet oldal - Sikeres lista lekérdezés")*/
                    if (result.data.potentialCompanySearchResult.length == 0) {
                        if (searchType == 1) {
                            $scope.isFirstAddCompanyWindow = false;
                            $scope.isAddCompanyWindow = true;
                        } else if (searchType == 2) {
                            $scope.usageType = 1;
                            $scope.mainId = null;
                            $scope.companyProfiles = null;
                            $scope.selectedCompanyProfile = null;
                            $scope.isAddCompanyWindow2 = true;
                            setListsWithValues()
                            $scope.companyWindowTitle = "ÚJ CÉG ADATLAPJA";
                            $scope.isAddCompanyWindow = false;
                            $scope.showCompanyList = false;
                            $scope.showInfo = false;
                            $scope.showFirstCall = false;
                            $scope.showSendLink = false;
                            $scope.showSendLink2 = false;
                            $scope.showTodos = false;
                            $scope.compName = null;
                            $scope.fbPageName = null;
                            $scope.fbLikes = null;
                            $scope.storeCount = null;
                            $scope.storesCount1 = 1;
                            $scope.storesCount2 = 1;
                            $scope.companyStatus = 1;
                            $scope.isEnabledModComp = false;
                            $scope.isSearchResults = false;
                            $scope.compStoreAddrCity3 = $scope.compStoreAddrCity;
                            $scope.isFBFieldsRequired = false
                            $scope.fbPriceLevelDisabled = $scope.fbURL.split($scope.facebookPrefix)[1] == ''
                            $scope.fbPageMinLength = 0;
                            $scope.fbPriceLevel = null
                            $scope.googlePriceLevel = null
                            $scope.compSize = null
                            $scope.haveLoyaltyProgram = null
                            $scope.payAtTheCounter = null
                            $scope.isThereLunchMenu = null
                            $scope.isThereTakeaway = null
                            $scope.storeDesign = null
                            $scope.webPageDesign = 0
                            $scope.compClass = null
                            setTimeout(function () { angular.element(document.getElementById('scrollContainer-3'))[0].scrollTop = 0; }, 0);
                            setTimeout(function () {
                                document.getElementById("compNameInput").focus();
                            }, 100);
                        }
                    } else {
                        $scope.companylist = result.data.potentialCompanySearchResult;
                        $scope.showCompanyList = true;
                        $scope.hideCalledComps = null;
                        $scope.showInfo = false;
                        $scope.showFirstCall = false;
                        $scope.showSendLink = false;
                        $scope.showSendLink2 = false;
                        $scope.showTodos = false;
                        $scope.isAddCompanyWindow = false;
                        $scope.isFirstAddCompanyWindow = false;
                        $scope.isEnabledModComp = false;
                        $scope.isSearchResults = false;
                        document.getElementById("cimke").innerHTML = "<b>Keresési találatok: " + result.data.potentialCompanySearchResult.length + "</b> cég <b>" + result.data.potentialCompanySearchResult.length + "</b> rekordja";
                        document.getElementById("cimke2").innerHTML = "<b>Keresési találatok: " + result.data.potentialCompanySearchResult.length + "</b> cég <b>" + result.data.potentialCompanySearchResult.length + "</b> rekordja";
                        //$scope.cimke = "Keresési találatok: " + result.data.potentialCompanySearchResult.length + " cég " + result.data.potentialCompanySearchResult.length + " rekordja";
                        setListHeight();
                    }
                } else {
                    /*GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/partneri_módosítás_történet/lista_lekérdezés_sikertelen",
                        "HU Partner [PRIVÁT] - Partneri módosítás történet oldal - Sikertelen lista lekérdezés")*/
                    commonService.showDialogWindowWithTitleOnly('Szerver hiba. Próbáld meg egy kicsit később újra.', 'Rendben')
                }
            });
        }

        $scope.copyToClipboard = function () {
            var copyText = document.getElementById("fbPageCopiable");

            /* Select the text field */
            copyText.select();

            /* Copy the text inside the text field */
            document.execCommand("copy");

            $scope.successCopyText = `A Facebook címet kimásoltuk a vágólapra.`;

            if (document.getElementById('okMessageAfterCopy')) {
                document.getElementById('okMessageAfterCopy').style.display = "block";
                $('#okMessageAfterCopy').delay(2000).hide(0)
            }
        }

        $scope.copyMainAndSubId = (mainId, subId) => {
            var dummy = document.createElement("textarea");
            document.body.appendChild(dummy);
            dummy.value = `mainId=${mainId}_subId=${subId}_`;
            dummy.select();
            document.execCommand("copy");
            document.body.removeChild(dummy);

            $scope.successCopyText = `A “${dummy.value}” fájlnév előtag szöveget a vágólapra másoltuk.`
            
            if (document.getElementById('okMessageAfterCopy')) {
                document.getElementById('okMessageAfterCopy').style.display = "block";
                $('#okMessageAfterCopy').delay(2000).hide(0)
            }
            
            
            /*let copyToast = $mdToast.simple()
                .parent(document.getElementById('potentialCompaniesPage'))
                .textContent(`A “${dummy.value}” fájlnév előtag szöveget a vágólapra másoltuk.`)
                .position('bottom center')
                .hideDelay(2000)
            $mdToast.show(copyToast).then(function () { }).catch(function () { });*/
        }

        $scope.openCompanyNewTab = function (mainId) {
            $cookies.put('mainId', mainId);
            var newTab = $window.open("", "_blank");
            newTab.location = "/yaltyadmin738/adminPotentialCompanies";
        }

        var hidePrioCompanyList = function () {
            var x = document.getElementById("prioCompanyList");
            x.style.display = "none";
            setTimeout(function () { x.style.display = "block"; }, 100);
        }

        $scope.openCompany = function (mainId) {
            if (document.getElementById('loadingOverlay')) {
                document.getElementById('loadingOverlay').style.display = "block";
                document.getElementById('innerPartnerPageContainer').style.overflow = "hidden";
            }
            let filters = {};
            filters.mainId = mainId;
            filters.queryType = 1;
            $scope.mainId = mainId;
            return $http.post(API_PREFIX.url + '/yaltyadmin/getSalesPotentialCompanyRecords', filters).then(function (result) {
                if (document.getElementById('loadingOverlay')) {
                    document.getElementById('loadingOverlay').style.display = "none";
                    document.getElementById('innerPartnerPageContainer').style.overflow = "unset";
                }
                if (result.data.errorCode == 0) {
                    $scope.checkDate = new Date();
                    $scope.checkToNow = new Date();
                    let numberOfDaysToAdd = -5;
                    $scope.checkDate.setDate($scope.checkDate.getDate() + numberOfDaysToAdd);
                    $scope.companylist = result.data.potentialCompanySearchResult;
                    $scope.companyPreRegistrationId = result.data.companyPreRegistrationId;
                    $scope.companyId = result.data.companyId
                    $scope.packageDisplayName = result.data.packageDisplayName;
                    $scope.compNamePromo = result.data.compNamePromo
                    $scope.compLogoImgURL = result.data.compLogoImgURL
                    $scope.showCompanyList = true;
                    $scope.hideCalledComps = null;
                    $scope.showInfo = false;
                    $scope.showFirstCall = false;
                    $scope.showSendLink = false;
                    $scope.showSendLink2 = false;
                    $scope.showTodos = false;
                    $scope.isAddCompanyWindow = false;
                    $scope.isFirstAddCompanyWindow = false;
                    $scope.companyPreRegistrationStatus = result.data.companyPreRegistrationStatus;
                    $scope.registrationLinkSentFirstTimeTimestamp = result.data.registrationLinkSentFirstTimeTimestamp;
                    $scope.freePackageSubscriptionExpLimited = result.data.freePackageSubscriptionExpLimited;
                    $scope.freePackageSubscriptionExpTimestamp = result.data.freePackageSubscriptionExpTimestamp;
                    $scope.status = result.data.status;
                    $scope.numberOfDaysSinceSendOutFirstOpen = result.data.numberOfDaysSinceSendOutFirstOpen;
                    $scope.numberOfDaysSinceSendOutLastOpen = result.data.numberOfDaysSinceSendOutLastOpen;
                    $scope.numberOfDifferentDaysOpenedSinceSendOut = result.data.numberOfDifferentDaysOpenedSinceSendOut;
                    $scope.contentCoverageScore = result.data.contentCoverageScore;
                    $scope.sumTimeSpentOnPages = result.data.sumTimeSpentOnPages;
                    $scope.activationStringExpTimestamp = result.data.activationStringExpTimestamp;
                    $scope.firstEmailViewTimestamp = result.data.firstEmailViewTimestamp;
                    $scope.numberOfEmailViews = result.data.numberOfEmailViews;
                    $scope.firstLCYActivatedTimestamp = result.data.firstLCYActivatedTimestamp;
                    $scope.firstLCYCreatedTimestamp = result.data.firstLCYCreatedTimestamp;
                    $scope.subscriptionPackageFinalisedTimestampAtRegistration = result.data.subscriptionPackageFinalisedTimestampAtRegistration;
                    $scope.subscriptionPackagesIdAtRegistration = result.data.subscriptionPackagesIdAtRegistration;
                    $scope.subscriptionPackageDisplayNameAtRegistration = result.data.subscriptionPackageDisplayNameAtRegistration;
                    $scope.score = result.data.score;
                    $scope.isYaltyStartFreePackageEnabled = result.data.potentialCompanySearchResult[0].isYaltyStartFreePackageEnabled;
                    if($scope.freePackageSubscriptionExpTimestamp != null && new Date("2000-01-01") > new Date($scope.freePackageSubscriptionExpTimestamp)){
                        $scope.freePackageSubscriptionNotEnabled = true;
                    } else {
                        $scope.freePackageSubscriptionNotEnabled = false;
                    }
                    if ($scope.firstLCYActivatedTimestamp != null) {
                        $scope.saleStatus = '6. Aktiválta a kártyáját'
                    } else if ($scope.firstLCYCreatedTimestamp != null) {
                        $scope.saleStatus = '5. Létrehozta a kártyáját'
                    } else if ($scope.companyPreRegistrationStatus == 10) {
                        $scope.saleStatus = '4. Beregisztrált'
                    } else if ($scope.registrationLinkSentFirstTimeTimestamp != null && $scope.checkDate > new Date($scope.registrationLinkSentFirstTimeTimestamp)) {
                        $scope.saleStatus = '3. Emailt követő egyeztetés'
                    } else if ($scope.registrationLinkSentFirstTimeTimestamp != null && $scope.checkDate < new Date($scope.registrationLinkSentFirstTimeTimestamp)) {
                        $scope.saleStatus = '2. Email kiküldve 5 napon belül'
                    } else if (result.data.potentialCompanySearchResult[0].lastOutgoingCallTimestamp != null) {
                        $scope.saleStatus = '1. Első megkeresés folyamatban'
                    } else {
                        $scope.saleStatus = '0. Még nem kerestük'
                    }
                    if (result.data.potentialCompanySearchResult[0].firstAdministrationTimestamp != null || $scope.registrationLinkSentFirstTimeTimestamp != null) {
                        let startTimestamp;
                        if(result.data.potentialCompanySearchResult[0].firstAdministrationTimestamp != null && $scope.registrationLinkSentFirstTimeTimestamp == null) {
                            startTimestamp = result.data.potentialCompanySearchResult[0].firstAdministrationTimestamp;
                        } else if(result.data.potentialCompanySearchResult[0].firstAdministrationTimestamp == null && $scope.registrationLinkSentFirstTimeTimestamp != null) {
                            startTimestamp = $scope.registrationLinkSentFirstTimeTimestamp;
                        } else if(new Date(result.data.potentialCompanySearchResult[0].firstAdministrationTimestamp) < new Date($scope.registrationLinkSentFirstTimeTimestamp)) {
                            startTimestamp = result.data.potentialCompanySearchResult[0].firstAdministrationTimestamp;
                        } else {
                            startTimestamp = $scope.registrationLinkSentFirstTimeTimestamp;
                        }
                        if ($scope.subscriptionPackageFinalisedTimestampAtRegistration == null || ($scope.subscriptionPackagesIdAtRegistration == 1 && $scope.firstLCYActivatedTimestamp == null)) {
                            let date1 = new Date(startTimestamp);
                            let date2 = new Date();
                            let diffTime = Math.abs(date2 - date1);
                            let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                            $scope.saleTime = diffDays;
                        } else if ($scope.subscriptionPackagesIdAtRegistration == 1) {
                            let date1 = new Date(startTimestamp);
                            let date2 = new Date($scope.firstLCYActivatedTimestamp);
                            let diffTime = Math.abs(date2 - date1);
                            let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                            $scope.saleTime = diffDays;
                        } else {
                            let date1 = new Date(startTimestamp);
                            let date2 = new Date($scope.subscriptionPackageFinalisedTimestampAtRegistration);
                            let diffTime = Math.abs(date2 - date1);
                            let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                            $scope.saleTime = diffDays;
                        }
                    } else {
                        $scope.saleTime = null;
                    }
                    if ($scope.status == 10) {
                        $scope.activationStringExpTimestamp = null;
                    }
                    if ($scope.companyPreRegistrationStatus == 0 || $scope.companyPreRegistrationStatus == 1) {
                        $scope.regLinkSendedText = null;
                    } else if ($scope.companyPreRegistrationStatus >= 2) {
                        $scope.regLinkSendedText = "Email: ";
                        if (result.data.registrationLinkSentByEmailTimestamp == null) {
                            $scope.regLinkSendedText += "- <br><br>";
                        } else {
                            let res = result.data.registrationLinkSentByEmailTimestamp.split("#");
                            if (res.length == 1) {
                                $scope.regLinkSendedText += result.data.registrationLinkSentByEmailTimestamp;
                                $scope.regLinkSendedText += "<br><br>"
                            } else {
                                $scope.regLinkSendedText += "<br>"
                                for (let i = 0; i < res.length; i++) {
                                    $scope.regLinkSendedText += "&#8226 ";
                                    $scope.regLinkSendedText += res[i];
                                    $scope.regLinkSendedText += "<br>";
                                }
                                $scope.regLinkSendedText += "<br>";
                            }
                        }
                        $scope.regLinkSendedText += "FB Messenger: "
                        if (result.data.registrationLinkSentByFBMSGRTimestamp == null) {
                            $scope.regLinkSendedText += "-";
                        } else {
                            $scope.regLinkSendedText += result.data.registrationLinkSentByFBMSGRTimestamp;
                        }
                    }
                    if ($scope.companylist[0].compName != null) {
                        let html = "<b onclick='navigator.clipboard.writeText(\"" + $scope.companylist[0].compName + "\")' style='cursor: pointer' title='Vágólapra másolás: “"+ $scope.companylist[0].compName + "”'>" + $scope.companylist[0].compName + "</b> <span onclick='navigator.clipboard.writeText(\"mainId=" + $scope.companylist[0].mainId + "\")' style='cursor: pointer' title='Vágólapra másolás: “mainId="+ $scope.companylist[0].mainId + "”'>(mId:</span> <span onclick='navigator.clipboard.writeText(\"" + $scope.companylist[0].mainId + "\")' style='cursor: pointer' title='Vágólapra másolás: “"+ $scope.companylist[0].mainId + "”'>" + $scope.companylist[0].mainId + "</span>";
                        if($scope.companyId != null) {
                            html += " | <span onclick='navigator.clipboard.writeText(\"companyId=" + $scope.companyId + "\")' style='cursor: pointer' title='Vágólapra másolás: “companyId="+ $scope.companyId + "”'>cId:</span> <span onclick='navigator.clipboard.writeText(\"" + $scope.companyId + "\")' style='cursor: pointer' title='Vágólapra másolás: “"+ $scope.companyId + "”'>" + $scope.companyId + "</span>"
                        }
                        html += ") cég <b>" + $scope.companylist.length + "</b> rekordja";
                        document.getElementById("cimke").innerHTML = html;
                        document.getElementById("cimke2").innerHTML = html;
                        $scope.compName = $scope.companylist[0].compName;
                    } else {
                        let html = "<b onclick='navigator.clipboard.writeText(\"" + $scope.companylist[0].facebookPageName + "\")' style='cursor: pointer' title='Vágólapra másolás: “"+ $scope.companylist[0].facebookPageName + "”'>" + $scope.companylist[0].facebookPageName + "</b> <span onclick='navigator.clipboard.writeText(\"mainId=" + $scope.companylist[0].mainId + "\")' style='cursor: pointer' title='Vágólapra másolás: “mainId="+ $scope.companylist[0].mainId + "”'>(mId:</span> <span onclick='navigator.clipboard.writeText(\"" + $scope.companylist[0].mainId + "\")' style='cursor: pointer' title='Vágólapra másolás: “"+ $scope.companylist[0].mainId + "”'>" + $scope.companylist[0].mainId + "</span>)"
                        if($scope.companyId != null) {
                            html += " | <span onclick='navigator.clipboard.writeText(\"companyId=" + $scope.companyId + "\")' style='cursor: pointer' title='Vágólapra másolás: “companyId="+ $scope.companyId + "”'>cId:</span> <span onclick='navigator.clipboard.writeText(\"" + $scope.companyId + "\")' style='cursor: pointer' title='Vágólapra másolás: “"+ $scope.companyId + "”'>" + $scope.companyId + "</span>"
                        }
                        html += ") cég <b>" + $scope.companylist.length + "</b> rekordja";
                        document.getElementById("cimke").innerHTML = html;
                        document.getElementById("cimke2").innerHTML = html;
                        $scope.fbPageName = $scope.companylist[0].facebookPageName;
                    }
                    if ($scope.companylist.length != 0) {
                        $scope.isEnabledModComp = true;
                        $scope.isSearchResults = false;
                    }
                    setListHeight();
                } else {
                    commonService.showDialogWindowWithTitleOnly('Szerver hiba. Próbáld meg egy kicsit később újra.', 'Rendben')
                }
            });
        }

        if (companyMainId != null) {
            $cookies.remove('mainId');
            $scope.openCompany(companyMainId);
        }

        $scope.showListToSendRegLink = function () {
            hidePrioCompanyList();
            if (document.getElementById('loadingOverlay')) {
                document.getElementById('loadingOverlay').style.display = "block";
                document.getElementById('innerPartnerPageContainer').style.overflow = "hidden";
            }
            let filters = {};
            filters.yaltyAdminUserId = adminUserId;
            return $http.post(API_PREFIX.url + '/yaltyadmin/salesPotentialCompanyListToSendRegLinkTo', filters).then(function (result) {
                if (document.getElementById('loadingOverlay')) {
                    document.getElementById('loadingOverlay').style.display = "none";
                    document.getElementById('innerPartnerPageContainer').style.overflow = "unset";
                }
                if (result.data.errorCode == 0) {
                    if (result.data.potentialCompanySearchResult.length == 0) {
                        $scope.companylist = result.data.potentialCompanySearchResult;
                        $scope.showCompanyList = true;
                        $scope.hideCalledComps = null;
                        $scope.showInfo = true;
                        $scope.showFirstCall = false;
                        $scope.showSendLink = false;
                        $scope.showSendLink2 = true;
                        $scope.showTodos = false;
                        $scope.isSearchWindow = false;
                        $scope.isEnabledModComp = false;
                        $scope.isSearchResults = false;
                        document.getElementById("cimke").innerHTML = "<b>Akiknek még nem küldtem regisztrációs linket lista: " + 0 + "</b> cég <b>" + 0 + "</b> rekordja";
                        document.getElementById("cimke2").innerHTML = "<b>Akiknek még nem küldtem regisztrációs linket lista: " + 0 + "</b> cég <b>" + 0 + "</b> rekordja";
                        setListHeight();
                    } else {
                        $scope.companylist = result.data.potentialCompanySearchResult;
                        $scope.showCompanyList = true;
                        $scope.hideCalledComps = null;
                        $scope.showFirstCall = false;
                        $scope.showSendLink = false;
                        $scope.showSendLink2 = true;
                        $scope.showTodos = false;
                        $scope.isSearchWindow = false;
                        $scope.isEnabledModComp = false;
                        $scope.isSearchResults = true;
                        $scope.orderBy = 1;
                        $scope.showInfo = false;
                        let array = [];
                        for (let i = 0; i < result.data.potentialCompanySearchResult.length; i++) {
                            if (array.indexOf(result.data.potentialCompanySearchResult[i].mainId) === -1) {
                                array.push(result.data.potentialCompanySearchResult[i].mainId);
                            }
                        }
                        document.getElementById("cimke").innerHTML = "<b>Akiknek még nem küldtem regisztrációs linket lista: " + array.length + "</b> cég <b>" + result.data.potentialCompanySearchResult.length + "</b> rekordja";
                        document.getElementById("cimke2").innerHTML = "<b>Akiknek még nem küldtem regisztrációs linket lista: " + array.length + "</b> cég <b>" + result.data.potentialCompanySearchResult.length + "</b> rekordja";
                        setListHeight();
                    }
                } else {
                    commonService.showDialogWindowWithTitleOnly('Szerver hiba. Próbáld meg egy kicsit később újra.', 'Rendben')
                }
            });
        }

        $scope.showListNotOpenedRegLink = function () {
            hidePrioCompanyList();
            if (document.getElementById('loadingOverlay')) {
                document.getElementById('loadingOverlay').style.display = "block";
                document.getElementById('innerPartnerPageContainer').style.overflow = "hidden";
            }
            let filters = {};
            filters.yaltyAdminUserId = adminUserId;
            return $http.post(API_PREFIX.url + '/yaltyadmin/salesPotentialCompanyListThatHaveNotYetOpenedEmailRegLink', filters).then(function (result) {
                if (document.getElementById('loadingOverlay')) {
                    document.getElementById('loadingOverlay').style.display = "none";
                    document.getElementById('innerPartnerPageContainer').style.overflow = "unset";
                }
                if (result.data.errorCode == 0) {
                    if (result.data.potentialCompanySearchResult.length == 0) {
                        $scope.companylist = result.data.potentialCompanySearchResult;
                        $scope.showCompanyList = true;
                        $scope.showInfo = true;
                        $scope.showFirstCall = false;
                        $scope.showSendLink = true;
                        $scope.hideCalledComps = true;
                        $scope.showSendLink2 = false;
                        $scope.showTodos = false;
                        $scope.isSearchWindow = false;
                        $scope.isEnabledModComp = false;
                        $scope.isSearchResults = false;
                        document.getElementById("cimke").innerHTML = "<b>Akiknek már küldtem regisztrációs linket, de még nem nyitották meg lista: " + 0 + "</b> cég <b>" + 0 + "</b> rekordja";
                        document.getElementById("cimke2").innerHTML = "<b>Akiknek már küldtem regisztrációs linket, de még nem nyitották meg lista: " + 0 + "</b> cég <b>" + 0 + "</b> rekordja";
                        setListHeight();
                    } else {
                        $scope.companylist = result.data.potentialCompanySearchResult;
                        $scope.showCompanyList = true;
                        $scope.showFirstCall = false;
                        $scope.showSendLink = true;
                        $scope.hideCalledComps = true;
                        $scope.showSendLink2 = false;
                        $scope.showTodos = false;
                        $scope.isSearchWindow = false;
                        $scope.isEnabledModComp = false;
                        $scope.isSearchResults = true;
                        $scope.orderBy = 1;
                        $scope.showInfo = false;
                        let array = [];
                        for (let i = 0; i < result.data.potentialCompanySearchResult.length; i++) {
                            if (array.indexOf(result.data.potentialCompanySearchResult[i].mainId) === -1) {
                                array.push(result.data.potentialCompanySearchResult[i].mainId);
                            }
                        }
                        document.getElementById("cimke").innerHTML = "<b>Akiknek már küldtem regisztrációs linket, de még nem nyitották meg lista: " + array.length + "</b> cég <b>" + result.data.potentialCompanySearchResult.length + "</b> rekordja";
                        document.getElementById("cimke2").innerHTML = "<b>Akiknek már küldtem regisztrációs linket, de még nem nyitották meg lista: " + array.length + "</b> cég <b>" + result.data.potentialCompanySearchResult.length + "</b> rekordja";
                        setListHeight();
                    }
                } else {
                    commonService.showDialogWindowWithTitleOnly('Szerver hiba. Próbáld meg egy kicsit később újra.', 'Rendben')
                }
            });
        }

        $scope.showTodoList = function () {
            if (document.getElementById('loadingOverlay')) {
                document.getElementById('loadingOverlay').style.display = "block";
                document.getElementById('innerPartnerPageContainer').style.overflow = "hidden";
            }
            let filters = {};
            filters.contactOwnerYaltyAdminUserId = adminUserId;
            $scope.sendButtonDisabled = true;
            return $http.post(API_PREFIX.url + '/yaltyadmin/salesPotentialCompanyYAdminTodoList', filters).then(function (result) {
                if (document.getElementById('loadingOverlay')) {
                    document.getElementById('loadingOverlay').style.display = "none";
                    document.getElementById('innerPartnerPageContainer').style.overflow = "unset";
                }
                $scope.sendButtonDisabled = false;
                if (result.data.errorCode == 0) {
                    $scope.checkToNow = new Date();
                    $scope.checkToToday = new Date();
                    $scope.checkToToday.setHours(23, 59, 59, 999);
                    if (result.data.potentialCompanySearchResult.length == 0) {
                        $scope.companylist = result.data.potentialCompanySearchResult;
                        $scope.showCompanyList = true;
                        $scope.hideCalledComps = null;
                        $scope.showInfo = true;
                        $scope.showFirstCall = false;
                        $scope.showSendLink = false;
                        $scope.showSendLink2 = false;
                        $scope.isSearchWindow = false;
                        $scope.isEnabledModComp = false;
                        $scope.isSearchResults = false;
                        $scope.showTodos = true;
                        document.getElementById("cimke").innerHTML = "Mai teendőim száma: <b>" + 0 + "</b> | Elmaradt teendőim száma: <b>" + 0 + "</b> :) | Összes teendők száma: <b>" + 0 + "</b>";
                        document.getElementById("cimke2").innerHTML = "Mai teendőim száma: <b>" + 0 + "</b> | Elmaradt teendőim száma: <b>" + 0 + "</b> :) | Összes teendők száma: <b>" + 0 + "</b>";
                        setListHeight();
                    } else {
                        $scope.companylist = result.data.potentialCompanySearchResult;
                        $scope.showCompanyList = true;
                        $scope.hideCalledComps = null;
                        $scope.showFirstCall = false;
                        $scope.showSendLink = false;
                        $scope.showSendLink2 = false;
                        $scope.isSearchWindow = false;
                        $scope.isEnabledModComp = false;
                        $scope.isSearchResults = false;
                        $scope.showTodos = true;
                        $scope.showInfo = false;
                        let array = [];
                        let array2 = [];
                        for (let i = 0; i < result.data.potentialCompanySearchResult.length; i++) {
                            if ($scope.checkToNow > new Date(result.data.potentialCompanySearchResult[i].nextTODODeadline)) {
                                array.push(result.data.potentialCompanySearchResult[i].mainId);
                            } else if (($scope.checkToNow <= new Date(result.data.potentialCompanySearchResult[i].nextTODODeadline)) && ($scope.checkToToday >= new Date(result.data.potentialCompanySearchResult[i].nextTODODeadline))) {
                                array2.push(result.data.potentialCompanySearchResult[i].mainId);
                            }
                        }
                        if (array.length != 0) {
                            document.getElementById("cimke").innerHTML = "Mai teendőim száma: <b>" + array2.length + "</b> | <span style=\"color: #E85858\">Elmaradt teendőim száma: <b>" + array.length + "</b> :(</span> | Összes teendők száma: <b>" + result.data.potentialCompanySearchResult.length + "</b>";
                            document.getElementById("cimke2").innerHTML = "Mai teendőim száma: <b>" + array2.length + "</b> | <span style=\"color: #E85858\">Elmaradt teendőim száma: <b>" + array.length + "</b> :(</span> | Összes teendők száma: <b>" + result.data.potentialCompanySearchResult.length + "</b>";
                        } else {
                            document.getElementById("cimke").innerHTML = "Mai teendőim száma: <b>" + array2.length + "</b> | Elmaradt teendőim száma: <b>" + 0 + "</b> :) | Összes teendők száma: <b>" + result.data.potentialCompanySearchResult.length + "</b>";
                            document.getElementById("cimke2").innerHTML = "Mai teendőim száma: <b>" + array2.length + "</b> | Elmaradt teendőim száma: <b>" + 0 + "</b> :) | Összes teendők száma: <b>" + result.data.potentialCompanySearchResult.length + "</b>";
                        }
                        setListHeight();
                    }
                } else {
                    commonService.showDialogWindowWithTitleOnly('Szerver hiba. Próbáld meg egy kicsit később újra.', 'Rendben')
                }
            });
        }


        $scope.showFirstCallList = function () {
            hidePrioCompanyList();
            if (document.getElementById('loadingOverlay')) {
                document.getElementById('loadingOverlay').style.display = "block";
                document.getElementById('innerPartnerPageContainer').style.overflow = "hidden";
            }
            let filters = {};
            filters.yaltyAdminUserId = adminUserId;
            return $http.post(API_PREFIX.url + '/yaltyadmin/salesPotentialPrioritisedCompanyListForFirstCall', filters).then(function (result) {
                if (document.getElementById('loadingOverlay')) {
                    document.getElementById('loadingOverlay').style.display = "none";
                    document.getElementById('innerPartnerPageContainer').style.overflow = "unset";
                }
                if (result.data.errorCode == 0) {
                    $scope.checkToNow = new Date();
                    if (result.data.salesPotentialPrioritisedCompanyList.length == 0) {
                        $scope.companylist = result.data.salesPotentialPrioritisedCompanyList;
                        $scope.showCompanyList = true;
                        $scope.showInfo = true;
                        $scope.showFirstCall = true;
                        $scope.hideCalledComps = true;
                        $scope.showSendLink = false;
                        $scope.showSendLink2 = false;
                        $scope.showTodos = false;
                        $scope.isSearchWindow = false;
                        $scope.isEnabledModComp = false;
                        $scope.isSearchResults = false;
                        document.getElementById("cimke").innerHTML = "<b>Infó emailt követően felhívandók priorizált listája: " + 0 + "</b> cég <b>" + 0 + "</b> rekordja";
                        document.getElementById("cimke2").innerHTML = "<b>Infó emailt követően felhívandók priorizált listája: " + 0 + "</b> cég <b>" + 0 + "</b> rekordja";
                        setListHeight();
                    } else {
                        $scope.companylist = result.data.salesPotentialPrioritisedCompanyList;
                        $scope.showCompanyList = true;
                        $scope.showFirstCall = true;
                        $scope.hideCalledComps = true;
                        $scope.showSendLink = false;
                        $scope.showSendLink2 = false;
                        $scope.showTodos = false;
                        $scope.showInfo = false;
                        $scope.isSearchWindow = false;
                        $scope.isEnabledModComp = false;
                        $scope.isSearchResults = false;
                        let array = [];
                        for (let i = 0; i < result.data.salesPotentialPrioritisedCompanyList.length; i++) {
                            if (array.indexOf(result.data.salesPotentialPrioritisedCompanyList[i].mainId) === -1) {
                                array.push(result.data.salesPotentialPrioritisedCompanyList[i].mainId);
                            }
                        }
                        document.getElementById("cimke").innerHTML = "<b>Infó emailt követően felhívandók priorizált listája: " + array.length + "</b> cég <b>" + result.data.salesPotentialPrioritisedCompanyList.length + "</b> rekordja";
                        document.getElementById("cimke2").innerHTML = "<b>Infó emailt követően felhívandók priorizált listája: " + array.length + "</b> cég <b>" + result.data.salesPotentialPrioritisedCompanyList.length + "</b> rekordja";
                        setListHeight();
                    }
                } else {
                    commonService.showDialogWindowWithTitleOnly('Szerver hiba. Próbáld meg egy kicsit később újra.', 'Rendben')
                }
            });
        }

        $scope.checkNextTodo = function () {
            if ($scope.ctrl.searchTextNextTodo != null && $scope.ctrl.searchTextNextTodo.length > 0) {
                return false;
            } else {
                return true;
            }
        }

        $scope.checkNextTodo2 = function () {
            if ($scope.ctrl.searchTextNextTodo != null && $scope.ctrl.searchTextNextTodo.length > 0 && $scope.deadlineDate != null) {
                return false;
            } else {
                return true;
            }
        }

        $scope.addTime = function (difference) {
            if ($scope.ctrl.searchTextNextTodo != null && $scope.ctrl.searchTextNextTodo.length > 0) {
                let now;
                if ($scope.deadlineDate != null && difference != 'now+5min') {
                    now = $scope.deadlineDate;
                } else {
                    now = new Date();
                }
                var date = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes());
                if (difference == '1 hour') {
                    date.setHours(date.getHours() + 1);
                } else if (difference == '1 day') {
                    date.setDate(date.getDate() + 1);
                } else if (difference == '1 week') {
                    date.setDate(date.getDate() + 7);
                } else if (difference == '1 month') {
                    date.setMonth(date.getMonth() + 1);
                } else if (difference == '6 months') {
                    date.setMonth(date.getMonth() + 6);
                } else if (difference == '1 year') {
                    date.setFullYear(date.getFullYear() + 1);
                } else if (difference == 'now+5min') {
                    date.setMinutes(date.getMinutes() + 5);
                }
                $scope.deadlineDate = date;
                $scope.deadlineTime = date
                $scope.isDeadLineDateLabelToShow = false
                $scope.isDeadLineTimeLabelToShow = false
            }
        }

        $scope.saveAdministration = function () {
            $scope.editEndClientSideTimestamp = new Date();
            $scope.editElapsedTime = $scope.editEndClientSideTimestamp - $scope.editStartClientSideTimestamp;
            var error = 0;
            if ($scope.administrationCheckbox) {
                if ($scope.administrationForm.timeId.$invalid) {
                    $scope.administrationForm.timeId.$touched = true
                    error++;
                }
                if ($scope.administrationForm.partnerContactTime.$invalid) {
                    $scope.administrationForm.partnerContactTime.$touched = true
                    error++;
                }
                if ($scope.administrationForm.partnerContactDate.$invalid) {
                    $scope.administrationForm.partnerContactDate.$touched = true
                    error++;
                }
                if ($scope.administrationForm.directionId.$invalid) {
                    $scope.administrationForm.directionId.$touched = true
                    error++;
                }
                if ($scope.administrationForm.directionMode.$invalid) {
                    $scope.administrationForm.directionMode.$touched = true
                    error++;
                }
                if ($scope.administrationForm.timeInMin.$invalid) {
                    $scope.administrationForm.timeInMin.$touched = true
                    error++;
                }
                if ($scope.administrationForm.goal.$invalid) {
                    $scope.administrationForm.goal.$touched = true
                    error++;
                }
                if ($scope.administrationForm.result.$invalid) {
                    $scope.administrationForm.result.$touched = true
                    error++;
                }
                if ($scope.administrationForm.reason && $scope.administrationForm.reason.$invalid) {
                    $scope.administrationForm.reason.$touched = true
                    error++;
                }
                if ($scope.administrationForm.decisionMaker.$invalid) {
                    $scope.administrationForm.decisionMaker.$touched = true
                    error++
                }
                if ($scope.administrationForm.enquiry.$invalid) {
                    $scope.administrationForm.enquiry.$touched = true
                    error++
                }
            }
            if ($scope.administrationForm.deadlineDate.$invalid) {
                $scope.administrationForm.deadlineDate.$touched = true
                error++;
            }
            if ($scope.administrationForm.deadlineTime.$invalid) {
                $scope.administrationForm.deadlineTime.$touched = true
                error++;
            }
            if ($scope.administrationForm.nextTodo.$invalid) {
                $scope.administrationForm.nextTodo.$touched = true
                error++;
            }
            if (error != 0) {
                commonService.showDialogWindowWithTitleOnly('Tölts ki minden kötelező mezőt!', 'OK', () => {
                })
            } else {
                $scope.sendButtonDisabled = true;
                let filters = {};
                filters.usageType = 3;
                if ($scope.mainId) {
                    filters.mainId = $scope.mainId;
                }
                if ($scope.ctrl.searchTextNextTodo) {
                    filters.nextTODO = $scope.ctrl.searchTextNextTodo;
                    if ($scope.deadlineDate) {
                        let deadlineDate = new Date($scope.deadlineDate)
                        let deadlineTime = new Date($scope.deadlineTime)
                        deadlineDate.setHours(deadlineTime.getHours(), deadlineTime.getMinutes())
                        filters.nextTODODeadline = deadlineDate;
                    }
                }
                if ($scope.textArea2) {
                    filters.notes = $scope.textArea2;
                }
                filters.wasPartnerContact = $scope.administrationCheckbox;
                if ($scope.directionId && $scope.administrationCheckbox) {
                    filters.partnerContactDirection = $scope.directionId;
                }
                if ($scope.directionMode && $scope.administrationCheckbox) {
                    filters.partnerContactChannel = $scope.directionMode;
                    if ($scope.directionMode == 'Phone call' || $scope.directionMode == 'Personal') {
                        if ($scope.timeInMin) {
                            filters.phoneCallDuration = $scope.timeInMin;
                        }
                    }
                }
                if ($scope.ctrl.searchTextGoal && $scope.administrationCheckbox) {
                    filters.partnerContactPurpose = $scope.ctrl.searchTextGoal;
                }
                if ($scope.ctrl.searchTextResult && $scope.administrationCheckbox) {
                    filters.partnerContactOutcome = $scope.ctrl.searchTextResult;
                    if ($scope.ctrl.searchTextResult == 'Sikertelen értékesítés') {
                        if ($scope.ctrl.searchTextReason) {
                            filters.partnerContactRejectionCause = $scope.ctrl.searchTextReason;
                        }
                    }
                }
                if ($scope.timeId && $scope.administrationCheckbox) {
                    if ($scope.timeId == 1) {
                        filters.partnerContactTimestamp = $scope.editStartClientSideTimestamp;
                    } else if ($scope.timeId == 2) {
                        let partnerContactDate = new Date($scope.partnerContactDate)
                        let partnerContactTime = new Date($scope.partnerContactTime)
                        partnerContactDate.setHours(partnerContactTime.getHours(), partnerContactTime.getMinutes())
                        filters.partnerContactTimestamp = partnerContactDate;
                    }
                }
                //20230921 partnerContactObservations mező kivezetése:
                /*if ($scope.textArea1 && $scope.administrationCheckbox) {
                    filters.partnerContactObservations = $scope.textArea1;
                }*/
                if ($scope.textArea1 && $scope.textArea1 != '') {
                    filters.customerMasterData = $scope.textArea1;
                }
                filters.talkedWithDecisionMaker = $scope.decisionMaker ? parseInt($scope.decisionMaker) : null
                filters.perceivedInterestLevel = $scope.enquiry ? parseInt($scope.enquiry) : null
                filters.editStartClientSideTimestamp = $scope.editStartClientSideTimestamp
                filters.editEndClientSideTimestamp = new Date()
                filters.editElapsedTime = filters.editEndClientSideTimestamp - new Date(filters.editStartClientSideTimestamp)
                return $http.post(API_PREFIX.url + '/yaltyadmin/addOrModifyPotentialCompany', filters).then(function (result) {
                    $scope.sendButtonDisabled = false;
                    if (result.data.errorCode == 0) {
                        $scope.isAddCompanyWindow2 = false;
                        $scope.isSearchWindow = false;
                        $scope.isAdministrationWindow = false;
                        let filters = {};
                        filters.mainId = result.data.mainId;
                        $scope.mainId = result.data.mainId;
                        filters.queryType = 1;
                        return $http.post(API_PREFIX.url + '/yaltyadmin/getSalesPotentialCompanyRecords', filters).then(function (result) {
                            if (document.getElementById('loadingOverlay')) {
                                document.getElementById('loadingOverlay').style.display = "none";
                                document.getElementById('innerPartnerPageContainer').style.overflow = "unset";
                            }
                            if (result.data.errorCode == 0) {
                                $scope.checkToNow = new Date();
                                $scope.companylist = result.data.potentialCompanySearchResult;
                                $scope.companyPreRegistrationId = result.data.companyPreRegistrationId;
                                $scope.showCompanyList = true;
                                $scope.hideCalledComps = null;
                                $scope.showInfo = false;
                                $scope.showFirstCall = false;
                                $scope.showSendLink = false;
                                $scope.showSendLink2 = false;
                                $scope.showTodos = false;
                                $scope.isAddCompanyWindow = false;
                                $scope.isFirstAddCompanyWindow = false;
                                $scope.addCompany2Form.$setUntouched()
                                $scope.companyPreRegistrationStatus = result.data.companyPreRegistrationStatus;
                                $scope.registrationLinkSentFirstTimeTimestamp = result.data.registrationLinkSentFirstTimeTimestamp;
                                $scope.freePackageSubscriptionExpLimited = result.data.freePackageSubscriptionExpLimited;
                                $scope.freePackageSubscriptionExpTimestamp = result.data.freePackageSubscriptionExpTimestamp;
                                $scope.status = result.data.status;
                                $scope.numberOfDaysSinceSendOutFirstOpen = result.data.numberOfDaysSinceSendOutFirstOpen;
                                $scope.numberOfDaysSinceSendOutLastOpen = result.data.numberOfDaysSinceSendOutLastOpen;
                                $scope.numberOfDifferentDaysOpenedSinceSendOut = result.data.numberOfDifferentDaysOpenedSinceSendOut;
                                $scope.contentCoverageScore = result.data.contentCoverageScore;
                                $scope.sumTimeSpentOnPages = result.data.sumTimeSpentOnPages;
                                $scope.activationStringExpTimestamp = result.data.activationStringExpTimestamp;
                                $scope.firstEmailViewTimestamp = result.data.firstEmailViewTimestamp;
                                $scope.numberOfEmailViews = result.data.numberOfEmailViews;
                                $scope.firstLCYActivatedTimestamp = result.data.firstLCYActivatedTimestamp;
                                $scope.firstLCYCreatedTimestamp = result.data.firstLCYCreatedTimestamp;
                                $scope.subscriptionPackageFinalisedTimestampAtRegistration = result.data.subscriptionPackageFinalisedTimestampAtRegistration;
                                $scope.subscriptionPackagesIdAtRegistration = result.data.subscriptionPackagesIdAtRegistration;
                                $scope.subscriptionPackageDisplayNameAtRegistration = result.data.subscriptionPackageDisplayNameAtRegistration;
                                $scope.score = result.data.score;
                                $scope.isYaltyStartFreePackageEnabled = result.data.potentialCompanySearchResult[0].isYaltyStartFreePackageEnabled;
                                if($scope.freePackageSubscriptionExpTimestamp != null && new Date("2000-01-01") > new Date($scope.freePackageSubscriptionExpTimestamp)){
                                    $scope.freePackageSubscriptionNotEnabled = true;
                                } else {
                                    $scope.freePackageSubscriptionNotEnabled = false;
                                }
                                if ($scope.firstLCYActivatedTimestamp != null) {
                                    $scope.saleStatus = '6. Aktiválta a kártyáját'
                                } else if ($scope.firstLCYCreatedTimestamp != null) {
                                    $scope.saleStatus = '5. Létrehozta a kártyáját'
                                } else if ($scope.companyPreRegistrationStatus == 10) {
                                    $scope.saleStatus = '4. Beregisztrált'
                                } else if ($scope.registrationLinkSentFirstTimeTimestamp != null && $scope.checkDate > new Date($scope.registrationLinkSentFirstTimeTimestamp)) {
                                    $scope.saleStatus = '3. Emailt követő egyeztetés'
                                } else if ($scope.registrationLinkSentFirstTimeTimestamp != null && $scope.checkDate < new Date($scope.registrationLinkSentFirstTimeTimestamp)) {
                                    $scope.saleStatus = '2. Email kiküldve 5 napon belül'
                                } else if (result.data.potentialCompanySearchResult[0].lastOutgoingCallTimestamp != null) {
                                    $scope.saleStatus = '1. Első megkeresés folyamatban'
                                } else {
                                    $scope.saleStatus = '0. Még nem kerestük'
                                }
                                if (result.data.potentialCompanySearchResult[0].firstAdministrationTimestamp != null || $scope.registrationLinkSentFirstTimeTimestamp != null) {
                                    let startTimestamp;
                                    if(result.data.potentialCompanySearchResult[0].firstAdministrationTimestamp != null && $scope.registrationLinkSentFirstTimeTimestamp == null) {
                                        startTimestamp = result.data.potentialCompanySearchResult[0].firstAdministrationTimestamp;
                                    } else if(result.data.potentialCompanySearchResult[0].firstAdministrationTimestamp == null && $scope.registrationLinkSentFirstTimeTimestamp != null) {
                                        startTimestamp = $scope.registrationLinkSentFirstTimeTimestamp;
                                    } else if(new Date(result.data.potentialCompanySearchResult[0].firstAdministrationTimestamp) < new Date($scope.registrationLinkSentFirstTimeTimestamp)) {
                                        startTimestamp = result.data.potentialCompanySearchResult[0].firstAdministrationTimestamp;
                                    } else {
                                        startTimestamp = $scope.registrationLinkSentFirstTimeTimestamp;
                                    }
                                    if ($scope.subscriptionPackageFinalisedTimestampAtRegistration == null || ($scope.subscriptionPackagesIdAtRegistration == 1 && $scope.firstLCYActivatedTimestamp == null)) {
                                        let date1 = new Date(startTimestamp);
                                        let date2 = new Date();
                                        let diffTime = Math.abs(date2 - date1);
                                        let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                                        $scope.saleTime = diffDays;
                                    } else if ($scope.subscriptionPackagesIdAtRegistration == 1) {
                                        let date1 = new Date(startTimestamp);
                                        let date2 = new Date($scope.firstLCYActivatedTimestamp);
                                        let diffTime = Math.abs(date2 - date1);
                                        let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                                        $scope.saleTime = diffDays;
                                    } else {
                                        let date1 = new Date(startTimestamp);
                                        let date2 = new Date($scope.subscriptionPackageFinalisedTimestampAtRegistration);
                                        let diffTime = Math.abs(date2 - date1);
                                        let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                                        $scope.saleTime = diffDays;
                                    }
                                } else {
                                    $scope.saleTime = null;
                                }
                                if ($scope.status == 10) {
                                    $scope.activationStringExpTimestamp = null;
                                }
                                if ($scope.companyPreRegistrationStatus == 0 || $scope.companyPreRegistrationStatus == 1) {
                                    $scope.regLinkSendedText = null;
                                } else if ($scope.companyPreRegistrationStatus >= 2) {
                                    $scope.regLinkSendedText = "Email: ";
                                    if (result.data.registrationLinkSentByEmailTimestamp == null) {
                                        $scope.regLinkSendedText += "- <br><br>";
                                    } else {
                                        let res = result.data.registrationLinkSentByEmailTimestamp.split("#");
                                        if (res.length == 1) {
                                            $scope.regLinkSendedText += result.data.registrationLinkSentByEmailTimestamp;
                                            $scope.regLinkSendedText += "<br><br>"
                                        } else {
                                            $scope.regLinkSendedText += "<br>"
                                            for (let i = 0; i < res.length; i++) {
                                                $scope.regLinkSendedText += "&#8226 ";
                                                $scope.regLinkSendedText += res[i];
                                                $scope.regLinkSendedText += "<br>";
                                            }
                                            $scope.regLinkSendedText += "<br>";
                                        }
                                    }
                                    $scope.regLinkSendedText += "FB Messenger: "
                                    if (result.data.registrationLinkSentByFBMSGRTimestamp == null) {
                                        $scope.regLinkSendedText += "-";
                                    } else {
                                        $scope.regLinkSendedText += result.data.registrationLinkSentByFBMSGRTimestamp;
                                    }
                                }
                                if ($scope.companylist[0].compName != null) {
                                    let html = "<b onclick='navigator.clipboard.writeText(\"" + $scope.companylist[0].compName + "\")' style='cursor: pointer' title='Vágólapra másolás: “"+ $scope.companylist[0].compName + "”'>" + $scope.companylist[0].compName + "</b> <span onclick='navigator.clipboard.writeText(\"mainId=" + $scope.companylist[0].mainId + "\")' style='cursor: pointer' title='Vágólapra másolás: “mainId="+ $scope.companylist[0].mainId + "”'>(mId:</span> <span onclick='navigator.clipboard.writeText(\"" + $scope.companylist[0].mainId + "\")' style='cursor: pointer' title='Vágólapra másolás: “"+ $scope.companylist[0].mainId + "”'>" + $scope.companylist[0].mainId + "</span>";
                                    if($scope.companyId != null) {
                                        html += " | <span onclick='navigator.clipboard.writeText(\"companyId=" + $scope.companyId + "\")' style='cursor: pointer' title='Vágólapra másolás: “companyId="+ $scope.companyId + "”'>cId:</span> <span onclick='navigator.clipboard.writeText(\"" + $scope.companyId + "\")' style='cursor: pointer' title='Vágólapra másolás: “"+ $scope.companyId + "”'>" + $scope.companyId + "</span>"
                                    }
                                    html += ") cég <b>" + $scope.companylist.length + "</b> rekordja";
                                    document.getElementById("cimke").innerHTML = html;
                                    document.getElementById("cimke2").innerHTML = html;
                                } else {
                                    let html = "<b onclick='navigator.clipboard.writeText(\"" + $scope.companylist[0].facebookPageName + "\")' style='cursor: pointer' title='Vágólapra másolás: “"+ $scope.companylist[0].facebookPageName + "”'>" + $scope.companylist[0].facebookPageName + "</b> <span onclick='navigator.clipboard.writeText(\"mainId=" + $scope.companylist[0].mainId + "\")' style='cursor: pointer' title='Vágólapra másolás: “mainId="+ $scope.companylist[0].mainId + "”'>(mId:</span> <span onclick='navigator.clipboard.writeText(\"" + $scope.companylist[0].mainId + "\")' style='cursor: pointer' title='Vágólapra másolás: “"+ $scope.companylist[0].mainId + "”'>" + $scope.companylist[0].mainId + "</span>)"
                                    if($scope.companyId != null) {
                                        html += " | <span onclick='navigator.clipboard.writeText(\"companyId=" + $scope.companyId + "\")' style='cursor: pointer' title='Vágólapra másolás: “companyId="+ $scope.companyId + "”'>cId:</span> <span onclick='navigator.clipboard.writeText(\"" + $scope.companyId + "\")' style='cursor: pointer' title='Vágólapra másolás: “"+ $scope.companyId + "”'>" + $scope.companyId + "</span>"
                                    }
                                    html += ") cég <b>" + $scope.companylist.length + "</b> rekordja";
                                    document.getElementById("cimke").innerHTML = html;
                                    document.getElementById("cimke2").innerHTML = html;
                                }
                                if ($scope.companylist.length != 0) {
                                    $scope.isEnabledModComp = true;
                                    $scope.isSearchResults = false;
                                }
                                setListHeight();
                            } else {
                                commonService.showDialogWindowWithTitleOnly('Szerver hiba. Próbáld meg egy kicsit később újra.', 'Rendben')
                                /*GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/partneri_módosítás_történet/lista_lekérdezés_sikertelen",
                                    "HU Partner [PRIVÁT] - Partneri módosítás történet oldal - Sikertelen lista lekérdezés")*/
                            }
                        });
                    } else {
                        /*GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/partneri_módosítás_történet/lista_lekérdezés_sikertelen",
                            "HU Partner [PRIVÁT] - Partneri módosítás történet oldal - Sikertelen lista lekérdezés")*/
                        commonService.showDialogWindowWithTitleOnly('Szerver hiba. Próbáld meg egy kicsit később újra.', 'Rendben')
                    }
                    setListHeight();
                }, function () {
                    $scope.sendButtonDisabled = false;
                    commonService.showDialogWindowWithTitleOnly('Szerver hiba. Ne csukd le az ablakot, próbáld meg a mentést egy kicsit később újra. A biztonság kedvéért pedig másold ki a most beírt új szövegeket egy külső jegyzetbe, hogy biztos ne vesszenek el.', 'Rendben')
                });
            }
        }

        $scope.showAdministration = function (type) {
            $scope.administrationForm.$setUntouched();
            $scope.isAdministrationWindow = true;
            if ($scope.compName != null) {
                $scope.administrationWindowTitle = "ÜGYINTÉZÉS - " + $scope.compName.toUpperCase();
            } else {
                $scope.administrationWindowTitle = "ÜGYINTÉZÉS - " + $scope.fbPageName.toUpperCase();
            }
            if (type == 0) {
                $scope.administrationCheckbox = false;
                $scope.timeId = null;
                $scope.directionId = null;
                $scope.directionMode = null;
                $scope.ctrl.searchTextGoal = null;
                document.getElementById("administrationDiv").style.backgroundColor = "#DFDFDF";
                //20230921 partnerContactObservations mező kivezetése:
                /*document.getElementById("experiencesButton").style.backgroundColor = "#DFDFDF";
                document.getElementById("experiencesTitle").style.color = "#FFF";
                document.getElementById("experiencesButton").style.cursor = "not-allowed";*/
                document.getElementById("experiencesButton").style.backgroundColor = "#00AEEF";
                document.getElementById("experiencesTitle").style.color = "#FFF";
                document.getElementById("experiencesButton").style.cursor = "pointer";
            } else if (type == 1) {
                $scope.administrationCheckbox = true;
                $scope.timeId = 1;
                $scope.directionId = "Outgoing";
                $scope.directionMode = "Phone call";
                $scope.goal = null;
                setTimeout(function () { $scope.goal = "Első kapcsolatfelvétel (ismertetés)"; }, 100);
                //$scope.ctrl.searchTextGoal = "Első kapcsolatfelvétel (ismertetés)";
                document.getElementById("administrationDiv").style.backgroundColor = "#00AEEF";
                document.getElementById("experiencesButton").style.backgroundColor = "#00AEEF";
                document.getElementById("experiencesTitle").style.color = "#FFF";
                document.getElementById("experiencesButton").style.cursor = "pointer";
            } else if (type == 2) {
                $scope.administrationCheckbox = true;
                $scope.timeId = 2;
                $scope.directionId = "Outgoing";
                $scope.directionMode = "Phone call";
                $scope.goal = null;
                setTimeout(function () { $scope.goal = "Első kapcsolatfelvétel (ismertetés)"; }, 100);
                //$scope.ctrl.searchTextGoal = "Első kapcsolatfelvétel (ismertetés)";
                document.getElementById("administrationDiv").style.backgroundColor = "#00AEEF";
                document.getElementById("experiencesButton").style.backgroundColor = "#00AEEF";
                document.getElementById("experiencesTitle").style.color = "#FFF";
                document.getElementById("experiencesButton").style.cursor = "pointer";
            } else if (type == 3) {
                $scope.administrationCheckbox = true;
                $scope.timeId = 1;
                $scope.directionId = "Outgoing";
                $scope.directionMode = "Phone call";
                $scope.goal = null;
                document.getElementById("administrationDiv").style.backgroundColor = "#00AEEF";
                document.getElementById("experiencesButton").style.backgroundColor = "#00AEEF";
                document.getElementById("experiencesTitle").style.color = "#FFF";
                document.getElementById("experiencesButton").style.cursor = "pointer";
            }
            $scope.partnerContactTime = null;
            $scope.partnerContactDate = null;
            $scope.deadlineDate = null;
            $scope.deadlineTime = null;
            $scope.textArea1 = "";
            $scope.textArea2 = "";
            $scope.textAreaMode = 2;
            $scope.nextTodo = "";
            $scope.ctrl.searchTextResult = null;
            $scope.ctrl.searchTextReason = null;
            $scope.clickOnNotesButton();
            $scope.timeInMin = null;
            $scope.decisionMaker = null
            $scope.enquiry = null;
            if (document.getElementById('loadingOverlay')) {
                document.getElementById('loadingOverlay').style.display = "block";
                document.getElementById('innerPartnerPageContainer').style.overflow = "hidden";
            }
            let filters = {};
            filters.mainId = $scope.companylist[0].mainId;
            filters.queryType = 2;
            return $http.post(API_PREFIX.url + '/yaltyadmin/getSalesPotentialCompanyRecords', filters).then(function (result) {
                if (document.getElementById('loadingOverlay')) {
                    document.getElementById('loadingOverlay').style.display = "none";
                    document.getElementById('innerPartnerPageContainer').style.overflow = "unset";
                }
                setTimeout(function () {
                    $scope.ctrl.searchTextNextTodo = null;
                    $scope.deadlineDate = null;
                    $scope.deadlineTime = null
                    if (result.data.errorCode == 0) {
                        if (result.data.potentialCompanySearchResult[0].customerMasterData != null) {
                            $scope.textArea1 = result.data.potentialCompanySearchResult[0].customerMasterData;
                        }
                        if (result.data.potentialCompanySearchResult[0].notes != null) {
                            $scope.textArea2 = result.data.potentialCompanySearchResult[0].notes;
                        }
                        if (result.data.potentialCompanySearchResult[0].nextTODO != null) {
                            $scope.nextTodo = result.data.potentialCompanySearchResult[0].nextTODO;
                            $scope.ctrl.searchTextNextTodo = result.data.potentialCompanySearchResult[0].nextTODO;
                        }
                        if (result.data.potentialCompanySearchResult[0].nextTODODeadline != null) {
                            let deadlineDate = new Date(result.data.potentialCompanySearchResult[0].nextTODODeadline)
                            $scope.deadlineDate = deadlineDate;
                            $scope.deadlineTime = deadlineDate;
                        }
                        $scope.editStartClientSideTimestamp = new Date();
                        $scope.administrationInitValues = {}
                        $scope.administrationInitValues.administrationCheckbox = $scope.administrationCheckbox;
                        $scope.administrationInitValues.timeId = $scope.timeId;
                        $scope.administrationInitValues.directionId = $scope.directionId;
                        $scope.administrationInitValues.directionMode = $scope.directionMode;
                        $scope.administrationInitValues.searchTextGoal = $scope.ctrl.searchTextGoal;
                        $scope.administrationInitValues.partnerContactTime = $scope.partnerContactTime;
                        $scope.administrationInitValues.partnerContactDate = $scope.partnerContactDate;
                        $scope.administrationInitValues.deadlineDate = $scope.deadlineDate;
                        $scope.administrationInitValues.deadlineTime = $scope.deadlineTime;

                        if (result.data.potentialCompanySearchResult[0].nextTODO != null) {
                            $scope.administrationInitValues.nextTodo = result.data.potentialCompanySearchResult[0].nextTODO;
                        } else {
                            $scope.administrationInitValues.nextTodo = null;
                        }
                        $scope.administrationInitValues.textArea1 = $scope.textArea1;
                        $scope.administrationInitValues.textArea2 = $scope.textArea2;
                        $scope.administrationInitValues.searchTextResult = $scope.ctrl.searchTextResult;
                        $scope.administrationInitValues.searchTextReason = $scope.ctrl.searchTextReason;
                        $scope.administrationInitValues.timeInMin = $scope.timeInMin;
                    } else {
                        commonService.showDialogWindowWithTitleOnly('Szerver hiba. Próbáld meg egy kicsit később újra.', 'Rendben')
                    }
                }, 100);
                $scope.refreshAdminToken();
            });
        }

        $scope.cancelAdministration = function () {
            if ($scope.isAdministrationDataModified()) {
                var confirm = $mdDialog.confirm()
                    .title('Biztos nem mented a változtatásaid?')
                    .ok('BIZTOS NEM MENTEM')
                    .cancel('MÉGSEM');
                $mdDialog.show(confirm).then(function () {
                    $scope.isAdministrationWindow = false;
                }, function () {

                });
            } else {
                $scope.isAdministrationWindow = false;
            }
        }

        //Potenciális partnerekhez tartozó:
        $scope.openPartnerNewTab = function (subscriptionPackage) {
            $scope.sendButtonDisabledForOpenPartnerNewTab = true;
            if ($scope.companyId != null) {
                var company = {};
                company.partnerId = $scope.companyId;
                company.subscriptionPackage = subscriptionPackage;
                $http.post(API_PREFIX.url + '/yaltyadmin/getYaltyAdminUserToken', company).then(function (result) {
                    if (result.data.errorCode == 0) {
                        /*
                            Ez a törlés azért kell ide a partner megnyitása elé, mert ha megnyitjuk a
                            Reg. link landing oldal előnézetet az előregisztrációk listájából korábban,
                            akkor az alábbi cookie-k töltve maradnak, és ezek gondot okozhatnak
                            partner megnyitása után.
                            Példa: megnyitunk egy olyan partnert, akinek ingyenes előfizetése van úgy,
                            hogy közben töltve vannak az alábbi cookie-k, akkor az előfizetések oldalon
                            az előfizetések táblában nem jelenik meg az ingyenes csomag (saját csomagja). 
                        */
                        $cookies.remove('freePackageSubscriptionExpLimited')
                        $cookies.remove('freePackageSubscriptionExpTimestamp')
                        $cookies.remove('isPartnerRegistered')
                        var newTab = $window.open("", "_blank");
                        $scope.sendButtonDisabledForOpenPartnerNewTab = false;
                        $rootScope.isDataSheetModified = false;
                        $cookies.put('authorizationLevel', result.data.authorizationLevel);
                        $scope.authorizationLevel = $cookies.get('authorizationLevel');
                        newTab.location = result.data.hasPartnerActiveSub ? "/partner/home" : "/partner/home/partner-subscription"
                        $cookies.put('userCompany', $scope.compNamePromo);
                        $cookies.put('compLogo', $scope.compLogoImgURL);
                        $cookies.put('userName', "YALTY ADMIN");
                        $cookies.put('sbcrtyp', result.data.partnerData.subData + "&" + result.data.hasPartnerActiveSub + "&" + result.data.partnerData.subscriptionEndTimestamp);
                        $cookies.put('additionalCompanyStoreNumber', result.data.partnerData.additionalCompanyStoreNumber);
                        $cookies.put('additionalPartnerUserNumber', result.data.partnerData.additionalPartnerUserNumber);
                        $cookies.put('additionalFreePartnerUserNumber', result.data.partnerData.additionalFreePartnerUserNumber);
                        $cookies.put('limitMonthlyPushMessageMaxNo', result.data.partnerData.limitMonthlyPushMessageMaxNo);
                        $cookies.put('limitMonthlyIndividualPushMessageMaxNo', result.data.partnerData.limitMonthlyIndividualPushMessageMaxNo);
                        $cookies.put('paymentFrequency', result.data.partnerData.paymentFrequency)
                        $cookies.put('isPaymentForTheNextPeriodRequired', result.data.partnerData.isPaymentForTheNextPeriodRequired)
                        $cookies.put('areCustomDisplaySettingsForRedeemHandlingSheetEnabled', result.data.partnerData.areCustomDisplaySettingsForRedeemHandlingSheetEnabled);
                        $cookies.put('canSendIndividualPushMessages', result.data.partnerData.canSendIndividualPushMessages);
                        $cookies.put('isYaltyStartEnabled', result.data.partnerData.isYaltyStartPaidPackageEnabled);
                        $rootScope.partnerSubscriptionType = $cookies.get('sbcrtyp');
                        $rootScope.hasPartnerActiveSub = result.data.hasPartnerActiveSub;
                        $window.localStorage['compNamePromo'] = $scope.compNamePromo;
                        $window.localStorage['compLogoImgURL'] = $scope.compLogoImgURL;
                        $window.localStorage['companyId'] = $scope.companyId;
                    } else if (result.data.errorCode == 3) {
                        var alert = $mdDialog.alert()
                            .title('A partner oldalai még nem nyithatók meg!')
                            .textContent('Ehhez a partnerhez még nem tartozik előfizetés. A partner már regisztrált, de még sosem választott előfizetést.')
                            .ok('Rendben')
                        $mdDialog.show(alert).then(function () {
                            $scope.sendButtonDisabledForOpenPartnerNewTab = false;
                        });
                    } else {
                        $scope.sendButtonDisabledForOpenPartnerNewTab = false;
                    }
                });
            }
        }

        $scope.modifyCompSize = () => {
            if ($scope.storeCount == 1) {
                $scope.compSize = 1;
            } else if ($scope.storeCount > 1 && $scope.storeCount < 5) {
                $scope.compSize = 2;
            } else if ($scope.compSize != 3 && $scope.compSize != 4) {
                $scope.compSize = null;
            }
            if ($scope.storeCount == 1) {
                $scope.storesCount1 = 2;
                $scope.storesCount2 = 2;
            } else {
                $scope.storesCount1 = 1;
                $scope.storesCount2 = 1;
            }
        }

        const setListsWithValues = () => {
            setFBAndGooglePriceLevels()
            setCompSizes()
            setLoyaltyProgramOptions()
            setPayAtTheCounterOptions()
            setLunchMenuAndTakeawayOptions()
            setStoreDesignOptions()
            setWebPageDesignOptions()
            setCompClassOptions()
            //setCompanyStatuses()
        }

        const setFBAndGooglePriceLevels = () => {
            $scope.fbAndGooglePriceLevels = []
            $scope.fbAndGooglePriceLevels.push({ id: 1, value: '$' })
            $scope.fbAndGooglePriceLevels.push({ id: 2, value: '$$' })
            $scope.fbAndGooglePriceLevels.push({ id: 3, value: '$$$' })
            $scope.fbAndGooglePriceLevels.push({ id: 4, value: '$$$$' })
            $scope.fbAndGooglePriceLevels.push({ id: 0, value: 'Nincs megadva' })
        }

        const setCompSizes = () => {
            $scope.compSizes = []
            $scope.compSizes.push({ id: 1, value: 'Együzletes cég' })
            $scope.compSizes.push({ id: 2, value: 'Néhány üzletes cég (2-4)' })
            $scope.compSizes.push({ id: 3, value: 'Hazai lánc (>= 5 üzlet és csak itthon van üzlete)' })
            $scope.compSizes.push({ id: 4, value: 'Nemzetközi lánc (>= 5 üzlet és külföldön is van üzlete)' })
        }

        const setLoyaltyProgramOptions = () => {
            $scope.loyaltyProgramOptions = []
            $scope.loyaltyProgramOptions.push({ id: 0, value: 'Nincs' })
            $scope.loyaltyProgramOptions.push({ id: 1, value: 'Van, papíros (pecsételős)' })
            $scope.loyaltyProgramOptions.push({ id: 2, value: 'Van, plasztik (pontgyűjtős)' })
            $scope.loyaltyProgramOptions.push({ id: 5, value: 'Van, egyéb (írd be a jegyzetekbe milyen)' })
            $scope.loyaltyProgramOptions.push({ id: 10, value: 'Nem ismert' })
        }

        const setPayAtTheCounterOptions = () => {
            $scope.payAtTheCounterOptions = []
            $scope.payAtTheCounterOptions.push({ id: 0, value: 'Nem' })
            $scope.payAtTheCounterOptions.push({ id: 1, value: 'Igen' })
            $scope.payAtTheCounterOptions.push({ id: 2, value: 'Nem ismert' })
        }

        const setLunchMenuAndTakeawayOptions = () => {
            $scope.lunchMenuAndTakeawayOptions = []
            $scope.lunchMenuAndTakeawayOptions.push({ id: 0, value: 'Nincs' })
            $scope.lunchMenuAndTakeawayOptions.push({ id: 1, value: 'Van' })
            $scope.lunchMenuAndTakeawayOptions.push({ id: 2, value: 'Nem ismert' })
        }

        const setStoreDesignOptions = () => {
            $scope.storeDesignOptions = []
            $scope.storeDesignOptions.push({ id: 1, value: '1 - Öreges' })
            $scope.storeDesignOptions.push({ id: 2, value: '2 - Inkább öreges' })
            $scope.storeDesignOptions.push({ id: 3, value: '3 - A kettő között (átlagos)' })
            $scope.storeDesignOptions.push({ id: 4, value: '4 - Inkább trendi' })
            $scope.storeDesignOptions.push({ id: 5, value: '5 - Letisztult, modern, fiatalos, trendi' })
            $scope.storeDesignOptions.push({ id: 0, value: 'Nincs kép az üzletről' })
        }

        const setWebPageDesignOptions = () => {
            $scope.webPageDesignOptions = []
            $scope.webPageDesignOptions.push({ id: 1, value: '1 - Elavult, régi' })
            $scope.webPageDesignOptions.push({ id: 2, value: '2 - Inkább elavult' })
            $scope.webPageDesignOptions.push({ id: 3, value: '3 - A kettő között (átlagos)' })
            $scope.webPageDesignOptions.push({ id: 4, value: '4 - Inkább trendi' })
            $scope.webPageDesignOptions.push({ id: 5, value: '5 - Letisztult, modern, fiatalos, trendi' })
            $scope.webPageDesignOptions.push({ id: 0, value: 'Nincs weboldala' })
        }

        const setCompClassOptions = () => {
            $scope.compClassOptions = []
            $scope.compClassOptions.push({ id: 1, value: '1 - Idejétmúlt (“lepukkant”)' })
            $scope.compClassOptions.push({ id: 2, value: '2 - Átlag alatti' })
            $scope.compClassOptions.push({ id: 3, value: '3 - Hétköznapi (casual)' })
            $scope.compClassOptions.push({ id: 4, value: '4 - Elegáns' })
            $scope.compClassOptions.push({ id: 5, value: '5 - Exkluzív (upscale)' })
        }

        const setCompanyStatuses = (showDeprecatedValue) => {
            $scope.companyStatusOptions = []
            $scope.companyStatusOptions.push({ id: 1, value: 'Normál (alapértelmezett)' })
            $scope.companyStatusOptions.push({ id: 5, value: 'Nem célcsoport' })
            if (showDeprecatedValue) {
                $scope.companyStatusOptions.push({ id: 10, value: 'Tartósan nem elérhető' })
            }
            $scope.companyStatusOptions.push({ id: 11, value: 'Tartósan nem elérhető - Az egyetlen talált telefonszám(ok) már nem hozzá tartozik' })
            $scope.companyStatusOptions.push({ id: 12, value: 'Tartósan nem elérhető - Nem találtunk hozzá egyáltalán telefonszámot' })
            $scope.companyStatusOptions.push({ id: 13, value: 'Tartósan nem elérhető - Eleinte felvették, de utána már sokadszorra nem' })
            $scope.companyStatusOptions.push({ id: 14, value: 'Tartósan nem elérhető - Egyszer sem vették fel az elérhető telefonszámokon' })
            $scope.companyStatusOptions.push({ id: 30, value: 'Nem jutottunk el a döntéshozóig' })
            $scope.companyStatusOptions.push({ id: 50, value: 'Nem érdekli - Kategorikusan elzárkózik a döntéshozó' })
            $scope.companyStatusOptions.push({ id: 55, value: 'Nem érdekli - Többszöri egyeztetés után úgy döntött nem érdekli' })
            $scope.companyStatusOptions.push({ id: 58, value: 'Nem érdekli - Később (több, mint fél év múlva) talán érdekes lehet' })
            $scope.companyStatusOptions.push({ id: 60, value: 'Nem érdekli - Alkalmazott elmondása alapján nem érdekli (de a döntéshozót nem értük el)' })
            $scope.companyStatusOptions.push({ id: 100, value: 'Megszűnt' })
        }

        /*addOrRemoveDeprecatedCompanyStatus = (isAddition) => {
            $scope.
        }*/


        $scope.modifyCompany = function () {
            setTimeout(function () { angular.element(document.getElementById('scrollContainer-3'))[0].scrollTop = 0; }, 0);
            $scope.isAddCompanyWindow2 = true;
            setListsWithValues()
            $scope.mainId = $scope.companylist[0].mainId;
            if (document.getElementById('loadingOverlay')) {
                document.getElementById('loadingOverlay').style.display = "block";
                document.getElementById('innerPartnerPageContainer').style.overflow = "hidden";
            }
            let filters = {};
            filters.mainId = $scope.companylist[0].mainId;
            filters.queryType = 2;
            return $http.post(API_PREFIX.url + '/yaltyadmin/getSalesPotentialCompanyRecords', filters).then(function (result) {
                if (document.getElementById('loadingOverlay')) {
                    document.getElementById('loadingOverlay').style.display = "none";
                    document.getElementById('innerPartnerPageContainer').style.overflow = "unset";
                }
                if (result.data.errorCode == 0) {
                    const potentialCompany = result.data.potentialCompanySearchResult[0]
                    $scope.companyStatus = potentialCompany.companyStatus;
                    setCompanyStatuses(potentialCompany.companyStatus == 10)
                    $scope.mainContactPersonId = potentialCompany.contactOwnerYaltyAdminUserId;
                    $scope.taxNumber = potentialCompany.taxNum;
                    $scope.compName = potentialCompany.compName;
                    $scope.fbPageName = potentialCompany.facebookPageName;
                    //$scope.companyProfiles = potentialCompany.compProfile;
                    $scope.selectedCompanyProfile = {};
                    $scope.selectedCompanyProfile.compProfile = potentialCompany.compProfile;
                    if ($scope.addCompany2Form.selectedCompanyProfile.$viewValue) {
                        filters.compProfile = $scope.addCompany2Form.selectedCompanyProfile.$viewValue;
                    }
                    $scope.fbLikes = potentialCompany.facebookPageLikeNumber;
                    $scope.storeCount = potentialCompany.numberOfStores;
                    $scope.compStoreAddrCity3 = {};
                    $scope.compStoreAddrCity3.placeName = potentialCompany.storeAddrCity;
                    $scope.fbPriceLevel = potentialCompany.facebookPagePriceClass
                    if (!potentialCompany.fbPageName && !potentialCompany.facebookPageUserName && !potentialCompany.fbLikes) {
                        $scope.fbPriceLevelDisabled = true
                    }
                    $scope.googlePriceLevel = potentialCompany.googlePriceClass
                    $scope.compSize = potentialCompany.compSize
                    $scope.haveLoyaltyProgram = potentialCompany.loyaltyProgramAlreadyInUse
                    $scope.payAtTheCounter = potentialCompany.payingIsNormallyAtRegister
                    $scope.isThereLunchMenu = potentialCompany.isThereLunchMenu
                    $scope.isThereTakeaway = potentialCompany.isThereTakeaway
                    $scope.storeDesign = potentialCompany.ratingStoreDesign
                    $scope.webPageDesign = !potentialCompany.webPageNakedDomain ? 0 : potentialCompany.ratingWebPageDesign
                    $scope.compClass = potentialCompany.ratingCompClass
                    if (potentialCompany.multipleStoresInCity == null) {
                        $scope.storesCount1 = 1;
                    } else if (potentialCompany.multipleStoresInCity == 0) {
                        $scope.storesCount1 = 2;
                    } else if (potentialCompany.multipleStoresInCity == 1) {
                        $scope.storesCount1 = 3;
                    }
                    if (potentialCompany.storesInMultipleCities == null) {
                        $scope.storesCount2 = 1;
                    } else if (potentialCompany.storesInMultipleCities == 0) {
                        $scope.storesCount2 = 2;
                    } else if (potentialCompany.storesInMultipleCities == 1) {
                        $scope.storesCount2 = 3;
                    }
                    if (potentialCompany.facebookPageUserName != null) {
                        $scope.fbURL = $scope.facebookPrefix + potentialCompany.facebookPageUserName;
                    }
                    if (potentialCompany.webPageNakedDomain != null) {
                        $scope.webURL = $scope.webPrefix + potentialCompany.webPageNakedDomain;
                    }
                    if ($scope.compName != null) {
                        $scope.companyWindowTitle = $scope.compName.toUpperCase() + " ADATLAPJA";
                    } else {
                        $scope.companyWindowTitle = $scope.fbPageName.toUpperCase() + " ADATLAPJA";
                    }
                    $scope.initValues = {}
                    $scope.initValues.companyStatus = $scope.companyStatus;
                    $scope.initValues.mainContactPersonId = $scope.mainContactPersonId;
                    $scope.initValues.taxNumber = [$scope.taxNumber]
                    $scope.taxNumber ? true : $scope.initValues.taxNumber.push("")
                    $scope.initValues.compName = [$scope.compName]
                    $scope.compName ? true : $scope.initValues.compName.push("")
                    //$scope.initValues.companyProfiles = $scope.companyProfiles
                    $scope.initValues.selectedCompanyProfile = {}
                    $scope.initValues.selectedCompanyProfile.compProfile = $scope.selectedCompanyProfile.compProfile
                    $scope.initValues.fbPageName = [$scope.fbPageName]
                    $scope.fbPageName ? true : $scope.initValues.fbPageName.push("")
                    $scope.initValues.fbLikes = $scope.fbLikes
                    $scope.initValues.storeCount = $scope.storeCount
                    $scope.initValues.compStoreAddrCity3 = {}
                    $scope.initValues.compStoreAddrCity3.placeName = $scope.compStoreAddrCity3.placeName
                    $scope.initValues.storesCount1 = $scope.storesCount1
                    $scope.initValues.storesCount2 = $scope.storesCount2
                    $scope.initValues.fbURL = $scope.fbURL
                    $scope.initValues.webURL = $scope.webURL
                    $scope.initValues.fbPriceLevel = $scope.fbPriceLevel
                    $scope.initValues.googlePriceLevel = $scope.googlePriceLevel
                    $scope.initValues.compSize = $scope.compSize
                    $scope.initValues.haveLoyaltyProgram = $scope.haveLoyaltyProgram
                    $scope.initValues.payAtTheCounter = $scope.payAtTheCounter
                    $scope.initValues.isThereLunchMenu = $scope.isThereLunchMenu
                    $scope.initValues.isThereTakeaway = $scope.isThereTakeaway
                    $scope.initValues.storeDesign = $scope.storeDesign
                    $scope.initValues.webPageDesign = $scope.webPageDesign
                    $scope.initValues.compClass = $scope.compClass
                    $scope.usageType = 2;
                    $scope.editStartClientSideTimestamp = new Date()
                } else {
                    commonService.showDialogWindowWithTitleOnly('Szerver hiba. Próbáld meg egy kicsit később újra.', 'Rendben')
                }
            });
        }

        $scope.isCompanyDataModified = function () {
            if ($scope.initValues.selectedCompanyProfile.compProfile != $scope.selectedCompanyProfile.compProfile ||
                $scope.initValues.fbLikes != $scope.fbLikes ||
                $scope.initValues.storeCount != $scope.storeCount ||
                $scope.initValues.compStoreAddrCity3.placeName != $scope.compStoreAddrCity3.placeName ||
                $scope.initValues.storesCount1 != $scope.storesCount1 ||
                $scope.initValues.storesCount2 != $scope.storesCount2 ||
                $scope.initValues.fbURL != $scope.fbURL ||
                $scope.initValues.webURL != $scope.webURL ||
                $scope.initValues.mainContactPersonId != $scope.mainContactPersonId ||
                $scope.initValues.companyStatus != $scope.companyStatus ||
                $scope.initValues.taxNumber.indexOf($scope.taxNumber) == -1 ||
                $scope.initValues.compName.indexOf($scope.compName) == -1 ||
                $scope.initValues.fbPageName.indexOf($scope.fbPageName) == -1 ||
                $scope.initValues.fbPriceLevel != $scope.fbPriceLevel ||
                $scope.initValues.googlePriceLevel != $scope.googlePriceLevel ||
                $scope.initValues.compSize != $scope.compSize ||
                $scope.initValues.haveLoyaltyProgram != $scope.haveLoyaltyProgram ||
                $scope.initValues.payAtTheCounter != $scope.payAtTheCounter ||
                $scope.initValues.isThereLunchMenu != $scope.isThereLunchMenu ||
                $scope.initValues.isThereTakeaway != $scope.isThereTakeaway ||
                $scope.initValues.storeDesign != $scope.storeDesign ||
                $scope.initValues.webPageDesign != $scope.webPageDesign ||
                $scope.initValues.compClass != $scope.compClass) {
                return true
            }
            return false
        }

        $scope.isAdministrationDataModified = function () {
            if ($scope.administrationInitValues != null && ($scope.administrationInitValues.administrationCheckbox != $scope.administrationCheckbox ||
                ($scope.administrationCheckbox && $scope.administrationInitValues.timeId != $scope.timeId) ||
                ($scope.administrationCheckbox && $scope.administrationInitValues.directionId != $scope.directionId) ||
                ($scope.administrationCheckbox && $scope.administrationInitValues.directionMode != $scope.directionMode) ||
                ($scope.administrationCheckbox && $scope.administrationInitValues.searchTextGoal != $scope.ctrl.searchTextGoal) ||
                ($scope.administrationCheckbox && $scope.administrationInitValues.partnerContactTime != $scope.partnerContactTime) ||
                ($scope.administrationCheckbox && $scope.administrationInitValues.partnerContactDate != $scope.partnerContactDate) ||
                $scope.administrationInitValues.deadlineDate != $scope.deadlineDate ||
                $scope.administrationInitValues.deadlineTime != $scope.deadlineTime ||
                ($scope.administrationInitValues.nextTodo != $scope.ctrl.searchTextNextTodo && ($scope.administrationInitValues.nextTodo != null || $scope.ctrl.searchTextNextTodo != "")) ||
                (/*$scope.administrationCheckbox && */$scope.administrationInitValues.textArea1 != $scope.textArea1) || //20230921: partnerContactObservations mező kivezetése
                $scope.administrationInitValues.textArea2 != $scope.textArea2 ||
                ($scope.administrationCheckbox && $scope.administrationInitValues.searchTextResult != $scope.ctrl.searchTextResult) ||
                ($scope.administrationCheckbox && $scope.administrationInitValues.searchTextReason != $scope.ctrl.searchTextReason) ||
                ($scope.administrationCheckbox && $scope.administrationInitValues.timeInMin != $scope.timeInMin))) {
                return true
            }
            return false
        }

        $scope.addCompany = function () {
            let isScrolled = false
            if (!$scope.addCompany2Form.compName.$viewValue && !$scope.addCompany2Form.fbPageName.$viewValue) {
                commonService.showDialogWindowWithTitleOnly('A “Cégnév” vagy a “Facebook Page neve” mezők közül legalább az egyiket kötelező kitölteni.', 'OK')
                isScrolled = true
                scrollToElement('compName')
            }
            if ($scope.addCompany2Form.fbURL.$viewValue.split($scope.facebookPrefix)[1] || $scope.addCompany2Form.fbPageName.$viewValue || $scope.addCompany2Form.fbLikes.$viewValue) {
                let invalidFields = []
                let scrollTo
                if (!$scope.addCompany2Form.fbPageName.$viewValue) {
                    invalidFields.push('fbPageName')
                    scrollTo = 'fbPageName'
                } if (!$scope.addCompany2Form.fbURL.$viewValue.split($scope.facebookPrefix)[1]) {
                    invalidFields.push('fbURL')
                    scrollTo = scrollTo ? scrollTo : 'fbURL'
                } if ($scope.addCompany2Form.fbLikes.$viewValue == null) {
                    invalidFields.push('fbLikes')
                    scrollTo = scrollTo ? scrollTo : 'fbLikes'
                } if ($scope.addCompany2Form.fbPriceLevel.$viewValue == null) {
                    invalidFields.push('fbPriceLevel')
                    scrollTo = scrollTo ? scrollTo : 'fbPriceLevel'
                }
                if (invalidFields.length > 0) {
                    $scope.isFBFieldsRequired = true
                    $scope.fbPageMinLength = 14;
                    for (const fbFields of invalidFields) {
                        $scope.addCompany2Form[fbFields].$touched = true
                        $scope.addCompany2Form[fbFields].$valid = false
                        $scope.addCompany2Form[fbFields].$invalid = true
                    }
                    if (!isScrolled) {
                        isScrolled = true
                        scrollToElement(scrollTo)
                    }
                }
            }
            if ($scope.addCompany2Form.$invalid) {
                if ($scope.addCompany2Form.selectedCompanyProfile.$invalid) {
                    $scope.addCompany2Form.selectedCompanyProfile.$touched = true
                    if (!isScrolled) {
                        isScrolled = true
                        commonService.showDialogWindowWithTitleOnly('Tölts ki minden kötelező mezőt!', 'OK', () => {
                            scrollToElement('selectedCompanyProfile')
                        })
                    }
                }
                if ($scope.addCompany2Form.taxNumber.$invalid) {
                    if (!isScrolled) {
                        isScrolled = true
                        commonService.showDialogWindowWithTitleOnly('Egy vagy több mező értékét hibásan adtad meg!', 'OK', () => {
                            scrollToElement('taxNumber')
                        })
                    }
                }
                if ($scope.addCompany2Form.fbPageName.$invalid) {
                    $scope.addCompany2Form.fbPageName.$touched = true
                    if (!isScrolled) {
                        isScrolled = true
                        commonService.showDialogWindowWithTitleOnly('Tölts ki minden kötelező mezőt!', 'OK', () => {
                            scrollToElement('fbPageName')
                        })
                    }
                }
                if ($scope.addCompany2Form.fbURL.$invalid) {
                    if (!isScrolled) {
                        isScrolled = true
                        commonService.showDialogWindowWithTitleOnly('Egy vagy több mező értékét hibásan adtad meg!', 'OK', () => {
                            scrollToElement('fbURL')
                        })
                    }
                }
                if ($scope.addCompany2Form.fbLikes.$invalid) {
                    if (!isScrolled) {
                        isScrolled = true
                        commonService.showDialogWindowWithTitleOnly('Egy vagy több mező értékét hibásan adtad meg!', 'OK', () => {
                            scrollToElement('fbLikes')
                        })
                    }
                }
                if ($scope.addCompany2Form.fbPriceLevel.$invalid) {
                    $scope.addCompany2Form.fbPriceLevel.$touched = true
                    if (!isScrolled) {
                        isScrolled = true
                        commonService.showDialogWindowWithTitleOnly('Tölts ki minden kötelező mezőt!', 'OK', () => {
                            scrollToElement('fbPriceLevel')
                        })
                    }
                }
                if ($scope.addCompany2Form.googlePriceLevel.$invalid) {
                    $scope.addCompany2Form.googlePriceLevel.$touched = true
                    if (!isScrolled) {
                        isScrolled = true
                        commonService.showDialogWindowWithTitleOnly('Tölts ki minden kötelező mezőt!', 'OK', () => {
                            scrollToElement('googlePriceLevel')
                        })
                    }
                }
                if ($scope.addCompany2Form.webURL.$invalid) {
                    if (!isScrolled) {
                        isScrolled = true
                        commonService.showDialogWindowWithTitleOnly('Egy vagy több mező értékét hibásan adtad meg!', 'OK', () => {
                            scrollToElement('webURL')
                        })
                    }
                }
                if ($scope.addCompany2Form.compStoreAddrCity3.$invalid) {
                    $scope.addCompany2Form.compStoreAddrCity3.$touched = true
                    if (!isScrolled) {
                        isScrolled = true
                        commonService.showDialogWindowWithTitleOnly('Tölts ki minden kötelező mezőt!', 'OK', () => {
                            scrollToElement('compStoreAddrCity3')
                        })
                    }
                }
                if ($scope.addCompany2Form.storeCount.$invalid) {
                    if (!isScrolled) {
                        isScrolled = true
                        commonService.showDialogWindowWithTitleOnly('Egy vagy több mező értékét hibásan adtad meg!', 'OK', () => {
                            scrollToElement('storeCount')
                        })
                    }
                }
                if ($scope.addCompany2Form.compSize.$invalid) {
                    $scope.addCompany2Form.compSize.$touched = true
                    if (!isScrolled) {
                        isScrolled = true
                        commonService.showDialogWindowWithTitleOnly('Tölts ki minden kötelező mezőt!', 'OK', () => {
                            scrollToElement('compSize')
                        })
                    }
                }
                if ($scope.addCompany2Form.haveLoyaltyProgram.$invalid) {
                    $scope.addCompany2Form.haveLoyaltyProgram.$touched = true
                    if (!isScrolled) {
                        isScrolled = true
                        commonService.showDialogWindowWithTitleOnly('Tölts ki minden kötelező mezőt!', 'OK', () => {
                            scrollToElement('haveLoyaltyProgram')
                        })
                    }
                }
                if ($scope.addCompany2Form.payAtTheCounter.$invalid) {
                    $scope.addCompany2Form.payAtTheCounter.$touched = true
                    if (!isScrolled) {
                        isScrolled = true
                        commonService.showDialogWindowWithTitleOnly('Tölts ki minden kötelező mezőt!', 'OK', () => {
                            scrollToElement('payAtTheCounter')
                        })
                    }
                }
                if ($scope.addCompany2Form.isThereLunchMenu.$invalid) {
                    $scope.addCompany2Form.isThereLunchMenu.$touched = true
                    if (!isScrolled) {
                        isScrolled = true
                        commonService.showDialogWindowWithTitleOnly('Tölts ki minden kötelező mezőt!', 'OK', () => {
                            scrollToElement('isThereLunchMenu')
                        })
                    }
                }
                if ($scope.addCompany2Form.isThereTakeaway.$invalid) {
                    $scope.addCompany2Form.isThereTakeaway.$touched = true
                    if (!isScrolled) {
                        isScrolled = true
                        commonService.showDialogWindowWithTitleOnly('Tölts ki minden kötelező mezőt!', 'OK', () => {
                            scrollToElement('isThereTakeaway')
                        })
                    }
                }
                if ($scope.addCompany2Form.storeDesign.$invalid) {
                    $scope.addCompany2Form.storeDesign.$touched = true
                    if (!isScrolled) {
                        isScrolled = true
                        commonService.showDialogWindowWithTitleOnly('Tölts ki minden kötelező mezőt!', 'OK', () => {
                            scrollToElement('storeDesign')
                        })
                    }
                }
                if ($scope.addCompany2Form.webPageDesign.$invalid) {
                    $scope.addCompany2Form.webPageDesign.$touched = true
                    if (!isScrolled) {
                        isScrolled = true
                        commonService.showDialogWindowWithTitleOnly('Tölts ki minden kötelező mezőt!', 'OK', () => {
                            scrollToElement('webPageDesign')
                        })
                    }
                }
                if ($scope.addCompany2Form.compClass.$invalid) {
                    $scope.addCompany2Form.compClass.$touched = true
                    if (!isScrolled) {
                        isScrolled = true
                        commonService.showDialogWindowWithTitleOnly('Tölts ki minden kötelező mezőt!', 'OK', () => {
                            scrollToElement('compClass')
                        })
                    }
                }
                if ($scope.addCompany2Form.mainContactPersonId.$invalid) {
                    $scope.addCompany2Form.mainContactPersonId.$touched = true
                    if (!isScrolled) {
                        isScrolled = true
                        commonService.showDialogWindowWithTitleOnly('Tölts ki minden kötelező mezőt!', 'OK', () => {
                            scrollToElement('mainContactPersonId')
                        })
                    }
                }
            } else {
                if (document.getElementById('loadingOverlay')) {
                    document.getElementById('loadingOverlay').style.display = "block";
                    document.getElementById('innerPartnerPageContainer').style.overflow = "hidden";
                }
                const webPageNakedDomain = $scope.webURL.split($scope.webPrefix)[1] ? $scope.webURL.split($scope.webPrefix)[1] : null
                const facebookPageUserName = $scope.fbURL.split($scope.facebookPrefix)[1] ? $scope.fbURL.split($scope.facebookPrefix)[1] : null
                if (webPageNakedDomain == null && $scope.taxNumber == null && facebookPageUserName == null) {
                    if (!isScrolled) {
                        isScrolled = true
                        commonService.showDialogWindowWithTitleOnly('A Facebook oldal, weboldal és adószám hármasból legalább az egyik kitöltése kötelező.', 'OK', () => {
                            scrollToElement('taxNumber')
                        })
                    }
                }
                if (isScrolled) return
                let filters = {};
                filters.usageType = $scope.usageType;
                if ($scope.mainId) filters.mainId = $scope.mainId
                if ($scope.compName) filters.compName = $scope.compName
                if ($scope.taxNumber) filters.taxNum = $scope.taxNumber
                //if ($scope.companyProfiles) filters.compProfile = $scope.companyProfiles
                if ($scope.addCompany2Form.selectedCompanyProfile.$viewValue) {
                    filters.compProfile = $scope.addCompany2Form.selectedCompanyProfile.$viewValue;
                }
                filters.webPageNakedDomain = webPageNakedDomain
                if ($scope.fbPageName) filters.facebookPageName = $scope.fbPageName;
                filters.facebookPageUserName = facebookPageUserName
                if ($scope.fbLikes >= 0) filters.facebookPageLikeNumber = $scope.fbLikes;
                if ($scope.country) filters.storeAddrCountry = $scope.country;
                if ($scope.addCompany2Form.compStoreAddrCity3.$viewValue) {
                    filters.storeAddrCity = $scope.addCompany2Form.compStoreAddrCity3.$viewValue;
                }
                if (true) {//TODO
                    filters.storeAddrCityDistrict = null;
                }
                if ($scope.storeCount) filters.numberOfStores = $scope.storeCount;
                if ($scope.storesCount1) {
                    if ($scope.storesCount1 == 1) {
                        filters.multipleStoresInCity = null;
                    } else if ($scope.storesCount1 == 2) {
                        filters.multipleStoresInCity = 0;
                    } else if ($scope.storesCount1 == 3) {
                        filters.multipleStoresInCity = 1;
                    }
                }
                if ($scope.storesCount2) {
                    if ($scope.storesCount2 == 1) {
                        filters.storesInMultipleCities = null;
                    } else if ($scope.storesCount2 == 2) {
                        filters.storesInMultipleCities = 0;
                    } else if ($scope.storesCount2 == 3) {
                        filters.storesInMultipleCities = 1;
                    }
                }
                if ($scope.mainContactPersonId) filters.contactOwnerYaltyAdminUserId = $scope.mainContactPersonId;
                if ($scope.companyStatus) filters.companyStatus = $scope.companyStatus;
                filters.facebookPagePriceClass = $scope.fbPriceLevel
                filters.googlePriceClass = $scope.googlePriceLevel
                filters.compSize = $scope.compSize
                filters.loyaltyProgramAlreadyInUse = $scope.haveLoyaltyProgram
                filters.payingIsNormallyAtRegister = $scope.payAtTheCounter
                filters.isThereLunchMenu = $scope.addCompany2Form.selectedCompanyProfile.$viewValue == 'Étterem' ? $scope.isThereLunchMenu : null
                filters.isThereTakeaway = $scope.addCompany2Form.selectedCompanyProfile.$viewValue == 'Étterem' ? $scope.isThereTakeaway : null
                filters.ratingStoreDesign = $scope.storeDesign
                filters.ratingWebPageDesign = $scope.webPageDesign
                filters.ratingCompClass = $scope.compClass
                filters.editStartClientSideTimestamp = $scope.editStartClientSideTimestamp
                filters.editEndClientSideTimestamp = new Date()
                filters.editElapsedTime = filters.editEndClientSideTimestamp - new Date(filters.editStartClientSideTimestamp)
                $scope.sendButtonDisabled = true;
                return $http.post(API_PREFIX.url + '/yaltyadmin/addOrModifyPotentialCompany', filters).then(function (result) {
                    if (document.getElementById('loadingOverlay')) {
                        document.getElementById('loadingOverlay').style.display = "none";
                        document.getElementById('innerPartnerPageContainer').style.overflow = "unset";
                    }
                    if (result.data.errorCode == 0) {
                        $scope.isAddCompanyWindow2 = false;
                        $scope.sendButtonDisabled = false;
                        $scope.isSearchWindow = false;
                        /*GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/partneri_módosítás_történet/lista_lekérdezés_sikeres",
                            "HU Partner [PRIVÁT] - Partneri módosítás történet oldal - Sikeres lista lekérdezés");*/
                        let filters = {};
                        filters.mainId = result.data.mainId;
                        $scope.mainId = result.data.mainId;
                        filters.queryType = 1;
                        return $http.post(API_PREFIX.url + '/yaltyadmin/getSalesPotentialCompanyRecords', filters).then(function (result) {
                            if (document.getElementById('loadingOverlay')) {
                                document.getElementById('loadingOverlay').style.display = "none";
                                document.getElementById('innerPartnerPageContainer').style.overflow = "unset";
                            }
                            if (result.data.errorCode == 0) {
                                $scope.checkToNow = new Date();
                                $scope.companylist = result.data.potentialCompanySearchResult;
                                $scope.companyPreRegistrationId = result.data.companyPreRegistrationId;
                                $scope.showCompanyList = true;
                                $scope.hideCalledComps = null;
                                $scope.showInfo = false;
                                $scope.showFirstCall = false;
                                $scope.showSendLink = false;
                                $scope.showSendLink2 = false;
                                $scope.showTodos = false;
                                $scope.isAddCompanyWindow = false;
                                $scope.isFirstAddCompanyWindow = false;
                                $scope.addCompany2Form.$setUntouched()
                                $scope.companyPreRegistrationStatus = result.data.companyPreRegistrationStatus;
                                $scope.registrationLinkSentFirstTimeTimestamp = result.data.registrationLinkSentFirstTimeTimestamp;
                                $scope.freePackageSubscriptionExpLimited = result.data.freePackageSubscriptionExpLimited;
                                $scope.freePackageSubscriptionExpTimestamp = result.data.freePackageSubscriptionExpTimestamp;
                                $scope.status = result.data.status;
                                $scope.numberOfDaysSinceSendOutFirstOpen = result.data.numberOfDaysSinceSendOutFirstOpen;
                                $scope.numberOfDaysSinceSendOutLastOpen = result.data.numberOfDaysSinceSendOutLastOpen;
                                $scope.numberOfDifferentDaysOpenedSinceSendOut = result.data.numberOfDifferentDaysOpenedSinceSendOut;
                                $scope.contentCoverageScore = result.data.contentCoverageScore;
                                $scope.sumTimeSpentOnPages = result.data.sumTimeSpentOnPages;
                                $scope.activationStringExpTimestamp = result.data.activationStringExpTimestamp;
                                $scope.firstEmailViewTimestamp = result.data.firstEmailViewTimestamp;
                                $scope.numberOfEmailViews = result.data.numberOfEmailViews;
                                $scope.firstLCYActivatedTimestamp = result.data.firstLCYActivatedTimestamp;
                                $scope.firstLCYCreatedTimestamp = result.data.firstLCYCreatedTimestamp;
                                $scope.subscriptionPackageFinalisedTimestampAtRegistration = result.data.subscriptionPackageFinalisedTimestampAtRegistration;
                                $scope.subscriptionPackagesIdAtRegistration = result.data.subscriptionPackagesIdAtRegistration;
                                $scope.subscriptionPackageDisplayNameAtRegistration = result.data.subscriptionPackageDisplayNameAtRegistration;
                                $scope.score = result.data.score;
                                $scope.isYaltyStartFreePackageEnabled = result.data.potentialCompanySearchResult[0].isYaltyStartFreePackageEnabled;
                                if($scope.freePackageSubscriptionExpTimestamp != null && new Date("2000-01-01") > new Date($scope.freePackageSubscriptionExpTimestamp)){
                                    $scope.freePackageSubscriptionNotEnabled = true;
                                } else {
                                    $scope.freePackageSubscriptionNotEnabled = false;
                                }
                                if ($scope.firstLCYActivatedTimestamp != null) {
                                    $scope.saleStatus = '6. Aktiválta a kártyáját'
                                } else if ($scope.firstLCYCreatedTimestamp != null) {
                                    $scope.saleStatus = '5. Létrehozta a kártyáját'
                                } else if ($scope.companyPreRegistrationStatus == 10) {
                                    $scope.saleStatus = '4. Beregisztrált'
                                } else if ($scope.registrationLinkSentFirstTimeTimestamp != null && $scope.checkDate > new Date($scope.registrationLinkSentFirstTimeTimestamp)) {
                                    $scope.saleStatus = '3. Emailt követő egyeztetés'
                                } else if ($scope.registrationLinkSentFirstTimeTimestamp != null && $scope.checkDate < new Date($scope.registrationLinkSentFirstTimeTimestamp)) {
                                    $scope.saleStatus = '2. Email kiküldve 5 napon belül'
                                } else if (result.data.potentialCompanySearchResult[0].lastOutgoingCallTimestamp != null) {
                                    $scope.saleStatus = '1. Első megkeresés folyamatban'
                                } else {
                                    $scope.saleStatus = '0. Még nem kerestük'
                                }
                                if (result.data.potentialCompanySearchResult[0].firstAdministrationTimestamp != null || $scope.registrationLinkSentFirstTimeTimestamp != null) {
                                    let startTimestamp;
                                    if(result.data.potentialCompanySearchResult[0].firstAdministrationTimestamp != null && $scope.registrationLinkSentFirstTimeTimestamp == null) {
                                        startTimestamp = result.data.potentialCompanySearchResult[0].firstAdministrationTimestamp;
                                    } else if(result.data.potentialCompanySearchResult[0].firstAdministrationTimestamp == null && $scope.registrationLinkSentFirstTimeTimestamp != null) {
                                        startTimestamp = $scope.registrationLinkSentFirstTimeTimestamp;
                                    } else if(new Date(result.data.potentialCompanySearchResult[0].firstAdministrationTimestamp) < new Date($scope.registrationLinkSentFirstTimeTimestamp)) {
                                        startTimestamp = result.data.potentialCompanySearchResult[0].firstAdministrationTimestamp;
                                    } else {
                                        startTimestamp = $scope.registrationLinkSentFirstTimeTimestamp;
                                    }
                                    if ($scope.subscriptionPackageFinalisedTimestampAtRegistration == null || ($scope.subscriptionPackagesIdAtRegistration == 1 && $scope.firstLCYActivatedTimestamp == null)) {
                                        let date1 = new Date(startTimestamp);
                                        let date2 = new Date();
                                        let diffTime = Math.abs(date2 - date1);
                                        let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                                        $scope.saleTime = diffDays;
                                    } else if ($scope.subscriptionPackagesIdAtRegistration == 1) {
                                        let date1 = new Date(startTimestamp);
                                        let date2 = new Date($scope.firstLCYActivatedTimestamp);
                                        let diffTime = Math.abs(date2 - date1);
                                        let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                                        $scope.saleTime = diffDays;
                                    } else {
                                        let date1 = new Date(startTimestamp);
                                        let date2 = new Date($scope.subscriptionPackageFinalisedTimestampAtRegistration);
                                        let diffTime = Math.abs(date2 - date1);
                                        let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                                        $scope.saleTime = diffDays;
                                    }
                                } else {
                                    $scope.saleTime = null;
                                }
                                if ($scope.status == 10) {
                                    $scope.activationStringExpTimestamp = null;
                                }
                                if ($scope.companyPreRegistrationStatus == 0 || $scope.companyPreRegistrationStatus == 1) {
                                    $scope.regLinkSendedText = null;
                                } else if ($scope.companyPreRegistrationStatus >= 2) {
                                    $scope.regLinkSendedText = "Email: ";
                                    if (result.data.registrationLinkSentByEmailTimestamp == null) {
                                        $scope.regLinkSendedText += "- <br><br>";
                                    } else {
                                        let res = result.data.registrationLinkSentByEmailTimestamp.split("#");
                                        if (res.length == 1) {
                                            $scope.regLinkSendedText += result.data.registrationLinkSentByEmailTimestamp;
                                            $scope.regLinkSendedText += "<br><br>"
                                        } else {
                                            $scope.regLinkSendedText += "<br>"
                                            for (let i = 0; i < res.length; i++) {
                                                $scope.regLinkSendedText += "&#8226 ";
                                                $scope.regLinkSendedText += res[i];
                                                $scope.regLinkSendedText += "<br>";
                                            }
                                            $scope.regLinkSendedText += "<br>";
                                        }
                                    }
                                    $scope.regLinkSendedText += "FB Messenger: "
                                    if (result.data.registrationLinkSentByFBMSGRTimestamp == null) {
                                        $scope.regLinkSendedText += "-";
                                    } else {
                                        $scope.regLinkSendedText += result.data.registrationLinkSentByFBMSGRTimestamp;
                                    }
                                }
                                if ($scope.companylist[0].compName != null) {
                                    let html = "<b onclick='navigator.clipboard.writeText(\"" + $scope.companylist[0].compName + "\")' style='cursor: pointer' title='Vágólapra másolás: “"+ $scope.companylist[0].compName + "”'>" + $scope.companylist[0].compName + "</b> <span onclick='navigator.clipboard.writeText(\"mainId=" + $scope.companylist[0].mainId + "\")' style='cursor: pointer' title='Vágólapra másolás: “mainId="+ $scope.companylist[0].mainId + "”'>(mId:</span> <span onclick='navigator.clipboard.writeText(\"" + $scope.companylist[0].mainId + "\")' style='cursor: pointer' title='Vágólapra másolás: “"+ $scope.companylist[0].mainId + "”'>" + $scope.companylist[0].mainId + "</span>";
                                    if($scope.companyId != null) {
                                        html += " | <span onclick='navigator.clipboard.writeText(\"companyId=" + $scope.companyId + "\")' style='cursor: pointer' title='Vágólapra másolás: “companyId="+ $scope.companyId + "”'>cId:</span> <span onclick='navigator.clipboard.writeText(\"" + $scope.companyId + "\")' style='cursor: pointer' title='Vágólapra másolás: “"+ $scope.companyId + "”'>" + $scope.companyId + "</span>"
                                    }
                                    html += ") cég <b>" + $scope.companylist.length + "</b> rekordja";
                                    document.getElementById("cimke").innerHTML = html;
                                    document.getElementById("cimke2").innerHTML = html;
                                } else {
                                    let html = "<b onclick='navigator.clipboard.writeText(\"" + $scope.companylist[0].facebookPageName + "\")' style='cursor: pointer' title='Vágólapra másolás: “"+ $scope.companylist[0].facebookPageName + "”'>" + $scope.companylist[0].facebookPageName + "</b> <span onclick='navigator.clipboard.writeText(\"mainId=" + $scope.companylist[0].mainId + "\")' style='cursor: pointer' title='Vágólapra másolás: “mainId="+ $scope.companylist[0].mainId + "”'>(mId:</span> <span onclick='navigator.clipboard.writeText(\"" + $scope.companylist[0].mainId + "\")' style='cursor: pointer' title='Vágólapra másolás: “"+ $scope.companylist[0].mainId + "”'>" + $scope.companylist[0].mainId + "</span>)"
                                    if($scope.companyId != null) {
                                        html += " | <span onclick='navigator.clipboard.writeText(\"companyId=" + $scope.companyId + "\")' style='cursor: pointer' title='Vágólapra másolás: “companyId="+ $scope.companyId + "”'>cId:</span> <span onclick='navigator.clipboard.writeText(\"" + $scope.companyId + "\")' style='cursor: pointer' title='Vágólapra másolás: “"+ $scope.companyId + "”'>" + $scope.companyId + "</span>"
                                    }
                                    html += ") cég <b>" + $scope.companylist.length + "</b> rekordja";
                                    document.getElementById("cimke").innerHTML = html;
                                    document.getElementById("cimke2").innerHTML = html;
                                }
                                if ($scope.companylist.length != 0) {
                                    $scope.isEnabledModComp = true;
                                    $scope.isSearchResults = false;
                                }
                                setListHeight();
                            } else {
                                commonService.showDialogWindowWithTitleOnly('Szerver hiba. Próbáld meg egy kicsit később újra.', 'Rendben')
                                /*GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/partneri_módosítás_történet/lista_lekérdezés_sikertelen",
                                    "HU Partner [PRIVÁT] - Partneri módosítás történet oldal - Sikertelen lista lekérdezés")*/
                            }
                        });
                    } else {
                        /*GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/partneri_módosítás_történet/lista_lekérdezés_sikertelen",
                            "HU Partner [PRIVÁT] - Partneri módosítás történet oldal - Sikertelen lista lekérdezés")*/
                        $scope.sendButtonDisabled = false;
                        commonService.showDialogWindowWithTitleOnly('Szerver hiba. Próbáld meg egy kicsit később újra.', 'Rendben')
                    }
                    setListHeight();
                });



            }
        }

        /*$scope.removeInvalidCharsFromFbLikes = () => {
            if ($scope.fbLikes >= 0) {
                $scope.fbLikes = Array.from($scope.fbLikes).filter(x => !isNaN(x)).join("")
            }
        }*/

        $scope.checkForm = function () {
            if (($scope.directionMode == 'Phone call' || $scope.directionMode == 'Personal') && $scope.timeInMin == 0) {
                $scope.decisionMaker = null;
                $scope.enquiry = null;
            } else if ($scope.directionMode != 'Phone call' && $scope.directionMode != 'Personal') {
                $scope.timeInMin = null;
            }
            if($scope.directionMode == 'Personal'){
                $scope.directionId = null;
            }
        }

        $scope.checkFBValues = () => {
            if (!$scope.fbPageName && !$scope.fbURL.split($scope.facebookPrefix)[1] && !$scope.fbLikes) {
                $scope.isFBFieldsRequired = false
                $scope.fbPriceLevel = null
                $scope.fbPriceLevelDisabled = true
                $scope.fbPageMinLength = 0;
            } else {
                $scope.fbPriceLevelDisabled = false
            }
        }

        let scrollToElement = (id) => {
            $location.hash(id);
            $anchorScroll()
            $location.hash('');
            $location.replace();
        }

        $scope.cancelAddCompany = function () {
            $scope.isAddCompanyWindow = false;
            $scope.addCompany1Form.$setUntouched()
            $scope.isFirstAddCompanyWindow = false;
            $scope.firstAddCompanyForm.$setUntouched()
            $scope.fbURL = null;
        }
        $scope.cancelAddCompany2 = function () {
            $scope.isAddCompanyWindow2 = false;
            $scope.addCompany2Form.$setUntouched()
        }
        $scope.cancelSearch = function () {
            $scope.isSearchWindow = false;
            $scope.searchForm.$setUntouched()
        }
        $scope.showAddCompany = function () {
            $scope.editStartClientSideTimestamp = new Date()
            $scope.mainContactPersonId = adminUserId;
            $scope.isFirstAddCompanyWindow = true;
            //$scope.isAddCompanyWindow = true;
            $scope.fbURL = $scope.facebookPrefix;
            $scope.webURL = $scope.webPrefix;
            $scope.taxNumber = null;
            $scope.firstAddCompanyForm.$setUntouched()
            setTimeout(function () {
                document.getElementById("fbPage").focus();
            }, 100);
        }
        $scope.showSearch = function () {
            setTimeout(function () { angular.element(document.getElementById('scrollContainer-2'))[0].scrollTop = 0; }, 0);
            $scope.isSearchWindow = true;
            $scope.companyProfiles = [];
            $scope.allCompanyProfile = [];
            for (let i = 0; i < $scope.promoCompProfiles.length; i++) {
                var compProf = {};
                compProf.value = $scope.promoCompProfiles[i].compProfile;
                compProf.name = $scope.promoCompProfiles[i].compProfile;
                var index = $scope.allCompanyProfile.findIndex(x => x.name == $scope.promoCompProfiles[i].compProfile)
                if (index === -1) {
                    $scope.allCompanyProfile.push(compProf);
                }
            }
            $scope.checkAll = true;
            for (let i = 0; i < $scope.allCompanyProfile.length; i++) {
                $scope.companyProfiles.push($scope.allCompanyProfile[i].value);
            }
            if ($scope.compStoreAddrCity != null) {
                $scope.compStoreAddrCity2 = $scope.compStoreAddrCity;
            } else {
                $scope.compStoreAddrCity2 = null;
            }
            $scope.forekordAzon = null;
            $scope.compName = null;
            $scope.fbPageName = null;
            $scope.fbMinLike = null;
            $scope.storeCount = null;
            $scope.storesCount1 = 1;
            $scope.storesCount2 = 1;
            $scope.taxNumber = null;
            $scope.minStore = null;
            $scope.companyAddedBy = null;
            $scope.companyModifiedBy = null;
            $scope.mainContactPersonIdSearch = adminUserId;
            $scope.fromTimestamp = null;
            $scope.endTimestamp = null;
            $scope.fbURL = $scope.facebookPrefix;
            $scope.webURL = $scope.webPrefix;
            $scope.listing = "0";
        }

        $scope.$watch('selectedCompanyProfile', function (value) {
            if(value){
                $scope.companyProfiles = value.compProfile;
            }
        })

        $scope.$watch('companyProfiles', function (value) {
            if (value) {
                if ($scope.companyProfiles.length == $scope.allCompanyProfile.length) {
                    $scope.checkAll = true;
                } else {
                    $scope.checkAll = false;
                }
            }
        })
        $scope.setAllOptions = function (checkValue, array, model) {
            if (checkValue == true) {
                if (model == "companyProfiles") {
                    $scope.companyProfiles = [];
                    for (let i = 0; i < $scope.allCompanyProfile.length; i++) {
                        $scope.companyProfiles.push($scope.allCompanyProfile[i].value);
                    }
                }
            } else {
                if (model == "companyProfiles") {
                    $scope.companyProfiles = [];
                }
            }
        }
        $scope.setCookie = function () {
            if ($scope.compStoreAddrCity == null) {
                $cookies.remove('yaltyCity');
            } else {
                $cookies.put('yaltyCity', $scope.compStoreAddrCity.placeName);
            }
        }
    })

    .controller('RegisterPartnerController', function ($rootScope, $scope, $cookies, $window, $http, API_PREFIX, currentState, getPromoCatergories, getCities) {
        $scope.currentState = currentState;
        $scope.adminUserType = $cookies.get('adminUserType');
        $rootScope.sub = null;
        $scope.authorizationLevel = null;
        $scope.sendButtonDisabled = false;
        $scope.regButtonShow = true;
        $scope.listOfCities = getCities.listOfCities;
        var promoCategories = [];
        for (var item in getPromoCatergories.allYaltyPromoCategory) {
            promoCategories[item] = {};
            promoCategories[item].id = getPromoCatergories.allYaltyPromoCategory[item].id;
            promoCategories[item].promoCategoryName = getPromoCatergories.allYaltyPromoCategory[item].kategorianev;
        }
        $scope.promotionCategories = promoCategories;
        $scope.modifyDisabled = false;

        $scope.openPartnerNewTab = function () {
            const company = { partnerId: $scope.companyId };
            $http.post(API_PREFIX.url + '/yaltyadmin/getYaltyAdminUserToken', company).then(function (result) {
                if (result.data.errorCode == 0) {
                    /*
                        Ez a törlés azért kell ide a partner megnyitása elé, mert ha megnyitjuk a
                        Reg. link landing oldal előnézetet az előregisztrációk listájából korábban,
                        akkor az alábbi cookie-k töltve maradnak, és ezek gondot okozhatnak
                        partner megnyitása után.
                        Példa: megnyitunk egy olyan partnert, akinek ingyenes előfizetése van úgy,
                        hogy közben töltve vannak az alábbi cookie-k, akkor az előfizetések oldalon
                        az előfizetések táblában nem jelenik meg az ingyenes csomag (saját csomagja). 
                    */
                    $cookies.remove('freePackageSubscriptionExpLimited');
                    $cookies.remove('freePackageSubscriptionExpTimestamp');
                    $cookies.remove('isPartnerRegistered');
                    $cookies.put('authorizationLevel', result.data.authorizationLevel);
                    $cookies.put('userCompany', $scope.compNamePromo);
                    $cookies.put('compLogo', $scope.compLogoImgURL);
                    $cookies.put('userName', "YALTY ADMIN");
                    $cookies.put('sbcrtyp', result.data.partnerData.subData);
                    $cookies.put('additionalCompanyStoreNumber', result.data.partnerData.additionalCompanyStoreNumber);
                    $cookies.put('additionalPartnerUserNumber', result.data.partnerData.additionalPartnerUserNumber);
                    $cookies.put('additionalFreePartnerUserNumber', result.data.partnerData.additionalFreePartnerUserNumber);
                    $cookies.put('limitMonthlyPushMessageMaxNo', result.data.partnerData.limitMonthlyPushMessageMaxNo);
                    $cookies.put('limitMonthlyIndividualPushMessageMaxNo', result.data.partnerData.limitMonthlyIndividualPushMessageMaxNo);
                    $cookies.put('paymentFrequency', result.data.partnerData.paymentFrequency)
                    $cookies.put('isPaymentForTheNextPeriodRequired', result.data.partnerData.isPaymentForTheNextPeriodRequired);
                    $cookies.put('areCustomDisplaySettingsForRedeemHandlingSheetEnabled', result.data.partnerData.areCustomDisplaySettingsForRedeemHandlingSheetEnabled);
                    $cookies.put('isYaltyStartEnabled', result.data.partnerData.isYaltyStartPaidPackageEnabled);
                    $scope.authorizationLevel = $cookies.get('authorizationLevel');
                    $rootScope.partnerSubscriptionType = $cookies.get('sbcrtyp');
                    $rootScope.hasPartnerActiveSub = result.data.hasPartnerActiveSub;
                    $window.localStorage.removeItem('compNamePromo');
                    $window.localStorage.removeItem('compLogoImgURL');
                    $window.localStorage['companyId'] = $scope.companyId;
                    var newTab = $window.open("", "_blank");
                    newTab.location = "/partner/home";
                }
            });
        }

    })

    .controller('YaltyCitysController', function ($rootScope, $scope, $state, $cookies, $http, API_PREFIX, $mdDialog, currentState) {
        $scope.currentState = currentState;
        $scope.adminUserType = $cookies.get('adminUserType');

        $scope.checkFirstLetter = function () {
            var token = $scope.cityName;
            if (token) {
                token = token.charAt(0).toUpperCase() + token.slice(1);
            }
            $scope.cityName = token;
        }

        $scope.createNewYaltyCity = function () {
            var confirm = $mdDialog.confirm()
                .title('Biztosan felveszed ezt az új Yalty várost?')
                .ok('Igen')
                .cancel('Mégsem');
            $mdDialog.show(confirm).then(function () {
                if (document.getElementById('loadingOverlay')) {
                    document.getElementById('loadingOverlay').style.display = "block";
                    document.getElementById('innerPartnerPageContainer').style.overflow = "hidden";
                }
                var yaltyCity = {};
                yaltyCity.cityName = $scope.cityName;
                yaltyCity.cityCenterGPSLatCoord = parseFloat($scope.cityCenterGPSLatCoord).toFixed(7);
                yaltyCity.cityCenterGPSLongCoord = parseFloat($scope.cityCenterGPSLongCoord).toFixed(7);
                yaltyCity.cityNeighbourhoodKmRadius = $scope.cityNeighbourhoodKmRadius;
                $http.post(API_PREFIX.url + '/yaltyadmin/createNewYaltyCity', yaltyCity).then(function (result) {
                    if (document.getElementById('loadingOverlay')) {
                        document.getElementById('loadingOverlay').style.display = "none";
                        document.getElementById('innerPartnerPageContainer').style.overflow = "unset";
                    }
                    if (result.data.errorCode == 0) {
                        const alert = $mdDialog.alert()
                            .textContent('A Yalty város létrehozása sikeresen megtörtént.')
                            .ariaLabel('A Yalty város létrehozása sikeresen megtörtént.')
                            .ok('Rendben')
                        $mdDialog.show(alert).then(function () {
                            $scope.sendButtonDisabled = false;
                            $state.reload();
                        });
                    } else if (result.data.errorCode == 1) {
                        const alert = $mdDialog.alert()
                            .textContent('Sikertelen adatbázis művelet miatt a folyamat megszakadt, kérjük ismételd meg a műveletet.')
                            .ok('Rendben')
                        $mdDialog.show(alert).then(function () {
                            $scope.sendButtonDisabled = false;
                        });
                    } else if (result.data.errorCode == 2) {
                        const alert = $mdDialog.alert()
                            .title("Sikertelen művelet")
                            .textContent("A művelet elvégzéséhez nincs jogosultsága. Ezért kiléptetjük az oldalról.")
                            .ok('Rendben')
                        $mdDialog.show(alert).then(function () {
                            $rootScope.isDataSheetModified = false;
                            $scope.sendButtonDisabled = false;
                            $state.go('adminLogin');
                        });
                    }
                }, function () { })
            }, function () {
            });

        }
    })