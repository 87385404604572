import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, ControlContainer, FormGroup } from '@angular/forms';
import { SelectInput } from "src/app/shared/interfaces/input";

@Component({
    selector: 'app-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit {

    form: FormGroup;
    @Input() inputName: string;
    @Input() label: string;
    @Input() placeholder: string;
    @Input() objArray: SelectInput[];

    constructor(private controlContainer: ControlContainer) { }

    ngOnInit() {
        this.form = <FormGroup>this.controlContainer.control;
    }

    get input(): AbstractControl { return this.form.get(this.inputName)!; }

}
