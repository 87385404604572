import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ControlContainer, FormGroup } from '@angular/forms';

@Component({
    selector: 'app-date-picker',
    templateUrl: './date-picker.component.html',
    styleUrls: ['./date-picker.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DatepickerComponent implements OnInit {

    form: FormGroup;
    @Input() inputName: string;
    @Input() label: string;
    @Input() placeholder: string;
    @Input() disabled = false;

    constructor(private controlContainer: ControlContainer) { }

    ngOnInit() {
        this.form = <FormGroup>this.controlContainer.control;
    }

}
