import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'toFixed'
})
export class ToFixedPipe implements PipeTransform {

    transform(value: number, precision: number): unknown {
        if (!value) return 0;
        return value.toFixed(precision);
    }

}
