import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { UpgradeModule } from '@angular/upgrade/static';
import { HttpClientModule } from '@angular/common/http';
import { APP_BASE_HREF } from '@angular/common';
import { UrlSerializer } from '@angular/router';
import CustomUrlSerializer from 'src/app/config/custom.url.serializer';
import { CookieService } from 'ngx-cookie-service';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularMaterialModule } from 'src/app/config/angular-material.module';
import { PushNotificationComponent } from 'src/app/pages/push-notification/push-notification.component';
import { ReactiveFormsModule } from '@angular/forms';
import { YaltyCityComponent } from './shared/components/yalty-city/yalty-city.component';
import { LanguageSelectorComponent } from './shared/components/language-selector/language-selector.component';
import { InputFieldComponent } from './shared/components/input-text/input-text.component';
import { TextareaFieldComponent } from './shared/components/input-textarea/input-textarea.component';
import { DialogComponent } from './shared/components/dialog/dialog.component';
import { InputNumberComponent } from './shared/components/input-number/input-number.component';
import { SelectComponent } from './shared/components/select/select.component';
import { DatepickerComponent } from './shared/components/date-picker/date-picker.component';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DATE_FORMAT, CustomDateAdapter } from './config/date-picker.config';
import { DatetimePickerComponent } from './shared/components/datetime-picker/datetime-picker.component';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular-material-components/moment-adapter';
import {
    NgxMatDateAdapter,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NGX_MAT_DATE_FORMATS
} from '@angular-material-components/datetime-picker';
import { CustomDateTimeAdapter, DATE_TIME_FORMAT } from './config/datetime-picker.config';
import { AggregateStoresComponent } from './pages/aggregate-stores/aggregate-stores.component';
import { TableComponent } from './shared/components/table/table.component';
import { CheckboxComponent } from './shared/components/checkbox/checkbox.component';
import { AutocompleteComponent } from './shared/components/autocomplete/autocomplete.component';
import { GoogleMapsComponent } from './shared/components/google-maps/google-maps.component';
import { RadioButtonComponent } from './shared/components/radio-button/radio-button.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { LoyaltyCardYaltyDashboardComponent } from './pages/loyalty-card-yalty-dashboard/loyalty-card-yalty-dashboard.component';
import { LoyaltyCardYaltyTimeSeriesStatsComponent } from './pages/loyalty-card-yalty-time-series-stats/loyalty-card-yalty-time-series-stats.component';
import { LoyaltyCardYaltyUserStatsComponent } from './pages/loyalty-card-yalty-user-stats/loyalty-card-yalty-user-stats.component';
import { MultipleSelectComponent } from './shared/components/multiple-select/multiple-select.component';
import { SendingPartnerEmailComponent } from './pages/sending-partner-email/sending-partner-email.component';
import { PartnerEmailTemplateComponent } from './shared/components/partner-email-template/partner-email-template.component';
import { ToFixedPipe } from './shared/pipes/to-fixed.pipe';
import { MultipleSelectAllComponent } from './shared/components/multiple-select-all/multiple-select-all.component';
import { ToggleButtonComponent } from './shared/components/toggle-button/toggle-button.component';
import { ChartsModule } from 'ng2-charts';

@NgModule({
    declarations: [
        AppComponent,
        PushNotificationComponent,
        YaltyCityComponent,
        LanguageSelectorComponent,
        InputFieldComponent,
        TextareaFieldComponent,
        DialogComponent,
        InputNumberComponent,
        SelectComponent,
        DatepickerComponent,
        DatetimePickerComponent,
        AggregateStoresComponent,
        TableComponent,
        CheckboxComponent,
        AutocompleteComponent,
        GoogleMapsComponent,
        RadioButtonComponent,
        LoyaltyCardYaltyDashboardComponent,
        LoyaltyCardYaltyTimeSeriesStatsComponent,
        LoyaltyCardYaltyUserStatsComponent,
        MultipleSelectComponent,
        SendingPartnerEmailComponent,
        PartnerEmailTemplateComponent,
        ToFixedPipe,
        MultipleSelectAllComponent,
        ToggleButtonComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        UpgradeModule,
        AngularMaterialModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        NgxMatDatetimePickerModule,
        NgxMatTimepickerModule,
        ScrollingModule,
        ChartsModule
    ],
    providers: [
        { provide: APP_BASE_HREF, useValue: '/' },
        { provide: UrlSerializer, useClass: CustomUrlSerializer },
        { provide: MAT_DATE_LOCALE, useValue: 'hu-HU' },
        {
            provide: DateAdapter,
            useClass: CustomDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },
        {
            provide: NgxMatDateAdapter,
            useClass: CustomDateTimeAdapter,
            deps: [MAT_DATE_LOCALE, NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },
        { provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT },
        { provide: NGX_MAT_DATE_FORMATS, useValue: DATE_TIME_FORMAT },
        CookieService
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }