import { Injectable } from '@angular/core';
import { ChartOptions } from 'chart.js';

@Injectable({
    providedIn: 'root'
})
export class ChartService {

    defaultChartOptions: ChartOptions = {
        responsive: true,
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true,
                    precision: 0,
                    callback: function (value: number) {
                        if (value % 1 === 0) {
                            return value;
                        }
                    }
                }
            }]
        },
        legend: {
            display: true,
            position: 'right',
            onClick: function (e: any, legendItem: any) {
                e.stopPropagation();
            }
        },
        maintainAspectRatio: false,
        tooltips: {
            mode: 'label',
            intersect: false
        },
    };

    constructor() { }
}
