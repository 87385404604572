<div class="loyalty-card-yalty-user-stats-component default-srolling-design" style="max-width: 99%;">
    <div class="state-and-filters-container">
        <h2>{{ currentState }}
            <i class="fa fa-chevron-right" style="font-size:80%"></i> {{ currentSubState }}
            <i class="fa fa-info-circle tooltip" style="margin: 0; align-self: center;">
                <div class="yaltytiptextright" style="margin-bottom: -31px; width: 500px">
                    <p>Ez az oldal ügyfélszintű kimutatásokat tartalmaz. Minden ügyfelét listázza egyedileg és az egyes ügyfelekhez különböző élettartam és élettartam érték mutatókat jelenít meg. Így például, hogy az adott ügyfél mióta ügyfele, hány kártyája volt már összesen, hány pecsétet kapott és hány jutalmat váltott már be eddig. Emellett mutatja az ügyfelek aktuális kártyáit és azok állapotát is.
                    </p>
                </div>
            </i>
        </h2>
        <form [formGroup]="filtersForm" class="filters-form" [hidden]="((adminUserType=='' || adminUserType=='executive') && currentSubType != 'Egyedi' && currentSubType != 'Premium') || (loyaltyCardTemplates.length < 2 && stores.length < 2 && (allStoresResult == null || allStoresResult.wasStampOrRedeemInDeletedStore == false) && (allStoresResult == null || allStoresResult.wasStampOrRedeemWithoutStore == false))">
            <app-multiple-select-all inputName="loyaltyCardFilter" label="Hűségkártya" [objArray]="loyaltyCardTemplates"
                class="gray-1-theme" allOptionDisplayName="Mind">
            </app-multiple-select-all>
            <mat-form-field appearance="standard">
                <mat-label style="line-height: 1.2;">Üzlet</mat-label>
                <mat-select formControlName="storeFilter" multiple required>
                    <mat-select-trigger>{{storeFilterDisplayValue}}</mat-select-trigger>
                    <mat-checkbox color="primary" class="in-select option-separated"
                        [formControl]="activeStoreFilterField" matRipple>Csak aktív üzletek megjelenítése</mat-checkbox>
                    <mat-option [value]="'all'">Mind</mat-option>
                    <mat-option [value]="'deleted'"
                        *ngIf="allStoresResult && allStoresResult.wasStampOrRedeemInDeletedStore">Törölt üzletek
                    </mat-option>
                    <mat-option [value]="'virtual'"
                        *ngIf="allStoresResult && allStoresResult.wasStampOrRedeemWithoutStore && !activeStoreFilter">
                        Online vagy telefonos rendelések (Nincs üzlet)</mat-option>
                    <mat-option *ngFor="let store of stores" [value]="store.value">{{store.display}}</mat-option>
                </mat-select>
                <mat-error *ngIf="storeFilterField.hasError('required')">Mező kitöltése kötelező!</mat-error>
            </mat-form-field>
            <button mat-flat-button color="primary" (click)="update(true)" class="update-button" style="border-radius: 6px;"
                [disabled]="initApiCallFinished == false || waitingForAPIResult || (adminUserType=='' && currentSubType != 'Egyedi' && currentSubType != 'Premium') || (adminUserType!='' && adminUserType!='executive') || ((adminUserType=='' || adminUserType=='executive') && (loyaltyCardTemplates.length == 0 || stores.length == 0) && (currentSubType == 'Egyedi' || currentSubType == 'Premium'))">
                <span [hidden]="initApiCallFinished == false || waitingForAPIResult">Frissítés</span>
                <img *ngIf="initApiCallFinished == false || waitingForAPIResult" class="loading" src="imgs/loading_indicator.gif">
            </button>
        </form>
    </div>

    <div *ngIf="(adminUserType=='' || adminUserType=='executive') && currentSubType != 'Egyedi' && currentSubType != 'Premium'">
        <div class="stampOrRedeemHappenedEverErrorMessage" style="background-color:rgb(226,240,217); border-radius: 9px;">
            <img src="../imgs/warning_icon.svg" height="17.5">
            <p style="color:rgb(56,87,35);">Az adatok megtekintéséhez <b>{{reqSubType}}</b> előfizetés szükséges.</p>
        </div>
        <p style="max-width: 620px; width: 100%; color: darkgray;"> Önnek jelenleg <b>{{currentSubType}}</b> előfizetése van. <a href="partner/home/partner-subscription" style="color: #00AEEF; text-decoration: none;">Az előfizetések áttekintéséhez és módosításához kattintson ide.</a></p>
    </div>
    <div *ngIf="adminUserType!='' && adminUserType!='executive'" class="stat-container">
        <p>Adatbiztonsági okokból Yalty admin nem látja és nem kezelheti partnereink felhasználóinak adatait.</p>
    </div>

    <div *ngIf="initApiCallFinished == true && (adminUserType=='' || adminUserType=='executive') && (!stampOrRedeemHappenedEver) && (currentSubType == 'Egyedi' || currentSubType == 'Premium')">
        <div class="stampOrRedeemHappenedEverErrorMessage" style="background-color:rgb(226,240,217); border-radius: 9px;">
                  <img src="../imgs/warning_icon.svg" height="25">
                  <p style="color:rgb(56,87,35);">Még nincs aktív hűségkártyája vagy a kártyát még egy ügyfele sem használta.</p>
            </div>
          <p style="max-width: 620px; width: 100%; color: darkgray;">A Ügyfél lista akkor jelenik meg, ha legalább egy kártyán legalább egy pecsételés történt.</p>

    </div>
    <div *ngIf="initApiCallFinished == true && (adminUserType=='' || adminUserType=='executive') && ((!stampOrRedeemHappenedEver) || ((currentSubType != 'Egyedi' && currentSubType != 'Premium')))">
          <img class="sampleViewOfDashboardOrTable" 
          src="../imgs/yalty_weblap_partneri_ugyfel_lista_20250224.png" 
          (click)="openFullscreen('../imgs/yalty_weblap_partneri_ugyfel_lista_20250224.png')">
      
        <!-- Teljes képernyős nézet -->
        <div 
          *ngIf="isFullscreen" 
          class="fullscreen-model" 
          (click)="closeFullscreen()">
          <img [src]="fullscreenImageSrc" alt="Fullscreen preview">
        </div>
    </div>

    <div *ngIf="(adminUserType=='' || adminUserType=='executive') && (currentSubType == 'Egyedi' || currentSubType == 'Premium')">
        <div>
            <div *ngIf="showLCYUserStats">
            <div style="display: flex; justify-content: space-between; width: auto;">
            <form [formGroup]="orderByForm" class="filters-form">
                <div>
                    <div style="display: flex; justify-content: space-between;">
                        <mat-form-field appearance="standard" style="width: 180px; margin-right: 0px;">
                            <mat-label style="line-height: 1.2;">Rendezés</mat-label>
                            <mat-select formControlName="orderBy">
                            <mat-option [value]="'userLifeTime'">Ügyfél élettartam</mat-option>
                            <mat-option [value]="'nrOfStamps'">Pecsétek (db)</mat-option>
                            <mat-option [hidden]="hasAnyLoyaltyCardWithMoreThanOneRedeem == false" [value]="'nrOfRedeemedFinalRewards'">Beváltott végjutalmak (db)</mat-option>
                            <mat-option [value]="'nrOfRedeemedInstances'">Beváltott kártyák (db)</mat-option>
                            <mat-option [value]="'nrOfActiveLoyaltyCardYaltyInstances'">Aktív kártyák (db)</mat-option>
                            </mat-select>
                            <mat-error *ngIf="storeFilterField.hasError('required')">Mező kitöltése kötelező!</mat-error>
                            </mat-form-field>
                            <mat-checkbox color="primary" class="in-select option-separated" style="width: 160px; margin-bottom: 0; align-self: center;"
                            [formControl]="detailedViewField">Részletes nézet</mat-checkbox>
                            <i class="fa fa-info-circle tooltip" style="margin: 0; align-self: center;">
                                <div class="yaltytiptextright" style="margin-bottom: 18px;">
                                    <p>
                                    A részletes nézetet bekapcsolva további oszlopok, adatok válnak láthatóvá a táblázatban.
                                    </p>
                                </div>
                            </i>
                    </div>
                </div>
            </form>
            <div style="width: 100%; margin-left: 15px; display: flex; align-items: center;">
                <mat-form-field class="mat-form-field" style="color: black; flex-grow: 1;align-items: center; max-width: 370px; top: 1.5px;" floatLabel="always">
                    <mat-label style="text-align: left; color: #9e9e9e;"><svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 21.227 20.981">
                        <g id="search" transform="translate(-280.85 -65.85)">
                          <g id="zoom" transform="translate(36.688 -250.739)">
                            <line id="Line_5" data-name="Line 5" x1="4.428" y1="4.305" transform="translate(259.335 331.638)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.3" style="stroke: rgb(128, 128, 128);"/>
                            <ellipse id="Ellipse_6" data-name="Ellipse 6" cx="8.118" cy="7.995" rx="8.118" ry="7.995" transform="translate(245.313 317.739)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.3" style="stroke: rgb(128, 128, 128);"/>
                          </g></g></svg> Szűrő</mat-label>
                    <input 
                      matInput 
                      type="search" 
                      [(ngModel)]="quickSearchText" 
                      (ngModelChange)="filterByPartnerSearch()" 
                      [placeholder]="quickSearchPlaceholderText">
                      <button 
                        *ngIf="quickSearchText" 
                        matSuffix 
                        style="z-index: 3; padding: 0px; margin-bottom: 4px; height: 22px; background: white; border: none; " 
                        (click)="quickSearchText = ''; filterByPartnerSearch()">
                        <img style="width: 18px;" src="imgs/remove_icon_grey.svg">
                      </button>
                  </mat-form-field>
            </div>
            <div layout="row" style="display: flex; align-items: center; margin-right: 0px;">
                <mat-form-field appearance="standard" style="width: 90px; margin-right: 10px;">
                    <mat-label>Fájltípus</mat-label>
                    <mat-select [(value)]="selectedFileType">
                      <mat-option [value]="'Excel'">Excel</mat-option>
                      <mat-option [value]="'CSV'">CSV</mat-option>
                    </mat-select>
                  </mat-form-field>
                    <button mat-flat-button color="primary" (click)="exportData()" class="update-button" style="border-radius: 6px;">
                    <span>Exportálás</span>
                </button>
            </div>
        </div>
    </div>

    <div *ngIf="showLCYUserStats && filteredUserStats.length == 0" class="stat-container">
        <p>A beállított szűrés mellett nincs megjeleníthető adat. Módosítson a szűrésen vagy törölje azt.</p>
    </div>
    <mat-spinner *ngIf="initApiCallFinished == false || waitingForAPIResult" [diameter]="40"></mat-spinner>

    <cdk-virtual-scroll-viewport *ngIf="showLCYUserStats" itemSize="60" minBufferPx="300000" maxBufferPx="300100" class="cdk-viewport"
                [ngStyle]="storeListHeight">
                <table class="default-design all-store-list">
                    <tr *ngIf="filteredUserStats.length > 0" style="height: 28px; position: sticky; top: 0; z-index: 4;">
                        <th rowspan="2">
                        </th>
                        <th rowspan="2" style="min-width: 200px;">
                           <div style="display: flex; justify-content: center;">
                            <p style="margin: 0;">Ügyfél</p>
                            <div class="tooltip" style="align-self: center; padding-top: 2px;">
                                <ion-icon name="information-circle-outline"
                                    style="width: 20px">
                                </ion-icon>
                                <span class="yaltytiptextbottom3" style="margin-top: 30px; margin-right: -10px; width: 140px;">
                                    Az ügyfél neve és<br>publikus azonosítója.
                                </span>
                            </div>
                        </div>
                        </th>
                        <th *ngIf="isContactInformationBeingAsked" rowspan="2" style="min-width: 200px;">
                            <div style="display: flex; justify-content: center;">
                             <p style="margin: 0;">Kapcsolat</p>
                         </div>
                         </th>
                        <th colspan="2">Aktivitás</th>
                        <th rowspan="2">
                            <div style="display: flex; justify-content: space-between;">
                                <p style="margin: 0;">Ügyfél<br>élettartam</p>
                                <div class="tooltip" style="align-self: center; padding-top: 2px;">
                                    <ion-icon name="information-circle-outline"
                                        style="width: 20px">
                                    </ion-icon>
                                    <span class="yaltytiptextbottom4" style="margin-top: 30px; margin-right: -260px; width: 300px;">
                                        Az első és utolsó aktivitás közt eltelt idő.
                                    </span>
                                </div>
                            </div>
                        </th>
                        <th rowspan="2" [hidden]="detailedView == false || nrOfFilteredLoyaltyCardTemplates < 2" style="background-color: #80d7f7;">
                            <div style="display: flex; justify-content: space-between;">
                                <p style="margin: 0;">Hűségkártya<br>típusok (db)</p>
                                <div class="tooltip" style="align-self: center; padding-top: 5px;">
                                    <ion-icon name="information-circle-outline"
                                        style="width: 20px">
                                    </ion-icon>
                                    <span class="yaltytiptextbottom4" style="margin-top: 30px; margin-right: -260px; width: 300px;">
                                        Hány különböző típusú kártyája van (darab).
                                    </span>
                                </div>
                            </div>
                        </th>
                        <th rowspan="2">
                            <div style="display: flex; justify-content: space-between;">
                                <p style="margin: 0;">Pecsétek (db)</p>
                                <div class="tooltip" style="align-self: center; padding-top: 2px;">
                                    <ion-icon name="information-circle-outline"
                                        style="width: 20px">
                                    </ion-icon>
                                    <span class="yaltytiptextbottom4" style="margin-top: 30px; margin-right: -260px; width: 300px;">
                                        Hány pecsétet gyűjtött eddig össze összesen (darab).
                                    </span>
                                </div>
                            </div>
                        </th>
                        <th rowspan="2" [hidden]="hasAnyLoyaltyCardWithMoreThanOneRedeem == false">
                            <div style="display: flex; justify-content: space-between;">
                                <p style="margin: 0;">Beváltott köztes<br>jutalmak (db)</p>
                                <div class="tooltip" style="align-self: center; padding-top: 5px;">
                                    <ion-icon name="information-circle-outline"
                                        style="width: 20px">
                                    </ion-icon>
                                    <span class="yaltytiptextbottom4" style="margin-top: 30px; margin-right: -260px; width: 300px;">
                                        Az összes eddig beváltott köztes jutalmak száma (darab).
                                    </span>
                                </div>
                            </div>
                        </th>
                        <th rowspan="2" [hidden]="hasAnyLoyaltyCardWithMoreThanOneRedeem == false">
                            <div style="display: flex; justify-content: space-between;">
                                <p style="margin: 0;">Beváltott<br>végjutalmak (db)</p>
                                <div class="tooltip" style="align-self: center; padding-top: 5px;">
                                    <ion-icon name="information-circle-outline"
                                        style="width: 20px">
                                    </ion-icon>
                                    <span class="yaltytiptextbottom3" style="margin-top: 30px; margin-right: -10px; width: 305px;">
                                        Az összes eddig beváltott végjutalmak száma (darab).
                                    </span>
                                </div>
                            </div>
                        </th>
                        <th rowspan="2">
                            <div style="display: flex; justify-content: space-between;">
                                <p style="margin: 0;">Beváltott<br>kártyák (db)</p>
                                <div class="tooltip" style="align-self: center; padding-top: 5px;">
                                    <ion-icon name="information-circle-outline"
                                        style="width: 20px">
                                    </ion-icon>
                                    <span class="yaltytiptextbottom3" style="margin-top: 30px; margin-right: -10px; width: 305px;">
                                        Az összes olyan eddigi kártyák száma (darab),<br>amelyeken minden pecsét kitelt és<br>minden jutalom beváltásra került.
                                    </span>
                                </div>
                            </div>
                        </th>
                        <th rowspan="2" [hidden]="detailedView == false" style="background-color: #80d7f7;">
                            <div style="display: flex; justify-content: space-between;">
                                <p style="margin: 0;">Lejárt<br>kártyák (db)</p>
                                <div class="tooltip" style="align-self: center; padding-top: 5px;">
                                    <ion-icon name="information-circle-outline"
                                        style="width: 20px">
                                    </ion-icon>
                                    <span class="yaltytiptextbottom4" style="margin-top: 30px; margin-right: -260px; width: 300px;">
                                        Az összes eddigi lejárt kártyák száma (darab).
                                    </span>
                                </div>
                            </div>
                        </th>
                        <th rowspan="2" [hidden]="detailedView == false" style="background-color: #80d7f7;">
                            <div style="display: flex; justify-content: space-between;">
                                <p style="margin: 0;">Törölt<br>kártyák (db)</p>
                                <div class="tooltip" style="align-self: center; padding-top: 5px;">
                                    <ion-icon name="information-circle-outline"
                                        style="width: 20px">
                                    </ion-icon>
                                    <span class="yaltytiptextbottom4" style="margin-top: 30px; margin-right: -260px; width: 300px;">
                                        Az összes eddigi ügyfél által törölt<br>(de nem lejárt) kártyák száma (darab).
                                    </span>
                                </div>
                            </div>
                        </th>
                        <th rowspan="2">
                            <div style="display: flex; justify-content: space-between;">
                                <p style="margin: 0;">Aktív<br>kártyák (db)</p>
                                <div class="tooltip" style="align-self: center; padding-top: 5px;">
                                    <ion-icon name="information-circle-outline"
                                        style="width: 20px">
                                    </ion-icon>
                                    <span class="yaltytiptextbottom3" style="margin-top: 30px; margin-right: -10px; width: 305px;">
                                        Az olyan jelenleg is aktív, nyitott kártyák száma (darab),<br>amelyeken van legalább egy pecsét és amelyek még<br>érvényesek, tehát egyik lejáratuk szerint sem jártak még le.
                                    </span>
                                </div>
                            </div>
                        </th>
                        <th rowspan="2" [hidden]="detailedView == false || hasAnyLoyaltyCardWithEnabledYLCSelectableProducts != true" style="min-width: 260px; background-color: #80d7f7;">
                            <div style="display: flex; justify-content: center;">
                                <p style="margin: 0;">Pecsételési/Beváltási<br>tételek</p>
                                <div class="tooltip" style="align-self: center; padding-top: 5px;">
                                    <ion-icon name="information-circle-outline"
                                        style="width: 20px">
                                    </ion-icon>
                                    <span class="yaltytiptextbottom3" style="margin-top: 30px; margin-right: -10px; width: 310px;">
                                        Pecsételési/Beváltási tételek TOP3 statisztikája.<br>Az ügyfél által legtöbbet fogyasztott/vásárolt tételt mutatja<br>az ügyfél összes tételéhez viszonyított százalékos aránnyal<br>és a tényleges fogyasztási darabszámmal.<br><br>Megjegyzés: amennyiben az adott ügyfél még nem<br>használt olyan hűségkártyát, amihez tartoznak<br>pecsételési/beváltási tételek úgy az ügyfélhez<br>statisztika sem jelenik itt meg. Vagyis csak olyan kártyák<br>esetén jelenik meg statisztika az ügyfélhez, amely<br>kártyához tartoznak pecsételési/beváltási tételek és<br>amely kártyát az ügyfél már használta is.
                                    </span>
                                </div>
                            </div>
                        </th>
                        <th colspan="7" [hidden]="detailedView == false" style="background-color: #80d7f7;">
                            <div style="display: flex; justify-content: center;">
                                <p style="margin: 0;">Aktív kártyájának / kártyáinak állapota</p>
                                <div class="tooltip" style="align-self: center; padding-top: 2px;">
                                    <ion-icon name="information-circle-outline"
                                        style="width: 20px">
                                    </ion-icon>
                                    <span class="yaltytiptextbottom3" style="margin-top: 30px; margin-right: -10px; width: 305px;">
                                        Az olyan jelenleg is aktív, nyitott kártyáinak állapota,<br>amelyeken van legalább egy pecsét és amelyek még<br>érvényesek, tehát egyik lejáratuk szerint sem jártak még le.
                                    </span>
                                </div>
                            </div>
                        </th>
                    </tr>
                    <tr *ngIf="filteredUserStats.length > 0" style="position: sticky; top: 27.8px; z-index: 3;">
                        <th>Első</th>
                        <th>Utolsó</th>
                        <th [hidden]="detailedView == false || loyaltyCardTemplates.length < 2" style="min-width: 250px; background-color: #80d7f7;">
                            <p style="margin: 0;">Hűségkártya</p>
                        </th>
                        <th [hidden]="detailedView == false" style="min-width: 121px; background-color: #80d7f7;">
                            <div style="display: flex; justify-content: center;">
                                <p style="margin: 0; align-self: center;">Pecsételési lejárat</p>
                            </div>
                        </th>
                        <th [hidden]="detailedView == false" style="min-width: 121px; background-color: #80d7f7;">
                            <div style="display: flex; justify-content: center;">
                                <p style="margin: 0; align-self: center;">Véglejárat</p>
                            </div>
                        </th>
                        <th style="min-width: 100px; background-color: #80d7f7;" [hidden]="detailedView == false">
                            <div style="display: flex; justify-content: center;">
                                <p style="margin: 0;">Állapot</p>
                                <div class="tooltip" style="align-self: center; padding-top: 2px;">
                                    <ion-icon name="information-circle-outline"
                                        style="width: 20px">
                                    </ion-icon>
                                    <span class="yaltytiptextbottom3" style="margin-top: 30px; margin-right: -10px; width: 305px;">
                                        Az eddig a kártyán összegyűjtött és az összes,<br>a kártyán lehetséges pecsétek száma.
                                    </span>
                                </div>
                            </div>
                        </th>
                        <th style="min-width: 100px; background-color: #80d7f7;" [hidden]="detailedView == false || hasAnyLoyaltyCardWithMoreThanOneRedeem == false">
                            <p style="margin: 0;">Köztes jutalmak</p>
                        </th>
                        <th style="min-width: 100px; background-color: #80d7f7;" [hidden]="detailedView == false">
                            <p style="margin: 0;">Végjutalom</p>
                        </th>
                    </tr>
                    <tr *cdkVirtualFor="let user of filteredUserStats; index as i" [ngClass]="{'lightblue-background': i % 2 === 1}">
                        <td style="color: #abafb9;">{{i+1}}.</td>
                        <td>
                            <div style="display: flex;">
                                <span><img [src]="user.profilePicImgURL || 'imgs/icon_profile_picture_DFDFDF.png'" height="60" width="60" style="border-radius: 15px; display: inherit; padding: 0;"></span>
                                <div *ngIf="user.lastName != null || user.firstName != null" style="display: flex; flex-direction: column; width: 100%; justify-content: center;">
                                    <span style="align-self: center; display: contents;"><b><p style="text-align: center; margin: 0px;" [innerHTML]="setSearchHighlight(user.lastName+' '+user.firstName)"></p></b></span>
                                    <span style="align-self: center; display: contents;"><p style="text-align: center; margin: 0px;" [innerHTML]="setSearchHighlight(user.publicRedeemUserId)"></p></span>
                                </div>
                                <div *ngIf="user.lastName == null && user.firstName == null" style="display: flex; flex-direction: column; width: 100%; justify-content: center;">
                                    <span style="align-self: center; display: contents;"><i><p style="text-align: center; margin: 0px;" [innerHTML]="setSearchHighlight('Törölt felhasználó')"></p></i></span>
                                    <span style="align-self: center; display: contents;"><p style="text-align: center; margin: 0px;" [innerHTML]="setSearchHighlight(user.publicRedeemUserId)"></p></span>
                                </div>
                            </div>
                        </td>
                        <td *ngIf="isContactInformationBeingAsked">
                            <div *ngIf="user.lastName == null && user.firstName == null">
                                <span><i><p style="text-align: center; margin: 0px;" [innerHTML]="setSearchHighlight('Törölt felhasználó')"></p></i></span>
                            </div>
                            <div *ngIf="user.emailContact.length > 0 && (user.lastName != null || user.firstName != null)">
                                <div *ngFor="let emailContact of user.emailContact; index as j" style=" display: flex; align-items: center;">
                                    <p style="margin: 3px; width: 100%; text-align: center;" [innerHTML]="setSearchHighlight(emailContact)"></p>
                                </div>
                            </div>
                            <div *ngIf="user.phoneContact.length > 0 && (user.lastName != null || user.firstName != null)">
                                <div *ngFor="let phoneContact of user.phoneContact; index as j" style=" display: flex; align-items: center;">
                                    <p style="margin: 3px; width: 100%; text-align: center;" [innerHTML]="setSearchHighlight(phoneContact)"></p>
                                </div>
                            </div>
                            
                            <span *ngIf="user.phoneContact.length == 0 && user.emailContact.length == 0 && user.customerContactInfoRegistrationTimestamp.length > 0 && (user.lastName != null || user.firstName != null)"><i><p style="margin: auto;" [innerHTML]="setSearchHighlight('Nem adta meg.')"></p></i></span>
                            <span *ngIf="user.phoneContact.length == 0 && user.emailContact.length == 0 && user.customerContactInfoRegistrationTimestamp.length == 0 && (user.lastName != null || user.firstName != null)"><i><p style="margin: auto;" [innerHTML]="setSearchHighlight('Még nem adta meg.')"></p></i></span>
                        </td>
                        <td>
                            <p style="margin: 0;"><b>{{user.firstActivityTimestamp | date: 'yyyy.MM.dd.'}}</b><br>{{user.firstActivityTimestamp | date: 'HH:mm'}}</p>
                        </td>
                        <td>
                            <p style="margin: 0;"><b>{{user.lastActivityTimestamp | date: 'yyyy.MM.dd.'}}</b><br>{{user.lastActivityTimestamp | date: 'HH:mm'}}</p>
                        </td>
                        <td>
                            <span *ngIf="user.userLifeTime + 1 > 365">{{((user.userLifeTime + 1) - ((user.userLifeTime + 1) % 365)) / 365 | number:'1.0-0'}} év<br>{{(user.userLifeTime + 1) % 365 | number:'1.0-0'}} nap</span>
                            <span *ngIf="user.userLifeTime + 1 <= 365">{{(user.userLifeTime + 1) | number:'1.0-0'}} nap</span>
                        </td>
                        <td [hidden]="detailedView == false || nrOfFilteredLoyaltyCardTemplates < 2">
                            {{user.nrOfLoyaltyCards != null ? user.nrOfLoyaltyCards : '-'}}
                        </td>
                        <td>
                            {{user.nrOfStamps != null ? user.nrOfStamps : '-'}}
                        </td>
                        <td [hidden]="hasAnyLoyaltyCardWithMoreThanOneRedeem == false">
                            {{user.nrOfRedeemedIntermediateRewards != null ? user.nrOfRedeemedIntermediateRewards : '-'}}
                        </td>
                        <td [hidden]="hasAnyLoyaltyCardWithMoreThanOneRedeem == false">
                            {{user.nrOfRedeemedFinalRewards != null ? user.nrOfRedeemedFinalRewards : '-'}}
                        </td>
                        <td>
                            {{user.nrOfRedeemedInstances != null ? user.nrOfRedeemedInstances : '-'}}
                        </td>
                        <td [hidden]="detailedView == false">
                            {{user.nrOfExpiredInstances != null ? user.nrOfExpiredInstances : '-'}}
                        </td>
                        <td [hidden]="detailedView == false">
                            {{user.nrOfRemovedInstances != null ? user.nrOfRemovedInstances : '-'}}
                        </td>
                        <td>
                            {{user.activeLoyaltyCardYaltyInstances.length}}
                        </td>
                        <td [hidden]="detailedView == false || hasAnyLoyaltyCardWithEnabledYLCSelectableProducts != true">
                            <div *ngIf="user.topYLCSelectedProducts.length > 0">
                                <div *ngFor="let promotion of user.topYLCSelectedProducts; index as j">
                                    <p style="text-align: left; margin: 0;"><b>{{promotion.promotionName}}</b></p>
                                    <ul style="text-align: left;">
                                        <li *ngFor="let YLCSelectedProduct of promotion.YLCSelectedProducts; index as k">- {{YLCSelectedProduct.YLCSelectedProduct}} {{YLCSelectedProduct.percentage}}% ({{YLCSelectedProduct.nrOfYLCSelectedProducts}} db)</li>
                                    </ul>                                          
                                </div>                                
                            </div>
                            <div *ngIf="user.topYLCSelectedProducts.length == 0">
                                <p style="margin: 0; width: 100%; text-align: center;">-</p>
                            </div>
                        </td>
                        <td [hidden]="detailedView == false || loyaltyCardTemplates.length < 2" style="padding-top: 0; padding-bottom: 0;">
                            <div *ngIf="user.activeLoyaltyCardYaltyInstances.length > 0">
                                <div *ngFor="let activeLoyaltyCardYaltyInstance of user.activeLoyaltyCardYaltyInstances; index as j" style="height: 35px; display: flex; align-items: center;" [ngStyle]="{'border-bottom': (j + 1) < user.activeLoyaltyCardYaltyInstances.length ? '2px solid #F2F2F2' : '0px'}">
                                    <p style="margin: 0; width: 100%; text-align: center;">{{activeLoyaltyCardYaltyInstance.promotionName}}</p>
                                </div>
                            </div>
                            <div *ngIf="user.activeLoyaltyCardYaltyInstances.length == 0">
                                <p style="margin: 0; width: 100%; text-align: center;">-</p>
                            </div>
                        </td>
                        <td [hidden]="detailedView == false" style="padding-top: 0; padding-bottom: 0;">
                            <div *ngIf="user.activeLoyaltyCardYaltyInstances.length > 0">
                                <div *ngFor="let activeLoyaltyCardYaltyInstance of user.activeLoyaltyCardYaltyInstances; index as j" style="height: 35px; display: flex; align-items: center;" [ngStyle]="{'border-bottom': (j + 1) < user.activeLoyaltyCardYaltyInstances.length ? '2px solid #F2F2F2' : '0px'}">
                                    <p *ngIf="activeLoyaltyCardYaltyInstance.cardValidityStampingExpiry != null" style="margin: 0; width: 100%; text-align: center;"><b>{{activeLoyaltyCardYaltyInstance.cardValidityStampingExpiry | date: 'yyyy.MM.dd.'}}</b><span *ngIf="timezone != 'Europe/Budapest'"><br>{{activeLoyaltyCardYaltyInstance.cardValidityStampingExpiry | date: 'HH:mm'}}</span></p>
                                    <p *ngIf="activeLoyaltyCardYaltyInstance.cardValidityStampingExpiry == null" style="margin: 0; width: 100%; text-align: center;">-</p>
                                </div>
                            </div>
                            <div *ngIf="user.activeLoyaltyCardYaltyInstances.length == 0">
                                <p style="margin: 0; width: 100%; text-align: center;">-</p>
                            </div>
                        </td>
                        <td [hidden]="detailedView == false" style="padding-top: 0; padding-bottom: 0;">
                            <div *ngIf="user.activeLoyaltyCardYaltyInstances.length > 0">
                                <div *ngFor="let activeLoyaltyCardYaltyInstance of user.activeLoyaltyCardYaltyInstances; index as j" style="height: 35px; display: flex; align-items: center;" [ngStyle]="{'border-bottom': (j + 1) < user.activeLoyaltyCardYaltyInstances.length ? '2px solid #F2F2F2' : '0px'}">
                                    <p *ngIf="activeLoyaltyCardYaltyInstance.cardValidityEndExpiry != null" style="margin: 0; width: 100%; text-align: center;"><b>{{activeLoyaltyCardYaltyInstance.cardValidityEndExpiry | date: 'yyyy.MM.dd.'}}</b><span *ngIf="timezone != 'Europe/Budapest'"><br>{{activeLoyaltyCardYaltyInstance.cardValidityEndExpiry | date: 'HH:mm'}}</span></p>
                                    <p *ngIf="activeLoyaltyCardYaltyInstance.cardValidityEndExpiry == null" style="margin: 0; width: 100%; text-align: center;">-</p>
                                </div>
                            </div>
                            <div *ngIf="user.activeLoyaltyCardYaltyInstances.length == 0">
                                <p style="margin: 0; width: 100%; text-align: center;">-</p>
                            </div>
                        </td>
                        <td [hidden]="detailedView == false" style="padding-top: 0; padding-bottom: 0;">
                            <div *ngIf="user.activeLoyaltyCardYaltyInstances.length > 0">
                                <div *ngFor="let activeLoyaltyCardYaltyInstance of user.activeLoyaltyCardYaltyInstances; index as j" style="height: 35px; display: flex; align-items: center;" [ngStyle]="{'border-bottom': (j + 1) < user.activeLoyaltyCardYaltyInstances.length ? '2px solid #F2F2F2' : '0px'}">
                                    <p style="margin: 0; width: 100%; text-align: center;">{{activeLoyaltyCardYaltyInstance.currentNrOfStamps}}/{{activeLoyaltyCardYaltyInstance.maxStamps}}</p>
                                </div>
                            </div>
                            <div *ngIf="user.activeLoyaltyCardYaltyInstances.length == 0">
                                <p style="margin: 0; width: 100%; text-align: center;">-</p>
                            </div>
                        </td>
                        <td [hidden]="detailedView == false || hasAnyLoyaltyCardWithMoreThanOneRedeem == false" style="padding-top: 0; padding-bottom: 0;">
                            <div *ngIf="user.activeLoyaltyCardYaltyInstances.length > 0">
                                <div *ngFor="let activeLoyaltyCardYaltyInstance of user.activeLoyaltyCardYaltyInstances; index as j" style="height: 35px; display: flex; align-items: center;" [ngStyle]="{'border-bottom': (j + 1) < user.activeLoyaltyCardYaltyInstances.length ? '2px solid #F2F2F2' : '0px'}">
                                    <p style="margin: 0; width: 100%; text-align: center;">{{activeLoyaltyCardYaltyInstance.notRedeemedNotFinalRewards}}</p>
                                </div>
                            </div>
                            <div *ngIf="user.activeLoyaltyCardYaltyInstances.length == 0">
                                <p style="margin: 0; width: 100%; text-align: center;">-</p>
                            </div>
                        </td>
                        <td [hidden]="detailedView == false" style="padding-top: 0; padding-bottom: 0;">
                            <div *ngIf="user.activeLoyaltyCardYaltyInstances.length > 0">
                                <div *ngFor="let activeLoyaltyCardYaltyInstance of user.activeLoyaltyCardYaltyInstances; index as j" style="height: 35px; display: flex; align-items: center; justify-content: center;" [ngStyle]="{'border-bottom': (j + 1) < user.activeLoyaltyCardYaltyInstances.length ? '2px solid #F2F2F2' : '0px'}">
                                    <p *ngIf="activeLoyaltyCardYaltyInstance.notRedeemedFinalReward == 0" style="margin: 0; width: 100%; text-align: center;">-</p>
                                    <div *ngIf="activeLoyaltyCardYaltyInstance.notRedeemedFinalReward == 1" class="tooltip" style="align-self: center;">
                                        <img style="width: 18px;" src="../imgs/ajandek_50px_ColorBlack.png">
                                        <span class="yaltytiptext" style="margin-top: 0px; margin-right: 6px; width: 200px;">
                                            A kártyához tartozik egy még aktív, beváltható végjutalom.
                                        </span>
                                    </div>  
                                </div>
                            </div>
                            <div *ngIf="user.activeLoyaltyCardYaltyInstances.length == 0">
                                <p style="margin: 0; width: 100%; text-align: center;">-</p>
                            </div>
                        </td>
                    </tr>
                </table>
            </cdk-virtual-scroll-viewport>
        </div>
    </div>
</div>