import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { DialogService } from './dialog.service';

@Injectable({
    providedIn: 'root'
})
export class SubscriptionService {

    private subscriptions: Subscriptions = {
        yalty_start_free: 'Yalty Start',
        yalty_start_paid: 'Yalty Start',
        basic: 'Design+',
        premium: 'Premium',
        custom: 'Egyedi'
    }
    private subscription = this.cookieService.get('sbcrtyp');
    private subscriptionType: keyof Subscriptions = (this.subscription.split('&')[0]) as keyof Subscriptions;
    private storesNr = parseInt(this.subscription.split('&')[2]);
    constructor(
        private cookieService: CookieService,
        private dialogService: DialogService
    ) { }

    showMaxNrOfStoreError() {
        const html = `Elérte a jelenlegi előfizetési csomagja (<b>${this.subscriptionName}</b>) 
        szerinti létrehozható üzletek maximális számát (${this.nrOfStores} db üzlet).
        Törölje ki az egyik üzletet vagy váltson magasabb előfizetési csomagra.
        <a class="orange-link" href="partner/home/partner-subscription" (click)="goToSubscription()">Az előfizetések áttekintéséhez és módosításához kattintson ide.</a>`
        const dialogRef = this.dialogService.openDialogWithHTML(html, 'Rendben', undefined, '600px');
    }

    isNewStoreCanBeAdded(storeNr: number): boolean {
        if (this.subscriptionType === 'custom' && this.storesNr === 0) return true;
        return storeNr < this.storesNr;
    }

    get subscriptionName(): string {
        return this.subscriptions[this.subscriptionType]
    }

    get nrOfStores(): number {
        return this.storesNr;
    }
}



interface Subscriptions {
    yalty_start_free: string;
    yalty_start_paid: string,
    basic: string,
    premium: string,
    custom: string
}
