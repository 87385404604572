// @ts-nocheck
angular.module('yaltyApp')
    .controller('accountDeletionRequestCtrl', function ($scope, $http, $rootScope, $state, AuthService, $mdDialog, API_PREFIX) {
        $scope.accountDeletionRequest = function() {
            $scope.DeleteInProgress = true;
            $scope.sendButtonDisabled = true;
            var settings = {};
            settings.email = $scope.email;
            $http.post(API_PREFIX.url + '/service/accountDeletionRequest', settings).then(function (result) {
                if (result.data.errorCode == 0) {
                    $mdDialog.show(
                        $mdDialog.alert()
                            .title("Sikeres fiók törlési igény beküldés / Successful account deletion request")
                            .ok('OK')
                    )
                } else if (result.data.errorCode == 1){
                    const alert = $mdDialog.alert()
                        .title("Szerver hiba történt, kérlek próbáld meg újra! / Server error. Try again later.")
                        .ok('OK')
                    $mdDialog.show(alert)
                    } else if (result.data.errorCode == 2){
                        const alert = $mdDialog.alert()
                            .title("Szerver hiba történt, kérlek próbáld meg újra! / Server error. Try again later.")
                            .ok('OK')
                        $mdDialog.show(alert)
                        };
        })
        $scope.DeleteInProgress = false;
    }})