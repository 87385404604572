import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class HttpService {

    constructor(private http: HttpClient) { }

    post<T>(apiURL: string, body: object): Observable<T> {
        return this.http.post<T>(`/api${apiURL}`, body);
    }

    get<T>(apiURL: string): Observable<T> {
        return this.http.get<T>(`/api${apiURL}`);
    }
}
