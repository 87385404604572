import { NgxMatDatetimePicker } from '@angular-material-components/datetime-picker';
import { Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AbstractControl, ControlContainer, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import * as moment from 'moment';
import { OverlayContainer } from '@angular/cdk/overlay';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-datetime-picker',
    templateUrl: './datetime-picker.component.html',
    styleUrls: ['./datetime-picker.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DatetimePickerComponent implements OnInit {

    @ViewChild('picker') picker: ElementRef;

    form: FormGroup;
    @Input() inputName: string;
    @Input() label: string;
    @Input() placeholder: string;
    @Input() date: moment.Moment;
    @Input() disabled = false;
    @Input() showSpinners = true;
    @Input() showSeconds = false;
    @Input() touchUi = false;
    @Input() enableMeridian = false;
    @Input() minDate: moment.Moment;
    @Input() minDateErrorText: string;
    @Input() maxDate: moment.Moment;
    @Input() stepHour = 1;
    @Input() stepMinute = 1;
    @Input() stepSecond = 1;
    validators: ValidatorFn[] = [];
    private subscriptions: Subscription[] = [];
    //@Input() color: ThemePalette = 'accent';

    constructor(private overlayContainer: OverlayContainer, private controlContainer: ControlContainer) {
        const overlayContainerClasses = this.overlayContainer.getContainerElement().classList;
        // if (overlayContainerClasses.contains('transparent-black-theme')) {
        //     overlayContainerClasses.remove('transparent-black-theme');
        // }
        overlayContainerClasses.add('gray-2-theme');
    }

    ngOnInit() {
        this.form = <FormGroup>this.controlContainer.control;
        if (this.minDate) this.validators.push(this.minDateValidator);
        this.input.setValidators(this.validators.concat(this.input.validator!));
    }

    minDateValidator(control: AbstractControl): { [key: string]: any } | null {
        if (control.value && new Date(control.value._d) < new Date()) {
            return { 'minDate': true };
        }
        return null;
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub) => {
            sub.unsubscribe();
        })
    }

    get input(): AbstractControl { return this.form.get(this.inputName)!; }
}


