import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, ControlContainer, FormGroup } from '@angular/forms';
import { Observable, Subscription, from, combineLatest } from 'rxjs';
import { CustomErrorStateMatcher } from 'src/app/config/error-state-matcher';
import { RadioButtonInput } from '../../interfaces/input';
import { distinctUntilChanged, filter, startWith, switchMap, tap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { GoogleMapsService } from '../../services/googe-maps.service';
import { DialogService } from 'src/app/shared/services/dialog.service';

@Component({
    selector: 'app-google-maps',
    templateUrl: './google-maps.component.html',
    styleUrls: ['./google-maps.component.scss']
})
export class GoogleMapsComponent implements OnInit {

    map: google.maps.Map;
    service: google.maps.places.PlacesService;
    infowindow: google.maps.InfoWindow;

    //@ViewChild(GoogleMap, { static: false }) map: google.maps.Map;
    @Input() showMapSettingsSameAsCo = false;
    @Input() address$: Observable<any>;
    @Input() mapManuallyRepositioned: boolean;
    @Output() mapManuallyDragedChanged = new EventEmitter<boolean>();
    @Output() mapPending = new EventEmitter<boolean>();
    initLat = 46.2491399;
    initLong = 20.1444542;
    center: google.maps.LatLngLiteral = { lat: this.initLat, lng: this.initLong };
    marker: google.maps.Marker;
    form: FormGroup;

    GPSCoordinatesAutoOrManualOptions: RadioButtonInput[] = [
        { value: false, display: 'Automatikusan, a térkép markere alapján' },
        { value: true, display: 'Manuális megadással' }];
    mapManuallyDraged = false;
    showMap = false;
    allAddressFilled = false;
    invalidAddress = false;
    mapInitialized = false;
    matcher = new CustomErrorStateMatcher();
    private subscriptions: Subscription[] = [];
    private listeners: google.maps.MapsEventListener[] = [];

    constructor(private controlContainer: ControlContainer,
        private googleMapsService: GoogleMapsService,
        private dialogService: DialogService,
        private elRef: ElementRef) { }

    ngOnInit() {
        this.map = new google.maps.Map(document.getElementById("map") as HTMLElement, {
            center: this.center,
            disableDefaultUI: true,
            zoom: 18,
            zoomControl: true,
            scrollwheel: false,
            draggable: true
        });
        this.mapManuallyDraged = this.mapManuallyRepositioned;
        // const svgMarker = {
        //     path:
        //       "M10.453 14.016l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM12 2.016q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
        //     fillColor: "blue",
        //     fillOpacity: 0.6,
        //     strokeWeight: 0,
        //     rotation: 0,
        //     scale: 2,
        //     anchor: new google.maps.Point(15, 30),
        //   };
        this.marker = new google.maps.Marker({
            position: this.center,
            //icon: svgMarker,
            map: this.map
        });
        this.marker.setMap(this.map);
        this.service = new google.maps.places.PlacesService(this.map);
        this.listeners.push(
            this.map.addListener("center_changed", () => {
                this.setMarkerPosition();
            }),
            this.map.addListener("dragend", () => {
                this.invalidAddress = false;
                this.updateCoordinates(true);
            })
        );
        this.form = <FormGroup>this.controlContainer.control;
        this.subscriptions.push(
            combineLatest([
                this.googleMapsService.addressZIP$.pipe(startWith(this.storeAddrZIP)),
                this.googleMapsService.addressCity$.pipe(startWith(this.storeAddrCity)),
                this.googleMapsService.addressStreet$.pipe(startWith(this.storeAddrStreet)),
                this.googleMapsService.addressNo$.pipe(startWith(this.storeAddrNo)),
                this.googleMapsService.autoUpdate$
            ]).pipe(
                //distinctUntilChanged(),
                tap(([zip, city, street, no]) => {
                    this.mapPending.emit(zip && city && street && no && !this.GPSCoordinatesAutoOrManual && (!this.mapManuallyDraged || !this.mapInitialized) ? true : false);
                    this.allAddressFilled = (zip && city && street && no) ? true : false
                    this.showMap = ((this.allAddressFilled && !this.GPSCoordinatesAutoOrManual) ||
                        (this.GPSCoordinatesAutoOrManual && this.latitudeCoordinate && this.longitudeCoordinate)) ? true : false
                }),
                filter(([zip, city, street, no]) =>
                    zip && city && street && no && !this.GPSCoordinatesAutoOrManual && (!this.mapManuallyDraged || !this.mapInitialized) ? true : false
                ),
                map(([zip, city, street, no]) => {
                    let address = { city, street, no };
                    return {
                        request: {
                            query: Object.values(address).join(" "),
                            fields: ["geometry"],
                        },
                        address
                    }
                }),
                switchMap(res =>
                    this.findPlace$(res.request, res.address)
                )
            ).subscribe(() => this.mapPending.emit(false)),
            this.GPSCoordinatesAutoOrManualField.valueChanges
                .subscribe(res => {
                    this.updateCoordinatesValidations(res);
                    if (!this.mapManuallyDraged || !this.mapInitialized) this.googleMapsService.updateMap();
                    if (res) { 
                        this.invalidAddress = false;
                        if (this.longitudeCoordinate && this.latitudeCoordinate) this.showMap = true;
                        if (!this.mapInitialized) {
                            this.map.setCenter({ lat: this.latitudeCoordinate || 46.2491399, lng: this.longitudeCoordinate || 20.1444542 });
                        }
                    }
                })

        );
        this.GPSCoordinatesAutoOrManualField.setValue(this.GPSCoordinatesAutoOrManual);
    }

    addressChanged(addressFieldName: string, value: string) {
        this.googleMapsService.updateMap(addressFieldName, value);
    }

    coordinatesChanged() {
        if (!this.latitudeCoordinate || !this.longitudeCoordinate) return
        this.map.setCenter({ lat: this.latitudeCoordinate, lng: this.longitudeCoordinate });
        this.map.setZoom(18);
        this.showMap = true;
    }

    setMarkerPosition() {
        this.marker.setPosition(this.map.getCenter());
    }

    updateCoordinates(mapManuallyDraged: boolean) {
        const lat = this.map.getCenter().lat();
        const lng = this.map.getCenter().lng();
        this.latitudeCoordinateField.setValue(lat === this.initLat ? null : this.map.getCenter().lat());
        this.longitudeCoordinateField.setValue(lng === this.initLong ? null : this.map.getCenter().lng());
        this.mapManuallyDraged = mapManuallyDraged;
        this.mapManuallyDragedChanged.emit(mapManuallyDraged);
        if (mapManuallyDraged === true) this.GPSCoordinatesAutoOrManualField.setValue(false, { emitEvent: false })
        this.updateCoordinatesValidations(!mapManuallyDraged);
    }

    updateCoordinatesValidations(manual: boolean) {
        if (manual && this.GPSCoordinatesAutoOrManual) {
            this.form.controls.latitudeCoordinate.enable();
            this.form.controls.longitudeCoordinate.enable();
        }
        else {
            this.form.controls.latitudeCoordinate.disable();
            this.form.controls.longitudeCoordinate.disable();
        }
    }

    findPlace$(request: google.maps.places.FindPlaceFromQueryRequest, address: Address): Observable<void> {
        if (!this.mapInitialized && this.latitudeCoordinate && this.longitudeCoordinate) {
            return from(this.findPlaceByCoordinates());
        } else {
            return from(this.findPlaceByAddress(request, address));
        }

    }

    findPlaceByCoordinates() {
        return new Promise<void>((resolve) => {
            if (!this.latitudeCoordinate || !this.longitudeCoordinate) return
            this.map.setCenter({ lat: this.latitudeCoordinate, lng: this.longitudeCoordinate });
            this.mapInitialized = true;
            this.googleMapsService.updateMap();
            resolve();
        })
    }

    findPlaceByAddress(request: google.maps.places.FindPlaceFromQueryRequest, address: Address, isRetry?: boolean): Promise<void> {
        return new Promise((resolve, reject) => {
            this.service.findPlaceFromQuery(
                request,
                (
                    results: google.maps.places.PlaceResult[] | null,
                    status: google.maps.places.PlacesServiceStatus
                ) => {
                    this.mapInitialized = true;
                    if (status === google.maps.places.PlacesServiceStatus.OK && results) {
                        if (!isRetry) this.invalidAddress = false;
                        this.map.setCenter(results[0].geometry!.location!);
                        this.map.setZoom(18);
                        this.updateCoordinates(false);
                        resolve()
                    } else {
                        this.invalidAddress = true;
                        if (address.hasOwnProperty('no')) {
                            delete address.no;
                        } else if (address.hasOwnProperty('street')) {
                            delete address.street;
                        } else {
                            reject();
                            return;
                        }
                        const addressData = {
                            query: Object.values(address).join(" "),
                            fields: ["geometry"],
                        };
                        this.findPlaceByAddress(addressData, address, true);
                    }
                }
            );
        })
    }

    resetMapToStoreAddress() {
        if (!this.allAddressFilled) {
            this.dialogService.openDialog('Hiányos cím adatok',
                'Töltse ki az összes cím mezőt, majd nyomja meg a gombot újra.', 'Ok', undefined);
        } else {
            this.mapManuallyDraged = false;
            this.googleMapsService.updateMap();
            this.GPSCoordinatesAutoOrManualField.setValue(false);
        }
    }

    get latitudeCoordinate(): number | null { return this.latitudeCoordinateField.value; }
    get longitudeCoordinate(): number | null { return this.longitudeCoordinateField.value; }
    get GPSCoordinatesAutoOrManual(): boolean { return this.GPSCoordinatesAutoOrManualField.value; }
    get storeAddrZIP(): string | null { return this.storeAddrZIPField.value; }
    get storeAddrCity(): string | null { return this.storeAddrCityField.value; }
    get storeAddrStreet(): string | null { return this.storeAddrStreetField.value; }
    get storeAddrNo(): string | null { return this.storeAddrNoField.value; }

    get latitudeCoordinateField(): AbstractControl { return this.form.get('latitudeCoordinate')!; }
    get longitudeCoordinateField(): AbstractControl { return this.form.get('longitudeCoordinate')!; }
    get GPSCoordinatesAutoOrManualField(): AbstractControl { return this.form.get('GPSCoordinatesAutoOrManual')!; }
    get storeAddrZIPField(): AbstractControl { return this.form.get('storeAddrZIP')!; }
    get storeAddrCityField(): AbstractControl { return this.form.get('storeAddrCity')!; }
    get storeAddrStreetField(): AbstractControl { return this.form.get('storeAddrStreet')!; }
    get storeAddrNoField(): AbstractControl { return this.form.get('storeAddrNo')!; }

    ngOnDestroy() {
        this.subscriptions.forEach((sub) => {
            sub.unsubscribe();
        })
        this.listeners.forEach((listener) => {
            google.maps.event.removeListener(listener);
        })
    }

}

interface Address {
    city: string | null;
    street?: string | null;
    no?: string | null;
}
