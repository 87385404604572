import { NgxMatDateFormats } from "@angular-material-components/datetime-picker";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import * as moment from "moment";

export const DATE_TIME_FORMAT: NgxMatDateFormats = {
    parse: {
        dateInput: "LL, LTS"
      },
      display: {
        dateInput: "YYYY. MMMM D. HH:mm",
        monthYearLabel: "MMM YYYY",
        dateA11yLabel: "LL",
        monthYearA11yLabel: "MMMM YYYY"
    }
};

export class CustomDateTimeAdapter extends MomentDateAdapter {
    getDayOfWeekNames(style: 'long' | 'short' | 'narrow') {
      return ['V', 'H', 'K', 'S', 'C', 'P', 'S'];
    }

    getHour(date: moment.Moment): number {
        return date.hours();
    }
    
    getMinute(date: moment.Moment): number {
        return date.minutes();
    }
    
    getSecond(date: moment.Moment): number {
        return date.seconds();
    }
    
    setHour(date: moment.Moment, value: number): void {
        date.hours(value);
    }
    
    setMinute(date: moment.Moment, value: number): void {
        date.minutes(value);
    }
    
    setSecond(date: moment.Moment, value: number): void {
        date.seconds(value);
    }
    
    copyTime(toDate: moment.Moment, fromDate: moment.Moment): void {
        this.setHour(toDate, this.getHour(fromDate));
        this.setMinute(toDate, this.getMinute(fromDate));
        this.setSecond(toDate, this.getSecond(fromDate));
    }
    
    setTimeByDefaultValues(date: moment.Moment, defaultTime: number[]): void {
        if (!Array.isArray(defaultTime)) {
            throw Error('@Input DefaultTime should be an array');
        }
        this.setHour(date, defaultTime[0] || 0);
        this.setMinute(date, defaultTime[1] || 0);
        this.setSecond(date, defaultTime[2] || 0);
    }
    
    compareDateWithTime(first: moment.Moment, second: moment.Moment, showSeconds?: boolean): number {
        let res = super.compareDate(first, second) ||
            this.getHour(first) - this.getHour(second) ||
            this.getMinute(first) - this.getMinute(second);
        if (showSeconds) {
            res = res || this.getSecond(first) - this.getSecond(second);
        }
        return res;
    }
    
    isSameTime(a: moment.Moment, b: moment.Moment): boolean {
        if (a == null || b == null) {
            return true;
        }
        return this.getHour(a) === this.getHour(b)
            && this.getMinute(a) === this.getMinute(b)
            && this.getSecond(a) === this.getSecond(b);
    }
}