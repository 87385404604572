import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, ControlContainer, FormGroup } from '@angular/forms';
import { RadioButtonInput } from '../../interfaces/input';

@Component({
    selector: 'app-radio-button',
    templateUrl: './radio-button.component.html',
    styleUrls: ['./radio-button.component.scss']
})
export class RadioButtonComponent implements OnInit {

    form: FormGroup;
    @Input() inputName: string;
    @Input() objArray: RadioButtonInput[];

    constructor(private controlContainer: ControlContainer) { }

    ngOnInit() {
        this.form = <FormGroup>this.controlContainer.control;

    }

    get input(): AbstractControl { return this.form.get(this.inputName)!; }

}
