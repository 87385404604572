import { SelectInput } from '../interfaces/input';

export class MultipleSelect {

    allOption = 'all';
    allOptionSelected = false;

    constructor() { }
    
    getOptions(options: SelectInput[], selectedOptions: (string|number)[],
        allOptionSelected: boolean): (string|number)[] {
        if (!allOptionSelected && this.allOptionSelected) {
            this.allOptionSelected = false;
            return [];
        } else if (allOptionSelected && !this.allOptionSelected) {
            this.allOptionSelected = true;
            const allOptions = options.map(o => o.value);
            return [this.allOption, ...allOptions];
        } else if (allOptionSelected && selectedOptions.length - 1 !== options.length) {
            this.allOptionSelected = false;
            const currentOptions = selectedOptions;
            currentOptions.shift();
            return [...currentOptions];
        } else if (!allOptionSelected && selectedOptions.length === options.length) {
            this.allOptionSelected = true;
            return [this.allOption, ...selectedOptions];
        } else {
            this.allOptionSelected = false;
            return selectedOptions;
        }
    }
}
