<mat-form-field [formGroup]="form" appearance="standard">
    <mat-label>{{label}}</mat-label>
    <mat-select [formControlName]="inputName" multiple [required]="required">
        <mat-select-trigger>
            {{selectDisplayValue}}
        </mat-select-trigger>
        <mat-option [value]="'all'" class="option-header">{{allOptionDisplayName}}</mat-option>
        <mat-option *ngFor="let obj of objArray" [value]="obj.value">{{obj.display}}</mat-option>
    </mat-select>
    <mat-error *ngIf="input.hasError('required')">Mező kitöltése kötelező!</mat-error>
</mat-form-field>
