import { Injectable } from '@angular/core';
import { SelectInput } from '../interfaces/input';

@Injectable({
    providedIn: 'root'
})
export class MultipleSelectService {

    constructor() { }

    getDisplayValue(options: (string|number)[]): string | undefined {
        const length = options ? options.length : 0;
        if (options === undefined || length == 0 || options.includes('all')) return;
        
        return options.join(', ');
    }
}
