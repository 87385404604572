<div class="loyalty-card-yalty-dashboard-component default-srolling-design" style="max-width: 97%;">
    <div class="state-and-filters-container">
        <h2>{{ currentState }}
            <i class="fa fa-chevron-right" style="font-size:80%"></i> {{ currentSubState }}
            <i class="fa fa-info-circle tooltip" style="margin: 0; align-self: center;">
                <div class="yaltytiptextright" style="margin-bottom: -64px; width: 500px">
                    <p>Ez az oldal egy áttekintést ad hűségkártyái és fogyasztói legfontosabb kimutatásaival (KPI statisztikák, mutatók). Az oldalra ránézve rögtön látja külön az elmúlt 30 nap aktivitását és a teljes élettörténeti aktivitást is. A mutatók között látja a kártyái és fogyasztói számát, a kártyák esetén alábontva külön a kártyák állapotai szerinti számosságokat (aktív, lejárt stb. kártyák), kártyák és fogyasztók esetén is látszódnak a már meglévő illetve külön az új kártya és fogyasztó számok, arányok. Több kártya és üzlet esetén összesítve és az adott kártyákra/üzletekre nézve is szűrhetők a kimutatások.
                    </p>
                </div>
            </i>
        </h2>
        <form [formGroup]="filtersForm" class="filters-form" [hidden]="((adminUserType=='' || adminUserType=='executive') && currentSubType != 'Egyedi' && currentSubType != 'Premium') || (loyaltyCardTemplates.length < 2 && stores.length < 2 && (allStoresResult == null || allStoresResult.wasStampOrRedeemInDeletedStore == false) && (allStoresResult == null || allStoresResult.wasStampOrRedeemWithoutStore == false))">
            <app-multiple-select-all inputName="loyaltyCardFilter" label="Hűségkártya" [objArray]="loyaltyCardTemplates"
                class="gray-1-theme" allOptionDisplayName="Mind">
            </app-multiple-select-all>
            <mat-form-field appearance="standard">
                <mat-label style="line-height: 1.2;">Üzlet</mat-label>
                <mat-select formControlName="storeFilter" multiple required>
                    <mat-select-trigger>{{storeFilterDisplayValue}}</mat-select-trigger>
                    <mat-checkbox color="primary" class="in-select option-separated"
                        [formControl]="activeStoreFilterField" matRipple>Csak aktív üzletek megjelenítése</mat-checkbox>
                    <mat-option [value]="'all'">Mind</mat-option>
                    <mat-option [value]="'deleted'"
                        *ngIf="allStoresResult && allStoresResult.wasStampOrRedeemInDeletedStore">Törölt üzletek
                    </mat-option>
                    <mat-option [value]="'virtual'"
                        *ngIf="allStoresResult && allStoresResult.wasStampOrRedeemWithoutStore && !activeStoreFilter">
                        Online vagy telefonos rendelések (Nincs üzlet)</mat-option>
                    <mat-option *ngFor="let store of stores" [value]="store.value">{{store.display}}</mat-option>
                </mat-select>
                <mat-error *ngIf="storeFilterField.hasError('required')">Mező kitöltése kötelező!</mat-error>
            </mat-form-field>
            <button mat-flat-button color="primary" (click)="update(true)" class="update-button" style="border-radius: 6px;"
                [disabled]="initApiCallFinished == false || waitingForAPIResult || (adminUserType=='' && currentSubType != 'Egyedi' && currentSubType != 'Premium') || (adminUserType!='' && adminUserType!='executive') || ((adminUserType=='' || adminUserType=='executive') && (loyaltyCardTemplates.length == 0 || stores.length == 0) && (currentSubType == 'Egyedi' || currentSubType == 'Premium'))">
                <span [hidden]="initApiCallFinished == false || waitingForAPIResult">Frissítés</span>
                <img *ngIf="initApiCallFinished == false || waitingForAPIResult" class="loading" src="imgs/loading_indicator.gif">
            </button>
        </form>
    </div>

    <div *ngIf="(adminUserType=='' || adminUserType=='executive') && currentSubType != 'Egyedi' && currentSubType != 'Premium'">
            <div class="stampOrRedeemHappenedEverErrorMessage" style="background-color:rgb(226,240,217); border-radius: 9px;">
                <img src="../imgs/warning_icon.svg" height="17.5">
                <p style="color:rgb(56,87,35);">Az adatok megtekintéséhez <b>{{reqSubType}}</b> előfizetés szükséges.</p>
        </div>
        <p style="max-width: 620px; width: 100%; color: darkgray;"> Önnek jelenleg <b>{{currentSubType}}</b> előfizetése van. <a href="partner/home/partner-subscription" style="color: #00AEEF; text-decoration: none;">Az előfizetések áttekintéséhez és módosításához kattintson ide.</a></p>
    </div>
    
    <div *ngIf="adminUserType!='' && adminUserType!='executive'" class="stat-container">
        <p>Adatbiztonsági okokból Yalty admin nem látja és nem kezelheti partnereink felhasználóinak adatait.</p>
    </div>

    <div *ngIf="initApiCallFinished == true && !stampOrRedeemHappenedEver && (currentSubType == 'Egyedi' || currentSubType == 'Premium') && (adminUserType=='' || adminUserType=='executive')">
        <div class="stampOrRedeemHappenedEverErrorMessage" style="background-color:rgb(226,240,217); border-radius: 9px;">
                <img src="../imgs/warning_icon.svg" height="25">
                <p style="color:rgb(56,87,35);">Még nincs aktív hűségkártyája vagy a kártyát még egy ügyfele sem használta.</p>
        </div>
        <p style="max-width: 620px; width: 100%; color: darkgray;">A Dashboard akkor jelenik meg, ha legalább egy kártyán legalább egy pecsételés történt.</p>
    </div>

    <div *ngIf="initApiCallFinished == true && (adminUserType=='' || adminUserType=='executive') && (!stampOrRedeemHappenedEver || (currentSubType != 'Egyedi' && currentSubType != 'Premium'))">
       <img class="sampleViewOfDashboardOrTable" 
          src="../imgs/yalty_weblap_partneri_dashboard_20250224.png" 
          alt="Dashboard preview" 
          (click)="openFullscreen('imgs/yalty_weblap_partneri_dashboard_20250224.png')">

        <!-- Teljes képernyős nézet -->
        <div 
          *ngIf="isFullscreen" 
          class="fullscreen-model" 
          (click)="closeFullscreen()">
          <img [src]="fullscreenImageSrc" alt="Fullscreen preview">
        </div>
    </div>
    

    <div *ngIf="(currentSubType == 'Egyedi' || currentSubType == 'Premium') && (initApiCallFinished == false || waitingForAPIResult)" style="width: 100%; text-align: -webkit-center;">
        <mat-spinner [diameter]="40"></mat-spinner>
    </div>

    <div *ngIf="showLCYDashboardStats && ((adminUserType=='' || adminUserType=='executive') && (currentSubType == 'Egyedi' || currentSubType == 'Premium')) && dashboardDisplayable == false" class="stat-container">
        <p>A beállított szűrések mellett nincs megjeleníthető adat.</p>
    </div>

    <div *ngIf="showLCYDashboardStats && dashboardDisplayable == true && ((adminUserType=='' || adminUserType=='executive') && (currentSubType == 'Egyedi' || currentSubType == 'Premium'))" class="stat-container">
        <div class="section-title" style="margin-top: 75px">Elmúlt 30 nap mutatói</div>
        <div class="section" style="height: auto; padding-top: 15px; padding-bottom: 15px;">
            <table style="width: 720px; border-spacing: 0;">
                <tr>
                    <td style="width: 25%; padding-top: 10px; padding-bottom: 8px;">
                        <div style="width: fit-content; display: block; margin-left: auto; margin-right: auto;">
                            <div style="display: flex;">
                                <div class="tooltip">
                                    <span><p class="stat-title">Aktív ügyfelek</p></span>
                                    <span class="yaltytiptextbottom4" style="margin-top: 10px; margin-right: -220px; width: 300px;">
                                        <b>Azon egyedi ügyfelek száma, akik az elmúlt 30 napban aktívak voltak.</b><span style="color: #00AEEF;" *ngIf="activeFilterSet == true"><br><br><b>Aktív szűrés van beállítva hűségkártyára vagy üzletre</b>, így a mutató értéke a szűrés szerint értendő.</span>
                                    </span>
                                </div>
                            </div>
                            <div style="display: flex;">
                                <div class="tooltip">
                                    <span><p style="font-weight: bold; font-size: 32px; margin-top: 0; margin-bottom: 0; margin-right: 5px;">{{lCYDashboardStats.nrOfUsersWithin30Days | number}}</p></span>
                                    <span class="yaltytiptextbottom4" style="margin-top: 15px; margin-right: -260px; width: 300px;">
                                        <b>Azon egyedi ügyfelek száma, akik az elmúlt 30 napban aktívak voltak.</b><span style="color: #00AEEF;" *ngIf="activeFilterSet == true"><br><br><b>Aktív szűrés van beállítva hűségkártyára vagy üzletre</b>, így a mutató értéke a szűrés szerint értendő.</span>
                                    </span>
                                </div>
                                <div class="tooltip" *ngIf="lCYDashboardStats.nrOfUsersWithin30Days > lCYDashboardStats.nrOfUsersInThePrevious30Days">
                                    <span style="align-self: center; margin-left: 5px;"><p class="increase" style="margin-top: -10px"><span style="vertical-align: middle; font-size: 6px;">&#9650;</span> {{(lCYDashboardStats.nrOfUsersInThePrevious30Days > 0 ? lCYDashboardStats.nrOfUsersWithin30Days /
                                        lCYDashboardStats.nrOfUsersInThePrevious30Days * 100 - 100 : 100.00) | number : '.2-2'}}%</p></span>
                                    <span class="yaltytiptextbottom2" style="margin-top: 15px; margin-right: -440px;">
                                        <b>Ügyfélszám változás az elmúlt 30 napot megelőző 30 naphoz képest.</b> Az elmúlt 30 napot megelőző 30 napban <b>{{lCYDashboardStats.nrOfUsersInThePrevious30Days}}</b> ügyfél használta hűségkártyáit.<span style="color: #00AEEF;" *ngIf="activeFilterSet == true"><br><br><b>Aktív szűrés van beállítva hűségkártyára vagy üzletre</b>, így a mutató értéke a szűrés szerint értendő.</span>
                                    </span>        
                                </div>
                                <div class="tooltip" *ngIf="lCYDashboardStats.nrOfUsersWithin30Days < lCYDashboardStats.nrOfUsersInThePrevious30Days">
                                    <span style="align-self: center; margin-left: 5px;"><p class="decrease" style="margin-top: -10px"><span style="vertical-align: middle; font-size: 6px;">&#9660;</span> {{(lCYDashboardStats.nrOfUsersInThePrevious30Days > 0 ? (lCYDashboardStats.nrOfUsersWithin30Days /
                                        lCYDashboardStats.nrOfUsersInThePrevious30Days * 100 - 100) * -1 : 100.00) | number : '.2-2'}}%</p></span>
                                    <span class="yaltytiptextbottom2" style="margin-top: 15px; margin-right: -440px;">
                                        <b>Ügyfélszám változás az elmúlt 30 napot megelőző 30 naphoz képest.</b> Az elmúlt 30 napot megelőző 30 napban <b>{{lCYDashboardStats.nrOfUsersInThePrevious30Days}}</b> ügyfél használta hűségkártyáit.<span style="color: #00AEEF;" *ngIf="activeFilterSet == true"><br><br><b>Aktív szűrés van beállítva hűségkártyára vagy üzletre</b>, így a mutató értéke a szűrés szerint értendő.</span>
                                    </span>
                                </div>
                                <div class="tooltip" *ngIf="lCYDashboardStats.nrOfUsersWithin30Days == lCYDashboardStats.nrOfUsersInThePrevious30Days">
                                    <span style="align-self: center; margin-left: 5px;"><p class="equal" style="margin-top: -10px">0.00%</p></span>
                                    <span class="yaltytiptextbottom2" style="margin-top: 15px; margin-right: -440px;">
                                        <b>Ügyfélszám változás az elmúlt 30 napot megelőző 30 naphoz képest.</b> Az elmúlt 30 napot megelőző 30 napban <b>{{lCYDashboardStats.nrOfUsersInThePrevious30Days}}</b> ügyfél használta hűségkártyáit.<span style="color: #00AEEF;" *ngIf="activeFilterSet == true"><br><br><b>Aktív szűrés van beállítva hűségkártyára vagy üzletre</b>, így a mutató értéke a szűrés szerint értendő.</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td style="width: 25%; border-right-style: solid; border-right-color: white; border-right-width: 1px; padding-top: 10px; padding-bottom: 8px;">
                        <div style="width: fit-content; display: block; margin-left: auto; margin-right: auto;">
                            <div style="display: flex;">
                                <div class="tooltip">
                                    <p class="stat-title">Új ügyfelek</p>
                                    <span class="yaltytiptextbottom4" style="margin-top: 10px; margin-right: -230px; width: 300px;">
                                        <b>Új ügyfelek száma az elmúlt 30 napban.</b><span style="color: #00AEEF;" *ngIf="activeFilterSet == true"><br><br><b>Aktív szűrés van beállítva hűségkártyára vagy üzletre</b>, így a mutató értéke a szűrés szerint értendő.</span>
                                    </span>
                                </div>
                            </div>
                            <div style="display: flex;">
                                <div class="tooltip">
                                    <span><p style="font-weight: bold; font-size: 32px; margin-top: 0; margin-bottom: 0; margin-right: 5px;">{{lCYDashboardStats.nrOfNewUsersWithin30Days | number}}</p></span>
                                    <span class="yaltytiptextbottom4" style="margin-top: 15px; margin-right: -260px; width: 300px;">
                                        <b>Új ügyfelek száma az elmúlt 30 napban.</b><span style="color: #00AEEF;" *ngIf="activeFilterSet == true"><br><br><b>Aktív szűrés van beállítva hűségkártyára vagy üzletre</b>, így a mutató értéke a szűrés szerint értendő.</span>
                                    </span>
                                </div>
                                <div class="tooltip" *ngIf="lCYDashboardStats.nrOfNewUsersWithin30Days > lCYDashboardStats.nrOfNewUsersInThePrevious30Days">
                                    <span style="align-self: center; margin-left: 5px;"><p class="increase" style="margin-top: -10px"><span style="vertical-align: middle; font-size: 6px;">&#9650;</span> {{(lCYDashboardStats.nrOfNewUsersInThePrevious30Days > 0 ? lCYDashboardStats.nrOfNewUsersWithin30Days /
                                        lCYDashboardStats.nrOfNewUsersInThePrevious30Days * 100 - 100 : 100.00) | number : '.2-2'}}%</p></span>
                                    <span class="yaltytiptextbottom2" style="margin-top: 15px; margin-right: -440px;">
                                        <b>Új ügyfelek számának változása az elmúlt 30 napot megelőző 30 naphoz képest.</b> Az elmúlt 30 napot megelőző 30 napban <b>{{lCYDashboardStats.nrOfNewUsersInThePrevious30Days}}</b> új ügyfél használta hűségkártyáit.<span style="color: #00AEEF;" *ngIf="activeFilterSet == true"><br><br><b>Aktív szűrés van beállítva hűségkártyára vagy üzletre</b>, így a mutató értéke a szűrés szerint értendő.</span>
                                    </span>
                                </div>
                                <div class="tooltip" *ngIf="lCYDashboardStats.nrOfNewUsersWithin30Days < lCYDashboardStats.nrOfNewUsersInThePrevious30Days">
                                    <span style="align-self: center; margin-left: 5px;"><p class="decrease" style="margin-top: -10px"><span style="vertical-align: middle; font-size: 6px;">&#9660;</span> {{(lCYDashboardStats.nrOfNewUsersInThePrevious30Days > 0 ? (lCYDashboardStats.nrOfNewUsersWithin30Days /
                                        lCYDashboardStats.nrOfNewUsersInThePrevious30Days * 100 - 100) * -1 : 100.00) | number : '.2-2'}}%</p></span>
                                    <span class="yaltytiptextbottom2" style="margin-top: 15px; margin-right: -440px;">
                                        <b>Új ügyfelek számának változása az elmúlt 30 napot megelőző 30 naphoz képest.</b> Az elmúlt 30 napot megelőző 30 napban <b>{{lCYDashboardStats.nrOfNewUsersInThePrevious30Days}}</b> új ügyfél használta hűségkártyáit.<span style="color: #00AEEF;" *ngIf="activeFilterSet == true"><br><br><b>Aktív szűrés van beállítva hűségkártyára vagy üzletre</b>, így a mutató értéke a szűrés szerint értendő.</span>
                                    </span>
                                </div>
                                <div class="tooltip" *ngIf="lCYDashboardStats.nrOfNewUsersWithin30Days == lCYDashboardStats.nrOfNewUsersInThePrevious30Days">
                                    <span style="align-self: center; margin-left: 5px;"><p class="equal" style="margin-top: -10px">0.00%</p></span>
                                    <span class="yaltytiptextbottom2" style="margin-top: 15px; margin-right: -440px;">
                                        <b>Új ügyfelek számának változása az elmúlt 30 napot megelőző 30 naphoz képest.</b> Az elmúlt 30 napot megelőző 30 napban <b>{{lCYDashboardStats.nrOfNewUsersInThePrevious30Days}}</b> új ügyfél használta hűségkártyáit.<span style="color: #00AEEF;" *ngIf="activeFilterSet == true"><br><br><b>Aktív szűrés van beállítva hűségkártyára vagy üzletre</b>, így a mutató értéke a szűrés szerint értendő.</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td style="width: 25%; border-left-style: solid; border-left-color: white; border-left-width: 1px;  padding-top: 10px; padding-bottom: 8px;">
                        <div style="width: fit-content; display: block; margin-left: auto; margin-right: auto;">
                            <div style="display: flex;">
                                <div class="tooltip">
                                    <p class="stat-title">Aktív kártyák</p>
                                    <span class="yaltytiptextbottom4" style="margin-top: 10px; margin-right: -230px; width: 300px;">
                                        <b>Azon egyedi kártyák száma, amelyeket az elmúlt 30 napban használtak.</b><span style="color: #00AEEF;" *ngIf="activeFilterSet == true"><br><br><b>Aktív szűrés van beállítva hűségkártyára vagy üzletre</b>, így a mutató értéke a szűrés szerint értendő.</span>
                                    </span>
                                </div>
                            </div>
                            <div style="display: flex;">
                                <div class="tooltip">
                                    <span><p style="font-weight: bold; font-size: 32px; margin-top: 0; margin-bottom: 0; margin-right: 5px;">{{lCYDashboardStats.nrOfInstancesWithin30Days | number}}</p></span>
                                    <span class="yaltytiptextbottom4" style="margin-top: 15px; margin-right: -260px; width: 300px;">
                                        <b>Azon egyedi kártyák száma, amelyeket az elmúlt 30 napban használtak</b><span style="color: #00AEEF;" *ngIf="activeFilterSet == true"><br><br><b>Aktív szűrés van beállítva hűségkártyára vagy üzletre</b>, így a mutató értéke a szűrés szerint értendő.</span>
                                    </span>
                                </div>
                                <div class="tooltip" *ngIf="lCYDashboardStats.nrOfInstancesWithin30Days > lCYDashboardStats.nrOfInstancesInThePrevious30Days">
                                    <span style="align-self: center; margin-left: 5px;"><p class="increase" style="margin-top: -10px"><span style="vertical-align: middle; font-size: 6px;">&#9650;</span> {{(lCYDashboardStats.nrOfInstancesInThePrevious30Days > 0 ? lCYDashboardStats.nrOfInstancesWithin30Days /
                                        lCYDashboardStats.nrOfInstancesInThePrevious30Days * 100 - 100 : 100.00) | number : '.2-2'}}%</p></span>
                                    <span class="yaltytiptextbottom2" style="margin-top: 15px; margin-right: -440px;">
                                        <b>Kártyaszám változás az elmúlt 30 napot megelőző 30 naphoz képest.</b> Az elmúlt 30 napot megelőző 30 napban <b>{{lCYDashboardStats.nrOfInstancesInThePrevious30Days}}</b> kártya volt használtban.<span style="color: #00AEEF;" *ngIf="activeFilterSet == true"><br><br><b>Aktív szűrés van beállítva hűségkártyára vagy üzletre</b>, így a mutató értéke a szűrés szerint értendő.</span>
                                    </span>
                                </div>
                                <div class="tooltip" *ngIf="lCYDashboardStats.nrOfInstancesWithin30Days < lCYDashboardStats.nrOfInstancesInThePrevious30Days">
                                    <span style="align-self: center; margin-left: 5px;"><p class="decrease" style="margin-top: -10px"><span style="vertical-align: middle; font-size: 6px;">&#9660;</span> {{(lCYDashboardStats.nrOfInstancesInThePrevious30Days > 0 ? (lCYDashboardStats.nrOfInstancesWithin30Days /
                                        lCYDashboardStats.nrOfInstancesInThePrevious30Days * 100 - 100) * -1 : 100.00) | number : '.2-2'}}%</p></span>
                                    <span class="yaltytiptextbottom2" style="margin-top: 15px; margin-right: -440px;">
                                        <b>Kártyaszám változás az elmúlt 30 napot megelőző 30 naphoz képest.</b> Az elmúlt 30 napot megelőző 30 napban <b>{{lCYDashboardStats.nrOfInstancesInThePrevious30Days}}</b> kártya volt használtban.<span style="color: #00AEEF;" *ngIf="activeFilterSet == true"><br><br><b>Aktív szűrés van beállítva hűségkártyára vagy üzletre</b>, így a mutató értéke a szűrés szerint értendő.</span>
                                    </span>
                                </div>
                                <div class="tooltip" *ngIf="lCYDashboardStats.nrOfInstancesWithin30Days == lCYDashboardStats.nrOfInstancesInThePrevious30Days">
                                    <span style="align-self: center; margin-left: 5px;"><p class="equal" style="margin-top: -10px">0.00%</p></span>
                                    <span class="yaltytiptextbottom2" style="margin-top: 15px; margin-right: -440px;">
                                        <b>Kártyaszám változás az elmúlt 30 napot megelőző 30 naphoz képest.</b> Az elmúlt 30 napot megelőző 30 napban <b>{{lCYDashboardStats.nrOfInstancesInThePrevious30Days}}</b> kártya volt használtban.<span style="color: #00AEEF;" *ngIf="activeFilterSet == true"><br><br><b>Aktív szűrés van beállítva hűségkártyára vagy üzletre</b>, így a mutató értéke a szűrés szerint értendő.</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td style="width: 25%; padding-top: 10px; padding-bottom: 8px;">
                        <div style="width: fit-content; display: block; margin-left: auto; margin-right: auto;">
                            <div style="display: flex;">
                                <div class="tooltip">
                                    <p class="stat-title">Új kártyák</p>
                                    <span class="yaltytiptextbottom" style="margin-top: 15px; margin-right: -5px; width: 300px;">
                                        <b>Új kártyák száma az elmúlt 30 napban.</b><span style="color: #00AEEF;" *ngIf="activeFilterSet == true"><br><br><b>Aktív szűrés van beállítva hűségkártyára vagy üzletre</b>, így a mutató értéke a szűrés szerint értendő.</span>
                                    </span>
                                </div>
                            </div>
                            <div style="display: flex;">
                                <div class="tooltip">
                                    <span><p style="font-weight: bold; font-size: 32px; margin-top: 0; margin-bottom: 0; margin-right: 5px;">{{lCYDashboardStats.nrOfNewInstancesWithin30Days | number}}</p></span>
                                    <span class="yaltytiptextbottom" style="margin-top: 15px; margin-right: -25px; width: 300px;">
                                        <b>Új kártyák száma az elmúlt 30 napban.</b><span style="color: #00AEEF;" *ngIf="activeFilterSet == true"><br><br><b>Aktív szűrés van beállítva hűségkártyára vagy üzletre</b>, így a mutató értéke a szűrés szerint értendő.</span>
                                    </span>
                                </div>
                                <div class="tooltip" *ngIf="lCYDashboardStats.nrOfNewInstancesWithin30Days > lCYDashboardStats.nrOfNewInstancesInThePrevious30Days">
                                    <span style="align-self: center; margin-left: 5px;"><p class="increase" style="margin-top: -10px"><span style="vertical-align: middle; font-size: 6px;">&#9650;</span> {{(lCYDashboardStats.nrOfNewInstancesInThePrevious30Days > 0 ? lCYDashboardStats.nrOfNewInstancesWithin30Days /
                                        lCYDashboardStats.nrOfNewInstancesInThePrevious30Days * 100 - 100 : 100.00) | number : '.2-2'}}%</p></span>
                                    <span class="yaltytiptextbottom" style="margin-top: 15px; margin-right: -10px;">
                                        <b>Új kártyák számának változása az elmúlt 30 napot megelőző 30 naphoz képest.</b> Az elmúlt 30 napot megelőző 30 napban <b>{{lCYDashboardStats.nrOfNewInstancesInThePrevious30Days}}</b> új kártya volt használatban.<span style="color: #00AEEF;" *ngIf="activeFilterSet == true"><br><br><b>Aktív szűrés van beállítva hűségkártyára vagy üzletre</b>, így a mutató értéke a szűrés szerint értendő.</span>
                                    </span>
                                </div>
                                <div class="tooltip" *ngIf="lCYDashboardStats.nrOfNewInstancesWithin30Days < lCYDashboardStats.nrOfNewInstancesInThePrevious30Days">
                                    <span style="align-self: center; margin-left: 5px;"><p class="decrease" style="margin-top: -10px"><span style="vertical-align: middle; font-size: 6px;">&#9660;</span> {{(lCYDashboardStats.nrOfNewInstancesInThePrevious30Days > 0 ? (lCYDashboardStats.nrOfNewInstancesWithin30Days /
                                        lCYDashboardStats.nrOfNewInstancesInThePrevious30Days * 100 - 100) * -1 : 100.00) | number : '.2-2'}}%</p></span>
                                    <span class="yaltytiptextbottom" style="margin-top: 15px; margin-right: -10px;">
                                        <b>Új kártyák számának változása az elmúlt 30 napot megelőző 30 naphoz képest.</b> Az elmúlt 30 napot megelőző 30 napban <b>{{lCYDashboardStats.nrOfNewInstancesInThePrevious30Days}}</b> új kártya volt használatban.<span style="color: #00AEEF;" *ngIf="activeFilterSet == true"><br><br><b>Aktív szűrés van beállítva hűségkártyára vagy üzletre</b>, így a mutató értéke a szűrés szerint értendő.</span>
                                    </span>
                                </div>
                                <div class="tooltip" *ngIf="lCYDashboardStats.nrOfNewInstancesWithin30Days == lCYDashboardStats.nrOfNewInstancesInThePrevious30Days">
                                    <span style="align-self: center; margin-left: 5px;"><p class="equal" style="margin-top: -10px">0.00%</p></span>
                                    <span class="yaltytiptextbottom" style="margin-top: 15px; margin-right: -10px;">
                                        <b>Új kártyák számának változása az elmúlt 30 napot megelőző 30 naphoz képest.</b> Az elmúlt 30 napot megelőző 30 napban <b>{{lCYDashboardStats.nrOfNewInstancesInThePrevious30Days}}</b> új kártya volt használatban.<span style="color: #00AEEF;" *ngIf="activeFilterSet == true"><br><br><b>Aktív szűrés van beállítva hűségkártyára vagy üzletre</b>, így a mutató értéke a szűrés szerint értendő.</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            </table>
        </div>
        <div class="section-title" style="margin-top: 60px">Teljes időszak összesített mutatói</div>
        <div class="section" style="height: auto; background-color: #d9d9d9;">
            <table style="width: 720px; border-spacing: 0; padding: 15px 0;">
                <tr>
                    <td style="width: 25%; padding-top: 5px; padding-bottom: 14px; padding-left: 20px; vertical-align: top; border-right-style: solid; border-right-color: white; border-right-width: 1px;">
                        <div style="display: flex;">
                            <div class="tooltip">
                                <p class="stat-title">Ügyfelek</p>
                                <span class="yaltytiptextbottom4" style="margin-top: 10px; margin-right: -240px; width: 300px;">
                                    <b>Az összes eddigi, egyedi ügyfelek száma.</b><span style="color: #00AEEF;" *ngIf="activeFilterSet == true"><br><br><b>Aktív szűrés van beállítva hűségkártyára vagy üzletre</b>, így a mutató értéke a szűrés szerint értendő.</span>
                                </span>
                            </div>
                        </div>
                        <div style="display: flex;">
                            <div class="tooltip">
                                <span><p style="font-weight: bold; font-size: 32px; margin-top: 0; margin-bottom: 0; margin-right: 10px;">{{lCYDashboardStats.totalNrOfUsers | number}}</p></span>
                                <span class="yaltytiptextbottom4" style="margin-top: 10px; margin-right: -250px; width: 300px;">
                                    <b>Az összes eddigi, egyedi ügyfelek száma.</b><span style="color: #00AEEF;" *ngIf="activeFilterSet == true"><br><br><b>Aktív szűrés van beállítva hűségkártyára vagy üzletre</b>, így a mutató értéke a szűrés szerint értendő.</span>
                                </span>
                            </div>
                            <div class="tooltip" *ngIf="lCYDashboardStats.nrOfNewUsersWithin30Days != 0">
                                <span style="align-self: center; margin-left: 5px;"><p class="increase" style="margin-top: -10px"><span style="vertical-align: middle; font-size: 6px;">&#9650;</span> {{((lCYDashboardStats.totalNrOfUsers - lCYDashboardStats.nrOfNewUsersWithin30Days) > 0 ? lCYDashboardStats.totalNrOfUsers /
                                    (lCYDashboardStats.totalNrOfUsers - lCYDashboardStats.nrOfNewUsersWithin30Days) * 100 - 100 : 100.00) | number : '.2-2'}}%</p></span>
                                <span class="yaltytiptextbottom2" style="margin-top: 10px; margin-right: -455px;">
                                    <b>Az elmúlt 30 nap ügyfélszám növekménye.</b> Az elmúlt 30 napban <b>{{lCYDashboardStats.nrOfNewUsersWithin30Days}}</b> új ügyfél használta hűségkártyáit.<span style="color: #00AEEF;" *ngIf="activeFilterSet == true"><br><br><b>Aktív szűrés van beállítva hűségkártyára vagy üzletre</b>, így a mutató értéke a szűrés szerint értendő.</span>
                                </span>
                            </div>
                            <div class="tooltip" *ngIf="lCYDashboardStats.nrOfNewUsersWithin30Days == 0">
                                <span style="align-self: center; margin-left: 5px;"><p class="equal" style="margin-top: -10px">0.00%</p></span>
                                <span class="yaltytiptextbottom2" style="margin-top: 20px; margin-right: -445px;">
                                    <b>Az elmúlt 30 nap ügyfélszám növekménye.</b> Az elmúlt 30 napban <b>{{lCYDashboardStats.nrOfNewUsersWithin30Days}}</b> új ügyfél használta hűségkártyáit.<span style="color: #00AEEF;" *ngIf="activeFilterSet == true"><br><br><b>Aktív szűrés van beállítva hűségkártyára vagy üzletre</b>, így a mutató értéke a szűrés szerint értendő.</span>
                                </span>
                            </div>
                        </div>
                    </td>
                    <td style="width: 75%; padding-top: 5px; padding-left: 20px; padding-right: 20px; padding-bottom: 20px; border-left-style: solid; border-left-color: white; border-left-width: 1px;">
                        <div style="display: flex;">
                            <div class="tooltip">
                                <p class="stat-title">Kártyák</p>
                                <span class="yaltytiptextbottom4" style="margin-top: 10px; margin-right: -240px; width: 300px;">
                                    <b>Az összes eddigi, ügyfél által létrehozott hűségkártyák száma.</b><span style="color: #00AEEF;" *ngIf="activeFilterSet == true"><br><br><b>Aktív szűrés van beállítva hűségkártyára vagy üzletre</b>, így a mutató értéke a szűrés szerint értendő.</span>
                                </span>
                            </div>
                        </div>
                        <div style="display: flex;">
                            <div class="tooltip">
                                <span><p style="font-weight: bold; font-size: 32px; margin-top: 0; margin-bottom: 0; margin-right: 5px;">{{lCYDashboardStats.totalNrOfInstances | number}}</p></span>
                                <span class="yaltytiptextbottom4" style="margin-top: 10px; margin-right: -260px; width: 300px;">
                                    <b>Az összes eddigi, ügyfél által létrehozott hűségkártyák száma.</b><span style="color: #00AEEF;" *ngIf="activeFilterSet == true"><br><br><b>Aktív szűrés van beállítva hűségkártyára vagy üzletre</b>, így a mutató értéke a szűrés szerint értendő.</span>
                                </span>
                            </div>
                            <div class="tooltip" *ngIf="lCYDashboardStats.nrOfNewInstancesWithin30Days != 0">
                                <span style="align-self: center; margin-left: 5px;"><p class="increase" style="margin-top: -10px"><span style="vertical-align: middle; font-size: 6px;">&#9650;</span> {{((lCYDashboardStats.totalNrOfInstances - lCYDashboardStats.nrOfNewInstancesWithin30Days) > 0 ? lCYDashboardStats.totalNrOfInstances /
                                    (lCYDashboardStats.totalNrOfInstances - lCYDashboardStats.nrOfNewInstancesWithin30Days) * 100 - 100 : 100.00) | number : '.2-2'}}%</p></span>
                                <span class="yaltytiptextbottom2" style="margin-top: 10px; margin-right: -455px;">
                                    <b>Az elmúlt 30 nap kártyaszám növekménye.</b> Az elmúlt 30 napban <b>{{lCYDashboardStats.nrOfNewInstancesWithin30Days}}</b> új kártyát hoztak létre ügyfelei.<span style="color: #00AEEF;" *ngIf="activeFilterSet == true"><br><br><b>Aktív szűrés van beállítva hűségkártyára vagy üzletre</b>, így a mutató értéke a szűrés szerint értendő.</span>
                                </span>
                            </div>
                            <div class="tooltip" *ngIf="lCYDashboardStats.nrOfNewInstancesWithin30Days == 0">
                                <span style="align-self: center; margin-left: 5px;"><p class="equal" style="margin-top: -10px">0.00%</p></span>
                                <span class="yaltytiptextbottom2" style="margin-top: 20px; margin-right: -445px;">
                                    <b>Az elmúlt 30 nap kártyaszám növekménye.</b> Az elmúlt 30 napban <b>{{lCYDashboardStats.nrOfNewInstancesWithin30Days}}</b> új kártyát hoztak létre ügyfelei.<span style="color: #00AEEF;" *ngIf="activeFilterSet == true"><br><br><b>Aktív szűrés van beállítva hűségkártyára vagy üzletre</b>, így a mutató értéke a szűrés szerint értendő.</span>
                                </span>
                            </div>
                        </div>
                        <hr style="border: 0.5px solid #bfbfbf; margin-bottom: 20px; margin-top: 20px;">
                        <div style="display: flex; justify-content: center;">
                            <div style="margin-right: 15px; width: 25%;">
                                <div style="display: flex;">
                                    <div class="tooltip">
                                        <p class="stat-title">Aktív</p>
                                        <span class="yaltytiptextbottom2" style="margin-top: 10px; margin-right: -450px;">
                                            <b>Az összes jelenleg aktív, nyitott kártyák száma.</b> Az összes olyan hűségkártyák száma, amelyeken van legalább egy pecsételés és még nem lejárt, nem lezárt.<span style="color: #00AEEF;" *ngIf="activeFilterSet == true"><br><br><b>Aktív szűrés van beállítva hűségkártyára vagy üzletre</b>, így a mutató értéke a szűrés szerint értendő.</span>
                                        </span>
                                    </div>
                                </div>
                                <div style="display: flex;">
                                    <div class="tooltip">
                                        <span><p style="font-weight: bold; font-size: 20px; margin-top: 0; margin-bottom: 0; margin-right: 5px; line-height: 1;">{{lCYDashboardStats.totalNrOfActiveInstances | number}}</p></span>
                                        <span class="yaltytiptextbottom2" style="margin-top: 15px; margin-right: -460px;">
                                            <b>Az összes jelenleg aktív, nyitott kártyák száma.</b> Az összes olyan hűségkártyák száma, amelyeken van legalább egy pecsételés és még nem lejárt, nem lezárt.<span style="color: #00AEEF;" *ngIf="activeFilterSet == true"><br><br><b>Aktív szűrés van beállítva hűségkártyára vagy üzletre</b>, így a mutató értéke a szűrés szerint értendő.</span>
                                        </span>
                                    </div>
                                    <div class="tooltip" *ngIf="lCYDashboardStats.totalNrOfActiveInstances == 0">
                                        <span style="align-self: end; margin-left: 5px;"><p style="margin-top: -14px; margin-bottom: 0; color: #58687f; font-size: 14px;">0.0%</p></span>
                                        <span class="yaltytiptextbottom" style="margin-top: 15px; width: 300px; margin-right: -10px;">
                                            <b>Aktív kártyák aránya az összes kártyára nézve.</b><span style="color: #00AEEF;" *ngIf="activeFilterSet == true"><br><br><b>Aktív szűrés van beállítva hűségkártyára vagy üzletre</b>, így a mutató értéke a szűrés szerint értendő.</span>
                                        </span>
                                    </div>
                                    <div class="tooltip" *ngIf="lCYDashboardStats.totalNrOfActiveInstances != 0">
                                        <span style="align-self: end; margin-left: 5px;"><p style="margin-top: -14px; margin-bottom: 0; color: #58687f; font-size: 14px;">{{lCYDashboardStats.totalNrOfActiveInstances / lCYDashboardStats.totalNrOfInstances *100 | number: '.1-1'}}%</p></span>
                                        <span class="yaltytiptextbottom" style="margin-top: 15px; width: 300px; margin-right: -10px;">
                                            <b>Aktív kártyák aránya az összes kártyára nézve.</b><span style="color: #00AEEF;" *ngIf="activeFilterSet == true"><br><br><b>Aktív szűrés van beállítva hűségkártyára vagy üzletre</b>, így a mutató értéke a szűrés szerint értendő.</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div style="margin-right: 15px; width: 25%;">
                                <div style="display: flex;">
                                    <div class="tooltip">
                                        <p class="stat-title">Beváltott</p>
                                        <span class="yaltytiptextbottom2" style="margin-top: 10px; margin-right: -440px;">
                                            <b>Az összes beváltott kártyák száma.</b> Tehát az összes olyan hűségkártyák száma, amelyeken az összes pecsét kigyűlt és az összes jutalmat is beváltották már.<span style="color: #00AEEF;" *ngIf="activeFilterSet == true"><br><br><b>Aktív szűrés van beállítva hűségkártyára vagy üzletre</b>, így a mutató értéke a szűrés szerint értendő.</span>
                                        </span>
                                    </div>
                                </div>
                                <div style="display: flex;">
                                    <div class="tooltip">
                                        <span><p style="font-weight: bold; font-size: 20px; margin-top: 0; margin-bottom: 0; margin-right: 5px; line-height: 1;">{{lCYDashboardStats.totalNrOfRedeemedInstances | number}}</p></span>
                                        <span class="yaltytiptextbottom2" style="margin-top: 15px; margin-right: -460px;">
                                            <b>Az összes beváltott kártyák száma.</b> Tehát az összes olyan hűségkártyák száma, amelyeken az összes pecsét kigyűlt és az összes jutalmat is beváltották már.<span style="color: #00AEEF;" *ngIf="activeFilterSet == true"><br><br><b>Aktív szűrés van beállítva hűségkártyára vagy üzletre</b>, így a mutató értéke a szűrés szerint értendő.</span>
                                        </span>
                                    </div>
                                    <div class="tooltip" *ngIf="lCYDashboardStats.totalNrOfRedeemedInstances == 0">
                                        <span style="align-self: end; margin-left: 5px;"><p style="margin-top: -14px; margin-bottom: 0; color: #58687f; font-size: 14px;">0.0%</p></span>
                                        <span class="yaltytiptextbottom" style="margin-top: 15px; width: 300px; margin-right: -10px;">
                                            <b>Beváltott kártyák aránya az összes kártyára nézve.</b><span style="color: #00AEEF;" *ngIf="activeFilterSet == true"><br><br><b>Aktív szűrés van beállítva hűségkártyára vagy üzletre</b>, így a mutató értéke a szűrés szerint értendő.</span>
                                        </span>
                                    </div>
                                    <div class="tooltip" *ngIf="lCYDashboardStats.totalNrOfRedeemedInstances != 0">
                                        <span style="align-self: end; margin-left: 5px;"><p style="margin-top: -14px; margin-bottom: 0; color: #58687f; font-size: 14px;">{{lCYDashboardStats.totalNrOfRedeemedInstances / lCYDashboardStats.totalNrOfInstances *100 | number: '.1-1'}}%</p></span>
                                        <span class="yaltytiptextbottom" style="margin-top: 15px; width: 300px; margin-right: -10px;">
                                            <b>Beváltott kártyák aránya az összes kártyára nézve.</b><span style="color: #00AEEF;" *ngIf="activeFilterSet == true"><br><br><b>Aktív szűrés van beállítva hűségkártyára vagy üzletre</b>, így a mutató értéke a szűrés szerint értendő.</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div style="margin-right: 15px; width: 25%;">
                                <div style="display: flex;">
                                    <div class="tooltip">
                                        <p class="stat-title">Lejárt</p>
                                        <span class="yaltytiptextbottom2" style="margin-top: 10px; margin-right: -450px;">
                                            <b>Az összes lejárt kártyák száma.</b> Tehát az összes olyan hűségkártyák száma, amelyek esetén a pecsételések közti idő vagy a kártya véglejárata lejárt.<span style="color: #00AEEF;" *ngIf="activeFilterSet == true"><br><br><b>Aktív szűrés van beállítva hűségkártyára vagy üzletre</b>, így a mutató értéke a szűrés szerint értendő.</span>
                                        </span>
                                    </div>
                                </div>
                                <div style="display: flex;">
                                    <div class="tooltip">
                                        <span><p style="font-weight: bold; font-size: 20px; margin-top: 0; margin-bottom: 0; margin-right: 5px; line-height: 1;">{{lCYDashboardStats.totalNrOfExpiredInstances | number}}</p></span>
                                        <span class="yaltytiptextbottom2" style="margin-top: 15px; margin-right: -460px;">
                                            <b>Az összes lejárt kártyák száma.</b> Tehát az összes olyan hűségkártyák száma, amelyek esetén a pecsételések közti idő vagy a kártya véglejárata lejárt.<span style="color: #00AEEF;" *ngIf="activeFilterSet == true"><br><br><b>Aktív szűrés van beállítva hűségkártyára vagy üzletre</b>, így a mutató értéke a szűrés szerint értendő.</span>
                                        </span>
                                    </div>
                                    <div class="tooltip" *ngIf="lCYDashboardStats.totalNrOfExpiredInstances == 0">
                                        <span style="align-self: end; margin-left: 5px;"><p style="margin-top: -14px; margin-bottom: 0; color: #58687f; font-size: 14px;">0.0%</p></span>
                                        <span class="yaltytiptextbottom" style="margin-top: 15px; width: 300px; margin-right: -10px;">
                                            <b>Lejárt kártyák aránya az összes kártyára nézve.</b><span style="color: #00AEEF;" *ngIf="activeFilterSet == true"><br><br><b>Aktív szűrés van beállítva hűségkártyára vagy üzletre</b>, így a mutató értéke a szűrés szerint értendő.</span>
                                        </span>
                                    </div>
                                    <div class="tooltip" *ngIf="lCYDashboardStats.totalNrOfExpiredInstances != 0">
                                        <span style="align-self: end; margin-left: 5px;"><p style="margin-top: -14px; margin-bottom: 0; color: #58687f; font-size: 14px;">{{lCYDashboardStats.totalNrOfExpiredInstances / lCYDashboardStats.totalNrOfInstances *100 | number: '.1-1'}}%</p></span>
                                        <span class="yaltytiptextbottom" style="margin-top: 15px; width: 300px; margin-right: -10px;">
                                            <b>Lejárt kártyák aránya az összes kártyára nézve.</b><span style="color: #00AEEF;" *ngIf="activeFilterSet == true"><br><br><b>Aktív szűrés van beállítva hűségkártyára vagy üzletre</b>, így a mutató értéke a szűrés szerint értendő.</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div style="margin-right: 15px; width: 25%;">
                                <div style="display: flex;">
                                    <div class="tooltip">
                                        <p class="stat-title">Törölt</p>
                                        <span class="yaltytiptextbottom" style="margin-top: 10px; margin-right: -17px;">
                                            <b>Az összes ügyfél által törölt kártyák száma.</b> Tehát az összes olyan hűségkártyák száma, amelyeket az ügyfél eltávolított (még a pecsételési vagy kártya véglejárati idő lejáratát megelőzően).<span style="color: #00AEEF;" *ngIf="activeFilterSet == true"><br><br><b>Aktív szűrés van beállítva hűségkártyára vagy üzletre</b>, így a mutató értéke a szűrés szerint értendő.</span>
                                        </span>
                                    </div>
                                </div>
                                <div style="display: flex;">
                                    <div class="tooltip">
                                        <span><p style="font-weight: bold; font-size: 20px; margin-top: 0; margin-bottom: 0; margin-right: 5px; line-height: 1;">{{lCYDashboardStats.totalNrOfClosedInstances | number}}</p></span>
                                        <span class="yaltytiptextbottom" style="margin-top: 15px; margin-right: -26px;">
                                            <b>Az összes ügyfél által törölt kártyák száma.</b> Tehát az összes olyan hűségkártyák száma, amelyeket az ügyfél eltávolított (még a pecsételési vagy kártya véglejárati idő lejáratát megelőzően).<span style="color: #00AEEF;" *ngIf="activeFilterSet == true"><br><br><b>Aktív szűrés van beállítva hűségkártyára vagy üzletre</b>, így a mutató értéke a szűrés szerint értendő.</span>
                                        </span>
                                    </div>
                                    <div class="tooltip" *ngIf="lCYDashboardStats.totalNrOfClosedInstances == 0">
                                        <span style="align-self: end; margin-left: 5px;"><p style="margin-top: -14px; margin-bottom: 0; color: #58687f; font-size: 14px;">0.0%</p></span>
                                        <span class="yaltytiptextbottom" style="margin-top: 15px; width: 300px; margin-right: -10px;">
                                            <b>Törölt kártyák aránya az összes kártyára nézve.</b><span style="color: #00AEEF;" *ngIf="activeFilterSet == true"><br><br><b>Aktív szűrés van beállítva hűségkártyára vagy üzletre</b>, így a mutató értéke a szűrés szerint értendő.</span>
                                        </span>
                                    </div>
                                    <div class="tooltip" *ngIf="lCYDashboardStats.totalNrOfClosedInstances != 0">
                                        <span style="align-self: end; margin-left: 5px;"><p style="margin-top: -14px; margin-bottom: 0; color: #58687f; font-size: 14px;">{{lCYDashboardStats.totalNrOfClosedInstances / lCYDashboardStats.totalNrOfInstances *100 | number: '.1-1'}}%</p></span>
                                        <span class="yaltytiptextbottom" style="margin-top: 15px; width: 300px; margin-right: -10px;">
                                            <b>Törölt kártyák aránya az összes kártyára nézve.</b><span style="color: #00AEEF;" *ngIf="activeFilterSet == true"><br><br><b>Aktív szűrés van beállítva hűségkártyára vagy üzletre</b>, így a mutató értéke a szűrés szerint értendő.</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            </table>
        </div>
        <div class="section" style="height: auto;">
            <table style="width: 720px; border-spacing: 0;">
                <tr>
                    <td style="width: 20%; padding-top: 20px; padding-bottom: 20px; vertical-align: top;">
                        <div style="display: flex; justify-content: center; margin-bottom: 5px;">
                            <div class="tooltip">
                                <span><p style="font-weight: bold; font-size: 20px; margin-top: 0; margin-bottom: 0; margin-right: 5px;">{{lCYDashboardStats.totalNrOfStamps | number}}</p></span>
                                <span class="yaltytiptextbottom4" style="margin-top: 15px; margin-right: -255px; width: 300px;">
                                    <b>Az összes eddig kiadott pecsétek száma.</b><span style="color: #00AEEF;" *ngIf="activeFilterSet == true"><br><br><b>Aktív szűrés van beállítva hűségkártyára vagy üzletre</b>, így a mutató értéke a szűrés szerint értendő.</span>
                                </span>
                            </div>
                        </div>
                        <div style="display: flex; justify-content: center;">
                            <div class="tooltip">
                                <span><p class="stat-title2">Pecsétek (db)</p></span>
                                <span class="yaltytiptextbottom4" style="margin-top: 15px; margin-right: -220px; width: 300px;">
                                    <b>Az összes eddig kiadott pecsétek száma.</b><span style="color: #00AEEF;" *ngIf="activeFilterSet == true"><br><br><b>Aktív szűrés van beállítva hűségkártyára vagy üzletre</b>, így a mutató értéke a szűrés szerint értendő.</span>
                                </span>
                            </div>
                        </div>
                    </td>
                    <td style="width: 20%; padding-top: 20px; padding-bottom: 20px; vertical-align: top;">
                        <div style="display: flex; justify-content: center; margin-bottom: 5px;">
                            <div class="tooltip">
                                <span><p style="font-weight: bold; font-size: 20px; margin-top: 0; margin-bottom: 0; margin-right: 5px;">{{lCYDashboardStats.totalNrOfRedeemedFinalRewards | number}}</p></span>
                                <span class="yaltytiptextbottom4" style="margin-top: 15px; margin-right: -255px; width: 300px;">
                                    <b>Az összes eddigi végjutalom beváltások száma.</b><span style="color: #00AEEF;" *ngIf="activeFilterSet == true"><br><br><b>Aktív szűrés van beállítva hűségkártyára vagy üzletre</b>, így a mutató értéke a szűrés szerint értendő.</span>
                                </span>
                            </div>
                        </div>
                        <div style="display: flex; justify-content: center;">
                            <div class="tooltip">
                                <p class="stat-title2">Végjutalom<br>beváltások (db)</p>
                                <span class="yaltytiptextbottom4" style="margin-top: 15px; margin-right: -220px; width: 300px;">
                                    <b>Az összes eddigi végjutalom beváltások száma.</b><span style="color: #00AEEF;" *ngIf="activeFilterSet == true"><br><br><b>Aktív szűrés van beállítva hűségkártyára vagy üzletre</b>, így a mutató értéke a szűrés szerint értendő.</span>
                                </span>
                            </div>
                        </div>
                    </td>
                    <td style="width: 20%; padding-top: 20px; padding-bottom: 20px; vertical-align: top;">
                        <div style="display: flex; justify-content: center; margin-bottom: 5px;">
                            <div class="tooltip">
                                <span *ngIf="lCYDashboardStats.avgTimeBetweenTwoStamps != null"><p style="font-weight: bold; font-size: 20px; margin-top: 0; margin-bottom: 0; margin-right: 5px;">{{lCYDashboardStats.avgTimeBetweenTwoStamps |
                                    toFixed: 2 }}</p></span>
                                <span *ngIf="lCYDashboardStats.avgTimeBetweenTwoStamps == null"><p style="font-weight: bold; font-size: 20px; margin-top: 0; margin-bottom: 0; margin-right: 5px;">-</p></span>
                                <span class="yaltytiptextbottom4" style="margin-top: 15px; margin-right: -260px; width: 300px;">
                                    <b>Két pecsételés közt eltelt átlagos napszám.</b><span style="color: #00AEEF;" *ngIf="activeFilterSet == true"><br><br><b>Aktív szűrés van beállítva hűségkártyára vagy üzletre</b>, így a mutató értéke a szűrés szerint értendő. Ha a mutató értéke “-“ akkor a beállított szűrések mellett nincs elég adat a mutató kiszámításához.</span>
                                </span>
                            </div>
                        </div>
                        <div style="display: flex; justify-content: center;">
                            <div class="tooltip">
                                <p class="stat-title2">Két pecsételés<br>közti átlagos<br>idő (nap)</p>
                                <span class="yaltytiptextbottom4" style="margin-top: 15px; margin-right: -220px; width: 300px;">
                                    <b>Két pecsételés közt eltelt átlagos napszám.</b><span style="color: #00AEEF;" *ngIf="activeFilterSet == true"><br><br><b>Aktív szűrés van beállítva hűségkártyára vagy üzletre</b>, így a mutató értéke a szűrés szerint értendő. Ha a mutató értéke “-“ akkor a beállított szűrések mellett nincs elég adat a mutató kiszámításához.</span>
                                </span>
                            </div>
                        </div>
                    </td>
                    <td style="width: 20%; padding-top: 20px; padding-bottom: 20px; vertical-align: top;">
                        <div style="display: flex; justify-content: center; margin-bottom: 5px;">
                            <div class="tooltip">
                                <span *ngIf="lCYDashboardStats.avgCardStampingLifetime != null"><p style="font-weight: bold; font-size: 20px; margin-top: 0; margin-bottom: 0; margin-right: 5px;">{{lCYDashboardStats.avgCardStampingLifetime | toFixed: 2 }}</p></span>
                                <span *ngIf="lCYDashboardStats.avgCardStampingLifetime == null"><p style="font-weight: bold; font-size: 20px; margin-top: 0; margin-bottom: 0; margin-right: 5px;">-</p></span>
                                <span class="yaltytiptextbottom" style="margin-top: 15px;margin-right: -25px;">
                                    <b>Átlagosan ennyi nap alatt gyűlt ki az összes pecsét a kártyákon.</b><span style="color: #00AEEF;" *ngIf="activeFilterSet == true"><br><br><b>Aktív szűrés van beállítva hűségkártyára vagy üzletre</b>, így a mutató értéke a szűrés szerint értendő. Ennél a mutatónál üzletre szűrés beállítása mellett azokat a kártyákat vesszük, amelyek esetén minden pecsételés a kiválasztott üzlet(ek)ben történt. Ilyenkor kiesnek az olyan kártyák, amelyek vegyesen több üzletben lettek pecsételve. Ha a mutató értéke “-“ akkor a beállított szűrések mellett nincs elég adat a mutató kiszámításához.</span>
                                </span>
                            </div>
                        </div>
                        <div style="display: flex; justify-content: center;">
                            <div class="tooltip">
                                <p class="stat-title2">Kártyák átlagos<br>pecsétkigyűlés<br>élethossza (nap)</p>
                                <span class="yaltytiptextbottom" style="margin-top: 15px;margin-right: 20px;">
                                    <b>Átlagosan ennyi nap alatt gyűlt ki az összes pecsét a kártyákon.</b><span style="color: #00AEEF;" *ngIf="activeFilterSet == true"><br><br><b>Aktív szűrés van beállítva hűségkártyára vagy üzletre</b>, így a mutató értéke a szűrés szerint értendő. Ennél a mutatónál üzletre szűrés beállítása mellett azokat a kártyákat vesszük, amelyek esetén minden pecsételés a kiválasztott üzlet(ek)ben történt. Ilyenkor kiesnek az olyan kártyák, amelyek vegyesen több üzletben lettek pecsételve. Ha a mutató értéke “-“ akkor a beállított szűrések mellett nincs elég adat a mutató kiszámításához.</span>
                                </span>
                            </div>
                        </div>
                    </td>
                    <td style="width: 20%; padding-top: 20px; padding-bottom: 20px; vertical-align: top;">
                        <div style="display: flex; justify-content: center; margin-bottom: 5px;">
                            <div class="tooltip">
                                <span *ngIf="lCYDashboardStats.avgCardRedemptionLifetime != null"><p style="font-weight: bold; font-size: 20px; margin-top: 0; margin-bottom: 0; margin-right: 5px;">{{lCYDashboardStats.avgCardRedemptionLifetime | toFixed: 2 }}</p></span>
                                <span *ngIf="lCYDashboardStats.avgCardRedemptionLifetime == null"><p style="font-weight: bold; font-size: 20px; margin-top: 0; margin-bottom: 0; margin-right: 5px;">-</p></span>
                                <span class="yaltytiptextbottom" style="margin-top: 15px;margin-right: -25px;">
                                    <b>Átlagosan ennyi nap telt el a kártyák első pecsételésétől az összes jutalom beváltásáig.</b><span style="color: #00AEEF;" *ngIf="activeFilterSet == true"><br><br><b>Aktív szűrés van beállítva hűségkártyára vagy üzletre</b>, így a mutató értéke a szűrés szerint értendő. Ennél a mutatónál üzletre szűrés beállítása mellett azokat a kártyákat vesszük, amelyek esetén minden pecsételés és a végbeváltás is a kiválasztott üzlet(ek)ben történt. Ilyenkor kiesnek az olyan kártyák, amelyek vegyesen több üzletben lettek pecsételve vagy beváltva. Ha a mutató értéke “-“ akkor a beállított szűrések mellett nincs elég adat a mutató kiszámításához.</span>
                                </span>
                            </div>
                        </div>
                        <div style="display: flex; justify-content: center;">
                            <div class="tooltip">
                                <p class="stat-title2">Kártyák átlagos<br>végbeváltási élet-<br>hossza (nap)</p>
                                <span class="yaltytiptextbottom" style="margin-top: 15px;margin-right: 20px;">
                                    <b>Átlagosan ennyi nap telt el a kártyák első pecsételésétől az összes jutalom beváltásáig.</b><span style="color: #00AEEF;" *ngIf="activeFilterSet == true"><br><br><b>Aktív szűrés van beállítva hűségkártyára vagy üzletre</b>, így a mutató értéke a szűrés szerint értendő. Ennél a mutatónál üzletre szűrés beállítása mellett azokat a kártyákat vesszük, amelyek esetén minden pecsételés és a végbeváltás is a kiválasztott üzlet(ek)ben történt. Ilyenkor kiesnek az olyan kártyák, amelyek vegyesen több üzletben lettek pecsételve vagy beváltva. Ha a mutató értéke “-“ akkor a beállított szűrések mellett nincs elég adat a mutató kiszámításához.</span>
                                </span>
                            </div>
                        </div>
                    </td>
                </tr>
            </table>
        </div>
        <div style="height: 50px;"></div>
    </div>
</div>