<mat-form-field [formGroup]="form" appearance="standard">
    <mat-label>Yalty város</mat-label>
    <mat-select formControlName="yaltyCity" (selectionChange)="setYaltyCityValue()"
        multiple required>
        <mat-select-trigger>
            {{yaltyCityDisplayValue}}
        </mat-select-trigger>
        <mat-option [value]="'all'" class="option-header">Összes</mat-option>
        <mat-option *ngFor="let city of yaltyCities" [value]="city">{{city}}</mat-option>
    </mat-select>
    <mat-error *ngIf="yaltyCity.hasError('required')">Mező kitöltése kötelező!</mat-error>
</mat-form-field>
