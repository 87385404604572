// @ts-nocheck

import { downgradeComponent } from '@angular/upgrade/static';
import { AggregateStoresComponent } from 'src/app/pages/aggregate-stores/aggregate-stores.component';

angular.module('yaltyApp')
    .directive('aggregateStores', downgradeComponent({ component: AggregateStoresComponent }) as angular.IDirectiveFactory)
    .controller('companyStoresCtrl', function ($scope, $timeout, $rootScope, $cookies, $window, $anchorScroll, $filter, $http, $mdDialog, $state, $location, API_PREFIX, StoreService, Map, $document, AuthService, SubscriptionNames) {
        var virtualSize = $window.innerHeight - 300// 0.65;
        var dateNull = new Date(1970, 0, 1, 0, 0, 0);
        $scope.userType = $cookies.get('adminUserType');
        const companyStoreCount = $cookies.get('sbcrtyp').split('&')[2];
        $rootScope.isDataSheetModified = false;
        $scope.zoomSlider = 0;
        $scope.sliderMin = 0;
        $scope.sliderMax = 10;
        $rootScope.companyCropperData = {};
        $scope.compStoreDetail = {};
        $scope.compImageURL = $scope.dataCompany.compLogoImgURL;
        $scope.coordinateMode = "automatic";
        let rrt = 2;
        let rgr = 500;
        if ($scope.dataCompany != null && $scope.dataCompany.redeemRestrictionType == 2) {
            rrt = 3;
            rgr = 0;
        } else if ($scope.dataCompany != null && $scope.dataCompany.redeemRestrictionType == 3) {
            rrt = 5;
            rgr = 0;
        }
        $scope.redeemRestrictionType = rrt;
        $scope.redeemGeofenceRadius = rgr;
        $scope.sendButtonDisabled = false;
        $scope.isOpened = "fa fa-chevron-up";
        $scope.containerSize = { "height": virtualSize + "px", "width": "272px", "margin-left": "8px" };
        $scope.mapManuallyRepositioned = 0;
        $scope.mapManuallyReposition = 0;
        $scope.center = {};
        $scope.isSameName = { "color": "black" };
        $scope.isSameDesc = { "color": "black" };
        $scope.isSamePhone = { "color": "black" };
        $scope.isSameWeb = { "color": "black" };
        $scope.isSameFb = { "color": "black" };
        $scope.isSameAddress = { "color": "#4E525C" };
        $scope.coordinate = { "color": "black", "text-align": "left" };
        $scope.showStores = true;
        $scope.isAddress = true;
        $scope.buttonClass = "activeBtn";
        $scope.showSetStore = false;
        $scope.newButtonClass = "btn";
        $scope.animateMobile = "";
        $scope.facebookPrefix = "facebook.com/";
        $scope.FBURL = $scope.facebookPrefix;
        $scope.countries = [{ id: 1, name: 'Magyarország' }];
        $scope.todaysDate = new Date();
        let googleMap;
        $scope.orderBy = [{ id: '1', value: 'Utolsó módosítás dátuma szerint legújabb elől', order: 'lastUpdatedTimestamp' },
        { id: '2', value: 'Utolsó módosítás dátuma szerint legrégebbi elől', order: 'lastUpdatedTimestamp' },
        { id: '3', value: 'Üzlet megnevezése szerint A-Z', order: 'compStoreName' },
        { id: '4', value: 'Üzlet megnevezése szerint Z-A', order: 'compStoreName' }];
        $scope.order = $scope.orderBy[0];
        $scope.orderStore = "'" + $scope.orderBy[0].order + "'";
        $scope.reverseStore = false;
        $rootScope.$watch('companyCropperData.compStoreImage', function (current, prevent) {
            if (prevent && current) {
                if (prevent.includes('/imgs/') && !current.includes('/imgs/')) {
                    $scope.isCompStoreImgSame = false;
                    $rootScope.isDataSheetModified = true;
                }
            }
        });
        $rootScope.$watch('isDataSheetModified', function () {
            if ($rootScope.isDataSheetModified == false) {
                $scope.sendButtonDisabled = true;
            } else {
                $scope.sendButtonDisabled = false;
            }
        });
        $scope.checkChanges = function () {
            if ($scope.openingTimesPartnerMessage) {
                $scope.openingTimesPartnerMessage = $scope.openingTimesPartnerMessage.trim();
            }
            if ($rootScope.isDataSheetModified == false) {
                if ($scope.companyStoreId) {
                    if ($scope.compStoreName != $scope.compStoreDetail.compStoreName) {
                        $rootScope.isDataSheetModified = true;
                    } else if ($scope.compDescription != $scope.compStoreDetail.compStoreDescription) {
                        $rootScope.isDataSheetModified = true;
                    } else if ($scope.publicContactPhone != $scope.compStoreDetail.publicContactPhone) {
                        $rootScope.isDataSheetModified = true;
                    } else if ($scope.webURL != $scope.compStoreDetail.webURL) {
                        $rootScope.isDataSheetModified = true;
                    } else if ($scope.FBURL != `facebook.com/${$scope.compStoreDetail.FBURL}`) {
                        $rootScope.isDataSheetModified = true;
                    } else if ($scope.onlineStoreRedeemInfo != $scope.compStoreDetail.onlineStoreRedeemInfo) {
                        $rootScope.isDataSheetModified = true;
                    } else if ($scope.compStoreAddrCountry != $scope.compStoreDetail.compStoreAddrCountry) {
                        $rootScope.isDataSheetModified = true;
                    } else if ($scope.compStoreAddrZIP != $scope.compStoreDetail.compStoreAddrZIP) {
                        $rootScope.isDataSheetModified = true;
                    } else if ($scope.compStoreAddrCity != null && $scope.compStoreAddrCity.placeName != $scope.compStoreDetail.compStoreAddrCity) {
                        $rootScope.isDataSheetModified = true;
                    } else if ($scope.compStoreAddrStreet != $scope.compStoreDetail.compStoreAddrStreet) {
                        $rootScope.isDataSheetModified = true;
                    } else if ($scope.compStoreAddrNo != $scope.compStoreDetail.compStoreAddrNo) {
                        $rootScope.isDataSheetModified = true;
                    } else if ($scope.center.lat != $scope.compStoreDetail.latitudeCoordinate) {
                        $rootScope.isDataSheetModified = true;
                    } else if ($scope.center.lng != $scope.compStoreDetail.longitudeCoordinate) {
                        $rootScope.isDataSheetModified = true;
                    } else if ($scope.coordinateMode != $scope.compStoreDetail.coordinateMode) {
                        $rootScope.isDataSheetModified = true;
                    } else if ($scope.redeemRestrictionType != $scope.compStoreDetail.redeemRestrictionType) {
                        $rootScope.isDataSheetModified = true;
                    } else if ($scope.redeemGeofenceRadius != $scope.compStoreDetail.redeemGeofenceRadius) {
                        $rootScope.isDataSheetModified = true;
                    } else if ($scope.isDefOpeningTimeSame != $scope.compStoreDetail.isDefOpeningTimeSame) {
                        $rootScope.isDataSheetModified = true;
                    } else if ($scope.defOpeningTimeFromHour != parseInt(getHour($scope.compStoreDetail.defOpeningTimeFrom)) && $scope.defOpeningTimeFromMin == parseInt(getMin($scope.compStoreDetail.defOpeningTimeFrom)) && $scope.defOpeningTimeUntilHour == parseInt(getHour($scope.compStoreDetail.defOpeningTimeUntil)) && $scope.defOpeningTimeUntilMin == parseInt(getMin($scope.compStoreDetail.defOpeningTimeUntil))) {
                        $rootScope.isDataSheetModified = true;
                    } else if ($scope.defOpeningTimeFromMin != parseInt(getHour($scope.compStoreDetail.defOpeningTimeFrom)) && $scope.defOpeningTimeFromMin != parseInt(getMin($scope.compStoreDetail.defOpeningTimeFrom)) && $scope.defOpeningTimeUntilHour == parseInt(getHour($scope.compStoreDetail.defOpeningTimeUntil)) && $scope.defOpeningTimeUntilMin == parseInt(getMin($scope.compStoreDetail.defOpeningTimeUntil))) {
                        $rootScope.isDataSheetModified = true;
                    } else if ($scope.defOpeningTimeUntilHour != parseInt(getHour($scope.compStoreDetail.defOpeningTimeFrom)) && $scope.defOpeningTimeFromMin == parseInt(getMin($scope.compStoreDetail.defOpeningTimeFrom)) && $scope.defOpeningTimeUntilHour != parseInt(getHour($scope.compStoreDetail.defOpeningTimeUntil)) && $scope.defOpeningTimeUntilMin == parseInt(getMin($scope.compStoreDetail.defOpeningTimeUntil))) {
                        $rootScope.isDataSheetModified = true;
                    } else if ($scope.defOpeningTimeUntilMin != parseInt(getHour($scope.compStoreDetail.defOpeningTimeFrom)) && $scope.defOpeningTimeFromMin == parseInt(getMin($scope.compStoreDetail.defOpeningTimeFrom)) && $scope.defOpeningTimeUntilHour == parseInt(getHour($scope.compStoreDetail.defOpeningTimeUntil)) && $scope.defOpeningTimeUntilMin != parseInt(getMin($scope.compStoreDetail.defOpeningTimeUntil))) {
                        $rootScope.isDataSheetModified = true;
                    } else if ($scope.displayPartnerMsgInsteadOfOpeningTimes != $scope.compStoreDetail.displayPartnerMsgInsteadOfOpeningTimes) {
                        $rootScope.isDataSheetModified = true;
                    } else if ($scope.openingTimesPartnerMessage != $scope.compStoreDetail.openingTimesPartnerMessage) {
                        $rootScope.isDataSheetModified = true;
                    } else if ($scope.holidayOpeningTimes != $scope.compStoreDetail.holidayOpeningTimes) {
                        $rootScope.isDataSheetModified = true;
                    } else if ($scope.holidayOpeningTimesUntil != $scope.compStoreDetail.holidayOpeningTimesUntil) {
                        $rootScope.isDataSheetModified = true;
                    }
                } else {
                    if ($scope.compStoreName != $scope.dataCompany.compNamePromo) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.compDescription != $scope.dataCompany.compDescription) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.publicContactPhone != $scope.dataCompany.publicContactPhone) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.webURL != $scope.dataCompany.webURL) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.FBURL != $scope.dataCompany.FBURL) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.onlineStoreRedeemInfo != null) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.compStoreAddrCountry) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.compStoreAddrZIP) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.compStoreAddrCity && $scope.compStoreAddrCity.placeName) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.compStoreAddrStreet) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.compStoreAddrNo) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.center.lat) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.center.lng) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.coordinateMode != 'automatic') {
                        $rootScope.isDataSheetModified = true;
                    }
                    let rrt = '2';
                    let rgr = '500';
                    if ($scope.dataCompany != null && $scope.dataCompany.redeemRestrictionType == 2) {
                        rrt = '3';
                        rgr = '0';
                    } else if ($scope.dataCompany != null && $scope.dataCompany.redeemRestrictionType == 3) {
                        rrt = '5';
                        rgr = '0';
                    }
                    if ($scope.redeemRestrictionType != rrt) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.redeemGeofenceRadius != rgr) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.isDefOpeningTimeSame != true) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.isDefAroundTheClock != false) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.displayPartnerMsgInsteadOfOpeningTimes != false) {
                        $rootScope.isDataSheetModified = true;
                    }
                    if ($scope.openingTimesPartnerMessage) {
                        $rootScope.isDataSheetModified = true;
                    }
                }
            }
        }
        $scope.resetPage = function () {
            if ($rootScope.isDataSheetModified == false) {
                $window.scrollTo(0, 0);
                $state.reload();
            } else {
                var confirm = $mdDialog.confirm()
                    .title('Biztosan el szeretné hagyni az oldalt?')
                    .textContent('Az adatlapon történt módosításai mentés nélkül elvesznek.')
                    .ariaLabel('Oldal elhagyás megerősítése')
                    .ok('Igen')
                    .cancel('Mégsem');
                $mdDialog.show(confirm).then(function () {
                    $rootScope.isDataSheetModified = false;
                    $window.scrollTo(0, 0);
                    $state.reload();
                }, function () {
                });
            }
        }
        $scope.validFBURL = function () {
            if ($rootScope.isDataSheetModified == false) {
                $rootScope.isDataSheetModified = true;
            }
            if ($scope.FBURL.split($scope.facebookPrefix)[1]) {
                var FBURL = $scope.facebookPrefix + $scope.FBURL.split($scope.facebookPrefix)[1];
                $scope.FBURL = FBURL;
            } else {
                $scope.FBURL = $scope.facebookPrefix;
            }
        }
        $scope.validTranslatedFBURL = function () {
            if ($rootScope.isDataSheetModified == false) {
                $rootScope.isDataSheetModified = true;
            }
            if ($scope.translatedFBURL.split($scope.facebookPrefix)[1]) {
                var FBURL = $scope.facebookPrefix + $scope.translatedFBURL.split($scope.facebookPrefix)[1];
                $scope.translatedFBURL = FBURL;
            } else {
                $scope.translatedFBURL = $scope.facebookPrefix;
            }
        }
        $scope.orderChange = function (id) {
            if (id == 1 || id == 4) {
                $scope.orderStore = "'" + $scope.orderBy[(id - 1)].order + "'";
                $scope.reverseStore = true;
            } else {
                $scope.orderStore = "'" + $scope.orderBy[(id - 1)].order + "'";
                $scope.reverseStore = false;
            }
        }
        $scope.deleteCompanyStore = function (ev) {
            var confirm = $mdDialog.confirm()
                .title('Biztosan törölni szeretné az üzletet?')
                .textContent('Igen válasz esetén az üzlet törlésre kerül.')
                .ariaLabel('Törlés megerősítése')
                .targetEvent(ev)
                .ok('Igen')
                .cancel('Mégsem');

            $mdDialog.show(confirm).then(function () {
                if ($scope.companyStoreId) {
                    if ($rootScope.isDataSheetModified == true) {
                        $rootScope.isDataSheetModified = false;
                    }
                    var obj = {};
                    obj.companyStoreId = $scope.companyStoreId;
                    $scope.removeInProgress = true;
                    StoreService.removeStore(obj).then(function (result) {
                        $scope.removeInProgress = false;
                        let title
                        if (result.data.errorCode == 0) {
                            title = 'Az üzletet rendben töröltük.'
                        } else {
                            title = 'Az üzlet törlése közben váratlan hiba történ. Kérjük próbálja meg a törlést később.'
                        }
                        $mdDialog.show(
                            $mdDialog.alert()
                                .clickOutsideToClose(true)
                                .title(title)
                                .ok('Rendben')
                                .targetEvent(ev)
                        )
                        $state.reload();
                    }, function (result) {
                        $scope.removeInProgress = false;
                        $mdDialog.show(
                            $mdDialog.alert()
                                .clickOutsideToClose(true)
                                .title(result.data.msg)
                                .ok('Rendben')
                                .targetEvent(ev)
                        )
                        $state.reload();
                    })
                }
            }, function () {
            });
        }
        $scope.displayedCountryWide = function () {
            $rootScope.isDataSheetModified = true;
            if ($scope.isDisplayedCountrywide == true) {
                $scope.yaltyCity = null;
            }
        }
        $scope.checkRedeemRestrictionType = function () {
            if ($scope.redeemRestrictionType == 0 || $scope.redeemRestrictionType == 3 || $scope.redeemRestrictionType == 5) {
                $scope.redeemGeofenceRadius = 0;
            } else if ($scope.redeemGeofenceRadius == 0) {
                $scope.redeemGeofenceRadius = 500;
            }
            $scope.checkChanges();
        }
        $scope.companyStoreIsOnlineStore = function () {
            $rootScope.isDataSheetModified = true;
            if ($scope.isOnlineStore == true) {
                $scope.isSameAddress = { "color": "#DFDFDF", "border-color": "#DFDFDF" };
                $scope.coordinate = { "color": "#DFDFDF", "text-align": "left" };
                $scope.googleMapIsInvalid = false;
                $scope.isCompStoreAddrSame = false;
                $scope.isMapSettingsSame = false;
                $scope.compStoreAddrCountry = null;
                $scope.compStoreAddrZIP = null;
                $scope.compStoreAddrCity = '';
                $scope.compStoreAddrStreet = null;
                $scope.compStoreAddrNo = null;
                $scope.compStoreMapImgURL = null;
                $scope.center = {};
                $scope.isAddress = true;
                $scope.redeemRestrictionType = 0;
                $scope.redeemGeofenceRadius = 0;
                $scope.coordinateMode = "automatic";
                $scope.mapManuallyReposition = 0;
                $scope.mapManuallyRepositioned = 0;
            } else {
                $scope.isCompStoreAddrSame = true;
                $scope.isMapSettingsSame = true;
                $scope.isSameCompanyAddr('same');
                $scope.coordinate = { "color": "black", "text-align": "left" };
                $scope.isSameAddress = { "color": "#4E525C", "border-color": "#4E525C" };
                let rrt = 2;
                let rgr = 500;
                if ($scope.dataCompany != null && $scope.dataCompany.redeemRestrictionType == 2) {
                    rrt = 3;
                    rgr = 0;
                } else if ($scope.dataCompany != null && $scope.dataCompany.redeemRestrictionType == 3) {
                    rrt = 5;
                    rgr = 0;
                }
                $scope.redeemRestrictionType = rrt;
                $scope.redeemGeofenceRadius = rgr;
                $scope.setMapSettings();
            }
        }
        $scope.checkCities = function () {
            if ($scope.yaltyCity != undefined) {
                if ($scope.compStoreAddrCity && $scope.compStoreAddrCity.placeName == $scope.yaltyCity.placeName) {
                    $scope.invalidErrorInYaltyCity = true;
                } else {
                    $scope.invalidErrorInYaltyCity = false;
                }
            } else {
                $scope.invalidErrorInYaltyCity = false;
            }
        }

        $scope.isYaltyCitySectionHided = function () {
            if ($scope.compStoreAddrCity != null && $scope.compStoreAddrCity != '') {
                if ($scope.listOfYaltyCities != null && $scope.listOfYaltyCities.length > 0) {
                    var result = $scope.listOfYaltyCities.filter(obj => {
                        return obj.placeName === $scope.compStoreAddrCity.placeName
                    })
                    if (result.length > 0 && $scope.isOnlineStore != true && $scope.userType == null) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }

        $scope.yaltyCitySearch = function (query) {
            if (query) {
                $rootScope.isDataSheetModified = true;
                var filtered = $scope.listOfYaltyCities.filter(function (i) {
                    var lowercaseQuery = angular.lowercase(query);
                    return ((i.placeName.toLowerCase()).indexOf(lowercaseQuery) === 0);
                })
                if (filtered.length > 0) {
                    return filtered;
                } else {
                    return $scope.listOfYaltyCities;
                }
            } else {
                return $scope.listOfYaltyCities;
            }
        }
        $scope.querySearch = function (query) {
            if (query) {
                var filtered = $scope.listOfCities.filter(function (i) {
                    var lowercaseQuery = angular.lowercase(query);
                    return ((i.placeName.toLowerCase()).indexOf(lowercaseQuery) === 0);
                })
                if (filtered.length > 50) {
                    return filtered.slice(0, 50);
                } else if (filtered.length > 0) {
                    return filtered;
                } else {
                    return $scope.listOfCities.slice(0, 50);
                }
            } else {
                return $scope.listOfCities.slice(0, 50);
            }
        }
        $scope.sendCompanyStoresData = function (companyStore) {
            if($scope.holidayOpeningTimesUntil != null){
                $scope.setTimeToEndOfDay();
            }
            if (!companyStore.$valid) {

                angular.forEach(companyStore.$error, function (field) {
                    angular.forEach(field, function (errorField) {
                        errorField.$setTouched();
                    });
                });
                let errorInEnglishTranslation = null
                if (!companyStore.translatedCompStoreName.$valid) {
                    errorInEnglishTranslation = 'translatedCompStoreName'
                } else if (!companyStore.translatedCompStoreDescription.$valid) {
                    errorInEnglishTranslation = 'translatedCompStoreDescription'
                } else if (!companyStore.translatedWebURL.$valid) {
                    errorInEnglishTranslation = 'translatedWebURL'
                } else if (!companyStore.translatedFBURL.$valid) {
                    errorInEnglishTranslation = 'translatedFBURL'
                } else if (!companyStore.translatedOnlineStoreRedeemInfo.$valid) {
                    errorInEnglishTranslation = 'translatedOnlineStoreRedeemInfo'
                } else if (!companyStore.translatedOpeningTimesPartnerMessage.$valid) {
                    errorInEnglishTranslation = 'translatedOpeningTimesPartnerMessage'
                }

                if (errorInEnglishTranslation) {
                    $scope.langName = 'angol'
                    $scope.companyStoresForm.languageSelector.$touched = true
                    $scope.companyStoresForm.languageSelector.$valid = false
                    $scope.companyStoresForm.languageSelector.$invalid = true
                    $scope.companyStoresForm.languageSelector.$error = { required: true }
                    document.getElementById('languageSelector').scrollIntoView()
                }

                if ($rootScope.companyCropperData.compStoreImage == null) {
                    companyStore.storeImg.$error.need = true;
                }
                if (angular.element($document[0].querySelector('input.ng-invalid')).length > 0) {
                    angular.element($document[0].querySelector('input.ng-invalid')).focus();
                } else if (angular.element($document[0].querySelector('md-select.ng-invalid')).length > 0) {
                    angular.element($document[0].querySelector('md-select.ng-invalid')).focus();
                } else if (angular.element($document[0].querySelector('md-datepicker.ng-invalid')).length > 0) {
                    angular.element($document[0].querySelector('md-datepicker.ng-invalid')).focus();
                } else {
                    angular.element($document[0].querySelector('textarea.ng-invalid')).focus();
                }
                return false;
            } else if ($rootScope.companyCropperData.compStoreImage == null) {
                companyStore.storeImg.$error.need = true;
                $('.main').animate({ scrollTop: $('div#storeImg').offset().top - 150 }, "slow");
            } else if ($scope.invalidErrorInYaltyCity == true) {
                $('.main').animate({ scrollTop: $('div#invalidErrorInYaltyCity').offset().top - 150 }, "slow");
            } else if (companyStoreCount != 0 && (($rootScope.partnerSubscriptionType == 'yalty_start_free' && $scope.allStores.length >= companyStoreCount && !$scope.companyStoreId)
                || ($rootScope.partnerSubscriptionType == 'yalty_start_paid' && $scope.allStores.length >= companyStoreCount && !$scope.companyStoreId)
                || ($rootScope.partnerSubscriptionType == 'basic' && $scope.allStores.length >= companyStoreCount && !$scope.companyStoreId)
                || ($rootScope.partnerSubscriptionType == 'premium' && $scope.allStores.length >= companyStoreCount && !$scope.companyStoreId))) {
                $mdDialog.show({
                    template:
                        '<md-dialog>' +
                        '  <md-dialog-content class="md-dialog-content">' +
                        'Elérte a jelenlegi előfizetési csomagja (<b>{{partnerSubscriptionName}}' +
                        '</b>) szerinti létrehozható üzletek maximális számát ({{count}} db üzlet). ' +
                        'Törölje ki az egyik üzletet vagy váltson magasabb előfizetési csomagra. ' +
                        '<a ng-click="navigate()">Az előfizetések áttekintéséhez és módosításához kattintson ide.</a>' +
                        '  </md-dialog-content>' +
                        '  <md-dialog-actions>' +
                        '    <md-button ng-click="closeDialog()" class="md-primary">' +
                        '      Rendben' +
                        '    </md-button>' +
                        '  </md-dialog-actions>' +
                        '</md-dialog>',
                    locals: {
                        partnerSubscriptionType: $rootScope.partnerSubscriptionType,
                        partnerSubscriptionName: SubscriptionNames[$rootScope.partnerSubscriptionType],
                        count: companyStoreCount
                    },
                    controller: DialogCtrl
                });
            } else {
                $scope.sendButtonDisabled = true;
                var companyStoreData = {};
                if ($scope.companyStoreId) {
                    companyStoreData.companyStoreId = $scope.companyStoreId;
                } else {
                    companyStoreData.companyStoreId = undefined;
                }
                companyStoreData.compStoreImg = $rootScope.companyCropperData.compStoreImage;
                companyStoreData.isCompStoreImgSame = $scope.isCompStoreImgSame ? 1 : 0;
                companyStoreData.compStoreName = $scope.compStoreName;
                companyStoreData.isCompStoreNameSame = $scope.isCompStoreNameSame ? 1 : 0;
                companyStoreData.compDescription = "";
                companyStoreData.compDescription = $scope.compDescription;
                companyStoreData.isCompStoreDescriptionSame = $scope.isCompStoreDescriptionSame ? 1 : 0;
                companyStoreData.isHolidayOpeningTimesSame = $scope.isHolidayOpeningTimesSame ? 1 : 0;
                companyStoreData.holidayOpeningTimes = $scope.holidayOpeningTimes != "" ? $scope.holidayOpeningTimes : null;
                companyStoreData.holidayOpeningTimesUntil = $scope.holidayOpeningTimesUntil;

                if ($scope.publicContactPhone != null) {
                    companyStoreData.publicContactPhone = $scope.publicContactPhone;
                } else {
                    companyStoreData.publicContactPhone = "";
                }
                companyStoreData.isPublicContactPhone = $scope.isPublicContactPhone ? 1 : 0;
                if ($scope.webURL != null) {
                    companyStoreData.webURL = $scope.webURL;
                } else {
                    companyStoreData.webURL = "";
                }
                companyStoreData.isWebURLSame = $scope.isWebURLSame ? 1 : 0;
                if ($scope.FBURL.split($scope.facebookPrefix)[1]) {
                    companyStoreData.FBURL = $scope.FBURL.split($scope.facebookPrefix)[1];
                } else {
                    companyStoreData.FBURL = "";
                }
                companyStoreData.isFBURLSame = $scope.isFBURLSame ? 1 : 0;
                companyStoreData.isOnlineStore = $scope.isOnlineStore ? 1 : 0;
                companyStoreData.onlineStoreRedeemInfo = $scope.isOnlineStore ? $scope.onlineStoreRedeemInfo : null;
                if ($scope.compStoreAddrCountry == 1) {
                    companyStoreData.compStoreAddrCountry = "Magyarország";
                }
                companyStoreData.compStoreAddrZIP = $scope.compStoreAddrZIP;
                if ($scope.compStoreAddrCity != null) {
                    companyStoreData.compStoreAddrCity = $scope.compStoreAddrCity.placeName;
                } else {
                    companyStoreData.compStoreAddrCity = $scope.compStoreAddrCity;
                }
                companyStoreData.compStoreAddrStreet = $scope.compStoreAddrStreet;
                companyStoreData.compStoreAddrNo = $scope.compStoreAddrNo;
                companyStoreData.isCompStoreAddrSame = $scope.isCompStoreAddrSame ? 1 : 0;
                companyStoreData.compStoreMapImgURL = $scope.compStoreMapImgURL;
                if ($cookies.get('adminUserType')) {
                    companyStoreData.isDisplayedCountrywide = $scope.isDisplayedCountrywide ? 1 : 0;
                }
                if ($scope.yaltyCity != null) {
                    companyStoreData.yaltyCity = $scope.yaltyCity.placeName;
                } else {
                    companyStoreData.yaltyCity = null;
                }
                if ($scope.isDefAroundTheClock) {
                    companyStoreData.defOpeningTimeFrom = "00:00";
                    companyStoreData.defOpeningTimeUntil = "24:00";
                } else {
                    companyStoreData.defOpeningTimeFrom = isBiggerThanZero($scope.defOpeningTimeFromHour, $scope.defOpeningTimeFromMin);
                    companyStoreData.defOpeningTimeUntil = isBiggerThanZero($scope.defOpeningTimeUntilHour, $scope.defOpeningTimeUntilMin);
                }
                companyStoreData.displayPartnerMsgInsteadOfOpeningTimes = $scope.displayPartnerMsgInsteadOfOpeningTimes
                if ($scope.displayPartnerMsgInsteadOfOpeningTimes == true) {
                    companyStoreData.openingTimesPartnerMessage = $scope.openingTimesPartnerMessage;
                } else {
                    companyStoreData.openingTimesPartnerMessage = undefined;
                }
                companyStoreData.isClosedOnMonday = $scope.isClosingTimeMonday ? 1 : 0;
                companyStoreData.openingTimeMondayFrom = isBiggerThanZero($scope.openingTimeMondayFromHour, $scope.openingTimeMondayFromMin);
                companyStoreData.openingTimeMondayUntil = isBiggerThanZero($scope.openingTimeMondayUntilHour, $scope.openingTimeMondayUntilMin);
                companyStoreData.isOpeningTimeMondayDef = $scope.isOpeningTimeMondayDef ? 1 : 0;
                companyStoreData.isClosedOnTuesday = $scope.isClosingTimeTuesday ? 1 : 0;
                companyStoreData.openingTimeTuesdayFrom = isBiggerThanZero($scope.openingTimeTuesdayFromHour, $scope.openingTimeTuesdayFromMin);
                companyStoreData.openingTimeTuesdayUntil = isBiggerThanZero($scope.openingTimeTuesdayUntilHour, $scope.openingTimeTuesdayUntilMin);
                companyStoreData.isOpeningTimeTuesdayDef = $scope.isOpeningTimeTuesdayDef ? 1 : 0;
                companyStoreData.isClosedOnWednesday = $scope.isClosingTimeWednesday ? 1 : 0;
                companyStoreData.openingTimeWednesdayFrom = isBiggerThanZero($scope.openingTimeWednesdayFromHour, $scope.openingTimeWednesdayFromMin);
                companyStoreData.openingTimeWednesdayUntil = isBiggerThanZero($scope.openingTimeWednesdayUntilHour, $scope.openingTimeWednesdayUntilMin);
                companyStoreData.isOpeningTimeWednesdayDef = $scope.isOpeningTimeWednesdayDef ? 1 : 0;
                companyStoreData.isClosedOnThursday = $scope.isClosingTimeThursday ? 1 : 0;
                companyStoreData.openingTimeThursdayFrom = isBiggerThanZero($scope.openingTimeThursdayFromHour, $scope.openingTimeThursdayFromMin);
                companyStoreData.openingTimeThursdayUntil = isBiggerThanZero($scope.openingTimeThursdayUntilHour, $scope.openingTimeThursdayUntilMin);
                companyStoreData.isOpeningTimeThursdayDef = $scope.isOpeningTimeThursdayDef ? 1 : 0;
                companyStoreData.isClosedOnFriday = $scope.isClosingTimeFriday ? 1 : 0;
                companyStoreData.openingTimeFridayFrom = isBiggerThanZero($scope.openingTimeFridayFromHour, $scope.openingTimeFridayFromMin);
                companyStoreData.openingTimeFridayUntil = isBiggerThanZero($scope.openingTimeFridayUntilHour, $scope.openingTimeFridayUntilMin);
                companyStoreData.isOpeningTimeFridayDef = $scope.isOpeningTimeFridayDef ? 1 : 0;
                companyStoreData.isClosedOnSaturday = $scope.isClosingTimeSaturday ? 1 : 0;
                companyStoreData.openingTimeSaturdayFrom = isBiggerThanZero($scope.openingTimeSaturdayFromHour, $scope.openingTimeSaturdayFromMin);
                companyStoreData.openingTimeSaturdayUntil = isBiggerThanZero($scope.openingTimeSaturdayUntilHour, $scope.openingTimeSaturdayUntilMin);
                companyStoreData.isOpeningTimeSaturdayDef = $scope.isOpeningTimeSaturdayDef ? 1 : 0;
                companyStoreData.isClosedOnSunday = $scope.isClosingTimeSunday ? 1 : 0;
                companyStoreData.openingTimeSundayFrom = isBiggerThanZero($scope.openingTimeSundayFromHour, $scope.openingTimeSundayFromMin);
                companyStoreData.openingTimeSundayUntil = isBiggerThanZero($scope.openingTimeSundayUntilHour, $scope.openingTimeSundayUntilMin);
                companyStoreData.isOpeningTimeSundayDef = $scope.isOpeningTimeSundayDef ? 1 : 0;
                companyStoreData.isDefOpeningTimeSame = ($scope.isDefOpeningTimeSame) ? 1 : 0;
                if ($scope.center.lat && $scope.center.lng) {
                    companyStoreData.latitudeCoordinate = $scope.center.lat.toFixed(7);
                    companyStoreData.longitudeCoordinate = $scope.center.lng.toFixed(7);
                } else {
                    companyStoreData.latitudeCoordinate = null;
                    companyStoreData.longitudeCoordinate = null;
                }
                companyStoreData.mapManuallyRepositioned = $scope.mapManuallyRepositioned;
                if ($scope.coordinateMode == "automatic") {
                    companyStoreData.GPSCoordinatesAutoOrManual = 0;
                } else {
                    companyStoreData.GPSCoordinatesAutoOrManual = 1;
                }
                companyStoreData.redeemRestrictionType = $scope.redeemRestrictionType;
                companyStoreData.redeemGeofenceRadius = $scope.redeemGeofenceRadius;
                companyStoreData.isMapSettingsSame = $scope.isMapSettingsSame;

                companyStoreData.isTranslation_EN_Enabled = $scope.isEnglishTranslationEnabled == 'bekapcsolt'
                companyStoreData.compStoreName_EN = $scope['EN'].compStoreName
                companyStoreData.compStoreDescription_EN = $scope['EN'].compStoreDescription
                companyStoreData.webURL_EN = $scope['EN'].webURL ? $scope['EN'].webURL : "";
                if ($scope['EN'].FBURL.split($scope.facebookPrefix)[1]) {
                    companyStoreData.FBURL_EN = $scope['EN'].FBURL.split($scope.facebookPrefix)[1];
                } else {
                    companyStoreData.FBURL_EN = "";
                }
                companyStoreData.onlineStoreRedeemInfo_EN = $scope['EN'].onlineStoreRedeemInfo
                companyStoreData.openingTimesPartnerMessage_EN = $scope['EN'].openingTimesPartnerMessage

                $scope.saveInProgress = true;
                StoreService.addOrUpdate(companyStoreData).then(function (result) {
                    $scope.saveInProgress = false;
                    if (result.errorCode == 0 && !$scope.companyStoreId) {
                        const alert = $mdDialog.alert()
                            .title("Az üzletet rendben létrehoztuk.")
                            .ok('Rendben')
                        $mdDialog.show(alert).then(function () {
                            $rootScope.isDataSheetModified = false;
                            $state.reload();
                        });
                    } else if (result.errorCode == 0 && $scope.companyStoreId) {
                        const alert = $mdDialog.alert()
                            .title("Az üzlet módosításait rendben elmentettük.")
                            .ok('Rendben')
                        $mdDialog.show(alert).then(function () {
                            $rootScope.isDataSheetModified = false;
                            $state.reload();
                        });
                    } else if (result.errorCode == 1) {
                        const alert = $mdDialog.alert()
                            .title("Az üzlet mentése közben váratlan hiba történt. Kérjük próbálja meg a mentést később.")
                            .ok('Rendben')
                        $mdDialog.show(alert)
                    }
                    else if (result.errorCode == 3) {
                        const alert = $mdDialog.alert()
                            .title("Az üzlet mentése sikertelen: ugyanezen a fizikai címen már van egy üzlete. Módosítsa valamelyik üzlet címét.")
                            .ok('Rendben')
                        $mdDialog.show(alert).then(function () {
                            $scope.sendButtonDisabled = false;
                        });
                    } else if (result.errorCode == 4) {
                        $mdDialog.show({
                            template:
                                '<md-dialog>' +
                                '  <md-dialog-content class="md-dialog-content">' +
                                'Elérte a jelenlegi előfizetési csomagja (<b>{{partnerSubscriptionName}}' +
                                '</b>) szerinti létrehozható üzletek maximális számát ({{count}} db üzlet). ' +
                                'Törölje ki az egyik üzletet vagy váltson magasabb előfizetési csomagra. ' +
                                '<a ng-click="navigate()">Az előfizetések áttekintéséhez és módosításához kattintson ide.</a>' +
                                '  </md-dialog-content>' +
                                '  <md-dialog-actions>' +
                                '    <md-button ng-click="closeDialog()" class="md-primary">' +
                                '      Rendben' +
                                '    </md-button>' +
                                '  </md-dialog-actions>' +
                                '</md-dialog>',
                            locals: {
                                partnerSubscriptionType: $rootScope.partnerSubscriptionType,
                                partnerSubscriptionName: SubscriptionNames[$rootScope.partnerSubscriptionType],
                                count: companyStoreCount
                            },
                            controller: DialogCtrl
                        });
                    } else if (result.errorCode == 5) {
                        const alert = $mdDialog.alert()
                            .title("Sikertelen művelet")
                            .textContent("A művelet elvégzéséhez nincs jogosultsága. Ezért kiléptetjük az oldalról.")
                            .ok('Rendben')
                        $mdDialog.show(alert).then(function () {
                            $rootScope.isDataSheetModified = false;
                            AuthService.logout()
                        });
                    } else if (result.errorCode == 6) {
                        const alert = $mdDialog.alert()
                            .title("Az üzlet mentése sikertelen: a 'Beváltási korlátozás' mező kiválasztott értéke nem választható! Módosítsa másik értékre.")
                            .textContent("Amennyiben cégének üzleteire NFC és/vagy QR kód alapú beváltási korlátozást választott korábban, akkor válasszon ezen lehetőségek közül, ellenkező esetben pedig válassza a GPS távolság szerinti figyelmeztetés/korlátozás lehetőségek egyikét, esetleg a korlátozás megszüntetését.")
                            .ok('Rendben')
                        $mdDialog.show(alert).then(function () {
                            $rootScope.isDataSheetModified = false;
                            $state.reload();
                        });
                    } else {
                        const alert = $mdDialog.alert()
                            .title(result.msg)
                            .textContent(result.msg)
                            .ok('Rendben')
                        $mdDialog.show(alert).then(function () {
                            $scope.sendButtonDisabled = false;
                        });
                    }
                }, function (result) {
                    $scope.saveInProgress = false;
                    var alert = $mdDialog.alert()
                        .title(result.msg)
                        .textContent(result.msg)
                        .ok('Rendben')
                    $mdDialog.show(alert).then(function () {
                    });
                });
            }
        };

        $scope.setMapSettings = function () {
            $rootScope.isDataSheetModified = true;
            if ($scope.isMapSettingsSame == true) {
                $scope.center.lat = $scope.dataCompany.latitudeCoordinate;
                $scope.center.lng = $scope.dataCompany.longitudeCoordinate;
                if ($scope.dataCompany.GPSCoordinatesAutoOrManual == 0) {
                    $scope.coordinateMode = "automatic";
                    $scope.mapManuallyReposition = $scope.dataCompany.mapManuallyRepositioned;
                    $scope.mapManuallyRepositioned = $scope.dataCompany.mapManuallyRepositioned;
                } else {
                    $scope.coordinateMode = "manual";
                    $scope.mapManuallyReposition = 1;
                    $scope.mapManuallyRepositioned = $scope.dataCompany.mapManuallyRepositioned;
                }
                Map.init().then(function () {
                    $scope.center.lat = $scope.dataCompany.latitudeCoordinate;
                    $scope.center.lng = $scope.dataCompany.longitudeCoordinate;
                    googleMap = Map.getMap();
                    googleMap.addListener("zoom_changed", () => {
                        $scope.refreshMapView()
                    })
                    $scope.isAddress = false;
                    Map.setCenterFromCoordinates($scope.center.lat, $scope.center.lng);
                    setStaticMapView();
                })
            } else {
                $scope.mapManuallyReposition = 0;
                $scope.mapManuallyRepositioned = 0;
                $scope.coordinateMode = "automatic";
                if (($scope.compStoreAddrCountry && $scope.compStoreAddrZIP && $scope.compStoreAddrCity.placeName && $scope.compStoreAddrStreet && $scope.compStoreAddrNo) != null) {
                    setMapView();
                    refreshMapView();
                    $scope.isAddress = false;
                    $scope.isSameAddress = { "color": "black" };
                } else {
                    $scope.center.lat = null;
                    $scope.center.lng = null;
                    $scope.isAddress = true;
                    $scope.isSameAddress = { "color": "#4E525C" };
                }
            }
        }
        $scope.isClosingTime = function (day) {
            $rootScope.isDataSheetModified = true;
            if (day == 1) {
                $scope.isOpeningTimeMondayDef = false;
                $scope.isMonAroundTheClock = false;
                $scope.openingTimeMondayFromHour = null;
                $scope.openingTimeMondayFromMin = null;
                $scope.openingTimeMondayUntilHour = null;
                $scope.openingTimeMondayUntilMin = null;
            } else if (day == 2) {
                $scope.isOpeningTimeTuesdayDef = false;
                $scope.isTueAroundTheClock = false;
                $scope.openingTimeTuesdayFromHour = null;
                $scope.openingTimeTuesdayFromMin = null;
                $scope.openingTimeTuesdayUntilHour = null;
                $scope.openingTimeTuesdayUntilMin = null;
            } else if (day == 3) {
                $scope.isOpeningTimeWednesdayDef = false;
                $scope.isWedAroundTheClock = false;
                $scope.openingTimeWednesdayFromHour = null;
                $scope.openingTimeWednesdayFromMin = null;
                $scope.openingTimeWednesdayUntilHour = null;
                $scope.openingTimeWednesdayUntilMin = null;
            } else if (day == 4) {
                $scope.isOpeningTimeThursdayDef = false;
                $scope.isThuAroundTheClock = false;
                $scope.openingTimeThursdayFromHour = null;
                $scope.openingTimeThursdayFromMin = null;
                $scope.openingTimeThursdayUntilHour = null;
                $scope.openingTimeThursdayUntilMin = null;
            } else if (day == 5) {
                $scope.isOpeningTimeFridayDef = false;
                $scope.isFriAroundTheClock = false;
                $scope.openingTimeFridayFromHour = null;
                $scope.openingTimeFridayFromMin = null;
                $scope.openingTimeFridayUntilHour = null;
                $scope.openingTimeFridayUntilMin = null;
            } else if (day == 6) {
                $scope.isOpeningTimeSaturdayDef = false;
                $scope.isSatAroundTheClock = false;
                $scope.openingTimeSaturdayFromHour = null;
                $scope.openingTimeSaturdayFromMin = null;
                $scope.openingTimeSaturdayUntilHour = null;
                $scope.openingTimeSaturdayUntilMin = null;
            } else if (day == 7) {
                $scope.isOpeningTimeSundayDef = false;
                $scope.isSunAroundTheClock = false;
                $scope.openingTimeSundayFromHour = null;
                $scope.openingTimeSundayFromMin = null;
                $scope.openingTimeSundayUntilHour = null;
                $scope.openingTimeSundayUntilMin = null;
            }
        }
        const isOpeningTimeSame = function (day) {
            $rootScope.isDataSheetModified = true;
            if (day == 0) {
                if ($scope.isOpeningTimeMondayDef) {
                    $scope.openingTimeMondayFromHour = $scope.defOpeningTimeFromHour;
                    $scope.openingTimeMondayFromMin = $scope.defOpeningTimeFromMin;
                    $scope.openingTimeMondayUntilHour = $scope.defOpeningTimeUntilHour;
                    $scope.openingTimeMondayUntilMin = $scope.defOpeningTimeUntilMin;
                }
                if ($scope.isOpeningTimeTuesdayDef) {
                    $scope.openingTimeTuesdayFromHour = $scope.defOpeningTimeFromHour;
                    $scope.openingTimeTuesdayFromMin = $scope.defOpeningTimeFromMin;
                    $scope.openingTimeTuesdayUntilHour = $scope.defOpeningTimeUntilHour;
                    $scope.openingTimeTuesdayUntilMin = $scope.defOpeningTimeUntilMin;
                }
                if ($scope.isOpeningTimeWednesdayDef) {
                    $scope.openingTimeWednesdayFromHour = $scope.defOpeningTimeFromHour;
                    $scope.openingTimeWednesdayFromMin = $scope.defOpeningTimeFromMin;
                    $scope.openingTimeWednesdayUntilHour = $scope.defOpeningTimeUntilHour;
                    $scope.openingTimeWednesdayUntilMin = $scope.defOpeningTimeUntilMin;
                }
                if ($scope.isOpeningTimeThursdayDef) {
                    $scope.openingTimeThursdayFromHour = $scope.defOpeningTimeFromHour;
                    $scope.openingTimeThursdayFromMin = $scope.defOpeningTimeFromMin;
                    $scope.openingTimeThursdayUntilHour = $scope.defOpeningTimeUntilHour;
                    $scope.openingTimeThursdayUntilMin = $scope.defOpeningTimeUntilMin;
                }
                if ($scope.isOpeningTimeFridayDef) {
                    $scope.openingTimeFridayFromHour = $scope.defOpeningTimeFromHour;
                    $scope.openingTimeFridayFromMin = $scope.defOpeningTimeFromMin;
                    $scope.openingTimeFridayUntilHour = $scope.defOpeningTimeUntilHour;
                    $scope.openingTimeFridayUntilMin = $scope.defOpeningTimeUntilMin;
                }
                if ($scope.isOpeningTimeSaturdayDef) {
                    $scope.openingTimeSaturdayFromHour = $scope.defOpeningTimeFromHour;
                    $scope.openingTimeSaturdayFromMin = $scope.defOpeningTimeFromMin;
                    $scope.openingTimeSaturdayUntilHour = $scope.defOpeningTimeUntilHour;
                    $scope.openingTimeSaturdayUntilMin = $scope.defOpeningTimeUntilMin;
                }
                if ($scope.isOpeningTimeSundayDef) {
                    $scope.openingTimeSundayFromHour = $scope.defOpeningTimeFromHour;
                    $scope.openingTimeSundayFromMin = $scope.defOpeningTimeFromMin;
                    $scope.openingTimeSundayUntilHour = $scope.defOpeningTimeUntilHour;
                    $scope.openingTimeSundayUntilMin = $scope.defOpeningTimeUntilMin;
                }
            } else if (day == 1) {
                $scope.isMonAroundTheClock = false;
                $scope.isClosingTimeMonday = false;
                if ($scope.isOpeningTimeMondayDef) {
                    $scope.openingTimeMondayFromHour = $scope.defOpeningTimeFromHour;
                    $scope.openingTimeMondayFromMin = $scope.defOpeningTimeFromMin;
                    $scope.openingTimeMondayUntilHour = $scope.defOpeningTimeUntilHour;
                    $scope.openingTimeMondayUntilMin = $scope.defOpeningTimeUntilMin;
                } else {
                    $scope.openingTimeMondayFromHour = null;
                    $scope.openingTimeMondayFromMin = null;
                    $scope.openingTimeMondayUntilHour = null;
                    $scope.openingTimeMondayUntilMin = null;
                }
            } else if (day == 2) {
                $scope.isTueAroundTheClock = false;
                $scope.isClosingTimeTuesday = false;
                if ($scope.isOpeningTimeTuesdayDef) {
                    $scope.openingTimeTuesdayFromHour = $scope.defOpeningTimeFromHour;
                    $scope.openingTimeTuesdayFromMin = $scope.defOpeningTimeFromMin;
                    $scope.openingTimeTuesdayUntilHour = $scope.defOpeningTimeUntilHour;
                    $scope.openingTimeTuesdayUntilMin = $scope.defOpeningTimeUntilMin;
                } else {
                    $scope.openingTimeTuesdayFromHour = null;
                    $scope.openingTimeTuesdayFromMin = null;
                    $scope.openingTimeTuesdayUntilHour = null;
                    $scope.openingTimeTuesdayUntilMin = null;
                }
            } else if (day == 3) {
                $scope.isWedAroundTheClock = false;
                $scope.isClosingTimeWednesday = false;
                if ($scope.isOpeningTimeWednesdayDef) {
                    $scope.openingTimeWednesdayFromHour = $scope.defOpeningTimeFromHour;
                    $scope.openingTimeWednesdayFromMin = $scope.defOpeningTimeFromMin;
                    $scope.openingTimeWednesdayUntilHour = $scope.defOpeningTimeUntilHour;
                    $scope.openingTimeWednesdayUntilMin = $scope.defOpeningTimeUntilMin;
                } else {
                    $scope.openingTimeWednesdayFromHour = null;
                    $scope.openingTimeWednesdayFromMin = null;
                    $scope.openingTimeWednesdayUntilHour = null;
                    $scope.openingTimeWednesdayUntilMin = null;
                }
            } else if (day == 4) {
                $scope.isThuAroundTheClock = false;
                $scope.isClosingTimeThursday = false;
                if ($scope.isOpeningTimeThursdayDef) {
                    $scope.openingTimeThursdayFromHour = $scope.defOpeningTimeFromHour;
                    $scope.openingTimeThursdayFromMin = $scope.defOpeningTimeFromMin;
                    $scope.openingTimeThursdayUntilHour = $scope.defOpeningTimeUntilHour;
                    $scope.openingTimeThursdayUntilMin = $scope.defOpeningTimeUntilMin;
                } else {
                    $scope.openingTimeThursdayFromHour = null;
                    $scope.openingTimeThursdayFromMin = null;
                    $scope.openingTimeThursdayUntilHour = null;
                    $scope.openingTimeThursdayUntilMin = null;
                }
            } else if (day == 5) {
                $scope.isFriAroundTheClock = false;
                $scope.isClosingTimeFriday = false;
                if ($scope.isOpeningTimeFridayDef) {
                    $scope.openingTimeFridayFromHour = $scope.defOpeningTimeFromHour;
                    $scope.openingTimeFridayFromMin = $scope.defOpeningTimeFromMin;
                    $scope.openingTimeFridayUntilHour = $scope.defOpeningTimeUntilHour;
                    $scope.openingTimeFridayUntilMin = $scope.defOpeningTimeUntilMin;
                } else {
                    $scope.openingTimeFridayFromHour = null;
                    $scope.openingTimeFridayFromMin = null;
                    $scope.openingTimeFridayUntilHour = null;
                    $scope.openingTimeFridayUntilMin = null;
                }
            } else if (day == 6) {
                $scope.isSatAroundTheClock = false;
                $scope.isClosingTimeSaturday = false;
                if ($scope.isOpeningTimeSaturdayDef) {
                    $scope.openingTimeSaturdayFromHour = $scope.defOpeningTimeFromHour;
                    $scope.openingTimeSaturdayFromMin = $scope.defOpeningTimeFromMin;
                    $scope.openingTimeSaturdayUntilHour = $scope.defOpeningTimeUntilHour;
                    $scope.openingTimeSaturdayUntilMin = $scope.defOpeningTimeUntilMin;
                } else {
                    $scope.openingTimeSaturdayFromHour = null;
                    $scope.openingTimeSaturdayFromMin = null;
                    $scope.openingTimeSaturdayUntilHour = null;
                    $scope.openingTimeSaturdayUntilMin = null;
                }
            } else if (day == 7) {
                $scope.isSunAroundTheClock = false;
                $scope.isClosingTimeSunday = false;
                if ($scope.isOpeningTimeSundayDef) {
                    $scope.openingTimeSundayFromHour = $scope.defOpeningTimeFromHour;
                    $scope.openingTimeSundayFromMin = $scope.defOpeningTimeFromMin;
                    $scope.openingTimeSundayUntilHour = $scope.defOpeningTimeUntilHour;
                    $scope.openingTimeSundayUntilMin = $scope.defOpeningTimeUntilMin;
                } else {
                    $scope.openingTimeSundayFromHour = null;
                    $scope.openingTimeSundayFromMin = null;
                    $scope.openingTimeSundayUntilHour = null;
                    $scope.openingTimeSundayUntilMin = null;
                }
            }
        }
        $scope.isOpeningTimeSame = function (day) {
            isOpeningTimeSame(day);
        }
        $scope.isAroundTheClock = function (param) {
            $rootScope.isDataSheetModified = true;
            if (param == 'def') {
                $scope.isDefOpeningTimeSame = false;
                $scope.defOpeningTimeFromHour = 0;
                $scope.defOpeningTimeFromMin = 0;
                $scope.defOpeningTimeUntilHour = 24;
                $scope.defOpeningTimeUntilMin = 0;
                isOpeningTimeSame(0);
            } else if (param == 'mon') {
                $scope.isOpeningTimeMondayDef = false;
                $scope.isClosingTimeMonday = false;
                $scope.openingTimeMondayFromHour = 0;
                $scope.openingTimeMondayFromMin = 0;
                $scope.openingTimeMondayUntilHour = 24;
                $scope.openingTimeMondayUntilMin = 0;
            } else if (param == 'tue') {
                $scope.isOpeningTimeTuesdayDef = false;
                $scope.isClosingTimeTuesday = false;
                $scope.openingTimeTuesdayFromHour = 0;
                $scope.openingTimeTuesdayFromMin = 0;
                $scope.openingTimeTuesdayUntilHour = 24;
                $scope.openingTimeTuesdayUntilMin = 0;
            } else if (param == 'wed') {
                $scope.isOpeningTimeWednesdayDef = false;
                $scope.isClosingTimeWednesday = false;
                $scope.openingTimeWednesdayFromHour = 0;
                $scope.openingTimeWednesdayFromMin = 0;
                $scope.openingTimeWednesdayUntilHour = 24;
                $scope.openingTimeWednesdayUntilMin = 0;
            } else if (param == 'thu') {
                $scope.isOpeningTimeThursdayDef = false;
                $scope.isClosingTimeThursday = false;
                $scope.openingTimeThursdayFromHour = 0;
                $scope.openingTimeThursdayFromMin = 0;
                $scope.openingTimeThursdayUntilHour = 24;
                $scope.openingTimeThursdayUntilMin = 0;
            } else if (param == 'fri') {
                $scope.isOpeningTimeFridayDef = false;
                $scope.isClosingTimeFriday = false;
                $scope.openingTimeFridayFromHour = 0;
                $scope.openingTimeFridayFromMin = 0;
                $scope.openingTimeFridayUntilHour = 24;
                $scope.openingTimeFridayUntilMin = 0;
            } else if (param == 'sat') {
                $scope.isOpeningTimeSaturdayDef = false;
                $scope.isClosingTimeSaturday = false;
                $scope.openingTimeSaturdayFromHour = 0;
                $scope.openingTimeSaturdayFromMin = 0;
                $scope.openingTimeSaturdayUntilHour = 24;
                $scope.openingTimeSaturdayUntilMin = 0;
            } else if (param == 'sun') {
                $scope.isOpeningTimeSundayDef = false;
                $scope.isClosingTimeSunday = false;
                $scope.openingTimeSundayFromHour = 0;
                $scope.openingTimeSundayFromMin = 0;
                $scope.openingTimeSundayUntilHour = 24;
                $scope.openingTimeSundayUntilMin = 0;
            }
        }
        $scope.isSameTime = function (param) {
            $rootScope.isDataSheetModified = true;
            if (param == "defFrom") {
                if ($scope.isDefOpeningTimeSame) {
                    if ($scope.defOpeningTimeFromHour != getHour($scope.dataCompany.defOpeningTimeFrom)) {
                        $scope.isDefOpeningTimeSame = false;
                    }
                    if ($scope.defOpeningTimeFromMin != getMin($scope.dataCompany.defOpeningTimeFrom)) {
                        $scope.isDefOpeningTimeSame = false;
                    }
                    if ($scope.defOpeningTimeUntilHour != getHour($scope.dataCompany.defOpeningTimeUntil)) {
                        $scope.isDefOpeningTimeSame = false;
                    }
                    if ($scope.defOpeningTimeUntilMin != getMin($scope.dataCompany.defOpeningTimeUntil)) {
                        $scope.isDefOpeningTimeSame = false;
                    }
                }
            } else if (param == "monFrom") {
                if ($scope.isOpeningTimeMondayDef) {
                    if ($scope.openingTimeMondayFromHour != $scope.defOpeningTimeFromHour) {
                        $scope.isOpeningTimeMondayDef = false;
                    }
                    if ($scope.openingTimeMondayFromMin != $scope.defOpeningTimeFromMin) {
                        $scope.isOpeningTimeMondayDef = false;
                    }
                    if ($scope.openingTimeMondayUntilHour != $scope.defOpeningTimeUntilHour) {
                        $scope.isOpeningTimeMondayDef = false;
                    }
                    if ($scope.openingTimeMondayUntilMin != $scope.defOpeningTimeUntilMin) {
                        $scope.isOpeningTimeMondayDef = false;
                    }
                }
            } else if (param == "tueFrom") {
                if ($scope.isOpeningTimeTuesdayDef) {
                    if ($scope.openingTimeTuesdayFromHour != $scope.defOpeningTimeFromHour) {
                        $scope.isOpeningTimeTuesdayDef = false;
                    }
                    if ($scope.openingTimeTuesdayFromMin != $scope.defOpeningTimeFromMin) {
                        $scope.isOpeningTimeTuesdayDef = false;
                    }
                    if ($scope.openingTimeTuesdayUntilHour != $scope.defOpeningTimeUntilHour) {
                        $scope.isOpeningTimeTuesdayDef = false;
                    }
                    if ($scope.openingTimeTuesdayUntilMin != $scope.defOpeningTimeUntilMin) {
                        $scope.isOpeningTimeTuesdayDef = false;
                    }
                }
            } else if (param == "wedFrom") {
                if ($scope.isOpeningTimeWednesdayDef) {
                    if ($scope.openingTimeWednesdayFromHour != $scope.defOpeningTimeFromHour) {
                        $scope.isOpeningTimeWednesdayDef = false;
                    }
                    if ($scope.openingTimeWednesdayFromMin != $scope.defOpeningTimeFromMin) {
                        $scope.isOpeningTimeWednesdayDef = false;
                    }
                    if ($scope.openingTimeWednesdayUntilHour != $scope.defOpeningTimeUntilHour) {
                        $scope.isOpeningTimeWednesdayDef = false;
                    }
                    if ($scope.openingTimeWednesdayUntilMin != $scope.defOpeningTimeUntilMin) {
                        $scope.isOpeningTimeWednesdayDef = false;
                    }
                }
            } else if (param == "thuFrom") {
                if ($scope.isOpeningTimeThursdayDef) {
                    if ($scope.openingTimeThursdayFromHour != $scope.defOpeningTimeFromHour) {
                        $scope.isOpeningTimeThursdayDef = false;
                    }
                    if ($scope.openingTimeThursdayFromMin != $scope.defOpeningTimeFromMin) {
                        $scope.isOpeningTimeThursdayDef = false;
                    }
                    if ($scope.openingTimeThursdayUntilHour != $scope.defOpeningTimeUntilHour) {
                        $scope.isOpeningTimeThursdayDef = false;
                    }
                    if ($scope.openingTimeThursdayUntilMin != $scope.defOpeningTimeUntilMin) {
                        $scope.isOpeningTimeThursdayDef = false;
                    }
                }
            } else if (param == "friFrom") {
                if ($scope.isOpeningTimeFridayDef) {
                    if ($scope.openingTimeFridayFromHour != $scope.defOpeningTimeFromHour) {
                        $scope.isOpeningTimeFridayDef = false;
                    }
                    if ($scope.openingTimeFridayFromMin != $scope.defOpeningTimeFromMin) {
                        $scope.isOpeningTimeFridayDef = false;
                    }
                    if ($scope.openingTimeFridayUntilHour != $scope.defOpeningTimeUntilHour) {
                        $scope.isOpeningTimeFridayDef = false;
                    }
                    if ($scope.openingTimeFridayUntilMin != $scope.defOpeningTimeUntilMin) {
                        $scope.isOpeningTimeFridayDef = false;
                    }
                }
            } else if (param == "satFrom") {
                if ($scope.isOpeningTimeSaturdayDef) {
                    if ($scope.openingTimeSaturdayFromHour != $scope.defOpeningTimeFromHour) {
                        $scope.isOpeningTimeSaturdayDef = false;
                    }
                    if ($scope.openingTimeSaturdayFromMin != $scope.defOpeningTimeFromMin) {
                        $scope.isOpeningTimeSaturdayDef = false;
                    }
                    if ($scope.openingTimeSaturdayUntilHour != $scope.defOpeningTimeUntilHour) {
                        $scope.isOpeningTimeSaturdayDef = false;
                    }
                    if ($scope.openingTimeSaturdayUntilMin != $scope.defOpeningTimeUntilMin) {
                        $scope.isOpeningTimeSaturdayDef = false;
                    }
                }
            } else if (param == "sunFrom") {
                if ($scope.isOpeningTimeSundayDef) {
                    if ($scope.openingTimeSundayFromHour != $scope.defOpeningTimeFromHour) {
                        $scope.isOpeningTimeSundayDef = false;
                    }
                    if ($scope.openingTimeSundayFromMin != $scope.defOpeningTimeFromMin) {
                        $scope.isOpeningTimeSundayDef = false;
                    }
                    if ($scope.openingTimeSundayUntilHour != $scope.defOpeningTimeUntilHour) {
                        $scope.isOpeningTimeSundayDef = false;
                    }
                    if ($scope.openingTimeSundayUntilMin != $scope.defOpeningTimeUntilMin) {
                        $scope.isOpeningTimeSundayDef = false;
                    }
                }
            }
        }
        const resetVariables = function () {
            if ($rootScope.companyCropperData.cropper) {
                $rootScope.companyCropperData.cropper.destroy();
            }
            $scope.googleMapIsInvalid = false;
            delete $rootScope.companyCropperData;
            $rootScope.companyCropperData = {};
            $scope.compStoreName = null;
            $scope.compDescription = null;
            $scope.publicContactPhone = null;
            $scope.webURL = null
            $scope.FBURL = $scope.facebookPrefix;
            $scope.onlineStoreRedeemInfo = null;
            $scope.displayPartnerMsgInsteadOfOpeningTimes = false;
            $scope.openingTimesPartnerMessage = undefined;
            $rootScope.companyCropperData = {};
            $rootScope.isSelectedFile = false
            $scope.isCompStoreAddrSame = null;
            $scope.compStoreAddrCountry = null;
            $scope.compStoreAddrZIP = null;
            $scope.compStoreAddrCity = "";
            $scope.compStoreAddrStreet = null;
            $scope.compStoreAddrNo = null;
            $scope.compStoreMapImgURL = null;
            $scope.isDefOpeningTimeSame = true;
            //$scope.defOpeningTimeFrom = null;
            //$scope.defOpeningTimeUntil = null;
            $scope.isClosingTimeMonday = null;
            $scope.isClosingTimeTuesday = null;
            $scope.isClosingTimeWednesday = null;
            $scope.isClosingTimeThursday = null;
            $scope.isClosingTimeFriday = null;
            $scope.isClosingTimeSaturday = null;
            $scope.isClosingTimeSunday = null;
            $scope.isMonAroundTheClock = null;
            $scope.isTueAroundTheClock = null;
            $scope.isWedAroundTheClock = null;
            $scope.isThuAroundTheClock = null;
            $scope.isFriAroundTheClock = null;
            $scope.isSatAroundTheClock = null;
            $scope.isSunAroundTheClock = null;
            $scope.companyStoreId = null;
            $scope.mapManuallyRepositioned = 0;
            $scope.mapManuallyReposition = 0;
            $scope.coordinateMode = "automatic";
            $scope.isAddress = true;
            $scope.isOnlineStore = false;
            $scope.isDisplayedCountrywide = false;
            $scope.center = {};
            $scope.querySearch();
            $rootScope.isDataSheetModified = false;
            $scope.isHolidayOpeningTimesSame = true;
        }
        var setStore = function () {
            $scope.buttonClass = "btn";
            $scope.animateMobile = "yalty-animateTo-mobile";
            $scope.openVirtual = { "margin-top": "0px", "margin-left": "7px" };
            $scope.showStores = false;
            $scope.newStore = "ÚJ ÜZLET";
            $scope.showSetStore = true;
            $scope.isOpened = "fa fa-chevron-down";
            $scope.newButtonClass = "activeBtn";
            resetVariables();
            var date = new Date();
            var year = $filter('date')(date, "yyyy");
            var month = $filter('date')(date, "MM");
            var day = $filter('date')(date, "dd");
            var monthInHungarian = getMonth(month);
            $scope.modifiedDate = year + ". " + monthInHungarian + " " + day + ".";
            $rootScope.companyCropperData.compStoreImage = $scope.dataCompany.compImgURL;
            $scope.companyImage = $rootScope.companyCropperData.compStoreImage;
            $scope.isCompStoreImgSame = true;
            $scope.compStoreName = $scope.dataCompany.compNamePromo;
            $scope.isCompStoreNameSame = true;
            $scope.compDescription = $scope.dataCompany.compDescription;
            $scope.isCompStoreDescriptionSame = true;
            $scope.publicContactPhone = $scope.dataCompany.publicContactPhone;
            $scope.isPublicContactPhone = true;
            $scope.webURL = $scope.dataCompany.webURL;
            $scope.isWebURLSame = true;
            $scope.FBURL += $scope.dataCompany.FBURL;
            $scope.isFBURLSame = true;
            $scope.isOpeningTimeMondayDef = true;
            $scope.isOpeningTimeTuesdayDef = true;
            $scope.isOpeningTimeWednesdayDef = true;
            $scope.isOpeningTimeThursdayDef = true;
            $scope.isOpeningTimeFridayDef = true;
            $scope.isOpeningTimeSaturdayDef = true;
            $scope.isOpeningTimeSundayDef = true;
            $scope.compStoreAddrCountry = 1;
            $scope.isMapSettingsSame = false;
            $scope.holidayOpeningTimes = $scope.dataCompany.defHolidayOpeningTimes;
            $scope.holidayOpeningTimesUntil = $scope.dataCompany.defHolidayOpeningTimesUntil;
            let rrt = 2;
            let rgr = 500;
            if ($scope.dataCompany != null && $scope.dataCompany.redeemRestrictionType == 2) {
                rrt = 3;
                rgr = 0;
            } else if ($scope.dataCompany != null && $scope.dataCompany.redeemRestrictionType == 3) {
                rrt = 5;
                rgr = 0;
            }
            $scope.redeemRestrictionType = rrt;
            $scope.redeemGeofenceRadius = rgr;
            $scope.yaltyCity = null;
            isSameCompanyOpen();

            $scope.isEnglishTranslationEnabled = $scope.dataCompany.isTranslation_EN_Enabled ? 'bekapcsolt' : 'kikapcsolt';
            $scope.isEnglishTranslationEnabledPreviousValue = $scope.isEnglishTranslationEnabled;
            $scope.translationEnabled = $scope.dataCompany.isTranslation_EN_Enabled;
            $scope.languages = [
                { id: 1, listName: 'Magyar (kötelező)', url: "../imgs/translation_flag_HU.png" },
                {
                    id: 2, listName: `Angol (${$scope.isEnglishTranslationEnabled})`,
                    url: "../imgs/translation_flag_EN.png",
                    windowTitleName: 'ANGOL', name: 'Angol', code: 'EN'
                }
            ]
            $scope.languageSelector = 1
            $scope['EN'] = {}
            $scope['EN'].FBURL = $scope.facebookPrefix
            $scope.translatedFBURL = $scope.facebookPrefix
            $scope.partnerData = {}
            $scope.partnerData.translatedCompStoreName = $scope.dataCompany.compNamePromo_EN
            $scope.partnerData.translatedCompStoreDescription = $scope.dataCompany.compDescription_EN
            $scope.partnerData.translatedWebURL = $scope.dataCompany.webURL_EN_Company
            $scope.partnerData.translatedFBURL = $scope.dataCompany.FBURL_EN_Company

            $rootScope.isDataSheetModified = false;

        }
        $scope.setStore = function () {
            if ($rootScope.isDataSheetModified == false) {
                setStore();
            } else {
                var confirm = $mdDialog.confirm()
                    .title('Biztosan el szeretné hagyni az oldalt?')
                    .textContent('Az adatlapon történt módosításai mentés nélkül elvesznek.')
                    .ariaLabel('Oldal elhagyás megerősítése')
                    .ok('Igen')
                    .cancel('Mégsem');
                $mdDialog.show(confirm).then(function () {
                    $rootScope.isDataSheetModified = false;
                    $window.scrollTo(0, 0);
                    setStore();
                }, function () {
                });
            }
        }
        $scope.showPromotions = function () {
            if (document.getElementById('overlayPromotions')) {
                document.getElementById('overlayPromotions').style.display = "block";
                document.getElementById('innerPartnerPageContainer').style.overflow = "hidden";
            }
        }
        $scope.hidePromotions = function () {
            if (document.getElementById('overlayPromotions')) {
                document.getElementById('overlayPromotions').style.display = "none";
                document.getElementById('innerPartnerPageContainer').style.overflow = "unset";
            }
        }
        $scope.navigateToCoupon = function (id) {
            var newTab = $window.open("", "_blank");
            newTab.location = "/partner/home/coupons-our-coupons/" + id;
        }
        $scope.navigateToLoyaltyCardYalty = function (id) {
            $state.go('home.huseg2', { loyaltyCardId: id })
        }
        $scope.navigateToLoyaltyCard = function (id) {
            $state.go('home.huseg5', { loyaltyCardId: id })
        }
        $scope.hungarianMonth = function (date) {
            var year = $filter('date')(date, "yyyy");
            var month = $filter('date')(date, "MM");
            var day = $filter('date')(date, "dd");
            var monthInHungarian = getMonth(month);
            return year + ". " + monthInHungarian + " " + day + ".";
        }
        var modifyStore = function (companyStoreId, isTemplate) {
            resetVariables();
            StoreService.modifyStore(companyStoreId).then(function (result) {
                if (!isTemplate) {
                    $scope.companyStoreId = companyStoreId;
                    $scope.compStoreName = result.compStoreName;
                    $rootScope.companyCropperData.compStoreImage = result.compStoreImgURL;
                } else {
                    if (!result.isCompStoreImgSame) {
                        var img = new Image();
                        var canvas = document.getElementById("compStoreImgCanvas");
                        var context = canvas.getContext("2d");
                        img.src = result.compStoreImgURL;
                        img.onload = function () {
                            context.canvas.height = img.height;
                            context.canvas.width = img.width;
                            context.drawImage(img, 0, 0);
                            $rootScope.companyCropperData.compStoreImage = canvas.toDataURL("image/jpeg", 0.9).replace("image/jpeg", "image/jpg");
                        }
                    } else {
                        $rootScope.companyCropperData.compStoreImage = result.compStoreImgURL;
                    }
                    /*if (result.compStoreName != null && result.compStoreName != "") {
                        if ((result.compStoreName + " (MÁSOLAT)").length <= 60) {*/
                    $scope.compStoreName = result.compStoreName/* + " (MÁSOLAT)";
                        } else {
                            var slice;
                            if (result.compStoreName.length > 47) {
                                slice = result.compStoreName.slice(0, 47);
                            }
                            $scope.compStoreName = slice + "... (MÁSOLAT)";
                        }
                    } else {
                        $scope.compStoreName = null;
                    }*/
                }
                $scope.showSetStore = true;
                $scope.buttonClass = "btn";
                $scope.newButtonClass = "btn";
                $scope.animateMobile = "yalty-animateTo-mobile";
                $scope.showStores = false;
                $scope.openVirtual = { "margin-top": "0px", "margin-left": "7px" };
                $scope.compStoreDetail = result;
                var year = $filter('date')(result.lastUpdatedTimestamp, "yyyy");
                var month = $filter('date')(result.lastUpdatedTimestamp, "MM");
                var day = $filter('date')(result.lastUpdatedTimestamp, "dd");
                var monthInHungarian = getMonth(month);
                $scope.modifiedDate = year + ". " + monthInHungarian + " " + day + ".";
                $scope.companyImage = result.compStoreImgURL;
                $scope.isCompStoreImgSame = result.isCompStoreImgSame;
                $scope.isCompStoreNameSame = result.isCompStoreNameSame;
                $scope.compDescription = "";
                $scope.compDescription = result.compStoreDescription;
                $scope.isCompStoreDescriptionSame = result.isCompStoreDescriptionSame;
                $scope.publicContactPhone = result.publicContactPhone;
                $scope.isPublicContactPhone = result.isPublicContactPhone;
                $scope.webURL = result.webURL;
                $scope.isWebURLSame = result.isWebURLSame;
                $scope.FBURL = $scope.facebookPrefix + result.FBURL;
                $scope.isFBURLSame = result.isFBURLSame;
                $scope.isOnlineStore = result.isOnlineStore;
                $scope.onlineStoreRedeemInfo = result.onlineStoreRedeemInfo;
                $scope.isCompStoreAddrSame = result.isCompStoreAddrSame;
                if (result.compStoreAddrCountry == null && result.compStoreAddrCity == null && result.compStoreAddrStreet == null && result.compStoreAddrZIP == null && result.compStoreAddrNo == null) {
                    $scope.isAddress = true;
                    $scope.isSameAddress = { "color": "#DFDFDF", "border-color": "#DFDFDF" };
                    $scope.coordinate = { "color": "#DFDFDF", "text-align": "left" };
                } else {
                    $scope.isAddress = false;
                    $scope.isSameAddress = { "color": "#4E525C", "border-color": "#4E525C" };
                    $scope.coordinate = { "color": "black", "text-align": "left" };
                    if (result.compStoreAddrCountry == "Magyarország") {
                        $scope.compStoreAddrCountry = 1;
                    }
                    $scope.compStoreAddrZIP = result.compStoreAddrZIP;
                    var index = $scope.listOfCities.findIndex(record => record.placeName === result.compStoreAddrCity)
                    $scope.compStoreAddrCity = $scope.listOfCities[index];
                    $scope.compStoreAddrStreet = result.compStoreAddrStreet;
                    $scope.compStoreAddrNo = result.compStoreAddrNo;
                    $scope.compStoreMapImgURL = result.compStoreMapImgURL;
                }
                if (result.isCompStoreAddrSame == true) {
                    $scope.isSameAddress = { "color": "black" };
                }
                $scope.mapManuallyRepositioned = result.mapManuallyRepositioned;
                $scope.mapManuallyReposition = result.mapManuallyRepositioned;
                if (result.GPSCoordinatesAutoOrManual == 0) {
                    $scope.coordinateMode = "automatic";
                    $scope.compStoreDetail.coordinateMode = "automatic";
                } else {
                    $scope.coordinateMode = "manual";
                    $scope.compStoreDetail.coordinateMode = "manual";
                }
                $scope.isMapSettingsSame = result.isMapSettingsSame;
                $scope.center.lat = result.latitudeCoordinate;
                $scope.center.lng = result.longitudeCoordinate;
                if ($scope.coordinateMode == "manual") {
                    if ($scope.center.lat && $scope.center.lng) {
                        $scope.isAddress = false;
                        $scope.getAddressFromCoordinates();
                    }
                } else if ($scope.coordinateMode == "automatic" && $scope.mapManuallyRepositioned == 0 && $scope.center.lat && $scope.center.lng) {
                    setMapView();
                    refreshMapView();
                } else {
                    if ($scope.center.lat && $scope.center.lng) {
                        Map.init().then(function () {
                            googleMap = Map.getMap();
                            googleMap.addListener("zoom_changed", () => {
                                $scope.refreshMapView()
                            })
                            $scope.isAddress = false;
                            Map.setCenterFromCoordinates($scope.center.lat, $scope.center.lng);
                        });
                    }
                }
                $scope.redeemGeofenceRadius = result.redeemGeofenceRadius;
                $scope.redeemRestrictionType = result.redeemRestrictionType;
                $scope.isDisplayedCountrywide = result.isDisplayedCountrywide;
                var yaltyCityIndex = $scope.listOfYaltyCities.findIndex(record => record.placeName === result.yaltyCity)
                $scope.yaltyCity = $scope.listOfYaltyCities[yaltyCityIndex];
                if (result.defOpeningTimeFrom == "00:00" && result.defOpeningTimeUntil == "24:00") {
                    $scope.isDefAroundTheClock = true;
                }
                $scope.isDefOpeningTimeSame = result.isDefOpeningTimeSame;
                $scope.defOpeningTimeFromHour = parseInt(getHour(result.defOpeningTimeFrom));
                $scope.defOpeningTimeFromMin = parseInt(getMin(result.defOpeningTimeFrom));
                $scope.defOpeningTimeUntilHour = parseInt(getHour(result.defOpeningTimeUntil));
                $scope.defOpeningTimeUntilMin = parseInt(getMin(result.defOpeningTimeUntil));

                $scope.displayPartnerMsgInsteadOfOpeningTimes = result.displayPartnerMsgInsteadOfOpeningTimes;
                $scope.openingTimesPartnerMessage = result.openingTimesPartnerMessage;
                $scope.isHolidayOpeningTimesSame = result.isHolidayOpeningTimesSame;
                $scope.holidayOpeningTimes = result.holidayOpeningTimes;
                $scope.holidayOpeningTimesUntil = result.holidayOpeningTimesUntil;

                if (result.openingTimeMondayFrom == "00:00" && result.openingTimeMondayUntil == "24:00") {
                    $scope.isMonAroundTheClock = true;
                }
                $scope.isOpeningTimeMondayDef = result.isOpeningTimeMondayDef;
                $scope.isClosingTimeMonday = result.isClosedOnMonday;
                if (result.isClosedOnMonday == true) {
                    $scope.openingTimeMondayFromHour = null;
                    $scope.openingTimeMondayFromMin = null;
                    $scope.openingTimeMondayUntilHour = null;
                    $scope.openingTimeMondayUntilMin = null;
                } else {
                    $scope.openingTimeMondayFromHour = parseInt(getHour(result.openingTimeMondayFrom));
                    $scope.openingTimeMondayFromMin = parseInt(getMin(result.openingTimeMondayFrom));
                    $scope.openingTimeMondayUntilHour = parseInt(getHour(result.openingTimeMondayUntil));
                    $scope.openingTimeMondayUntilMin = parseInt(getMin(result.openingTimeMondayUntil));
                }
                if (result.openingTimeTuesdayFrom == "00:00" && result.openingTimeTuesdayUntil == "24:00") {
                    $scope.isTueAroundTheClock = true;
                }
                $scope.isOpeningTimeTuesdayDef = result.isOpeningTimeTuesdayDef;
                $scope.isClosingTimeTuesday = result.isClosedOnTuesday;
                if (result.isClosedOnTuesday == true) {
                    $scope.openingTimeTuesdayFromHour = null;
                    $scope.openingTimeTuesdayFromMin = null;
                    $scope.openingTimeTuesdayUntilHour = null;
                    $scope.openingTimeTuesdayUntilMin = null;
                } else {
                    $scope.openingTimeTuesdayFromHour = parseInt(getHour(result.openingTimeTuesdayFrom));
                    $scope.openingTimeTuesdayFromMin = parseInt(getMin(result.openingTimeTuesdayFrom));
                    $scope.openingTimeTuesdayUntilHour = parseInt(getHour(result.openingTimeTuesdayUntil));
                    $scope.openingTimeTuesdayUntilMin = parseInt(getMin(result.openingTimeTuesdayUntil));
                }
                if (result.openingTimeWednesdayFrom == "00:00" && result.openingTimeWednesdayUntil == "24:00") {
                    $scope.isWedAroundTheClock = true;
                }
                $scope.isOpeningTimeWednesdayDef = result.isOpeningTimeWednesdayDef;
                $scope.isClosingTimeWednesday = result.isClosedOnWednesday;
                if (result.isClosedOnWednesday == true) {
                    $scope.openingTimeWednesdayFromHour = null;
                    $scope.openingTimeWednesdayFromMin = null;
                    $scope.openingTimeWednesdayUntilHour = null;
                    $scope.openingTimeWednesdayUntilMin = null;
                } else {
                    $scope.openingTimeWednesdayFromHour = parseInt(getHour(result.openingTimeWednesdayFrom));
                    $scope.openingTimeWednesdayFromMin = parseInt(getMin(result.openingTimeWednesdayFrom));
                    $scope.openingTimeWednesdayUntilHour = parseInt(getHour(result.openingTimeWednesdayUntil));
                    $scope.openingTimeWednesdayUntilMin = parseInt(getMin(result.openingTimeWednesdayUntil));
                }
                if (result.openingTimeThursdayFrom == "00:00" && result.openingTimeThursdayUntil == "24:00") {
                    $scope.isThuAroundTheClock = true;
                }
                $scope.isOpeningTimeThursdayDef = result.isOpeningTimeThursdayDef;
                $scope.isClosingTimeThursday = result.isClosedOnThursday;
                if (result.isClosedOnThursday == true) {
                    $scope.openingTimeThursdayFromHour = null;
                    $scope.openingTimeThursdayFromMin = null;
                    $scope.openingTimeThursdayUntilHour = null;
                    $scope.openingTimeThursdayUntilMin = null;
                } else {
                    $scope.openingTimeThursdayFromHour = parseInt(getHour(result.openingTimeThursdayFrom));
                    $scope.openingTimeThursdayFromMin = parseInt(getMin(result.openingTimeThursdayFrom));
                    $scope.openingTimeThursdayUntilHour = parseInt(getHour(result.openingTimeThursdayUntil));
                    $scope.openingTimeThursdayUntilMin = parseInt(getMin(result.openingTimeThursdayUntil));
                }
                if (result.openingTimeFridayFrom == "00:00" && result.openingTimeFridayUntil == "24:00") {
                    $scope.isFriAroundTheClock = true;
                }
                $scope.isOpeningTimeFridayDef = result.isOpeningTimeFridayDef;
                $scope.isClosingTimeFriday = result.isClosedOnFriday;
                if (result.isClosedOnFriday == true) {
                    $scope.openingTimeFridayFromHour = null;
                    $scope.openingTimeFridayFromMin = null;
                    $scope.openingTimeFridayUntilHour = null;
                    $scope.openingTimeFridayUntilMin = null;
                } else {
                    $scope.openingTimeFridayFromHour = parseInt(getHour(result.openingTimeFridayFrom));
                    $scope.openingTimeFridayFromMin = parseInt(getMin(result.openingTimeFridayFrom));
                    $scope.openingTimeFridayUntilHour = parseInt(getHour(result.openingTimeFridayUntil));
                    $scope.openingTimeFridayUntilMin = parseInt(getMin(result.openingTimeFridayUntil));
                }
                if (result.openingTimeSaturdayFrom == "00:00" && result.openingTimeSaturdayUntil == "24:00") {
                    $scope.isSatAroundTheClock = true;
                }
                $scope.isOpeningTimeSaturdayDef = result.isOpeningTimeSaturdayDef;
                $scope.isClosingTimeSaturday = result.isClosedOnSaturday;
                if (result.isClosedOnSaturday == true) {
                    $scope.openingTimeSaturdayFromHour = null;
                    $scope.openingTimeSaturdayFromMin = null;
                    $scope.openingTimeSaturdayUntilHour = null;
                    $scope.openingTimeSaturdayUntilMin = null;
                } else {
                    $scope.openingTimeSaturdayFromHour = parseInt(getHour(result.openingTimeSaturdayFrom));
                    $scope.openingTimeSaturdayFromMin = parseInt(getMin(result.openingTimeSaturdayFrom));
                    $scope.openingTimeSaturdayUntilHour = parseInt(getHour(result.openingTimeSaturdayUntil));
                    $scope.openingTimeSaturdayUntilMin = parseInt(getMin(result.openingTimeSaturdayUntil));
                }
                if (result.openingTimeSundayFrom == "00:00" && result.openingTimeSundayUntil == "24:00") {
                    $scope.isSunAroundTheClock = true;
                }
                $scope.isOpeningTimeSundayDef = result.isOpeningTimeSundayDef;
                $scope.isClosingTimeSunday = result.isClosedOnSunday;
                if (result.isClosedOnSunday == true) {
                    $scope.openingTimeSundayFromHour = null;
                    $scope.openingTimeSundayFromMin = null;
                    $scope.openingTimeSundayUntilHour = null;
                    $scope.openingTimeSundayUntilMin = null;
                } else {
                    $scope.openingTimeSundayFromHour = parseInt(getHour(result.openingTimeSundayFrom));
                    $scope.openingTimeSundayFromMin = parseInt(getMin(result.openingTimeSundayFrom));
                    $scope.openingTimeSundayUntilHour = parseInt(getHour(result.openingTimeSundayUntil));
                    $scope.openingTimeSundayUntilMin = parseInt(getMin(result.openingTimeSundayUntil));
                }
                $scope.promoCategoryId = result.promoCategoryId;

                $scope.isEnglishTranslationEnabled = result.isTranslation_EN_Enabled ? 'bekapcsolt' : 'kikapcsolt';
                $scope.isEnglishTranslationEnabledPreviousValue = $scope.isEnglishTranslationEnabled;
                $scope.translationEnabled = result.isTranslation_EN_Enabled
                $scope.languages = [
                    { id: 1, listName: 'Magyar (kötelező)', url: "../imgs/translation_flag_HU.png" },
                    {
                        id: 2, listName: `Angol (${$scope.isEnglishTranslationEnabled})`,
                        url: "../imgs/translation_flag_EN.png",
                        windowTitleName: 'ANGOL', name: 'Angol', code: 'EN'
                    }
                ]
                $scope.partnerData = {}
                $scope.languageSelector = 1
                $scope['EN'] = {}
                $scope['EN'].compStoreName = result.compStoreName_EN
                $scope['EN'].compStoreDescription = result.compStoreDescription_EN
                $scope['EN'].webURL = result.webURL_EN
                $scope['EN'].FBURL = $scope.facebookPrefix + result.FBURL_EN
                $scope['EN'].onlineStoreRedeemInfo = result.onlineStoreRedeemInfo_EN
                $scope['EN'].openingTimesPartnerMessage = result.openingTimesPartnerMessage_EN
                $scope.translatedCompStoreName = result.compStoreName_EN
                $scope.partnerData.translatedCompStoreName = result.compNamePromo_EN
                $scope.translatedCompStoreDescription = result.compStoreDescription_EN
                $scope.partnerData.translatedCompStoreDescription = result.compDescription_EN
                $scope.translatedWebURL = result.webURL_EN
                $scope.partnerData.translatedWebURL = result.webURL_EN_Company
                $scope.translatedFBURL = $scope.facebookPrefix + result.FBURL_EN;
                $scope.partnerData.translatedFBURL = result.FBURL_EN_Company
                $scope.translatedOnlineStoreRedeemInfo = result.onlineStoreRedeemInfo_EN
                $scope.translatedOpeningTimesPartnerMessage = result.openingTimesPartnerMessage_EN

                $rootScope.isDataSheetModified = false;
            }, function (result) {
                var alert = $mdDialog.alert()
                    .title(result.msg)
                    .textContent(result.msg)
                    .ok('Rendben')
                $mdDialog.show(alert).then(function () {
                });
            });
        }
        $scope.modifyStore = function (companyStoreId, isTemplate) {
            if ($rootScope.isDataSheetModified == false) {
                modifyStore(companyStoreId, isTemplate);
            } else {
                var confirm = $mdDialog.confirm()
                    .title('Biztosan el szeretné hagyni az oldalt?')
                    .textContent('Az adatlapon történt módosításai mentés nélkül elvesznek.')
                    .ariaLabel('Oldal elhagyás megerősítése')
                    .ok('Igen')
                    .cancel('Mégsem');
                $mdDialog.show(confirm).then(function () {
                    $rootScope.isDataSheetModified = false;
                    $window.scrollTo(0, 0);
                    modifyStore(companyStoreId, isTemplate);
                }, function () {
                });
            }
        }
        $scope.getStores = function () {
            if ($scope.showStores) {
                $scope.buttonClass = "btn";
                $scope.animateMobile = "yalty-animateTo-mobile";
                $scope.showStores = false;
                $scope.openVirtual = { "margin-top": "0px", "margin-left": "7px" };
                $scope.isOpened = "fa fa-chevron-down";
            } else {
                $scope.buttonClass = "activeBtn";
                $scope.animateMobile = "yalty-animateFrom-mobile"
                $scope.showStores = true;
                $scope.openVirtual = { "margin-top": -(virtualSize + 60) + "px", "margin-left": "7px" };
                $scope.isOpened = "fa fa-chevron-up";
            }
        }
        $scope.openTranslationWindow = (languageId, whereToOpen) => {
            if (languageId != 1) {
                const langIndex = $scope.languages.findIndex(item => item.id == languageId)
                $scope.windowTitleLang = $scope.languages[langIndex].windowTitleName
                $scope.langName = $scope.languages[langIndex].name
                $scope.lang = $scope.languages[langIndex].code
                $scope.translatedCompStoreName = $scope[$scope.lang].compStoreName
                $scope.translatedCompStoreDescription = $scope[$scope.lang].compStoreDescription
                $scope.translatedWebURL = $scope[$scope.lang].webURL
                $scope.translatedFBURL = $scope[$scope.lang].FBURL
                $scope.translatedOnlineStoreRedeemInfo = $scope[$scope.lang].onlineStoreRedeemInfo
                $scope.translatedOpeningTimesPartnerMessage = $scope[$scope.lang].openingTimesPartnerMessage
                $scope.showTranslationWindow = true
                const langCode = $scope.languages[langIndex].code
                $scope.initValues = {}
                $scope.initValues[langCode] = {}
                $scope.initValues[langCode].enabled = $scope.translationEnabled
                if (!$scope.translationEnabled) {
                    $scope.translationEnabled = true
                    $scope.languageSelector = 2
                    $scope.modifyTranslationEnabled(true)
                }
                const divToScroll = whereToOpen ? whereToOpen : 'translationTable'
                $(`#${divToScroll}`).animate({ scrollTop: 0 }, "fast");
            }
        }
        $scope.modifyTranslationEnabled = (windowOpening) => {
            if ($scope.languageSelector == 2) {
                $scope.isEnglishTranslationEnabled = $scope.translationEnabled ? 'bekapcsolt' : 'kikapcsolt'
                $scope.languages[1].listName = `Angol (${$scope.isEnglishTranslationEnabled})`
            }
            const langName = $scope.langName.toLowerCase()
            if ($scope.translationEnabled) {
                let alert = $mdDialog.confirm()
                    .title(`A(z) ${langName} fordítás bekapcsolása esetén minden releváns, a felhasználók számára a mobil appokban megjelenő céges szövegeket, üzletek információit és a promóciók szövegeit is szükséges ${langName} nyelvre fordítani. A fordításban érintett oldalak:`)
                    .htmlContent(`
                    <ul class="md-title mdDialogList">
                        <li>Partneri adatok,</li>
                        <li>Üzletek (azon belül az egyes üzletek),</li>
                        <li>Hűségkártyáink (azon belül az egyes, még aktív hűségkártyák) és </li>
                    </ul>`)
                    .ok('Rendben')
                if (windowOpening) alert.cancel('Mégsem')
                $mdDialog.show(alert).catch(() => {
                    $scope.companyStoresForm.languageSelector.$valid = true
                    $scope.companyStoresForm.languageSelector.$invalid = false
                    $scope.companyStoresForm.languageSelector.$error = {}
                    $scope.translationEnabled = false
                    $scope.closeTranslationWindow()
                })
            } else {
                const alert = $mdDialog.alert()
                    .title(`A(z) ${langName} fordítás kikapcsolása esetén minden releváns, a felhasználók számára a mobil appokban megjelenő céges szövegek, üzletek információ és a promóciók szövege esetén kikapcsoljuk a(z) ${langName} nyelvű fordítást. Tehát minden, a fordításban érintett oldalon:`)
                    .htmlContent(`
                        <ul class="md-title mdDialogList">
                            <li>Partneri adatok,</li>
                            <li>Üzletek (azon belül az egyes üzletek),</li>
                            <li>Hűségkártyáink (azon belül az egyes hűségkártyák) és</li>
                        </ul>
                    `)
                    .ok('Rendben')
                $mdDialog.show(alert).then(() => {
                    $scope.companyStoresForm.languageSelector.$valid = true
                    $scope.companyStoresForm.languageSelector.$invalid = false
                    $scope.companyStoresForm.languageSelector.$error = {}
                })
            }

        }

        $scope.closeTranslationWindow = () => {
            $scope.languageSelector = 1
            $scope.showTranslationWindow = false
            $scope.translationEnabled = $scope.initValues['EN'].enabled
            $scope.isEnglishTranslationEnabled = $scope.translationEnabled ? 'bekapcsolt' : 'kikapcsolt'
            $scope.languages[$scope.languageSelector].listName = `Angol (${$scope.isEnglishTranslationEnabled})`
        }

        $scope.copyToTranslationField = (fieldName, copyFromPartnerData) => {
            const translationFieldName = 'translated' + fieldName.charAt(0).toUpperCase() + fieldName.slice(1)
            if (copyFromPartnerData) {
                if ($scope.partnerData[translationFieldName]) {
                    $scope[translationFieldName] = translationFieldName == 'translatedFBURL' ?
                        $scope.facebookPrefix + $scope.partnerData[translationFieldName] :
                        $scope.partnerData[translationFieldName]
                }
            } else if ($scope[fieldName]) {
                $scope[translationFieldName] = $scope[fieldName]
            }
        }

        $scope.saveTranslation = () => {
            let isTranslationModified = false;
            if ($scope[$scope.lang].compStoreName != $scope.translatedCompStoreName) {
                $scope[$scope.lang].compStoreName = $scope.translatedCompStoreName
                isTranslationModified = true;
            }
            if ($scope[$scope.lang].compStoreDescription != $scope.translatedCompStoreDescription) {
                $scope[$scope.lang].compStoreDescription = $scope.translatedCompStoreDescription
                isTranslationModified = true;
            }
            if ($scope[$scope.lang].webURL != $scope.translatedWebURL) {
                $scope[$scope.lang].webURL = $scope.translatedWebURL
                isTranslationModified = true;
            }
            if ($scope[$scope.lang].FBURL != $scope.translatedFBURL) {
                $scope[$scope.lang].FBURL = $scope.translatedFBURL
                isTranslationModified = true;
            }
            if ($scope[$scope.lang].onlineStoreRedeemInfo != $scope.translatedOnlineStoreRedeemInfo) {
                $scope[$scope.lang].onlineStoreRedeemInfo = $scope.translatedOnlineStoreRedeemInfo
                isTranslationModified = true;
            }
            if ($scope[$scope.lang].openingTimesPartnerMessage != $scope.translatedOpeningTimesPartnerMessage) {
                $scope[$scope.lang].openingTimesPartnerMessage = $scope.translatedOpeningTimesPartnerMessage
                isTranslationModified = true;
            }
            $scope.languageSelector = 1
            $scope.sendButtonDisabled = false
            $rootScope.isDataSheetModified = true
            if ($scope.companyStoresForm.translatedCompStoreName.$valid && $scope.companyStoresForm.translatedCompStoreDescription.$valid &&
                $scope.companyStoresForm.translatedWebURL.$valid && $scope.companyStoresForm.translatedFBURL.$valid &&
                (!$scope.isOnlineStore || $scope.companyStoresForm.translatedOnlineStoreRedeemInfo.$valid) &&
                (!$scope.openingTimesPartnerMessage || $scope.companyStoresForm.translatedOpeningTimesPartnerMessage.$valid)) {
                $scope.companyStoresForm.languageSelector.$valid = true
                $scope.companyStoresForm.languageSelector.$invalid = false
                $scope.companyStoresForm.languageSelector.$error = {}
            }
            $scope.showTranslationWindow = false
            if (isTranslationModified == true || $scope.isEnglishTranslationEnabledPreviousValue != $scope.isEnglishTranslationEnabled) {
                $scope.isEnglishTranslationEnabledPreviousValue = $scope.isEnglishTranslationEnabled;
                var confirm = $mdDialog.alert()
                    .textContent('Figyelem: a fordítások módosításainak mentéséhez használja az Üzlet oldal Mentés gombját.')
                    .ok('Rendben')
                $mdDialog.show(confirm).then(function () {
                }, function () {
                });
            }
        }

        $scope.isSameCompanyName = function (param) {
            if ($scope.compStoreName) {
                $scope.compStoreName = $scope.compStoreName.trim();
            }
            if (param == "same") {
                if ($scope.isCompStoreNameSame == true) {
                    $scope.compStoreName = $scope.dataCompany.compNamePromo;
                    $scope.isSameName = { "color": "black" };
                    $rootScope.isDataSheetModified = true;
                } else {
                    $scope.compStoreName = null;
                    $scope.isSameName = { "color": "#4E525C" };
                    $rootScope.isDataSheetModified = true;
                }
            } else if (param == "change") {
                if ($scope.compStoreName != $scope.dataCompany.compNamePromo) {
                    $scope.isCompStoreNameSame = false;
                    $scope.isSameName = { "color": "#4E525C" };
                    $rootScope.isDataSheetModified = true;
                }
            }
        }
        $scope.isSameCompanyImg = function (form) {
            if ($scope.isCompStoreImgSame == true) {
                $rootScope.companyCropperData.compStoreImage = $scope.dataCompany.compImgURL;
                $rootScope.isDataSheetModified = true;
                form.storeImg.$error.need = false;
            } else {
                $rootScope.companyCropperData.compStoreImage = null;
                $rootScope.isDataSheetModified = true;
                form.storeImg.$error.need = true;
            }
        }
        $scope.isSameCompanyDesc = function (param) {
            if ($scope.compDescription) {
                $scope.compDescription = $scope.compDescription.trim();
            }
            if (param == "same") {
                if ($scope.isCompStoreDescriptionSame == true) {
                    $scope.compDescription = $scope.dataCompany.compDescription;
                    $scope.isSameDesc = { "color": "black" };
                    $rootScope.isDataSheetModified = true;
                } else {
                    $scope.compDescription = null;
                    $scope.isSameDesc = { "color": "#4E525C" };
                    $rootScope.isDataSheetModified = true;
                }
            } else if (param == "change") {
                if ($scope.compDescription != $scope.dataCompany.compDescription) {
                    $scope.isCompStoreDescriptionSame = false;
                    $scope.isSameDesc = { "color": "#4E525C" };
                    $rootScope.isDataSheetModified = true;
                }
            }
        }
        $scope.isSameCompanyPhone = function (param) {
            if ($scope.publicContactPhone) {
                $scope.publicContactPhone = $scope.publicContactPhone.trim();
            }
            if (param == "same") {
                if ($scope.isPublicContactPhone == true) {
                    $scope.publicContactPhone = $scope.dataCompany.publicContactPhone;
                    $scope.isSamePhone = { "color": "black" };
                    $rootScope.isDataSheetModified = true;
                } else {
                    $scope.publicContactPhone = "";
                    $scope.isSamePhone = { "color": "#4E525C" };
                    $rootScope.isDataSheetModified = true;
                }
            } else if (param == "change") {
                if ($scope.publicContactPhone != $scope.dataCompany.publicContactPhone) {
                    $scope.isPublicContactPhone = false;
                    $scope.isSamePhone = { "color": "#4E525C" };
                    $rootScope.isDataSheetModified = true;
                }
            }
        }
        $scope.isSameCompanyWeb = function (param) {
            if ($scope.webURL) {
                $scope.webURL = $scope.webURL.trim();
            }
            if (param == "same") {
                if ($scope.isWebURLSame == true) {
                    $scope.webURL = $scope.dataCompany.webURL;
                    $scope.isSameWeb = { "color": "black" };
                    $rootScope.isDataSheetModified = true;
                } else {
                    $scope.webURL = '';
                    $scope.isSameWeb = { "color": "#4E525C" };
                    $rootScope.isDataSheetModified = true;
                }
            } else if (param == "change") {
                if ($scope.webURL != $scope.dataCompany.webURL) {
                    $scope.isWebURLSame = false;
                    $scope.isSameWeb = { "color": "#4E525C" };
                    $rootScope.isDataSheetModified = true;
                }
            }
        }
        $scope.isSameCompanyFB = function (param) {
            if ($scope.FBURL) {
                $scope.FBURL = $scope.FBURL.trim();
            }
            if (param == "same") {
                if ($scope.isFBURLSame == true) {
                    $scope.FBURL = $scope.facebookPrefix + $scope.dataCompany.FBURL;
                    $scope.isSameFb = { "color": "black" };
                    $rootScope.isDataSheetModified = true;
                } else {
                    $scope.FBURL = $scope.facebookPrefix;
                    $scope.isSameFb = { "color": "#4E525C" };
                    $rootScope.isDataSheetModified = true;
                }
            } else if (param == "change") {
                if ($scope.FBURL != ($scope.facebookPrefix + $scope.dataCompany.FBURL)) {
                    $scope.isFBURLSame = false;
                    $scope.isSameFb = { "color": "#4E525C" };
                    $rootScope.isDataSheetModified = true;
                }
            }
        }
        $scope.coordinateModeChange = function () {
            $rootScope.isDataSheetModified = true;
            if ($scope.coordinateMode == "manual") {
                if ($scope.center.lat && $scope.center.lng) {
                    $scope.isAddress = false;
                    $scope.googleMapIsInvalid = false;
                    $scope.isMapSettingsSame = false;
                    $scope.getAddressFromCoordinates();
                }
            } else {
                if (($scope.compStoreAddrCountry && $scope.compStoreAddrZIP && $scope.compStoreAddrCity && $scope.compStoreAddrCity.placeName && $scope.compStoreAddrStreet && $scope.compStoreAddrNo) != null && $scope.mapManuallyRepositioned == 0 && $scope.coordinateMode == "automatic") {
                    setMapView();
                    refreshMapView();
                    $scope.isAddress = false;
                    $scope.isMapSettingsSame = false;
                    $scope.isSameAddress = { "color": "black" };
                    $scope.mapManuallyReposition = 0;
                } else if (($scope.compStoreAddrCountry && $scope.compStoreAddrZIP && $scope.compStoreAddrCity && $scope.compStoreAddrCity.placeName && $scope.compStoreAddrStreet && $scope.compStoreAddrNo) != null && $scope.mapManuallyRepositioned == 1 && $scope.coordinateMode == "automatic") {
                    $scope.isAddress = false;
                    $scope.isMapSettingsSame = false;
                    $scope.isSameAddress = { "color": "black" };
                    $scope.mapManuallyReposition = 1;
                } else {
                    if (!$scope.mapManuallyRepositioned) $scope.mapManuallyReposition = 0;
                    $scope.isAddress = true;
                }
            }
        }
        $scope.isSameCompanyAddr = function (param) {
            if ($scope.compStoreAddrStreet) {
                $scope.compStoreAddrStreet = $scope.compStoreAddrStreet.trim();
            }
            if ($scope.compStoreAddrNo) {
                $scope.compStoreAddrNo = $scope.compStoreAddrNo.trim();
            }
            if (param == "same") {
                if ($scope.isCompStoreAddrSame == true) {
                    $rootScope.isDataSheetModified = true;
                    if ($scope.dataCompany.hqAddrCountry == "Magyarország") {
                        $scope.compStoreAddrCountry = 1;
                    }
                    $scope.compStoreAddrZIP = $scope.dataCompany.hqAddrZIP;
                    var index = $scope.listOfCities.findIndex(record => record.placeName === $scope.dataCompany.hqAddrCity)
                    $scope.compStoreAddrCity = $scope.listOfCities[index];
                    $scope.compStoreAddrStreet = $scope.dataCompany.hqAddrStreet;
                    $scope.compStoreAddrNo = ($scope.dataCompany.hqAddrNo);
                    $scope.isAddress = false;
                    $scope.isSameAddress = { "color": "black" };
                    $scope.coordinate = { "color": "black", "text-align": "left" };
                    if ($scope.coordinateMode == "manual" && $scope.center.lat && $scope.center.lng) {
                        $scope.mapManuallyReposition = 1;
                    } else {
                        if ($scope.isMapSettingsSame == false) {
                            setMapView();
                        } else {
                            $scope.setMapSettings();
                        }
                    }
                    $scope.mapManuallyRepositioned = 0;
                } else {
                    $rootScope.isDataSheetModified = true;
                    $scope.compStoreAddrCountry = 1;
                    $scope.compStoreAddrZIP = null;
                    $scope.compStoreAddrCity = '';
                    $scope.compStoreAddrStreet = null;
                    $scope.compStoreAddrNo = null;
                    $scope.isSameAddress = { "color": "#4E525C" };
                    $scope.coordinate = { "color": "black", "text-align": "left" };
                    $scope.center.lng = null;
                    $scope.center.lat = null;
                    $scope.isAddress = true;
                }
            } else if (param == "change") {
                $rootScope.isDataSheetModified = true;
                $scope.checkCities();
                if (($scope.compStoreAddrCountry && $scope.compStoreAddrZIP && $scope.compStoreAddrCity && $scope.compStoreAddrCity.placeName && $scope.compStoreAddrStreet && $scope.compStoreAddrNo) != null && $scope.mapManuallyRepositioned == 0 && $scope.coordinateMode == "automatic") {
                    setMapView();
                    refreshMapView();
                    $scope.isAddress = false;
                    $scope.isSameAddress = { "color": "black" };
                    $scope.mapManuallyReposition = 0;
                } else if (($scope.compStoreAddrCountry && $scope.compStoreAddrZIP && $scope.compStoreAddrCity && $scope.compStoreAddrCity.placeName && $scope.compStoreAddrStreet && $scope.compStoreAddrNo) != null && $scope.mapManuallyRepositioned == 1 && $scope.coordinateMode == "automatic") {
                    $scope.isAddress = false;
                    $scope.isSameAddress = { "color": "black" };
                    $scope.mapManuallyReposition = 1;
                } else if ($scope.coordinateMode == "manual" && $scope.center.lat && $scope.center.lng) {
                    if (($scope.compStoreAddrCountry && $scope.compStoreAddrZIP && $scope.compStoreAddrCity && $scope.compStoreAddrCity.placeName && $scope.compStoreAddrStreet && $scope.compStoreAddrNo) != null) {
                        $scope.mapManuallyReposition = 1;
                    }
                    $scope.isAddress = false;
                    $scope.isSameAddress = { "color": "black" };
                } else {
                    $scope.isAddress = true;
                    $scope.isSameAddress = { "color": "#4E525C" };
                }
                if ($scope.compStoreAddrCountry != 1) {
                    $scope.isCompStoreAddrSame = false;
                    $scope.isSameAddress = { "color": "#4E525C" };
                } else if ($scope.compStoreAddrZIP != $scope.dataCompany.hqAddrZIP) {
                    $scope.isCompStoreAddrSame = false;
                    $scope.isSameAddress = { "color": "#4E525C" };
                } else if ($scope.compStoreAddrCity && $scope.compStoreAddrCity.placeName != $scope.dataCompany.hqAddrCity) {
                    $scope.isCompStoreAddrSame = false;
                    $scope.isSameAddress = { "color": "#4E525C" };
                } else if ($scope.compStoreAddrStreet != $scope.dataCompany.hqAddrStreet) {
                    $scope.isCompStoreAddrSame = false;
                    $scope.isSameAddress = { "color": "#4E525C" };
                } else if ($scope.compStoreAddrNo != ($scope.dataCompany.hqAddrNo)) {
                    $scope.isCompStoreAddrSame = false;
                    $scope.isSameAddress = { "color": "#4E525C" };
                }
            }
        }
        const isSameCompanyOpen = function () {
            $scope.isDefAroundTheClock = false;
            if ($scope.isDefOpeningTimeSame == true) {
                $rootScope.isDataSheetModified = true;
                $scope.defOpeningTimeFromHour = parseInt(getHour($scope.dataCompany.defOpeningTimeFrom));
                $scope.defOpeningTimeFromMin = parseInt(getMin($scope.dataCompany.defOpeningTimeFrom));
                $scope.defOpeningTimeUntilHour = parseInt(getHour($scope.dataCompany.defOpeningTimeUntil));
                $scope.defOpeningTimeUntilMin = parseInt(getMin($scope.dataCompany.defOpeningTimeUntil));
                isOpeningTimeSame(0);
            } else {
                $rootScope.isDataSheetModified = true;
                $scope.defOpeningTimeFromHour = null;
                $scope.defOpeningTimeFromMin = null;
                $scope.defOpeningTimeUntilHour = null;
                $scope.defOpeningTimeUntilMin = null;
            }
        }
        $scope.isSameCompanyOpen = function () {
            isSameCompanyOpen();
        }
        $scope.isSameCompanyHoliday = function () {
            if ($scope.isHolidayOpeningTimesSame == true) {
                $rootScope.isDataSheetModified = true;
                $scope.holidayOpeningTimes = $scope.dataCompany.defHolidayOpeningTimes;
                $scope.holidayOpeningTimesUntil = $scope.dataCompany.defHolidayOpeningTimesUntil;

            } else {
                $rootScope.isDataSheetModified = true;
                $scope.holidayOpeningTimes = null;
                $scope.holidayOpeningTimesUntil = null;
            }
        }

        $scope.preventLineBreaks = function (keypress) {
            if (keypress.keyCode === 13) {
                keypress.preventDefault();
            }
        }

        $scope.resetDatePicker = function () {
            if(!$scope.isHolidayOpeningTimesSame){
                $scope.holidayOpeningTimesUntil = "";
                setTimeout(function () {
                    $scope.holidayOpeningTimesUntil = null;
                    }, 0)
            $scope.companyStoresForm.holidayOpeningTimesUntil.$setTouched();
        }}

        $scope.setTimeToEndOfDay = function () {
                $scope.holidayOpeningTimesUntil = setDateToEndOfTheDay(moment($scope.holidayOpeningTimesUntil).toDate(), true);
        }

        function setDateToEndOfTheDay(date, isUTC) {
            date.setUTCHours(23)
            date.setUTCMinutes(59)
            date.setUTCSeconds(59)
            date.setUTCMilliseconds(999)
            if (isUTC) {
                date.setHours(date.getHours() - getHungarianOffset(date))
            }
            return date
        }

        function getLastSundayOfMarch(year) {
            var timezoneChangeDate = new Date(year, 2, 31);
            while (timezoneChangeDate.getDay() !== 0) {
                timezoneChangeDate.setDate(timezoneChangeDate.getDate() - 1);
            }
            return timezoneChangeDate.getDate()
        }

        function getLastSundayOfOctober(year) {
            var timezoneChangeDate = new Date(year, 9, 31);
            while (timezoneChangeDate.getDay() !== 0) {
                timezoneChangeDate.setDate(timezoneChangeDate.getDate() - 1);
            }
            return timezoneChangeDate.getDate();
        }

        function getHungarianOffset(date) {
            const timezoneChangeSummerMonth = 2
            const timezoneChangeSummerDay = getLastSundayOfMarch(date.getFullYear());
            const timezoneChangeAutumnMonth = 9
            const timezoneChangeAutumnDay = getLastSundayOfOctober(date.getFullYear());
            const resMonth = date.getMonth()
            const resDay = date.getDate()
            let offsetInHour
            if (resMonth > timezoneChangeSummerMonth && resMonth < timezoneChangeAutumnMonth) {
                offsetInHour = 2
            } else if (resMonth == timezoneChangeSummerMonth && resDay >= timezoneChangeSummerDay) {
                offsetInHour = 2
            } else if (resMonth == timezoneChangeAutumnMonth && resDay <= timezoneChangeAutumnDay) {
                offsetInHour = 2
            } else {
                offsetInHour = 1
            }
            return offsetInHour
        }

        $scope.isSameCompName = function () {
            if ($scope.compStoreName != null && $scope.compStoreName != "") {
                $scope.newStore = null;
                $rootScope.isDataSheetModified = true;
            } else if ($scope.compStoreName == null || $scope.compStoreName == "") {
                $scope.newStore = "ÚJ ÜZLET";
                $scope.compStoreName = null;
                $rootScope.isDataSheetModified = true;
            }
        }
        $scope.getAddressFromCoordinates = function () {
            if ($scope.coordinateMode == "manual") {
                if ($scope.center.lat && $scope.center.lng) {
                    $scope.mapManuallyReposition = 1;
                    Map.init().then(function () {
                        googleMap = Map.getMap();
                        googleMap.addListener("zoom_changed", () => {
                            $scope.refreshMapView()
                        })
                        $scope.isAddress = false;
                        Map.setCenterFromCoordinates($scope.center.lat, $scope.center.lng);
                    })
                }
            }
        }
        const setMapView = function () {
            Map.init().then(function () {
                var address = $scope.compStoreAddrCity.placeName + " " + $scope.compStoreAddrStreet + " " + $scope.compStoreAddrNo;
                Map.search(address).then(function (res) { // success
                    $scope.googleMapIsInvalid = false;
                    googleMap = Map.getMap();
                    googleMap.addListener("zoom_changed", () => {
                        $scope.refreshMapView()
                    })
                    Map.addMarker(res);
                    $scope.center.lat = res.geometry.location.lat();
                    $scope.center.lng = res.geometry.location.lng();
                    setStaticMapView();
                },
                    function (status) { // error
                        $scope.googleMapIsInvalid = $scope.mapManuallyRepositioned ? false : true;
                        address = $scope.compStoreAddrCity.placeName + " " + $scope.compStoreAddrStreet
                        Map.search(address).then(function (res) {
                            Map.addMarker(res);
                            googleMap = Map.getMap();
                            googleMap.addListener("zoom_changed", () => {
                                $scope.refreshMapView()
                            })
                            $scope.center.lat = res.geometry.location.lat();
                            $scope.center.lng = res.geometry.location.lng();
                            setStaticMapView();
                        }, function (status) {
                            address = $scope.compStoreAddrCity.placeName
                            Map.search(address).then(function (res) {
                                Map.addMarker(res);
                                googleMap = Map.getMap();
                                googleMap.addListener("zoom_changed", () => {
                                    $scope.refreshMapView()
                                })
                                $scope.center.lat = res.geometry.location.lat();
                                $scope.center.lng = res.geometry.location.lng();
                                setStaticMapView();
                            })
                        });
                    });
                $scope.center = Map.getCenterPos;
            });
        }
        const setStaticMapView = function () {
            if ($scope.compStoreAddrCountry == "1" && $scope.compStoreAddrCity && $scope.compStoreAddrCity.placeName && $scope.compStoreAddrStreet && $scope.compStoreAddrNo) {
                var center = Map.getCenterPos();
                $scope.compStoreMapImgURL = "https://maps.google.com/maps/api/staticmap?sensor=false&callback=initialize&center=" + center.pos.lat() + "," + center.pos.lng() + "&zoom=19&size=750x460&maptype=roadmap&format=png&visual_refresh=true&markers=size:lar%7Ccolor:0x00AEEF%7Clabel:Y%7C" + center.pos.lat() + ',' + center.pos.lng() + "&key=AIzaSyAuMO2H5Jh1L92B1D7uKW4BneSzh6ywbMI";
            }
        }
        const refreshMapView = function () {
            var center = Map.getCenterPos().pos;
            Map.refreshMarker(center);
            $scope.center.lat = center.lat();
            $scope.center.lng = center.lng();
        }
        $scope.setMapView = function () {
            $rootScope.isDataSheetModified = true;
            if (($scope.compStoreAddrCountry && $scope.compStoreAddrZIP && $scope.compStoreAddrCity && $scope.compStoreAddrCity.placeName && $scope.compStoreAddrStreet && $scope.compStoreAddrNo) != null) {
                setMapView();
                refreshMapView();
                $scope.coordinateMode = "automatic";
                $scope.mapManuallyRepositioned = 0;
                $scope.mapManuallyReposition = 0;
                $scope.isMapSettingsSame = false;
            } else {
                var alert = $mdDialog.alert()
                    .title('Hiányos cím adatok')
                    .textContent('Töltse ki az összes cím mezőt, majd nyomja meg a gombot újra.')
                    .ariaLabel('Hiányos cím adatok')
                    .ok('Ok')
                $mdDialog.show(alert).then(function () {
                    angular.element($document[0].querySelector('input.ng-invalid')).focus();
                });
            }
        }
        $scope.refreshMapView = function () {
            refreshMapView();
            setStaticMapView();
            $scope.coordinateMode = "automatic";
            $scope.isMapSettingsSame = false;
            $rootScope.isDataSheetModified = true;
            $scope.mapManuallyRepositioned = 1;
            $scope.mapManuallyReposition = 1;
            $scope.googleMapIsInvalid = false;
        }
        $scope.convertNumberToTime = function (fromHour, fromMin) {
            return isBiggerThanZero(fromHour, fromMin);
        }
        function DialogCtrl($scope, $mdDialog, partnerSubscriptionName, partnerSubscriptionType, count) {
            $scope.count = count;
            $scope.partnerSubscriptionName = partnerSubscriptionName;
            $scope.partnerSubscriptionType = partnerSubscriptionType;
            $scope.closeDialog = function () {
                $mdDialog.hide();
            }
            $scope.navigate = function () {
                $mdDialog.hide();
                $state.go("home.subscription")
            }
        }
        $scope.orderChange(1)
    });

function isBiggerThanZero(fromHour, fromMin) {
    if (fromHour == null && fromMin == null) {
        return null;
    } else if (fromHour < 10 && fromMin > 9) {
        return "0" + fromHour + ":" + fromMin;
    } else if (fromHour < 10 && fromMin < 10) {
        return "0" + fromHour + ":0" + fromMin;
    } else if (fromHour > 9 && fromMin < 10) {
        return fromHour + ":0" + fromMin;
    } else if (fromHour > 9 && fromMin > 9) {
        return fromHour + ":" + fromMin;
    }
}

function getMin(date) {
    if (date != null) {
        return date.split(":")[1];
    } else {
        return null;
    }
}
function getHour(date) {
    if (date != null) {
        return date.split(":")[0];
    } else {
        return null;
    }
}
function getMonth(month) {
    if (month == "01") {
        return "január";
    } else if (month == "02") {
        return "február";
    } else if (month == "03") {
        return "március";
    } else if (month == "04") {
        return "április";
    } else if (month == "05") {
        return "május";
    } else if (month == "06") {
        return "június";
    } else if (month == "07") {
        return "július";
    } else if (month == "08") {
        return "augusztus";
    } else if (month == "09") {
        return "szeptember";
    } else if (month == "10") {
        return "október";
    } else if (month == "11") {
        return "november";
    } else if (month == "12") {
        return "december";
    }
}