import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { DialogComponent } from 'src/app/shared/components/dialog/dialog.component';
import { ErrorCodeResponse } from 'src/app/shared/interfaces/error-code-response';
import { SelectInput } from 'src/app/shared/interfaces/input';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { ErrorHandlerService } from 'src/app/shared/services/error-handler.service';
import { FormValidatorService } from 'src/app/shared/services/form-validator.service';
import { HttpService } from 'src/app/shared/services/http.service';

@Component({
    selector: 'app-sending-partner-email',
    templateUrl: './sending-partner-email.component.html',
    styleUrls: ['./sending-partner-email.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SendingPartnerEmailComponent implements OnInit {

    currentState = 'EGYÉB';
    currentSubState = 'PARTNERI EMAIL KÜLDÉS';

    companyIdsForm: FormGroup;
    emailForm: FormGroup;
    defaultCompanyIdsFormValues = {
        companyIds: ''
    };
    defaultEmailFormValues = {
        sender: null,
        subject: '',
        body: '',
        template: null
    }
    companyIdArray: number[];
    companies: CompanyData[];
    companiesDataErrors: CompaniesDataErrors;
    waitingForCompanyDataAPIResult = false;
    waitingForSendingEmailAPIResult = false;
    showPreview = false;
    emailAddress: string;
    emailSubjectPreview: string;
    emailBodyPreview: string;
    getCompaniesDataForBulkEmailSendingInTemplateToPartnersRequest: GetCompaniesDataForBulkEmailSendingInTemplateToPartnersRequest;
    getCompaniesDataForBulkEmailSendingInTemplateToPartnersResponse: GetCompaniesDataForBulkEmailSendingInTemplateToPartnersResponse;
    bulkEmailSendingInTemplateToPartnersRequest: BulkEmailSendingInTemplateToPartnersRequest;
    needToValidateCompanyIds = false;
    adminName = this.cookieService.get('adminUserName');
    adminEmail = this.cookieService.get('adminUserEmail');
    senderOptions: SelectInput[] = [
        { value: `${this.adminName} <${this.adminEmail}>`, display: `${this.adminName} <${this.adminEmail}>` },
        { value: 'Yalty <noreply@yalty.com>', display: 'Yalty <noreply@yalty.com>' },
        { value: 'Yalty <ugyfelszolgalat@yalty.com>', display: 'Yalty <ugyfelszolgalat@yalty.com>' },
        { value: 'Yalty <info@yalty.com>', display: 'Yalty <info@yalty.com>' }];
    templateOptions: SelectInput[] = [
        { value: 'noreply', display: 'Noreply email sablon' },
        { value: 'customerServiceGeneral', display: 'Általános ügyfélszolgálati email sablon' }];
    private subscriptions: Subscription[] = [];

    constructor(
        private formBuilder: FormBuilder,
        private formValidatorService: FormValidatorService,
        private httpService: HttpService,
        private errorHandlerService: ErrorHandlerService,
        private dialogService: DialogService,
        private cookieService: CookieService
    ) { }

    ngOnInit(): void {
        this.companyIdsForm = this.formBuilder.group({
            companyIds: [
                this.defaultCompanyIdsFormValues.companyIds,
                [
                    Validators.pattern('^[0-9\,\ ]*'),
                    Validators.required
                ]
            ]
        });
        this.emailForm = this.formBuilder.group({
            sender: [this.defaultEmailFormValues.sender, Validators.required],
            subject: [this.defaultEmailFormValues.subject, Validators.required],
            body: [this.defaultEmailFormValues.body, Validators.required],
            template: [this.defaultEmailFormValues.template, Validators.required]
        });
        this.companyIdsField.valueChanges.subscribe(() => {
            this.needToValidateCompanyIds = true;
        });
    }

    update() {
        if (!this.companyIdsForm.valid) {
            this.formValidatorService.validateAllFormFields(this.companyIdsForm);
            return;
        }
        this.companyIdArray = this.companyIds
            .replace(/\s/g, "")
            .split(',')
            .map(id => +id)
            .filter(id => id !== 0)
            .sort((a, b) => a - b);
        this.updateTable();
    }

    updateTable() {
        this.waitingForCompanyDataAPIResult = true;
        this.getCompaniesDataForBulkEmailSendingInTemplateToPartnersRequest = { companyIds: this.companyIdArray };
        this.httpService.post<GetCompaniesDataForBulkEmailSendingInTemplateToPartnersResponse>('/yaltyadmin/getCompaniesDataForBulkEmailSendingInTemplateToPartners', this.getCompaniesDataForBulkEmailSendingInTemplateToPartnersRequest)
            .subscribe((res: GetCompaniesDataForBulkEmailSendingInTemplateToPartnersResponse | ErrorCodeResponse) => {
                this.waitingForCompanyDataAPIResult = false;
                this.getCompaniesDataForBulkEmailSendingInTemplateToPartnersResponse = this.errorHandlerService.handleError(res);
                this.companies = this.getCompaniesDataForBulkEmailSendingInTemplateToPartnersResponse.companiesData;
                this.companiesDataErrors = this.getCompaniesDataForBulkEmailSendingInTemplateToPartnersResponse.companiesDataErrors;
                this.needToValidateCompanyIds = false;
            });
    }

    openPreview(companyId: number) {
        const index = this.companies.findIndex(obj => obj.id === companyId);
        if (index === -1) return;
        this.emailAddress = this.companies[index].userName || '';
        const partnerUserLastAndFirstName = this.companies[index].partnerUserLastAndFirstName || '';
        const packageDisplayName = this.companies[index].packageDisplayName || '';
        this.emailSubjectPreview = this.subject
            .replace(/##PARTNERUSER_LAST_AND_FIRST_NAME##/g, partnerUserLastAndFirstName)
            .replace(/##PACKAGE_DISPLAY_NAME##/g, packageDisplayName);
        this.emailBodyPreview = this.body
            .replace(/##PARTNERUSER_LAST_AND_FIRST_NAME##/g, partnerUserLastAndFirstName)
            .replace(/##PACKAGE_DISPLAY_NAME##/g, packageDisplayName);
        this.showPreview = true;
    }

    sendEmails() {
        if (!this.companyIdsForm.valid) {
            this.formValidatorService.validateAllFormFields(this.companyIdsForm);
            return;
        }
        if (!this.emailForm.valid) {
            this.formValidatorService.validateAllFormFields(this.emailForm);
            return;
        }
        if (this.needToValidateCompanyIds || !this.companies || this.companies.length === 0) {
            let dialogRef: MatDialogRef<DialogComponent, any>;
            dialogRef = this.dialogService.openDialog('Frissítsd a Partnerlistát!',
                'A Címzett partnerek azonosítói frissültek. A kiküldéshez frissítsd a Partnerlistát a Partnerlista frissítése gomb megnyomásával.',
                'Partnerlista frissítése', 'Mégsem', '450px');
            dialogRef.afterClosed().subscribe((confirmed) => {
                if (confirmed) this.update();
            })
            return;
        }
        let dialogRef: MatDialogRef<DialogComponent, any>;
        let title = '';
        if (!this.companiesDataErrors) return;
        if (this.companiesDataErrors.invalidCompanyId) {
            title = 'Egy vagy több partner azonosító hibás!';
        } else if (this.companiesDataErrors.missingMasterUser) {
            title = 'Egy vagy több partner master felhasználója hiányzik!';
        } else if (this.companiesDataErrors.missingSubscription) {
            title = 'Egy vagy több partner előfizetése hiányzik! (Regisztrált, de még nem fizetett elő.)';
        }
        if (title) {
            dialogRef = this.dialogService.openDialog(title,
                undefined, 'Ok', undefined, '450px');
            return
        }

        dialogRef = this.dialogService.openDialog('Biztosan ki akarod küldeni az emaileket?',
            undefined, 'Igen', 'Mégsem', '450px');
        dialogRef.afterClosed().subscribe((confirmed) => {
            if (!confirmed) return;

            this.waitingForSendingEmailAPIResult = true;
            const emailData = [];
            for (let i = 0; i < this.companies.length; i++) {
                emailData.push({
                    emailAddress: this.companies[i].userName!,
                    partnerUserLastAndFirstName: this.companies[i].partnerUserLastAndFirstName!,
                    packageDisplayName: this.companies[i].packageDisplayName!
                });
            }
            this.bulkEmailSendingInTemplateToPartnersRequest = {
                emailData: emailData,
                sender: this.sender,
                subject: this.subject,
                body: this.body,
                template: this.template
            }
            this.httpService.post<BulkEmailSendingInTemplateToPartnersResponse>('/yaltyadmin/bulkEmailSendingInTemplateToPartners', this.bulkEmailSendingInTemplateToPartnersRequest)
                .subscribe((res: BulkEmailSendingInTemplateToPartnersResponse | ErrorCodeResponse) => {
                    this.waitingForSendingEmailAPIResult = false;
                    const resp: BulkEmailSendingInTemplateToPartnersResponse = this.errorHandlerService.handleError(res);
                    let dialogRef;
                    if (resp.errorCode === 0) {
                        dialogRef = this.dialogService.openDialog(`Az emaileket sikeresen kiküldtük!`,
                            '', 'OK', undefined, '450px');
                    } else if (resp.errorCode == 3) {
                        dialogRef = this.dialogService.openDialog(`Az emailek kiküldése közben egy vagy több email kiküldés sikertelen volt! Összes célzott partner: ${this.companies.length} (Ebből sikertelen: ${resp.nrOfFailedEmailSend}).`,
                            '', 'OK', undefined, '450px');
                    }
                    if (dialogRef) dialogRef.afterClosed().subscribe(() => {
                        this.companyIdsForm.reset(this.defaultCompanyIdsFormValues, { emitEvent: false });
                        this.emailForm.reset(this.defaultEmailFormValues, { emitEvent: false });
                        this.companies = [];
                    });
                });
        });
    }

    closePreviewWindow() {
        this.showPreview = false;
    }

    get companyIdsField(): AbstractControl { return this.companyIdsForm.get('companyIds')!; }
    get companyIds(): string { return this.companyIdsField.value; }

    get emailSubjectField(): AbstractControl { return this.emailForm.get('subject')!; }
    get subject(): string { return this.emailSubjectField.value; }

    get emailBodyField(): AbstractControl { return this.emailForm.get('body')!; }
    get body(): string { return this.emailBodyField.value; }

    get emailSenderField(): AbstractControl { return this.emailForm.get('sender')!; }
    get sender(): string { return this.emailSenderField.value; }

    get emailTemplateField(): AbstractControl { return this.emailForm.get('template')!; }
    get template(): string { return this.emailTemplateField.value; }

    ngOnDestroy() {
        this.subscriptions.forEach((sub) => {
            sub.unsubscribe();
        })
    }
}

interface GetCompaniesDataForBulkEmailSendingInTemplateToPartnersRequest {
    companyIds: number[];
}

interface GetCompaniesDataForBulkEmailSendingInTemplateToPartnersResponse {
    errorCode: number;
    companiesData: CompanyData[];
    companiesDataErrors: CompaniesDataErrors;
}

interface CompanyData {
    id: number;
    compNamePromo: string | null;
    partnerUserLastAndFirstName: string | null;
    userName: string | null;
    packageDisplayName: string | null;
}

interface CompaniesDataErrors {
    invalidCompanyId: boolean,
    missingMasterUser: boolean,
    missingSubscription: boolean
}

interface BulkEmailSendingInTemplateToPartnersRequest {
    emailData: EmailData[];
    sender: string;
    subject: string;
    body: string;
    template: string;
}

interface BulkEmailSendingInTemplateToPartnersResponse {
    errorCode: number;
    nrOfSuccessEmailSend: number;
    nrOfFailedEmailSend: number;
}

interface EmailData {
    emailAddress: string;
    partnerUserLastAndFirstName: string;
    packageDisplayName: string;
}