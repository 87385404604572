import { Injectable } from '@angular/core';
import { ErrorHandlerService } from "src/app/shared/services/error-handler.service";
import { HttpService } from "src/app/shared/services/http.service";
import { GetCitiesRequest, GetCitiesResponse } from 'src/app/shared/interfaces/get-cities';
import { ErrorCodeResponse } from '../interfaces/error-code-response';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CitiesService {

    allYaltyCityOptionsSelected = false;

    constructor(private HttpService: HttpService,
        private ErrorHandlerService: ErrorHandlerService,) { }

    getYaltyCityDisplayValue(yaltyCities: string[]): string {
        const length = yaltyCities ? yaltyCities.length : 0;
        if (yaltyCities === undefined || length == 0) {
            return "Yalty város"
        } else if (yaltyCities.includes('all')) {
            return "Összes"
        } else {
            let text = "";
            for (let i = 0; i < length; i++) {
                text += yaltyCities[i];
                if ((i + 1) !== length) {
                    text += ", ";
                }
            }
            return text;
        }
    }

    getCities(options: GetCitiesRequest): Observable<string[]> {
        return this.HttpService.post<GetCitiesResponse>('/etc/getCities', options)
            .pipe(
                map(resp => this.ErrorHandlerService.handleError(resp)),
                map(resp => resp.listOfCities.map(city => city.placeName)))

    }

    getYaltyCityValue(allYaltyCities: string[], selectedYaltyCities: string[],
        allOptionSelected: boolean): string[] {
        if (!allOptionSelected && this.allYaltyCityOptionsSelected) {
            this.allYaltyCityOptionsSelected = false;
            return [];
        } else if (allOptionSelected && !this.allYaltyCityOptionsSelected) {
            this.allYaltyCityOptionsSelected = true;
            return ['all', ...allYaltyCities];
        } else if (allOptionSelected && selectedYaltyCities.length - 1 !== allYaltyCities.length) {
            this.allYaltyCityOptionsSelected = false;
            let currentYaltyCities: string[] = selectedYaltyCities;
            currentYaltyCities.shift();
            return [...currentYaltyCities];
        } else if (!allOptionSelected && selectedYaltyCities.length === allYaltyCities.length) {
            this.allYaltyCityOptionsSelected = true;
            return ['all', ...allYaltyCities];
        } else {
            this.allYaltyCityOptionsSelected = false;
            return selectedYaltyCities;
        }
    }
}
