import { OverlayContainer } from '@angular/cdk/overlay';
import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, ControlContainer, FormGroup } from '@angular/forms';
import { SelectInput } from 'src/app/shared/interfaces/input';
import { FormFieldValidatorService } from 'src/app/shared/services/form-field-validator.service';
import { Subscription } from 'rxjs';
import { MultipleSelect } from '../../classes/multiple-select';
import { MultipleSelectService } from '../../services/multiple-select.service';

@Component({
    selector: 'app-multiple-select-all',
    templateUrl: './multiple-select-all.component.html',
    styleUrls: ['./multiple-select-all.component.scss']
})
export class MultipleSelectAllComponent implements OnInit {

    form: FormGroup;
    @Input() inputName: string;
    @Input() label: string;
    @Input() objArray: SelectInput[];
    @Input() initValue: (string | number)[] | null;
    @Input() allOptionDisplayName: string;
    selectDisplayValue: string;
    multipleSelect = new MultipleSelect();

    private subscriptions: Subscription[] = [];

    constructor(
        private controlContainer: ControlContainer,
        private overlayContainer: OverlayContainer,
        private formFieldValidatorService: FormFieldValidatorService,
        private multipleSelectService: MultipleSelectService
    ) {
        const overlayContainerClasses = this.overlayContainer.getContainerElement().classList;
        overlayContainerClasses.add('gray-2-theme');
    }

    ngOnInit() {
        this.form = <FormGroup>this.controlContainer.control;
        this.subscriptions.push(
            this.input.valueChanges.subscribe(val => {
                setTimeout(() => {
                    this.setValue(val);
                }, 0);
            })
        );
    }

    setValue(value: (string | number)[]) {
        this.input.setValue(this.multipleSelect.getOptions(
            this.objArray, this.input.value,
            value.includes('all')
        ), { emitEvent: false });
        const objArray = this.objArray.filter(o => this.input.value.includes(o.value));
        const displayValues = objArray.map(o => o.display);
        if (this.input.value.includes('all')) displayValues.push('all');
        this.selectDisplayValue = this.multipleSelectService.getDisplayValue(
            displayValues
        ) || this.allOptionDisplayName
    }

    get required(): boolean { return this.formFieldValidatorService.isRequired(this.input); }

    get input(): AbstractControl { return this.form.get(this.inputName)!; }

    ngOnDestroy() {
        this.subscriptions.forEach((sub) => {
            sub.unsubscribe();
        })
    }

}