<table>
    <tr>
        <th *ngIf="showIndex"></th>
        <th *ngFor="let column of headers">
            {{column.label}}
        </th>
    </tr>
    <tr *ngFor="let row of rows; let i = index">
        <td *ngIf="showIndex">{{(i + 1)}}</td>
        <td *ngFor="let column of headers">
            {{row[column.contentRef]}}
        </td>
    </tr>
</table>
