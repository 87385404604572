import { Component, Input, OnInit } from '@angular/core';
import { Table, TableHeader, TableRow } from '../../interfaces/table';

@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {

    @Input() showIndex = false;
    @Input() table: Table;
    headers: TableHeader[];
    rows: Object[];

    constructor() { }

    ngOnInit() {
        this.headers = this.table.headers;
        this.rows = this.table.rows;
    }

}
