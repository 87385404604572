// @ts-nocheck

"use strict";
angular.module('yaltyApp')
  .service('UserAccountService', function ($q, $http, API_PREFIX, GoogleAnalyticsService) {
    this.getUsers = function () {
      return $q(function (resolve, reject) {
        $http.post(API_PREFIX.url + '/partner/getUsersListViewData').then(function (result) {
          if (result.data.errorCode == 0) {
            GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/felhasználói_fiókok/fiókok_lekérdezés_sikeres",
              "HU Partner [PRIVÁT] - Felhasználói fiókok oldal - Sikeres fiók lista lekérdezés")
          } else {
            GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/felhasználói_fiókok/fiókok_lekérdezés_sikertelen",
              "HU Partner [PRIVÁT] - Felhasználói fiókok oldal - Sikertelen fiók lista lekérdezés")
          }
          resolve(result.data.partnerUserData);
        }, function (result) {
          GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/felhasználói_fiókok/fiókok_lekérdezés_sikertelen",
            "HU Partner [PRIVÁT] - Felhasználói fiókok oldal - Sikertelen fiók lista lekérdezés")
          reject(result.data.errorCode);
        })
      })
    };

    this.getUserData = function (id) {
      return $q(function (resolve, reject) {
        $http.post(API_PREFIX.url + '/partner/getUserData', id).then(function (result) {
          if (result.data.errorCode == 0) {
            GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/felhasználói_fiókok/saját_fiók_lekérdezés_sikeres",
              "HU Partner [PRIVÁT] - Felhasználói fiókok oldal - Sikeres saját fiók lekérdezés")
          } else {
            GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/felhasználói_fiókok/saját_fiók_lekérdezés_sikertelen",
              "HU Partner [PRIVÁT] - Felhasználói fiókok oldal - Sikertelen saját fiók lekérdezés")
          }
          resolve(result.data);
        }, function (result) {
          GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/felhasználói_fiókok/saját_fiók_lekérdezés_sikertelen",
            "HU Partner [PRIVÁT] - Felhasználói fiókok oldal - Sikertelen saját fiók lekérdezés")
          reject(result.data.errorCode);
        })
      })
    };

    this.sendUserAccountData = function (userAccountData) {
      return $q(function (resolve, reject) {
        $http.post(API_PREFIX.url + '/partner/registerOrUpdateUser', userAccountData).then(function (result) {
          if (result.data.errorCode == 0) {
            GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/felhasználói_fiókok/fiók_létrehozás_módosítás_sikeres",
              "HU Partner [PRIVÁT] - Felhasználói fiókok oldal - Sikeres fiók lértehozás, módosítás")
          } else {
            GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/felhasználói_fiókok/fiók_létrehozás_módosítás_sikertelen",
              "HU Partner [PRIVÁT] - Felhasználói fiókok oldal - Sikertelen fiók lértehozás, módosítás")
          }
          resolve(result.data);
        }, function (result) {
          GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/felhasználói_fiókok/fiók_létrehozás_módosítás_sikertelen",
            "HU Partner [PRIVÁT] - Felhasználói fiókok oldal - Sikertelen fiók lértehozás, módosítás")
          reject(result.data);
        })
      })
    };

    this.resendActivationEmail = function (userAccountData) {
      return $q(function (resolve, reject) {
        $http.post(API_PREFIX.url + '/partner/resendActivationEmail', userAccountData).then(function (result) {
          if (result.data.errorCode == 0) {
            GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/felhasználói_fiókok/sikeres_email_kiküldés",
              "HU Partner [PRIVÁT] - Felhasználói fiókok oldal - Sikeres email kiküldés")
          } else {
            GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/felhasználói_fiókok/sikertelen_email_kiküldés",
              "HU Partner [PRIVÁT] - Felhasználói fiókok oldal - Sikertelen email kiküldés")
          }
          resolve(result.data);
        }, function (result) {
          GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/felhasználói_fiókok/sikertelen_email_kiküldés",
            "HU Partner [PRIVÁT] - Felhasználói fiókok oldal - Sikertelen email kiküldés")
          reject(result.data);
        })
      })
    };

    this.removeUser = function (userId) {
      return $q(function (resolve, reject) {
        $http.post(API_PREFIX.url + "/partner/removeUser", userId).then(function (result) {
          if (result.data.errorCode == 0) {
            GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/felhasználói_fiókok/fiók_törlés_sikeres",
              "HU Partner [PRIVÁT] - Felhasználói fiókok oldal - Sikeres fiók törlés")
          } else {
            GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/felhasználói_fiókok/fiók_törlés_sikertelen",
              "HU Partner [PRIVÁT] - Felhasználói fiókok oldal - Sikertelen fiók törlés")
          }
          resolve(result);
        }, function (result) {
          GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/felhasználói_fiókok/fiók_törlés_sikertelen",
            "HU Partner [PRIVÁT] - Felhasználói fiókok oldal - Sikertelen fiók törlés")
          reject(result);
        })
      })
    }

  })
  .factory('RedeemService', function (API_PREFIX, $rootScope, $http) {
    var source = null;

    return {
      openConn: function () {
        source = new EventSource(API_PREFIX.url + "/sse/connectPartner");
        console.log('eventSource object created');
      },
      getRedeemRequest: function (callback) {
        //console.log("várjuk a beváltást.");
        source.addEventListener("redeemRequest", callback, false);
      },
      getPartnerKey: function (callback) {
        source.addEventListener("sendPartnerKey", callback, false);
      },
      isConnOpened: function (callback) {
        source.addEventListener('open', callback, false);
      },
      getErrors: function (callback) {
        source.addEventListener('error', callback, false);
      },
      getRedeemCanceled: function (callback) {
        source.addEventListener("redeemCanceled", callback, false);//megszakadt
      },
      getRedeemOfflineRedeemed: function (callback) {
        source.addEventListener("offlineRedeemed", callback, false);//menetközben offlinera váltott és beváltotta
      },
      getRedeemFinalised: function (callback) {
        source.addEventListener("redeemFinalised", callback, false);//beváltás véglegesítve
      },
      closeConn: function (whereClosed, exceptionString, offlineTimestamp) {
        if (source != null) {
          source.close();
          source = null
        }
        const request = {}
        request.sse = {
          partnerKey: $rootScope.partnerKey,
          eventName: 'onCloseAt' + whereClosed,
          exceptionString: exceptionString ?
            JSON.stringify(exceptionString) : ''
        }
        if (offlineTimestamp) request.sse.offlineTimestamp = offlineTimestamp
        $http.post(API_PREFIX.url + "/service/logWebAppFrontendEvent", request, function (result) {
        }, function () { });
      }
    };

  })
  .service('Map', function ($q) {
    var isInitalized = false;

    this.isInitalized = function () {
      return isInitalized;
    }

    this.init = function () {
      var d = $q.defer();
      var options = {
        center: new google.maps.LatLng(46.2491399, 20.1444542),
        zoom: 18,
        zoomControl: true,
        scrollwheel: false,
        disableDefaultUI: true,
        draggable: true
      }
      this.map = new google.maps.Map(
        document.getElementById("map"), options
      );
      this.places = new google.maps.places.PlacesService(this.map);
      //angular.element(document.getElementById("map")).append("<div class='centerMarker'></div>");
      isInitalized = true;
      if (isInitalized == true) {
        d.resolve(isInitalized);
      } else { d.reject(isInitalized); }
      return d.promise;
    }

    this.getMap = function () {
      return this.map ? this.map : undefined;
    }

    this.search = function (str) {
      var d = $q.defer();
      this.places.textSearch({ query: str }, function (results, status) {
        if (status == 'OK') {
          d.resolve(results[0]);
        }
        else d.reject(status);
      });
      return d.promise;
    }

    this.setCenterFromCoordinates = function (lat, lng) {
      var latlng = { lat: lat, lng: lng };
      this.map.setCenter(latlng);
    }

    this.getCenterPos = function () {
      var yaltyMap = this.map;
      return { "pos": yaltyMap.getCenter(), "zoom": yaltyMap.getZoom() };
    }

    this.addMarker = function (res) {
      /*if (this.marker) this.marker.setMap(null);
      this.marker = new google.maps.Marker({
        map: this.map,
        position: res.geometry.location,
      });*/
      this.map.setCenter(res.geometry.location);
    }

    this.refreshMarker = function (pos) {
      this.map.setCenter(pos);
      /*if (this.marker) this.marker.setMap(null);
      this.marker = new google.maps.Marker({
        map: this.map,
        position: pos,
      });*/
    }
  })

  .service('LoyaltyCardService', function ($q, $http, API_PREFIX, GoogleAnalyticsService) {
    var modifyLoyaltyCard = function (loyaltyCardId) {
      return $q(function (resolve, reject) {
        var payload = {};
        payload.loyaltyCardId = loyaltyCardId;
        $http.post(API_PREFIX.url + '/loyaltyCard/getLoyaltyCardStampDetailViewData', payload).then(function (result) {
          if (result.data.errorCode == 0) {
            GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/hűségkártyáink/hűségkártya_részletes_adatok_lekérdezése_sikeres",
              "HU Partner [PRIVÁT] - Hűségkártyáink oldal - Hűségkártya adatainak lekérdezése sikeres")
          } else {
            GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/hűségkártyáink/hűségkártya_részletes_adatok_lekérdezése_sikertelen",
              "HU Partner [PRIVÁT] - Hűségkártyáink oldal - Hűségkártya adatainak lekérdezése sikertelen")
          }
          resolve(result.data);
        }).catch(function (result) {
          GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/hűségkártyáink/hűségkártya_részletes_adatok_lekérdezése_sikertelen",
            "HU Partner [PRIVÁT] - Hűségkártyáink oldal - Hűségkártya adatainak lekérdezése sikertelen")
          reject(result.data);
        });
      });
    }

    var addOrUpdateLoyaltyCard = function (payload) {
      return $q(function (resolve, reject) {
        $http.post(API_PREFIX.url + '/loyaltyCard/addOrUpdateLoyaltyCardStamp_v2', payload).then(function (result) {
          if (result.data.errorCode == 0) {
            GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/hűségkártyáink/hűségkártya_létrehozás_módosítás_sikeres",
              "HU Partner [PRIVÁT] - Hűségkártyáink oldal - Hűségkártya létrehozása, módosítása sikeres")
          } else {
            GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/hűségkártyáink/hűségkártya_létrehozás_módosítás_sikertelen",
              "HU Partner [PRIVÁT] - Hűségkártyáink oldal - Hűségkártya létrehozása, módosítása sikertelen")
          }
          resolve(result.data);
        }).catch(function (result) {
          GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/hűségkártyáink/hűségkártya_létrehozás_módosítás_sikertelen",
            "HU Partner [PRIVÁT] - Hűségkártyáink oldal - Hűségkártya létrehozása, módosítása sikertelen")
          reject(result.data);
        });
      });
    }

    var activateOrDeactivateLoyaltyCard = function (loyaltyCardId, actionType, stopPreviousCardIssuing) {
      return $q(function (resolve, reject) {
        var obj = {};
        obj.loyaltyCardId = loyaltyCardId;
        obj.actionType = actionType;
        obj.stopPreviousCardIssuing = stopPreviousCardIssuing;
        $http.post(API_PREFIX.url + '/loyaltyCard/activateLoyaltyCardStampPromotion_v2', obj).then(function (result) {
          if (result.data.errorCode == 0) {
            GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/hűségkártyáink/hűségkártya_promóció_módosítás_sikeres",
              "HU Partner [PRIVÁT] - Hűségkártyáink oldal - Hűségkártya promóció módosítása sikeres")
          } else {
            GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/hűségkártyáink/hűségkártya_promóció_módosítás_sikertelen",
              "HU Partner [PRIVÁT] - Hűségkártyáink oldal - Hűségkártya promóció módosítása sikertelen")
          }
          resolve(result.data);
        }).catch(function (result) {
          GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/hűségkártyáink/hűségkártya_promóció_módosítás_sikertelen",
            "HU Partner [PRIVÁT] - Hűségkártyáink oldal - Hűségkártya promóció módosítása sikertelen")
          reject(result.data);
        });
      });
    }

    return {
      addOrUpdateLoyaltyCard: addOrUpdateLoyaltyCard,
      modifyLoyaltyCard: modifyLoyaltyCard,
      activateOrDeactivateLoyaltyCard: activateOrDeactivateLoyaltyCard
    };
  })

  .service('LoyaltyCardService2', function ($q, $http, API_PREFIX, GoogleAnalyticsService) {
    var modifyLoyaltyCard = function (loyaltyCardId) {
      return $q(function (resolve, reject) {
        var payload = {};
        payload.loyaltyCardId = loyaltyCardId;
        $http.post(API_PREFIX.url + '/loyaltyCard/getLoyaltyCardDetailViewData', payload).then(function (result) {
          if (result.data.errorCode == 0) {
            GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/törzskártyáink/törzskártya_részletes_adatok_lekérdezése_sikeres",
              "HU Partner [PRIVÁT] - Törzskártyáink oldal - Törzskártya adatainak lekérdezése sikeres")
          } else {
            GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/törzskártyáink/törzskártya_részletes_adatok_lekérdezése_sikertelen",
              "HU Partner [PRIVÁT] - Törzskártyáink oldal - Törzskártya adatainak lekérdezése sikertelen")
          }
          resolve(result.data);
        }).catch(function (result) {
          GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/törzskártyáink/törzskártya_részletes_adatok_lekérdezése_sikertelen",
            "HU Partner [PRIVÁT] - Törzskártyáink oldal - Törzskártya adatainak lekérdezése sikertelen")
          reject(result.data);
        });
      });
    }

    var addOrUpdateLoyaltyCard = function (payload) {
      return $q(function (resolve, reject) {
        $http.post(API_PREFIX.url + '/loyaltyCard/addOrUpdateLoyaltyCard', payload).then(function (result) {
          if (result.data.errorCode == 0) {
            GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/törzskártyáink/törzskártya_létrehozás_módosítás_sikeres",
              "HU Partner [PRIVÁT] - Törzskártyáink oldal - Törzskártya létrehozása, módosítása sikeres")
          } else {
            GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/törzskártyáink/törzskártya_létrehozás_módosítás_sikertelen",
              "HU Partner [PRIVÁT] - Törzskártyáink oldal - Törzskártya létrehozása, módosítása sikertelen")
          }
          resolve(result.data);
        }).catch(function (result) {
          GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/törzskártyáink/törzskártya_létrehozás_módosítás_sikertelen",
            "HU Partner [PRIVÁT] - Törzskártyáink oldal - Törzskártya létrehozása, módosítása sikertelen")
          reject(result.data);
        });
      });
    }

    var activateOrDeactivateLoyaltyCard = function (loyaltyCardId, actionType) {
      return $q(function (resolve, reject) {
        var obj = {};
        obj.loyaltyCardId = loyaltyCardId;
        obj.actionType = actionType;
        $http.post(API_PREFIX.url + '/loyaltyCard/activateLoyaltyCardPromotion', obj).then(function (result) {
          if (result.data.errorCode == 0) {
            GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/törzskártyáink/törzskártya_promóció_módosítás_sikeres",
              "HU Partner [PRIVÁT] - Törzskártyáink oldal - Törzskártya promóció módosítása sikeres")
          } else {
            GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/törzskártyáink/törzskártya_promóció_módosítás_sikertelen",
              "HU Partner [PRIVÁT] - Törzskártyáink oldal - Törzskártya promóció módosítása sikertelen")
          }
          resolve(result.data);
        }).catch(function (result) {
          GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/törzskártyáink/törzskártya_promóció_módosítás_sikertelen",
            "HU Partner [PRIVÁT] - Törzskártyáink oldal - Törzskártya promóció módosítása sikertelen")
          reject(result.data);
        });
      });
    }

    return {
      addOrUpdateLoyaltyCard: addOrUpdateLoyaltyCard,
      modifyLoyaltyCard: modifyLoyaltyCard,
      activateOrDeactivateLoyaltyCard: activateOrDeactivateLoyaltyCard
    };
  })

  .service('CouponService', function ($q, $http, API_PREFIX, GoogleAnalyticsService) {
    var modifyCoupon = function (couponId) {
      return $q(function (resolve, reject) {
        var payload = '{"couponId": "' + couponId + '"}';
        var obj = JSON.parse(payload);
        $http.post(API_PREFIX.url + '/coupon/getCouponDetailViewData', obj).then(function (result) {
          if (result.data.errorCode == 0) {
            GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/kuponok_kuponjaink/kupon_részletes_adatok_lekérdezése_sikeres",
              "HU Partner [PRIVÁT] - Kuponjaink oldal - Kupon részletes adatok lekérdezése sikeres")
          } else {
            GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/kuponok_kuponjaink/kupon_részletes_adatok_lekérdezése_sikertelen",
              "HU Partner [PRIVÁT] - Kuponjaink oldal - Kupon részletes adatok lekérdezése sikertelen")
          }
          resolve(result.data);
        }).catch(function (result) {
          GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/kuponok_kuponjaink/kupon_részletes_adatok_lekérdezése_sikertelen",
            "HU Partner [PRIVÁT] - Kuponjaink oldal - Kupon részletes adatok lekérdezése sikertelen")
          reject(result.data.errorCode);
        });
      });
    }

    const getCouponCollections = function (couponCollectionId, couponPromoStatusCode) {
        return $q(function (resolve, reject) {
            const requestBody = {
            couponCollectionId: couponCollectionId,
            couponPromoStatusCode: couponPromoStatusCode
            }
            $http.post(API_PREFIX.url + '/coupon/getListOfActiveCouponCollections', requestBody).then(function (collections) {
                resolve(collections.data);
            }).catch(function (collections) {
                reject(collections.data.errorCode);
            })
        });
    }

    var activateOrDeactivateCoupon = function (couponId, actionType, datesTimeZone) {
      return $q(function (resolve, reject) {
        var obj = {};
        obj.couponId = couponId;
        obj.actionType = actionType;
        obj.datesTimeZone = datesTimeZone; //FONTOS: amikor a Kuponjaink oldalon választható lesz a timezone tegyük majd ezt is rendbe, írjuk be a db-be majd az időkkel együtt a timezone-t is és onnan validáljuk.
        $http.post(API_PREFIX.url + '/coupon/activateCouponPromotion_v2', obj).then(function (result) {
          if (result.data.errorCode == 0) {
            GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/kuponok_kuponjaink/kupon_promóció_módosítás_sikeres",
              "HU Partner [PRIVÁT] - Kuponjaink oldal - Kupon promóció módosítás sikeres")
          } else {
            GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/kuponok_kuponjaink/kupon_promóció_módosítás_sikertelen",
              "HU Partner [PRIVÁT] - Kuponjaink oldal - Kupon promóció módosítás sikertelen")
          }
          resolve(result.data);
        }).catch(function (result) {
          GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/kuponok_kuponjaink/kupon_promóció_módosítás_sikertelen",
            "HU Partner [PRIVÁT] - Kuponjaink oldal - Kupon promóció módosítás sikertelen")
          reject(result.data);
        });
      });
    }

    /*var getCoupons = function () {
      return $q(function (resolve, reject) {
        $http.get(API_PREFIX.url + '/coupon/getAllCouponsListViewData').then(function (result) {
          if (result.data.errorCode == 0) {
            resolve(result.data.allCouponsListViewData);
          } else {
            reject(result.data.errorCode);
          }
        })
      })
    };*/

    return {
      //getCoupons: getCoupons,
      modifyCoupon: modifyCoupon,
      activateOrDeactivateCoupon: activateOrDeactivateCoupon,
      getCouponCollections: getCouponCollections
    };
  })

  .service('StoreService', function ($q, $http, $location, API_PREFIX, GoogleAnalyticsService) {
    var modifyStore = function (companyStoreId) {
      return $q(function (resolve, reject) {
        var payload = '{"companyStoreId": "' + companyStoreId + '"}';
        var obj = JSON.parse(payload);
        $http.post(API_PREFIX.url + '/company/getCompanyStoreData', obj).then(function (result) {
          if (result.data.errorCode == 0) {
            GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/üzletek/üzlet_részletes_adat_lekérdezés_sikeres",
              "HU Partner [PRIVÁT] - Üzletek oldal - Sikeres üzlet adatok lekérdezés")
          } else {
            GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/üzletek/üzlet_részletes_adat_lekérdezés_sikertelen",
              "HU Partner [PRIVÁT] - Üzletek oldal - Sikertelen üzlet adatok lekérdezés")
          }
          resolve(result.data.companyStoreData[0]);
        }).catch(function (result) {
          GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/üzletek/üzlet_részletes_adat_lekérdezés_sikertelen",
            "HU Partner [PRIVÁT] - Üzletek oldal - Sikertelen üzlet adatok lekérdezés")
          reject(result.data.errorCode);
        });
      });
    }

    var addOrUpdate = function (companyStoresData) {
      return $q(function (resolve, reject) {
        $http.post(API_PREFIX.url + '/company/addOrUpdateCompanyStoreData_v2', companyStoresData).then(function (result) {
          if (result.data.errorCode == 0) {
            GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/üzletek/üzlet_létrehozás_módosítás_sikeres",
              "HU Partner [PRIVÁT] - Üzletek oldal - Sikeres üzlet létrehozás, módosítás")
          } else {
            GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/üzletek/üzlet_létrehozás_módosítás_sikertelen",
              "HU Partner [PRIVÁT] - Üzletek oldal - Sikertelen üzlet létrehozás, módosítás")
          }
          resolve(result.data);
        }).catch(function (result) {
          GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/üzletek/üzlet_létrehozás_módosítás_sikertelen",
            "HU Partner [PRIVÁT] - Üzletek oldal - Sikertelen üzlet létrehozás, módosítás")
          reject(result.data);
        });
      });
    }

    var removeStore = function (companyStoreId) {
      return $q(function (resolve, reject) {
        $http.post(API_PREFIX.url + '/company/removeCompanyStore_v2', companyStoreId).then(function (result) {
          if (result.data.errorCode == 0) {
            GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/üzletek/üzlet_törlés_sikeres",
              "HU Partner [PRIVÁT] - Üzletek oldal - Sikeres üzlet törlés")
          } else {
            GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/üzletek/üzlet_törlés_sikertelen",
              "HU Partner [PRIVÁT] - Üzletek oldal - Sikertelen üzlet törlés")
          }
          resolve(result);
        }, function (result) {
          GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/üzletek/üzlet_törlés_sikertelen",
            "HU Partner [PRIVÁT] - Üzletek oldal - Sikertelen üzlet törlés")
          reject(result);
        });
      });
    }

    var getCities = function (cityTypeSelector) {
      var url = $location.absUrl();
      cityTypeSelector.url = url;
      return $q(function (resolve, reject) {
        $http.post(API_PREFIX.url + '/etc/getCities', cityTypeSelector).then(function (result) {
          resolve(result.data);
        }, function (result) {
          reject(result.data);
        })
      });
    }

    return {
      modifyStore: modifyStore,
      addOrUpdate: addOrUpdate,
      removeStore: removeStore,
      getCities: getCities
    }
  })

  .service('AuthService', function ($q, $http, $location, $state, $window, $cookies, $rootScope, API_PREFIX, GoogleAnalyticsService) {

    var register = function (user) {
      return $q(function (resolve, reject) {
        $http.post(API_PREFIX.url + '/partner/registerUser', user).then(function (result) {
          if (result.data.success) {
            resolve(result.data.msg);
          } else {
            reject(result.data.msg);
          }
        });
      });
    };

    var forgottenPassword = function (userName) {
      return $q(function (resolve, reject) {
        $http.post(API_PREFIX.url + '/partner/sendPasswordResetEmail', userName).then(function (result) {
          resolve(result);
        }, function (result) {
          reject(result);
        });
      });
    }

    var signup = function (partnerData) {
      return $q(function (resolve, reject) {
        $http.post(API_PREFIX.url + '/partner/preregisterPartner', partnerData).then(function (result) {
          resolve(result);
        }, function (result) {
          reject(result);
        }, function () {
          reject()
        });
      });
    }

    var unsubscribe = function () {
      let params = {
        as: $state.params.as,
        email: $state.params.email,
        cm: $state.params.cm,
        id: $state.params.id
      }
      return $q(function (resolve, reject) {
        $http.post(API_PREFIX.url + '/etc/emailMarketingUnsubscribe', params).then(function (result) {
          resolve(result.data);
        }, function (result) {
          reject(result.data);
        });
      });
    }

    var checkPaymentStatus = function () {
      let paymentTransactionId = $state.params.transactionId;
      return $q(function (resolve, reject) {
        $http.post(API_PREFIX.url + '/partner/checkPaymentStatus', { paymentTransactionId: paymentTransactionId }).then(function (result) {
          resolve(result.data);
        }, function (result) {
          reject(result.data);
        });
      });
    }

    var getPartnerPreregistrationData = function () {
      var url = $location.absUrl();
      var payload = '{ "url": "' + url + '"}';
      var obj = JSON.parse(payload);
      return $q(function (resolve, reject) {
        $http.post(API_PREFIX.url + '/partner/getPartnerPreregistrationData', obj).then(function (result) {
          if (!url.includes("?preview")) {
            if (result.data.errorCode == 0) {
              GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/partner_regisztráció/regisztrációs_adatok_lekérdezése_sikeres",
                "HU Partner [PRIVÁT] - Regisztráció - Adatok lekérdezése sikeres")
            } else {
              GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/partner_regisztráció/regisztrációs_adatok_lekérdezése_sikertelen",
                "HU Partner [PRIVÁT] - Regisztráció - Adatok lekérdezése sikertelen")
            }
          }
          resolve(result);
        }, function (result) {
          if (!url.includes("?preview")) {
            GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/partner_regisztráció/regisztrációs_adatok_lekérdezése_sikertelen",
              "HU Partner [PRIVÁT] - Regisztráció - Adatok lekérdezése sikertelen")
          }
          reject(result);
        });
      });
    }

    var isUserAuthorized = function (state) {
      var url = $location.absUrl();
      state.url = url;
      return $q(function (resolve, reject) {
        $http.post(API_PREFIX.url + '/partner/isUserAuthorized', state).then(function (result) {
          resolve(result.data);
        }, function (result) {
          reject(result.data);
        });
      });
    };

    var activatePartnerUser = function (user) {
      return $q(function (resolve, reject) {
        $http.post(API_PREFIX.url + '/partner/activateUser', user).then(function (result) {
          if (result.data.errorCode == 0) {
            GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/felhasználó_fiók_aktiváció/felhasználó_aktiváció_sikeres",
              "HU Partner [PRIVÁT] - Felhasználói fiók aktiváció - Sikeres előregisztráció")
          } else {
            GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/felhasználó_fiók_aktiváció/felhasználó_aktiváció_sikertelen",
              "HU Partner [PRIVÁT] - Felhasználói fiók aktiváció - Sikertelen előregisztráció")
          }
          resolve(result);
        }, function (result) {
          GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/felhasználó_fiók_aktiváció/felhasználó_aktiváció_sikertelen",
            "HU Partner [PRIVÁT] - Felhasználói fiók aktiváció - Sikertelen előregisztráció")
          reject(result);
        });
      });
    };

    var activateUser = function () {
      var url = $location.url();
      var payload = '{ "url": "' + url.split('&')[0] + '"}';
      console.log("payload: " + payload);
      var obj = JSON.parse(payload);
      return $q(function (resolve, reject) {
        $http.post(API_PREFIX.url + '/user/activateUser', obj).then(function (result) {
          if (result.data.errorCode == 0) {
            GoogleAnalyticsService.sendDataToGA("/HU/felhasználói/privat/felhasználó_fiók_aktiváció/felhasználó_aktiváció_sikeres",
              "HU Felhasználó [PRIVÁT] - Fiók aktiváció - Sikeres")
          } else {
            GoogleAnalyticsService.sendDataToGA("/HU/felhasználói/privat/felhasználó_fiók_aktiváció/felhasználó_aktiváció_sikertelen",
              "HU Felhasználó [PRIVÁT] - Fiók aktiváció - Sikertelen")
          }
          resolve(result);
        }, function (result) {
          GoogleAnalyticsService.sendDataToGA("/HU/felhasználói/privat/felhasználó_fiók_aktiváció/felhasználó_aktiváció_sikertelen",
            "HU Felhasználó [PRIVÁT] - Fiók aktiváció - Sikertelen")
          reject(result);
        });
      });
    };


    var checkPartnerDeleteLinkExpiration = function () {
      var url = $location.url();
      var payload = '{ "url": "' + url + '"}';
      var obj = JSON.parse(payload);
      return $q(function (resolve, reject) {
        $http.post(API_PREFIX.url + '/partner/checkPartnerDeleteLinkExpiration', obj).then(function (result) {
          if (result.data.errorCode == 0) {
            GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/partner_felhasználó_fiók_törlés/érvényes_link",
              "HU Partner [PRIVÁT] - Felhasználói fiók törlés - Érvényes link")
          } else {
            GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/partner_felhasználó_fiók_törlés/érvénytelen_link",
              "HU Partner [PRIVÁT] - Felhasználói fiók törlés - Érvénytelen link")
          }
          resolve(result);
        }, function (result) {
          GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/partner_felhasználó_fiók_törlés/érvénytelen_link",
            "HU Partner [PRIVÁT] - Felhasználói fiók törlés - Érvénytelen link")
          reject(result);
        });
      });
    }

    var checkActivationStringExpiration = function (partnerOrUser) {
      var url = $location.url();
      var payload = '{ "url": "' + url + '"}';
      var obj = JSON.parse(payload);
      return $q(function (resolve, reject) {
        $http.post(API_PREFIX.url + '/partner/checkActivationStringExpiration', obj).then(function (result) {
          if (result.data.errorCode == 0) {
            GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/felhasználó_fiók_aktiváció/érvényes_link",
              "HU Partner [PRIVÁT] - Felhasználói fiók aktiváció - Érvényes link")
            resolve(result);
          } else {
            GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/felhasználó_fiók_aktiváció/érvénytelen_link",
              "HU Partner [PRIVÁT] - Felhasználói fiók aktiváció - Érvénytelen link")
            reject(result);
          }
        }, function () {
          GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/felhasználó_fiók_aktiváció/érvénytelen_link",
            "HU Partner [PRIVÁT] - Felhasználói fiók aktiváció - Érvénytelen link")
        });
      });
    };

    var checkPasswordResetStringExpiration = function (partnerOrUser) {
      var url = $location.url();
      var payload = '{ "url": "' + url + '"}';
      if(partnerOrUser == 'user'){
        payload = '{ "url": "' + url.split('&')[0] + '"}';
      }
      console.log("payload: " + payload);
      var obj = JSON.parse(payload);
      return $q(function (resolve, reject) {
        $http.post(API_PREFIX.url + '/' + partnerOrUser + '/checkPasswordResetStringExpiration', obj).then(function (result) {
          if (result.data.errorCode == 0) {
            if (partnerOrUser == "user") {
              GoogleAnalyticsService.sendDataToGA("/HU/felhasználói/privat/felhasználó_fiók_jelszó_reset/érvényes_link",
                "HU Felhasználó [PRIVÁT] - Fiók jelszó reset - Érvényes link")
            } else if (partnerOrUser == "partner") {
              GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/partner_felhasználó_fiók_jelszó_reset/érvényes_link",
                "HU Partner [PRIVÁT] - Felhasználói fiók jelszó reset - Érvényes link")
            }
            resolve(result);
          } else {
            if (partnerOrUser == "user") {
              GoogleAnalyticsService.sendDataToGA("/HU/felhasználói/privat/felhasználó_fiók_jelszó_reset/érvénytelen_link",
                "HU Felhasználó [PRIVÁT] - Fiók jelszó reset - Érvénytelen link")
            } else if (partnerOrUser == "partner") {
              GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/partner_felhasználó_fiók_jelszó_reset/érvénytelen_link",
                "HU Partner [PRIVÁT] - Felhasználói fiók jelszó reset - Érvénytelen link")
            }
            reject(result);
          }
        }, function () {
          GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/partner_felhasználó_fiók_jelszó_reset/érvénytelen_link",
            "HU Partner [PRIVÁT] - Felhasználói fiók jelszó reset - Érvénytelen link")
        });
      });
    };

    var resendActivationEmail = function () {
      return $q(function (resolve, reject) {
        $http.post(API_PREFIX.url + '/partner/resendActivationEmail', {}).then(function (result) {
          if (result.data.errorCode == 0) {
            GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/master_fiók_aktiváció/sikeres_email_kiküldés",
              "HU Partner [PRIVÁT] - Master fiók aktiváció - Sikeres email kiküldés")
            resolve(result.data);
          } else {
            GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/master_fiók_aktiváció/sikertelen_email_kiküldés",
              "HU Partner [PRIVÁT] - Master fiók aktiváció - Sikertelen email kiküldés")
            reject(result.data);
          }
        }, function () {
          GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/master_fiók_aktiváció/sikertelen_email_kiküldés",
            "HU Partner [PRIVÁT] - Master fiók aktiváció - Sikertelen email kiküldés")
        });
      });
    };

    var closeTransaction = function (paymentTransactionId) {
      return $q(function (resolve, reject) {
        $http.post(API_PREFIX.url + '/partner/closeTransactionByPartneruserAfterClosingBarionPage', { paymentTransactionId }).then(function (result) {
          resolve(result.data);
        }, function () {
          reject();
        })
      })
    };

    var login = function (user) {
      return $q(function (resolve, reject) {
        $http.post(API_PREFIX.url + '/partner/loginUser', user, {timeout: 20000}).then(function (result) {
          if (result.data.success) {
            GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/felhasználó_beléptetés_sikeres",
              "HU Partner [PRIVÁT] - Sikeres bejelentkezés")
            resolve(result.data);
          } else {
            GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/felhasználó_beléptetés_sikertelen",
              "HU Partner [PRIVÁT] - Sikertelen bejelentkezés")
            reject(result.data);
          }
        }, function () {
          reject(null)
          GoogleAnalyticsService.sendDataToGA("/HU/partneri/privat/felhasználó_beléptetés_sikertelen",
            "HU Partner [PRIVÁT] - Sikertelen bejelentkezés")
        });
      });
    };

    var removeCookies = function(){
      $rootScope.isDataSheetModified = false;
      $cookies.remove('firstName');
      $cookies.remove('lastName');
      $cookies.remove('nameTitle');
      $cookies.remove('areRedeemPageFilterSettingsPredefined');
      $cookies.remove('defaultRedeemPageShowFilter');
      $cookies.remove('defaultRedeemPageCityFilter');
      $cookies.remove('defaultRedeemPageCompanyStoreFilter');
      $cookies.remove('defaultRedeemPageCompanyStoreCashRegisterFilter');
      $cookies.remove('userEmail');
      $cookies.remove('userCompany');
      $cookies.remove('compLogo');
      $cookies.remove('authorizationLevel');
      $cookies.remove('currentUserType');
      $cookies.remove('expdt');
      $cookies.remove('alrtdt');
      $cookies.remove('sbcrtyp');
      $cookies.remove('brnpymntd');
      $cookies.remove('trnsctncrtdtstmp');
      $cookies.remove('additionalCompanyStoreNumber');
      $cookies.remove('additionalPartnerUserNumber');
      $cookies.remove('additionalFreePartnerUserNumber');
      $cookies.remove('limitMonthlyPushMessageMaxNo');
      $cookies.remove('limitMonthlyIndividualPushMessageMaxNo');
      $cookies.remove('paymentFrequency');
      $cookies.remove('isPaymentForTheNextPeriodRequired');
      $cookies.remove('recurringPaymentEnabled');
      $cookies.remove('redeemPageShowFilter');
      $cookies.remove('redeemPageCompanyStoreFilter');
      $cookies.remove('redeemPageCityFilter');
      $cookies.remove('redeemPageCompanyStoreFilterCheckAll');
      $cookies.remove('redeemPageCityFilterCheckAll');
      $cookies.remove('redeemPageCompanyStoreCashRegisterFilter');
      $cookies.remove('areCustomDisplaySettingsForRedeemHandlingSheetEnabled');
      $cookies.remove('canSendIndividualPushMessages');
      $cookies.remove('freePackageSubscriptionExpTimestamp');
      $cookies.remove('isYaltyStartEnabled');
      $cookies.remove('partnerType');
      $window.localStorage.clear();
      $rootScope.authorizationLevel = null;
      $rootScope.$$listeners['timer-tick'] = [];
      $rootScope.$$listeners['timer-stopped'] = [];
      $rootScope.$broadcast('timer-stop');
      $cookies.remove('token');
      $cookies.remove('redeemToken');
      $cookies.remove('tempToken');
      $cookies.remove('isMarketingPackagePageVisible');
    }

    var logout = function (reason) {
      return $q(function (resolve, reject) {
        $http.post(API_PREFIX.url + '/partner/logoutUser', '').then(function (result) {
          $rootScope.isDataSheetModified = false;
          $cookies.remove('firstName');
          $cookies.remove('lastName');
          $cookies.remove('nameTitle');
          $cookies.remove('areRedeemPageFilterSettingsPredefined');
          $cookies.remove('defaultRedeemPageShowFilter');
          $cookies.remove('defaultRedeemPageCityFilter');
          $cookies.remove('defaultRedeemPageCompanyStoreFilter');
          $cookies.remove('defaultRedeemPageCompanyStoreCashRegisterFilter');
          $cookies.remove('userEmail');
          $cookies.remove('userCompany');
          $cookies.remove('compLogo');
          $cookies.remove('authorizationLevel');
          $cookies.remove('currentUserType');
          $cookies.remove('expdt');
          $cookies.remove('alrtdt');
          $cookies.remove('sbcrtyp');
          $cookies.remove('brnpymntd');
          $cookies.remove('trnsctncrtdtstmp');
          $cookies.remove('additionalCompanyStoreNumber');
          $cookies.remove('additionalPartnerUserNumber');
          $cookies.remove('additionalFreePartnerUserNumber');
          $cookies.remove('limitMonthlyPushMessageMaxNo');
          $cookies.remove('limitMonthlyIndividualPushMessageMaxNo');
          $cookies.remove('paymentFrequency');
          $cookies.remove('isPaymentForTheNextPeriodRequired');
          $cookies.remove('isMarketingPackagePageVisible');
          $cookies.remove('recurringPaymentEnabled');
          $cookies.remove('redeemPageShowFilter');
          $cookies.remove('redeemPageCompanyStoreFilter');
          $cookies.remove('redeemPageCityFilter');
          $cookies.remove('redeemPageCompanyStoreFilterCheckAll');
          $cookies.remove('redeemPageCityFilterCheckAll');
          $cookies.remove('redeemPageCompanyStoreCashRegisterFilter');
          $cookies.remove('areCustomDisplaySettingsForRedeemHandlingSheetEnabled');
          $cookies.remove('canSendIndividualPushMessages');
          $cookies.remove('freePackageSubscriptionExpTimestamp');
          $cookies.remove('isYaltyStartEnabled');
          $cookies.remove('partnerType');
          $window.localStorage.clear();
          $rootScope.authorizationLevel = null;
          $rootScope.$$listeners['timer-tick'] = [];
          $rootScope.$$listeners['timer-stopped'] = [];
          $rootScope.$broadcast('timer-stop');
          if(reason == "cashRegisterLogout") {
            $state.go('cashRegisterLogin');
          } else if (reason == "cashRegisterTimeout") {
            $state.go('cashRegisterLogin', { 'errorText': 'Lejárt az időkorlát.' });
          } else if (reason == "timeout") {
            $state.go('partner_HU.login', { 'errorText': 'Lejárt az időkorlát.' });
          } else if (reason == 'noredirect') { } else {
            $state.go('partner_HU.login');
          }
          resolve(true)
        }, function () {
          $rootScope.isDataSheetModified = false;
          $cookies.remove('firstName');
          $cookies.remove('lastName');
          $cookies.remove('nameTitle');
          $cookies.remove('areRedeemPageFilterSettingsPredefined');
          $cookies.remove('defaultRedeemPageShowFilter');
          $cookies.remove('defaultRedeemPageCityFilter');
          $cookies.remove('defaultRedeemPageCompanyStoreFilter');
          $cookies.remove('defaultRedeemPageCompanyStoreCashRegisterFilter');
          $cookies.remove('userEmail');
          $cookies.remove('userCompany');
          $cookies.remove('compLogo');
          $cookies.remove('authorizationLevel');
          $cookies.remove('currentUserType');
          $cookies.remove('expdt');
          $cookies.remove('alrtdt');
          $cookies.remove('sbcrtyp');
          $cookies.remove('brnpymntd');
          $cookies.remove('trnsctncrtdtstmp');
          $cookies.remove('additionalCompanyStoreNumber');
          $cookies.remove('additionalPartnerUserNumber');
          $cookies.remove('additionalFreePartnerUserNumber');
          $cookies.remove('limitMonthlyPushMessageMaxNo');
          $cookies.remove('limitMonthlyIndividualPushMessageMaxNo');
          $cookies.remove('redeemPageShowFilter');
          $cookies.remove('redeemPageCompanyStoreFilter');
          $cookies.remove('redeemPageCityFilter');
          $cookies.remove('redeemPageCompanyStoreFilterCheckAll');
          $cookies.remove('redeemPageCityFilterCheckAll');
          $cookies.remove('redeemPageCompanyStoreCashRegisterFilter');
          $cookies.remove('areCustomDisplaySettingsForRedeemHandlingSheetEnabled');
          $cookies.remove('canSendIndividualPushMessages');
          $cookies.remove('freePackageSubscriptionExpTimestamp');
          $cookies.remove('isYaltyStartEnabled');
          $cookies.remove('partnerType');
          $window.localStorage.clear();
          $rootScope.authorizationLevel = null;
          $rootScope.$$listeners['timer-tick'] = [];
          $rootScope.$$listeners['timer-stopped'] = [];
          $rootScope.$broadcast('timer-stop');
          if(reason == "cashRegisterLogout") {
            $state.go('cashRegisterLogin');
          } else if (reason == 'noredirect') { } else {
            $state.go('partner_HU.login');
          }
          reject(true)
        })
      })
    }

    var updateSessionData = function (result) {
      var userName = $cookies.get('userName');
      var userEmail = $cookies.get('userEmail');
      var userCompany = $cookies.get('userCompany');
      var compLogo = $cookies.get('compLogo');
      var authorizationLevel = $cookies.get('authorizationLevel');
      var currentUserType = $cookies.get('currentUserType');
      var sbcrtyp = $cookies.get('sbcrtyp');
      var nameTitle = $cookies.get('nameTitle');
      var lastName = $cookies.get('lastName');
      var firstName = $cookies.get('firstName');
      var areRedeemPageFilterSettingsPredefined = $cookies.get('areRedeemPageFilterSettingsPredefined');
      var defaultRedeemPageShowFilter = $cookies.get('defaultRedeemPageShowFilter');
      var defaultRedeemPageCityFilter = $cookies.get('defaultRedeemPageCityFilter');
      var defaultRedeemPageCompanyStoreFilter = $cookies.get('defaultRedeemPageCompanyStoreFilter');
      var defaultRedeemPageCompanyStoreCashRegisterFilter = $cookies.get('defaultRedeemPageCompanyStoreCashRegisterFilter');
      var additionalCompanyStoreNumber = $cookies.get('additionalCompanyStoreNumber');
      var additionalPartnerUserNumber = $cookies.get('additionalPartnerUserNumber');
      var additionalFreePartnerUserNumber = $cookies.get('additionalFreePartnerUserNumber');
      var limitMonthlyPushMessageMaxNo = $cookies.get('limitMonthlyPushMessageMaxNo');
      var limitMonthlyIndividualPushMessageMaxNo = $cookies.get('limitMonthlyIndividualPushMessageMaxNo');
      var areCustomDisplaySettingsForRedeemHandlingSheetEnabled = $cookies.get('areCustomDisplaySettingsForRedeemHandlingSheetEnabled');
      var canSendIndividualPushMessages = $cookies.get('canSendIndividualPushMessages');
      var freePackageSubscriptionExpTimestamp = $cookies.get('freePackageSubscriptionExpTimestamp');
      var isYaltyStartEnabled = $cookies.get('isYaltyStartEnabled');
      var partnerType = $cookies.get('partnerType');
      var expireDate = new Date(result.endDate);
      $cookies.put('expdt', result.endDate, { 'expires': expireDate });
      $cookies.put('alrtdt', result.alertDate, { 'expires': expireDate });
      $cookies.put('userName', userName, { 'expires': expireDate });
      $cookies.put('userEmail', userEmail, { 'expires': expireDate });
      $cookies.put('userCompany', userCompany, { 'expires': expireDate });
      $cookies.put('compLogo', compLogo, { 'expires': expireDate });
      $cookies.put('authorizationLevel', authorizationLevel, { 'expires': expireDate });
      $cookies.put('currentUserType', currentUserType, { 'expires': expireDate });
      $cookies.put('sbcrtyp', sbcrtyp, { 'expires': expireDate });
      $cookies.put('nameTitle', nameTitle, { 'expires': expireDate });
      $cookies.put('lastName', lastName, { 'expires': expireDate });
      $cookies.put('firstName', firstName, { 'expires': expireDate });
      $cookies.put('areRedeemPageFilterSettingsPredefined', areRedeemPageFilterSettingsPredefined, { 'expires': expireDate });
      $cookies.put('defaultRedeemPageShowFilter', defaultRedeemPageShowFilter, { 'expires': expireDate });
      $cookies.put('defaultRedeemPageCityFilter', defaultRedeemPageCityFilter, { 'expires': expireDate });
      $cookies.put('defaultRedeemPageCompanyStoreFilter', defaultRedeemPageCompanyStoreFilter, { 'expires': expireDate });
      $cookies.put('defaultRedeemPageCompanyStoreCashRegisterFilter', defaultRedeemPageCompanyStoreCashRegisterFilter, { 'expires': expireDate });
      $cookies.put('additionalCompanyStoreNumber', additionalCompanyStoreNumber, { 'expires': expireDate });
      $cookies.put('additionalPartnerUserNumber', additionalPartnerUserNumber, { 'expires': expireDate });
      $cookies.put('additionalFreePartnerUserNumber', additionalFreePartnerUserNumber, { 'expires': expireDate });
      $cookies.put('limitMonthlyPushMessageMaxNo', limitMonthlyPushMessageMaxNo, { 'expires': expireDate });
      $cookies.put('limitMonthlyIndividualPushMessageMaxNo', limitMonthlyIndividualPushMessageMaxNo, { 'expires': expireDate });
      $cookies.put('areCustomDisplaySettingsForRedeemHandlingSheetEnabled', areCustomDisplaySettingsForRedeemHandlingSheetEnabled, { 'expires': expireDate });
      $cookies.put('canSendIndividualPushMessages', canSendIndividualPushMessages, { 'expires': expireDate });
      $cookies.put('freePackageSubscriptionExpTimestamp', freePackageSubscriptionExpTimestamp, { 'expires': expireDate });
      $cookies.put('isYaltyStartEnabled', isYaltyStartEnabled, { 'expires': expireDate });
      $cookies.put('partnerType', partnerType, { 'expires': expireDate });
    }

    const getUserAgent = () => {
      let logData = {}
      var md = new MobileDetect(window.navigator.userAgent);
      if (md.phone() != null) {
        logData.deviceType = 'Mobile'
        logData.deviceName = md.mobile();
      } else if (md.tablet() != null) {
        logData.deviceType = 'Tablet'
        logData.deviceName = md.tablet();
      } else {
        logData.deviceType = 'Desktop'
        logData.deviceName = 'Unknown'
      }
      if (logData.deviceName == "UnknownMobile" || logData.deviceName == "UnknownPhone" || logData.deviceName == "UnknownTablet") {
        logData.deviceName = "Other";
      }
      var userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'];
      logData.browserUserAgent = userAgent.trunc(499);
      logData.osType = "Unknown";
      logData.osVersion = 'Unknown'
      if (macosPlatforms.indexOf(platform) !== -1) {
        logData.osType = 'Mac OS';
      } else if (iosPlatforms.indexOf(platform) !== -1) {
        logData.osType = 'iOS';
        if (md.version('iOS') != null) {
          logData.osVersion = md.version('iOS');
        }
      } else if (windowsPlatforms.indexOf(platform) !== -1) {
        logData.osType = 'Windows';
      } else if (/Android/.test(userAgent)) {
        logData.osType = 'Android';
        if (md.version('Android') != null) {
          logData.osVersion = md.version('Android');
        }
      } else if (!os && /Linux/.test(platform)) {
        logData.osType = 'Linux';
      }
      if (window.navigator.userAgent.indexOf("Windows NT 10.0") != -1) {
        logData.osType = "Windows";
        logData.osVersion = "10";
      }
      if (window.navigator.userAgent.indexOf("Windows NT 6.2") != -1) {
        logData.osType = "Windows";
        logData.osVersion = "8";
      }
      if (window.navigator.userAgent.indexOf("Windows NT 6.1") != -1) {
        logData.osType = "Windows";
        logData.osVersion = "7";
      }
      if (window.navigator.userAgent.indexOf("Windows NT 6.0") != -1) {
        logData.osType = "Windows";
        logData.osVersion = "Vista";
      }
      if (window.navigator.userAgent.indexOf("Windows NT 5.1") != -1) {
        logData.osType = "Windows";
        logData.osVersion = "XP";
      }
      if (window.navigator.userAgent.indexOf("Windows NT 5.0") != -1) {
        logData.osType = "Windows";
        logData.osVersion = "2000";
      }
      // Opera 8.0+
      var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

      // Firefox 1.0+
      var isFirefox = typeof InstallTrigger !== 'undefined';

      // Safari 3.0+ "[object HTMLElementConstructor]" 
      var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));

      // Internet Explorer 6-11
      var isIE = /*@cc_on!@*/false || !!document.documentMode;

      // Edge 20+
      var isEdge = !isIE && !!window.StyleMedia;

      // Chrome 1 - 71
      var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
      if (isOpera) {
        logData.browserName = 'Opera';
      } else if (isFirefox) {
        logData.browserName = 'Firefox';
      } else if (isIE) {
        logData.browserName = 'IE';
      } else if (isEdge) {
        logData.browserName = 'Edge';
      } else if (isSafari) {
        logData.browserName = 'Safari';
      } else if (isChrome) {
        logData.browserName = 'Chrome';
      } else {
        logData.browserName = 'Unknown';
      }
      logData.browserVersion = 'Unknown';
      logData.devicePhysicalHeight = screen.height * window.devicePixelRatio;
      logData.devicePhysicalWidth = screen.width * window.devicePixelRatio;
      logData.deviceViewportHeight = document.documentElement.clientHeight;
      logData.deviceViewportWidth = document.documentElement.clientWidth;
      return JSON.stringify(logData)
    }

    return {
      login: login,
      logout: logout,
      removeCookies: removeCookies,
      resendActivationEmail: resendActivationEmail,
      register: register,
      forgottenPassword: forgottenPassword,
      signup: signup,
      isUserAuthorized: isUserAuthorized,
      unsubscribe: unsubscribe,
      checkPaymentStatus: checkPaymentStatus,
      updateSessionData: updateSessionData,
      checkPartnerDeleteLinkExpiration: checkPartnerDeleteLinkExpiration,
      checkPasswordResetStringExpiration: checkPasswordResetStringExpiration,
      checkActivationStringExpiration: checkActivationStringExpiration,
      activatePartnerUser: activatePartnerUser,
      activateUser: activateUser,
      getPartnerPreregistrationData: getPartnerPreregistrationData,
      closeTransaction: closeTransaction,
      getUserAgent: getUserAgent
    };
  })

  .service('GoogleAnalyticsService', function () {
    this.sendDataToGA = function (path, title) {
      if (!path && !title) return;
      if (document.location.hostname == "www.yalty.com") {
        return gtag('config', 'UA-112496735-1', {
          'page_path': path,
          'page_title': title
        });
      } else if (document.location.hostname == "yalty-appserv-dev-development-002.azurewebsites.net") {
        return gtag('config', 'UA-112496735-2', {
          'page_path': path,
          'page_title': title
        });
      }
    }
  })

  .service('commonService', function ($mdDialog) {
    this.showDialogWindowWithTitleOnly = function (title, buttonLabel, callback) {
        const alert = $mdDialog.alert()
            .title(title)
            .ok(buttonLabel)
        $mdDialog.show(alert).then(() => {
            callback && callback()
        });
    };
    this.showDialogWindow = function (title, text, buttonLabel, callback) {
        const alert = $mdDialog.alert()
            .title(title)
            .textContent(text)
            .ok(buttonLabel)
        $mdDialog.show(alert).then(() => {
            callback && callback()
        });
    };
    this.setFloatingLabelInCaseOfAutoFill = function() {
        let autoFillDectectionIntervallCounter = 0;
        const autoFillDectectionIntervall = setInterval(() => {
            const autoFillFields = $('input:-webkit-autofill');
            if (autoFillFields.length || autoFillDectectionIntervallCounter > 100) { 
                clearAutoFillDectectionIntervall();
                autoFillFields.each(function(i, el) {
                    $(el).parent().addClass('md-input-has-value');
                });
            }
            autoFillDectectionIntervallCounter++;
        }, 100)

        const clearAutoFillDectectionIntervall = () => {
            clearInterval(autoFillDectectionIntervall);
        }
    };
  })