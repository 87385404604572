import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ErrorCodeResponse } from '../interfaces/error-code-response';
import { DialogService } from 'src/app/shared/services/dialog.service';

@Injectable({
    providedIn: 'root'
})
export class ErrorHandlerService {

    constructor(private DialogService: DialogService) { }

    handleError<T>(x: T | ErrorCodeResponse): T {
        if ('errorCode' in x && x.errorCode !== 0) {
            let title: string = '';
            if (x.errorCode === 1) {
                title = 'Szerver hiba. Kérjük próbálja meg később.';
            } else if (x.errorCode === 2) {
                title = 'Jogosulatlan hozzáférés (érvénytelen token, errorCode = 2)';
            }
            if (x.errorCode === 1 || x.errorCode === 2) {
                this.DialogService
                    .openDialog(title,
                        '', 'OK', undefined);
            }

            // dialogRef.afterClosed().subscribe(() => {
            //     throw x.errorCode
            // });
        }

        return x as T;
    }
}
