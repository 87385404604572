// @ts-nocheck

//var app = angular.module('yaltyApp', ['ngRoute']);

/*app.controller('loginCtrl', function($scope,$http,$location) {
    $scope.slogan = "Digital Customer Engagement";
    $scope.showLogin = function (){
        $scope.login.attr("visibility","visible");
    };
    $scope.submit = function () {
        var logmail = $scope.username;
        var logpass = $scope.password;
        if($scope.userEmail=='admin'&&$scope.userPass == 'admin'){
            $location.path('/yalty');
        }else{
            alert("Hiba!");
        }
    };
});*/
"use strict";
angular.module('Authentication', [])
 
.controller('LoginController',
    ['$scope', '$rootScope', '$location', 'AuthenticationService',
    function ($scope, $rootScope, $location, AuthenticationService) {
        // reset login status
        AuthenticationService.ClearCredentials();
        $scope.slogan = "Digital Customer Engagement";
 
        $scope.login = function () {
            $scope.dataLoading = true;
            AuthenticationService.Login($scope.username, $scope.password, function(response) {
                if(response.success) {
                    AuthenticationService.SetCredentials($scope.username, $scope.password);
                    $location.path('/home');
                } else {
                    $scope.error = response.message;
                    $scope.dataLoading = false;
                }
            });
        };
    }]);