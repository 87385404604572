import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-partner-email-template',
    templateUrl: './partner-email-template.component.html',
    styleUrls: ['./partner-email-template.component.scss']
})
export class PartnerEmailTemplateComponent implements OnInit {

    @Input() emailBody: string;
    @Input() type: 'noreply' | 'customerServiceGeneral';
    actualYear = new Date().getFullYear();

    constructor() { }

    ngOnInit(): void {
    }

}
