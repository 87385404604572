import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, ControlContainer, FormGroup } from '@angular/forms';
import { CustomErrorStateMatcher } from 'src/app/config/error-state-matcher';
import { FormFieldValidatorService } from "src/app/shared/services/form-field-validator.service";

@Component({
    selector: 'app-input-text',
    templateUrl: './input-text.component.html',
    styleUrls: ['./input-text.component.scss']
})
export class InputFieldComponent implements OnInit {

    form: FormGroup;
    @Input() inputName: string;
    @Input() label: string;
    @Input() placeholder: string;
    @Input() maxLength: number;
    matcher = new CustomErrorStateMatcher();
    @Output() blurEvent = new EventEmitter<FocusEvent>();

    constructor(
        private formFieldValidatorService: FormFieldValidatorService,
        private controlContainer: ControlContainer
    ) { }

    ngOnInit() {
        this.form = <FormGroup>this.controlContainer.control;
    }

    emitBlurEvent($event: FocusEvent) {
        this.blurEvent.emit($event);
    }

    get required(): boolean { return this.formFieldValidatorService.isRequired(this.input); }

    get input(): AbstractControl { return this.form.get(this.inputName)!; }

    get value(): string {
        return this.input.value ?
            this.input.value : ''
    }

}
