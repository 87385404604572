<mat-form-field [formGroup]="form" appearance="standard">
    <mat-label>{{label}}</mat-label>
    <textarea matInput cdkTextareaAutosize [formControlName]="inputName" [required]="required"
        [errorStateMatcher]="matcher" [ngClass]="{'invalid': input.invalid }" (blur)="emitBlurEvent($event)"></textarea>
    <mat-hint align="end" *ngIf="maxLength">{{value.length}} / {{maxLength}}</mat-hint>
    <mat-hint align="end" *ngIf="!maxLength">{{value.length}}</mat-hint>
    <mat-error *ngIf="input.hasError('required')">
        <div>Mező kitöltése kötelező!</div>
        <div class="char-counter-error">{{value.length}} / {{maxLength}}</div>
    </mat-error>
    <mat-error *ngIf="input.hasError('maxlength')">
        <div>A mező maximálisan {{maxLength}} karakter hosszúságú lehet!</div>
        <div class="char-counter-error">{{value.length}} / {{maxLength}}</div>
    </mat-error>
    <mat-error *ngIf="input.hasError('pattern')">
        <div>{{patternErrorMsg}}</div>
        <div *ngIf="maxLength" class="char-counter-error">{{value.length}} / {{maxLength}}</div>
    </mat-error>
</mat-form-field>