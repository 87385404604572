<mat-form-field [formGroup]="form" appearance="standard">
    <mat-label>{{label}}</mat-label>
    <input type="text" [placeholder]="placeholder" aria-label="label" matInput [formControlName]="inputName"
        [required]="required" [matAutocomplete]="auto" [errorStateMatcher]="matcher" (blur)="emitBlurEvent($event)">
    <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
            {{option}}
        </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="input.hasError('required')">Mező kitöltése kötelező!</mat-error>
    <mat-error *ngIf="input.hasError('notSelected')">{{input.errors?.message}}</mat-error>
    <mat-error *ngIf="input.hasError('customError')">{{input.errors?.message}}</mat-error>
</mat-form-field>