import './MainCtrl';
import './accountDeletionRequestCtrl';
import './activationCtrl';
import './adminPageController';
import './appshareCtrl';
import './companyStoresCtrl';
import './constants';
import './controller';
import './huseg';
import './loyaltyCards';
import './kupon';
import './login';
import './loyaltyCardRegistration68Ctrl';
import './loyaltyCardRegistration69Ctrl';
import './loyaltyCardRegistration70Ctrl';
import './loyaltyCardRegistration71Ctrl';
import './loyaltyCardRegistration72Ctrl';
import './loyaltyCardYaltyRedeemsHistoryController';
import './loyaltyCardYaltyStampsAndRedeemsController';
import './partnerPushNotificationsController';
import './marketingPackages';
import './partnerModHistoryController';
import './redeemCtrl';
import './services';
import './subscriptionController';
import './userAccountsCtrl';