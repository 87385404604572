<mat-form-field [formGroup]="form" appearance="standard" class="languageSelector">
    <mat-label>Üzenet nyelve</mat-label>
    <mat-select formControlName="languageSelector">
        <mat-select-trigger>
            <img width="40" [src]="selectedImgURL">{{selectedLanguageText}}
        </mat-select-trigger>
        <mat-option *ngFor="let lang of languages" [value]="lang.value">
            <img width="40" [src]="lang.imgURL">{{lang.display}}
        </mat-option>
    </mat-select>
</mat-form-field>