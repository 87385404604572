// @ts-nocheck

angular.module('yaltyApp')
    .filter('unique', function () {

        return function (items, filterOn) {

            if (filterOn === false) {
                return items;
            }

            if ((filterOn || angular.isUndefined(filterOn)) && angular.isArray(items)) {
                var hashCheck = {}, newItems = [];

                var extractValueToCompare = function (item) {
                    if (angular.isObject(item) && angular.isString(filterOn)) {
                        return item[filterOn];
                    } else {
                        return item;
                    }
                };

                angular.forEach(items, function (item) {
                    var valueToCheck, isDuplicate = false;

                    for (var i = 0; i < newItems.length; i++) {
                        if (angular.equals(extractValueToCompare(newItems[i]), extractValueToCompare(item))) {
                            isDuplicate = true;
                            break;
                        }
                    }
                    if (!isDuplicate) {
                        newItems.push(item);
                    }

                });
                items = newItems;
            }
            return items;
        };
    })
    .controller('redeemCtrl', function ($scope, $http, $cookies, $filter, $window, $state, $mdDialog, $document, API_PREFIX, RedeemService, AuthService, $rootScope, $mdToast, $timeout) {
        $scope.adminUserType = $cookies.get('adminUserType');
        $scope.current = {};
        $scope.current.status = "";
        $scope.current.isConnected = "";
        $scope.currentStateName = $state.current.name;
        $scope.areCustomDisplaySettingsForRedeemHandlingSheetEnabled = $cookies.get('areCustomDisplaySettingsForRedeemHandlingSheetEnabled')
        $scope.isReconnectionWaitingBorderOn = false;
        $scope.reconnectionTimeout = null;
        
        document.addEventListener("visibilitychange", () => {
            if (!document.hidden) {
                if($scope.current.statusCode != 2 && $scope.current.statusCode != 4){
                    $scope.isReconnectionWaitingBorderOn = true;
                    if ($scope.reconnectionTimeout) {// Korábbi időzítő törlése
                        $timeout.cancel($scope.reconnectionTimeout); 
                    }
                    
                    $scope.reconnectionTimeout = $timeout(() => {
                        $scope.isReconnectionWaitingBorderOn = false;
                    }, 4100);
                }
            }
        });

        $scope.innerWidth = window.innerWidth;

        function reportWindowSize() {
            $scope.innerWidth = window.innerWidth;
        }

        window.addEventListener("resize", reportWindowSize);

        window.addEventListener('orientationchange', reportWindowSize);

        if ((navigator.userAgent.match(/iPhone/i)) || (navigator.userAgent.match(/iPod/i))) {
            $('.ap-otp-input').css('width', "19px");
            $('.ap-otp-input').css('height', "21px");
            $('.ap-otp-input').css('border-radius', "8px");
            $('.ap-otp-input').css('font-size', "15px");
            $('.ap-otp-input').css('margin-left', "1px");
            $('.ap-otp-input').css('margin-right', "1px");
            $('.ap-otp-input').css('margin-top', "4px");
            $('.ap-otp-input').css('margin-bottom', "4px");
            $('#publicUserId3').css('margin-right', "8px");
        }
        if ($scope.currentStateName == 'cashRegisterCashing') {
            var loggedOutFromPartnerPage = function (reason = "cashRegisterLogout") {
                AuthService.logout(reason);
            }
            function timerFunction() {
                var current = Date.parse(new Date());
                if (current >= $rootScope.endDate) {
                    loggedOutFromPartnerPage("cashRegisterTimeout");
                    clearInterval(timer);
                    return;
                }
                if ($rootScope.endDate != $cookies.get('expdt')) {
                    if (isNaN(parseInt($cookies.get('expdt')))) {
                        loggedOutFromPartnerPage("cashRegisterLogout");
                        clearInterval(timer);
                        return;
                    } else {
                        $rootScope.endDate = parseInt($cookies.get('expdt'));
                        $rootScope.deadlineInMilli = parseInt($cookies.get('alrtdt'));
                    }
                }
            }
            var timer = setInterval(timerFunction, 1000);
        }
        $scope.logout = function () {
            clearInterval(timer);
            AuthService.logout('cashRegisterLogout');
        };
        $scope.pageReload = function () {
            location.reload();
        };
        $scope.redeemRequests = [];
        if ($cookies.get('redeemPageCompanyStoreFilter') != null) {
            $scope.companyStoreFilter = JSON.parse($cookies.get('redeemPageCompanyStoreFilter'));
        } else if ($cookies.get('defaultRedeemPageCompanyStoreFilter') != null && $cookies.get('defaultRedeemPageCompanyStoreFilter') != 'all') {
            $scope.companyStoreFilter = JSON.parse($cookies.get('defaultRedeemPageCompanyStoreFilter'));
        } else {
            $scope.companyStoreFilter = [];
        }
        if ($cookies.get('redeemPageCityFilter') != null) {
            $scope.cityFilter = JSON.parse($cookies.get('redeemPageCityFilter'));
        } else if ($cookies.get('defaultRedeemPageCityFilter') != null && $cookies.get('defaultRedeemPageCityFilter') != 'all') {
            $scope.cityFilter = JSON.parse($cookies.get('defaultRedeemPageCityFilter'));
        } else {
            $scope.cityFilter = [];
        }
        $scope.loyaltycardRequestsOriginal = [];
        $scope.allLoyaltyCardYaltyAndCompanyStoreData = [];
        $scope.badgeNr = 0;
        $scope.areRedeemPageFilterSettingsPredefined = $cookies.get('areRedeemPageFilterSettingsPredefined') != null ? $cookies.get('areRedeemPageFilterSettingsPredefined') === "true" : false;
        if ($cookies.get('redeemPageShowFilter') != null) {
            $scope.showFilter = $cookies.get('redeemPageShowFilter') === "true";
        } else if ($cookies.get('defaultRedeemPageShowFilter') != null) {
            $scope.showFilter = $cookies.get('defaultRedeemPageShowFilter') === "true";
        } else {
            $scope.showFilter = false;
        }
        $scope.cashRegisterDataForFiltering = [];
        $scope.loyaltyCardPromotions = undefined;
        $scope.yaltyCity = undefined;
        $scope.companyStores = undefined;
        $scope.companyStoreCashRegister = undefined;
        if ($cookies.get('redeemPageCompanyStoreFilterCheckAll') != null) {
            $scope.checkAllStore = $cookies.get('redeemPageCompanyStoreFilterCheckAll') === "true";
        } else if ($cookies.get('defaultRedeemPageCompanyStoreFilter') != null) {
            $scope.checkAllStore = $cookies.get('defaultRedeemPageCompanyStoreFilter') === "all";
        } else {
            $scope.checkAllStore = true;
        }
        if ($cookies.get('redeemPageCityFilterCheckAll') != null) {
            $scope.checkAllCity = $cookies.get('redeemPageCityFilterCheckAll') === "true";
        } else if ($cookies.get('defaultRedeemPageCityFilter') != null) {
            $scope.checkAllCity = $cookies.get('defaultRedeemPageCityFilter') === "all";
        } else {
            $scope.checkAllCity = true;
        }
        if ($cookies.get('redeemPageCompanyStoreCashRegisterFilter') != null) {
            $scope.companyStoreCashRegisterFilter = parseInt($cookies.get('redeemPageCompanyStoreCashRegisterFilter'));
        } else if ($cookies.get('defaultRedeemPageCompanyStoreCashRegisterFilter') != null && $cookies.get('defaultRedeemPageCompanyStoreCashRegisterFilter') != 'null') {
            $scope.companyStoreCashRegisterFilter = parseInt($cookies.get('defaultRedeemPageCompanyStoreCashRegisterFilter'));
        } else {
            $scope.companyStoreCashRegisterFilter = 0;
        }
        $scope.dateNow = new Date();
        $scope.tabID = window.sessionStorage.tabID ? window.sessionStorage.tabID : window.sessionStorage.tabID = Math.random();
        const emptyMark = {
            "color": "rgb(166,166,166)",
            "border-width": "0",
            "outline": "none",
            "font-style": "italic",
            "font-size": "90%",
            "padding": "0 8px"
        }
        const modifiedMark = {
            "color": "#00AEEF",
            "border-width": "0",
            "outline": "none",
            "font-style": "normal",
            "font-size": "90%",
            "padding": "0 8px"
        }
        const unmodifiedMark = {
            "color": "#4E525C",
            "border-width": "0",
            "outline": "none",
            "font-style": "normal",
            "font-size": "90%",
            "padding": "0 8px"
        }

        setInterval(update, 1000);
        function update() {
            $scope.$apply($scope.dateNow = new Date());
            updateBadgeNr();
        }

        function updateBadgeNr() {
            let badgeNr = 0;
            let activeRedeemRequests = [];
            for (let i = 0; i < $scope.redeemRequests.length; i++) {
                if ($scope.redeemRequests[i].status == 'ÚJ') {
                    activeRedeemRequests.push($scope.redeemRequests[i]);
                    badgeNr++;
                }
            }
            let filtered = $filter('filter')(activeRedeemRequests, $scope.filterByStores);
            let filtered2 = $filter('filter')(filtered, $scope.filterByCashRegisters);
            $scope.badgeNr = (badgeNr - filtered2.length);
        }

        $scope.updateShowFilter = function () {
            $scope.showFilter = !$scope.showFilter;
            $cookies.put('redeemPageShowFilter', $scope.showFilter);
        }

        //Később, ha egy kattintással resetelni szeretnénk a szúrőket:
        /*$scope.resetFilters = function () {
            $scope.badgeNr = 0;
            $scope.checkAllCity = true;
            $scope.setAllCities();
            $scope.checkAllStore = true;
            $scope.setAllCompStores();
        }*/

        $scope.isRewardReachedMaxNr = function (rewardNr, redeem) {
            let isReached = false;
            if (redeem.redeemData.loyaltyCard.nrOfRedeemableRewardsAtOnce > 0 && (redeem.redeemData.loyaltyCard.nrOfReward1Redeems + redeem.redeemData.loyaltyCard.nrOfReward2Redeems + redeem.redeemData.loyaltyCard.nrOfReward3Redeems + redeem.redeemData.loyaltyCard.nrOfReward4Redeems + redeem.redeemData.loyaltyCard.nrOfReward5Redeems) == redeem.redeemData.loyaltyCard.nrOfRedeemableRewardsAtOnce) {
                isReached = true;
            }
            if (redeem.redeemData.loyaltyCard.nrOfRedeemableRewardsPerRewardTypes > 0) {
                if (rewardNr == 1 && redeem.redeemData.loyaltyCard.nrOfRedeemableRewardsPerRewardTypes == redeem.redeemData.loyaltyCard.nrOfReward1Redeems) {
                    isReached = true;
                } else if (rewardNr == 2 && redeem.redeemData.loyaltyCard.nrOfRedeemableRewardsPerRewardTypes == redeem.redeemData.loyaltyCard.nrOfReward2Redeems) {
                    isReached = true;
                } else if (rewardNr == 3 && redeem.redeemData.loyaltyCard.nrOfRedeemableRewardsPerRewardTypes == redeem.redeemData.loyaltyCard.nrOfReward3Redeems) {
                    isReached = true;
                } else if (rewardNr == 4 && redeem.redeemData.loyaltyCard.nrOfRedeemableRewardsPerRewardTypes == redeem.redeemData.loyaltyCard.nrOfReward4Redeems) {
                    isReached = true;
                } else if (rewardNr == 5 && redeem.redeemData.loyaltyCard.nrOfRedeemableRewardsPerRewardTypes == redeem.redeemData.loyaltyCard.nrOfReward5Redeems) {
                    isReached = true;
                }
            }
            if (isReached == true) {
                return true;
            } else if (rewardNr == 1) {
                let maxRewardNr = redeem.redeemData.loyaltyCard.nrOfCollectedReward1;
                if (redeem.redeemData.loyaltyCard.mustBeRedeemedLater == false) {
                    maxRewardNr += redeem.redeemData.loyaltyCard.nrOfReward1CollectedNow;
                }
                if (redeem.redeemData.loyaltyCard.nrOfReward1Redeems == maxRewardNr) {
                    return true;
                } else {
                    return false;
                }
            } else if (rewardNr == 2) {
                let maxRewardNr = redeem.redeemData.loyaltyCard.nrOfCollectedReward2;
                if (redeem.redeemData.loyaltyCard.mustBeRedeemedLater == false) {
                    maxRewardNr += redeem.redeemData.loyaltyCard.nrOfReward2CollectedNow;
                }
                if (redeem.redeemData.loyaltyCard.nrOfReward2Redeems == maxRewardNr) {
                    return true;
                } else {
                    return false;
                }
            } else if (rewardNr == 3) {
                let maxRewardNr = redeem.redeemData.loyaltyCard.nrOfCollectedReward3;
                if (redeem.redeemData.loyaltyCard.mustBeRedeemedLater == false) {
                    maxRewardNr += redeem.redeemData.loyaltyCard.nrOfReward3CollectedNow;
                }
                if (redeem.redeemData.loyaltyCard.nrOfReward3Redeems == maxRewardNr) {
                    return true;
                } else {
                    return false;
                }
            } else if (rewardNr == 4) {
                let maxRewardNr = redeem.redeemData.loyaltyCard.nrOfCollectedReward4;
                if (redeem.redeemData.loyaltyCard.mustBeRedeemedLater == false) {
                    maxRewardNr += redeem.redeemData.loyaltyCard.nrOfReward4CollectedNow;
                }
                if (redeem.redeemData.loyaltyCard.nrOfReward4Redeems == maxRewardNr) {
                    return true;
                } else {
                    return false;
                }
            } else if (rewardNr == 5) {
                let maxRewardNr = redeem.redeemData.loyaltyCard.nrOfCollectedReward5;
                if (redeem.redeemData.loyaltyCard.mustBeRedeemedLater == false) {
                    maxRewardNr += redeem.redeemData.loyaltyCard.nrOfReward5CollectedNow;
                }
                if (redeem.redeemData.loyaltyCard.nrOfReward5Redeems == maxRewardNr) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }

        $scope.setRedeemMarkFocus = function (status) {
            if (status == "ÚJ") {
                angular.element(document.querySelector('#partnerRedeemRemark')).focus();
            }
        }

        $scope.redeemMarkModified = function (loyaltyCard) {
            loyaltyCard.partnerRedeemRemarkStyle = modifiedMark;
            if (!loyaltyCard.YLCPartnerRedeemRemark) {
                loyaltyCard.partnerRedeemRemarkStyle = emptyMark;
            }
        }

        $scope.rewardChecked = function (redeem, rewardNr) {
            let nrOfRequestedRewards = 0;
            if (redeem.redeemData.loyaltyCard.reward1RedeemRequested) {
                nrOfRequestedRewards++;
            }
            if (redeem.redeemData.loyaltyCard.reward2RedeemRequested) {
                nrOfRequestedRewards++;
            }
            if (redeem.redeemData.loyaltyCard.reward3RedeemRequested) {
                nrOfRequestedRewards++;
            }
            if (redeem.redeemData.loyaltyCard.reward4RedeemRequested) {
                nrOfRequestedRewards++;
            }
            if (redeem.redeemData.loyaltyCard.reward5RedeemRequested) {
                nrOfRequestedRewards++;
            }
            if (nrOfRequestedRewards <= redeem.redeemData.loyaltyCard.nrOfRedeemableRewardsAtOnce || redeem.redeemData.loyaltyCard.nrOfRedeemableRewardsAtOnce == 0) {
                redeem.redeemData.loyaltyCard.getReward = nrOfRequestedRewards;
            } else {
                if (rewardNr == 1) {
                    redeem.redeemData.loyaltyCard.reward1RedeemRequested = false;
                }
                if (rewardNr == 2) {
                    redeem.redeemData.loyaltyCard.reward2RedeemRequested = false;
                }
                if (rewardNr == 3) {
                    redeem.redeemData.loyaltyCard.reward3RedeemRequested = false;
                }
                if (rewardNr == 4) {
                    redeem.redeemData.loyaltyCard.reward4RedeemRequested = false;
                }
                if (rewardNr == 5) {
                    redeem.redeemData.loyaltyCard.reward5RedeemRequested = false;
                }
                redeem.redeemData.loyaltyCard.getReward = redeem.redeemData.loyaltyCard.nrOfRedeemableRewardsAtOnce;
            }
            redeem.redeemData.loyaltyCard.newLaterRewards = redeem.redeemData.loyaltyCard.nrOfReward1CollectedNow + redeem.redeemData.loyaltyCard.nrOfReward2CollectedNow + redeem.redeemData.loyaltyCard.nrOfReward3CollectedNow +
                redeem.redeemData.loyaltyCard.nrOfReward4CollectedNow + redeem.redeemData.loyaltyCard.nrOfReward5CollectedNow
            if (redeem.redeemData.loyaltyCard.mustBeRedeemedLater == false) {
                if (redeem.redeemData.loyaltyCard.reward1RedeemRequested == true && redeem.redeemData.loyaltyCard.nrOfCollectedReward1 == 0) {
                    redeem.redeemData.loyaltyCard.newLaterRewards--;
                }
                if (redeem.redeemData.loyaltyCard.reward2RedeemRequested == true && redeem.redeemData.loyaltyCard.nrOfCollectedReward2 == 0) {
                    redeem.redeemData.loyaltyCard.newLaterRewards--;
                }
                if (redeem.redeemData.loyaltyCard.reward3RedeemRequested == true && redeem.redeemData.loyaltyCard.nrOfCollectedReward3 == 0) {
                    redeem.redeemData.loyaltyCard.newLaterRewards--;
                }
                if (redeem.redeemData.loyaltyCard.reward4RedeemRequested == true && redeem.redeemData.loyaltyCard.nrOfCollectedReward4 == 0) {
                    redeem.redeemData.loyaltyCard.newLaterRewards--;
                }
                if (redeem.redeemData.loyaltyCard.reward5RedeemRequested == true && redeem.redeemData.loyaltyCard.nrOfCollectedReward5 == 0) {
                    redeem.redeemData.loyaltyCard.newLaterRewards--;
                }
            }
            if (redeem.redeemData.loyaltyCard.isYLCSelectableProductsEnabled == true) {
                if (rewardNr == 1 && redeem.redeemData.loyaltyCard.reward1RedeemRequested == true) {
                    $scope.YLCSelectedProductRedeem = redeem;
                    $scope.YLCSelectableProducts = JSON.parse(redeem.redeemData.loyaltyCard.YLCSelectableProducts);
                    $scope.lastActionForYLCSelectableProducts = "redemption";
                    $scope.lastActionRewardNrForYLCSelectableProducts = 1;
                    $scope.YLCSelectedProductIndex = null;
                    $scope.showYLCSelectedProductWindow = true;
                } else if (rewardNr == 2 && redeem.redeemData.loyaltyCard.reward2RedeemRequested == true) {
                    $scope.YLCSelectedProductRedeem = redeem;
                    $scope.YLCSelectableProducts = JSON.parse(redeem.redeemData.loyaltyCard.YLCSelectableProducts);
                    $scope.lastActionForYLCSelectableProducts = "redemption";
                    $scope.lastActionRewardNrForYLCSelectableProducts = 2;
                    $scope.YLCSelectedProductIndex = null;
                    $scope.showYLCSelectedProductWindow = true;
                } else if (rewardNr == 3 && redeem.redeemData.loyaltyCard.reward3RedeemRequested == true) {
                    $scope.YLCSelectedProductRedeem = redeem;
                    $scope.YLCSelectableProducts = JSON.parse(redeem.redeemData.loyaltyCard.YLCSelectableProducts);
                    $scope.lastActionForYLCSelectableProducts = "redemption";
                    $scope.lastActionRewardNrForYLCSelectableProducts = 3;
                    $scope.YLCSelectedProductIndex = null;
                    $scope.showYLCSelectedProductWindow = true;
                } else if (rewardNr == 4 && redeem.redeemData.loyaltyCard.reward4RedeemRequested == true) {
                    $scope.YLCSelectedProductRedeem = redeem;
                    $scope.YLCSelectableProducts = JSON.parse(redeem.redeemData.loyaltyCard.YLCSelectableProducts);
                    $scope.lastActionForYLCSelectableProducts = "redemption";
                    $scope.lastActionRewardNrForYLCSelectableProducts = 4;
                    $scope.YLCSelectedProductIndex = null;
                    $scope.showYLCSelectedProductWindow = true;
                } else if (rewardNr == 5 && redeem.redeemData.loyaltyCard.reward5RedeemRequested == true) {
                    $scope.YLCSelectedProductRedeem = redeem;
                    $scope.YLCSelectableProducts = JSON.parse(redeem.redeemData.loyaltyCard.YLCSelectableProducts);
                    $scope.lastActionForYLCSelectableProducts = "redemption";
                    $scope.lastActionRewardNrForYLCSelectableProducts = 5;
                    $scope.YLCSelectedProductIndex = null;
                    $scope.showYLCSelectedProductWindow = true;
                } else if (rewardNr == 1 && redeem.redeemData.loyaltyCard.reward1RedeemRequested == false) {
                    if (redeem.redeemData.loyaltyCard.YLCSelectedProductsForReward1Redemptions.length > 0) {
                        redeem.redeemData.loyaltyCard.YLCSelectedProductsForReward1Redemptions.pop()
                        /*console.log(redeem.redeemData.loyaltyCard.YLCSelectedProductsForStampings)
                        console.log($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.YLCSelectedProductsForReward1Redemptions)
                        console.log($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.YLCSelectedProductsForReward2Redemptions)
                        console.log($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.YLCSelectedProductsForReward3Redemptions)
                        console.log($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.YLCSelectedProductsForReward4Redemptions)
                        console.log($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.YLCSelectedProductsForReward5Redemptions)*/
                    }
                } else if (rewardNr == 2 && redeem.redeemData.loyaltyCard.reward2RedeemRequested == false) {
                    if (redeem.redeemData.loyaltyCard.YLCSelectedProductsForReward2Redemptions.length > 0) {
                        redeem.redeemData.loyaltyCard.YLCSelectedProductsForReward2Redemptions.pop()
                        /*console.log(redeem.redeemData.loyaltyCard.YLCSelectedProductsForStampings)
                        console.log($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.YLCSelectedProductsForReward1Redemptions)
                        console.log($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.YLCSelectedProductsForReward2Redemptions)
                        console.log($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.YLCSelectedProductsForReward3Redemptions)
                        console.log($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.YLCSelectedProductsForReward4Redemptions)
                        console.log($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.YLCSelectedProductsForReward5Redemptions)*/
                    }
                } else if (rewardNr == 3 && redeem.redeemData.loyaltyCard.reward3RedeemRequested == false) {
                    if (redeem.redeemData.loyaltyCard.YLCSelectedProductsForReward3Redemptions.length > 0) {
                        redeem.redeemData.loyaltyCard.YLCSelectedProductsForReward3Redemptions.pop()
                        /*console.log(redeem.redeemData.loyaltyCard.YLCSelectedProductsForStampings)
                        console.log($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.YLCSelectedProductsForReward1Redemptions)
                        console.log($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.YLCSelectedProductsForReward2Redemptions)
                        console.log($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.YLCSelectedProductsForReward3Redemptions)
                        console.log($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.YLCSelectedProductsForReward4Redemptions)
                        console.log($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.YLCSelectedProductsForReward5Redemptions)*/
                    }
                } else if (rewardNr == 4 && redeem.redeemData.loyaltyCard.reward4RedeemRequested == false) {
                    if (redeem.redeemData.loyaltyCard.YLCSelectedProductsForReward4Redemptions.length > 0) {
                        redeem.redeemData.loyaltyCard.YLCSelectedProductsForReward4Redemptions.pop()
                        /*console.log(redeem.redeemData.loyaltyCard.YLCSelectedProductsForStampings)
                        console.log($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.YLCSelectedProductsForReward1Redemptions)
                        console.log($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.YLCSelectedProductsForReward2Redemptions)
                        console.log($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.YLCSelectedProductsForReward3Redemptions)
                        console.log($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.YLCSelectedProductsForReward4Redemptions)
                        console.log($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.YLCSelectedProductsForReward5Redemptions)*/
                    }
                } else if (rewardNr == 5 && redeem.redeemData.loyaltyCard.reward5RedeemRequested == false) {
                    if (redeem.redeemData.loyaltyCard.YLCSelectedProductsForReward5Redemptions.length > 0) {
                        redeem.redeemData.loyaltyCard.YLCSelectedProductsForReward5Redemptions.pop()
                        /*console.log(redeem.redeemData.loyaltyCard.YLCSelectedProductsForStampings)
                        console.log($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.YLCSelectedProductsForReward1Redemptions)
                        console.log($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.YLCSelectedProductsForReward2Redemptions)
                        console.log($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.YLCSelectedProductsForReward3Redemptions)
                        console.log($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.YLCSelectedProductsForReward4Redemptions)
                        console.log($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.YLCSelectedProductsForReward5Redemptions)*/
                    }
                }
            }
        }

        $scope.getSelectedText = function (cityOrStore) {
            if (cityOrStore == 'city') {
                return $scope.checkAllCity == true || $scope.cityFilter == undefined || $scope.cityFilter.length == 0 ? "Minden város" : () => {
                    let selectedText = "";
                    if ($scope.cityFilter) {
                        let iterations = $scope.cityFilter.length;
                        if (iterations > 1) {
                            for (let data of $scope.cityFilter) {
                                $filter('unique')($scope.dataForFiltering, 'compStoreAddrCity').filter((x) => {
                                    if (x.compStoreAddrCity == data) {
                                        if (--iterations <= 0) {
                                            selectedText += (x.compStoreAddrCity)
                                        } else {
                                            selectedText += (x.compStoreAddrCity + ", ")
                                        }
                                    }
                                })
                            }
                            if ($scope.cityFilter.includes(null)) {
                                selectedText += "Online vagy telefonos rendelések (Nincs város)"
                            }
                        } else {
                            if ($scope.cityFilter[0] == null) {
                                selectedText += "Online vagy telefonos rendelések (Nincs város)"
                            } else {
                                selectedText = $scope.cityFilter[0]
                            }
                        }
                    }
                    return selectedText != "" ? selectedText : undefined;
                }
            } else {
                return $scope.checkAllStore == true || $scope.companyStoreFilter == undefined || $scope.companyStoreFilter.length == 0 ? "Minden üzlet" : () => {
                    let selectedText = "";
                    if ($scope.companyStoreFilter) {
                        let iterations = $scope.companyStoreFilter.length;
                        for (let data of $scope.companyStoreFilter) {
                            $filter('unique')($scope.dataForFiltering, 'compStoreName').filter((x) => {
                                if (x.companyStoreId == data) {
                                    if (--iterations <= 0) {
                                        selectedText += (x.compStoreName)
                                    } else {
                                        selectedText += (x.compStoreName + ", ")
                                    }
                                } else if (data == null) {
                                    selectedText += "Online vagy telefonos rendelések (Nincs üzlet)"
                                }
                            })
                        }
                    }
                    return selectedText != "" ? selectedText : undefined;
                }

            }
        }

        $scope.filterByStores = function (redeem) {
            if ($scope.companyStoreFilter) {
                if ($scope.companyStoreFilter.length > 0) {
                    return ($scope.companyStoreFilter.indexOf(
                        redeem.redeemData.other.companyStoreId ?
                            parseInt(redeem.redeemData.other.companyStoreId) : null) !== -1);
                } else {
                    return true;
                }
            }
        };


        $scope.filterByCashRegisters = function (redeem) {
            if ($scope.companyStoreCashRegisterFilter) {
                if ($scope.companyStoreCashRegisterFilter != 0) {
                    return $scope.companyStoreCashRegisterFilter ==
                        (redeem.redeemData.other.companyStoreCashRegisterId ?
                            parseInt(redeem.redeemData.other.companyStoreCashRegisterId) : null);
                } else {
                    return true;
                }
            } else {
                return true;
            }
        };

        $scope.storeFilterByCites = function (dataForFiltering) {
            if ($scope.cityFilter) {
                if ($scope.cityFilter.length > 0) {
                    return ($scope.cityFilter.indexOf(dataForFiltering.compStoreAddrCity) !== -1);
                } else {
                    return true;
                }
            } else {
                return true;
            }
        };
        $scope.cityFilterByStores = function (dataForFiltering) {
            if ($scope.companyStoreFilter) {
                if ($scope.companyStoreFilter.length > 0) {
                    return ($scope.companyStoreFilter.indexOf(dataForFiltering.companyStoreId) !== -1);
                } else {
                    return true;
                }
            } else {
                return true;
            }
        };
        $scope.setAllCompStores = function (isInitial) {
            $cookies.put('redeemPageCompanyStoreFilterCheckAll', $scope.checkAllStore)
            if ($scope.checkAllStore == true) {
                $scope.companyStoreFilter = []
                for (let store of $filter('unique')($scope.dataForFiltering, 'compStoreName')) {
                    $scope.companyStoreFilter.push(store.companyStoreId);
                }
                if ($scope.isPhoneOrOnlineOrderAvailableFilter) {
                    $scope.companyStoreFilter.push(null);
                }
                if (!isInitial) {
                    $scope.checkCompanyStoreAndCity(true)
                }
            } else {
                $scope.companyStoreFilter = []
            }
        }
        $scope.setAllCities = function (isInitial) {
            $cookies.put('redeemPageCityFilterCheckAll', $scope.checkAllCity)
            if ($scope.checkAllCity == true) {
                $scope.cityFilter = []
                for (let store of $filter('unique')($scope.dataForFiltering, 'compStoreAddrCity')) {
                    $scope.cityFilter.push(store.compStoreAddrCity);
                }
                if ($scope.isPhoneOrOnlineOrderAvailableFilter) {
                    $scope.cityFilter.push(null);
                }
                if (!isInitial) {
                    $scope.checkCompanyStoreAndCity(false)
                }
            } else {
                $scope.cityFilter = []
            }
        }

        $scope.checkCompanyStoreCashRegister = function () {
            $cookies.put('redeemPageCompanyStoreCashRegisterFilter', $scope.companyStoreCashRegisterFilter)
        }

        $scope.checkCompanyStoreAndCity = function (isCompanyStore) {
            if (isCompanyStore) {
                let filtered = $filter('filter')($scope.dataForFiltering, $scope.cityFilterByStores)
                let uniqueFiltered = $filter('unique')(filtered, 'compStoreAddrCity')
                $scope.cityFilter = []
                for (let store of uniqueFiltered) {
                    $scope.cityFilter.push(store.compStoreAddrCity)
                }
                if ($scope.isPhoneOrOnlineOrderAvailableFilter == true && $scope.companyStoreFilter) {
                    let index = $scope.companyStoreFilter.indexOf(null)
                    if (index != -1) {
                        $scope.cityFilter.push(null);
                    } else {
                        index = $scope.cityFilter.indexOf(null);
                        if (index != -1) {
                            delete $scope.cityFilter[index];
                        }
                    }
                }
            } else {
                let filtered = $filter('filter')($scope.dataForFiltering, $scope.storeFilterByCites)
                let uniqueFiltered = $filter('unique')(filtered, 'compStoreName')
                $scope.companyStoreFilter = []
                for (let store of uniqueFiltered) {
                    $scope.companyStoreFilter.push(store.companyStoreId)
                }
                if ($scope.isPhoneOrOnlineOrderAvailableFilter == true && $scope.cityFilter) {
                    let index = $scope.cityFilter.indexOf(null);
                    if (index != -1) {
                        $scope.companyStoreFilter.push(null);
                    } else {
                        index = $scope.companyStoreFilter.indexOf(null);
                        if (index != -1) {
                            delete $scope.companyStoreFilter[index];
                        }
                    }
                }
            }
        }
        $scope.$watch('companyStoreFilter', function (current) {
            $cookies.put('redeemPageCompanyStoreFilter', JSON.stringify($scope.companyStoreFilter))
            if (current) {
                let length;
                if ($scope.isPhoneOrOnlineOrderAvailableFilter) {
                    length = $filter('unique')($scope.dataForFiltering, 'compStoreName').length + 1
                } else {
                    length = $filter('unique')($scope.dataForFiltering, 'compStoreName').length
                }
                if (current.length === length) {
                    $scope.checkAllStore = true;
                    $cookies.put('redeemPageCompanyStoreFilterCheckAll', true)
                } else {
                    $scope.checkAllStore = false;
                    $cookies.put('redeemPageCompanyStoreFilterCheckAll', false)
                }
            } else {
                $scope.checkAllStore = false;
                $cookies.put('redeemPageCompanyStoreFilterCheckAll', false)
            }
            if ($scope.companyStoreFilter.length == 1) {
                $scope.cashRegisterDataForFiltering = [];
                for (let i = 0; i < $scope.dataForFiltering.length; i++) {
                    if ($scope.companyStoreFilter.includes($scope.dataForFiltering[i].companyStoreId)) {
                        $scope.cashRegisterDataForFiltering = $scope.dataForFiltering[i].companyStoreCashRegisters;
                    }
                }
                if (($cookies.get('redeemPageCompanyStoreCashRegisterFilter') == null && ($cookies.get('defaultRedeemPageCompanyStoreCashRegisterFilter') == null || $cookies.get('defaultRedeemPageCompanyStoreCashRegisterFilter') == 'null')) || $scope.cashRegisterDataForFiltering.some(item => item.id === $scope.companyStoreCashRegisterFilter) == false) {
                    $scope.companyStoreCashRegisterFilter = 0;
                    $cookies.put('redeemPageCompanyStoreCashRegisterFilter', 0)
                }
            } else {
                $scope.companyStoreCashRegisterFilter = 0;
                $cookies.put('redeemPageCompanyStoreCashRegisterFilter', 0)
            }
        })
        $scope.$watch('cityFilter', function (current) {
            $cookies.put('redeemPageCityFilter', JSON.stringify($scope.cityFilter))
            if (current) {
                let length;
                if ($scope.isPhoneOrOnlineOrderAvailableFilter) {
                    length = $filter('unique')($scope.dataForFiltering, 'compStoreAddrCity').length + 1
                } else {
                    length = $filter('unique')($scope.dataForFiltering, 'compStoreAddrCity').length
                }
                if (current.length === length) {
                    $scope.checkAllCity = true;
                    $cookies.put('redeemPageCityFilterCheckAll', true)
                } else {
                    $scope.checkAllCity = false;
                    $cookies.put('redeemPageCityFilterCheckAll', false)
                }
            } else {
                $scope.checkAllCity = false;
                $cookies.put('redeemPageCityFilterCheckAll', false)
            }
        })

        $scope.currentPromotionIsOnline = function () {
            if ($scope.loyaltyCardPromotions) {
                let promotions = $filter('filter')($scope.allLoyaltyCardYaltyAndCompanyStoreData, { loyaltyCardYaltyId: $scope.loyaltyCardPromotions }, true)
                let isOnline = false;
                for (let promo of promotions) {
                    $scope.loyaltyCardPromotionName = promo.promotionName;
                    if (promo.isPhoneOrOnlineOrderAvailable == true) {
                        isOnline = true;
                    }
                }
                $scope.currentPromoIsOnline = isOnline;
            } else {
                $scope.currentPromoIsOnline = $scope.isPhoneOrOnlineOrderAvailable;
            }
            $scope.checkIsPhoneOrOnlineOrderAvailable('yaltyCity')
            $scope.checkIsPhoneOrOnlineOrderAvailable('companyStores')
        }

        $scope.getDataForPartnerInitiatedRedeems = function () {
            if (document.getElementById('transitionOverlay')) {
                document.getElementById('transitionOverlay').style.display = "block";
                document.getElementById('innerPartnerPageContainer').style.overflow = "hidden";
            }
            if ($scope.allLoyaltyCardYaltyAndCompanyStoreData.length == 0) {
                $http.get(API_PREFIX.url + "/partner/getLoyaltyCardYaltyAndCompanyStoreDataForPartnerInitiatedRedeems").then((result) => {
                    if (document.getElementById('transitionOverlay')) {
                        document.getElementById('transitionOverlay').style.display = "none";
                        document.getElementById('innerPartnerPageContainer').style.overflow = "unset";
                    }
                    $scope.showCreateRedeemWindow = true;
                    setTimeout(function () {
                        document.getElementById('publicUserId1').focus();
                    }, 200)
                    let loyaltyCardYaltyIdFiltered = $filter('unique')(result.data.allLoyaltyCardYaltyAndCompanyStoreData, 'loyaltyCardYaltyId')
                    let compStoreAddrCityFiltered = $filter('unique')(result.data.allLoyaltyCardYaltyAndCompanyStoreData, 'compStoreAddrCity')
                    let compStoreNameFiltered = $filter('unique')(result.data.allLoyaltyCardYaltyAndCompanyStoreData, 'compStoreName')
                    for (let data of result.data.allLoyaltyCardYaltyAndCompanyStoreData) {
                        data.isPhoneOrOnlineOrderAvailable == 1 ? $scope.isPhoneOrOnlineOrderAvailable = true : $scope.isPhoneOrOnlineOrderAvailable = false;
                    }

                    loyaltyCardYaltyIdFiltered.length == 1 ? $scope.loyaltyCardPromotions = loyaltyCardYaltyIdFiltered[0].loyaltyCardYaltyId : $scope.loyaltyCardPromotions = undefined;
                    compStoreAddrCityFiltered.length == 1 && $scope.isPhoneOrOnlineOrderAvailable == false ? $scope.yaltyCity = compStoreAddrCityFiltered[0].compStoreAddrCity : $scope.yaltyCity = undefined;
                    compStoreNameFiltered.length == 1 && $scope.isPhoneOrOnlineOrderAvailable == false ? $scope.companyStores = compStoreNameFiltered[0].compStoreName : $scope.companyStores = undefined;
                    $scope.companyStoreCashRegister = undefined;
                    $scope.allLoyaltyCardYaltyAndCompanyStoreData = result.data.allLoyaltyCardYaltyAndCompanyStoreData;
                    $scope.currentPromotionIsOnline()
                    if ($scope.cityFilter.length == 1 && $scope.cityFilter[0] != 'null' && $scope.cityFilter[0] != null && $scope.allLoyaltyCardYaltyAndCompanyStoreData != null && $scope.allLoyaltyCardYaltyAndCompanyStoreData.length > 0) {
                        $scope.yaltyCity = $scope.cityFilter[0];
                    }
                    if ($scope.companyStoreFilter.length == 1 && $scope.companyStoreFilter[0] != 'null' && $scope.companyStoreFilter[0] != null && $scope.allLoyaltyCardYaltyAndCompanyStoreData != null && $scope.allLoyaltyCardYaltyAndCompanyStoreData.length > 0) {
                        let item = compStoreNameFiltered.find(item => item.companyStoreId === $scope.companyStoreFilter[0]);
                        if (item != null) {
                            $scope.companyStores = item.compStoreName
                        }
                    }
                    if ($scope.companyStoreCashRegisterFilter != 0 && $scope.companyStoreCashRegisterFilter != null && $scope.allLoyaltyCardYaltyAndCompanyStoreData != null && $scope.allLoyaltyCardYaltyAndCompanyStoreData.length > 0) {
                        let item = $scope.cashRegisterDataForFiltering.find(item => item.id === $scope.companyStoreCashRegisterFilter);
                        if (item != null) {
                            $scope.companyStoreCashRegister = item.companyStoreCashRegisterName
                        }
                    }
                })
            } else {
                let loyaltyCardYaltyIdFiltered = $filter('unique')($scope.allLoyaltyCardYaltyAndCompanyStoreData, 'loyaltyCardYaltyId')
                let compStoreAddrCityFiltered = $filter('unique')($scope.allLoyaltyCardYaltyAndCompanyStoreData, 'compStoreAddrCity')
                let compStoreNameFiltered = $filter('unique')($scope.allLoyaltyCardYaltyAndCompanyStoreData, 'compStoreName')
                loyaltyCardYaltyIdFiltered.length == 1 ? $scope.loyaltyCardPromotions = loyaltyCardYaltyIdFiltered[0].loyaltyCardYaltyId : $scope.loyaltyCardPromotions = undefined;
                compStoreAddrCityFiltered.length == 1 && $scope.isPhoneOrOnlineOrderAvailable == false ? $scope.yaltyCity = compStoreAddrCityFiltered[0].compStoreAddrCity : $scope.yaltyCity = undefined;
                compStoreNameFiltered.length == 1 && $scope.isPhoneOrOnlineOrderAvailable == false ? $scope.companyStores = compStoreNameFiltered[0].compStoreName : $scope.companyStores = undefined;
                $scope.companyStoreCashRegister = undefined;
                if ($scope.cityFilter.length == 1 && $scope.cityFilter[0] != 'null' && $scope.cityFilter[0] != null && $scope.allLoyaltyCardYaltyAndCompanyStoreData != null && $scope.allLoyaltyCardYaltyAndCompanyStoreData.length > 0) {
                    $scope.yaltyCity = $scope.cityFilter[0];
                }
                if ($scope.companyStoreFilter.length == 1 && $scope.companyStoreFilter[0] != 'null' && $scope.companyStoreFilter[0] != null && $scope.allLoyaltyCardYaltyAndCompanyStoreData != null && $scope.allLoyaltyCardYaltyAndCompanyStoreData.length > 0) {
                    let item = compStoreNameFiltered.find(item => item.companyStoreId === $scope.companyStoreFilter[0]);
                    if (item != null) {
                        $scope.companyStores = item.compStoreName
                    }
                }
                if ($scope.companyStoreCashRegisterFilter != 0 && $scope.companyStoreCashRegisterFilter != null && $scope.allLoyaltyCardYaltyAndCompanyStoreData != null && $scope.allLoyaltyCardYaltyAndCompanyStoreData.length > 0) {
                    let item = $scope.cashRegisterDataForFiltering.find(item => item.id === $scope.companyStoreCashRegisterFilter);
                    if (item != null) {
                        $scope.companyStoreCashRegister = item.companyStoreCashRegisterName
                    }
                }
                $scope.showCreateRedeemWindow = true;
                setTimeout(function () {
                    document.getElementById('publicUserId1').focus();
                }, 200)
                if (document.getElementById('transitionOverlay')) {
                    document.getElementById('transitionOverlay').style.display = "none";
                    document.getElementById('innerPartnerPageContainer').style.overflow = "unset";
                }
            }
        }

        $scope.checkIsPhoneOrOnlineOrderAvailable = function (param) {
            if (param == 'yaltyCity') {
                let filtered = $filter('filter')($scope.allLoyaltyCardYaltyAndCompanyStoreData, { loyaltyCardYaltyId: $scope.loyaltyCardPromotions, compStoreAddrCity: $scope.yaltyCity }, true)
                let uniqueFiltered = $filter('unique')(filtered, 'compStoreName')
                if (uniqueFiltered.length == 1 && $scope.currentPromoIsOnline == false) {
                    $scope.companyStores = []
                    $scope.companyStores = uniqueFiltered[0].compStoreName
                } else if (uniqueFiltered.length == 0 && $scope.currentPromoIsOnline == true) {
                    $scope.companyStores = []
                    $scope.companyStores = 'null';
                }
                if ($scope.yaltyCity == undefined && $scope.currentPromoIsOnline == true) {
                    $scope.companyStores = undefined;
                    $scope.yaltyCity = undefined;
                } else if ($scope.yaltyCity == 'null' && $scope.currentPromoIsOnline == true) {
                    $scope.companyStores = 'null';
                    $scope.yaltyCity = 'null';
                }
            } else {
                let filtered = $filter('filter')($scope.allLoyaltyCardYaltyAndCompanyStoreData, { loyaltyCardYaltyId: $scope.loyaltyCardPromotions, compStoreName: $scope.companyStores }, true)
                let uniqueFiltered = $filter('unique')(filtered, 'compStoreAddrCity')
                if (uniqueFiltered.length == 1 && $scope.currentPromoIsOnline == false) {
                    $scope.yaltyCity = []
                    $scope.yaltyCity = uniqueFiltered[0].compStoreAddrCity
                } else if (uniqueFiltered.length == 0 && $scope.currentPromoIsOnline == true) {
                    $scope.yaltyCity = []
                    $scope.yaltyCity = 'null';
                }
                if ($scope.companyStores == undefined && $scope.currentPromoIsOnline == true) {
                    $scope.companyStores = undefined;
                    $scope.yaltyCity = undefined;
                } else if ($scope.companyStores == 'null' && $scope.currentPromoIsOnline == true) {
                    $scope.companyStores = 'null';
                    $scope.yaltyCity = 'null';
                }
            }
            let filtered = $filter('filter')($scope.allLoyaltyCardYaltyAndCompanyStoreData, { compStoreAddrCity: $scope.yaltyCity, compStoreName: $scope.companyStores }, true)
            let uniqueFiltered = $filter('unique')(filtered, 'loyaltyCardYaltyId')
            if (filtered.length == 1) {
                $scope.loyaltyCardPromotions = uniqueFiltered[0].loyaltyCardYaltyId;
                let promotions = $filter('filter')($scope.allLoyaltyCardYaltyAndCompanyStoreData, { loyaltyCardYaltyId: $scope.loyaltyCardPromotions }, true)
                let isOnline = false;
                for (let promo of promotions) {
                    $scope.loyaltyCardPromotionName = promo.promotionName;
                    if (promo.isPhoneOrOnlineOrderAvailable == true) {
                        isOnline = true;
                    }
                }
                $scope.currentPromoIsOnline = isOnline;
            }
        }

        $scope.cancelCreateRedeem = function (form) {
            if (form) {
                form.$setPristine();
                form.$setUntouched();
                publicUserId1.value = '';
                publicUserId2.value = '';
                publicUserId3.value = '';
                publicUserId4.value = '';
                publicUserId5.value = '';
                publicUserId6.value = '';
                form.loyaltyCardPromotions.$setPristine();
                form.loyaltyCardPromotions.$setUntouched();
                form.yaltyCity.$setPristine();
                form.yaltyCity.$setUntouched();
                form.companyStores.$setPristine();
                form.companyStores.$setUntouched();
            }
            $scope.loyaltyCardPromotions = undefined;
            $scope.yaltyCity = undefined;
            $scope.companyStores = undefined;
            $scope.publicUserId = undefined;
            $scope.showCreateRedeemWindow = false;
        }

        $scope.requestLoyaltyCardByPartner = function (form, forceRedeem, forcedLoyaltyCardYaltyId) {
            if (publicUserId1.value.length == 1 && publicUserId2.value.length == 1 && publicUserId3.value.length == 1 && publicUserId4.value.length == 1 && publicUserId5.value.length == 1 && publicUserId6.value.length == 1) {
                if ($scope.loyaltyCardPromotions && $scope.yaltyCity && $scope.companyStores) {
                    $scope.isCreateRedeemButtonDisabled = true;
                    let params = {};
                    let companyStoreId = $scope.allLoyaltyCardYaltyAndCompanyStoreData.find((x) => { if (x.compStoreName == $scope.companyStores) { return x.companyStoreId } })
                    let companyStoreCashRegisterId = $scope.allLoyaltyCardYaltyAndCompanyStoreData.find((x) => { if (x.companyStoreCashRegisterName == $scope.companyStoreCashRegister) { return x.companyStoreCashRegisterId } })
                    params.loyaltyCardYaltyId = forcedLoyaltyCardYaltyId != null ? forcedLoyaltyCardYaltyId : $scope.loyaltyCardPromotions;
                    params.companyStoreId = companyStoreId ? companyStoreId.companyStoreId : null
                    params.companyStoreName = $scope.companyStores;
                    params.companyStoreCashRegisterId = companyStoreCashRegisterId ? companyStoreCashRegisterId.companyStoreCashRegisterId : null;
                    params.companyStoreCashRegisterName = $scope.companyStoreCashRegister;
                    params.publicRedeemUserId = publicUserId1.value + publicUserId2.value + publicUserId3.value + publicUserId4.value + publicUserId5.value + publicUserId6.value;
                    params.partnerKey = $rootScope.partnerKey
                    params.forceRedeem = forceRedeem;
                    $scope.redeemStartInProgress = true;
                    $http.post(API_PREFIX.url + "/loyaltyCard/requestLoyaltyCardYaltyStampsAndRedeemsInitiatedByPartner", params).then((result) => {
                        $scope.redeemStartInProgress = false;
                        if (result.data.errorCode == 0) {
                            const alert = $mdDialog.alert()
                                .title("A beváltást sikeresen kezdeményezte.")
                                .textContent("A beváltás adatlapja hamarosan megjelenik a beváltási listában. Ezután ugyanúgy tudja azt kezelni, mint bármely más esetben.")
                                .ok('Rendben')
                            $mdDialog.show(alert).then(function () {
                                $scope.cancelCreateRedeem(form)
                                $scope.isCreateRedeemButtonDisabled = false;
                                $scope.showCreateRedeemWindow = false;
                            });
                        } else if (result.data.errorCode == 3) {
                            const alert = $mdDialog.alert()
                                .title("Hibás vagy nem létező felhasználói publikus azonosító.")
                                .textContent("Kérjük ellenőrizze az azonosító jegyeit, az esetleges elírásokat.")
                                .ok('Rendben')
                            $mdDialog.show(alert).then(function () {
                                $scope.isCreateRedeemButtonDisabled = false;
                            });
                        } else if (result.data.errorCode == 4) {
                            const alert = $mdDialog.alert()
                                .title("A kiválasztott hűségkártya törölt állapotú.")
                                .textContent("Az adott hűségkártyát a cégéhez tartozó valamely partner felhasználó időközben törölte. Így a kártyát a továbbiakban ügyfelei már nem tudják felhasználni.")
                                .ok('Rendben')
                            $mdDialog.show(alert).then(function () {
                                $scope.isCreateRedeemButtonDisabled = false;
                            });
                        } else if (result.data.errorCode == 5) {
                            const alert = $mdDialog.alert()
                                .title("A kiválasztott hűségkártya kifuttatott állapotú.")
                                .textContent("Az adott hűségkártyát a cégéhez tartozó valamely partner felhasználó időközben kifuttatta és a megadott felhasználónak nincs már megkezdett, legalább egy pecséttel rendelkező kártyája. Kifuttatott kártya esetén a felhasználó új kártyát már nem tud kezdeni.")
                                .ok('Rendben')
                            $mdDialog.show(alert).then(function () {
                                $scope.isCreateRedeemButtonDisabled = false;
                            });
                        } else if (result.data.errorCode == 6) {
                            const alert = $mdDialog.alert()
                                .title("A kiválasztott hűségkártya kifuttatott állapotú.")
                                .textContent("Az adott hűségkártyát a cégéhez tartozó valamely partner felhasználó időközben kifuttatta és a megadott felhasználónak nincs már megkezdett, legalább egy pecséttel rendelkező kártyája. Kifuttatott kártya esetén a felhasználó új kártyát már nem tud kezdeni.")
                                .ok('Rendben')
                            $mdDialog.show(alert).then(function () {
                                $scope.isCreateRedeemButtonDisabled = false;
                            });
                        } else if (result.data.errorCode == 7) {
                            const alert = $mdDialog.alert()
                                .title("A kiválasztott hűségkártya kifuttatott állapotú.")
                                .textContent("Az adott hűségkártyát a cégéhez tartozó valamely partner felhasználó időközben kifuttatta és a megadott felhasználónak csak egy lejárt érvényességű kártyája van. Kifuttatott kártya esetén a felhasználó új kártyát már nem tud kezdeni.")
                                .ok('Rendben')
                            $mdDialog.show(alert).then(function () {
                                $scope.isCreateRedeemButtonDisabled = false;
                            });
                        } else if (result.data.errorCode == 8) {
                            const alert = $mdDialog.alert()
                                .title("Az online vagy telefonos rendelés nincs engedélyezve az adott hűségkártyához")
                                .textContent("A kiválasztott hűségkártyához az online vagy telefonos rendelés nincs engedélyezve. Válasszon egy konkrét várost és üzletet, ha üzletben történő partner által kezdeményezett beváltásról van szó. Ha ténylegesen online vagy telefonos rendelés kapcsán szeretne beváltást indítani, akkor előbb engedélyezze az online és/vagy telefonos rendelést az adott hűségkártya adatlapján.")
                                .ok('Rendben')
                            $mdDialog.show(alert).then(function () {
                                $scope.isCreateRedeemButtonDisabled = false;
                            });
                        } else if (result.data.errorCode == 9) {
                            const alert = $mdDialog.alert()
                                .title("A kiválasztott üzlet nincs hozzárendelve a kiválasztott hűségkártyához.")
                                .textContent("Ha az adott hűségkártyára szeretné, hogy a kiválasztott üzletben is beváltható legyen, akkor rendelje hozzá az adott üzletet, mint beváltóhelyet, a hűségkártyához a kártya adatlapján.")
                                .ok('Rendben')
                            $mdDialog.show(alert).then(function () {
                                $scope.isCreateRedeemButtonDisabled = false;
                            });
                        } else if (result.data.errorCode == 10) {
                            const alert = $mdDialog.alert()
                                .title("Törölt felhasználó")
                                .textContent("A felhasználó időközben törölte a fiókját, így számára már pecsételés/beváltás sem kezdeményezhető.")
                                .ok('Rendben')
                            $mdDialog.show(alert).then(function () {
                                $scope.isCreateRedeemButtonDisabled = false;
                            });
                        } else if (result.data.errorCode == 11) {
                            let selectedLoyaltyCardYalty = $scope.allLoyaltyCardYaltyAndCompanyStoreData.find(item => item.loyaltyCardYaltyId == params.loyaltyCardYaltyId);
                            $mdDialog.show({
                                scope: $scope,
                                preserveScope: true,
                                controller: function ($scope, $mdDialog) {
                                    $scope.cancel = function () {
                                        $mdDialog.cancel();
                                        $scope.isCreateRedeemButtonDisabled = false;
                                    };
                                    $scope.startForSelected = function () {
                                        $mdDialog.cancel();
                                        $scope.showCreateRedeemWindow = false;
                                        $scope.requestLoyaltyCardByPartner(form, true, null);
                                    };
                                    $scope.startForRecommended = function () {
                                        $mdDialog.cancel();
                                        let selectedLoyaltyCardYalty = $scope.allLoyaltyCardYaltyAndCompanyStoreData.find(item => item.loyaltyCardYaltyId == result.data.linkedPreviousLoyaltyCardYalty.id && item.companyStoreId == params.companyStoreId && item.companyStoreCashRegisterId == params.companyStoreCashRegisterId);
                                        if (selectedLoyaltyCardYalty != null) {
                                            $scope.showCreateRedeemWindow = false;
                                            $scope.requestLoyaltyCardByPartner(form, false, result.data.linkedPreviousLoyaltyCardYalty.id);
                                        } else {
                                            if ($scope.areRedeemPageFilterSettingsPredefined == true) {
                                                const alert = $mdDialog.alert()
                                                    .title("Partneri fiók korlátozás: a megkezdett korábbi kártyához Önnek nincs hozzáférése")
                                                    .textContent("Az Ön partneri fiókja adott város(ok)ra, üzlet(ek)re, pénztár(ak)ra korlátozott. Ezen korlátozások mellett a megkezdett korábbi kártyához Önnek nincs hozzáférése. Válasszon másik kártyát (amennyiben ez lehetséges) vagy egyeztessen cége Yalty kapcsolattartójával, akinek Yalty admin vagy mester fiók hozzáférése van.")
                                                    .ok('Rendben')
                                                $mdDialog.show(alert).then(function () {
                                                    $scope.isCreateRedeemButtonDisabled = false;
                                                    $scope.showCreateRedeemWindow = false;
                                                });
                                            } else {
                                                const alert = $mdDialog.alert()
                                                    .title("A kiválasztott beváltóhely nincs hozzárendelve a megkezdett korábbi kártyához")
                                                    .textContent("A most kiválasztott, megkezdett korábbi kártyához nincs hozzárendelve az Ön által eredetileg (az új típusú kártyához) választott beváltóhely. Válassza ki ismét a megfelelő beváltóhelyet és kezdeményezze újra a beváltást/pecsételést.")
                                                    .ok('Rendben')
                                                $mdDialog.show(alert).then(function () {
                                                    $scope.companyStoreCashRegister = undefined;
                                                    $scope.companyStores = undefined;
                                                    $scope.yaltyCity = undefined;
                                                    $scope.loyaltyCardPromotions = result.data.linkedPreviousLoyaltyCardYalty.id
                                                    $scope.currentPromotionIsOnline();
                                                    $scope.isCreateRedeemButtonDisabled = false;
                                                });
                                            }
                                        }
                                    };
                                },
                                template: '<md-dialog>' +
                                    '<md-dialog-content>' +
                                    '<md-content layout-padding>' +
                                    '<div>' +
                                    '<h2>Megkezdett korábbi kártya továbbpecsételése</h2>' +
                                    '<p>' +
                                    'Ön két összekötött kártyából az újabb kártyát (' + selectedLoyaltyCardYalty.promotionName + ', ' + (selectedLoyaltyCardYalty.newCardIssuingStoppedTimestamp != null ? 'kifutó, ' : '') + 'aktiválva: ' + new Date(selectedLoyaltyCardYalty.activatedTimestamp).toISOString().split('T')[0].replaceAll('-', '.') + '.) választotta, de a vásárlónak még van megkezdett kártyája a régi típusú (' +
                                    result.data.linkedPreviousLoyaltyCardYalty.promotionName + ', ' + (result.data.linkedPreviousLoyaltyCardYalty.newCardIssuingStoppedTimestamp != null ? 'kifutó, ' : '') + 'aktiválva: ' + new Date(result.data.linkedPreviousLoyaltyCardYalty.activatedTimestamp).toISOString().split('T')[0].replaceAll('-', '.') + '.) kártyából.<br><br>' +
                                    'Javasoljuk, hogy előbb a már megkezdett régebbi kártyán folytassa a pecsételést.<br><br>' +
                                    'Pecsételés/Beváltás:' +
                                    '</p>' +
                                    '</div>' +
                                    '</md-content>' +
                                    '</md-dialog-content>' +
                                    '<md-dialog-actions layout-xs="column" layout-sm="column" layout-gt-sm="row" style="justify-content: center; padding-left: 8px; padding-right: 8px">' +
                                    '<md-button style="border: 2px solid #00AEEF; border-radius: 10px; background-color: #00AEEF" ng-click="startForRecommended()">A MÁR MEGKEZDETT RÉGI KÁRTYÁN</md-button>' +
                                    '<md-button style="border: 2px solid #ebebeb; border-radius: 10px; background-color: #ebebeb" ng-click="startForSelected()">ÚJ TÍPUSÚ KÁRTYÁN</md-button>' +
                                    '<md-button style="background-color: white;" ng-click="cancel()">Mégsem</md-button>' +
                                    '</md-dialog-actions>' +
                                    '</md-dialog>'
                            })
                        }
                    }, function () {
                        $scope.redeemStartInProgress = false;
                    })
                } else {
                    form.$setSubmitted();
                    angular.element($document[0].querySelector('input.ng-invalid')).focus();
                    angular.element($document[0].querySelector('#loyaltyCardPromotions')).focus();
                    angular.element($document[0].querySelector('#yaltyCity')).focus();
                    angular.element($document[0].querySelector('#companyStores')).focus();
                    if (!$scope.loyaltyCardPromotions) {
                        form.loyaltyCardPromotions.$valid = false;
                        form.loyaltyCardPromotions.$invalid = true;
                    }
                    if (!$scope.yaltyCity) {
                        form.yaltyCity.$valid = false;
                        form.yaltyCity.$invalid = true;
                    }
                    if (!$scope.companyStores) {
                        form.companyStores.$valid = false;
                        form.companyStores.$invalid = true;
                    }
                    $mdDialog.show(
                        $mdDialog.alert()
                            .title('A kijelölt mezők kitöltése szükséges a mentéshez.')
                            .ok('Rendben')
                    );
                }
            } else {
                $mdDialog.show(
                    $mdDialog.alert()
                        .title('A Felhasználó publikus azonosítójának megadása kötelező!')
                        .ok('Rendben')
                );
            }
        }
        if ($scope.checkAllCity == true) {
            $scope.setAllCities(true);
        }
        if ($scope.checkAllStore == true) {
            $scope.setAllCompStores(true);
        }
        var setCurrentAction = function (loyaltycard) {
            if (loyaltycard.canBeRedeemedLater == false) {
                if (loyaltycard.currentNrOfStamps == loyaltycard.reward1NrOfStampsNeeded
                    && loyaltycard.isRewardRedeemed1 == false) {
                    if (loyaltycard.reward2NrOfStampsNeeded == null) {
                        loyaltycard.currentAction = "végjutalom beváltása";
                    } else {
                        loyaltycard.currentAction = "1. jutalom beváltása";
                    }
                    loyaltycard.rewardDescr = loyaltycard.reward1RewardDescr;

                } else if (loyaltycard.currentNrOfStamps == loyaltycard.reward2NrOfStampsNeeded
                    && loyaltycard.isRewardRedeemed2 == false) {
                    if (loyaltycard.reward3NrOfStampsNeeded == null) {
                        loyaltycard.currentAction = "végjutalom beváltása";
                    } else {
                        loyaltycard.currentAction = "2. jutalom beváltása";
                    }
                    loyaltycard.rewardDescr = loyaltycard.reward1RewardDescr;

                } else if (loyaltycard.currentNrOfStamps == loyaltycard.reward3NrOfStampsNeeded
                    && loyaltycard.isRewardRedeemed3 == false) {
                    if (loyaltycard.reward4NrOfStampsNeeded == null) {
                        loyaltycard.currentAction = "végjutalom beváltása";
                    } else {
                        loyaltycard.currentAction = "3. jutalom beváltása";
                    }
                    loyaltycard.rewardDescr = loyaltycard.reward3RewardDescr;

                } else if (loyaltycard.currentNrOfStamps == loyaltycard.reward4NrOfStampsNeeded
                    && loyaltycard.isRewardRedeemed4 == false) {
                    if (loyaltycard.reward5NrOfStampsNeeded == null) {
                        loyaltycard.currentAction = "végjutalom beváltása";
                    } else {
                        loyaltycard.currentAction = "4. jutalom beváltása";
                    }
                    loyaltycard.rewardDescr = loyaltycard.reward4RewardDescr;

                } else if (loyaltycard.currentNrOfStamps == loyaltycard.reward5NrOfStampsNeeded
                    && loyaltycard.isRewardRedeemed5 == false) {
                    loyaltycard.currentAction = "végjutalom beváltása";
                    loyaltycard.rewardDescr = loyaltycard.reward5RewardDescr;
                } else {
                    loyaltycard.currentAction = "új pecsételés";
                }
            } else {
                loyaltycard.currentAction = "új pecsételés";
            }
        }

        var setNrOfStampsNeeded = function (loyaltycard) {
            if (loyaltycard.reward1NrOfStampsNeeded != null) {
                loyaltycard.nrOfStampsNeeded = loyaltycard.reward1NrOfStampsNeeded;
            }
            if (loyaltycard.reward2NrOfStampsNeeded != null) {
                loyaltycard.nrOfStampsNeeded = loyaltycard.reward2NrOfStampsNeeded;
            }
            if (loyaltycard.reward3NrOfStampsNeeded != null) {
                loyaltycard.nrOfStampsNeeded = loyaltycard.reward3NrOfStampsNeeded;
            }
            if (loyaltycard.reward4NrOfStampsNeeded != null) {
                loyaltycard.nrOfStampsNeeded = loyaltycard.reward4NrOfStampsNeeded;
            }
            if (loyaltycard.reward5NrOfStampsNeeded != null) {
                loyaltycard.nrOfStampsNeeded = loyaltycard.reward5NrOfStampsNeeded;
            }
        }

        var checkLatestRewardRedeemed = function (loyaltycard) {
            if (loyaltycard.isRewardRedeemed1 == false && loyaltycard.reward1NrOfStampsNeeded != null) {
                return false;
            } else if (loyaltycard.isRewardRedeemed2 == false && loyaltycard.reward2NrOfStampsNeeded != null) {
                return false;
            } else if (loyaltycard.isRewardRedeemed3 == false && loyaltycard.reward3NrOfStampsNeeded != null) {
                return false;
            } else if (loyaltycard.isRewardRedeemed4 == false && loyaltycard.reward4NrOfStampsNeeded != null) {
                return false;
            } else if (loyaltycard.isRewardRedeemed5 == false && loyaltycard.reward5NrOfStampsNeeded != null) {
                return false;
            } else {
                return true;
            }
        }

        var checkLatestStampReached = function (loyaltycard) {
            if (loyaltycard.currentNrOfStamps != loyaltycard.nrOfStampsNeeded) {
                return false;
            } else {
                return true;
            }
        }

        $scope.getYLCSelectedProductWindowTitle = function () {
            let title = '';
            if ($scope.YLCSelectedProductRedeem != null) {
                if ($scope.lastActionForYLCSelectableProducts == 'redemption') {
                    title = 'Beváltási tétel - '
                    if ($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.canBeRedeemedLater == false) {
                        if ($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.reward1NrOfStampsNeeded != null && $scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.reward1NrOfStampsNeeded == $scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.currentNrOfStamps) {
                            if ($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.reward2NrOfStampsNeeded == null) {
                                title += 'végjutalom'
                            } else {
                                title += $scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.currentNrOfStamps + '. pecsét'
                            }
                        } else if ($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.reward2NrOfStampsNeeded != null && $scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.reward2NrOfStampsNeeded == $scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.currentNrOfStamps) {
                            if ($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.reward3NrOfStampsNeeded == null) {
                                title += 'végjutalom'
                            } else {
                                title += $scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.currentNrOfStamps + '. pecsét'
                            }
                        } else if ($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.reward3NrOfStampsNeeded != null && $scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.reward3NrOfStampsNeeded == $scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.currentNrOfStamps) {
                            if ($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.reward4NrOfStampsNeeded == null) {
                                title += 'végjutalom'
                            } else {
                                title += $scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.currentNrOfStamps + '. pecsét'
                            }
                        } else if ($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.reward4NrOfStampsNeeded != null && $scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.reward4NrOfStampsNeeded == $scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.currentNrOfStamps) {
                            if ($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.reward5NrOfStampsNeeded == null) {
                                title += 'végjutalom'
                            } else {
                                title += $scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.currentNrOfStamps + '. pecsét'
                            }
                        } else if ($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.reward5NrOfStampsNeeded != null && $scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.reward5NrOfStampsNeeded == $scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.currentNrOfStamps) {
                            title += 'végjutalom'
                        }
                    } else {
                        if ($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.reward1NrOfStampsNeeded != null && $scope.lastActionRewardNrForYLCSelectableProducts == 1) {
                            if ($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.reward2NrOfStampsNeeded == null) {
                                title += 'végjutalom'
                            } else {
                                title += $scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.reward1NrOfStampsNeeded + '. pecsét'
                            }
                        } else if ($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.reward2NrOfStampsNeeded != null && $scope.lastActionRewardNrForYLCSelectableProducts == 2) {
                            if ($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.reward3NrOfStampsNeeded == null) {
                                title += 'végjutalom'
                            } else {
                                title += $scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.reward2NrOfStampsNeeded + '. pecsét'
                            }
                        } else if ($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.reward3NrOfStampsNeeded != null && $scope.lastActionRewardNrForYLCSelectableProducts == 3) {
                            if ($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.reward4NrOfStampsNeeded == null) {
                                title += 'végjutalom'
                            } else {
                                title += $scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.reward3NrOfStampsNeeded + '. pecsét'
                            }
                        } else if ($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.reward4NrOfStampsNeeded != null && $scope.lastActionRewardNrForYLCSelectableProducts == 4) {
                            if ($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.reward5NrOfStampsNeeded == null) {
                                title += 'végjutalom'
                            } else {
                                title += $scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.reward4NrOfStampsNeeded + '. pecsét'
                            }
                        } else if ($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.reward5NrOfStampsNeeded != null && $scope.lastActionRewardNrForYLCSelectableProducts == 5) {
                            title += 'végjutalom'
                        }
                    }
                } else {
                    title = 'Pecsételési tétel - ' + $scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.currentNrOfStamps + '. pecsét'
                }
            }
            return title;
        }

        $scope.checkcurrentNrOfStamps = function (param, redeem) {
            if (redeem.status == "ÚJ") {
                var index = $scope.loyaltycardRequestsOriginal.findIndex(x => angular.equals(x.key, redeem.key))
                var redeemIndex = $scope.redeemRequests.findIndex(x => angular.equals(x.key, redeem.key) && x.redeemType == 2)
                if (param == "+") {
                    if ((redeem.redeemData.loyaltyCard.canBeRedeemedLater == false && checkLatestRewardRedeemed(redeem.redeemData.loyaltyCard) == false) || (redeem.redeemData.loyaltyCard.canBeRedeemedLater == true && checkLatestStampReached(redeem.redeemData.loyaltyCard) == false)) {
                        if (redeem.redeemData.loyaltyCard.canBeRedeemedLater == false && redeem.redeemData.loyaltyCard.redeemCanBePostponed == false && (redeem.redeemData.loyaltyCard.currentNrOfStamps + 1) == redeem.redeemData.loyaltyCard.reward1NrOfStampsNeeded
                            && redeem.redeemData.loyaltyCard.isRewardRedeemed1 == false) {
                            redeem.redeemData.loyaltyCard.currentNrOfStamps = redeem.redeemData.loyaltyCard.currentNrOfStamps + 1;
                            redeem.redeemData.loyaltyCard.newStampCount = redeem.redeemData.loyaltyCard.newStampCount + 1;
                            redeem.redeemData.loyaltyCard.getReward = redeem.redeemData.loyaltyCard.getReward + 1;
                            redeem.redeemData.loyaltyCard.isCurrentRewardRedeemed = true;
                            redeem.redeemData.loyaltyCard.isRewardRedeemed1 = true;
                        } else if (redeem.redeemData.loyaltyCard.canBeRedeemedLater == false && redeem.redeemData.loyaltyCard.redeemCanBePostponed == false && (redeem.redeemData.loyaltyCard.currentNrOfStamps + 1) == redeem.redeemData.loyaltyCard.reward2NrOfStampsNeeded
                            && redeem.redeemData.loyaltyCard.isRewardRedeemed2 == false) {
                            redeem.redeemData.loyaltyCard.currentNrOfStamps = redeem.redeemData.loyaltyCard.currentNrOfStamps + 1;
                            redeem.redeemData.loyaltyCard.newStampCount = redeem.redeemData.loyaltyCard.newStampCount + 1;
                            redeem.redeemData.loyaltyCard.getReward = redeem.redeemData.loyaltyCard.getReward + 1;
                            redeem.redeemData.loyaltyCard.isCurrentRewardRedeemed = true;
                            redeem.redeemData.loyaltyCard.isRewardRedeemed2 = true;
                        } else if (redeem.redeemData.loyaltyCard.canBeRedeemedLater == false && redeem.redeemData.loyaltyCard.redeemCanBePostponed == false && (redeem.redeemData.loyaltyCard.currentNrOfStamps + 1) == redeem.redeemData.loyaltyCard.reward3NrOfStampsNeeded
                            && redeem.redeemData.loyaltyCard.isRewardRedeemed3 == false) {
                            redeem.redeemData.loyaltyCard.currentNrOfStamps = redeem.redeemData.loyaltyCard.currentNrOfStamps + 1;
                            redeem.redeemData.loyaltyCard.newStampCount = redeem.redeemData.loyaltyCard.newStampCount + 1;
                            redeem.redeemData.loyaltyCard.getReward = redeem.redeemData.loyaltyCard.getReward + 1;
                            redeem.redeemData.loyaltyCard.isCurrentRewardRedeemed = true;
                            redeem.redeemData.loyaltyCard.isRewardRedeemed3 = true;
                        } else if (redeem.redeemData.loyaltyCard.canBeRedeemedLater == false && redeem.redeemData.loyaltyCard.redeemCanBePostponed == false && (redeem.redeemData.loyaltyCard.currentNrOfStamps + 1) == redeem.redeemData.loyaltyCard.reward4NrOfStampsNeeded
                            && redeem.redeemData.loyaltyCard.isRewardRedeemed4 == false) {
                            redeem.redeemData.loyaltyCard.currentNrOfStamps = redeem.redeemData.loyaltyCard.currentNrOfStamps + 1;
                            redeem.redeemData.loyaltyCard.newStampCount = redeem.redeemData.loyaltyCard.newStampCount + 1;
                            redeem.redeemData.loyaltyCard.getReward = redeem.redeemData.loyaltyCard.getReward + 1;
                            redeem.redeemData.loyaltyCard.isCurrentRewardRedeemed = true;
                            redeem.redeemData.loyaltyCard.isRewardRedeemed4 = true;
                        } else if (redeem.redeemData.loyaltyCard.canBeRedeemedLater == false && redeem.redeemData.loyaltyCard.redeemCanBePostponed == false && (redeem.redeemData.loyaltyCard.currentNrOfStamps + 1) == redeem.redeemData.loyaltyCard.reward5NrOfStampsNeeded
                            && redeem.redeemData.loyaltyCard.isRewardRedeemed5 == false) {
                            redeem.redeemData.loyaltyCard.currentNrOfStamps = redeem.redeemData.loyaltyCard.currentNrOfStamps + 1;
                            redeem.redeemData.loyaltyCard.newStampCount = redeem.redeemData.loyaltyCard.newStampCount + 1;
                            redeem.redeemData.loyaltyCard.getReward = redeem.redeemData.loyaltyCard.getReward + 1;
                            redeem.redeemData.loyaltyCard.isCurrentRewardRedeemed = true;
                            redeem.redeemData.loyaltyCard.isRewardRedeemed5 = true;
                        } else if (redeem.redeemData.loyaltyCard.currentNrOfStamps == redeem.redeemData.loyaltyCard.reward1NrOfStampsNeeded
                            && redeem.redeemData.loyaltyCard.isRewardRedeemed1 == false && redeem.redeemData.loyaltyCard.canBeRedeemedLater == false) {
                            redeem.redeemData.loyaltyCard.getReward = redeem.redeemData.loyaltyCard.getReward + 1;
                            redeem.redeemData.loyaltyCard.isCurrentRewardRedeemed = true;
                            redeem.redeemData.loyaltyCard.isRewardRedeemed1 = true;
                            if (redeem.redeemData.loyaltyCard.isYLCSelectableProductsEnabled == true) {
                                $scope.YLCSelectedProductRedeem = redeem;
                                $scope.YLCSelectableProducts = JSON.parse(redeem.redeemData.loyaltyCard.YLCSelectableProducts);
                                $scope.lastActionForYLCSelectableProducts = "redemption";
                                $scope.YLCSelectedProductIndex = null;
                                $scope.showYLCSelectedProductWindow = true;
                            }
                        } else if (redeem.redeemData.loyaltyCard.currentNrOfStamps == redeem.redeemData.loyaltyCard.reward2NrOfStampsNeeded
                            && redeem.redeemData.loyaltyCard.isRewardRedeemed2 == false && redeem.redeemData.loyaltyCard.canBeRedeemedLater == false) {
                            redeem.redeemData.loyaltyCard.getReward = redeem.redeemData.loyaltyCard.getReward + 1;
                            redeem.redeemData.loyaltyCard.isCurrentRewardRedeemed = true;
                            redeem.redeemData.loyaltyCard.isRewardRedeemed2 = true;
                            if (redeem.redeemData.loyaltyCard.isYLCSelectableProductsEnabled == true) {
                                $scope.YLCSelectedProductRedeem = redeem;
                                $scope.YLCSelectableProducts = JSON.parse(redeem.redeemData.loyaltyCard.YLCSelectableProducts);
                                $scope.lastActionForYLCSelectableProducts = "redemption";
                                $scope.YLCSelectedProductIndex = null;
                                $scope.showYLCSelectedProductWindow = true;
                            }
                        } else if (redeem.redeemData.loyaltyCard.currentNrOfStamps == redeem.redeemData.loyaltyCard.reward3NrOfStampsNeeded
                            && redeem.redeemData.loyaltyCard.isRewardRedeemed3 == false && redeem.redeemData.loyaltyCard.canBeRedeemedLater == false) {
                            redeem.redeemData.loyaltyCard.getReward = redeem.redeemData.loyaltyCard.getReward + 1;
                            redeem.redeemData.loyaltyCard.isCurrentRewardRedeemed = true;
                            redeem.redeemData.loyaltyCard.isRewardRedeemed3 = true;
                            if (redeem.redeemData.loyaltyCard.isYLCSelectableProductsEnabled == true) {
                                $scope.YLCSelectedProductRedeem = redeem;
                                $scope.YLCSelectableProducts = JSON.parse(redeem.redeemData.loyaltyCard.YLCSelectableProducts);
                                $scope.lastActionForYLCSelectableProducts = "redemption";
                                $scope.YLCSelectedProductIndex = null;
                                $scope.showYLCSelectedProductWindow = true;
                            }
                        } else if (redeem.redeemData.loyaltyCard.currentNrOfStamps == redeem.redeemData.loyaltyCard.reward4NrOfStampsNeeded
                            && redeem.redeemData.loyaltyCard.isRewardRedeemed4 == false && redeem.redeemData.loyaltyCard.canBeRedeemedLater == false) {
                            redeem.redeemData.loyaltyCard.getReward = redeem.redeemData.loyaltyCard.getReward + 1;
                            redeem.redeemData.loyaltyCard.isCurrentRewardRedeemed = true;
                            redeem.redeemData.loyaltyCard.isRewardRedeemed4 = true;
                            if (redeem.redeemData.loyaltyCard.isYLCSelectableProductsEnabled == true) {
                                $scope.YLCSelectedProductRedeem = redeem;
                                $scope.YLCSelectableProducts = JSON.parse(redeem.redeemData.loyaltyCard.YLCSelectableProducts);
                                $scope.lastActionForYLCSelectableProducts = "redemption";
                                $scope.YLCSelectedProductIndex = null;
                                $scope.showYLCSelectedProductWindow = true;
                            }
                        } else if (redeem.redeemData.loyaltyCard.currentNrOfStamps == redeem.redeemData.loyaltyCard.reward5NrOfStampsNeeded
                            && redeem.redeemData.loyaltyCard.isRewardRedeemed5 == false && redeem.redeemData.loyaltyCard.canBeRedeemedLater == false) {
                            redeem.redeemData.loyaltyCard.getReward = redeem.redeemData.loyaltyCard.getReward + 1;
                            redeem.redeemData.loyaltyCard.isCurrentRewardRedeemed = true;
                            redeem.redeemData.loyaltyCard.isRewardRedeemed5 = true;
                            if (redeem.redeemData.loyaltyCard.isYLCSelectableProductsEnabled == true) {
                                $scope.YLCSelectedProductRedeem = redeem;
                                $scope.YLCSelectableProducts = JSON.parse(redeem.redeemData.loyaltyCard.YLCSelectableProducts);
                                $scope.lastActionForYLCSelectableProducts = "redemption";
                                $scope.YLCSelectedProductIndex = null;
                                $scope.showYLCSelectedProductWindow = true;
                            }
                        } else {
                            redeem.redeemData.loyaltyCard.currentNrOfStamps = redeem.redeemData.loyaltyCard.currentNrOfStamps + 1;
                            redeem.redeemData.loyaltyCard.newStampCount = redeem.redeemData.loyaltyCard.newStampCount + 1;
                            if (redeem.redeemData.loyaltyCard.isYLCSelectableProductsEnabled == true) {
                                $scope.YLCSelectedProductRedeem = redeem;
                                $scope.YLCSelectableProducts = JSON.parse(redeem.redeemData.loyaltyCard.YLCSelectableProducts);
                                $scope.lastActionForYLCSelectableProducts = "stamping";
                                $scope.YLCSelectedProductIndex = null;
                                $scope.showYLCSelectedProductWindow = true;
                            }
                        }
                        setCurrentAction(redeem.redeemData.loyaltyCard)
                    } else {
                        /*if (((redeem.redeemData.loyaltyCard.currentNrOfStamps == redeem.redeemData.loyaltyCard.nrOfStampsNeeded && redeem.redeemData.loyaltyCard.canBeRedeemedLater == false) || ((redeem.redeemData.loyaltyCard.currentNrOfStamps + 1) == redeem.redeemData.loyaltyCard.nrOfStampsNeeded && redeem.redeemData.loyaltyCard.canBeRedeemedLater == true))
                            && redeem.redeemData.loyaltyCard.isNewCardCreated == true) {
                            return;
                        }*/
                        if (index != -1) {
                            if (redeem.redeemData.loyaltyCard.newCardIssuingStoppedTimestamp == null) {
                                redeem.redeemData.loyaltyCard.isNewCardCreated = true;
                                redeem.redeemData.loyaltyCard.currentNrOfStamps = 1;
                                redeem.redeemData.loyaltyCard.isRewardRedeemed1 = false;
                                redeem.redeemData.loyaltyCard.isRewardRedeemed2 = false;
                                redeem.redeemData.loyaltyCard.isRewardRedeemed3 = false;
                                redeem.redeemData.loyaltyCard.isRewardRedeemed4 = false;
                                redeem.redeemData.loyaltyCard.isRewardRedeemed5 = false;
                                redeem.redeemData.loyaltyCard.newStampCount = redeem.redeemData.loyaltyCard.newStampCount + 1;
                                $scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.currentNrOfStamps = 0;
                                redeem.redeemData.loyaltyCard.cardValidityEndExpiry = redeem.redeemData.loyaltyCard.newCardValidityEndExpiry;
                                setCurrentAction(redeem);
                                if (redeem.redeemData.loyaltyCard.isYLCSelectableProductsEnabled == true) {
                                    $scope.YLCSelectedProductRedeem = redeem;
                                    $scope.YLCSelectableProducts = JSON.parse(redeem.redeemData.loyaltyCard.YLCSelectableProducts);
                                    $scope.lastActionForYLCSelectableProducts = "stamping";
                                    $scope.YLCSelectedProductIndex = null;
                                    $scope.showYLCSelectedProductWindow = true;
                                }
                            }
                        }
                    }
                } else if (param == "-") {
                    var currentNo = redeem.redeemData.loyaltyCard.currentNrOfStamps;
                    if (index != -1) {
                        if ($scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.currentNrOfStamps > (currentNo - 1)
                            && redeem.redeemData.loyaltyCard.isNewCardCreated == false) {
                            $scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.currentNrOfStamps = $scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.originalNrOfStamps;
                            $scope.redeemRequests[redeemIndex] = $.extend(true, {}, $scope.loyaltycardRequestsOriginal[index]);
                            $scope.redeemRequests[redeemIndex].showDetail = true;
                            setCurrentAction(redeem.redeemData.loyaltyCard)
                        } else {
                            if (redeem.redeemData.loyaltyCard.isNewCardCreated == true
                                && $scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.currentNrOfStamps >= (currentNo - 1) && redeem.redeemData.loyaltyCard.nrOfStampsNeeded >= (redeem.redeemData.loyaltyCard.newStampCount - 1)) {
                                redeem.redeemData.loyaltyCard.isNewCardCreated = false;
                                redeem.redeemData.loyaltyCard.newStampCount = redeem.redeemData.loyaltyCard.newStampCount - 1;
                                $scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.currentNrOfStamps = $scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.originalNrOfStamps;
                                redeem.redeemData.loyaltyCard.currentNrOfStamps = $scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.currentNrOfStamps + redeem.redeemData.loyaltyCard.newStampCount;
                                if (redeem.redeemData.loyaltyCard.canBeRedeemedLater == false) {
                                    redeem.redeemData.loyaltyCard.isRewardRedeemed1 = true;
                                    redeem.redeemData.loyaltyCard.isRewardRedeemed2 = true;
                                    redeem.redeemData.loyaltyCard.isRewardRedeemed3 = true;
                                    redeem.redeemData.loyaltyCard.isRewardRedeemed4 = true;
                                    redeem.redeemData.loyaltyCard.isRewardRedeemed5 = true;
                                    if (redeem.redeemData.loyaltyCard.YLCSelectedProductsForStampings.length > 0 && redeem.redeemData.loyaltyCard.newStampCount < redeem.redeemData.loyaltyCard.YLCSelectedProductsForStampings.length) {
                                        redeem.redeemData.loyaltyCard.YLCSelectedProductsForStampings.pop()
                                        //console.log(redeem.redeemData.loyaltyCard.YLCSelectedProductsForStampings)
                                        //console.log(redeem.redeemData.loyaltyCard.YLCSelectedProductsForRedemptions)
                                    }
                                } else {
                                    redeem.redeemData.loyaltyCard.isRewardRedeemed1 = $scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.isRewardRedeemed1;
                                    redeem.redeemData.loyaltyCard.isRewardRedeemed2 = $scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.isRewardRedeemed2;
                                    redeem.redeemData.loyaltyCard.isRewardRedeemed3 = $scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.isRewardRedeemed3;
                                    redeem.redeemData.loyaltyCard.isRewardRedeemed4 = $scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.isRewardRedeemed4;
                                    redeem.redeemData.loyaltyCard.isRewardRedeemed5 = $scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.isRewardRedeemed5;
                                }
                                redeem.redeemData.loyaltyCard.cardValidityEndExpiry = $scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.cardValidityEndExpiry;
                                setCurrentAction(redeem.redeemData.loyaltyCard)
                            } else if (redeem.redeemData.loyaltyCard.isNewCardCreated == true
                                && $scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.currentNrOfStamps >= (currentNo - 1) && redeem.redeemData.loyaltyCard.nrOfStampsNeeded <= (redeem.redeemData.loyaltyCard.newStampCount - 1)) {
                                redeem.redeemData.loyaltyCard.newStampCount = redeem.redeemData.loyaltyCard.newStampCount - 1;
                                redeem.redeemData.loyaltyCard.currentNrOfStamps = redeem.redeemData.loyaltyCard.nrOfStampsNeeded;
                                if (redeem.redeemData.loyaltyCard.canBeRedeemedLater == false) {
                                    redeem.redeemData.loyaltyCard.isRewardRedeemed1 = true;
                                    redeem.redeemData.loyaltyCard.isRewardRedeemed2 = true;
                                    redeem.redeemData.loyaltyCard.isRewardRedeemed3 = true;
                                    redeem.redeemData.loyaltyCard.isRewardRedeemed4 = true;
                                    redeem.redeemData.loyaltyCard.isRewardRedeemed5 = true;
                                    if (redeem.redeemData.loyaltyCard.YLCSelectedProductsForStampings.length > 0 && redeem.redeemData.loyaltyCard.newStampCount < redeem.redeemData.loyaltyCard.YLCSelectedProductsForStampings.length) {
                                        redeem.redeemData.loyaltyCard.YLCSelectedProductsForStampings.pop()
                                        //console.log(redeem.redeemData.loyaltyCard.YLCSelectedProductsForStampings)
                                        //console.log(redeem.redeemData.loyaltyCard.YLCSelectedProductsForRedemptions)
                                    }
                                } else {
                                    //TODO
                                    /*redeem.redeemData.loyaltyCard.isRewardRedeemed1 = $scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.isRewardRedeemed1;
                                    redeem.redeemData.loyaltyCard.isRewardRedeemed2 = $scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.isRewardRedeemed2;
                                    redeem.redeemData.loyaltyCard.isRewardRedeemed3 = $scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.isRewardRedeemed3;
                                    redeem.redeemData.loyaltyCard.isRewardRedeemed4 = $scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.isRewardRedeemed4;
                                    redeem.redeemData.loyaltyCard.isRewardRedeemed5 = $scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.isRewardRedeemed5;*/
                                }
                                setCurrentAction(redeem.redeemData.loyaltyCard)
                            } else {
                                if (redeem.redeemData.loyaltyCard.currentNrOfStamps == redeem.redeemData.loyaltyCard.reward1NrOfStampsNeeded
                                    && redeem.redeemData.loyaltyCard.isRewardRedeemed1 == true && redeem.redeemData.loyaltyCard.canBeRedeemedLater == false) {
                                    redeem.redeemData.loyaltyCard.getReward = redeem.redeemData.loyaltyCard.getReward - 1;
                                    redeem.redeemData.loyaltyCard.isRewardRedeemed1 = false;
                                    if (redeem.redeemData.loyaltyCard.redeemCanBePostponed == false) {
                                        redeem.redeemData.loyaltyCard.currentNrOfStamps = redeem.redeemData.loyaltyCard.currentNrOfStamps - 1;
                                        redeem.redeemData.loyaltyCard.newStampCount = redeem.redeemData.loyaltyCard.newStampCount - 1;
                                    }
                                    if (redeem.redeemData.loyaltyCard.YLCSelectedProductsForRedemptions.length > 0 && redeem.redeemData.loyaltyCard.getReward < redeem.redeemData.loyaltyCard.YLCSelectedProductsForRedemptions.length) {
                                        redeem.redeemData.loyaltyCard.YLCSelectedProductsForRedemptions.pop()
                                        //console.log(redeem.redeemData.loyaltyCard.YLCSelectedProductsForStampings)
                                        //console.log(redeem.redeemData.loyaltyCard.YLCSelectedProductsForRedemptions)
                                    }
                                } else if (redeem.redeemData.loyaltyCard.currentNrOfStamps == redeem.redeemData.loyaltyCard.reward2NrOfStampsNeeded
                                    && redeem.redeemData.loyaltyCard.isRewardRedeemed2 == true && redeem.redeemData.loyaltyCard.canBeRedeemedLater == false) {
                                    redeem.redeemData.loyaltyCard.getReward = redeem.redeemData.loyaltyCard.getReward - 1;
                                    redeem.redeemData.loyaltyCard.isRewardRedeemed2 = false;
                                    if (redeem.redeemData.loyaltyCard.redeemCanBePostponed == false) {
                                        redeem.redeemData.loyaltyCard.currentNrOfStamps = redeem.redeemData.loyaltyCard.currentNrOfStamps - 1;
                                        redeem.redeemData.loyaltyCard.newStampCount = redeem.redeemData.loyaltyCard.newStampCount - 1;
                                    }
                                    if (redeem.redeemData.loyaltyCard.YLCSelectedProductsForRedemptions.length > 0 && redeem.redeemData.loyaltyCard.getReward < redeem.redeemData.loyaltyCard.YLCSelectedProductsForRedemptions.length) {
                                        redeem.redeemData.loyaltyCard.YLCSelectedProductsForRedemptions.pop()
                                        //console.log(redeem.redeemData.loyaltyCard.YLCSelectedProductsForStampings)
                                        //console.log(redeem.redeemData.loyaltyCard.YLCSelectedProductsForRedemptions)
                                    }
                                } else if (redeem.redeemData.loyaltyCard.currentNrOfStamps == redeem.redeemData.loyaltyCard.reward3NrOfStampsNeeded
                                    && redeem.redeemData.loyaltyCard.isRewardRedeemed3 == true && redeem.redeemData.loyaltyCard.canBeRedeemedLater == false) {
                                    redeem.redeemData.loyaltyCard.getReward = redeem.redeemData.loyaltyCard.getReward - 1;
                                    redeem.redeemData.loyaltyCard.isRewardRedeemed3 = false;
                                    if (redeem.redeemData.loyaltyCard.redeemCanBePostponed == false) {
                                        redeem.redeemData.loyaltyCard.currentNrOfStamps = redeem.redeemData.loyaltyCard.currentNrOfStamps - 1;
                                        redeem.redeemData.loyaltyCard.newStampCount = redeem.redeemData.loyaltyCard.newStampCount - 1;
                                    }
                                    if (redeem.redeemData.loyaltyCard.YLCSelectedProductsForRedemptions.length > 0 && redeem.redeemData.loyaltyCard.getReward < redeem.redeemData.loyaltyCard.YLCSelectedProductsForRedemptions.length) {
                                        redeem.redeemData.loyaltyCard.YLCSelectedProductsForRedemptions.pop()
                                        //console.log(redeem.redeemData.loyaltyCard.YLCSelectedProductsForStampings)
                                        //console.log(redeem.redeemData.loyaltyCard.YLCSelectedProductsForRedemptions)
                                    }
                                } else if (redeem.redeemData.loyaltyCard.currentNrOfStamps == redeem.redeemData.loyaltyCard.reward4NrOfStampsNeeded
                                    && redeem.redeemData.loyaltyCard.isRewardRedeemed4 == true && redeem.redeemData.loyaltyCard.canBeRedeemedLater == false) {
                                    redeem.redeemData.loyaltyCard.getReward = redeem.redeemData.loyaltyCard.getReward - 1;
                                    redeem.redeemData.loyaltyCard.isRewardRedeemed4 = false;
                                    if (redeem.redeemData.loyaltyCard.redeemCanBePostponed == false) {
                                        redeem.redeemData.loyaltyCard.currentNrOfStamps = redeem.redeemData.loyaltyCard.currentNrOfStamps - 1;
                                        redeem.redeemData.loyaltyCard.newStampCount = redeem.redeemData.loyaltyCard.newStampCount - 1;
                                    }
                                    if (redeem.redeemData.loyaltyCard.YLCSelectedProductsForRedemptions.length > 0 && redeem.redeemData.loyaltyCard.getReward < redeem.redeemData.loyaltyCard.YLCSelectedProductsForRedemptions.length) {
                                        redeem.redeemData.loyaltyCard.YLCSelectedProductsForRedemptions.pop()
                                        //console.log(redeem.redeemData.loyaltyCard.YLCSelectedProductsForStampings)
                                        //console.log(redeem.redeemData.loyaltyCard.YLCSelectedProductsForRedemptions)
                                    }
                                } else if (redeem.redeemData.loyaltyCard.currentNrOfStamps == redeem.redeemData.loyaltyCard.reward5NrOfStampsNeeded
                                    && redeem.redeemData.loyaltyCard.isRewardRedeemed5 == true && redeem.redeemData.loyaltyCard.canBeRedeemedLater == false) {
                                    redeem.redeemData.loyaltyCard.getReward = redeem.redeemData.loyaltyCard.getReward - 1;
                                    redeem.redeemData.loyaltyCard.isRewardRedeemed5 = false;
                                    if (redeem.redeemData.loyaltyCard.redeemCanBePostponed == false) {
                                        redeem.redeemData.loyaltyCard.currentNrOfStamps = redeem.redeemData.loyaltyCard.currentNrOfStamps - 1;
                                        redeem.redeemData.loyaltyCard.newStampCount = redeem.redeemData.loyaltyCard.newStampCount - 1;
                                    }
                                    if (redeem.redeemData.loyaltyCard.YLCSelectedProductsForRedemptions.length > 0 && redeem.redeemData.loyaltyCard.getReward < redeem.redeemData.loyaltyCard.YLCSelectedProductsForRedemptions.length) {
                                        redeem.redeemData.loyaltyCard.YLCSelectedProductsForRedemptions.pop()
                                        //console.log(redeem.redeemData.loyaltyCard.YLCSelectedProductsForStampings)
                                        //console.log(redeem.redeemData.loyaltyCard.YLCSelectedProductsForRedemptions)
                                    }
                                } else {
                                    redeem.redeemData.loyaltyCard.currentNrOfStamps = redeem.redeemData.loyaltyCard.currentNrOfStamps - 1;
                                    redeem.redeemData.loyaltyCard.newStampCount = redeem.redeemData.loyaltyCard.newStampCount - 1;
                                    if (redeem.redeemData.loyaltyCard.YLCSelectedProductsForStampings.length > 0 && redeem.redeemData.loyaltyCard.newStampCount < redeem.redeemData.loyaltyCard.YLCSelectedProductsForStampings.length) {
                                        redeem.redeemData.loyaltyCard.YLCSelectedProductsForStampings.pop()
                                        //console.log(redeem.redeemData.loyaltyCard.YLCSelectedProductsForStampings)
                                        //console.log(redeem.redeemData.loyaltyCard.YLCSelectedProductsForRedemptions)
                                    }
                                }
                                setCurrentAction(redeem.redeemData.loyaltyCard)
                            }
                        }
                    }
                }
                if (redeem.redeemData.loyaltyCard.canBeRedeemedLater == true) {
                    if (redeem.redeemData.loyaltyCard.isNewCardCreated == true) {
                        let newStampCount = redeem.redeemData.loyaltyCard.newStampCount - (redeem.redeemData.loyaltyCard.nrOfStampsNeeded - $scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.originalNrOfStamps) - redeem.redeemData.loyaltyCard.currentNrOfStamps;
                        let nrOfCreatedInstancesBetweenActualAndLatestCreated = 0;
                        while (newStampCount >= 0) {
                            newStampCount = newStampCount - redeem.redeemData.loyaltyCard.nrOfStampsNeeded;
                            if (newStampCount >= 0) {
                                nrOfCreatedInstancesBetweenActualAndLatestCreated++;
                            }
                        }
                        if ($scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.originalNrOfStamps < redeem.redeemData.loyaltyCard.reward1NrOfStampsNeeded) {
                            redeem.redeemData.loyaltyCard.nrOfReward1CollectedNow = 1;
                        } else {
                            redeem.redeemData.loyaltyCard.nrOfReward1CollectedNow = 0;
                        }
                        if (redeem.redeemData.loyaltyCard.currentNrOfStamps >= redeem.redeemData.loyaltyCard.reward1NrOfStampsNeeded) {
                            redeem.redeemData.loyaltyCard.nrOfReward1CollectedNow++;
                        }
                        redeem.redeemData.loyaltyCard.nrOfReward1CollectedNow += nrOfCreatedInstancesBetweenActualAndLatestCreated
                        if (redeem.redeemData.loyaltyCard.reward2NrOfStampsNeeded != null && $scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.originalNrOfStamps < redeem.redeemData.loyaltyCard.reward2NrOfStampsNeeded) {
                            redeem.redeemData.loyaltyCard.nrOfReward2CollectedNow = 1;
                        } else {
                            redeem.redeemData.loyaltyCard.nrOfReward2CollectedNow = 0;
                        }
                        if (redeem.redeemData.loyaltyCard.reward2NrOfStampsNeeded != null && redeem.redeemData.loyaltyCard.currentNrOfStamps >= redeem.redeemData.loyaltyCard.reward2NrOfStampsNeeded) {
                            redeem.redeemData.loyaltyCard.nrOfReward2CollectedNow++;
                        }
                        if (redeem.redeemData.loyaltyCard.reward2NrOfStampsNeeded != null) {
                            redeem.redeemData.loyaltyCard.nrOfReward2CollectedNow += nrOfCreatedInstancesBetweenActualAndLatestCreated
                        }
                        if (redeem.redeemData.loyaltyCard.reward3NrOfStampsNeeded != null && $scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.originalNrOfStamps < redeem.redeemData.loyaltyCard.reward3NrOfStampsNeeded) {
                            redeem.redeemData.loyaltyCard.nrOfReward3CollectedNow = 1;
                        } else {
                            redeem.redeemData.loyaltyCard.nrOfReward3CollectedNow = 0;
                        }
                        if (redeem.redeemData.loyaltyCard.reward3NrOfStampsNeeded != null && redeem.redeemData.loyaltyCard.currentNrOfStamps >= redeem.redeemData.loyaltyCard.reward3NrOfStampsNeeded) {
                            redeem.redeemData.loyaltyCard.nrOfReward3CollectedNow++;
                        }
                        if (redeem.redeemData.loyaltyCard.reward3NrOfStampsNeeded != null) {
                            redeem.redeemData.loyaltyCard.nrOfReward3CollectedNow += nrOfCreatedInstancesBetweenActualAndLatestCreated
                        }
                        if (redeem.redeemData.loyaltyCard.reward4NrOfStampsNeeded != null && $scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.originalNrOfStamps < redeem.redeemData.loyaltyCard.reward4NrOfStampsNeeded) {
                            redeem.redeemData.loyaltyCard.nrOfReward4CollectedNow = 1;
                        } else {
                            redeem.redeemData.loyaltyCard.nrOfReward4CollectedNow = 0;
                        }
                        if (redeem.redeemData.loyaltyCard.reward4NrOfStampsNeeded != null && redeem.redeemData.loyaltyCard.currentNrOfStamps >= redeem.redeemData.loyaltyCard.reward4NrOfStampsNeeded) {
                            redeem.redeemData.loyaltyCard.nrOfReward4CollectedNow++;
                        }
                        if (redeem.redeemData.loyaltyCard.reward4NrOfStampsNeeded != null) {
                            redeem.redeemData.loyaltyCard.nrOfReward4CollectedNow += nrOfCreatedInstancesBetweenActualAndLatestCreated
                        }
                        if (redeem.redeemData.loyaltyCard.reward5NrOfStampsNeeded != null && $scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.originalNrOfStamps < redeem.redeemData.loyaltyCard.reward5NrOfStampsNeeded) {
                            redeem.redeemData.loyaltyCard.nrOfReward5CollectedNow = 1;
                        } else {
                            redeem.redeemData.loyaltyCard.nrOfReward5CollectedNow = 0;
                        }
                        if (redeem.redeemData.loyaltyCard.reward5NrOfStampsNeeded != null && redeem.redeemData.loyaltyCard.currentNrOfStamps >= redeem.redeemData.loyaltyCard.reward5NrOfStampsNeeded) {
                            redeem.redeemData.loyaltyCard.nrOfReward5CollectedNow++;
                        }
                        if (redeem.redeemData.loyaltyCard.reward5NrOfStampsNeeded != null) {
                            redeem.redeemData.loyaltyCard.nrOfReward5CollectedNow += nrOfCreatedInstancesBetweenActualAndLatestCreated
                        }
                    } else {
                        if (redeem.redeemData.loyaltyCard.currentNrOfStamps >= redeem.redeemData.loyaltyCard.reward1NrOfStampsNeeded &&
                            $scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.currentNrOfStamps < redeem.redeemData.loyaltyCard.reward1NrOfStampsNeeded) {
                            redeem.redeemData.loyaltyCard.nrOfReward1CollectedNow = 1;
                        } else {
                            redeem.redeemData.loyaltyCard.nrOfReward1CollectedNow = 0;
                        }
                        if (redeem.redeemData.loyaltyCard.reward2NrOfStampsNeeded != null && redeem.redeemData.loyaltyCard.currentNrOfStamps >= redeem.redeemData.loyaltyCard.reward2NrOfStampsNeeded &&
                            $scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.currentNrOfStamps < redeem.redeemData.loyaltyCard.reward2NrOfStampsNeeded) {
                            redeem.redeemData.loyaltyCard.nrOfReward2CollectedNow = 1;
                        } else {
                            redeem.redeemData.loyaltyCard.nrOfReward2CollectedNow = 0;
                        }
                        if (redeem.redeemData.loyaltyCard.reward3NrOfStampsNeeded != null && redeem.redeemData.loyaltyCard.currentNrOfStamps >= redeem.redeemData.loyaltyCard.reward3NrOfStampsNeeded &&
                            $scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.currentNrOfStamps < redeem.redeemData.loyaltyCard.reward3NrOfStampsNeeded) {
                            redeem.redeemData.loyaltyCard.nrOfReward3CollectedNow = 1;
                        } else {
                            redeem.redeemData.loyaltyCard.nrOfReward3CollectedNow = 0;
                        }
                        if (redeem.redeemData.loyaltyCard.reward4NrOfStampsNeeded != null && redeem.redeemData.loyaltyCard.currentNrOfStamps >= redeem.redeemData.loyaltyCard.reward4NrOfStampsNeeded &&
                            $scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.currentNrOfStamps < redeem.redeemData.loyaltyCard.reward4NrOfStampsNeeded) {
                            redeem.redeemData.loyaltyCard.nrOfReward4CollectedNow = 1;
                        } else {
                            redeem.redeemData.loyaltyCard.nrOfReward4CollectedNow = 0;
                        }
                        if (redeem.redeemData.loyaltyCard.reward5NrOfStampsNeeded != null && redeem.redeemData.loyaltyCard.currentNrOfStamps >= redeem.redeemData.loyaltyCard.reward5NrOfStampsNeeded &&
                            $scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.currentNrOfStamps < redeem.redeemData.loyaltyCard.reward5NrOfStampsNeeded) {
                            redeem.redeemData.loyaltyCard.nrOfReward5CollectedNow = 1;
                        } else {
                            redeem.redeemData.loyaltyCard.nrOfReward5CollectedNow = 0;
                        }
                    }
                    redeem.redeemData.loyaltyCard.newLaterRewards = redeem.redeemData.loyaltyCard.nrOfReward1CollectedNow + redeem.redeemData.loyaltyCard.nrOfReward2CollectedNow + redeem.redeemData.loyaltyCard.nrOfReward3CollectedNow + redeem.redeemData.loyaltyCard.nrOfReward4CollectedNow + redeem.redeemData.loyaltyCard.nrOfReward5CollectedNow
                    if (redeem.redeemData.loyaltyCard.mustBeRedeemedLater == false) {
                        if (redeem.redeemData.loyaltyCard.nrOfRedeemableRewardsPerRewardTypes == 1 || redeem.redeemData.loyaltyCard.nrOfRedeemableRewardsAtOnce == 1) {
                            if (redeem.redeemData.loyaltyCard.reward1RedeemRequested == true && redeem.redeemData.loyaltyCard.nrOfCollectedReward1 == 0) {
                                redeem.redeemData.loyaltyCard.newLaterRewards--;
                            }
                            if (redeem.redeemData.loyaltyCard.reward2RedeemRequested == true && redeem.redeemData.loyaltyCard.nrOfCollectedReward2 == 0) {
                                redeem.redeemData.loyaltyCard.newLaterRewards--;
                            }
                            if (redeem.redeemData.loyaltyCard.reward3RedeemRequested == true && redeem.redeemData.loyaltyCard.nrOfCollectedReward3 == 0) {
                                redeem.redeemData.loyaltyCard.newLaterRewards--;
                            }
                            if (redeem.redeemData.loyaltyCard.reward4RedeemRequested == true && redeem.redeemData.loyaltyCard.nrOfCollectedReward4 == 0) {
                                redeem.redeemData.loyaltyCard.newLaterRewards--;
                            }
                            if (redeem.redeemData.loyaltyCard.reward5RedeemRequested == true && redeem.redeemData.loyaltyCard.nrOfCollectedReward5 == 0) {
                                redeem.redeemData.loyaltyCard.newLaterRewards--;
                            }
                        } else {
                            if (redeem.redeemData.loyaltyCard.nrOfReward1Redeems > redeem.redeemData.loyaltyCard.nrOfCollectedReward1) {
                                redeem.redeemData.loyaltyCard.newLaterRewards -= (redeem.redeemData.loyaltyCard.nrOfReward1Redeems - redeem.redeemData.loyaltyCard.nrOfCollectedReward1);
                            }
                            if (redeem.redeemData.loyaltyCard.nrOfReward2Redeems > redeem.redeemData.loyaltyCard.nrOfCollectedReward2) {
                                redeem.redeemData.loyaltyCard.newLaterRewards -= (redeem.redeemData.loyaltyCard.nrOfReward2Redeems - redeem.redeemData.loyaltyCard.nrOfCollectedReward2);
                            }
                            if (redeem.redeemData.loyaltyCard.nrOfReward3Redeems > redeem.redeemData.loyaltyCard.nrOfCollectedReward3) {
                                redeem.redeemData.loyaltyCard.newLaterRewards -= (redeem.redeemData.loyaltyCard.nrOfReward3Redeems - redeem.redeemData.loyaltyCard.nrOfCollectedReward3);
                            }
                            if (redeem.redeemData.loyaltyCard.nrOfReward4Redeems > redeem.redeemData.loyaltyCard.nrOfCollectedReward4) {
                                redeem.redeemData.loyaltyCard.newLaterRewards -= (redeem.redeemData.loyaltyCard.nrOfReward4Redeems - redeem.redeemData.loyaltyCard.nrOfCollectedReward4);
                            }
                            if (redeem.redeemData.loyaltyCard.nrOfReward5Redeems > redeem.redeemData.loyaltyCard.nrOfCollectedReward5) {
                                redeem.redeemData.loyaltyCard.newLaterRewards -= (redeem.redeemData.loyaltyCard.nrOfReward5Redeems - redeem.redeemData.loyaltyCard.nrOfCollectedReward5);
                            }
                        }
                    }
                    if (param == "-" && redeem.redeemData.loyaltyCard.mustBeRedeemedLater == false) {
                        setMaxRedeemableRewardNr(redeem);
                    }
                }
            }
        }

        $scope.selectFromYLCSelectableProducts = function (index) {
            $scope.YLCSelectedProductIndex = index;
        }

        $scope.cancelYLCSelectedProductWindow = function () {
            $scope.showYLCSelectedProductWindow = false;
            $scope.YLCSelectedProduct = null;
            //$scope.YLCSelectedProductForm.YLCSelectedProduct.$setUntouched()
            if ($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.canBeRedeemedLater == false) {
                $scope.checkcurrentNrOfStamps("-", $scope.YLCSelectedProductRedeem);
            } else {
                if ($scope.lastActionForYLCSelectableProducts == "stamping") {
                    $scope.checkcurrentNrOfStamps("-", $scope.YLCSelectedProductRedeem);
                } else {
                    $scope.checkCurrentNrOfRewards($scope.lastActionRewardNrForYLCSelectableProducts, '-', $scope.YLCSelectedProductRedeem)
                }
            }
        }

        $scope.confirmYLCSelectedProduct = function () {
            if ($scope.YLCSelectedProductIndex == null) {
                //$scope.YLCSelectedProductForm.YLCSelectedProduct.$setTouched()
            } else {
                if ($scope.lastActionForYLCSelectableProducts == "stamping") {
                    $scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.YLCSelectedProductsForStampings.push($scope.YLCSelectableProducts[$scope.YLCSelectedProductIndex])
                } else {
                    if ($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.canBeRedeemedLater == false) {
                        $scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.YLCSelectedProductsForRedemptions.push($scope.YLCSelectableProducts[$scope.YLCSelectedProductIndex])
                    } else {
                        if ($scope.lastActionRewardNrForYLCSelectableProducts == 1) {
                            $scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.YLCSelectedProductsForReward1Redemptions.push($scope.YLCSelectableProducts[$scope.YLCSelectedProductIndex])
                        } else if ($scope.lastActionRewardNrForYLCSelectableProducts == 2) {
                            $scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.YLCSelectedProductsForReward2Redemptions.push($scope.YLCSelectableProducts[$scope.YLCSelectedProductIndex])
                        } else if ($scope.lastActionRewardNrForYLCSelectableProducts == 3) {
                            $scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.YLCSelectedProductsForReward3Redemptions.push($scope.YLCSelectableProducts[$scope.YLCSelectedProductIndex])
                        } else if ($scope.lastActionRewardNrForYLCSelectableProducts == 4) {
                            $scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.YLCSelectedProductsForReward4Redemptions.push($scope.YLCSelectableProducts[$scope.YLCSelectedProductIndex])
                        } else if ($scope.lastActionRewardNrForYLCSelectableProducts == 5) {
                            $scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.YLCSelectedProductsForReward5Redemptions.push($scope.YLCSelectableProducts[$scope.YLCSelectedProductIndex])
                        }
                    }
                }
                /*console.log($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.YLCSelectedProductsForStampings)
                console.log($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.YLCSelectedProductsForRedemptions)
                console.log($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.YLCSelectedProductsForReward1Redemptions)
                console.log($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.YLCSelectedProductsForReward2Redemptions)
                console.log($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.YLCSelectedProductsForReward3Redemptions)
                console.log($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.YLCSelectedProductsForReward4Redemptions)
                console.log($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.YLCSelectedProductsForReward5Redemptions)*/
                $scope.showYLCSelectedProductWindow = false;
                $scope.YLCSelectedProduct = null;
                //$scope.YLCSelectedProductForm.YLCSelectedProduct.$setUntouched()
            }
        }

        var setMaxRedeemableRewardNr = function (redeem) {
            if (redeem.redeemData.loyaltyCard.nrOfRedeemableRewardsPerRewardTypes == 1 || redeem.redeemData.loyaltyCard.nrOfRedeemableRewardsAtOnce == 1) {
                if ((redeem.redeemData.loyaltyCard.nrOfCollectedReward1 + redeem.redeemData.loyaltyCard.nrOfReward1CollectedNow) == 0 && redeem.redeemData.loyaltyCard.reward1RedeemRequested == true) {
                    redeem.redeemData.loyaltyCard.reward1RedeemRequested = false;
                    $scope.rewardChecked(redeem, 1)
                }
                if ((redeem.redeemData.loyaltyCard.nrOfCollectedReward2 + redeem.redeemData.loyaltyCard.nrOfReward2CollectedNow) == 0 && redeem.redeemData.loyaltyCard.reward2RedeemRequested == true) {
                    redeem.redeemData.loyaltyCard.reward2RedeemRequested = false;
                    $scope.rewardChecked(redeem, 2)
                }
                if ((redeem.redeemData.loyaltyCard.nrOfCollectedReward3 + redeem.redeemData.loyaltyCard.nrOfReward3CollectedNow) == 0 && redeem.redeemData.loyaltyCard.reward3RedeemRequested == true) {
                    redeem.redeemData.loyaltyCard.reward3RedeemRequested = false;
                    $scope.rewardChecked(redeem, 3)
                }
                if ((redeem.redeemData.loyaltyCard.nrOfCollectedReward4 + redeem.redeemData.loyaltyCard.nrOfReward4CollectedNow) == 0 && redeem.redeemData.loyaltyCard.reward4RedeemRequested == true) {
                    redeem.redeemData.loyaltyCard.reward4RedeemRequested = false;
                    $scope.rewardChecked(redeem, 4)
                }
                if ((redeem.redeemData.loyaltyCard.nrOfCollectedReward5 + redeem.redeemData.loyaltyCard.nrOfReward5CollectedNow) == 0 && redeem.redeemData.loyaltyCard.reward5RedeemRequested == true) {
                    redeem.redeemData.loyaltyCard.reward5RedeemRequested = false;
                    $scope.rewardChecked(redeem, 5)
                }
            } else if ((redeem.redeemData.loyaltyCard.nrOfRedeemableRewardsPerRewardTypes > 1 || redeem.redeemData.loyaltyCard.nrOfRedeemableRewardsPerRewardTypes == 0) && (redeem.redeemData.loyaltyCard.nrOfRedeemableRewardsAtOnce > 1 || redeem.redeemData.loyaltyCard.nrOfRedeemableRewardsAtOnce == 0)) {
                if ((redeem.redeemData.loyaltyCard.nrOfCollectedReward1 + redeem.redeemData.loyaltyCard.nrOfReward1CollectedNow) < redeem.redeemData.loyaltyCard.nrOfReward1Redeems) {
                    $scope.checkCurrentNrOfRewards(1, '-', redeem)
                }
                if ((redeem.redeemData.loyaltyCard.nrOfCollectedReward2 + redeem.redeemData.loyaltyCard.nrOfReward2CollectedNow) < redeem.redeemData.loyaltyCard.nrOfReward2Redeems) {
                    $scope.checkCurrentNrOfRewards(2, '-', redeem)
                }
                if ((redeem.redeemData.loyaltyCard.nrOfCollectedReward3 + redeem.redeemData.loyaltyCard.nrOfReward3CollectedNow) < redeem.redeemData.loyaltyCard.nrOfReward3Redeems) {
                    $scope.checkCurrentNrOfRewards(3, '-', redeem)
                }
                if ((redeem.redeemData.loyaltyCard.nrOfCollectedReward4 + redeem.redeemData.loyaltyCard.nrOfReward4CollectedNow) < redeem.redeemData.loyaltyCard.nrOfReward4Redeems) {
                    $scope.checkCurrentNrOfRewards(4, '-', redeem)
                }
                if ((redeem.redeemData.loyaltyCard.nrOfCollectedReward5 + redeem.redeemData.loyaltyCard.nrOfReward5CollectedNow) < redeem.redeemData.loyaltyCard.nrOfReward5Redeems) {
                    $scope.checkCurrentNrOfRewards(5, '-', redeem)
                }
            }
        }

        $scope.checkCurrentNrOfRewards = function (rewardNr, param, redeem) {
            if (redeem.status == "ÚJ") {
                if (param == "+") {
                    if (rewardNr == 1) {
                        if (redeem.redeemData.loyaltyCard.mustBeRedeemedLater == true) {
                            if (redeem.redeemData.loyaltyCard.nrOfReward1Redeems < redeem.redeemData.loyaltyCard.nrOfCollectedReward1 && (redeem.redeemData.loyaltyCard.nrOfReward1Redeems < redeem.redeemData.loyaltyCard.nrOfRedeemableRewardsPerRewardTypes || redeem.redeemData.loyaltyCard.nrOfRedeemableRewardsPerRewardTypes == 0) &&
                                (((redeem.redeemData.loyaltyCard.nrOfReward1Redeems + redeem.redeemData.loyaltyCard.nrOfReward2Redeems + redeem.redeemData.loyaltyCard.nrOfReward3Redeems + redeem.redeemData.loyaltyCard.nrOfReward4Redeems + redeem.redeemData.loyaltyCard.nrOfReward5Redeems) < redeem.redeemData.loyaltyCard.nrOfRedeemableRewardsAtOnce) || redeem.redeemData.loyaltyCard.nrOfRedeemableRewardsAtOnce == 0)) {
                                redeem.redeemData.loyaltyCard.nrOfReward1Redeems++;
                                if (redeem.redeemData.loyaltyCard.isYLCSelectableProductsEnabled == true) {
                                    $scope.YLCSelectedProductRedeem = redeem;
                                    $scope.YLCSelectableProducts = JSON.parse(redeem.redeemData.loyaltyCard.YLCSelectableProducts);
                                    $scope.lastActionForYLCSelectableProducts = "redemption";
                                    $scope.lastActionRewardNrForYLCSelectableProducts = 1;
                                    $scope.YLCSelectedProductIndex = null;
                                    $scope.showYLCSelectedProductWindow = true;
                                }
                            }
                        } else {
                            if (redeem.redeemData.loyaltyCard.nrOfReward1Redeems < (redeem.redeemData.loyaltyCard.nrOfCollectedReward1 + redeem.redeemData.loyaltyCard.nrOfReward1CollectedNow) && (redeem.redeemData.loyaltyCard.nrOfReward1Redeems < redeem.redeemData.loyaltyCard.nrOfRedeemableRewardsPerRewardTypes || redeem.redeemData.loyaltyCard.nrOfRedeemableRewardsPerRewardTypes == 0) &&
                                (((redeem.redeemData.loyaltyCard.nrOfReward1Redeems + redeem.redeemData.loyaltyCard.nrOfReward2Redeems + redeem.redeemData.loyaltyCard.nrOfReward3Redeems + redeem.redeemData.loyaltyCard.nrOfReward4Redeems + redeem.redeemData.loyaltyCard.nrOfReward5Redeems) < redeem.redeemData.loyaltyCard.nrOfRedeemableRewardsAtOnce) || redeem.redeemData.loyaltyCard.nrOfRedeemableRewardsAtOnce == 0)) {
                                redeem.redeemData.loyaltyCard.nrOfReward1Redeems++;
                                if (redeem.redeemData.loyaltyCard.isYLCSelectableProductsEnabled == true) {
                                    $scope.YLCSelectedProductRedeem = redeem;
                                    $scope.YLCSelectableProducts = JSON.parse(redeem.redeemData.loyaltyCard.YLCSelectableProducts);
                                    $scope.lastActionForYLCSelectableProducts = "redemption";
                                    $scope.lastActionRewardNrForYLCSelectableProducts = 1;
                                    $scope.YLCSelectedProductIndex = null;
                                    $scope.showYLCSelectedProductWindow = true;
                                }
                            }
                        }
                    } else if (rewardNr == 2) {
                        if (redeem.redeemData.loyaltyCard.mustBeRedeemedLater == true) {
                            if (redeem.redeemData.loyaltyCard.nrOfReward2Redeems < redeem.redeemData.loyaltyCard.nrOfCollectedReward2 && (redeem.redeemData.loyaltyCard.nrOfReward2Redeems < redeem.redeemData.loyaltyCard.nrOfRedeemableRewardsPerRewardTypes || redeem.redeemData.loyaltyCard.nrOfRedeemableRewardsPerRewardTypes == 0) &&
                                (((redeem.redeemData.loyaltyCard.nrOfReward1Redeems + redeem.redeemData.loyaltyCard.nrOfReward2Redeems + redeem.redeemData.loyaltyCard.nrOfReward3Redeems + redeem.redeemData.loyaltyCard.nrOfReward4Redeems + redeem.redeemData.loyaltyCard.nrOfReward5Redeems) < redeem.redeemData.loyaltyCard.nrOfRedeemableRewardsAtOnce) || redeem.redeemData.loyaltyCard.nrOfRedeemableRewardsAtOnce == 0)) {
                                redeem.redeemData.loyaltyCard.nrOfReward2Redeems++;
                                if (redeem.redeemData.loyaltyCard.isYLCSelectableProductsEnabled == true) {
                                    $scope.YLCSelectedProductRedeem = redeem;
                                    $scope.YLCSelectableProducts = JSON.parse(redeem.redeemData.loyaltyCard.YLCSelectableProducts);
                                    $scope.lastActionForYLCSelectableProducts = "redemption";
                                    $scope.lastActionRewardNrForYLCSelectableProducts = 2;
                                    $scope.YLCSelectedProductIndex = null;
                                    $scope.showYLCSelectedProductWindow = true;
                                }
                            }
                        } else {
                            if (redeem.redeemData.loyaltyCard.nrOfReward2Redeems < (redeem.redeemData.loyaltyCard.nrOfCollectedReward2 + redeem.redeemData.loyaltyCard.nrOfReward2CollectedNow) && (redeem.redeemData.loyaltyCard.nrOfReward2Redeems < redeem.redeemData.loyaltyCard.nrOfRedeemableRewardsPerRewardTypes || redeem.redeemData.loyaltyCard.nrOfRedeemableRewardsPerRewardTypes == 0) &&
                                (((redeem.redeemData.loyaltyCard.nrOfReward1Redeems + redeem.redeemData.loyaltyCard.nrOfReward2Redeems + redeem.redeemData.loyaltyCard.nrOfReward3Redeems + redeem.redeemData.loyaltyCard.nrOfReward4Redeems + redeem.redeemData.loyaltyCard.nrOfReward5Redeems) < redeem.redeemData.loyaltyCard.nrOfRedeemableRewardsAtOnce) || redeem.redeemData.loyaltyCard.nrOfRedeemableRewardsAtOnce == 0)) {
                                redeem.redeemData.loyaltyCard.nrOfReward2Redeems++;
                                if (redeem.redeemData.loyaltyCard.isYLCSelectableProductsEnabled == true) {
                                    $scope.YLCSelectedProductRedeem = redeem;
                                    $scope.YLCSelectableProducts = JSON.parse(redeem.redeemData.loyaltyCard.YLCSelectableProducts);
                                    $scope.lastActionForYLCSelectableProducts = "redemption";
                                    $scope.lastActionRewardNrForYLCSelectableProducts = 2;
                                    $scope.YLCSelectedProductIndex = null;
                                    $scope.showYLCSelectedProductWindow = true;
                                }
                            }
                        }
                    } else if (rewardNr == 3) {
                        if (redeem.redeemData.loyaltyCard.mustBeRedeemedLater == true) {
                            if (redeem.redeemData.loyaltyCard.nrOfReward3Redeems < redeem.redeemData.loyaltyCard.nrOfCollectedReward3 && (redeem.redeemData.loyaltyCard.nrOfReward3Redeems < redeem.redeemData.loyaltyCard.nrOfRedeemableRewardsPerRewardTypes || redeem.redeemData.loyaltyCard.nrOfRedeemableRewardsPerRewardTypes == 0) &&
                                (((redeem.redeemData.loyaltyCard.nrOfReward1Redeems + redeem.redeemData.loyaltyCard.nrOfReward2Redeems + redeem.redeemData.loyaltyCard.nrOfReward3Redeems + redeem.redeemData.loyaltyCard.nrOfReward4Redeems + redeem.redeemData.loyaltyCard.nrOfReward5Redeems) < redeem.redeemData.loyaltyCard.nrOfRedeemableRewardsAtOnce) || redeem.redeemData.loyaltyCard.nrOfRedeemableRewardsAtOnce == 0)) {
                                redeem.redeemData.loyaltyCard.nrOfReward3Redeems++;
                                if (redeem.redeemData.loyaltyCard.isYLCSelectableProductsEnabled == true) {
                                    $scope.YLCSelectedProductRedeem = redeem;
                                    $scope.YLCSelectableProducts = JSON.parse(redeem.redeemData.loyaltyCard.YLCSelectableProducts);
                                    $scope.lastActionForYLCSelectableProducts = "redemption";
                                    $scope.lastActionRewardNrForYLCSelectableProducts = 3;
                                    $scope.showYLCSelectedProductWindow = true;
                                }
                            }
                        } else {
                            if (redeem.redeemData.loyaltyCard.nrOfReward3Redeems < (redeem.redeemData.loyaltyCard.nrOfCollectedReward3 + redeem.redeemData.loyaltyCard.nrOfReward3CollectedNow) && (redeem.redeemData.loyaltyCard.nrOfReward3Redeems < redeem.redeemData.loyaltyCard.nrOfRedeemableRewardsPerRewardTypes || redeem.redeemData.loyaltyCard.nrOfRedeemableRewardsPerRewardTypes == 0) &&
                                (((redeem.redeemData.loyaltyCard.nrOfReward1Redeems + redeem.redeemData.loyaltyCard.nrOfReward2Redeems + redeem.redeemData.loyaltyCard.nrOfReward3Redeems + redeem.redeemData.loyaltyCard.nrOfReward4Redeems + redeem.redeemData.loyaltyCard.nrOfReward5Redeems) < redeem.redeemData.loyaltyCard.nrOfRedeemableRewardsAtOnce) || redeem.redeemData.loyaltyCard.nrOfRedeemableRewardsAtOnce == 0)) {
                                redeem.redeemData.loyaltyCard.nrOfReward3Redeems++;
                                if (redeem.redeemData.loyaltyCard.isYLCSelectableProductsEnabled == true) {
                                    $scope.YLCSelectedProductRedeem = redeem;
                                    $scope.YLCSelectableProducts = JSON.parse(redeem.redeemData.loyaltyCard.YLCSelectableProducts);
                                    $scope.lastActionForYLCSelectableProducts = "redemption";
                                    $scope.lastActionRewardNrForYLCSelectableProducts = 3;
                                    $scope.YLCSelectedProductIndex = null;
                                    $scope.showYLCSelectedProductWindow = true;
                                }
                            }
                        }
                    } else if (rewardNr == 4) {
                        if (redeem.redeemData.loyaltyCard.mustBeRedeemedLater == true) {
                            if (redeem.redeemData.loyaltyCard.nrOfReward4Redeems < redeem.redeemData.loyaltyCard.nrOfCollectedReward4 && redeem.redeemData.loyaltyCard.nrOfReward4Redeems < (redeem.redeemData.loyaltyCard.nrOfRedeemableRewardsPerRewardTypes || redeem.redeemData.loyaltyCard.nrOfRedeemableRewardsPerRewardTypes == 0) &&
                                (((redeem.redeemData.loyaltyCard.nrOfReward1Redeems + redeem.redeemData.loyaltyCard.nrOfReward2Redeems + redeem.redeemData.loyaltyCard.nrOfReward3Redeems + redeem.redeemData.loyaltyCard.nrOfReward4Redeems + redeem.redeemData.loyaltyCard.nrOfReward5Redeems) < redeem.redeemData.loyaltyCard.nrOfRedeemableRewardsAtOnce) || redeem.redeemData.loyaltyCard.nrOfRedeemableRewardsAtOnce == 0)) {
                                redeem.redeemData.loyaltyCard.nrOfReward4Redeems++;
                                if (redeem.redeemData.loyaltyCard.isYLCSelectableProductsEnabled == true) {
                                    $scope.YLCSelectedProductRedeem = redeem;
                                    $scope.YLCSelectableProducts = JSON.parse(redeem.redeemData.loyaltyCard.YLCSelectableProducts);
                                    $scope.lastActionForYLCSelectableProducts = "redemption";
                                    $scope.lastActionRewardNrForYLCSelectableProducts = 4;
                                    $scope.YLCSelectedProductIndex = null;
                                    $scope.showYLCSelectedProductWindow = true;
                                }
                            }
                        } else {
                            if (redeem.redeemData.loyaltyCard.nrOfReward4Redeems < (redeem.redeemData.loyaltyCard.nrOfCollectedReward4 + redeem.redeemData.loyaltyCard.nrOfReward4CollectedNow) && (redeem.redeemData.loyaltyCard.nrOfReward4Redeems < redeem.redeemData.loyaltyCard.nrOfRedeemableRewardsPerRewardTypes || redeem.redeemData.loyaltyCard.nrOfRedeemableRewardsPerRewardTypes == 0) &&
                                (((redeem.redeemData.loyaltyCard.nrOfReward1Redeems + redeem.redeemData.loyaltyCard.nrOfReward2Redeems + redeem.redeemData.loyaltyCard.nrOfReward3Redeems + redeem.redeemData.loyaltyCard.nrOfReward4Redeems + redeem.redeemData.loyaltyCard.nrOfReward5Redeems) < redeem.redeemData.loyaltyCard.nrOfRedeemableRewardsAtOnce) || redeem.redeemData.loyaltyCard.nrOfRedeemableRewardsAtOnce == 0)) {
                                redeem.redeemData.loyaltyCard.nrOfReward4Redeems++;
                                if (redeem.redeemData.loyaltyCard.isYLCSelectableProductsEnabled == true) {
                                    $scope.YLCSelectedProductRedeem = redeem;
                                    $scope.YLCSelectableProducts = JSON.parse(redeem.redeemData.loyaltyCard.YLCSelectableProducts);
                                    $scope.lastActionForYLCSelectableProducts = "redemption";
                                    $scope.lastActionRewardNrForYLCSelectableProducts = 4;
                                    $scope.YLCSelectedProductIndex = null;
                                    $scope.showYLCSelectedProductWindow = true;
                                }
                            }
                        }
                    } else if (rewardNr == 5) {
                        if (redeem.redeemData.loyaltyCard.mustBeRedeemedLater == true) {
                            if (redeem.redeemData.loyaltyCard.nrOfReward5Redeems < redeem.redeemData.loyaltyCard.nrOfCollectedReward5 && (redeem.redeemData.loyaltyCard.nrOfReward5Redeems < redeem.redeemData.loyaltyCard.nrOfRedeemableRewardsPerRewardTypes || redeem.redeemData.loyaltyCard.nrOfRedeemableRewardsPerRewardTypes == 0) &&
                                (((redeem.redeemData.loyaltyCard.nrOfReward1Redeems + redeem.redeemData.loyaltyCard.nrOfReward2Redeems + redeem.redeemData.loyaltyCard.nrOfReward3Redeems + redeem.redeemData.loyaltyCard.nrOfReward4Redeems + redeem.redeemData.loyaltyCard.nrOfReward5Redeems) < redeem.redeemData.loyaltyCard.nrOfRedeemableRewardsAtOnce) || redeem.redeemData.loyaltyCard.nrOfRedeemableRewardsAtOnce == 0)) {
                                redeem.redeemData.loyaltyCard.nrOfReward5Redeems++;
                                if (redeem.redeemData.loyaltyCard.isYLCSelectableProductsEnabled == true) {
                                    $scope.YLCSelectedProductRedeem = redeem;
                                    $scope.YLCSelectableProducts = JSON.parse(redeem.redeemData.loyaltyCard.YLCSelectableProducts);
                                    $scope.lastActionForYLCSelectableProducts = "redemption";
                                    $scope.lastActionRewardNrForYLCSelectableProducts = 5;
                                    $scope.YLCSelectedProductIndex = null;
                                    $scope.showYLCSelectedProductWindow = true;
                                }
                            }
                        } else {
                            if (redeem.redeemData.loyaltyCard.nrOfReward5Redeems < (redeem.redeemData.loyaltyCard.nrOfCollectedReward5 + redeem.redeemData.loyaltyCard.nrOfReward5CollectedNow) && (redeem.redeemData.loyaltyCard.nrOfReward5Redeems < redeem.redeemData.loyaltyCard.nrOfRedeemableRewardsPerRewardTypes || redeem.redeemData.loyaltyCard.nrOfRedeemableRewardsPerRewardTypes == 0) &&
                                (((redeem.redeemData.loyaltyCard.nrOfReward1Redeems + redeem.redeemData.loyaltyCard.nrOfReward2Redeems + redeem.redeemData.loyaltyCard.nrOfReward3Redeems + redeem.redeemData.loyaltyCard.nrOfReward4Redeems + redeem.redeemData.loyaltyCard.nrOfReward5Redeems) < redeem.redeemData.loyaltyCard.nrOfRedeemableRewardsAtOnce) || redeem.redeemData.loyaltyCard.nrOfRedeemableRewardsAtOnce == 0)) {
                                redeem.redeemData.loyaltyCard.nrOfReward5Redeems++;
                                if (redeem.redeemData.loyaltyCard.isYLCSelectableProductsEnabled == true) {
                                    $scope.YLCSelectedProductRedeem = redeem;
                                    $scope.YLCSelectableProducts = JSON.parse(redeem.redeemData.loyaltyCard.YLCSelectableProducts);
                                    $scope.lastActionForYLCSelectableProducts = "redemption";
                                    $scope.lastActionRewardNrForYLCSelectableProducts = 5;
                                    $scope.YLCSelectedProductIndex = null;
                                    $scope.showYLCSelectedProductWindow = true;
                                }
                            }
                        }
                    }
                } else if (param == "-") {
                    if (rewardNr == 1 && redeem.redeemData.loyaltyCard.nrOfReward1Redeems > 0) {
                        redeem.redeemData.loyaltyCard.nrOfReward1Redeems--;
                        if (redeem.redeemData.loyaltyCard.YLCSelectedProductsForReward1Redemptions.length > 0 && redeem.redeemData.loyaltyCard.nrOfReward1Redeems < redeem.redeemData.loyaltyCard.YLCSelectedProductsForReward1Redemptions.length) {
                            redeem.redeemData.loyaltyCard.YLCSelectedProductsForReward1Redemptions.pop()
                            /*console.log(redeem.redeemData.loyaltyCard.YLCSelectedProductsForStampings)
                            console.log($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.YLCSelectedProductsForReward1Redemptions)
                            console.log($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.YLCSelectedProductsForReward2Redemptions)
                            console.log($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.YLCSelectedProductsForReward3Redemptions)
                            console.log($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.YLCSelectedProductsForReward4Redemptions)
                            console.log($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.YLCSelectedProductsForReward5Redemptions)*/
                        }
                    } else if (rewardNr == 2 && redeem.redeemData.loyaltyCard.nrOfReward2Redeems > 0) {
                        redeem.redeemData.loyaltyCard.nrOfReward2Redeems--;
                        if (redeem.redeemData.loyaltyCard.YLCSelectedProductsForReward2Redemptions.length > 0 && redeem.redeemData.loyaltyCard.nrOfReward2Redeems < redeem.redeemData.loyaltyCard.YLCSelectedProductsForReward2Redemptions.length) {
                            redeem.redeemData.loyaltyCard.YLCSelectedProductsForReward2Redemptions.pop()
                            /*console.log(redeem.redeemData.loyaltyCard.YLCSelectedProductsForStampings)
                            console.log($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.YLCSelectedProductsForReward1Redemptions)
                            console.log($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.YLCSelectedProductsForReward2Redemptions)
                            console.log($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.YLCSelectedProductsForReward3Redemptions)
                            console.log($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.YLCSelectedProductsForReward4Redemptions)
                            console.log($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.YLCSelectedProductsForReward5Redemptions)*/
                        }
                    } else if (rewardNr == 3 && redeem.redeemData.loyaltyCard.nrOfReward3Redeems > 0) {
                        redeem.redeemData.loyaltyCard.nrOfReward3Redeems--;
                        if (redeem.redeemData.loyaltyCard.YLCSelectedProductsForReward3Redemptions.length > 0 && redeem.redeemData.loyaltyCard.nrOfReward3Redeems < redeem.redeemData.loyaltyCard.YLCSelectedProductsForReward3Redemptions.length) {
                            redeem.redeemData.loyaltyCard.YLCSelectedProductsForReward3Redemptions.pop()
                            /*console.log(redeem.redeemData.loyaltyCard.YLCSelectedProductsForStampings)
                            console.log($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.YLCSelectedProductsForReward1Redemptions)
                            console.log($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.YLCSelectedProductsForReward2Redemptions)
                            console.log($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.YLCSelectedProductsForReward3Redemptions)
                            console.log($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.YLCSelectedProductsForReward4Redemptions)
                            console.log($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.YLCSelectedProductsForReward5Redemptions)*/
                        }
                    } else if (rewardNr == 4 && redeem.redeemData.loyaltyCard.nrOfReward4Redeems > 0) {
                        redeem.redeemData.loyaltyCard.nrOfReward4Redeems--;
                        if (redeem.redeemData.loyaltyCard.YLCSelectedProductsForReward4Redemptions.length > 0 && redeem.redeemData.loyaltyCard.nrOfReward4Redeems < redeem.redeemData.loyaltyCard.YLCSelectedProductsForReward4Redemptions.length) {
                            redeem.redeemData.loyaltyCard.YLCSelectedProductsForReward4Redemptions.pop()
                            /*console.log(redeem.redeemData.loyaltyCard.YLCSelectedProductsForStampings)
                            console.log($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.YLCSelectedProductsForReward1Redemptions)
                            console.log($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.YLCSelectedProductsForReward2Redemptions)
                            console.log($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.YLCSelectedProductsForReward3Redemptions)
                            console.log($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.YLCSelectedProductsForReward4Redemptions)
                            console.log($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.YLCSelectedProductsForReward5Redemptions)*/
                        }
                    } else if (rewardNr == 5 && redeem.redeemData.loyaltyCard.nrOfReward5Redeems > 0) {
                        redeem.redeemData.loyaltyCard.nrOfReward5Redeems--;
                        if (redeem.redeemData.loyaltyCard.YLCSelectedProductsForReward5Redemptions.length > 0 && redeem.redeemData.loyaltyCard.nrOfReward5Redeems < redeem.redeemData.loyaltyCard.YLCSelectedProductsForReward5Redemptions.length) {
                            redeem.redeemData.loyaltyCard.YLCSelectedProductsForReward5Redemptions.pop()
                            /*console.log(redeem.redeemData.loyaltyCard.YLCSelectedProductsForStampings)
                            console.log($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.YLCSelectedProductsForReward1Redemptions)
                            console.log($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.YLCSelectedProductsForReward2Redemptions)
                            console.log($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.YLCSelectedProductsForReward3Redemptions)
                            console.log($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.YLCSelectedProductsForReward4Redemptions)
                            console.log($scope.YLCSelectedProductRedeem.redeemData.loyaltyCard.YLCSelectedProductsForReward5Redemptions)*/
                        }
                    }
                }
                redeem.redeemData.loyaltyCard.getReward = redeem.redeemData.loyaltyCard.nrOfReward1Redeems + redeem.redeemData.loyaltyCard.nrOfReward2Redeems +
                    redeem.redeemData.loyaltyCard.nrOfReward3Redeems + redeem.redeemData.loyaltyCard.nrOfReward4Redeems + redeem.redeemData.loyaltyCard.nrOfReward5Redeems;
                redeem.redeemData.loyaltyCard.newLaterRewards = redeem.redeemData.loyaltyCard.nrOfReward1CollectedNow + redeem.redeemData.loyaltyCard.nrOfReward2CollectedNow + redeem.redeemData.loyaltyCard.nrOfReward3CollectedNow + redeem.redeemData.loyaltyCard.nrOfReward4CollectedNow + redeem.redeemData.loyaltyCard.nrOfReward5CollectedNow
                if (redeem.redeemData.loyaltyCard.mustBeRedeemedLater == false) {
                    if (redeem.redeemData.loyaltyCard.nrOfReward1Redeems > redeem.redeemData.loyaltyCard.nrOfCollectedReward1) {
                        redeem.redeemData.loyaltyCard.newLaterRewards -= (redeem.redeemData.loyaltyCard.nrOfReward1Redeems - redeem.redeemData.loyaltyCard.nrOfCollectedReward1);
                    }
                    if (redeem.redeemData.loyaltyCard.nrOfReward2Redeems > redeem.redeemData.loyaltyCard.nrOfCollectedReward2) {
                        redeem.redeemData.loyaltyCard.newLaterRewards -= (redeem.redeemData.loyaltyCard.nrOfReward2Redeems - redeem.redeemData.loyaltyCard.nrOfCollectedReward2);
                    }
                    if (redeem.redeemData.loyaltyCard.nrOfReward3Redeems > redeem.redeemData.loyaltyCard.nrOfCollectedReward3) {
                        redeem.redeemData.loyaltyCard.newLaterRewards -= (redeem.redeemData.loyaltyCard.nrOfReward3Redeems - redeem.redeemData.loyaltyCard.nrOfCollectedReward3);
                    }
                    if (redeem.redeemData.loyaltyCard.nrOfReward4Redeems > redeem.redeemData.loyaltyCard.nrOfCollectedReward4) {
                        redeem.redeemData.loyaltyCard.newLaterRewards -= (redeem.redeemData.loyaltyCard.nrOfReward4Redeems - redeem.redeemData.loyaltyCard.nrOfCollectedReward4);
                    }
                    if (redeem.redeemData.loyaltyCard.nrOfReward5Redeems > redeem.redeemData.loyaltyCard.nrOfCollectedReward5) {
                        redeem.redeemData.loyaltyCard.newLaterRewards -= (redeem.redeemData.loyaltyCard.nrOfReward5Redeems - redeem.redeemData.loyaltyCard.nrOfCollectedReward5);
                    }
                }
            }
        }

        $scope.acceptOrRejectLoyaltyCardRedeem = function (redeem, isRedeemAccepted) {
            var request = {};
            request.key = redeem.key;
            let index = $scope.redeemRequests.findIndex(x => angular.equals(x.key, redeem.key))
            $scope.redeemRequests[index].loading = true;
            try {
                if (isRedeemAccepted === true) {
                    request.overallNoOfNewStamps = redeem.redeemData.loyaltyCard.newStampCount;
                    request.overallNoOfNewRedeems = redeem.redeemData.loyaltyCard.getReward;
                    request.overallNoOfNewRewards = redeem.redeemData.loyaltyCard.newLaterRewards;
                    request.YLCSelectedProductsForStampings = redeem.redeemData.loyaltyCard.YLCSelectedProductsForStampings;
                    request.YLCSelectedProductsForRedemptions = redeem.redeemData.loyaltyCard.YLCSelectedProductsForRedemptions;
                    request.YLCSelectedProductsForReward1Redemptions = redeem.redeemData.loyaltyCard.YLCSelectedProductsForReward1Redemptions;
                    request.YLCSelectedProductsForReward2Redemptions = redeem.redeemData.loyaltyCard.YLCSelectedProductsForReward2Redemptions;
                    request.YLCSelectedProductsForReward3Redemptions = redeem.redeemData.loyaltyCard.YLCSelectedProductsForReward3Redemptions;
                    request.YLCSelectedProductsForReward4Redemptions = redeem.redeemData.loyaltyCard.YLCSelectedProductsForReward4Redemptions;
                    request.YLCSelectedProductsForReward5Redemptions = redeem.redeemData.loyaltyCard.YLCSelectedProductsForReward5Redemptions;
                    if (redeem.redeemData.loyaltyCard.canBeRedeemedLater == true) {
                        if ((redeem.redeemData.loyaltyCard.nrOfRedeemableRewardsPerRewardTypes > 1 || redeem.redeemData.loyaltyCard.nrOfRedeemableRewardsPerRewardTypes == 0) && (redeem.redeemData.loyaltyCard.nrOfRedeemableRewardsAtOnce > 1 || redeem.redeemData.loyaltyCard.nrOfRedeemableRewardsAtOnce == 0)) {
                            request.nrOfReward1Redeems = redeem.redeemData.loyaltyCard.nrOfReward1Redeems;
                            request.nrOfReward2Redeems = redeem.redeemData.loyaltyCard.nrOfReward2Redeems;
                            request.nrOfReward3Redeems = redeem.redeemData.loyaltyCard.nrOfReward3Redeems;
                            request.nrOfReward4Redeems = redeem.redeemData.loyaltyCard.nrOfReward4Redeems;
                            request.nrOfReward5Redeems = redeem.redeemData.loyaltyCard.nrOfReward5Redeems;
                        } else {
                            request.nrOfReward1Redeems = redeem.redeemData.loyaltyCard.reward1RedeemRequested == true ? 1 : 0;
                            request.nrOfReward2Redeems = redeem.redeemData.loyaltyCard.reward2RedeemRequested == true ? 1 : 0;
                            request.nrOfReward3Redeems = redeem.redeemData.loyaltyCard.reward3RedeemRequested == true ? 1 : 0;
                            request.nrOfReward4Redeems = redeem.redeemData.loyaltyCard.reward4RedeemRequested == true ? 1 : 0;
                            request.nrOfReward5Redeems = redeem.redeemData.loyaltyCard.reward5RedeemRequested == true ? 1 : 0;
                        }
                    } else {
                        request.nrOfReward1Redeems = 0;
                        request.nrOfReward2Redeems = 0;
                        request.nrOfReward3Redeems = 0;
                        request.nrOfReward4Redeems = 0;
                        request.nrOfReward5Redeems = 0;
                    }
                } else {
                    request.overallNoOfNewStamps = 0;
                    request.overallNoOfNewRedeems = 0;
                    request.overallNoOfNewRewards = 0;
                    request.nrOfReward1Redeems = 0;
                    request.nrOfReward2Redeems = 0;
                    request.nrOfReward3Redeems = 0;
                    request.nrOfReward4Redeems = 0;
                    request.nrOfReward5Redeems = 0;
                }
                request.redeemAccepted = isRedeemAccepted;
                request.handlerName = $cookies.get("nameTitle") != "null" ?
                    $cookies.get("nameTitle") + " " + $cookies.get("lastName") + " " + $cookies.get("firstName") :
                    $cookies.get("lastName") + " " + $cookies.get("firstName")
                request.handlerEmail = $cookies.get("userEmail")
                request.handlerId = $scope.tabID;
                request.partnerKey = $rootScope.partnerKey
                if (redeem.redeemData.loyaltyCard.YLCPartnerRedeemRemark == "") {
                    request.YLCPartnerRedeemRemark = null;
                } else {
                    request.YLCPartnerRedeemRemark = redeem.redeemData.loyaltyCard.YLCPartnerRedeemRemark;
                }
                $http.post(API_PREFIX.url + '/loyaltyCard/partnerRedeemResponse', request).then(function (result) {
                }, function (result) {
                    console.log('status: ' + result.status + ' | statusText: ' + result.statusText + ' | request body: ' + JSON.stringify(result.config.data))
                    const request = {}
                    request.etc = 'status: ' + result.status + ' | statusText: ' + result.statusText + ' | request body: ' + JSON.stringify(result.config.data);
                    $http.post(API_PREFIX.url + "/service/logWebAppFrontendEvent", request, function (result) {
                    }, function () { });
                    $scope.redeemRequests[index].loading = false;
                    if (isRedeemAccepted === false) {
                        $scope.restoreLoyaltyCard(redeem);
                    }
                    $mdToast.show(
                        $mdToast.simple()
                            .parent(document.getElementById($scope.redeemRequests[index].key))
                            .textContent(isRedeemAccepted === false ? 'Nincs vagy bizonytalan a net kapcsolat. Próbálja meg a Elutasítást újra!' : 'Nincs vagy bizonytalan a net kapcsolat. Próbálja meg a Véglegesítést újra!')
                            .position('bottom left')
                            .hideDelay(2000))
                        .then(function () { }).catch(function () { });
                });
            } catch (e) {
                console.log(e);
                const request = {}
                request.etc = e.toString();
                $http.post(API_PREFIX.url + "/service/logWebAppFrontendEvent", request, function (result) {
                }, function () { });
                $scope.redeemRequests[index].loading = false;
            }
            updateSession()
        }

        $scope.isLoyaltycardStamped = function (loyaltycard, item, backgroundClass, isBlackGift) {
            if ((backgroundClass == "giftUnstampedBackground" || backgroundClass == "giftCollectedBackground") && (isBlackGift == true)) {
                return ((loyaltycard.reward1NrOfStampsNeeded == item || loyaltycard.reward2NrOfStampsNeeded == item
                    || loyaltycard.reward3NrOfStampsNeeded == item || loyaltycard.reward4NrOfStampsNeeded == item
                    || loyaltycard.reward5NrOfStampsNeeded == item) && (isBlackGift == true) ? true : false)
            } else if ((backgroundClass == "giftActiveStampedBackground" || backgroundClass == "giftStampedBackground") && (isBlackGift == false)) {
                return ((loyaltycard.reward1NrOfStampsNeeded == item || loyaltycard.reward2NrOfStampsNeeded == item
                    || loyaltycard.reward3NrOfStampsNeeded == item || loyaltycard.reward4NrOfStampsNeeded == item
                    || loyaltycard.reward5NrOfStampsNeeded == item) && (isBlackGift == false) ? true : false)
            } else if (isBlackGift == undefined) {
                return (loyaltycard.reward1NrOfStampsNeeded == item || loyaltycard.reward2NrOfStampsNeeded == item
                    || loyaltycard.reward3NrOfStampsNeeded == item || loyaltycard.reward4NrOfStampsNeeded == item
                    || loyaltycard.reward5NrOfStampsNeeded == item) ? true : false
            }
        }

        $scope.getBackgroundClass = function (redeem, item) {
            var index = $scope.loyaltycardRequestsOriginal.findIndex(x => angular.equals(x.key, redeem.key))
            if (index != -1) {
                //Ha a jelenlegi elemnél nagyobb vagy egyenlő a jelenlegi pecsétszám és (a jelenlegi reward megkapva vagy nem rewardon áll)
                if ($scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.currentNrOfStamps > item
                    || $scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.currentNrOfStamps == item
                    && ($scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.isCurrentRewardRedeemed == true
                        || $scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.isCurrentRewardRedeemed == null)) {
                    return 'stampedBackground'
                    //Ha a jelenlegi elem megegyezik a jelenlegi pecsét számmal, ami egyben reward is, de az még nincs beváltva
                } else if ($scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.currentNrOfStamps == item) {
                    return 'stampedBackground'
                    //Ha a jelenlegi elemnél nagyobb vagy egyenlő az aktuális pecsétszám és már adtunk rá további pecsétet és nem reward egyben
                } else if (redeem.redeemData.loyaltyCard.currentNrOfStamps >= item
                    && $scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.currentNrOfStamps < redeem.redeemData.loyaltyCard.currentNrOfStamps
                    && redeem.redeemData.loyaltyCard.reward1NrOfStampsNeeded != item && redeem.redeemData.loyaltyCard.reward2NrOfStampsNeeded != item
                    && redeem.redeemData.loyaltyCard.reward3NrOfStampsNeeded != item && redeem.redeemData.loyaltyCard.reward4NrOfStampsNeeded != item
                    && redeem.redeemData.loyaltyCard.reward5NrOfStampsNeeded != item) {
                    return 'activeStampedBackground'
                } else if ($scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.currentNrOfStamps > item
                    || $scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.currentNrOfStamps == item
                    || (redeem.redeemData.loyaltyCard.reward1NrOfStampsNeeded == item && redeem.redeemData.loyaltyCard.isRewardRedeemed1 == true)
                    || (redeem.redeemData.loyaltyCard.reward2NrOfStampsNeeded == item && redeem.redeemData.loyaltyCard.isRewardRedeemed2 == true)
                    || (redeem.redeemData.loyaltyCard.reward3NrOfStampsNeeded == item && redeem.redeemData.loyaltyCard.isRewardRedeemed3 == true)
                    || (redeem.redeemData.loyaltyCard.reward4NrOfStampsNeeded == item && redeem.redeemData.loyaltyCard.isRewardRedeemed4 == true)
                    || (redeem.redeemData.loyaltyCard.reward5NrOfStampsNeeded == item && redeem.redeemData.loyaltyCard.isRewardRedeemed5 == true)
                    && redeem.redeemData.loyaltyCard.isCurrentRewardRedeemed == true) {
                    return 'activeStampedBackground'
                } else if (redeem.redeemData.loyaltyCard.currentNrOfStamps == item
                    && $scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.currentNrOfStamps < redeem.redeemData.loyaltyCard.currentNrOfStamps
                    && ((redeem.redeemData.loyaltyCard.reward1NrOfStampsNeeded == item && redeem.redeemData.loyaltyCard.isRewardRedeemed1 == false)
                        || (redeem.redeemData.loyaltyCard.reward2NrOfStampsNeeded == item && redeem.redeemData.loyaltyCard.isRewardRedeemed2 == false)
                        || (redeem.redeemData.loyaltyCard.reward3NrOfStampsNeeded == item && redeem.redeemData.loyaltyCard.isRewardRedeemed3 == false)
                        || (redeem.redeemData.loyaltyCard.reward4NrOfStampsNeeded == item && redeem.redeemData.loyaltyCard.isRewardRedeemed4 == false)
                        || (redeem.redeemData.loyaltyCard.reward5NrOfStampsNeeded == item && redeem.redeemData.loyaltyCard.isRewardRedeemed5 == false))) {
                    return 'activeStampedBackground'
                } else if (redeem.redeemData.loyaltyCard.currentNrOfStamps >= item
                    && $scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.currentNrOfStamps < redeem.redeemData.loyaltyCard.currentNrOfStamps
                    && (redeem.redeemData.loyaltyCard.reward1NrOfStampsNeeded == item || redeem.redeemData.loyaltyCard.reward2NrOfStampsNeeded == item
                        || redeem.redeemData.loyaltyCard.reward3NrOfStampsNeeded == item || redeem.redeemData.loyaltyCard.reward4NrOfStampsNeeded == item
                        || redeem.redeemData.loyaltyCard.reward5NrOfStampsNeeded == item)) {
                    return 'activeStampedBackground'
                } else if (redeem.redeemData.loyaltyCard.currentNrOfStamps < item
                    || $scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.currentNrOfStamps > redeem.redeemData.loyaltyCard.currentNrOfStamps) {
                    return 'unstampedBackground'
                }
            }
        }

        $scope.getStampBackgroundClass = function (redeem, item) {
            var index = $scope.loyaltycardRequestsOriginal.findIndex(x => angular.equals(x.key, redeem.key))
            if (index != -1) {
                //Ha a jelenlegi elemnél nagyobb vagy egyenlő a jelenlegi pecsétszám és (a jelenlegi reward megkapva vagy nem rewardon áll)
                if ($scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.currentNrOfStamps >= item) {
                    return 'stampedBackground'
                    //Ha a jelenlegi elemnél nagyobb vagy egyenlő az aktuális pecsétszám és már adtunk rá további pecsétet és nem reward egyben
                } else if (redeem.redeemData.loyaltyCard.currentNrOfStamps >= item
                    && $scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.currentNrOfStamps < redeem.redeemData.loyaltyCard.currentNrOfStamps
                    && redeem.redeemData.loyaltyCard.reward1NrOfStampsNeeded != item && redeem.redeemData.loyaltyCard.reward2NrOfStampsNeeded != item
                    && redeem.redeemData.loyaltyCard.reward3NrOfStampsNeeded != item && redeem.redeemData.loyaltyCard.reward4NrOfStampsNeeded != item
                    && redeem.redeemData.loyaltyCard.reward5NrOfStampsNeeded != item) {
                    return 'activeStampedBackground'
                } else if ($scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.currentNrOfStamps > item
                    || $scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.currentNrOfStamps == item
                    || (redeem.redeemData.loyaltyCard.reward1NrOfStampsNeeded == item && redeem.redeemData.loyaltyCard.isRewardRedeemed1 == true)
                    || (redeem.redeemData.loyaltyCard.reward2NrOfStampsNeeded == item && redeem.redeemData.loyaltyCard.isRewardRedeemed2 == true)
                    || (redeem.redeemData.loyaltyCard.reward3NrOfStampsNeeded == item && redeem.redeemData.loyaltyCard.isRewardRedeemed3 == true)
                    || (redeem.redeemData.loyaltyCard.reward4NrOfStampsNeeded == item && redeem.redeemData.loyaltyCard.isRewardRedeemed4 == true)
                    || (redeem.redeemData.loyaltyCard.reward5NrOfStampsNeeded == item && redeem.redeemData.loyaltyCard.isRewardRedeemed5 == true)
                    && redeem.redeemData.loyaltyCard.isCurrentRewardRedeemed == true) {
                    return 'activeStampedBackground'
                } else if (redeem.redeemData.loyaltyCard.currentNrOfStamps == item
                    && $scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.currentNrOfStamps < redeem.redeemData.loyaltyCard.currentNrOfStamps
                    && ((redeem.redeemData.loyaltyCard.reward1NrOfStampsNeeded == item && redeem.redeemData.loyaltyCard.isRewardRedeemed1 == false)
                        || (redeem.redeemData.loyaltyCard.reward2NrOfStampsNeeded == item && redeem.redeemData.loyaltyCard.isRewardRedeemed2 == false)
                        || (redeem.redeemData.loyaltyCard.reward3NrOfStampsNeeded == item && redeem.redeemData.loyaltyCard.isRewardRedeemed3 == false)
                        || (redeem.redeemData.loyaltyCard.reward4NrOfStampsNeeded == item && redeem.redeemData.loyaltyCard.isRewardRedeemed4 == false)
                        || (redeem.redeemData.loyaltyCard.reward5NrOfStampsNeeded == item && redeem.redeemData.loyaltyCard.isRewardRedeemed5 == false))) {
                    return 'activeStampedBackground'
                } else if (redeem.redeemData.loyaltyCard.currentNrOfStamps >= item
                    && $scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.currentNrOfStamps < redeem.redeemData.loyaltyCard.currentNrOfStamps
                    && (redeem.redeemData.loyaltyCard.reward1NrOfStampsNeeded == item || redeem.redeemData.loyaltyCard.reward2NrOfStampsNeeded == item
                        || redeem.redeemData.loyaltyCard.reward3NrOfStampsNeeded == item || redeem.redeemData.loyaltyCard.reward4NrOfStampsNeeded == item
                        || redeem.redeemData.loyaltyCard.reward5NrOfStampsNeeded == item)) {
                    return 'activeStampedBackground'
                } else if (redeem.redeemData.loyaltyCard.currentNrOfStamps < item
                    || $scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.currentNrOfStamps > redeem.redeemData.loyaltyCard.currentNrOfStamps) {
                    return 'unstampedBackground'
                }
            }
        }

        $scope.getGiftBackgroundClass = function (redeem, item) {
            var index = $scope.loyaltycardRequestsOriginal.findIndex(x => angular.equals(x.key, redeem.key))
            if (index != -1) {
                if (redeem.redeemData.loyaltyCard.canBeRedeemedLater == false) {
                    //Ha a jelenlegi elemnél nagyobb vagy egyenlő a jelenlegi pecsétszám és (a jelenlegi reward megkapva vagy nem rewardon áll)
                    if ($scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.currentNrOfStamps > item
                        || $scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.currentNrOfStamps == item
                        && ($scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.isCurrentRewardRedeemed == true
                            || $scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.isCurrentRewardRedeemed == null)) {
                        return 'giftStampedBackground'
                        //Ha a jelenlegi elem megegyezik a jelenlegi pecsét számmal, ami egyben reward is, de az még nincs beváltva
                    } else if ($scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.currentNrOfStamps == item
                        && redeem.redeemData.loyaltyCard.isCurrentRewardRedeemed == false) {
                        return 'giftUnstampedBackground'
                        //Ha a jelenlegi elemnél nagyobb vagy egyenlő az aktuális pecsétszám és már adtunk rá további pecsétet és nem reward egyben
                    } else if (redeem.redeemData.loyaltyCard.currentNrOfStamps >= item
                        && $scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.currentNrOfStamps < redeem.redeemData.loyaltyCard.currentNrOfStamps
                        && redeem.redeemData.loyaltyCard.reward1NrOfStampsNeeded != item && redeem.redeemData.loyaltyCard.reward2NrOfStampsNeeded != item
                        && redeem.redeemData.loyaltyCard.reward3NrOfStampsNeeded != item && redeem.redeemData.loyaltyCard.reward4NrOfStampsNeeded != item
                        && redeem.redeemData.loyaltyCard.reward5NrOfStampsNeeded != item) {
                        return 'giftActiveStampedBackground'
                    } else if ($scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.currentNrOfStamps > item
                        || $scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.currentNrOfStamps == item
                        || (redeem.redeemData.loyaltyCard.reward1NrOfStampsNeeded == item && redeem.redeemData.loyaltyCard.isRewardRedeemed1 == true)
                        || (redeem.redeemData.loyaltyCard.reward2NrOfStampsNeeded == item && redeem.redeemData.loyaltyCard.isRewardRedeemed2 == true)
                        || (redeem.redeemData.loyaltyCard.reward3NrOfStampsNeeded == item && redeem.redeemData.loyaltyCard.isRewardRedeemed3 == true)
                        || (redeem.redeemData.loyaltyCard.reward4NrOfStampsNeeded == item && redeem.redeemData.loyaltyCard.isRewardRedeemed4 == true)
                        || (redeem.redeemData.loyaltyCard.reward5NrOfStampsNeeded == item && redeem.redeemData.loyaltyCard.isRewardRedeemed5 == true)
                        && redeem.redeemData.loyaltyCard.isCurrentRewardRedeemed == true) {
                        return 'giftActiveStampedBackground'
                    } else if (redeem.redeemData.loyaltyCard.currentNrOfStamps == item
                        && $scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.currentNrOfStamps < redeem.redeemData.loyaltyCard.currentNrOfStamps
                        && ((redeem.redeemData.loyaltyCard.reward1NrOfStampsNeeded == item && redeem.redeemData.loyaltyCard.isRewardRedeemed1 == false)
                            || (redeem.redeemData.loyaltyCard.reward2NrOfStampsNeeded == item && redeem.redeemData.loyaltyCard.isRewardRedeemed2 == false)
                            || (redeem.redeemData.loyaltyCard.reward3NrOfStampsNeeded == item && redeem.redeemData.loyaltyCard.isRewardRedeemed3 == false)
                            || (redeem.redeemData.loyaltyCard.reward4NrOfStampsNeeded == item && redeem.redeemData.loyaltyCard.isRewardRedeemed4 == false)
                            || (redeem.redeemData.loyaltyCard.reward5NrOfStampsNeeded == item && redeem.redeemData.loyaltyCard.isRewardRedeemed5 == false))) {
                        //return 'activeStampedUnredeemedBackground'
                        return 'giftUnstampedBackground'
                    } else if (redeem.redeemData.loyaltyCard.currentNrOfStamps >= item
                        && $scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.currentNrOfStamps < redeem.redeemData.loyaltyCard.currentNrOfStamps
                        && (redeem.redeemData.loyaltyCard.reward1NrOfStampsNeeded == item || redeem.redeemData.loyaltyCard.reward2NrOfStampsNeeded == item
                            || redeem.redeemData.loyaltyCard.reward3NrOfStampsNeeded == item || redeem.redeemData.loyaltyCard.reward4NrOfStampsNeeded == item
                            || redeem.redeemData.loyaltyCard.reward5NrOfStampsNeeded == item)) {
                        return 'giftActiveStampedBackground'
                    } else if (redeem.redeemData.loyaltyCard.currentNrOfStamps < item
                        || $scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.currentNrOfStamps > redeem.redeemData.loyaltyCard.currentNrOfStamps) {
                        return 'giftUnstampedBackground'
                    }
                } else {
                    if (item == redeem.redeemData.loyaltyCard.reward1NrOfStampsNeeded && redeem.redeemData.loyaltyCard.isRewardRedeemed1 == true) {
                        return 'giftStampedBackground'
                    } else if (item == redeem.redeemData.loyaltyCard.reward2NrOfStampsNeeded && redeem.redeemData.loyaltyCard.isRewardRedeemed2 == true) {
                        return 'giftStampedBackground'
                    } else if (item == redeem.redeemData.loyaltyCard.reward3NrOfStampsNeeded && redeem.redeemData.loyaltyCard.isRewardRedeemed3 == true) {
                        return 'giftStampedBackground'
                    } else if (item == redeem.redeemData.loyaltyCard.reward4NrOfStampsNeeded && redeem.redeemData.loyaltyCard.isRewardRedeemed4 == true) {
                        return 'giftStampedBackground'
                    } else if (item == redeem.redeemData.loyaltyCard.reward5NrOfStampsNeeded && redeem.redeemData.loyaltyCard.isRewardRedeemed5 == true) {
                        return 'giftStampedBackground'
                    } else {
                        if (redeem.redeemData.loyaltyCard.nrOfRedeemableRewardsPerRewardTypes == 1 || redeem.redeemData.loyaltyCard.nrOfRedeemableRewardsAtOnce == 1) {
                            if (redeem.redeemData.loyaltyCard.mustBeRedeemedLater == true) {
                                if (item == redeem.redeemData.loyaltyCard.reward1NrOfStampsNeeded && redeem.redeemData.loyaltyCard.currentNrOfStamps >= redeem.redeemData.loyaltyCard.reward1NrOfStampsNeeded && redeem.redeemData.loyaltyCard.nrOfCollectedReward1 == 1 && redeem.redeemData.loyaltyCard.nrOfReward1CollectedNow == 0 && redeem.redeemData.loyaltyCard.reward1RedeemRequested == true && redeem.redeemData.loyaltyCard.isNewCardCreated != true) {
                                    return 'giftActiveStampedBackground'
                                } else if (item == redeem.redeemData.loyaltyCard.reward2NrOfStampsNeeded && redeem.redeemData.loyaltyCard.currentNrOfStamps >= redeem.redeemData.loyaltyCard.reward2NrOfStampsNeeded && redeem.redeemData.loyaltyCard.nrOfCollectedReward2 == 1 && redeem.redeemData.loyaltyCard.nrOfReward2CollectedNow == 0 && redeem.redeemData.loyaltyCard.reward2RedeemRequested == true && redeem.redeemData.loyaltyCard.isNewCardCreated != true) {
                                    return 'giftActiveStampedBackground'
                                } else if (item == redeem.redeemData.loyaltyCard.reward3NrOfStampsNeeded && redeem.redeemData.loyaltyCard.currentNrOfStamps >= redeem.redeemData.loyaltyCard.reward3NrOfStampsNeeded && redeem.redeemData.loyaltyCard.nrOfCollectedReward3 == 1 && redeem.redeemData.loyaltyCard.nrOfReward3CollectedNow == 0 && redeem.redeemData.loyaltyCard.reward3RedeemRequested == true && redeem.redeemData.loyaltyCard.isNewCardCreated != true) {
                                    return 'giftActiveStampedBackground'
                                } else if (item == redeem.redeemData.loyaltyCard.reward4NrOfStampsNeeded && redeem.redeemData.loyaltyCard.currentNrOfStamps >= redeem.redeemData.loyaltyCard.reward4NrOfStampsNeeded && redeem.redeemData.loyaltyCard.nrOfCollectedReward4 == 1 && redeem.redeemData.loyaltyCard.nrOfReward4CollectedNow == 0 && redeem.redeemData.loyaltyCard.reward4RedeemRequested == true && redeem.redeemData.loyaltyCard.isNewCardCreated != true) {
                                    return 'giftActiveStampedBackground'
                                } else if (item == redeem.redeemData.loyaltyCard.reward5NrOfStampsNeeded && redeem.redeemData.loyaltyCard.currentNrOfStamps >= redeem.redeemData.loyaltyCard.reward5NrOfStampsNeeded && redeem.redeemData.loyaltyCard.nrOfCollectedReward5 == 1 && redeem.redeemData.loyaltyCard.nrOfReward5CollectedNow == 0 && redeem.redeemData.loyaltyCard.reward5RedeemRequested == true && redeem.redeemData.loyaltyCard.isNewCardCreated != true) {
                                    return 'giftActiveStampedBackground'
                                } else if ($scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.currentNrOfStamps < item && item == redeem.redeemData.loyaltyCard.reward1NrOfStampsNeeded && redeem.redeemData.loyaltyCard.isRewardRedeemed1 != true && redeem.redeemData.loyaltyCard.currentNrOfStamps >= redeem.redeemData.loyaltyCard.reward1NrOfStampsNeeded) {
                                    return 'giftCollectedBackground'
                                } else if ($scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.currentNrOfStamps < item && item == redeem.redeemData.loyaltyCard.reward2NrOfStampsNeeded && redeem.redeemData.loyaltyCard.isRewardRedeemed2 != true && redeem.redeemData.loyaltyCard.currentNrOfStamps >= redeem.redeemData.loyaltyCard.reward2NrOfStampsNeeded) {
                                    return 'giftCollectedBackground'
                                } else if ($scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.currentNrOfStamps < item && item == redeem.redeemData.loyaltyCard.reward3NrOfStampsNeeded && redeem.redeemData.loyaltyCard.isRewardRedeemed3 != true && redeem.redeemData.loyaltyCard.currentNrOfStamps >= redeem.redeemData.loyaltyCard.reward3NrOfStampsNeeded) {
                                    return 'giftCollectedBackground'
                                } else if ($scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.currentNrOfStamps < item && item == redeem.redeemData.loyaltyCard.reward4NrOfStampsNeeded && redeem.redeemData.loyaltyCard.isRewardRedeemed4 != true && redeem.redeemData.loyaltyCard.currentNrOfStamps >= redeem.redeemData.loyaltyCard.reward4NrOfStampsNeeded) {
                                    return 'giftCollectedBackground'
                                } else if ($scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.currentNrOfStamps < item && item == redeem.redeemData.loyaltyCard.reward5NrOfStampsNeeded && redeem.redeemData.loyaltyCard.isRewardRedeemed5 != true && redeem.redeemData.loyaltyCard.currentNrOfStamps >= redeem.redeemData.loyaltyCard.reward5NrOfStampsNeeded) {
                                    return 'giftCollectedBackground'
                                } else {
                                    return 'giftUnstampedBackground'
                                }
                            } else { //mustBeRedeemedLater == false
                                if (item == redeem.redeemData.loyaltyCard.reward1NrOfStampsNeeded && redeem.redeemData.loyaltyCard.currentNrOfStamps >= redeem.redeemData.loyaltyCard.reward1NrOfStampsNeeded && ((redeem.redeemData.loyaltyCard.nrOfCollectedReward1 == 1 && redeem.redeemData.loyaltyCard.nrOfReward1CollectedNow == 0) || (redeem.redeemData.loyaltyCard.nrOfCollectedReward1 == 0 && redeem.redeemData.loyaltyCard.nrOfReward1CollectedNow == 1)) && redeem.redeemData.loyaltyCard.reward1RedeemRequested == true) {
                                    return 'giftActiveStampedBackground'
                                } else if (item == redeem.redeemData.loyaltyCard.reward2NrOfStampsNeeded && redeem.redeemData.loyaltyCard.currentNrOfStamps >= redeem.redeemData.loyaltyCard.reward2NrOfStampsNeeded && ((redeem.redeemData.loyaltyCard.nrOfCollectedReward2 == 1 && redeem.redeemData.loyaltyCard.nrOfReward2CollectedNow == 0) || (redeem.redeemData.loyaltyCard.nrOfCollectedReward2 == 0 && redeem.redeemData.loyaltyCard.nrOfReward2CollectedNow == 1)) && redeem.redeemData.loyaltyCard.reward2RedeemRequested == true) {
                                    return 'giftActiveStampedBackground'
                                } else if (item == redeem.redeemData.loyaltyCard.reward3NrOfStampsNeeded && redeem.redeemData.loyaltyCard.currentNrOfStamps >= redeem.redeemData.loyaltyCard.reward3NrOfStampsNeeded && ((redeem.redeemData.loyaltyCard.nrOfCollectedReward3 == 1 && redeem.redeemData.loyaltyCard.nrOfReward3CollectedNow == 0) || (redeem.redeemData.loyaltyCard.nrOfCollectedReward3 == 0 && redeem.redeemData.loyaltyCard.nrOfReward3CollectedNow == 1)) && redeem.redeemData.loyaltyCard.reward3RedeemRequested == true) {
                                    return 'giftActiveStampedBackground'
                                } else if (item == redeem.redeemData.loyaltyCard.reward4NrOfStampsNeeded && redeem.redeemData.loyaltyCard.currentNrOfStamps >= redeem.redeemData.loyaltyCard.reward4NrOfStampsNeeded && ((redeem.redeemData.loyaltyCard.nrOfCollectedReward4 == 1 && redeem.redeemData.loyaltyCard.nrOfReward4CollectedNow == 0) || (redeem.redeemData.loyaltyCard.nrOfCollectedReward4 == 0 && redeem.redeemData.loyaltyCard.nrOfReward4CollectedNow == 1)) && redeem.redeemData.loyaltyCard.reward4RedeemRequested == true) {
                                    return 'giftActiveStampedBackground'
                                } else if (item == redeem.redeemData.loyaltyCard.reward5NrOfStampsNeeded && redeem.redeemData.loyaltyCard.currentNrOfStamps >= redeem.redeemData.loyaltyCard.reward5NrOfStampsNeeded && ((redeem.redeemData.loyaltyCard.nrOfCollectedReward5 == 1 && redeem.redeemData.loyaltyCard.nrOfReward5CollectedNow == 0) || (redeem.redeemData.loyaltyCard.nrOfCollectedReward5 == 0 && redeem.redeemData.loyaltyCard.nrOfReward5CollectedNow == 1)) && redeem.redeemData.loyaltyCard.reward5RedeemRequested == true) {
                                    return 'giftActiveStampedBackground'
                                } else if ($scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.currentNrOfStamps < item && item == redeem.redeemData.loyaltyCard.reward1NrOfStampsNeeded && redeem.redeemData.loyaltyCard.isRewardRedeemed1 != true && redeem.redeemData.loyaltyCard.currentNrOfStamps >= redeem.redeemData.loyaltyCard.reward1NrOfStampsNeeded) {
                                    return 'giftCollectedBackground'
                                } else if ($scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.currentNrOfStamps < item && item == redeem.redeemData.loyaltyCard.reward2NrOfStampsNeeded && redeem.redeemData.loyaltyCard.isRewardRedeemed2 != true && redeem.redeemData.loyaltyCard.currentNrOfStamps >= redeem.redeemData.loyaltyCard.reward2NrOfStampsNeeded) {
                                    return 'giftCollectedBackground'
                                } else if ($scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.currentNrOfStamps < item && item == redeem.redeemData.loyaltyCard.reward3NrOfStampsNeeded && redeem.redeemData.loyaltyCard.isRewardRedeemed3 != true && redeem.redeemData.loyaltyCard.currentNrOfStamps >= redeem.redeemData.loyaltyCard.reward3NrOfStampsNeeded) {
                                    return 'giftCollectedBackground'
                                } else if ($scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.currentNrOfStamps < item && item == redeem.redeemData.loyaltyCard.reward4NrOfStampsNeeded && redeem.redeemData.loyaltyCard.isRewardRedeemed4 != true && redeem.redeemData.loyaltyCard.currentNrOfStamps >= redeem.redeemData.loyaltyCard.reward4NrOfStampsNeeded) {
                                    return 'giftCollectedBackground'
                                } else if ($scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.currentNrOfStamps < item && item == redeem.redeemData.loyaltyCard.reward5NrOfStampsNeeded && redeem.redeemData.loyaltyCard.isRewardRedeemed5 != true && redeem.redeemData.loyaltyCard.currentNrOfStamps >= redeem.redeemData.loyaltyCard.reward5NrOfStampsNeeded) {
                                    return 'giftCollectedBackground'
                                } else {
                                    return 'giftUnstampedBackground'
                                }
                            }
                        } else { //(nrOfRedeemableRewardsPerRewardTypes > 1 || nrOfRedeemableRewardsPerRewardTypes == 0) && (nrOfRedeemableRewardsAtOnce > 1 || nrOfRedeemableRewardsAtOnce == 0)
                            if (redeem.redeemData.loyaltyCard.mustBeRedeemedLater == true) {
                                if (item == redeem.redeemData.loyaltyCard.reward1NrOfStampsNeeded && redeem.redeemData.loyaltyCard.currentNrOfStamps >= redeem.redeemData.loyaltyCard.reward1NrOfStampsNeeded && (redeem.redeemData.loyaltyCard.nrOfCollectedReward1 + redeem.redeemData.loyaltyCard.nrOfReward1CollectedNow == redeem.redeemData.loyaltyCard.nrOfReward1Redeems) && redeem.redeemData.loyaltyCard.isNewCardCreated != true) {
                                    return 'giftActiveStampedBackground'
                                } else if (item == redeem.redeemData.loyaltyCard.reward2NrOfStampsNeeded && redeem.redeemData.loyaltyCard.currentNrOfStamps >= redeem.redeemData.loyaltyCard.reward2NrOfStampsNeeded && (redeem.redeemData.loyaltyCard.nrOfCollectedReward2 + redeem.redeemData.loyaltyCard.nrOfReward2CollectedNow == redeem.redeemData.loyaltyCard.nrOfReward2Redeems) && redeem.redeemData.loyaltyCard.isNewCardCreated != true) {
                                    return 'giftActiveStampedBackground'
                                } else if (item == redeem.redeemData.loyaltyCard.reward3NrOfStampsNeeded && redeem.redeemData.loyaltyCard.currentNrOfStamps >= redeem.redeemData.loyaltyCard.reward3NrOfStampsNeeded && (redeem.redeemData.loyaltyCard.nrOfCollectedReward3 + redeem.redeemData.loyaltyCard.nrOfReward3CollectedNow == redeem.redeemData.loyaltyCard.nrOfReward3Redeems) && redeem.redeemData.loyaltyCard.isNewCardCreated != true) {
                                    return 'giftActiveStampedBackground'
                                } else if (item == redeem.redeemData.loyaltyCard.reward4NrOfStampsNeeded && redeem.redeemData.loyaltyCard.currentNrOfStamps >= redeem.redeemData.loyaltyCard.reward4NrOfStampsNeeded && (redeem.redeemData.loyaltyCard.nrOfCollectedReward4 + redeem.redeemData.loyaltyCard.nrOfReward4CollectedNow == redeem.redeemData.loyaltyCard.nrOfReward4Redeems) && redeem.redeemData.loyaltyCard.isNewCardCreated != true) {
                                    return 'giftActiveStampedBackground'
                                } else if (item == redeem.redeemData.loyaltyCard.reward5NrOfStampsNeeded && redeem.redeemData.loyaltyCard.currentNrOfStamps >= redeem.redeemData.loyaltyCard.reward5NrOfStampsNeeded && (redeem.redeemData.loyaltyCard.nrOfCollectedReward5 + redeem.redeemData.loyaltyCard.nrOfReward5CollectedNow == redeem.redeemData.loyaltyCard.nrOfReward5Redeems) && redeem.redeemData.loyaltyCard.isNewCardCreated != true) {
                                    return 'giftActiveStampedBackground'
                                } else if ($scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.currentNrOfStamps < item && item == redeem.redeemData.loyaltyCard.reward1NrOfStampsNeeded && redeem.redeemData.loyaltyCard.isRewardRedeemed1 != true && redeem.redeemData.loyaltyCard.currentNrOfStamps >= redeem.redeemData.loyaltyCard.reward1NrOfStampsNeeded) {
                                    return 'giftCollectedBackground'
                                } else if ($scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.currentNrOfStamps < item && item == redeem.redeemData.loyaltyCard.reward2NrOfStampsNeeded && redeem.redeemData.loyaltyCard.isRewardRedeemed2 != true && redeem.redeemData.loyaltyCard.currentNrOfStamps >= redeem.redeemData.loyaltyCard.reward2NrOfStampsNeeded) {
                                    return 'giftCollectedBackground'
                                } else if ($scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.currentNrOfStamps < item && item == redeem.redeemData.loyaltyCard.reward3NrOfStampsNeeded && redeem.redeemData.loyaltyCard.isRewardRedeemed3 != true && redeem.redeemData.loyaltyCard.currentNrOfStamps >= redeem.redeemData.loyaltyCard.reward3NrOfStampsNeeded) {
                                    return 'giftCollectedBackground'
                                } else if ($scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.currentNrOfStamps < item && item == redeem.redeemData.loyaltyCard.reward4NrOfStampsNeeded && redeem.redeemData.loyaltyCard.isRewardRedeemed4 != true && redeem.redeemData.loyaltyCard.currentNrOfStamps >= redeem.redeemData.loyaltyCard.reward4NrOfStampsNeeded) {
                                    return 'giftCollectedBackground'
                                } else if ($scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.currentNrOfStamps < item && item == redeem.redeemData.loyaltyCard.reward5NrOfStampsNeeded && redeem.redeemData.loyaltyCard.isRewardRedeemed5 != true && redeem.redeemData.loyaltyCard.currentNrOfStamps >= redeem.redeemData.loyaltyCard.reward5NrOfStampsNeeded) {
                                    return 'giftCollectedBackground'
                                } else {
                                    return 'giftUnstampedBackground'
                                }
                            } else {
                                if (item == redeem.redeemData.loyaltyCard.reward1NrOfStampsNeeded && redeem.redeemData.loyaltyCard.currentNrOfStamps >= redeem.redeemData.loyaltyCard.reward1NrOfStampsNeeded && (redeem.redeemData.loyaltyCard.nrOfCollectedReward1 + redeem.redeemData.loyaltyCard.nrOfReward1CollectedNow == redeem.redeemData.loyaltyCard.nrOfReward1Redeems)) {
                                    return 'giftActiveStampedBackground'
                                } else if (item == redeem.redeemData.loyaltyCard.reward2NrOfStampsNeeded && redeem.redeemData.loyaltyCard.currentNrOfStamps >= redeem.redeemData.loyaltyCard.reward2NrOfStampsNeeded && (redeem.redeemData.loyaltyCard.nrOfCollectedReward2 + redeem.redeemData.loyaltyCard.nrOfReward2CollectedNow == redeem.redeemData.loyaltyCard.nrOfReward2Redeems)) {
                                    return 'giftActiveStampedBackground'
                                } else if (item == redeem.redeemData.loyaltyCard.reward3NrOfStampsNeeded && redeem.redeemData.loyaltyCard.currentNrOfStamps >= redeem.redeemData.loyaltyCard.reward3NrOfStampsNeeded && (redeem.redeemData.loyaltyCard.nrOfCollectedReward3 + redeem.redeemData.loyaltyCard.nrOfReward3CollectedNow == redeem.redeemData.loyaltyCard.nrOfReward3Redeems)) {
                                    return 'giftActiveStampedBackground'
                                } else if (item == redeem.redeemData.loyaltyCard.reward4NrOfStampsNeeded && redeem.redeemData.loyaltyCard.currentNrOfStamps >= redeem.redeemData.loyaltyCard.reward4NrOfStampsNeeded && (redeem.redeemData.loyaltyCard.nrOfCollectedReward4 + redeem.redeemData.loyaltyCard.nrOfReward4CollectedNow == redeem.redeemData.loyaltyCard.nrOfReward4Redeems)) {
                                    return 'giftActiveStampedBackground'
                                } else if (item == redeem.redeemData.loyaltyCard.reward5NrOfStampsNeeded && redeem.redeemData.loyaltyCard.currentNrOfStamps >= redeem.redeemData.loyaltyCard.reward5NrOfStampsNeeded && (redeem.redeemData.loyaltyCard.nrOfCollectedReward5 + redeem.redeemData.loyaltyCard.nrOfReward5CollectedNow == redeem.redeemData.loyaltyCard.nrOfReward5Redeems)) {
                                    return 'giftActiveStampedBackground'
                                } else if ($scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.currentNrOfStamps < item && item == redeem.redeemData.loyaltyCard.reward1NrOfStampsNeeded && redeem.redeemData.loyaltyCard.isRewardRedeemed1 != true && redeem.redeemData.loyaltyCard.currentNrOfStamps >= redeem.redeemData.loyaltyCard.reward1NrOfStampsNeeded) {
                                    return 'giftCollectedBackground'
                                } else if ($scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.currentNrOfStamps < item && item == redeem.redeemData.loyaltyCard.reward2NrOfStampsNeeded && redeem.redeemData.loyaltyCard.isRewardRedeemed2 != true && redeem.redeemData.loyaltyCard.currentNrOfStamps >= redeem.redeemData.loyaltyCard.reward2NrOfStampsNeeded) {
                                    return 'giftCollectedBackground'
                                } else if ($scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.currentNrOfStamps < item && item == redeem.redeemData.loyaltyCard.reward3NrOfStampsNeeded && redeem.redeemData.loyaltyCard.isRewardRedeemed3 != true && redeem.redeemData.loyaltyCard.currentNrOfStamps >= redeem.redeemData.loyaltyCard.reward3NrOfStampsNeeded) {
                                    return 'giftCollectedBackground'
                                } else if ($scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.currentNrOfStamps < item && item == redeem.redeemData.loyaltyCard.reward4NrOfStampsNeeded && redeem.redeemData.loyaltyCard.isRewardRedeemed4 != true && redeem.redeemData.loyaltyCard.currentNrOfStamps >= redeem.redeemData.loyaltyCard.reward4NrOfStampsNeeded) {
                                    return 'giftCollectedBackground'
                                } else if ($scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.currentNrOfStamps < item && item == redeem.redeemData.loyaltyCard.reward5NrOfStampsNeeded && redeem.redeemData.loyaltyCard.isRewardRedeemed5 != true && redeem.redeemData.loyaltyCard.currentNrOfStamps >= redeem.redeemData.loyaltyCard.reward5NrOfStampsNeeded) {
                                    return 'giftCollectedBackground'
                                } else {
                                    return 'giftUnstampedBackground'
                                }
                            }
                        }
                    }
                }
            }
        }

        $scope.restoreLoyaltyCard = function (redeem) {
            var index = $scope.loyaltycardRequestsOriginal.findIndex(x => angular.equals(x.key, redeem.key))
            var redeemIndex = $scope.redeemRequests.findIndex(x => angular.equals(x.key, redeem.key) && x.redeemType == 2)
            if (index != -1 && redeemIndex != -1) {
                $scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.currentNrOfStamps = $scope.loyaltycardRequestsOriginal[index].redeemData.loyaltyCard.originalNrOfStamps;
                $scope.redeemRequests[redeemIndex] = $.extend(true, {}, $scope.loyaltycardRequestsOriginal[index]);
                $scope.redeemRequests[redeemIndex].showDetail = true;
            }
        }

        $window.addEventListener("offline", function () {
            $scope.$apply(function () {
                console.log('offline event');
                $scope.isReconnectionWaitingBorderOn = false;
                $scope.current.status = "Nincs internetkapcsolat. Várakozás az internetkapcsolat helyreállására ...";
                $scope.current.statusCode = 2
                $scope.current.isConnected = false;
                $scope.offlineTimestamp = new Date().toISOString()
                $scope.redeemRequests = $scope.redeemRequests.filter(redeem => redeem.status != 'ÚJ')
            });
        }, false);

        $window.addEventListener("online", function () {
            $scope.$apply(function () {
                console.log('online event');
                console.log('closeConn called from online event');
                RedeemService.closeConn('Online', '', $scope.offlineTimestamp);
                console.log('callRedeemService called from online event');
                callRedeemService();
                //$scope.current.status = "Az oldal folyamatosan fogadja a frissítéseket és az új beváltásokat.";
                //$scope.current.isConnected = true;
            });
        }, false);

        $scope.msg = {};
        $scope.isDetail = {};
        $scope.isOpened = "fa fa-chevron-down";
        $scope.redeemDate = {};
        $scope.redeemCanceled = false;
        $scope.statusText = "";
        $scope.names = [{ id: '1', value: 'kedvezmény', checked: false, style: '%', string: false },
        { id: '2', value: 'kedvezmény', checked: false, style: ' FT', string: false },
        { id: '3', value: '', checked: true, style: ' FT', string: false },
        { id: '4', value: 'Akciós ár', checked: false, style: ' FT', string: false },
        { id: '5', value: 'Ajándék', checked: false, style: '', string: true },
        { id: '6', value: '', checked: false, style: '', string: true },
        { id: '7', value: '', checked: false, style: '', string: false }];
        $scope.discount = {
            "font-size": "12px",
            "font-weight": "bold",
            "text-align": "left",
            "text-transform": "uppercase",
            "margin": "0px"
        };
        $scope.oldDiscount = {
            "text-decoration": "line-through",
            "font-size": "15px",
            "text-align": "left",
            "margin": "0px"
        };
        $scope.style = {
            "color": "coral",
            "font-size": "200%",
            "text-align": "center",
            "margin": "0px"
        };
        $scope.select = {
            "font-size": "70%",
            "font-weight": "bold",
            "text-align": "center",
            "margin": "0px",
            "text-transform": "uppercase"
        };

        $scope.sendRedeemResponse = function (cashing, res) {
            var response = {};
            response.key = cashing.key;
            let index = $scope.redeemRequests.findIndex(x => angular.equals(x.key, cashing.key))
            $scope.redeemRequests[index].loading = true;
            response.couponId = cashing.redeemData.coupon.id;
            if (cashing.redeemData.user.userName == "") {
                response.anonym = 1;
            } else {
                response.anonym = 0;
            }
            response.companyStoreId = cashing.redeemData.other.companyStoreId;
            response.redeemGeofenceDistFromStore = cashing.redeemData.other.redeemGeofenceDistFromStore;
            response.redeemAccepted = res;
            response.handlerName = $cookies.get("nameTitle") != "null" ?
                $cookies.get("nameTitle") + " " + $cookies.get("lastName") + " " + $cookies.get("firstName") :
                $cookies.get("lastName") + " " + $cookies.get("firstName")
            response.handlerEmail = $cookies.get("userEmail")
            response.handlerId = $scope.tabID;
            $http.post(API_PREFIX.url + "/coupon/partnerRedeemResponse", response, function (result) {
            }, function () {
                $scope.redeemRequests[index].loading = false;
            });
            updateSession()
        }
        function updateSession() {
            AuthService.isUserAuthorized({ state: $state.current.name }).then(function (result) {
                if (result.authorized === true && result.errorCode === 0) {
                    AuthService.updateSessionData(result)
                } else if (result.errorCode == 2) {
                    /*const alert = $mdDialog.alert()
                        .title('Lejárt a belépési időkorlát')
                        .textContent('Az időkorlát leteltével kiléptetjük az oldalról.')
                        .ok('Rendben')
                    $mdDialog.show(alert).then(function () {*/
                    $rootScope.isDataSheetModified = false;
                    AuthService.logout("logout");
                    //});
                }
            })
        }

        function callRedeemService() {
            console.log('openConn called from callRedeemService');
            RedeemService.openConn();

            RedeemService.isConnOpened(function (response) {
                console.log(response.type);
                if (response.type == "open") {
                    $scope.$apply(function () {
                        $scope.current.status = "Az oldal folyamatosan fogadja a frissítéseket és az új beváltásokat.";
                        $scope.current.statusCode = 1
                        $scope.current.isConnected = true;
                        let exceptionString = {
                            type: response.type,
                            target: {
                                onerror: response.target.onerror,
                                onmessage: response.target.onmessage,
                                onopen: response.target.onopen,
                                readyState: response.target.readyState,
                                url: response.target.url,
                                withCredentials: response.target.withCredentials
                            },
                            currentTarget: {
                                onerror: response.currentTarget.onerror,
                                onmessage: response.currentTarget.onmessage,
                                onopen: response.currentTarget.onopen,
                                readyState: response.currentTarget.readyState,
                                url: response.currentTarget.url,
                                withCredentials: response.currentTarget.withCredentials
                            },
                            eventPhase: response.eventPhase,
                            bubbles: response.bubbles,
                            cancelable: response.cancelable,
                            defaultPrevented: response.defaultPrevented,
                            composed: response.composed,
                            isTrusted: response.isTrusted,
                            timeStamp: response.timeStamp
                        }
                        const request = {}
                        request.sse = {
                            partnerKey: 'unknown',
                            eventName: 'onOpen',
                            exceptionString: JSON.stringify(exceptionString)
                        }
                        $http.post(API_PREFIX.url + "/service/logWebAppFrontendEvent", request, function (result) {
                        }, function () { });
                    });
                } else {
                    const request = {}
                    request.sse = {
                        partnerKey: 'unknown',
                        eventName: 'onOpen',
                        exceptionString: response.type
                    }
                    $http.post(API_PREFIX.url + "/service/logWebAppFrontendEvent", request, function (result) {
                    }, function () { });
                }
            });

            RedeemService.getErrors(function (response) {
                console.log(response.type)
                let errorLogNeeded = true
                if (response.type == "error") {
                    $scope.$apply(function () {
                        $scope.current.isConnected = false;
                        console.log(response.currentTarget.readyState);
                        console.log($scope.current.statusCode);
                        $scope.redeemRequests = $scope.redeemRequests.filter(redeem => redeem.status != 'ÚJ')
                        let exceptionString = {
                            type: response.type,
                            target: {
                                onerror: response.target.onerror,
                                onmessage: response.target.onmessage,
                                onopen: response.target.onopen,
                                readyState: response.target.readyState,
                                url: response.target.url,
                                withCredentials: response.target.withCredentials
                            },
                            currentTarget: {
                                onerror: response.currentTarget.onerror,
                                onmessage: response.currentTarget.onmessage,
                                onopen: response.currentTarget.onopen,
                                readyState: response.currentTarget.readyState,
                                url: response.currentTarget.url,
                                withCredentials: response.currentTarget.withCredentials
                            },
                            eventPhase: response.eventPhase,
                            bubbles: response.bubbles,
                            cancelable: response.cancelable,
                            defaultPrevented: response.defaultPrevented,
                            composed: response.composed,
                            isTrusted: response.isTrusted,
                            timeStamp: response.timeStamp
                        }
                        if (response.currentTarget.readyState == 0 && $scope.current.statusCode != 2) {
                            $scope.current.status = "A kapcsolat átmenetileg megszakadt. Újrakapcsolódás folyamatban ...";
                            $scope.current.statusCode = 3
                            errorLogNeeded = false
                            RedeemService.closeConn('Error', exceptionString);
                            setTimeout(function () {
                                if ($scope.current.statusCode != 2) callRedeemService()
                            }, 2000)
                        } else if (response.currentTarget.readyState == 2) {
                            $scope.isReconnectionWaitingBorderOn = false;
                            $scope.current.status = "Hiba a kapcsolatban. Kérjük frissítse az oldalt vagy próbálja meg később.";
                            $scope.current.statusCode = 4
                        }
                        if (errorLogNeeded) {
                            const request = {}
                            request.sse = {
                                partnerKey: $rootScope.partnerKey,
                                eventName: 'onError',
                                exceptionString: JSON.stringify(exceptionString)
                            }
                            $http.post(API_PREFIX.url + "/service/logWebAppFrontendEvent", request, function (result) {
                            }, function () { });
                        }
                    })
                } else {
                    const request = {}
                    request.sse = {
                        partnerKey: $rootScope.partnerKey,
                        eventName: 'onError',
                        exceptionString: response.type
                    }
                    $http.post(API_PREFIX.url + "/service/logWebAppFrontendEvent", request, function (result) {
                    }, function () { });
                }
            });

            RedeemService.getPartnerKey(function (response) {
                $scope.$apply(function () {
                    var res = JSON.parse(response.data);
                    $rootScope.partnerKey = res.partnerKey;
                });
            });

            RedeemService.getRedeemRequest(function (response) {
                $scope.$apply(function () {
                    var data = JSON.parse(response.data);
                    data.status = "ÚJ";
                    if (data.redeemType == 1) {
                        data.showFinalizeContainer = true;
                        data.showRestoreContainer = false;
                        data.showRejectContainer = false;
                        data.redeemCanceled = false;
                        data.statusText = null;
                        data.actualStatus = { "color": "rgb(84,130,53)" };
                        var year = $filter('date')(data.redeemData.other.redeeemRequestTimestamp, "yyyy");
                        var month = $filter('date')(data.redeemData.other.redeeemRequestTimestamp, "MM");
                        var day = $filter('date')(data.redeemData.other.redeeemRequestTimestamp, "dd");
                        var hour = $filter('date')(data.redeemData.other.redeeemRequestTimestamp, "HH");
                        var min = $filter('date')(data.redeemData.other.redeeemRequestTimestamp, "mm");
                        var monthInHungarian = getMonth(month);
                        data.modifiedDate = year + ". " + monthInHungarian + " " + day + ". ";
                        data.modifiedClock = hour + ":" + min;
                        if (data.redeemData.coupon.discountText == null) {
                            data.redeemData.coupon.discountValue = data.redeemData.coupon.discountAmount;
                        } else if (data.redeemData.coupon.discountAmount == 0) {
                            data.redeemData.coupon.discountValue = data.redeemData.coupon.discountText;
                        } else {
                            data.redeemData.coupon.discountValue = "";
                        }
                        data.redeemData.coupon.promotionType--;
                        if (data.redeemData.user.isAnonymUser == true) {
                            data.redeemData.user.profilePicImgURL = "";
                            data.redeemData.user.lastName = "anonim";
                            data.redeemData.user.firstName = "";
                        }
                    } else if (data.redeemType == 2) {
                        data.showFinalizeContainer = true;
                        data.showRestoreContainer = false;
                        data.showRejectContainer = false;
                        data.redeemCanceled = false;
                        data.statusText = null;
                        data.showDetail = false;
                        data.redeemData.loyaltyCard.originalNrOfStamps = data.redeemData.loyaltyCard.currentNrOfStamps;
                        data.redeemData.loyaltyCard.newStampCount = 0;
                        data.redeemData.loyaltyCard.getReward = 0;
                        data.redeemData.loyaltyCard.newLaterRewards = 0;
                        data.redeemData.loyaltyCard.isNewCardCreated = false;
                        data.redeemData.loyaltyCard.reward1RedeemRequested = false;
                        data.redeemData.loyaltyCard.reward2RedeemRequested = false;
                        data.redeemData.loyaltyCard.reward3RedeemRequested = false;
                        data.redeemData.loyaltyCard.reward4RedeemRequested = false;
                        data.redeemData.loyaltyCard.reward5RedeemRequested = false;
                        data.redeemData.loyaltyCard.nrOfReward1Redeems = 0;
                        data.redeemData.loyaltyCard.nrOfReward2Redeems = 0;
                        data.redeemData.loyaltyCard.nrOfReward3Redeems = 0;
                        data.redeemData.loyaltyCard.nrOfReward4Redeems = 0;
                        data.redeemData.loyaltyCard.nrOfReward5Redeems = 0;
                        data.redeemData.loyaltyCard.nrOfCollectedReward1 = 0;
                        data.redeemData.loyaltyCard.nrOfCollectedReward2 = 0;
                        data.redeemData.loyaltyCard.nrOfCollectedReward3 = 0;
                        data.redeemData.loyaltyCard.nrOfCollectedReward4 = 0;
                        data.redeemData.loyaltyCard.nrOfCollectedReward5 = 0;
                        data.redeemData.loyaltyCard.nrOfReward1CollectedNow = 0;
                        data.redeemData.loyaltyCard.nrOfReward2CollectedNow = 0;
                        data.redeemData.loyaltyCard.nrOfReward3CollectedNow = 0;
                        data.redeemData.loyaltyCard.nrOfReward4CollectedNow = 0;
                        data.redeemData.loyaltyCard.nrOfReward5CollectedNow = 0;
                        data.redeemData.loyaltyCard.YLCSelectedProductsForRedemptions = [];
                        data.redeemData.loyaltyCard.YLCSelectedProductsForReward1Redemptions = [];
                        data.redeemData.loyaltyCard.YLCSelectedProductsForReward2Redemptions = [];
                        data.redeemData.loyaltyCard.YLCSelectedProductsForReward3Redemptions = [];
                        data.redeemData.loyaltyCard.YLCSelectedProductsForReward4Redemptions = [];
                        data.redeemData.loyaltyCard.YLCSelectedProductsForReward5Redemptions = [];
                        data.redeemData.loyaltyCard.YLCSelectedProductsForStampings = [];
                        if (data.redeemData.loyaltyCard.canBeRedeemedLater == true) {
                            for (let i = 0; i < data.redeemData.loyaltyCard.previousNotRedeemedLoyaltyCardYaltyInstances.length; i++) {
                                if (data.redeemData.loyaltyCard.previousNotRedeemedLoyaltyCardYaltyInstances[i].reward1RedeemTimestamp == null) {
                                    data.redeemData.loyaltyCard.nrOfCollectedReward1++;
                                }
                                if (data.redeemData.loyaltyCard.reward2NrOfStampsNeeded != null && data.redeemData.loyaltyCard.previousNotRedeemedLoyaltyCardYaltyInstances[i].reward2RedeemTimestamp == null) {
                                    data.redeemData.loyaltyCard.nrOfCollectedReward2++;
                                }
                                if (data.redeemData.loyaltyCard.reward3NrOfStampsNeeded != null && data.redeemData.loyaltyCard.previousNotRedeemedLoyaltyCardYaltyInstances[i].reward3RedeemTimestamp == null) {
                                    data.redeemData.loyaltyCard.nrOfCollectedReward3++;
                                }
                                if (data.redeemData.loyaltyCard.reward4NrOfStampsNeeded != null && data.redeemData.loyaltyCard.previousNotRedeemedLoyaltyCardYaltyInstances[i].reward4RedeemTimestamp == null) {
                                    data.redeemData.loyaltyCard.nrOfCollectedReward4++;
                                }
                                if (data.redeemData.loyaltyCard.reward5NrOfStampsNeeded != null && data.redeemData.loyaltyCard.previousNotRedeemedLoyaltyCardYaltyInstances[i].reward5RedeemTimestamp == null) {
                                    data.redeemData.loyaltyCard.nrOfCollectedReward5++;
                                }
                            }
                            if (data.redeemData.loyaltyCard.currentNrOfStamps >= data.redeemData.loyaltyCard.reward1NrOfStampsNeeded && data.redeemData.loyaltyCard.reward1RedeemTimestamp == null) {
                                data.redeemData.loyaltyCard.nrOfCollectedReward1++;
                            }
                            if (data.redeemData.loyaltyCard.reward2NrOfStampsNeeded != null && data.redeemData.loyaltyCard.currentNrOfStamps >= data.redeemData.loyaltyCard.reward2NrOfStampsNeeded && data.redeemData.loyaltyCard.reward2RedeemTimestamp == null) {
                                data.redeemData.loyaltyCard.nrOfCollectedReward2++;
                            }
                            if (data.redeemData.loyaltyCard.reward3NrOfStampsNeeded != null && data.redeemData.loyaltyCard.currentNrOfStamps >= data.redeemData.loyaltyCard.reward3NrOfStampsNeeded && data.redeemData.loyaltyCard.reward3RedeemTimestamp == null) {
                                data.redeemData.loyaltyCard.nrOfCollectedReward3++;
                            }
                            if (data.redeemData.loyaltyCard.reward4NrOfStampsNeeded != null && data.redeemData.loyaltyCard.currentNrOfStamps >= data.redeemData.loyaltyCard.reward4NrOfStampsNeeded && data.redeemData.loyaltyCard.reward4RedeemTimestamp == null) {
                                data.redeemData.loyaltyCard.nrOfCollectedReward4++;
                            }
                            if (data.redeemData.loyaltyCard.reward5NrOfStampsNeeded != null && data.redeemData.loyaltyCard.currentNrOfStamps >= data.redeemData.loyaltyCard.reward5NrOfStampsNeeded && data.redeemData.loyaltyCard.reward5RedeemTimestamp == null) {
                                data.redeemData.loyaltyCard.nrOfCollectedReward5++;
                            }
                        }
                        if (data.redeemData.loyaltyCard.YLCPartnerRedeemRemark == null) {
                            data.redeemData.loyaltyCard.partnerRedeemRemarkStyle = emptyMark;
                            data.redeemData.loyaltyCard.YLCPartnerRedeemRemark = '';
                        } else {
                            data.redeemData.loyaltyCard.partnerRedeemRemarkStyle = unmodifiedMark;
                        }
                        if (data.redeemData.loyaltyCard.canBeRedeemedLater == false) {
                            if (data.redeemData.loyaltyCard.currentNrOfStamps > data.redeemData.loyaltyCard.reward1NrOfStampsNeeded
                                || (data.redeemData.loyaltyCard.currentNrOfStamps == data.redeemData.loyaltyCard.reward1NrOfStampsNeeded
                                    && data.redeemData.loyaltyCard.isCurrentRewardRedeemed == true)) {
                                data.redeemData.loyaltyCard.isRewardRedeemed1 = true;
                            } else {
                                data.redeemData.loyaltyCard.isRewardRedeemed1 = false;
                            }
                            if (data.redeemData.loyaltyCard.currentNrOfStamps > data.redeemData.loyaltyCard.reward2NrOfStampsNeeded
                                || (data.redeemData.loyaltyCard.currentNrOfStamps == data.redeemData.loyaltyCard.reward2NrOfStampsNeeded
                                    && data.redeemData.loyaltyCard.isCurrentRewardRedeemed == true)) {
                                data.redeemData.loyaltyCard.isRewardRedeemed2 = true;
                            } else {
                                data.redeemData.loyaltyCard.isRewardRedeemed2 = false;
                            }
                            if (data.redeemData.loyaltyCard.currentNrOfStamps > data.redeemData.loyaltyCard.reward3NrOfStampsNeeded
                                || (data.redeemData.loyaltyCard.currentNrOfStamps == data.redeemData.loyaltyCard.reward3NrOfStampsNeeded
                                    && data.redeemData.loyaltyCard.isCurrentRewardRedeemed == true)) {
                                data.redeemData.loyaltyCard.isRewardRedeemed3 = true;
                            } else {
                                data.redeemData.loyaltyCard.isRewardRedeemed3 = false;
                            }
                            if (data.redeemData.loyaltyCard.currentNrOfStamps > data.redeemData.loyaltyCard.reward4NrOfStampsNeeded
                                || (data.redeemData.loyaltyCard.currentNrOfStamps == data.redeemData.loyaltyCard.reward4NrOfStampsNeeded
                                    && data.redeemData.loyaltyCard.isCurrentRewardRedeemed == true)) {
                                data.redeemData.loyaltyCard.isRewardRedeemed4 = true;
                            } else {
                                data.redeemData.loyaltyCard.isRewardRedeemed4 = false;
                            }
                            if (data.redeemData.loyaltyCard.currentNrOfStamps > data.redeemData.loyaltyCard.reward5NrOfStampsNeeded
                                || (data.redeemData.loyaltyCard.currentNrOfStamps == data.redeemData.loyaltyCard.reward5NrOfStampsNeeded
                                    && data.redeemData.loyaltyCard.isCurrentRewardRedeemed == true)) {
                                data.redeemData.loyaltyCard.isRewardRedeemed5 = true;
                            } else {
                                data.redeemData.loyaltyCard.isRewardRedeemed5 = false;
                            }
                        } else {
                            if (data.redeemData.loyaltyCard.reward1RedeemTimestamp != null) {
                                data.redeemData.loyaltyCard.isRewardRedeemed1 = true;
                            } else {
                                data.redeemData.loyaltyCard.isRewardRedeemed1 = false;
                            }
                            if (data.redeemData.loyaltyCard.reward2RedeemTimestamp != null) {
                                data.redeemData.loyaltyCard.isRewardRedeemed2 = true;
                            } else {
                                data.redeemData.loyaltyCard.isRewardRedeemed2 = false;
                            }
                            if (data.redeemData.loyaltyCard.reward3RedeemTimestamp != null) {
                                data.redeemData.loyaltyCard.isRewardRedeemed3 = true;
                            } else {
                                data.redeemData.loyaltyCard.isRewardRedeemed3 = false;
                            }
                            if (data.redeemData.loyaltyCard.reward4RedeemTimestamp != null) {
                                data.redeemData.loyaltyCard.isRewardRedeemed4 = true;
                            } else {
                                data.redeemData.loyaltyCard.isRewardRedeemed4 = false;
                            }
                            if (data.redeemData.loyaltyCard.reward5RedeemTimestamp != null) {
                                data.redeemData.loyaltyCard.isRewardRedeemed5 = true;
                            } else {
                                data.redeemData.loyaltyCard.isRewardRedeemed5 = false;
                            }
                        }
                        setCurrentAction(data.redeemData.loyaltyCard)
                        setNrOfStampsNeeded(data.redeemData.loyaltyCard)
                        var loyalty = $.extend(true, {}, data);
                        $scope.loyaltycardRequestsOriginal.push(loyalty)
                    }
                    $scope.redeemRequests.push(data)

                    //Ha nincs aktív lap megnyitva, akkor a legutobbi aktívat megnyitjuk
                    if ($scope.isDetail.id == null) {
                        $scope.isDetail.id = data.redeemData.other.redeeemRequestTimestamp;
                    } else {
                        for (var i = 0; i < $scope.redeemRequests.length; i++) {
                            if ($scope.isDetail.id == $scope.redeemRequests[i].redeemData.other.redeeemRequestTimestamp) {
                                if ($scope.redeemRequests[i].status != 'ÚJ') {
                                    $scope.isDetail.id = data.redeemData.other.redeeemRequestTimestamp;
                                    return;
                                }
                            }
                        }
                    }
                });
            });

            RedeemService.getRedeemCanceled(function (response) {
                $scope.$apply(function () {
                    var res = JSON.parse(response.data);
                    if ($scope.showYLCSelectedProductWindow == true && res.redeeemRequestTimestamp == $scope.YLCSelectedProductRedeem.redeemData.other.redeeemRequestTimestamp) {
                        $scope.cancelYLCSelectedProductWindow();
                    }
                    for (var i = 0; i < $scope.redeemRequests.length; i++) {
                        if ($scope.redeemRequests[i].key == res.key && res.redeeemRequestTimestamp == $scope.redeemRequests[i].redeemData.other.redeeemRequestTimestamp) {
                            $scope.restoreLoyaltyCard($scope.redeemRequests[i]);
                            $scope.redeemRequests[i].redeemCanceled = true;
                            $scope.redeemRequests[i].actualStatus = { "color": "rgb(78,82,92)" };
                            var index = $scope.redeemRequests.findIndex(x => angular.equals(x.key, res.key));
                            if (index != -1) {
                                if ($scope.redeemRequests[index].redeemType == 2) {
                                    var originIndex = $scope.loyaltycardRequestsOriginal.findIndex(x => angular.equals(x.key, res.key))
                                    $scope.redeemRequests[index] = $.extend(true, {}, $scope.loyaltycardRequestsOriginal[originIndex]);
                                }
                            }
                            $scope.redeemRequests[i].finalizedTimestamp = new Date().toISOString()
                            if (res.reason == "lostUserConnection") {
                                $scope.redeemRequests[i].finalizedStatus = 'MEGSZAKADT'
                                $scope.redeemRequests[i].status = "MEGSZAKADT";
                                $scope.redeemRequests[i].statusText = "Lezárult a felhasználó mobilja (vagy megszakadt az eszközén az internetkapcsolat).";
                            } else if (res.reason == "userCancelled") {
                                $scope.redeemRequests[i].finalizedStatus = 'MEGSZAKADT'
                                $scope.redeemRequests[i].status = "VISSZALÉPETT";
                                $scope.redeemRequests[i].statusText = "A felhasználó visszalépett a beváltástól.";
                            } else if (res.reason == "timeout") {
                                $scope.redeemRequests[i].finalizedStatus = 'LEJÁRT'
                                $scope.redeemRequests[i].status = "LEJÁRT";
                                $scope.redeemRequests[i].statusText = "Lejárt a beváltás időkerete.";
                            }
                        }
                    }
                });
            });

            RedeemService.getRedeemOfflineRedeemed(function (response) {
                $scope.$apply(function () {
                    var res = JSON.parse(response.data);
                    for (var i = 0; i < $scope.redeemRequests.length; i++) {
                        if ($scope.redeemRequests[i].key == res.key && res.redeeemRequestTimestamp == $scope.redeemRequests[i].redeemData.other.redeeemRequestTimestamp) {
                            $scope.redeemRequests[i].redeemCanceled = true;
                            $scope.redeemRequests[i].finalizedTimestamp = new Date().toISOString()
                            $scope.redeemRequests[i].finalizedStatus = 'ELFOGADVA'
                            $scope.redeemRequests[i].status = "ELFOGADOTT";
                            $scope.redeemRequests[i].actualStatus = { "color": "rgb(78,82,92)" };
                            $scope.redeemRequests[i].statusText = "A felhasználó offline beváltotta a kupont";
                        }
                    }
                });
            });

            RedeemService.getRedeemFinalised(function (response) {
                $scope.$apply(function () {
                    var res = JSON.parse(response.data);
                    var index = $scope.redeemRequests.findIndex(x => angular.equals(x.key, res.key))
                    var refIndex = $scope.redeemRequests.findIndex(x => angular.equals(x.refKey, res.key))
                    if (index != -1) {
                        if (res.redeemAccepted === true) {
                            if ($scope.redeemRequests[index].redeemType == 1) {
                                $scope.redeemRequests[index].finalizedTimestamp = new Date().toISOString()
                                $scope.redeemRequests[index].finalizedStatus = "ELFOGADVA";
                                $scope.redeemRequests[index].status = "ELFOGADOTT";
                                $scope.redeemRequests[index].handlerStatus = "Elfogadva";
                                $scope.redeemRequests[index].actualStatus = { "color": "rgb(84,130,53)" };
                                $scope.redeemRequests[index].statusText = "";

                            } else if ($scope.redeemRequests[index].redeemType == 2) {
                                const originIndex = $scope.loyaltycardRequestsOriginal.findIndex(x => angular.equals(x.key, res.key))

                                if ($scope.redeemRequests[index].redeemData.loyaltyCard.newStampCount != res.overallNoOfNewStamps) {
                                    let count = res.overallNoOfNewStamps;
                                    count += res.overallNoOfNewRedeems;
                                    $scope.redeemRequests[index] = $.extend(true, {}, $scope.loyaltycardRequestsOriginal[originIndex]);
                                    for (let i = 0; i < count; i++) {
                                        $scope.checkcurrentNrOfStamps('+', $scope.redeemRequests[index]);
                                    }
                                }
                                if ($scope.loyaltycardRequestsOriginal[originIndex].redeemData.loyaltyCard.YLCPartnerRedeemRemark != res.YLCPartnerRedeemRemark) {
                                    if ($scope.redeemRequests[index].redeemData.loyaltyCard.YLCPartnerRedeemRemark == "" && res.YLCPartnerRedeemRemark == null) {
                                        $scope.redeemRequests[index].redeemData.loyaltyCard.partnerRedeemRemarkStyle = emptyMark;
                                    } else {
                                        $scope.redeemRequests[index].redeemData.loyaltyCard.partnerRedeemRemarkStyle = modifiedMark;
                                        $scope.redeemRequests[index].redeemData.loyaltyCard.YLCPartnerRedeemRemark = res.YLCPartnerRedeemRemark
                                    }
                                } else {
                                    $scope.redeemRequests[index].redeemData.loyaltyCard.partnerRedeemRemarkStyle = unmodifiedMark;
                                }
                                $scope.redeemRequests[index].finalizedTimestamp = new Date().toISOString()
                                $scope.redeemRequests[index].finalizedStatus = "VÉGLEGESÍTVE";
                                $scope.redeemRequests[index].handlerStatus = "Véglegesítve";
                                if ($scope.redeemRequests[index].redeemData.loyaltyCard.canBeRedeemedLater == false) {
                                    if (checkLatestRewardRedeemed($scope.redeemRequests[index].redeemData.loyaltyCard) == false) {
                                        $scope.redeemRequests[index].status = 'PECSÉTELT';
                                        if (refIndex != -1) {
                                            $scope.redeemRequests[refIndex].status = 'PECSÉTELT';
                                        }
                                    } else {
                                        $scope.redeemRequests[index].status = 'BEVÁLTOTT';
                                        if (refIndex != -1) {
                                            $scope.redeemRequests[refIndex].status = 'BEVÁLTOTT';
                                        }
                                    }
                                } else {
                                    if ($scope.redeemRequests[index].redeemData.loyaltyCard.getReward > 0) {
                                        $scope.redeemRequests[index].status = 'BEVÁLTOTT';
                                        if (refIndex != -1) {
                                            $scope.redeemRequests[refIndex].status = 'BEVÁLTOTT';
                                        }
                                    } else {
                                        $scope.redeemRequests[index].status = 'PECSÉTELT';
                                        if (refIndex != -1) {
                                            $scope.redeemRequests[refIndex].status = 'PECSÉTELT';
                                        }
                                    }
                                }
                            }
                        } else {
                            if ($scope.redeemRequests[index].redeemType == 1) {
                                $scope.redeemRequests[index].finalizedTimestamp = new Date().toISOString()
                                $scope.redeemRequests[index].finalizedStatus = "ELUTASÍTVA";
                                $scope.redeemRequests[index].status = "ELUTASÍTOTT";
                                $scope.redeemRequests[index].handlerStatus = "Elutasítva";
                                $scope.redeemRequests[index].statusText = "";
                                $scope.redeemRequests[index].showRestoreContainer = false;
                                $scope.redeemRequests[index].showRejectContainer = false;
                                $scope.redeemRequests[index].showFinalizeContainer = true;

                            } else if ($scope.redeemRequests[index].redeemType == 2) {
                                const originIndex = $scope.loyaltycardRequestsOriginal.findIndex(x => angular.equals(x.key, res.key))
                                $scope.redeemRequests[index] = $.extend(true, {}, $scope.loyaltycardRequestsOriginal[originIndex]);
                                $scope.redeemRequests[index].finalizedTimestamp = new Date().toISOString()
                                $scope.redeemRequests[index].finalizedStatus = "ELUTASÍTVA";
                                $scope.redeemRequests[index].status = 'ELUTASÍTOTT';
                                $scope.redeemRequests[index].handlerStatus = "Elutasítva";
                            }
                        }
                    }
                    $scope.redeemRequests[index].handlerId = res.handlerData.handlerId;
                    $scope.redeemRequests[index].handlerEmail = res.handlerData.handlerEmail;
                    $scope.redeemRequests[index].handlerName = res.handlerData.handlerName;
                });
            });
        }
        $scope.convertCardValidityEndExpiryTime = function (value){
            if(value == 30 || value == 60 || value == 90){
                return value / 30 + " hónap";
            }
            if(value == 180) return 'Fél év';
            if(value == 365 || value == 730 || value == 1825){
                return value / 365 + " év";
            }
            return value + " nap"
        }
        $scope.disableMainIncreaseButton = function (redeem){
            if(redeem.redeemData.loyaltyCard.maxStampNrLimitationAtStamping != null){
                if(redeem.redeemData.loyaltyCard.maxStampNrLimitationAtStamping <= redeem.redeemData.loyaltyCard.newStampCount){
                    if(redeem.redeemData.loyaltyCard.canBeRedeemedLater == true ||
                        (
                        (redeem.redeemData.loyaltyCard.currentNrOfStamps != redeem.redeemData.loyaltyCard.reward1NrOfStampsNeeded || redeem.redeemData.loyaltyCard.isRewardRedeemed1 == true)
                        && (redeem.redeemData.loyaltyCard.currentNrOfStamps != redeem.redeemData.loyaltyCard.reward2NrOfStampsNeeded || redeem.redeemData.loyaltyCard.isRewardRedeemed2 == true)
                        && (redeem.redeemData.loyaltyCard.currentNrOfStamps != redeem.redeemData.loyaltyCard.reward3NrOfStampsNeeded || redeem.redeemData.loyaltyCard.isRewardRedeemed3 == true)
                        && (redeem.redeemData.loyaltyCard.currentNrOfStamps != redeem.redeemData.loyaltyCard.reward4NrOfStampsNeeded || redeem.redeemData.loyaltyCard.isRewardRedeemed4 == true)
                        && (redeem.redeemData.loyaltyCard.currentNrOfStamps != redeem.redeemData.loyaltyCard.reward5NrOfStampsNeeded || redeem.redeemData.loyaltyCard.isRewardRedeemed5 == true)))
                        {
                        return true;
                        }
                }
            }
            if(redeem.redeemData.loyaltyCard.currentNrOfStamps == redeem.redeemData.loyaltyCard.nrOfStampsNeeded && redeem.redeemData.loyaltyCard.newCardIssuingStoppedTimestamp != null 
                && (redeem.redeemData.loyaltyCard.canBeRedeemedLater == true || (redeem.redeemData.loyaltyCard.canBeRedeemedLater == false && redeem.redeemData.loyaltyCard.redeemCanBePostponed == false) || (redeem.redeemData.loyaltyCard.redeemCanBePostponed == true && 
                (redeem.redeemData.loyaltyCard.isRewardRedeemed1 == true && redeem.redeemData.loyaltyCard.isRewardRedeemed2 == true && redeem.redeemData.loyaltyCard.isRewardRedeemed3 == true && redeem.redeemData.loyaltyCard.isRewardRedeemed4 == true && redeem.redeemData.loyaltyCard.isRewardRedeemed5 == true)))){
                    return true;
            }
            return false;
        }
        if ($scope.adminUserType == "executive" || $scope.adminUserType == null) {
            //console.log('callRedeemService called from ...');
            callRedeemService();
        }
        $scope.getDetail = function (id) {
            if (!$scope.isDetail.id) {
                $scope.isDetail.id = id;
            } else if ($scope.isDetail.id == id) {
                $scope.isDetail.id = null;
            } else {
                $scope.isDetail.id = null;
                $scope.isDetail.id = id;
            }
        }
        function getMonth(month) {
            if (month == "01") {
                return "január";
            } else if (month == "02") {
                return "február";
            } else if (month == "03") {
                return "március";
            } else if (month == "04") {
                return "április";
            } else if (month == "05") {
                return "május";
            } else if (month == "06") {
                return "június";
            } else if (month == "07") {
                return "július";
            } else if (month == "08") {
                return "augusztus";
            } else if (month == "09") {
                return "szeptember";
            } else if (month == "10") {
                return "október";
            } else if (month == "11") {
                return "november";
            } else if (month == "12") {
                return "december";
            }
        }

        const $inp = $(".ap-otp-input");

        $inp.on({
            paste(ev) { // Handle Pasting
                let clip = ev.originalEvent.clipboardData.getData('text').trim();
                clip = clip.replace(/\s+/g, '');
                clip = clip.replace('-', '');
                if (clip.length != 6 || !/^[a-zA-Z0-9]+$/.test(clip)) {
                    ev.preventDefault();
                    let pasteToast = $mdToast.simple().parent(document.getElementById('redeemPage'))
                        .textContent(`Sikertelen beillesztés. A publikus azonosító formátuma helytelen.`)
                        .position('bottom center')
                        .hideDelay(2000);
                    $mdToast.show(pasteToast).then(function () { }).catch(function () { });
                } else {
                    // Split string to Array or characters
                    const s = [...clip];
                    // Populate inputs. Focus last input.
                    $inp.val(i => s[i]).eq(5).focus();
                }
            },
            input(ev) { // Handle typing
                const i = $inp.index(this);
                const validCharacters = /^[a-zA-Z0-9]+$/;
                if (this.value && validCharacters.test(this.value)) {
                    $inp.eq(i + 1).focus();
                } else {
                    // Clear invalid input
                    this.value = '';
                }
            },
            keydown(ev) { // Handle Deleting and allowed characters
                const i = $inp.index(this);
                const validKeys = /^[a-zA-Z0-9]$/;
                if (!validKeys.test(ev.key) && ev.key !== "Backspace") { // Only allow letters, numbers, and Backspace
                    ev.preventDefault();
                } else if (!this.value && ev.key === "Backspace" && i) {
                    $inp.eq(i - 1).focus();
                }
            }
        });

    })