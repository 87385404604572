import { MomentDateAdapter } from "@angular/material-moment-adapter";

export const DATE_FORMAT = {
    parse: {
      dateInput: 'LL',
    },
    display: {
      dateInput: 'LL',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
    },
};

export class CustomDateAdapter extends MomentDateAdapter {
    getDayOfWeekNames(style: 'long' | 'short' | 'narrow') {
      return ['V', 'H', 'K', 'S', 'C', 'P', 'S'];
    }
}