import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class GoogleMapsService {
    subjects = {
        storeAddrZIP: new Subject<string>(),
        storeAddrCity: new Subject<string>(),
        storeAddrStreet: new Subject<string>(),
        storeAddrNo: new Subject<string>()
    }
    private autoUpdate = new Subject<void>();

    updateMap(field?: string, value?: string) {
        if (!field) this.autoUpdate.next();
        else if (field === 'storeAddrZIP') this.subjects[field].next(value);
        else if (field === 'storeAddrCity') this.subjects[field].next(value);
        else if (field === 'storeAddrStreet') this.subjects[field].next(value);
        else if (field === 'storeAddrNo') this.subjects[field].next(value);
    }

    get addressZIP$(): Observable<string> {
        return this.subjects.storeAddrZIP.asObservable();
    }
    get addressCity$(): Observable<string> {
        return this.subjects.storeAddrCity.asObservable();
    }
    get addressStreet$(): Observable<string> {
        return this.subjects.storeAddrStreet.asObservable();
    }
    get addressNo$(): Observable<string> {
        return this.subjects.storeAddrNo.asObservable();
    }
    get autoUpdate$(): Observable<void> {
        return this.autoUpdate.asObservable();
    }
}
