import { Injectable, SecurityContext } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { DialogComponent } from 'src/app/shared/components/dialog/dialog.component';

@Injectable({
    providedIn: 'root'
})
export class DialogService {

    constructor(public dialog: MatDialog, private dom: DomSanitizer) { }

    openDialog(title: string, detail: string | undefined, confirm: string, cancel: string | undefined, width: string = 'auto'): MatDialogRef<DialogComponent, any> {
        return this.dialog.open(DialogComponent, {
            width: width,
            data: {
                title: title, detail: detail,
                confirm: confirm, cancel: cancel
            },
            disableClose: true
        })
    }

    openDialogWithHTML(htmlContent: string, confirm: string, cancel: string | undefined, width: string = 'auto'): MatDialogRef<DialogComponent, any> {
        let dialogRef = this.dialog.open(DialogComponent, {
            width: width,
            data: {
                title: undefined, detail: undefined,
                confirm: confirm, cancel: cancel
            },
            disableClose: true
        });
        dialogRef.componentInstance.htmlContent = this.dom.sanitize(SecurityContext.HTML, htmlContent)!;
        return dialogRef;
    }
}
