<mat-form-field [formGroup]="form" appearance="standard" [floatLabel]="placeholder ? 'always' : 'auto'">
    <mat-label>{{label}}</mat-label>
    <input matInput [formControlName]="inputName" [placeholder]="placeholder" [required]="required" autocomplete="off"
        [errorStateMatcher]="matcher" [ngClass]="{'invalid': input.invalid }" (blur)="emitBlurEvent($event)">
        <mat-hint align="end" *ngIf="maxLength">{{value.length}} / {{maxLength}}</mat-hint>
        <mat-hint align="end" *ngIf="!maxLength">{{value.length}}</mat-hint>
    <mat-error *ngIf="input.hasError('required')">
        <div>Mező kitöltése kötelező!</div>
        <div class="char-counter-error">{{value.length}} / {{maxLength}}</div>
    </mat-error>
    <mat-error *ngIf="input.hasError('maxlength')">
        <div>A mező maximálisan {{maxLength}} karakter hosszúságú lehet!</div>
        <div class="char-counter-error">{{value.length}} / {{maxLength}}</div>
    </mat-error>
</mat-form-field>