// @ts-nocheck

// public/js/controllers/MainCtrl.js
angular.module('yaltyApp')
	.controller('PartnerHomeController', function ($scope, $http, $anchorScroll, $location, $state, $stateParams) {
		$scope.packagePeriod = 1;
		let imgArray = [
			'/imgs/partnerHome/a_drop_coffee_bar_kavekartya.png',
			'/imgs/partnerHome/adam_kavezo_kavekartya.png',
			'/imgs/partnerHome/ahoy_waffle_and_chocolate-legyel_nalunk_torzsvendeg.png',
			'/imgs/partnerHome/arch_beans_kavemuvek_kavekartya.png',
			'/imgs/partnerHome/arena_e-sport_bar_10_hamburger_ingyen.png',
			'/imgs/partnerHome/az_anyam_kenyerit_villas_reggeli_promocio.png',
			'/imgs/partnerHome/babutzi_kave_kartya.png',
			'/imgs/partnerHome/babutzi_reggeli_kartya.png',
			'/imgs/partnerHome/beke_pekseg_kavekartya.png',
			'/imgs/partnerHome/benedict_desszert_kartya.png',
			'/imgs/partnerHome/benedict_kave_kartya.png',
			'/imgs/partnerHome/bistro_17_ajandek_espresso_vagy_limonade.png',
			'/imgs/partnerHome/bistro_17_minden_tizedik_pizza_vagy_burger_ajandekba.png',
			'/imgs/partnerHome/block_bistro_ajandek_10_belepo.png',
			'/imgs/partnerHome/bogyisz_jutalom_kartya.png',
			'/imgs/partnerHome/bringiton_coffee-meleg_ital.png',
			'/imgs/partnerHome/bruberi_coffee_bakery_kavekartya.png',
			'/imgs/partnerHome/cafe_memories_1_kave_1_pecset.png',
			'/imgs/partnerHome/candy_shop_miskolc_3_torta_50.png',
			'/imgs/partnerHome/cinkelt_kocka_kavezo_jatekos_husegkartya.png',
			'/imgs/partnerHome/cinkelt_kocka_kavezo_kaves_husegkartya.png',
			'/imgs/partnerHome/cream_hasznaltruha_kedvezmenykartya.png',
			'/imgs/partnerHome/cube_coffee_bar_kave_kartya.png',
			'/imgs/partnerHome/delizia_gourmet_more_ebed_menukartya.png',
			'/imgs/partnerHome/dolce_vita_etterem_ujra_var_a_dolce_vita.png',
			'/imgs/partnerHome/dozis_fine_pastries_kave_kupon.png',
			'/imgs/partnerHome/dukata_pekseg_kavekartya.png',
			'/imgs/partnerHome/dulo_etterem_ajandek_ebed_vagy_vacsora.png',
			'/imgs/partnerHome/egbolt_cafe_bar_kave_minden_napra.png',
			'/imgs/partnerHome/egbolt_cafe_bar_sordelutan_a_teraszon.png',
			'/imgs/partnerHome/fekete_fekete_husegkartya.png',
			'/imgs/partnerHome/fekete_fekete_thq_husegkartya.png',
			'/imgs/partnerHome/fekete_major_husegkartya.png',
			'/imgs/partnerHome/firpo_burger_minden_hetedik_ajandek.png',
			'/imgs/partnerHome/fittanyuka_menu_sutibar_10_menu_1_menu_ajandek.png',
			'/imgs/partnerHome/freddo_cafe-torzskartya.png',
			'/imgs/partnerHome/goosebumps_coffee_lab_coffee_club.png',
			'/imgs/partnerHome/hamizo_kifozde_ajandek_foetel_es_desszert.png',
			'/imgs/partnerHome/hellocafe_latincafe_10_pont_utan_ajandek.png',
			'/imgs/partnerHome/herbar-pontgyujto_kartya.png',
			'/imgs/partnerHome/ivy_coffee_husegkartya.png',
			'/imgs/partnerHome/jam_etterem_kavezo_ajandek_paros_vacsora_menu.png',
			'/imgs/partnerHome/java_kavezo_torzskartya.png',
			'/imgs/partnerHome/karakter_1517_kavezo_ajandek_10_kave.png',
			'/imgs/partnerHome/kastner_kommunity_loyality_card.png',
			'/imgs/partnerHome/kemenes_zuglo_kave_husegkartya.png',
			'/imgs/partnerHome/laget_pekseg_kenyer_kartya.png',
			'/imgs/partnerHome/lenyeg_juice_bar_2_dl_le.png',
			'/imgs/partnerHome/libra-lg_kft_kaves_pecsetgyujto.png',
			'/imgs/partnerHome/lujza_koriander_torzsvasarlo_brunch_kartya.png',
			'/imgs/partnerHome/mars_teri_lipoti_pekseg_kave_kupon.png',
			'/imgs/partnerHome/matinee_cocktail_passport.png',
			'/imgs/partnerHome/matinee_coffee_passport.png',
			'/imgs/partnerHome/mythai_pecsetgyujtes.png',
			'/imgs/partnerHome/naturgold_hungaria_kft_naturgold_husegkartya.png',
			'/imgs/partnerHome/nokedlish_nokedlish_husegkartya.png',
			'/imgs/partnerHome/ohana_coffee_family_membership.png',
			'/imgs/partnerHome/palanta_salatabar_palanta_kartya.png',
			'/imgs/partnerHome/partisan_coffee_shop_husegkartya.png',
			'/imgs/partnerHome/peaky_barbers_minden_6_ajandek.png',
			'/imgs/partnerHome/piazza_d_f_kft_kavekartya.png',
			'/imgs/partnerHome/pizza_preferita_ajandek_pizza.png',
			'/imgs/partnerHome/quick_hair_westend_husegkartya.png',
			'/imgs/partnerHome/relax_szalon_miskolc_arckezeles_ajandek.png',
			'/imgs/partnerHome/revesz_lehel_ev_kedvezmeny_kartya.png',
			'/imgs/partnerHome/rockabilly_chicken_ajandekutalvany_szombatra.png',
			'/imgs/partnerHome/rojt_es_bojt_coffee_ajandek_kaveital.png',
			'/imgs/partnerHome/rossita_pizzeria_ajandek_10_pizza.png',
			'/imgs/partnerHome/rukkola_budapest_10_salata_vasarlasakor_1000ft_kedvezmeny_jar_neked.png',
			'/imgs/partnerHome/schweppes_jatssz_velunk.png',
			'/imgs/partnerHome/see_you_latte_kavezo_kavekartya.png',
			'/imgs/partnerHome/steiner_tamas_e.v_ajandek_doner_box.png',
			'/imgs/partnerHome/tati_farm_to_table_orok_10_brunch.png',
			'/imgs/partnerHome/thailand_massage_szalon_minden_otodik_masszazs_utan_a_hatodikat_ajandekba_adjuk.png',
			'/imgs/partnerHome/visegradi_12_etelbar_napi_menu.png',
			'/imgs/partnerHome/visegradi_12_etelbar-desszertes.png',
			'/imgs/partnerHome/visegradi_12_etelbar-fozelekes.png',
			'/imgs/partnerHome/waffle_and_love-torzsvasarloi_kartya.png',
			'/imgs/partnerHome/zabrakadabra_buda_tortakartya.png',
			'/imgs/partnerHome/zabrakadabra_oktogon_sutemeny_szelet.png',
			'/imgs/partnerHome/zamatic_deli_caffe_10_kave.png'
		]

		if(window.innerHeight > 800) {
			$scope.displayedRows = 3;
		} else if(window.innerHeight > 630) {
			$scope.displayedRows = 2;
		} else {
			$scope.displayedRows = 1;
		}

		$scope.innerWidth = window.innerWidth;

		function reportWindowSize() {
			$scope.innerWidth = window.innerWidth;
			/*if((window.innerHeight > 800 && $scope.displayedRows == 3) || (window.innerHeight <= 800 && window.innerHeight > 630 && $scope.displayedRows == 2) || (window.innerHeight <= 630 && $scope.displayedRows == 1)) {
				return;
			}
			if(window.innerHeight > 800) {
				$scope.displayedRows = 3;
			} else if(window.innerHeight > 630) {
				$scope.displayedRows = 2;
			} else {
				$scope.displayedRows = 1;
			}
			console.log($scope.displayedRows)*/
		}

		window.addEventListener("resize", reportWindowSize);

		let images = shuffle(imgArray);

		var scroller_inner1 = document.getElementById('scroller_inner1');
		var scroller_inner2 = document.getElementById('scroller_inner2');
		var scroller_inner3 = document.getElementById('scroller_inner3');
		var scroller_inner4 = document.getElementById('scroller_inner4');
		var scroller_inner5 = document.getElementById('scroller_inner5');
		var scroller_inner6 = document.getElementById('scroller_inner6');

		for(let i = 0; i <= 21; i++) {
			let str = '<img src="' + images[i] + '" width="299" height="148">';
			scroller_inner1.insertAdjacentHTML( 'beforeend', str );
		}

		for(let i = 22; i <= 43; i++) {
			let str = '<img src="' + images[i] + '" width="299" height="148">';
			scroller_inner2.insertAdjacentHTML( 'beforeend', str );
		}

		for(let i = 44; i <= 65; i++) {
			let str = '<img src="' + images[i] + '" width="299" height="148">';
			scroller_inner3.insertAdjacentHTML( 'beforeend', str );
		}

		for(let i = 0; i <= 32; i++) {
			let str = '<img src="' + images[i] + '" width="299" height="148">';
			scroller_inner4.insertAdjacentHTML( 'beforeend', str );
		}

		for(let i = 33; i <= 65; i++) {
			let str = '<img src="' + images[i] + '" width="299" height="148">';
			scroller_inner5.insertAdjacentHTML( 'beforeend', str );
		}

		for(let i = 0; i <= 65; i++) {
			let str = '<img src="' + images[i] + '" width="299" height="148">';
			scroller_inner6.insertAdjacentHTML( 'beforeend', str );
		}

		const scrollers = document.querySelectorAll('.scroller');

		if(!window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
			addAnimation();
		}

		function addAnimation() {
			for(let i = 0; i < scrollers.length; i++) {
				let scroller = scrollers[i];
				scroller.setAttribute("data-animated", true);
				if(i == 0) {
					scroller.style.setProperty('--_animation-duration', '121s');
				} else if(i == 1) {
					scroller.style.setProperty('--_animation-duration', '62s');
				} else if(i == 2) {
					scroller.style.setProperty('--_animation-duration', '81s');
				} else if(i == 3) {
					scroller.style.setProperty('--_animation-duration', '183s');
				} else if(i == 4) {
					scroller.style.setProperty('--_animation-duration', '92s');
				} else if(i == 5) {
					scroller.style.setProperty('--_animation-duration', '363s');
				}
				const scrollerInner = scroller.querySelector(".scroller_inner");
				const scrollerContent = Array.from(scrollerInner.children);

				scrollerContent.forEach((item) => {
					const duplicatedItem = item.cloneNode(true);
					duplicatedItem.setAttribute("aria-hidden", true);
					scrollerInner?.appendChild(duplicatedItem);
				})
			}
		}

		function shuffle(array) {
			let currentIndex = array.length,  randomIndex;
		  
			// While there remain elements to shuffle.
			while (currentIndex > 0) {
		  
			  // Pick a remaining element.
			  randomIndex = Math.floor(Math.random() * currentIndex);
			  currentIndex--;
		  
			  // And swap it with the current element.
			  [array[currentIndex], array[randomIndex]] = [
				array[randomIndex], array[currentIndex]];
			}
		  
			return array;
		  }
	})
	.controller('MainController', function ($scope, $http, $anchorScroll, $location, $state, $stateParams, $cookies) {
		$scope.actualYearForCopyright = new Date().getFullYear();
		$scope.slogan = "All your loyalty cards<br>in one place";
		$scope.headerSlogan = "All your loyalty cards in one place";
		$scope.active = { "float": "right", "font-weight": "bold", "color": "#4E525C", "text-transform": "uppercase" };
		$scope.active_sidebarmenu = { "float": "right", "font-weight": "bold", "color": "#4E525C", "text-transform": "uppercase" };
		$scope.active2 = { "float": "right", "font-weight": "bold" };
		$scope.active3 = { "float": "right", "font-weight": "bold" };
		$scope.active3_sidebarmenu = { "float": "right", "font-weight": "bold" };
		$scope.active5 = {"float": "right", "color": "#FFFFFF"}
		$scope.active6 = {"float": "right", "margin-left": "50px", "color": "#FFFFFF"}
		$scope.active7 = {"float": "right", "margin-top": "15px", "font-weight": "bold", "font-size": "85%", "color": "#FFFFFF"}
		$scope.active8 = {"float": "right", "margin-left": "50px", "margin-right": "40px", "color": "#FFFFFF"}
		$scope.active9 = {"background-color": "#FFF"}
		$scope.active10 = {"background-color": "rgba(0, 174, 239, 0.95)"}
		$scope.isMain = true;

		if ((navigator.userAgent.match(/iPhone/i)) || (navigator.userAgent.match(/iPod/i))) { //iPhone Version:
			$scope.isMobileDevice = true;
			$scope.closeMobileBanner = function () {
				$scope.isMobileDevice = false;
				//$scope.mobileDevicePaddingTop = { "padding-top": "10px" };
				$('#yalty-top-header-yalty-en').css("margin-top", "0px");
				//$('.sidebarIconToggle').css('margin-top', "0px");
			}
			if (!$scope.isMobileDevice || $location.url() == "/partnerHU/loginHU" || $location.url() == "/partnerHU/contactHU" || $location.url() == "/partner/login" || $location.url() == "/partner/contact") {
				//$scope.mobileDevicePaddingTop = { "padding-top": "10px" };
				$('#yalty-top-header-yalty-en').css("margin-top", "0px");
				//$('.sidebarIconToggle').css('margin-top', "0px");
			} else {
				//$scope.mobileDevicePaddingTop = { "padding-top": "24vw" };
				$('#yalty-top-header-yalty-en').css("margin-top", "24vw");
				//$('.sidebarIconToggle').css('margin-top', "24vw");
				if ($('#mobileBannerEN').css('display') == 'none') {
					//$scope.mobileDevicePaddingTop = { "padding-top": "10px" };
					$('#yalty-top-header-yalty-en').css("margin-top", "0px");
					//$('.sidebarIconToggle').css('margin-top', "0px");
				}
			}

			$scope.navigateToDownload = function () {
				window.location.href = 'https://itunes.apple.com/app/apple-store/id1340457476?pt=118959301&ct=www.yalty.com_public_web_app_download&mt=8'
			}
		} else if (navigator.userAgent.match(/android/i)) { //Android Version:
			$scope.isMobileDevice = true;
			$scope.closeMobileBanner = function () {
				$scope.isMobileDevice = false;
				//$scope.mobileDevicePaddingTop = { "padding-top": "10px" }
				$('#yalty-top-header-yalty-en').css("margin-top", "0px");
				//$('.sidebarIconToggle').css('margin-top', "0px");
			}
			if (!$scope.isMobileDevice || $location.url() == "/partnerHU/loginHU" || $location.url() == "/partnerHU/contactHU" || $location.url() == "/partner/login" || $location.url() == "/partner/contact") {
				//$scope.mobileDevicePaddingTop = { "padding-top": "10px" };
				$('#yalty-top-header-yalty-en').css("margin-top", "0px");
				//$('.sidebarIconToggle').css('margin-top', "0px");
			} else {
				//$scope.mobileDevicePaddingTop = { "padding-top": "24vw" };
				$('#yalty-top-header-yalty-en').css("margin-top", "24vw");
				//$('.sidebarIconToggle').css('margin-top', "24vw");
				if ($('#mobileBannerEN').css('display') == 'none') {
					//$scope.mobileDevicePaddingTop = { "padding-top": "10px" };
					$('#yalty-top-header-yalty-en').css("margin-top", "0px");
					//$('.sidebarIconToggle').css('margin-top', "0px");
				}
			}

			$scope.navigateToDownload = function () {
				window.location.href = 'https://play.google.com/store/apps/details?id=com.yalty.yaltyapp&referrer=utm_source%3Dwww.yalty.com_public_web_app_download%26anid%3Dadmob'
			}
		} else {
			$scope.isMobileDevice = false;
			//$scope.mobileDevicePaddingTop = { "padding-top": "10px" }
			$('#yalty-top-header-yalty-en').css("margin-top", "0px");
			//$('.sidebarIconToggle').css('padding-top', "0px");
		}

		$scope.setHULangPreffered = function () {
			$cookies.put('huLangPreffered', 'true');
		}

		$scope.hamburgermenuclick = function () {
			document.getElementById("openSidebarMenu").checked = !document.getElementById("openSidebarMenu").checked;
		};
		$scope.hamburgermenuclose = function () {
			document.getElementById("openSidebarMenu").checked = false;
		};
		$scope.goToSection = function (x) {
			if ((x == 1) || (x == 'P1') || (x == 'P1H')) {
				document.getElementById("openSidebarMenu").checked = false;

				if (x == 'P1') {
					$state.go('index.home', { 'obj': 'main' });
				} else if (x == 'P1H') {
					$state.go('index_HU.home', { 'obj': 'main' });
				} else {
					goTo('main', true);
					//$state.go('.', { 'obj': 'main' });
				}
			} else if ((x == 3) || (x == 'P3') || (x == 'P3H')) {
				document.getElementById("openSidebarMenu").checked = false;
				if (x == 'P3') {
					$state.go('index.home', { 'obj': 'yaltyApp' });
				} else if (x == 'P3H') {
					$state.go('index_HU.home', { 'obj': 'yaltyApp' });
				} else {
					goTo('yaltyApp', false);
					//$state.go('.', {}, { reload: true });
				}
			}
		};

		var goTo = function (x, isMain) {
			var newHash = x;
			$scope.isMain = isMain;
			if ($location.hash() !== newHash) {
				$location.hash(x);
			} else {
				$anchorScroll();
			}
		}


	})
	.controller('MainController_HU', function ($scope, $http, $anchorScroll, $location, $window, $state, $stateParams, $mdDialog, API_PREFIX, $filter, GoogleAnalyticsService) {
		$scope.actualYearForCopyright = new Date().getFullYear();
		if ((navigator.userAgent.match(/iPhone/i)) || (navigator.userAgent.match(/iPod/i))) { //iPhone Version:
			$scope.isMobileDevice = true;
			$scope.closeMobileBanner = function () {
				$scope.isMobileDevice = false;
				//$scope.mobileDevicePaddingTop = { "padding-top": "10px" };
				$('#yalty-top-header-yalty-hu').css("margin-top", "0px");
				//$('.sidebarIconToggle').css('margin-top', "0px");
			}
			if (!$scope.isMobileDevice || $location.url() == "/partnerHU/loginHU" || $location.url() == "/partnerHU/contactHU" || $location.url() == "/partner/login" || $location.url() == "/partner/contact") {
				//$scope.mobileDevicePaddingTop = { "padding-top": "10px" };
				$('#yalty-top-header-yalty-hu').css("margin-top", "0px");
				//$('.sidebarIconToggle').css('margin-top', "0px");
			} else {
				//$scope.mobileDevicePaddingTop = { "padding-top": "24vw" };
				$('#yalty-top-header-yalty-hu').css("margin-top", "24vw");
				//$('.sidebarIconToggle').css('margin-top', "24vw");
				if ($('#mobileBannerHU').css('display') == 'none') {
					//$scope.mobileDevicePaddingTop = { "padding-top": "10px" };
					$('#yalty-top-header-yalty-hu').css("margin-top", "0px");
					//$('.sidebarIconToggle').css('margin-top', "0px");
				}
			}

			$scope.navigateToDownload = function () {
				window.location.href = 'https://itunes.apple.com/app/apple-store/id1340457476?pt=118959301&ct=www.yalty.com_public_web_app_download&mt=8'
			}
		} else if (navigator.userAgent.match(/android/i)) { //Android Version:
			$scope.isMobileDevice = true;
			$scope.closeMobileBanner = function () {
				$scope.isMobileDevice = false;
				//$scope.mobileDevicePaddingTop = { "padding-top": "10px" }
				$('#yalty-top-header-yalty-hu').css("margin-top", "0px");
				//$('.sidebarIconToggle').css('margin-top', "0px");
			}
			if (!$scope.isMobileDevice || $location.url() == "/partnerHU/loginHU" || $location.url() == "/partnerHU/contactHU" || $location.url() == "/partner/login" || $location.url() == "/partner/contact") {
				//$scope.mobileDevicePaddingTop = { "padding-top": "10px" };
				$('#yalty-top-header-yalty-hu').css("margin-top", "0px");
				//$('.sidebarIconToggle').css('margin-top', "0px");
			} else {
				//$scope.mobileDevicePaddingTop = { "padding-top": "24vw" };
				$('#yalty-top-header-yalty-hu').css("margin-top", "24vw");
				//$('.sidebarIconToggle').css('margin-top', "24vw");
				if ($('#mobileBannerHU').css('display') == 'none') {
					//$scope.mobileDevicePaddingTop = { "padding-top": "10px" };
					$('#yalty-top-header-yalty-hu').css("margin-top", "0px");
					//$('.sidebarIconToggle').css('margin-top', "0px");
				}
			}

			$scope.navigateToDownload = function () {
				window.location.href = 'https://play.google.com/store/apps/details?id=com.yalty.yaltyapp&referrer=utm_source%3Dwww.yalty.com_public_web_app_download%26anid%3Dadmob'
			}
		} else {
			$scope.isMobileDevice = false;
			//$scope.mobileDevicePaddingTop = { "padding-top": "10px" }
			$('#yalty-top-header-yalty-hu').css("margin-top", "0px");
			//$('.sidebarIconToggle').css('padding-top', "0px");
		}
		$scope.isMain = true;
		$scope.slogan = "Minden hűségkártyád<br>egy helyen";
		$scope.headerSlogan = "Minden hűségkártyád egy helyen";
		if ($location.url() == '/discountHU') {
			$scope.active = { "float": "right", "font-weight": "bold" };
			$scope.active_sidebarmenu = { "float": "right", "font-weight": "bold" };
			$scope.active2 = { "float": "right", "font-weight": "bold" };
			$scope.active3 = { "float": "right", "font-weight": "bold" };
			$scope.active3_sidebarmenu = { "float": "right", "font-weight": "bold" };
			$scope.active4 = { "float": "right", "font-weight": "bold", "color": "#4E525C", "text-transform": "uppercase" };
			$scope.active5 = {"float": "right", "color": "#FFFFFF"}
			$scope.active6 = {"float": "right", "margin-left": "50px", "color": "#FFFFFF"}
			$scope.active7 = {"float": "right", "margin-top": "15px", "font-weight": "bold", "font-size": "85%", "color": "#FFFFFF"}
			$scope.active8 = {"float": "right", "margin-left": "50px", "margin-right": "40px", "color": "#FFFFFF"}
			$scope.active9 = {"background-color": "#FFF"}
			$scope.active10 = {"background-color": "rgba(0, 174, 239, 0.95)"}
			$scope.CategoryFill = function (category) {
				$scope.CategoryFilter = category;
				//$scope.class = "yaltyDiscountCategoryBoxListNameItemSelected";
				$scope.activeMenu = category
			};
			$scope.showorhide = false;
			$scope.status = '  ';
			$scope.customFullscreen = false;
			$scope.couponid = "";
			$scope.promname = "";
			$scope.compstorestreet = "";
			$scope.comstoreno = "";
			$scope.showAdvanced = function (ev, couponid, promname, compstorestreet, comstoreno) {
				$scope.couponid = couponid;
				$scope.promname = promname;
				$scope.compstorestreet = compstorestreet;
				$scope.comstoreno = comstoreno;
				$scope.showorhide = true;
				$mdDialog.show({
					controller: DialogController,
					templateUrl: 'reszleteskupon.html',
					parent: angular.element(document.body),
					targetEvent: ev,
					clickOutsideToClose: true,
					fullscreen: $scope.customFullscreen, // Only for -xs, -sm breakpoints.
				}).then(function (answer) {
					$scope.status = 'You said the information was "' + answer + '".';
				}, function () {
					$scope.status = 'You cancelled the dialog.';
				});
			};

			function DialogController($scope, $mdDialog) {
				$scope.hide = function () {
					$mdDialog.hide();
				};

				$scope.cancel = function () {
					$mdDialog.cancel();
				};

				$scope.answer = function (answer) {
					$mdDialog.hide(answer);
				};
			}

			$scope.search22 = function (x) {
				if ($scope.search.includes(x.compNamePromo)
					|| $scope.search.includes(x.promotionName)
					|| $scope.search.includes(x.promotionDetailedDescrsearch)) {
					return true;
				}
				else if ($scope.search == null || $scope.search == "") {
					return true;
				}
				else {

					return false;

				}

			};

			$scope.lathato = false;
			$scope.kuponid = null;
			$scope.getView = function (id) {
				if ($scope.kuponid == id) {
					return $scope.lathato;
				} else {
					return false;
				}
			}
			$scope.lockScroll = function (id) {
				document.getElementById("overlay").style.display = "block";
				document.getElementById("body").style.overflow = "hidden";
				document.getElementById("discount").style.overflow = "hidden";

				$scope.lathato = true;
				$scope.kuponid = id;
				return $scope.lathato;
			}
			$scope.offlockScroll = function () {
				document.getElementById("overlay").style.display = "none";
				document.getElementById("body").style.overflow = "auto";
				document.getElementById("discount").style.overflow = "auto";
				$scope.lathato = false;
				return $scope.lathato;
			}
			$scope.kuponidk = [];
			$scope.checkID = function (id) {
				for (var i in $scope.kuponidk) {
					if (angular.equals($scope.kuponidk[i], id)) {
						$scope.test();
						return false;
					}
				}
				$scope.kuponidk.push(id);
				$scope.test();
				return true;

			}

			$scope.varos2 = [];

			$scope.varoschange = function (value) {
				$scope.varos2 = [];
				var vva = {};
				vva.a = $scope.varos;
				$scope.varos2.push(vva.a);
			}

			$scope.checkToken = function () {
				if ($scope.PAKtoken == "" || $scope.PAKtoken == null) {
                    window.on('tokenerror');
					return false;
				} else {
					return true;
				}
			};



			$scope.varosvalasztas = function (ev) {
				if ($scope.checkToken()) {
					if ($scope.selection.length == 0) {
						$scope.loadingOver(true);
						var valasztottvaros = {};
						valasztottvaros.varos = $scope.varos;
						valasztottvaros.verifytoken = $scope.PAKtoken;
						$http.post(API_PREFIX.url + '/etc/getAllPublicWebPromotionData', valasztottvaros).then(function (result) {
							if (result.data.errorCode == 0) {
								$scope.kuponok = result.data.allPublicWebPromotionData;
								if ($scope.kuponok == null || $scope.kuponok.length == 0 || $scope.kuponok == []) { } else {
									$scope.categoryszures();
									$scope.activeMenu = $scope.catnames[0].id;
									$scope.CategoryFilter = $scope.catnames[0].id;
								}
							} else {}
							$scope.loadingOver(false);
						}, function (result) {
						});
					} else if ($scope.selection.length > 0) {
						var confirm = $mdDialog.confirm()
							.title('')
							.textContent('Városváltáskor ürítjük a nyomtatási gyűjtőlistád. Biztosan továbblépsz?')
							.ariaLabel('')
							.targetEvent(ev)
							.ok('Igen')
							.cancel('Mégsem');

						$mdDialog.show(confirm).then(function () {
							$scope.loadingOver(true);
							$scope.section = [];

							angular.forEach($scope.kuponok, function (couponId) {
								couponId.selected = false;
							});

							var valasztottvaros = {};
							valasztottvaros.varos = $scope.varos;
							valasztottvaros.verifytoken = $scope.PAKtoken;
							$http.post(API_PREFIX.url + '/etc/getAllPublicWebPromotionData', valasztottvaros).then(function (result) {
								if (result.data.errorCode == 0) {
									$scope.kuponok = result.data.allPublicWebPromotionData;
									if ($scope.kuponok == null || $scope.kuponok.length == 0 || $scope.kuponok == []) { } else {
										$scope.categoryszures();
										$scope.activeMenu = $scope.catnames[0].id;
										$scope.CategoryFilter = $scope.catnames[0].id;
									}
								} else {}
								$scope.loadingOver(false);
							}, function (result) {});
						}, function () {
							$scope.loadingOver(true);
							var str = $scope.varos2[$scope.varos2.length - 1];
							$scope.varos = str;
							$scope.loadingOver(false);
						});
					}
				} else {

				}
			};
			$scope.firstLocationLoad = true;
			$scope.firstLocationLoad2 = true;

			$scope.getLocationCity = function () {
				if ($scope.firstLocationLoad) {
					document.body.scrollTop = 0;
					document.documentElement.scrollTop = 0;
					$.getJSON("http://ip-api.com/json/", function (data) {
						$.each(data, function (k, v) {
							if (k == "city") {
								$scope.aCity = [];
								var vva = {};
								vva.a = v;
								$scope.aCity.push(vva.a);
							}
						});
						$scope.getLocationCity2();

					});
					$scope.firstLocationLoad = false;
					return $scope.varos;
				}
			}

			$scope.getLocationCity2 = function () {
				if ($scope.firstLocationLoad2) {
					for (var i in $scope.varosok) {

						if ($scope.varosok[i].cityName == $scope.aCity) {
							let city = "";
							city = $scope.aCity;
							$scope.varos = $scope.aCity[0];

							$scope.loadingOver(true);
							var valasztottvaros = {};
							valasztottvaros.varos = $scope.varos;
							valasztottvaros.verifytoken = $scope.PAKtoken;
							$http.post(API_PREFIX.url + '/etc/getAllPublicWebPromotionData', valasztottvaros).then(function (result) {
								if (result.data.errorCode == 0) {
									$scope.kuponok = result.data.allPublicWebPromotionData;
									if ($scope.kuponok == null || $scope.kuponok.length == 0 || $scope.kuponok == []) { } else {
										$scope.categoryszures();
										$scope.activeMenu = $scope.catnames[0].id;
									}
								} else {}
								$scope.loadingOver(false);
							}, function (result) {
							});
						}

					}
				}
				$scope.firstLocationLoad = false;
				$scope.firstLocationLoad2 = false;
			}
			$scope.aktualisdate = new Date();
			$scope.ervenyessegcheck = function (x, y) {
				if (x >= y) {
					return true;
				} else {
					return false;
				}
			}
			$scope.nyitvatartaskiemeles = function (akt, nap, isOpen, openingTimeFrom, openingTimeUntil) {
				if (akt == nap) {
					if (!isOpen) {
						var dateNow = new Date();
						var startDate = new Date(dateNow.getTime());
						startDate.setHours(openingTimeFrom.split(":")[0]);
						startDate.setMinutes(openingTimeFrom.split(":")[1]);
						var endDate = new Date(dateNow.getTime());
						endDate.setHours(openingTimeUntil.split(":")[0]);
						endDate.setMinutes(openingTimeUntil.split(":")[1]);
						const valid = startDate < dateNow && endDate > dateNow
						if (valid){
							return "nyitvatartaskiemelese";
						} else {
							return "nyitvatartaskiemelesezarva";
						}
					} else if (isOpen) {
						return "nyitvatartaskiemelesezarva";
					}
				} else {
					return "nyitvatartaskiemelese2";
				}
			}
			$scope.nyitvazarva = function (h, k, sze, cs, p, szo, v, hs, he, ks, ke, szes, szee, css, cse, ps, pe, szos, szoe, vs, ve, akt, idoora) {
				if (akt == "hétfő") {
					$scope.nyitvaoratol = (hs.split(':')[0]);
					$scope.nyitvaperctol = (hs.split(':')[1]);
					$scope.nyitvaoraig = (he.split(':')[0]);
					$scope.nyitvapercig = (he.split(':')[1]);
					if (hs < idoora && he > idoora && !h) {
						return true;
					}
				}
				if (akt == "kedd") {
					$scope.nyitvaoratol = (ks.split(':')[0]);
					$scope.nyitvaperctol = (ks.split(':')[1]);
					$scope.nyitvaoraig = (ke.split(':')[0]);
					$scope.nyitvapercig = (ke.split(':')[1]);
					if (ks < idoora && ke > idoora && !k) {
						return true;
					}
				}
				if (akt == "szerda") {
					$scope.nyitvaoratol = (szes.split(':')[0]);
					$scope.nyitvaperctol = (szes.split(':')[1]);
					$scope.nyitvaoraig = (szee.split(':')[0]);
					$scope.nyitvapercig = (szee.split(':')[1]);
					if (szes < idoora && szee > idoora && !sze) {
						return true;
					}
				}
				if (akt == "csütörtök") {
					$scope.nyitvaoratol = (css.split(':')[0]);
					$scope.nyitvaperctol = (css.split(':')[1]);
					$scope.nyitvaoraig = (cse.split(':')[0]);
					$scope.nyitvapercig = (cse.split(':')[1]);
					if (css < idoora && cse > idoora && !cs) {
						return true;
					}
				}
				if (akt == "péntek") {
					$scope.nyitvaoratol = (ps.split(':')[0]);
					$scope.nyitvaperctol = (ps.split(':')[1]);
					$scope.nyitvaoraig = (pe.split(':')[0]);
					$scope.nyitvapercig = (pe.split(':')[1]);
					if (ps < idoora && pe > idoora && !p) {
						return true;
					}
				}
				if (akt == "szombat") {
					$scope.nyitvaoratol = (szos.split(':')[0]);
					$scope.nyitvaperctol = (szos.split(':')[1]);
					$scope.nyitvaoraig = (szoe.split(':')[0]);
					$scope.nyitvapercig = (szoe.split(':')[1]);
					if (szos < idoora && szoe > idoora && !szo) {
						return true;
					}
				}
				if (akt == "vasárnap") {
					$scope.nyitvaoratol = (vs.split(':')[0]);
					$scope.nyitvaperctol = (vs.split(':')[1]);
					$scope.nyitvaoraig = (ve.split(':')[0]);
					$scope.nyitvapercig = (ve.split(':')[1]);
					if (vs < idoora && ve > idoora && !v) {
						return true;
					}
				}
				else {
					return false;
				}
			}



			$scope.catids = [];
			$scope.categoryszures = function () {
				$scope.catids = [];
				$scope.catnames = [];
				$scope.allcategoryview = false;
				$scope.errorchecker = false;
				for (var i in $scope.kuponok) {
					$scope.errorchecker = false;
					if (i == 0) {
						$scope.catids.push($scope.kuponok[i].promoCategoryId);
					} else {
						for (var l = 0; l <= $scope.catids.length; l++) {
							if ($scope.catids[l] == $scope.kuponok[i].promoCategoryId) {
								$scope.errorchecker = true;
							}
						}
						if (!$scope.errorchecker) {
							$scope.catids.push($scope.kuponok[i].promoCategoryId);
						}
					}
				}
				if ($scope.catids.length > 0) {
					$scope.catnames.push({
						id: -1,
						kategorianev: "Minden kategória",
						kategoriaIconUrl: null
					});
				}
				for (var j in $scope.catids) {
					for (var z in $scope.kategoriak) {
						if ($scope.catids[j] == $scope.kategoriak[z].id) {
							$scope.catnames.push({
								id: $scope.kategoriak[z].id,
								kategorianev: $scope.kategoriak[z].kategorianev,
								kategoriaIconUrl: $scope.kategoriak[z].kategoriaIconUrl
							});
						}
					}
				}
				if ($scope.catids.length > 0) {
					$scope.allcategoryview = true;
				} else {
					$scope.allcategoryview = false;
				}
			}
			$scope.novekmeny = 0;
			$scope.test = function () {
				$scope.novekmeny++;
				return $scope.novekmeny;
			}


			$scope.settingcontainerheight = function (x) {
                let h;
				if (window.innerWidth > 980) {
					if (4 < x) {
						const a = (x + 1) % 2;
						h = ((x + 1) / 2) * 180 + 300;
						if (a != 0) {
							h = h + 180;
						} else if (a == 0) {
							h = h + 100;
						}
					} else {
						h = 2 * 180 + 300;
					}
				}
				if (980 >= window.innerWidth && window.innerWidth > 650) {
					if (0 < x) {
						const a = (x + 1) % 2;
						h = ((x + 1) / 2) * 210 + 300;
						if (a != 0) {
							h = h + 180;
						}
					}

				}
				if (650 >= window.innerWidth) {
					h = (x + 1) * 210 + 300;
				}
				$(".sectionpubliccouponview").height(h);
			}

			$(window).on('resize', function () {
				$('.inner').each(function () {

					var box = $(this);
					var width = box.width();
					var height = box.height();

					box.attr('data-size', width + 'x' + height + ' (r: ' + (width / height).toFixed(2) + ')');

				});
			}).trigger('resize');

			$scope.felhaszfeltetelgenerator = function (felhasz, x1, x2, c, amount) {
				if (felhasz != null) {
					if (x1 == "unlimited" && x2 == "unlimited") {
						return "Többször beváltható kupon.";
					} else if (x1 == "limited" && x2 == "unlimited") {
						return "Többször beváltható kupon (összesen " + c + " darab).";
					} else if (x1 == "unlimited" && x2 == "limited") {
						return "Felhasználónként " + amount + " kupon váltható be.";
					} else if (x1 == "limited" && x2 == "limited") {
						return "Felhasználónként " + amount + " kupon váltható be (összesen " + c + " darab).";
					}
				}

			}

			$scope.loadingOver = function (isLast) {

				if (isLast) {
					onDiscountOverlay('loadingOverlay1');

				} else if (!isLast) {
					offDiscountOverlay('loadingOverlay1');
				}
            }
            
            function onDiscountOverlay(elementId) {
                document.getElementById(elementId).style.display = "block";
            }

            function offDiscountOverlay(elementId) {
                document.getElementById(elementId).style.display = "none";
            }

			$scope.promotionAddedToPrintList = function (promotion) {
				if (promotion === true) {
					GoogleAnalyticsService.sendDataToGA('/HU/felhasználói/kedvezmények/nyomtatási_listához_adás', 'HU Kedvezmények - Nyomtatási listához adás');
				}
			}

			$scope.egyenlodatumragok = function (nap) {
				const enlist = ["1", "4", "5", "7", "9", "10", "11", "12", "14", "15", "17", "19", "21", "22", "24", "25", "27", "29", "31"];
				const anlist = ["2", "3", "6", "8", "13", "16", "18", "20", "23", "26", "28", "30"];

				if (enlist.indexOf(nap) !== -1) {
					return "én";
				}
				if (anlist.indexOf(nap) !== -1) {
					return "án";
				}
			}
			$scope.nemegyenlodatumragok = function (nap) {
				const toollist = ["1", "4", "5", "7", "9", "10", "11", "12", "14", "15", "17", "19", "21", "22", "24", "25", "27", "29", "31"];
				const tollist = ["2", "3", "6", "8", "13", "16", "18", "20", "23", "26", "28", "30"];

				if (toollist.indexOf(nap) !== -1) {
					return "től";
				}
				if (tollist.indexOf(nap) !== -1) {
					return "tól";
				}
			}

			$scope.selectclass = function (kedvezmenytipusa, ar) {
				if (kedvezmenytipusa == 0) {
					return "yaltyDiscountContaineritemkedvezmenyszazalek"
				}
				if (kedvezmenytipusa == 1 && ar.length > 4) {
					return "yaltyDiscountContaineritemkedvezmenyszazaleksmall"
				}
				if (kedvezmenytipusa == 2 && ar.length > 3) {
					return "yaltyDiscountContaineritemkedvezmenyarsmall"
				}
				if (kedvezmenytipusa == 1 && ar.length <= 4) {
					return "yaltyDiscountContaineritemkedvezmenyszazalek"
				}
				if (kedvezmenytipusa == 2 && ar.length <= 3) {
					return "yaltyDiscountContaineritemkedvezmenyar"
				}
				if (kedvezmenytipusa == 3) {
					return "yaltyDiscountContaineritemkedvezmenyujar"
				}
				if (kedvezmenytipusa == 4) {
					return "yaltyDiscountContaineritemkedvezmenyar"
				}
				if (kedvezmenytipusa == 5) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 6) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
			}
			$scope.selectclasss = function (kedvezmenytipusa, ar) {
				if (kedvezmenytipusa == 0) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 1) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 2) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 3 && ar.length <= 3) {
					return "yaltyDiscountContaineritemkedvezmenyujar"
				}
				if (kedvezmenytipusa == 3 && ar.length > 3) {
					return "yaltyDiscountContaineritemkedvezmenyujarsmall"
				}
				if (kedvezmenytipusa == 4 && ar.length <= 3) {
					return "yaltyDiscountContaineritemkedvezmenyujar2"
				}
				if (kedvezmenytipusa == 4 && ar.length > 3) {
					return "yaltyDiscountContaineritemkedvezmenyujar2small"
				}
				if (kedvezmenytipusa == 5) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 6) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
			}
			$scope.egyedikedvezmeny = function (kedvezmenytipusa, ar) {
				if (kedvezmenytipusa == 0) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 1) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 2) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 3) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 4) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 5) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 6 && ar.length <= 5) {
					return "yaltyDiscountContaineritemegyedikedvezmeny"
				}
				if (kedvezmenytipusa == 6 && ar.length > 5 && ar.length <= 9) {
					return "yaltyDiscountContaineritemegyedikedvezmenysmall"
				}
				if (kedvezmenytipusa == 6 && ar.length > 9) {
					return "yaltyDiscountContaineritemegyedikedvezmenysmallsmall"
				}
			}
			$scope.ajandek = function (kedvezmenytipusa, ar) {
				if (kedvezmenytipusa == 0) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 1) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 2) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 3) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 4) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 5 && ar.length <= 6) {
					return "yaltyDiscountContaineritemkedvezmenyajandek"
				}
				if (kedvezmenytipusa == 5 && ar.length > 6 && ar.length <= 7) {
					return "yaltyDiscountContaineritemkedvezmenyajandeksmall"
				}
				if (kedvezmenytipusa == 5 && ar.length > 7) {
					return "yaltyDiscountContaineritemkedvezmenyajandeksmallsmall"
				}
				if (kedvezmenytipusa == 6) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
			}
			$scope.regiarshoworhidden = function (kedvezmenytipusa) {
				if (kedvezmenytipusa == 0) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 1) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 2) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 3) {
					return "yaltyDiscountContaineritemkedvezmenyregiar"
				}
				if (kedvezmenytipusa == 4) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 5) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 6) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
			}
			$scope.isSzazalek = function (kedvezmenytipusa) {
				if (kedvezmenytipusa == 0) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 1) {
					return "yaltiArSzazalek"
				}
				if (kedvezmenytipusa == 2) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 3) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 4) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 5) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 6) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
			}
			$scope.isArleszallitasD = function (kedvezmenytipusa) {
				if (kedvezmenytipusa == 0) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 1) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 2) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 3) {
					return "yaltyDiscountContaineritemkedvezmenyar"
				}
				if (kedvezmenytipusa == 4) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 5) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 6) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
			}
			$scope.isKedvezmenyesarD = function (kedvezmenytipusa) {
				if (kedvezmenytipusa == 0) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 1) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 2) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 3) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 4) {
					return "yaltyShowCouponDetailskedvezmenyar"
				}
				if (kedvezmenytipusa == 5) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 6) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
			}
			$scope.isAr = function (kedvezmenytipusa) {
				if (kedvezmenytipusa == 0) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 1) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 2) {
					return "yaltiArSzazalek"
				}
				if (kedvezmenytipusa == 3) {
					return "yaltiArSzazalek"
				}
				if (kedvezmenytipusa == 4) {
					return "yaltiArSzazalek"
				}
				if (kedvezmenytipusa == 5) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 6) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
			}
			$scope.isKedvezmenytext = function (kedvezmenytipusa) {
				if (kedvezmenytipusa == 0) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 1) {
					return "yaltyDiscountContaineritemkedvezmenytext"
				}
				if (kedvezmenytipusa == 2) {
					return "yaltyDiscountContaineritemkedvezmenytext"
				}
				if (kedvezmenytipusa == 3) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 4) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 5) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 6) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
			}
			$scope.isAkciosArtext = function (kedvezmenytipusa) {
				if (kedvezmenytipusa == 0) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 1) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 2) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 3) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 4) {
					return "yaltyDiscountContaineritemkedvezmenytext"
				}
				if (kedvezmenytipusa == 5) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 6) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
			}

			$scope.isAjandektext = function (kedvezmenytipusa) {
				if (kedvezmenytipusa == 0) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 1) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 2) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 3) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 4) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 5) {
					return "yaltyDiscountContaineritemkedvezmenytext"
				}
				if (kedvezmenytipusa == 6) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
			}
			$scope.selectclassD = function (kedvezmenytipusa) {
				if (kedvezmenytipusa == 0) {
					return "yaltyShowCouponDetailskedvezmenyszazalek"
				}
				if (kedvezmenytipusa == 1) {
					return "yaltyShowCouponDetailskedvezmenyszazalek"
				}
				if (kedvezmenytipusa == 2) {
					return "yaltyShowCouponDetailskedvezmenyar"
				}
				if (kedvezmenytipusa == 3) {
					return "yaltyShowCouponDetailskedvezmenyujar"
				}
				if (kedvezmenytipusa == 4) {
					return "yaltyShowCouponDetailskedvezmenyar"
				}
				if (kedvezmenytipusa == 5) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 6) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
			}
			$scope.selectclassDd = function (kedvezmenytipusa) {
				if (kedvezmenytipusa == 0) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 1) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 2) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 3) {
					return "yaltyShowCouponDetailskedvezmenyujar"
				}
				if (kedvezmenytipusa == 4) {
					return "yaltyShowCouponDetailskedvezmenyujar"
				}
				if (kedvezmenytipusa == 5) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 6) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
			}
			$scope.egyedikedvezmenyD = function (kedvezmenytipusa) {
				if (kedvezmenytipusa == 0) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 1) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 2) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 3) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 4) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 5) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 6) {
					return "yaltyShowCouponDetailskedvezmenyujar"
				}
			}
			$scope.ajandekD = function (kedvezmenytipusa) {
				if (kedvezmenytipusa == 0) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 1) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 2) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 3) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 4) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 5) {
					return "yaltyShowCouponDetailskedvezmenyszazalek"
				}
				if (kedvezmenytipusa == 6) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
			}
			$scope.regiarshoworhiddenD = function (kedvezmenytipusa) {
				if (kedvezmenytipusa == 0) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 1) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 2) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 3) {
					return "yaltyShowCouponDetailskedvezmenyregiar"
				}
				if (kedvezmenytipusa == 4) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 5) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 6) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
			}
			$scope.isKedvezmenytextD = function (kedvezmenytipusa) {
				if (kedvezmenytipusa == 0) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 1) {
					return "yaltyShowCouponDetailskedvezmenytext"
				}
				if (kedvezmenytipusa == 2) {
					return "yaltyShowCouponDetailskedvezmenytext"
				}
				if (kedvezmenytipusa == 3) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 4) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 5) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 6) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
			}
			$scope.isAkciosArtextD = function (kedvezmenytipusa) {
				if (kedvezmenytipusa == 0) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 1) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 2) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 3) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 4) {
					return "yaltyShowCouponDetailskedvezmenytext"
				}
				if (kedvezmenytipusa == 5) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 6) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
			}
			$scope.isAjandektextD = function (kedvezmenytipusa) {
				if (kedvezmenytipusa == 0) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 1) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 2) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 3) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 4) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
				if (kedvezmenytipusa == 5) {
					return "yaltyShowCouponDetailskedvezmenytext"
				}
				if (kedvezmenytipusa == 6) {
					return "yaltyDiscountContaineritemkedvezmenynotshow"
				}
			}
			$scope.selection = [];

			$scope.$watch('kuponok|filter:{selected:true}', function (nv) {
				$scope.selection = nv ? nv.map(function (x) {
					return x;
				}) : [];

			}, true);

			$scope.nyomtatas = function () {
				GoogleAnalyticsService.sendDataToGA('/HU/felhasználói/kedvezmények/nyomtatás', 'HU Kedvezmények - Nyomtatás');
				var printContents = document.getElementById("printable").innerHTML;
				var popupWin = window.open('', '_blank', 'width=595,height=auto');
				popupWin.document.open();
				popupWin.document.write('<html><head><meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />    <link rel="stylesheet" href="css/yalty.css"></head><body onload="window.print()">' + printContents + '</body></html>');
				popupWin.document.close();
			}
			$scope.printone = function (str) {
				var printContents = document.getElementById(str).innerHTML;
				var popupWin = window.open('', '_blank', 'width=595,height=auto');
				popupWin.document.open();
				popupWin.document.write('<html><head><meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />    <link rel="stylesheet" href="css/yalty.css"></head><body onload="window.print()">' + printContents + '</body></html>');
				popupWin.document.close();
			}


			$scope.NyomtatasiListaUrites = function (ev) {
				var confirm = $mdDialog.confirm()
					.title('')
					.textContent($scope.selection.length.toString() + ' kupont jelöltél nyomtatásra. Biztosan üríted a nyomtatási listád?')
					.ariaLabel('')
					.targetEvent(ev)
					.ok('Igen')
					.cancel('Mégsem');

				$mdDialog.show(confirm).then(function () {
					angular.forEach($scope.kuponok, function (couponId) {
						couponId.selected = false;
					});
				}, function () {});
			};


			$scope.stringbreak = function (str) {
				if (str.length > 22) {
					var str2 = str.split("");
					var str3 = "";
					var str4 = "";
					var str5 = "";
					var str6 = "";
					var n = 0;
					for (var i in str2) {
						if (n >= 0 && n < 22) {
							str3 += str2[i];
						}
						if (n >= 22 && n < 44) {
							str4 += str2[i];
						}
						if (n >= 44 && n < 66) {
							str5 += str2[i];
						}
						if (n >= 66 && n < 88) {
							str6 += str2[i];
						}
						n++;

					}
					return "     " + str3 + '</br>' + str4 + '</br>' + str5 + '</br>' + str6;
				} else {
					return "     " + str;
				}
			}

			$scope.osszegmegjelenites = function (str) {
				str = JSON.stringify(str);
				if (str.length > 6) {
					if (str.length == 8) {
						const str2 = str.split("");
						return str2[0] + str2[1] + "." + str2[2] + str2[3] + str2[4] + "." + str2[5] + str2[6] + str2[7];
					}
					if (str.length == 7) {
						const str2 = str.split("");
						return str2[0] + "." + str2[1] + str2[2] + str2[3] + "." + str2[4] + str2[5] + str2[6];
					}
				}
				if (str.length > 3) {
					if (str.length == 6) {
						const str2 = str.split("");
						return str2[0] + str2[1] + str2[2] + "." + str2[3] + str2[4] + str2[5];
					}
					if (str.length == 5) {
						const str2 = str.split("");
						return str2[0] + str2[1] + "." + str2[2] + str2[3] + str2[4];
					}
					if (str.length == 4) {
						const str2 = str.split("");
						return str2[0] + "." + str2[1] + str2[2] + str2[3];
					}
				}
				if (str.length <= 3) {
					return str;
				}
			};




			$scope.scrollTo = function (couponId, seemoreinfo, x) {
				if (seemoreinfo) {
					var details = $("#yaltyShowCouponDetails" + couponId);
					var details2 = details.offset().top;
					var nav = $('#dcc' + x + couponId);
					var nav2 = nav.offset().top;
					if (nav.length) {
						details.animate({
							scrollTop: $('#yaltyShowCouponDetails' + couponId).scrollTop() + (nav2 - details2)
						}, 800);
					}
				}
			};

		} else if ($location.url() == '/indexHU/home#yaltyApp') {
			$scope.active = { "float": "right", "font-weight": "bold" };
			$scope.active_sidebarmenu = { "float": "right", "font-weight": "bold" };
			$scope.active2 = { "float": "right", "font-weight": "bold" };
			$scope.active3 = { "float": "right", "font-weight": "bold", "color": "#4E525C", "text-transform": "uppercase" };
			$scope.active3_sidebarmenu = { "float": "right", "font-weight": "bold", "color": "#4E525C", "text-transform": "uppercase" };
			$scope.active4 = { "float": "right", "font-weight": "bold" };
			$scope.active5 = {"float": "right", "color": "#FFFFFF"}
			$scope.active6 = {"float": "right", "margin-left": "50px", "color": "#FFFFFF"}
			$scope.active7 = {"float": "right", "margin-top": "15px", "font-weight": "bold", "font-size": "85%", "color": "#FFFFFF"}
			$scope.active8 = {"float": "right", "margin-left": "50px", "margin-right": "40px", "color": "#FFFFFF"}
			$scope.active9 = {"background-color": "#00AEEF"}
			$scope.active10 = {"background-color": "rgba(255, 255, 255, 0.95)"}
		} else {
			$scope.active = { "float": "right", "font-weight": "bold", "color": "#4E525C", "text-transform": "uppercase" };
			$scope.active_sidebarmenu = { "float": "right", "font-weight": "bold", "color": "#4E525C", "text-transform": "uppercase" };
			$scope.active2 = { "float": "right", "font-weight": "bold" };
			$scope.active3 = { "float": "right", "font-weight": "bold" };
			$scope.active3_sidebarmenu = { "float": "right", "font-weight": "bold" };
			$scope.active4 = { "float": "right", "font-weight": "bold" };
			$scope.active5 = {"float": "right", "color": "#FFFFFF"}
			$scope.active6 = {"float": "right", "margin-left": "50px", "color": "#FFFFFF"}
			$scope.active7 = {"float": "right", "margin-top": "15px", "font-weight": "bold", "font-size": "85%", "color": "#FFFFFF"}
			$scope.active8 = {"float": "right", "margin-left": "50px", "margin-right": "40px", "color": "#FFFFFF"}
			$scope.active9 = {"background-color": "#FFF"}
			$scope.active10 = {"background-color": "rgba(0, 174, 239, 0.95)"}
		}
		$scope.duplicateclickverify = 0;
		$scope.HamburgerBarCheck = function () {
			var HamburgerBarValue = document.getElementById("openSidebarMenu").checked;
			if (!HamburgerBarValue) {
				document.getElementById("openSidebarMenu").checked = true;
			} else {
				document.getElementById("openSidebarMenu").checked = true;

			}
		};
		$scope.hamburgermenuclick = function () {
			document.getElementById("openSidebarMenu").checked = !document.getElementById("openSidebarMenu").checked;
		};
		$scope.hamburgermenuclose = function () {
			document.getElementById("openSidebarMenu").checked = false;
		};
		$scope.goToSection = function (x) {
			if ((x == 1) || (x == 'P1') || (x == 'P1H')) {
				$scope.hamburgermenuclose();
				if (x == 'P1') {
					$state.go('index.home', { 'obj': 'main' });
				} else if (x == 'P1H') {
					$scope.duplicateclickverify = 0;
					$state.go('index_HU.home', { 'obj': 'main' }).then(function () {
						$scope.active = { "float": "right", "font-weight": "bold", "color": "#4E525C", "text-transform": "uppercase" };
						$scope.active4 = { "float": "right", "font-weight": "bold" };
						goTo('main', true);
					});
				} else {
					$state.go('index_HU.home', { 'obj': 'main' }).then(function () {
						$scope.active = { "float": "right", "font-weight": "bold", "color": "#4E525C", "text-transform": "uppercase" };
						$scope.active4 = { "float": "right", "font-weight": "bold" };
						goTo('main', true);
					});
				}
			} else if ((x == 2) || (x == 'P2') || (x == 'P2H')) {
				$scope.hamburgermenuclose();
				if (x == 'P2') {
					$state.go('index.home', { 'obj': 'discount' });
				} else if (x == 'P2H') {
					if ($scope.duplicateclickverify == 0) {
					}
					$scope.duplicateclickverify = 1;
					$state.go('index_HU.discount');
					$scope.active = { "float": "right", "font-weight": "bold" };
					$scope.active4 = { "float": "right", "font-weight": "bold", "color": "#4E525C", "text-transform": "uppercase" };
					$scope.isMain = false;
				} else {
					goTo('discount', true);
				}
			} else if ((x == 4) || (x == 'P4') || (x == 'P4H')) {
				$scope.hamburgermenuclose();
				if (x == 'P4') {
					$state.go('index.home', { 'obj': 'yaltyApp' });
				} else if (x == 'P4H') {
					$scope.duplicateclickverify = 0;
					$state.go('index_HU.home', { 'obj': 'yaltyApp' }).then(function () {
						$scope.active3 = { "float": "right", "font-weight": "bold", "color": "#4E525C", "text-transform": "uppercase" };
						goTo('yaltyApp', false);
					});
				} else {
					goTo('yaltyApp', false);
				}
			}
		};

		var goTo = function (x, isMain) {
			var newHash = x;
			$scope.isMain = isMain;
			if ($location.hash() !== newHash) {
				$location.hash(x);
			} else {
				$anchorScroll();
			}
		}

	})
	.directive("scroll", function ($window, $location) {
		return function (scope, element, attrs) {
			angular.element($window).bind("scroll", function () {
				if (this.pageYOffset < $window.innerHeight && $location.url() != '/discountHU') {
					scope.isMain = true;
					scope.active = { "float": "right", "font-weight": "bold", "color": "#4E525C", "text-transform": "uppercase" };
					scope.active_sidebarmenu = { "float": "right", "font-weight": "bold", "color": "#4E525C", "text-transform": "uppercase" };
					scope.active2 = { "float": "right", "font-weight": "bold" };
					scope.active3 = { "float": "right", "font-weight": "bold" };
					scope.active3_sidebarmenu = { "float": "right", "font-weight": "bold" };
					scope.active4 = { "float": "right", "font-weight": "bold" };
					scope.active5 = {"float": "right", "color": "#FFFFFF"}
					scope.active6 = {"float": "right", "margin-left": "50px", "color": "#FFFFFF"}
					scope.active7 = {"float": "right", "margin-top": "15px", "font-weight": "bold", "font-size": "85%", "color": "#FFFFFF"}
					scope.active8 = {"float": "right", "margin-left": "50px", "margin-right": "40px", "color": "#FFFFFF"}
					scope.active9 = {"background-color": "#FFF"}
					scope.active10 = {"background-color": "rgba(0, 174, 239, 0.95)"}
				} /*else if ($location.url() == '/discountHU') {
					scope.isMain = false;
					scope.active = { "float": "right", "font-weight": "bold" };
					scope.active2 = { "float": "right", "font-weight": "bold" };
					scope.active3 = { "float": "right", "font-weight": "bold" };
					scope.active4 = { "float": "right", "font-weight": "bold", "color": "#4E525C", "text-transform": "uppercase" };
				}*/ else if (this.pageYOffset >= (1 * $window.innerHeight) && ($window.innerWidth > 700 ? (this.pageYOffset < (2 * $window.innerHeight)) : (this.pageYOffset <= document.getElementById('yaltyApp').offsetHeight))) {
					scope.isMain = false;
					scope.active = { "float": "right", "font-weight": "bold", "color": "#00AEEF"};
					scope.active_sidebarmenu = { "float": "right", "font-weight": "bold"};
					scope.active2 = { "float": "right", "font-weight": "bold", "color": "#00AEEF" };
					scope.active3 = { "float": "right", "font-weight": "bold", "color": "#4E525C", "text-transform": "uppercase" };
					scope.active3_sidebarmenu = { "float": "right", "font-weight": "bold", "color": "#4E525C", "text-transform": "uppercase" };
					scope.active4 = { "float": "right", "font-weight": "bold", "color": "#00AEEF" };
					scope.active5 = {"float": "right", "color": "#00AEEF"}
					scope.active6 = {"float": "right", "margin-left": "50px", "color": "#00AEEF"}
					scope.active7 = {"float": "right", "margin-top": "15px", "font-weight": "bold", "font-size": "85%", "color": "#00AEEF"}
					scope.active8 = {"float": "right", "margin-left": "50px", "margin-right": "40px", "color": "#00AEEF"}
					scope.active9 = {"background-color": "#00AEEF"}
					scope.active10 = {"background-color": "rgba(255, 255, 255, 0.95)"}
				} /*else if (this.pageYOffset >= (3 * $window.innerHeight) && ($window.innerWidth > 700 ? (this.pageYOffset < (4 * $window.innerHeight)) : (this.pageYOffset <= document.getElementById('discount').offsetHeight))) {
					scope.isMain = false;
					scope.active = { "float": "right", "font-weight": "bold" };
					scope.active2 = { "float": "right", "font-weight": "bold" };
					scope.active3 = { "float": "right", "font-weight": "bold" };
					scope.active4 = { "float": "right", "font-weight": "bold", "color": "#4E525C", "text-transform": "uppercase" };
				}*/
				scope.$apply();
			});
		};
	})
	.controller('yaltyAppCtrl', function ($scope, $timeout) {
		$scope.text = [{ main: "All your loyalty cards in one place", title: "", desc1: "", desc2: "", desc3: "", desc4: "", img: "../imgs/yaltyAppScreen.png", isNotList: false },
		{ main: "", title: "Intuitive, easy to use, lean user interface", desc1: "The most relevant promotions matching your interest show up on the home screen", desc2: "All other promotions and loyalty programs can easily be accessed including your select favourites", desc3: "", desc4: "", img: "../imgs/yaltyMainScreen_v2.png", isNotList: false },
		{ main: "", title: "Promotions are categorised in well-defined groups", desc1: "Browse through the latest additions or the ones closest to you", desc2: "Choose deals using well structured categories and filter and sort them further to find the ones you are most interested in", desc3: "Take a quick glimpse on the most relevant information of a promotion just browsing through its list view", desc4: "", img: "../imgs/yaltyCouponsScreen.png", isNotList: false },
		{ main: "", title: "The detail view of your selected promotion shows all the relevant information for you to decide on redeeming it", desc1: "It details the actual offer and lists its redeem conditions", desc2: "Shows its availability and possible limitations", desc3: "Selects the closest store to redeem it and shows all other stores featuring the promotion as well for your convenience", desc4: "", img: "../imgs/yaltyCouponDetailScreen.png", isNotList: false },
		{ main: "", title: "Redeeming is a convenient, simple and fast, click of a button process with instant customer feedback.", desc1: "You simply press the yalty redeem button and our partner confirms the redeem in response from their screen.", desc3: "", desc4: "", img: "../imgs/yaltyCashingScreen.png", isNotList: true }];
		var click = 0;
		$scope.main = $scope.text[click].main;
		$scope.title = $scope.text[click].title;
		$scope.desc1 = $scope.text[click].desc1;
		$scope.desc2 = $scope.text[click].desc2;
		$scope.desc3 = $scope.text[click].desc3;
		$scope.desc4 = $scope.text[click].desc4;
		$scope.imgs = $scope.text[click].img;
		$scope.isNotList = $scope.text[click].isNotList;
		var addClick = function (param) {
			if (param == "+") {
				click++;
				if (click > 4) {
					click = 0;
				}
				$scope.main = $scope.text[click].main;
				$scope.title = $scope.text[click].title;
				$scope.desc1 = $scope.text[click].desc1;
				$scope.desc2 = $scope.text[click].desc2;
				$scope.desc3 = $scope.text[click].desc3;
				$scope.desc4 = $scope.text[click].desc4;
				$scope.imgs = $scope.text[click].img;
				$scope.isNotList = $scope.text[click].isNotList;
			} else if (param == '-') {
				click--;
				if (click < 0) {
					click = 4;
				}
				$scope.main = $scope.text[click].main;
				$scope.title = $scope.text[click].title;
				$scope.desc1 = $scope.text[click].desc1;
				$scope.desc2 = $scope.text[click].desc2;
				$scope.desc3 = $scope.text[click].desc3;
				$scope.desc4 = $scope.text[click].desc4;
				$scope.imgs = $scope.text[click].img;
				$scope.isNotList = $scope.text[click].isNotList;
			}
		}
		$scope.addClick = function (param) {
			if (param == '+') {
				addClick('+');
			} else if (param == '-') {
				addClick('-');
			}
		}
		$scope.startDisplay = function () {
			var slideTimer =
				$timeout(function interval() {
					addClick('+');
					slideTimer = $timeout(interval, 6000);
				}, 6000);
		}
	})
	.controller('yaltyAppCtrl_HU', function ($scope, $timeout) {
		$scope.text = [{ main: "Minden hűségkártyád egy helyen", title: "", desc1: "", desc2: "", desc3: "", desc4: "", img: "../imgs/yaltyAppScreen.png", isNotList: false },
		{ main: "", title: "Intuitív, könnyű használat, letisztult felület", desc1: "Az aktuális, kiemelt promóciók elérhetők a főképernyőről", desc2: "A kedvenceid és minden más kedvezmény könnyen elérhető a gyorsmenükből", desc3: "", desc4: "", img: "../imgs/yaltyMainScreen_v2.png", isNotList: false },
		{ main: "", title: "A kedvezmények néhány, jóldefiniált kategóriába rendezettek", desc1: "Böngészd a legújabb kedvezményeket vagy azokat, amelyek a legközelebb vannak hozzád", desc2: "Válassz kategóriák szerint", desc3: "Átlapozva a kedvezményeket rögtön látod a legfontosabb jellemzőiket, így könnyen ki tudod választani a számodra legértékesebbeket", desc4: "", img: "../imgs/yaltyCouponsScreen.png", isNotList: false },
		{ main: "", title: "A kiválasztott promóció részletes nézete minden fontos információt tartalmaz ahhoz, hogy el tudd dönteni beváltod-e", desc1: "Részletes leírást ad a kedvezményről és felsorolja a beváltás feltételeit", desc2: "Megmutatja az elérhetőségét és esetleges limitációit", desc3: "Több üzlet közül kiválasztja a hozzád legközelebb esőt és listázza számodra a további üzleteket", desc4: "", img: "../imgs/yaltyCouponDetailScreen.png", isNotList: false },
		{ main: "", title: "A beváltás egy kényelmes, egyszerű és gyors folyamat azonnali visszajelzéssel.", desc1: "Egyszerűen csak nyomd meg a yalty beváltás gombot és kövesd a folyamatot és visszajelzést a megjelenő beváltási lapon.", desc3: "", desc4: "", img: "../imgs/yaltyCashingScreen.png", isNotList: true }];
		var click = 0;
		$scope.main = $scope.text[click].main;
		$scope.title = $scope.text[click].title;
		$scope.desc1 = $scope.text[click].desc1;
		$scope.desc2 = $scope.text[click].desc2;
		$scope.desc3 = $scope.text[click].desc3;
		$scope.desc4 = $scope.text[click].desc4;
		$scope.imgs = $scope.text[click].img;
		$scope.isNotList = $scope.text[click].isNotList;
		var addClick = function (param) {
			if (param == "+") {
				click++;
				if (click > 4) {
					click = 0;
				}
				$scope.main = $scope.text[click].main;
				$scope.title = $scope.text[click].title;
				$scope.desc1 = $scope.text[click].desc1;
				$scope.desc2 = $scope.text[click].desc2;
				$scope.desc3 = $scope.text[click].desc3;
				$scope.desc4 = $scope.text[click].desc4;
				$scope.imgs = $scope.text[click].img;
				$scope.isNotList = $scope.text[click].isNotList;
			} else if (param == '-') {
				click--;
				if (click < 0) {
					click = 4;
				}
				$scope.main = $scope.text[click].main;
				$scope.title = $scope.text[click].title;
				$scope.desc1 = $scope.text[click].desc1;
				$scope.desc2 = $scope.text[click].desc2;
				$scope.desc3 = $scope.text[click].desc3;
				$scope.desc4 = $scope.text[click].desc4;
				$scope.imgs = $scope.text[click].img;
				$scope.isNotList = $scope.text[click].isNotList;
			}
		}
		$scope.addClick = function (param) {
			if (param == '+') {
				addClick('+');
			} else if (param == '-') {
				addClick('-');
			}
		}
		$scope.startDisplay = function () {
			var slideTimer =
				$timeout(function interval() {
					addClick('+');
					slideTimer = $timeout(interval, 6000);
				}, 6000);
		}
	});