import { Injectable } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class CommonService {

    constructor() { }

    strcmp(str1: string | null | undefined, str2: string | null | undefined) {
        if (!str1) return 1;
        if (!str2) return -1;
        return str1 < str2 ? -1 : (str1 > str2 ? 1 : 0);
    }

    numbercmp(n1: number | null, n2: number | null, ascending = true): number {
        if (n1 === n2) return 0;
        if (n1 === null) return 1;
        if (n2 === null) return -1;
        if (ascending) return n1 < n2 ? -1 : 1;
        else return n1 < n2 ? 1 : -1;
    }

    deepEquals(x: any, y: any) {
        if (x === y) {
            return true; // if both x and y are null or undefined and exactly the same
        } else if (!(x instanceof Object) || !(y instanceof Object)) {
            return false; // if they are not strictly equal, they both need to be Objects
        } else if (x.constructor !== y.constructor) {
            // they must have the exact same prototype chain, the closest we can do is
            // test their constructor.
            return false;
        } else {
            for (const p in x) {
                if (!x.hasOwnProperty(p)) {
                    continue; // other properties were tested using x.constructor === y.constructor
                }
                if (!y.hasOwnProperty(p)) {
                    return false; // allows to compare x[ p ] and y[ p ] when set to undefined
                }
                if (x[p] === y[p]) {
                    continue; // if they have the same strict value or identity then they are equal
                }
                if (typeof (x[p]) !== 'object') {
                    return false; // Numbers, Strings, Functions, Booleans must be strictly equal
                }
                if (!this.deepEquals(x[p], y[p])) {
                    return false;
                }
            }
            for (const p in y) {
                if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) {
                    return false;
                }
            }
            return true;
        }
    }

    getAbsoluteHeightOfElement(el: string, byID: boolean = true): number {
        const element = byID ?
            document.getElementById(el) :
            document.getElementsByClassName(el)[0] as HTMLElement;
        if (!element) return 0;
        const styles = window.getComputedStyle(element);
        const margin = parseFloat(styles['marginTop']) +
            parseFloat(styles['marginBottom']);

        return Math.ceil(element.offsetHeight + margin);
    }

    capitalizeFirstLetter(string: string): string {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    get resizeObservable$(): Observable<Event> {
        return fromEvent(window, 'resize').pipe(throttleTime(100, undefined, { leading: false, trailing: true }));
    }
}
