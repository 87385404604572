import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, ControlContainer, FormGroup } from '@angular/forms';
import { LanguagesService } from "src/app/shared/services/languages.service";

@Component({
    selector: 'app-language-selector',
    templateUrl: './language-selector.component.html',
    styleUrls: ['./language-selector.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LanguageSelectorComponent implements OnInit {

    form: FormGroup;

    languages = this.languagesService.languages;
    selectedLanguageText = "Magyar";
    selectedImgURL = 'imgs/translation_flag_HU.png';

    constructor(
        private languagesService: LanguagesService,
        private controlContainer: ControlContainer
    ) { }

    ngOnInit() {
        this.form = <FormGroup>this.controlContainer.control;
        this.language.valueChanges.subscribe((val: string) => {
            const selectedLang = this.languages.find(e => e.value === val);
            if (!selectedLang) return;
            this.selectedLanguageText = selectedLang.display;
            this.selectedImgURL = selectedLang.imgURL;
        })
    }

    get language(): AbstractControl { return this.form.get('languageSelector')!; }

}
