// @ts-nocheck

// /api/user/activateUser(URL)
angular.module('yaltyApp')
    .controller('appshareCtrl', function ($scope, $timeout, $rootScope, $http, $state, $location, API_PREFIX, AuthService, $mdDialog, GoogleAnalyticsService, $cookies, $window, $filter) {
        let lang = 'hu';
        $scope.promotionShareBtnDisabled = false;
        if ($state.params.lang != null) {
            lang = $state.params.lang;
            if($state.params.lang == 'en' || $state.params.lang == 'hu'){
                $scope.lang = $state.params.lang;
            } else {
                $scope.lang = 'hu';
            }
            if ((navigator.userAgent.match(/iPhone/i)) || (navigator.userAgent.match(/iPod/i))) {
                $scope.downloadPic = 'ios';
            } else if (navigator.userAgent.match(/android/i)) {
                $scope.downloadPic = 'android';
            } else {
                $scope.downloadPic = 'pc';
            }
        }
        $scope.changeLang = (newLang) => {
            if(newLang == 'en'){
                $location.search('lang', 'en');
            } else if(newLang == 'hu'){
                $location.search('lang', 'hu');
            }
        }

        $scope.openPromotion = () => {
            $scope.promotionShareBtnDisabled = true;
            if ((navigator.userAgent.match(/android/i))) {
                window.open(`app://${window.location.host}/appshare/${$state.params.id}`, '_self')
            } else if((navigator.userAgent.match(/iPhone/i)) || (navigator.userAgent.match(/iPod/i))) {
                window.open(`com.yalty.yaltyapp://appshare/${$state.params.id}`, '_self')
            }
            $timeout(function () {
                $scope.promotionShareBtnDisabled = false;
            }, 3000);
        }

        if (window.i18next) {
            window.i18next.use(window.i18nextXHRBackend);
            window.i18next.init({
                debug: false,
                lng: lang,
                fallbackLng: 'hu',
                backend: {
                    loadPath: '/api/translations/web_app_translation_{{lng}}.json'
                }
            }, function (err, t) {
                $scope.appsharetext = i18next.t('services.app_share.mobile.first_section.title');
                $scope.appsharetext2 = i18next.t('services.app_share.mobile.first_section.text');
                $scope.appsharetext3 = i18next.t('services.app_share.mobile.second_section.title');
                $scope.appsharetext4 = i18next.t('services.app_share.mobile.second_section.text');
                $scope.appsharetext5 = i18next.t('services.app_share.mobile.open_the_promotion');
                $scope.appsharetext6 = i18next.t('services.app_share.pc.title');
                $scope.appsharetext7 = i18next.t('services.app_share.pc.text');
                $scope.appsharetext8 = i18next.t('services.app_share.pc.copy_link');
                $scope.appsharetext9 = i18next.t('services.app_share.pc.link_is_copied');
                $scope.$apply();

                $scope.copyLink = () => {
                    var dummy = document.createElement("textarea");
                    document.body.appendChild(dummy);
                    dummy.value = $location.absUrl();
                    dummy.select();
                    document.execCommand("copy");
                    document.body.removeChild(dummy);
        
                    $scope.successCopyText = $scope.appsharetext9;
                    
                    if (document.getElementById('okMessageAfterCopy')) {
                        document.getElementById('okMessageAfterCopy').style.display = "block";
                        $('#okMessageAfterCopy').delay(2000).hide(0)
                    }
                }
            });
        }


        $timeout(function () {
            if ((navigator.userAgent.match(/android/i))) {
                window.open(`app://${window.location.host}/appshare/${$state.params.id}`, '_self')
            } else if((navigator.userAgent.match(/iPhone/i)) || (navigator.userAgent.match(/iPod/i))) {
                window.open(`com.yalty.yaltyapp://appshare/${$state.params.id}`, '_self')
            }
        }, 500);

    });